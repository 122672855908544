import React from 'react';
import { connect } from 'react-redux';
import AddAssessment from './AddAssessmentComponent/AddAssessment';
// import { getFormativeAssessmentRequest } from 'Actions/FormativeAssessmentAction/FormativeAssessmentAction';


const AddAssessmentIndex = (props) => {
    return (
        <AddAssessment {...props} />
    )
}
const mapStateToProps = state => ({
    // state: state,
    // FormativeAssessmentTags: state.FormativeAssessment.getFormativeAssiss,
    // loader: state.FormativeAssessment.loader
});
const mapDispatchToProps = dispatch => {
    return {
        // getFormativeAssessmentRequest: (data) => dispatch(getFormativeAssessmentRequest(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    AddAssessmentIndex
);