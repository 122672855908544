import React, { useState, useEffect } from 'react';
import Routing from '../src/Router/Routing';
import { Button } from "reactstrap";
// import firebase from 'firebase/app';
import Modal from 'react-bootstrap/Modal';
import tabIcon from 'Assets/AcademicModule/tabicon.png';
import { getToken, messaging } from './firebase/firebase'
import './App.css';

const App = () => {
  const [isTokenFound, setTokenFound] = useState(false);
  const [foregroundMessage, setForegroundMessage] = useState({});
  const [show, setShow] = useState(false);
  const standardModal = ['br-8', foregroundMessage.image ? 'PushPopUp-modal-warnings' : 'PushPopUp-modal-warning'];

  useEffect(() => {
    All()
  }, [])

  const All = () => {
    navigator.serviceWorker.addEventListener("message", (message) => {
      const { title, body, image } = message.data.data;
      setForegroundMessage({
        title: title,
        body: body,
        image: image
      })
      setShow(true)
    });

    if (messaging && messaging?.onMessage) {
      messaging.onMessage((payload) => {
        return (payload);
      })
    } else {
      return true;
    }
  }

  useEffect(()=>{
      getToken(setTokenFound);
    }, [])

  const handleClose = () => {
    setShow(false);
  }

  return (
    <div className="App">
      <Routing />
      <Modal className={standardModal.join(' ')} show={show} onHide={handleClose} centered={true}>
        <Modal.Header>
          <Modal.Title>
            <div className="titleDiv">
              <img src={tabIcon} alt='' className='pushProfileForword' width="80" height="80" />
            </div>
            <div className="titleNameDiv">
              <p className="confirmMesg-text">{foregroundMessage.title}</p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="confirmTextData"><p className="confirm-text">{foregroundMessage.body}</p></div>
        </Modal.Body>
        {foregroundMessage.image && <img src={foregroundMessage.image} alt='' className='pushProfileForword' width="250" height="250" />}

        <Modal.Footer>
          <Button variant="default" className="cancelUpgrade-btn" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default App;
