import { call, takeLatest, put } from 'redux-saga/effects';
import UserRosterEnrolledReducer from 'Redux/Reducers/RosterReducer/rosterEnrolledReducer';
import UserLoadingReducer from 'Redux/Reducers/CommonReducer/loadingReducer';
import { createNotification } from 'Config/notificationtoast';
import { GET_ROSTER_ENROLLED, EDIT_ROSTER_ENROLLED } from 'Actions/constants';
import {
    GetRosterEnrolled_API,
    EditRosterEnrolled_API,
} from 'Config/Api';
import { chroniclecloud_code } from 'Config/Constant';
import request from 'Config/request';
import { decryptData, logOut } from 'Utils/Helper';


export function* userRosterEnrolledModuleSaga() {
    yield takeLatest(GET_ROSTER_ENROLLED, getRosterEnrolled);
    yield takeLatest(EDIT_ROSTER_ENROLLED, editRosterEnrolled);
}

function* getRosterEnrolled(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL =  `${GetRosterEnrolled_API}?user_id=${data.cc_user_id}&student_id=${action.data.student_id}&page_no=${action.data.page_no}&sort_by=sort_by_none`;
    const BODY = JSON.stringify({
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "student_id": action.data.student_id,
        "page_no": action.data.page_no,
        "sort_by": "sort_by_none"
    })
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res)
        if (MainDecode.status === 200) {
            yield put(UserRosterEnrolledReducer.getRosterEnrolledSuccess(MainDecode));
            // createNotification('success', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));

        } else {
            createNotification('error', MainDecode.message);
            yield put(UserRosterEnrolledReducer.getRosterEnrolledFailure(MainDecode));
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRosterEnrolledReducer.getRosterEnrolledFailure(false));
    }
}

function* editRosterEnrolled(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = EditRosterEnrolled_API;
    const BODY = JSON.stringify({
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "student_id": action.data.student_id,
        "student_details_array": JSON.stringify(action.data.student_details_array)
    })
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": btoa(BODY) },
        });
        const MainDecode = JSON.parse(atob(response));
        if (MainDecode.status === 'Success') {
            let data = {
                "student_id": action.data.student_id,
                "page_no": action.data.page_no
            }
            yield put(UserRosterEnrolledReducer.getRosterEnrolled(data));
            yield put(UserRosterEnrolledReducer.editRosterEnrolledSuccess(MainDecode));
            createNotification('success', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));

        } else {
            createNotification('error', MainDecode.message);
            yield put(UserRosterEnrolledReducer.editRosterEnrolledFailure(MainDecode));
            yield put(UserLoadingReducer.loadingSuccess(false));
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRosterEnrolledReducer.editRosterEnrolledFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));

    }
}

