import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../AppContainer/AppContainer.css';
// import {loading} from '../../Actions/loadingAction';

import Load from './Gear.gif';
class Loader extends Component {
  componentDidMount() {
  }
  render() {
    const { visible } = this.props;
    return (
      <div>
        <div className={visible ? "loadermainTrue" : "loadermainFalse"} >
          {visible &&
            <img
              src={Load}
              alt=""
              className="loaderCSS"
            />
          }
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  state: state,
  visible: state.loading.loading,
});
const mapDispatchToProps = dispatch => {
  return {
    // loading: status => dispatch (loading (status)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Loader);
