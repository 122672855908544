import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import "./StudentNoteProgressbar.scss";

class StudentNoteProgressbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { value, type, max_score } = this.props
    return (
      <CircularProgressbar
        className={type === 'media' ? 'upload_progress' : ""}
        value={type === 'media' ? `${value}%` : value > max_score ? max_score : value}
        text={type === 'media' ? `${value}%` : value > max_score ? max_score : value}
        maxValue={type === 'media' ? 100 : max_score}
        strokeWidth={10}
        // text={`${value}%`}
        styles={buildStyles({
          textSize: "28px",
          strokeLinecap: "butt",
          textColor: "#222222",
          pathColor: "#96D800",
          // pathColor: value > 5 ? "#96D800" : "rgb(237 78 79)",
        })}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentNoteProgressbar);
