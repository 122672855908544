import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    sendSubscribeRequest: ['data'],
    sendSubscribeSuccess: ['data'],
    sendSubscribeFailure: ['error'],

})

export const UserFooterReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    error: null,
    exists: null,
    loader: null,
    action: null
})

/* ------------- Reducers ------------- */

/* Send Subscribe Request */
export const sendSubscribeRequest = (state) => {

    return { ...state, fetching: true, error: null, loader: true }
}
export const sendSubscribeSuccess = (state, action) => {

    return { ...state, fetching: false, error: null, loader: false }
}
export const sendSubscribeFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'SEND_SUBSCRIBE_FAILURE', loader: false }
}



/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.SEND_SUBSCRIBE_REQUEST]: sendSubscribeRequest,
    [Types.SEND_SUBSCRIBE_SUCCESS]: sendSubscribeSuccess,
    [Types.SEND_SUBSCRIBE_FAILURE]: sendSubscribeFailure,



})
