

import {
    GET_DATA_USAGE_REQUEST,
    GET_DATA_USAGE_SUCCESS,
    GET_DATA_USAGE_FAILURE,


} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */



// ----------- Get DataUsage Request-------------
export function getDataUsageRequest(data) {
    return {
        type: GET_DATA_USAGE_REQUEST,
        data,
    };
}

export function getDataUsageSuccess(data) {
    return {
        type: GET_DATA_USAGE_SUCCESS,
        data,
    };
}

export function getDataUsageFailure(data) {
    return {
        type: GET_DATA_USAGE_FAILURE,
        data,
    };
}







