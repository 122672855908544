import React from 'react';
import { connect } from 'react-redux';
import ClassGradeBookDashboard from './ClassGradeBookDashboardComponent/ClassGradeBookDashboard';
import {
    getClassGradebookRequest,
    addNewClassGradebookCategoryRequest,
    addNewClassGradebookEventRequest,
    updateClassGradebookEventRequest,
    deleteClassGradebookEventRequest,
    updateClassGradebookScoreCommentRequest,
    updateClassGradebookIdRequest,
    updateClassGradebookColorRequest,
    updateClassGradebookStudentsRequest,
    updateClassGradebookAllColorRequest,
    updateClassGradebookInputRequest,
    addClassGradebookPopoverDataRequest,
    updateClassGradebookPopoverColorRequest,
    updateClassGradebookPopoverInputRequest,
    updateClassGradebookDragDataRequest,
    updateClassGradebookDragEventDataRequest,
    openClassGradebookPopoverRequest,
    NewClassGradebookRequest,
} from 'Actions/GradebookAction/classGradebookAction';
import { getClassList } from 'Actions/ClassAction/classAction';
import { getStudentGradebookNullRequest } from 'Actions/GradebookAction/studentGradebookAction';


import { getClassNoteNullRequest } from 'Actions/NoteAction/classNoteAction'

const ClassGradeBookIndex = (props) => {
    return (
        <ClassGradeBookDashboard {...props} />
    )
}
const mapStateToProps = state => ({
    state: state,
    classeslist: state.class,
    getClassGradebookList: state.UserClassGradebook.getClassGradebookList,
    allStudentList: state.UserClassGradebook.allStudentList,
    selectedCategoryList: state.UserClassGradebook.selectedCategoryList,
    selectedEventList: state.UserClassGradebook.selectedEventList,
    selectedStudentForPopoverDetails: state.UserClassGradebook.selectedStudentForPopoverDetails,
    upgradePopUp:  state.UserClassGradebook.error,
    loader: state.UserClassGradebook.loader

});
const mapDispatchToProps = dispatch => {
    return {
        getClassList: (data) => dispatch(getClassList(data)),
        getClassGradebookRequest: (data) => dispatch(getClassGradebookRequest(data)),
        addNewClassGradebookCategoryRequest: (data) => dispatch(addNewClassGradebookCategoryRequest(data)),
        addNewClassGradebookEventRequest: (data) => dispatch(addNewClassGradebookEventRequest(data)),
        updateClassGradebookEventRequest: (data) => dispatch(updateClassGradebookEventRequest(data)),
        deleteClassGradebookEventRequest: (data) => dispatch(deleteClassGradebookEventRequest(data)),
        updateClassGradebookScoreCommentRequest: (data) => dispatch(updateClassGradebookScoreCommentRequest(data)),

        // ------Update state------
        updateClassGradebookIdRequest: (data) => dispatch(updateClassGradebookIdRequest(data)),
        updateClassGradebookStudentsRequest: (data) => dispatch(updateClassGradebookStudentsRequest(data)),
        updateClassGradebookInputRequest: (data) => dispatch(updateClassGradebookInputRequest(data)),
        updateClassGradebookAllColorRequest: (data) => dispatch(updateClassGradebookAllColorRequest(data)),
        updateClassGradebookColorRequest: (data) => dispatch(updateClassGradebookColorRequest(data)),
        addClassGradebookPopoverDataRequest: (data) => dispatch(addClassGradebookPopoverDataRequest(data)),
        updateClassGradebookPopoverColorRequest: (data) => dispatch(updateClassGradebookPopoverColorRequest(data)),
        updateClassGradebookPopoverInputRequest: (data) => dispatch(updateClassGradebookPopoverInputRequest(data)),
        updateClassGradebookDragDataRequest: (data) => dispatch(updateClassGradebookDragDataRequest(data)),
        updateClassGradebookDragEventDataRequest: (data) => dispatch(updateClassGradebookDragEventDataRequest(data)),
        openClassGradebookPopoverRequest: (data) => dispatch(openClassGradebookPopoverRequest(data)),
        NewClassGradebookRequest: (data) => dispatch(NewClassGradebookRequest(data)),
        getClassNoteNullRequest: (data) => dispatch(getClassNoteNullRequest(data)),
        getStudentGradebookNullRequest: (data) => dispatch(getStudentGradebookNullRequest(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    ClassGradeBookIndex
);