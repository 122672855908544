import React from "react";
import "./StudentNoteAdd.scss";
import { formatAudioDuration } from "Utils/Helper";
import * as moment from 'moment';
function RadioCardLabel({ audioObj, audioIndex, getAudioTimeHandler }) {
  let myaudio = document.getElementsByTagName("audio")[audioIndex];
  const formattedDuration = formatAudioDuration(myaudio?.duration);
  getAudioTimeHandler(formattedDuration);
  return (
    <div className="audit-sec w-auto radioCardAudio">
      <div className="audio-sub-div">
        <p className="audio-filename">
          {audioObj?.cc_attachment_name == null || audioObj?.cc_attachment_name === ""
            ? `${moment().format('LL')} ${moment().format('LT')}`
            : audioObj.cc_attachment_name}
        </p>
        <audio
          id="audioNewID"
          controls
          className="player"
          preload="false"
          controlsList="nodownload noplaybackrate"
          src={audioObj.cc_attachment_fullurl}
        ></audio>
      </div>
    </div>
  );
}

export default RadioCardLabel;
