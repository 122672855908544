import { call, takeLatest, put } from 'redux-saga/effects';
import UserRegisterReducer from 'Redux/Reducers/AuthModuleReducer/registerReducer';
import UserLoadingReducer from 'Redux/Reducers/CommonReducer/loadingReducer';
import { createNotification } from 'Config/notificationtoast';
import { REGISTER } from 'Actions/constants';
import { Register_API } from 'Config/Api';
import { getRandomColor } from "Utils/Helper";
import {
  chroniclecloud_code, device_type, device_udid
} from 'Config/Constant';
import request from 'Config/request';
import { decryptData, encryptData } from 'Utils/Helper';

export function* userRegisterModuleSaga() {
  yield takeLatest(REGISTER, doRegister);
}


function* doRegister(action) {
  yield put(UserLoadingReducer.loading(true));
  // let header = new Headers({
  //   'Content-Type': 'application/json',
  // });

  // const URL = `${base_url_API}api_user_registration`;
  const URL = Register_API

  const BODY = {
    // "chroniclecloud_code": chroniclecloud_code,
    // "device_udid": device_udid,
    // "user_color_code": getRandomColor(),
    // "register_device": device_type,

    // // -------Basic Information------
    // "user_email_id": action.data.user_email_id,
    // "user_first_name": action.data.user_first_name,
    // "user_last_name": action.data.user_last_name,

    // // -------School / University Details------
    // "user_school_name": action.data.user_school_name,
    // "school_state": action.data.school_state,
    // "school_city": action.data.school_city,
    // "school_zipcode": action.data.school_zipcode,
    // "user_phone_number": action.data.user_phone_number,
    // "user_country": action.data.user_country,
    // "auto_complete_status": action.data.auto_complete_status,

    // // -------Create Password------
    // "user_password": action.data.user_password,

    //---------New PayLoad-------------
    // "role": "string",
    "first_name": action.data.user_first_name,
    "last_name": action.data.user_last_name,
    "email": action.data.user_email_id,
    "password": action.data.user_password,
    "school_name": action.data.user_school_name,
    "mobile": action.data.user_phone_number,
    "city": action.data.school_city,
    "state": action.data.school_state,
    "country": action.data.user_country,
    "auto_complete_status": action.data.auto_complete_status,
    "zip_code": action.data.school_zipcode,
    "user_color_code": getRandomColor(),
    "device_udid": device_udid,
    "platform": "web",
  }
  let response;
  try {
    response = yield call(request, URL, {
      method: 'POST',
      // headers: header,
      body: { "data": encryptData(BODY) },
    });
    const MainDecode = decryptData(response.res);

    if (MainDecode.status === 200) {

      createNotification('success', MainDecode.message);
      yield put(UserLoadingReducer.loadingSuccess(false));
      yield put(UserRegisterReducer.registerSuccess(MainDecode.response_data));

    } else {
      createNotification('error', MainDecode.message);
      yield put(UserLoadingReducer.loadingSuccess(false));
      yield put(UserRegisterReducer.registerFailure(false));
    }
  } catch (error) {

    createNotification('warning', "MainDecode.message");
    yield put(UserLoadingReducer.loadingSuccess(false));
    yield put(UserRegisterReducer.registerFailure(false));

  }
}

