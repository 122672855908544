import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


// import Logger from '../Lib/Logger'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getRosterEnrolled: ['data'],
    getRosterEnrolledSuccess: ['data'],
    getRosterEnrolledFailure: ['error'],

    editRosterEnrolled: ['data'],
    editRosterEnrolledSuccess: ['data'],
    editRosterEnrolledFailure: ['error'],


})

export const UserRosterEnrolledReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    get_roster_Enrolled: null,
    edit_roster_Enrolled: null,
    error: null,
    exists: null,
    loader: null,
    action: null
})

/* ------------- Reducers ------------- */


/* get Roster Enrolled */
export const getRosterEnrolled = (state) => {
    return {
        ...state, fetching: true, error: null, get_roster_Enrolled: null, loader: true
    }
}
export const getRosterEnrolledSuccess = (state, action) => {

    const { data } = action
    for (let item of data.data) {

        item.note_details1 = item.note_details
    }
    return { ...state, fetching: false, error: null, get_roster_Enrolled: data, action: action.type, exists: true, loader: false }
}
export const getRosterEnrolledFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_ROSTER_ENROLLED_FAILURE', loader: false }
}


/* edit Roster Enrolled */
export const editRosterEnrolled = (state) => {
    return {
        ...state, fetching: true, error: null, get_roster_Enrolled: null, loader: true
    }
}
export const editRosterEnrolledSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, get_roster_Enrolled: data, action: action.type, exists: true, loader: false }
}
export const editRosterEnrolledFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'EDIT_ROSTER_ENROLLED_FAILURE', loader: false }
}




/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.GET_ROSTER_ENROLLED]: getRosterEnrolled,
    [Types.GET_ROSTER_ENROLLED_SUCCESS]: getRosterEnrolledSuccess,
    [Types.GET_ROSTER_ENROLLED_FAILURE]: getRosterEnrolledFailure,

    [Types.EDIT_ROSTER_ENROLLED]: editRosterEnrolled,
    [Types.EDIT_ROSTER_ENROLLED_SUCCESS]: editRosterEnrolledSuccess,
    [Types.EDIT_ROSTER_ENROLLED_FAILURE]: editRosterEnrolledFailure,




    // -----------Contact--------








})
