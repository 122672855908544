

import {

    GET_ROSTER,
    GET_ROSTER_SUCCESS,
    GET_ROSTER_FAILURE,

    EDIT_ROSTER,
    EDIT_ROSTER_SUCCESS,
    EDIT_ROSTER_FAILURE,

    ADD_ROSTER_CONTACT,
    ADD_ROSTER_CONTACT_SUCCESS,
    ADD_ROSTER_CONTACT_FAILURE,


    EDIT_ROSTER_CONTACT,
    EDIT_ROSTER_CONTACT_SUCCESS,
    EDIT_ROSTER_CONTACT_FAILURE,

    DELETE_ROSTER_CONTACT,
    DELETE_ROSTER_CONTACT_SUCCESS,
    DELETE_ROSTER_CONTACT_FAILURE,


    ADD_ROSTER_INFO,
    ADD_ROSTER_INFO_SUCCESS,
    ADD_ROSTER_INFO_FAILURE,

    EDIT_ROSTER_INFO,
    EDIT_ROSTER_INFO_SUCCESS,
    EDIT_ROSTER_INFO_FAILURE,

    DELETE_ROSTER_INFO,
    DELETE_ROSTER_INFO_SUCCESS,
    DELETE_ROSTER_INFO_FAILURE,

    ADD_LINK_ROSTER,
    ADD_LINK_ROSTER_SUCCESS,
    ADD_LINK_ROSTER_FAILURE



} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */



// -----------Get RosterList-------------
export function getRoster(data) {
    return {
        type: GET_ROSTER,
        data,
    };
}

export function getRosterSuccess(data) {
    return {
        type: GET_ROSTER_SUCCESS,
        data,
    };
}

export function getRosterFailure(data) {
    return {
        type: GET_ROSTER_FAILURE,
        data,
    };
}


// -----------Edit Roster-------------
export function editRoster(data) {
    return {
        type: EDIT_ROSTER,
        data,
    };
}

export function editRosterSuccess(data) {
    return {
        type: EDIT_ROSTER_SUCCESS,
        data,
    };
}

export function editRosterFailure(data) {
    return {
        type: EDIT_ROSTER_FAILURE,
        data,
    };
}


// -----------Add Roster COntact-------------
export function addRosterContact(data) {
    return {
        type: ADD_ROSTER_CONTACT,
        data,
    };
}

export function addRosterContactSuccess(data) {
    return {
        type: ADD_ROSTER_CONTACT_SUCCESS,
        data,
    };
}

export function addRosterContactFailure(data) {
    return {
        type: ADD_ROSTER_CONTACT_FAILURE,
        data,
    };
}


// -----------Edit Roster COntact-------------
export function editRosterContact(data) {
    return {
        type: EDIT_ROSTER_CONTACT,
        data,
    };
}

export function editRosterContactSuccess(data) {
    return {
        type: EDIT_ROSTER_CONTACT_SUCCESS,
        data,
    };
}

export function editRosterContactFailure(data) {
    return {
        type: EDIT_ROSTER_CONTACT_FAILURE,
        data,
    };
}


// -----------Delete Roster COntact-------------
export function deleteRosterContact(data) {
    return {
        type: DELETE_ROSTER_CONTACT,
        data,
    };
}

export function deleteRosterContactSuccess(data) {
    return {
        type: DELETE_ROSTER_CONTACT_SUCCESS,
        data,
    };
}

export function deleteRosterContactFailure(data) {
    return {
        type: DELETE_ROSTER_CONTACT_FAILURE,
        data,
    };
}






// -----------Add Roster Info-------------
export function addRosterInfo(data) {
    return {
        type: ADD_ROSTER_INFO,
        data,
    };
}

export function addRosterInfoSuccess(data) {
    return {
        type: ADD_ROSTER_INFO_SUCCESS,
        data,
    };
}

export function addRosterInfoFailure(data) {
    return {
        type: ADD_ROSTER_INFO_FAILURE,
        data,
    };
}


// -----------Edit Roster Info-------------
export function editRosterInfo(data) {
    return {
        type: EDIT_ROSTER_INFO,
        data,
    };
}

export function editRosterInfoSuccess(data) {
    return {
        type: EDIT_ROSTER_INFO_SUCCESS,
        data,
    };
}

export function editRosterInfoFailure(data) {
    return {
        type: EDIT_ROSTER_INFO_FAILURE,
        data,
    };
}


// -----------Delete Roster Info-------------
export function deleteRosterInfo(data) {
    return {
        type: DELETE_ROSTER_INFO,
        data,
    };
}

export function deleteRosterInfoSuccess(data) {
    return {
        type: DELETE_ROSTER_INFO_SUCCESS,
        data,
    };
}

export function deleteRosterInfoFailure(data) {
    return {
        type: DELETE_ROSTER_INFO_FAILURE,
        data,
    };
}


// -----------Link Roster -------------
export function addLinkRoster(data) {
    return {
        type: ADD_LINK_ROSTER,
        data,
    };
}

export function addLinkRosterSuccess(data) {
    return {
        type: ADD_LINK_ROSTER_SUCCESS,
        data,
    };
}

export function addLinkRosterFailure(data) {
    return {
        type: ADD_LINK_ROSTER_FAILURE,
        data,
    };
}








