import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
// import { getImage } from "Config/commonFirebaseImage";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getCoreStandardListRequest: ["data"],
  getCoreStandardListSuccess: ["data"],
  getCoreStandardListFailure: ["error"],

  importCustomStandards: ["data"],
  importCustomStandardsError: ["data"],
  importCustomStandardsSuccess: ["data"],

 
});

export const UserCoreStandardReducer = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  getCoreStandardList: {},
  error: null,
  exists: null,
  coreLoader: null,
  action: null,
  importCustomStandardData: null,
  importLoader: false,
  
});

/* ------------- Reducers ------------- */

/* get Core Standard List */

export const getCoreStandardListRequest = (state, action) => {
  return {
    ...state,
    fetching: true,
    error: null,
    coreLoader: true,
  };
};
export const getCoreStandardListSuccess = (state, action) => {
  const { data } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    getCoreStandardList: data,
    action: action.type,
    exists: true,
    coreLoader: false,
  };
};
export const getCoreStandardListFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error,
    exists: false,
    action: "GET_CORE_STANDARD_LIST_FAILURE",
    coreLoader: false,
  };
};

export const importCustomStandards = (state, action) => {
  return {
    ...state,
    importLoader: true,
    importCustomStandardData: null,
    error: null,
  };
};

export const importCustomStandardsError = (state, action) => {
  return {
    ...state,
    importLoader: false,
    importCustomStandardData: null,
    error: action.data,
  };
};

export const importCustomStandardsSuccess = (state, action) => {
  return {
    ...state,
    importLoader: false,
    importCustomStandardData: action.data,
    error: null,
  };
};



/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CORE_STANDARD_LIST_REQUEST]: getCoreStandardListRequest,
  [Types.GET_CORE_STANDARD_LIST_SUCCESS]: getCoreStandardListSuccess,
  [Types.GET_CORE_STANDARD_LIST_FAILURE]: getCoreStandardListFailure,

  [Types.IMPORT_CUSTOM_STANDARDS]: importCustomStandards,
  [Types.IMPORT_CUSTOM_STANDARDS_SUCCESS]: importCustomStandardsSuccess,
  [Types.IMPORT_CUSTOM_STANDARDS_ERROR]: importCustomStandardsError,


});
