

import {
    GET_ROSTER_ENROLLED,
    GET_ROSTER_ENROLLED_SUCCESS,
    GET_ROSTER_ENROLLED_FAILURE,

    EDIT_ROSTER_ENROLLED,
    EDIT_ROSTER_ENROLLED_SUCCESS,
    EDIT_ROSTER_ENROLLED_FAILURE

} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */



// -----------Get RosterList-------------
export function getRosterEnrolled(data) {
    return {
        type: GET_ROSTER_ENROLLED,
        data,
    };
}

export function getRosterEnrolledSuccess(data) {
    return {
        type: GET_ROSTER_ENROLLED_SUCCESS,
        data,
    };
}

export function getRosterEnrolledFailure(data) {
    return {
        type: GET_ROSTER_ENROLLED_FAILURE,
        data,
    };
}


// -----------Edit RosterList-------------
export function editRosterEnrolled(data) {
    return {
        type: EDIT_ROSTER_ENROLLED,
        data,
    };
}

export function editRosterEnrolledSuccess(data) {
    return {
        type: EDIT_ROSTER_ENROLLED_SUCCESS,
        data,
    };
}

export function editRosterEnrolledFailure(data) {
    return {
        type: EDIT_ROSTER_ENROLLED_FAILURE,
        data,
    };
}






