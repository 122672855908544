import React from 'react';
import { connect } from 'react-redux';
import { 
        getNotificationRequest,
        rejectSharedClassRequest,
        rejectSharedStudentRequest,
        deleteNotificationRequest,
        notificationMarkAsReadRequest,
        getNotificationCountRequest
} from 'Actions/NotificationAction/notificationAction';
import { getAcademicList, getAcademicScore, getAcademicYears } from 'Actions/AcademicAction/academicAction';
import { getUserSettingRequest } from 'Actions/SettingAction/settingAction';
import NotificationDashboard from './NotificationDashboardModule/NotificationDashboard';

const NotificationIndex = (props) => {
    return (
        <NotificationDashboard {...props} />
    )
}


const mapStateToProps = state => ({
    state: state,
    getNotification: state.Notification.Get_Notification,
    rejectSharedClass:state.Notification.Reject_Shared_Class,
    rejectSharedStudent:state.Notification.Reject_Shared_Student,
    deleteNotification:state.Notification.Delete_Notification,
    notificationMarkAsReadClass:state.Notification.Notification_Mark_As_Read
    // notificationCount:state.Notification.Get_Notification_Count
});
const mapDispatchToProps = dispatch => {
    return {
        getAcademicList: () => dispatch(getAcademicList()),
        getAcademicScore: () => dispatch(getAcademicScore()),
        getAcademicYears: () => dispatch(getAcademicYears()),
        getUserSettingRequest:  (data) => dispatch(getUserSettingRequest(data)),
        getNotificationRequest:  (data) => dispatch(getNotificationRequest(data)),
        rejectSharedClassRequest:  (data) => dispatch(rejectSharedClassRequest(data)),
        rejectSharedStudentRequest:  (data) => dispatch(rejectSharedStudentRequest(data)),
        deleteNotificationRequest:  (data) => dispatch(deleteNotificationRequest(data)),
        notificationMarkAsReadRequest:  (data) => dispatch(notificationMarkAsReadRequest(data)),
        getNotificationCountRequest:  (data) => dispatch(getNotificationCountRequest(data))
    };
};
export default  connect(mapStateToProps, mapDispatchToProps)(NotificationIndex);