import { call, takeLatest, put } from 'redux-saga/effects';
import UserStudentGradebookReducer from 'Redux/Reducers/GradebookReducer/studentGradebookReducer';
import { createNotification } from 'Config/notificationtoast';
import {
    GET_STUDENT_GRADEBOOK_REQUEST,
    UPDATE_STUDENT_GRADEBOOK_REQUEST,
} from 'Actions/constants';
import {
    GetStudentGradebook_List_API,
    UpdateStudentGradebook_List_API
} from 'Config/Api';
import { chroniclecloud_code } from 'Config/Constant';
import request from 'Config/request';
import { decryptData, encryptData, logOut } from 'Utils/Helper';


export function* userStudentGradebookModuleSaga() {
    yield takeLatest(GET_STUDENT_GRADEBOOK_REQUEST, getStudentGradebookRequest);
    yield takeLatest(UPDATE_STUDENT_GRADEBOOK_REQUEST, updateStudentGradebookRequest);

}

// ---------- Get Student Gradebook-----
function* getStudentGradebookRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${GetStudentGradebook_List_API}?role=teacher&user_id=${data.cc_user_id}&class_id=${action.data.classId}&page=${action.data.pageNo}&limit=10&student_id=${action.data.studentId}`;
    // const BODY = JSON.stringify({
    //     "chroniclecloud_code": chroniclecloud_code,
    //     "user_id": data.cc_user_id.toString(),
    //     "device_udid": sessionStorage.getItem('UDID'),
    //     "class_id": action.data.classId,
    //     "page_no": action.data.pageNo,
    //     "student_id": action.data.studentId
    // })

    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });

        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            
            yield put(UserStudentGradebookReducer.getStudentGradebookSuccess(MainDecode));
            sessionStorage.setItem('studentGradebookScore',JSON.stringify(MainDecode?.data?.response_data?.category))

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserStudentGradebookReducer.getStudentGradebookFailure(MainDecode));
        }
    } catch (error) {
        
        createNotification('warning', 'Something went wrong');
        yield put(UserStudentGradebookReducer.getStudentGradebookFailure(false));

    }
}
// ---------- Get Student Gradebook------


// ---------- Get Student Gradebook-----
function* updateStudentGradebookRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = UpdateStudentGradebook_List_API;
    // const BODY = JSON.stringify({
    //     "chroniclecloud_code": chroniclecloud_code,
    //     "user_id": data.cc_user_id,
    //     "device_udid": sessionStorage.getItem('UDID'),
    //     "student_id": action.data.studentId,
    //     "grading_list": JSON.stringify(action.data.gradingList)

    // })

    const BODY = {
        "user_id": data.cc_user_id.toString(),
        "student_id": action.data.studentId,
        "grading_list": action.data.gradingList,
        "platform": "web"
      }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {    
            createNotification('success', MainDecode.message);
            yield put(UserStudentGradebookReducer.updateStudentGradebookSuccess(MainDecode));
            // window.sessionStorage.removeItem('studentGradebookScore')
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserStudentGradebookReducer.updateStudentGradebookFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserStudentGradebookReducer.updateStudentGradebookFailure(false));

    }
}
// ---------- Get Student Gradebook------


