import React, { Component } from 'react';
import { connect } from 'react-redux';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import { getNotSelectedRosters, addSelectedRosters } from 'Actions/ClassAction/classSelectRosterAction';
import { createNotification } from 'Config/notificationtoast';
import { getImage, getRosterImage } from "Config/commonFirebaseImage";
import UpgradePopUp from '../../upgradePopUp/upgradePopUp'
import './ClassRosterSelect.scss';
import cancelBlack from 'Assets/RosterModule/cancelBlack.png';
import DatePicker from 'react-datepicker'
import calendarIcon from 'Assets/NoteModule/calendar.svg'
class ClassRosterSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AllRoster: [],
            seletedRoster: '',
            SelectShareOption: '',
            filter: 'not_in_current_class',
            sort_by: 'sort_by_none',
            tag_id: '',
            loadMoreCount: 1,
            seletedAllRoster: false,
            selectedRosterId: [],
            importRoster: false,
            showPopUp: false,
            searchBar: true,
            searchText: '',
            ClassDetails: [],
            date_flag: '',
            by_date: '',
            defaultList : []
        }
    }
    // ---------UNSAFE_componentWillMount-------
    UNSAFE_componentWillMount() {
        this.getRosterList()
    }
    // ---------UNSAFE_componentWillMount-------

    // ---------componentWillReceiveProps-------
    async UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.SelectData) {
            const { importRoster } = this.state;
            if (!importRoster) {
                if (nextProps.SelectData.action === 'GET_NOT_SELECTED_ROSTERS_SUCCESS') {
                    if (nextProps.SelectData.notSelectedRoster) {
                        this.setState({
                            AllRoster: nextProps.SelectData.notSelectedRoster.student_data,
                            defaultList: nextProps.SelectData.notSelectedRoster.student_data
                        })
                        let getRosterData = nextProps.SelectData.notSelectedRoster.student_data;
                        // ---Fatch Firebase Image---
                        for (let rosterItem of getRosterData) {
                            if (rosterItem.cc_student_pic_url) {
                                if (rosterItem.cc_student_pic_url.includes("https") === true) {
                                    rosterItem.isSeleted = false;
                                }
                                else {
                                    rosterItem.isSeleted = false;
                                    rosterItem.cc_student_pic_url = await getRosterImage(rosterItem.cc_student_pic_url);

                                }
                            }

                        }
                        // ---Fatch Firebase Image---

                        this.setState({
                            ClassRosterList: getRosterData
                        })
                    }
                }
            }
            if (nextProps.state.classRosterSelect) {
                if (nextProps.state.classRosterSelect.action === "ADD_SELECTED_ROSTERS_FAILURE") {
                    if (nextProps.state.classRosterSelect && (nextProps.state.classRosterSelect.message === "Please update your subscription plan"
                        || "Note limit has been exceeded. Please upgrade the plan"
                        || "Media limit has been exceeded. Please upgrade the plan")) {
                        this.setState({
                            showPopUp: true
                        })
                    }
                }
            }
            if (importRoster) {
                if (nextProps.SelectData.action === 'ADD_SELECTED_ROSTERS_SUCCESS') {
                    if (nextProps.SelectData.selectedData.status === "Success") {
                        this.uncheckWhenClose()
                        this.BackTOHome()
                        this.setState({
                            importRoster: false
                        })
                    }

                }
            }

        }



    }
    // ---------componentWillReceiveProps-------

    // ---------getRosterList-------
    getRosterList() {
        const { location = {} } = this.props
        const { state = {} } = location;
        const { ClassDetails = '', academicYearId = '' } = state;
        this.setState({
            ClassDetails: ClassDetails,
            academicYearId: academicYearId
        })
        const { filter, loadMoreCount, sort_by, tag_id } = this.state
        const value = {
            class_id: ClassDetails.cc_class_id,
            filter_by: filter,
            page_no: loadMoreCount,
            academic_year: academicYearId,
            sort_by: sort_by,
            tag_id: tag_id,
            search_text: this.state.searchText,
            by_date: this.state.by_date,
            date_flag: this.state.date_flag,

        }

        this.props.getNotSelectedRosters(value)
    }
    // ---------getRosterList-------



    BackTOHome = () => {
        this.props.history.goBack();
    }
    handleClosePopUp = () => {
        this.setState({
            showPopUp: false
        })
    }

    // ---------Select Rosters for Class-------
    selectRoster = () => {
        if (this.state.selectedRosterId.length === 0) {
            createNotification('error', 'Please select at least one Roster/student.');
            return;
        }
        const { location = {} } = this.props
        const { state = {} } = location;
        const { ClassDetails = '', academicYearId = '' } = state;
        const { selectedRosterId, filter, loadMoreCount, sort_by, tag_id } = this.state
        const data = {
            "student_id": selectedRosterId,
            "academic_year_id": academicYearId,
            "class_id": ClassDetails.cc_class_id,
            "by_class_sharing": 'yes',
            "filter_by": filter,
            "page_no": loadMoreCount,
            "sort_by": sort_by,
            "tag_id": tag_id,
            history: this.props.history
        }
        this.setState({
            importRoster: true
        })
        this.props.addSelectedRosters(data)
        
    }
    // ---------Select Rosters for Class-------


    // ---------Uncheck For All when cancel-------
    uncheckWhenClose() {
        let AllRoster = this.state.AllRoster;
        for (let item of AllRoster) {
            item.isSeleted = false;
        }
        this.setState({
            AllRoster: AllRoster,
            seletedAllRoster: false,
            selectedRosterId: [],
        }, () => {
        })
    }
    // ---------Uncheck For All when cancel-------

    // ---------Check/Uncheck For All-------
    CheckAll = () => {
        let AllRoster = this.state.AllRoster;

        if (this.state.seletedAllRoster) {
            for (let item of AllRoster) {
                item.isSeleted = false;
            }
            this.setState({
                AllRoster: AllRoster,
                seletedAllRoster: false,
                selectedRosterId: [],
            }, () => {
            })
        } else {
            let allActive = []
            for (let item of AllRoster) {
                item.isSeleted = true
                allActive.push(item.cc_student_id)
            }
            this.setState({
                AllRoster: AllRoster,
                seletedAllRoster: true,
                selectedRosterId: allActive
            }, () => {
            })
        }


    }
    // ---------Check/Uncheck For All-------

    // ---------Check/Uncheck For One-------
    CheckValue = (item, index) => {
        let AllRoster = this.state.AllRoster;
        AllRoster[index].isSeleted = !AllRoster[index].isSeleted;
        this.pushInArray(item.cc_student_id)
        this.setState({
            AllRoster,
            seletedAllRoster: false
        })
    }
    // ---------Check/Uncheck For One-------


    // ---------Check/Uncheck in API Send Array-------
    pushInArray = (value) => {
        let selectedRosterId = this.state.selectedRosterId;
        let idFound = false;
        let fixedIndex = ''
        if (selectedRosterId.length > 0) {
            let index = 0
            for (let item of selectedRosterId) {
                if (item === value) {
                    idFound = true;
                    fixedIndex = index
                }
                index++
            }
            if (idFound) {
                selectedRosterId.splice(fixedIndex, 1)
            } else {
                selectedRosterId.push(value)
            }

        } else {
            selectedRosterId.push(value)
        }

        this.setState({
            selectedRosterId
        }, () => {
        })

    }
    // ---------Check/Uncheck in API Send Array-------

    dateChange = (date) => {
        this.uncheckWhenClose()
        var selectedDate = date.toLocaleDateString('en-CA')
        this.setState({
            by_date: selectedDate,
        }
            , () => {
                if (date && this.state.date_flag) {


                    const { classDetail = '', academicYearId = '' } = this.props.location.state;
                    let value = {
                        class_id: classDetail.cc_class_id,
                        search_text: this.state.searchText || '',
                        academic_year: academicYearId,
                        by_date: this.state.by_date || date.toLocaleDateString('en-CA'),
                        date_flag: this.state.date_flag,
                    }
                    this.getRosterList(value)
                } else {
                    this.setState({
                        ClassRosterList: this.state.StudentsWithoutSearch
                    })
                }
            }
        )
    }

    // ---------Auto search  -------
    handleSearchText = (event) => {
        this.uncheckWhenClose()
        this.setState({
            isAddStudentSubmit: false,
            InvelidMessageEmail: '',
            searchText: event ? event.target.value : this.state.searchText,
        }
            , () => {
                if (this.state.searchText.length > 2) {
                    const { classDetail = '', academicYearId = '' } = this.props.location.state;
                    const { searchText } = this.state
                    let value = {
                        class_id: classDetail.cc_class_id,
                        search_text: searchText,
                        academic_year: academicYearId,
                        by_date: this.state.by_date,
                        date_flag: this.state.date_flag,

                    }

                    // this.getRosterList(value)
                    let data = this.state.AllRoster.filter((item) => item.cc_student_first_name.toLowerCase().includes(searchText.toLowerCase()) || item.cc_student_last_name.toLowerCase().includes(searchText.toLowerCase()))
                    
                    this.setState({
                        AllRoster : data
                    })

                } else {
                    this.setState({
                        ClassRosterList: this.state.StudentsWithoutSearch,
                        AllRoster : this.state.defaultList
                    })
                }
            }
        )

    }
    // ---------Auto search  -------
    sortDate = (flag) => {
        this.uncheckWhenClose()
        this.setState({
            date_flag: flag,
        }

            , () => {
                if (flag && this.state.by_date) {
                    const { classDetail = '', academicYearId = '' } = this.props.location.state;
                    const { searchText } = this.state
                    let value = {
                        class_id: classDetail.cc_class_id,
                        search_text: searchText,
                        academic_year: academicYearId,
                        by_date: this.state.by_date,
                        date_flag: this.state.date_flag,

                    }

                    this.getRosterList(value)

                } else {
                    this.setState({
                        ClassRosterList: this.state.StudentsWithoutSearch
                    })
                }

            })
    }

    render() {
        let { AllRoster,
            selectedRosterId,
            showPopUp,
            seletedAllRoster } = this.state;
        const { loader } = this.props

        return (
            <div className='classRosterSelectContainer'>

                {/* ---------Class Roster Select Add ------ */}
                <div className="ClassRosterSelectMainDiv">
                    {/* ---------Class Roster Details ------ */}
                    <div className='classRosterSelectDetailsMain'>
                        <div className="classRosterSelectBackDiv" onClick={() => { this.BackTOHome() }}>
                            <i className="rosterClassBackIcon material-icons">chevron_left</i>
                            <p className='rosterClassSelectBackText'>Class Roster</p>
                        </div>
                        <div className='classRosterDetailTextDiv'>
                            <p className='classRosterClassName'>Roster List<span className='allRosterSelectNote'></span></p>
                        </div>
                    </div>
                    {/* ---------Class Roster Details ------ */}


                    {/* ---------Class Selected Roster Close-------- */}
                    {selectedRosterId.length > 0 && <div className='displayFlexInSeletRoster'>
                        <div className='croseSlectedDiv' onClick={() => { this.uncheckWhenClose() }}>
                            <i className="CrossAndConfirmIcon material-icons">close</i>
                        </div>
                        <div className='confirmSlectedDiv' onClick={() => { this.selectRoster() }}>
                            <i className="CrossAndConfirmIcon material-icons">check</i>

                        </div>
                    </div>}
                    {/* ---------Class Selected Roster Close-------- */}

                </div>
                {/* ---------Class Roster Add  ------ */}
                {/* ---------------Search Filter ------------ */}
                {/* ---------------Select All Filter------------ */}
                {this.state.searchBar && <div className="totalClassRosterSearchDiv">

                    <div className='rosterClassAllSelectDiv'>
                        <i onClick={() => { this.CheckAll() }} className="checkBoxSelectRoster material-icons">{seletedAllRoster ? 'check_circle' : 'radio_button_unchecked'}</i>
                        <p className='allRosterSelectBlock'>Select All Students</p>
                    </div>
                    {/* ---------------Select All Filter------------ */}
                    <div className='searchStudentStylediv' >
                        <div className="searchStudentdiv">
                            <div className="input-group-prepend border-0">
                                <button id="button-addon4" type="button" className="btn btn-link text-info searchIconDiv"><i className="fa fa-search searchIcon"></i></button>
                            </div>
                            <input
                                autoFocus
                                autoComplete='off'
                                margin="normal"
                                type='text'
                                value={this.state.searchText}
                                onChange={this.handleSearchText}
                                name="searchText"
                                className='searchStudent'
                                placeholder="Search"
                            ></input>
                        </div>
                    </div>
                    <div className='datePickerDiv'>
                        <div className='datePicker'>
                            <DatePicker
                                selected={this.date}
                                popperPlacement="bottom-center"
                                onChange={date => { this.dateChange(date) }}
                                customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
                            />
                            <span>{this.state.by_date}</span>
                        </div>
                    </div>
                    <div className="sortByDiv">
                        <div className='totalStudentsDiv'>
                            <span className='RosterYearSortNameText'>Filter By : </span>
                        </div>
                        <div>
                            <div className='rosterfilterDiv'>
                                <div className='classRosterfirstName'>
                                    <i onClick={() => { this.sortDate('0') }} className={`${this.state.date_flag === "0" ? 'checkBoxfilter' : 'unCheckBoxfilter'}  material-icons `}
                                    >
                                        {this.state.date_flag === "0" ? 'check_circle' : 'radio_button_unchecked'}
                                    </i>
                                    <p className='listAllText'>Before Date</p>
                                </div>

                                <div className='classRosterlastName'>
                                    <i onClick={() => { this.sortDate('1') }} className={`${this.state.date_flag === "1" ? 'checkBoxfilter' : 'unCheckBoxfilter'}   material-icons`} >
                                        {this.state.date_flag === "1" ? 'check_circle' : 'radio_button_unchecked'}
                                    </i>
                                    <p className='listAllText'>After Date</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='closeIconSearchBorderDiv'>
                        <div className='closeIconSearchDiv' style={{ display: this.state.by_date || this.state.date_flag || this.state.searchText ? "block" : "none" }}>
                            <img src={cancelBlack} alt='' className='closeIconSearch' onClick={() => {
                                this.uncheckWhenClose()
                                this.setState({
                                    searchBar: true,
                                    ClassRosterList: this.state.StudentsWithoutSearch,
                                    searchText: '',
                                    date_flag: '',
                                    by_date: '',

                                }, () => {
                                    if (true) {
                                        const { classDetail = '', academicYearId = '' } = this.props.location.state;
                                        const { searchText } = this.state
                                        let value = {
                                            class_id: classDetail.cc_class_id,
                                            search_text: searchText,
                                            academic_year: academicYearId,
                                            by_date: this.state.by_date,
                                            date_flag: this.state.date_flag,
                                        }

                                        this.getRosterList(value)

                                    } else {
                                        this.setState({
                                            ClassRosterList: this.state.StudentsWithoutSearch
                                        })
                                    }
                                })
                            }} />
                        </div>
                    </div>
                </div>}

                {/* ---------------Search Filter ------------ */}
                {/* ------ Loader Div----- */}
                {loader && <div>
                    <img src={loaderImag} alt='' className='loaderIconCss' />
                </div>}
                {/* ------ Loader Div----- */}

                {/* ------------Select Roster Card------------- */}
                {AllRoster && AllRoster.length > 0 ? <div className='classRosterSelectCardMainDiv'>
                    {AllRoster && AllRoster.map((item, index) => (
                        <div key={index} className='classRosterSelectCardDiv' >
                            <div className='selectRosterImageDiv'>
                                {item.cc_student_pic_url ? <img src={item.cc_student_pic_url} alt='' className='selectRosterProfileImage' /> :
                                    <div className='selectRosterProfileDummy'>
                                        <h5 className='profileSelectDummyText'>{item.cc_student_first_name.charAt(0)}{item.cc_student_last_name.charAt(0)}</h5>
                                    </div>}
                            </div>
                            <div className='selectRosterTextInfoDiv' >
                                <p className='selectRosterName'>{item.cc_student_last_name},{item.cc_student_first_name}</p>
                                <p className='selectRosterEmail'>{item.cc_student_email_id}</p>

                            </div>
                            <div className='selectRosterCheckBoxDiv'>
                                <i onClick={() => { this.CheckValue(item, index) }} className="checkBoxSelectRoster material-icons">{item.isSeleted ? 'check_circle' : 'radio_button_unchecked'}</i>

                            </div>
                        </div>))}
                </div> :
                    <div className='noRoster'>
                        {loader ? "" : <div data-toggle="modal" data-target="#addClassRosterModal">
                            <p className='tapToAddText'>All Rosters Added in class</p>

                        </div>}

                    </div>
                }
                {/* ------------Select Roster Card------------- */}
                {showPopUp && <UpgradePopUp handleClosePopUp={this.handleClosePopUp} />}
            </div>
        )
    }
}
const mapStateToProps = state => ({
    state: state,
    SelectData: state.classRosterSelect,
    loader: state.classRosterSelect.loader
});
const mapDispatchToProps = dispatch => {
    return {
        getNotSelectedRosters: (data) => dispatch(getNotSelectedRosters(data)),
        addSelectedRosters: (data) => dispatch(addSelectedRosters(data)),


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    ClassRosterSelect
);