import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ClassGradeBookTableData from '../ClassGradeBookTableDataComponent/ClassGradeBookTableData';
import ClassGradeBookAverageFooter from '../ClassGradeBookAverageFooterModule/ClassGradeBookAverageFooter';
import * as routes from "Router/RoutesURL";
import { ScrollSyncPane } from 'react-scroll-sync';
import "./ClassGradeBookTable.scss";

// Replace data according to index for drag
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getListStyle = isDraggingOver => ({
  display: 'flex',
  overflow: 'auto',
});

const ClassGradeBookTable = (props) => {
  const { ClassData, history, AcademicData, loader, message, isDragDisabled, getClassGradebookList, changeGradebookType, updateClassGradebookStudentsRequest, updateClassGradebookIdRequest, setCategoryIdCheck, setCategoryNameCheck, eventName,
    categoryName, setEventNameCheck, setEventIdCheck, setShowConfirmBreadCrumb, setdateCheck, updateClassGradebookPopoverColorRequest, updateClassGradebookPopoverInputRequest, addClassGradebookPopoverDataRequest, selectedStudentForPopoverDetails, updateClassGradebookScoreCommentRequest, updateClassGradebookDragDataRequest, openClassGradebookPopoverRequest, updateClassGradebookDragEventDataRequest, getStudentGradebookNullRequest } = props;
  const [selectedCatIndex, setCIndexCheck] = useState('');
  const [SeleectedEvetIndex, setEIndexCheck] = useState('');
  const [SelectedStudentIndex, setSIndexCheck] = useState('');

 
  useEffect(() => {
    if (message === "Edit Grading Event") {
      sessionStorage.setItem("dfaultEventName", JSON.stringify(eventName));
      sessionStorage.setItem("dfaultCategoryName", JSON.stringify(categoryName));
    }
  }, [])
  // -----drag data-----
  const onDragEnd = (result) => {
    if (getClassGradebookList.category.length > 1) {
      if (!result.destination) {
        return;
      }
      const Value = reorder(
        getClassGradebookList.category,
        result.source.index,
        result.destination.index
      );
      const data = {
        "categoryId": result.draggableId,
        "newIndex": result.destination.index,
        "classId": ClassData.cc_class_id,
        "Value": Value,
      }
      updateClassGradebookDragDataRequest(data)
    }
  }
  // -----drag data-----

  const setIndex = (cIndex, eventIndex, StudentIndex) => {
    setCIndexCheck(cIndex);
    setEIndexCheck(eventIndex);
    setSIndexCheck(StudentIndex);

  }

  return (
    <div>
      <div className="table-gradebook table-Fix-Size">
        {/* -------------Student List ------ */}
        <div className="table-gradebook-table">
          <div className={`table-left-section ${getClassGradebookList?.students?.length > 0 && getClassGradebookList?.category?.length == 0 && 'table-left-scroll-custom'}`}>
            <div>
              <div className="header-title header-style-top-title">
                <label>— Tap cells of Categories, Events, and Scores to add/view/edit</label>
              </div>
            </div>
            {getClassGradebookList && getClassGradebookList?.students && getClassGradebookList?.students?.length > 0 && getClassGradebookList?.students?.map((item, index) => (
              <div key={index} className="header-style" onClick={() => {
                getStudentGradebookNullRequest()
                history.push(routes.STUDENTGRADEBOOK, { id: item.cc_student_id, AcademicDataObj: AcademicData, academicYear: AcademicData.academic_year, classId: ClassData.cc_class_id, studentInfo: item, BackPage: 'Class Gradebook', classData: ClassData })
              }}>
                {item.cc_student_last_name !== "" && <div className="profile" >
                  {item.cc_student_pic_url ?
                    <img className="profile-img" src={item.cc_student_pic_url} alt="" width="50" height="50" />
                    :
                    <div className='profileDummyRosterProfile'>
                      <h5 className='rosterProfileImageText'>{item.cc_student_first_name.charAt(0)}{item.cc_student_last_name.charAt(0)}</h5>
                    </div>
                  }
                  {item.cc_student_last_name !== "" && <label>{item.cc_student_last_name?.replace(/(.{13})..+/, "$1…")}, {item.cc_student_first_name?.replace(/(.{13})..+/, "$1…")}</label>}
                </div>}
              </div>))}
          </div>
          {/* -------------Student List ------ */}
          {/*
            <div className="table-middle-section">
              <div>
                <div className="header-title header-style">
                  <div className="eventNameDiv"><label className="labeltitle">Event Category</label></div>
                  <div className="categoryNameDiv"><label className="labeltitle">Event Name</label></div>
                </div>
              </div>
              {getClassGradebookList && getClassGradebookList.students && getClassGradebookList.students.length > 0 && getClassGradebookList.students.map((item, index) => (
                <div key={index} className="header-style">
                  {index === 0 ?
                    <div className="profileScore" >
                      <label>Score</label>
                    </div> :
                    <div className="profileScore ">
                      <label></label>
                    </div>
                  }
                </div>))}
            </div>
          */}
          {/* -------------Table List ------ */}
          <div className="table-right-section">

            {getClassGradebookList && getClassGradebookList.category && getClassGradebookList.category.length > 0 ? <DragDropContext onDragEnd={onDragEnd}>
              <ScrollSyncPane group={["horizontal"]}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      {getClassGradebookList.category.map((item, index) => (
                        <Draggable key={item.cc_category_id} draggableId={JSON.stringify(item.cc_category_id)} index={index} isDragDisabled={!isDragDisabled}>
                          {(provided, snapshot) => (
                            <ClassGradeBookTableData
                              cIndex={index}
                              index={item.category_events.length}
                              isDragDisabled={isDragDisabled}
                              item={item}
                              provided={provided}
                              snapshot={snapshot}
                              ClassData={ClassData}
                              selectedCatIndex={selectedCatIndex}
                              SeleectedEvetIndex={SeleectedEvetIndex}
                              SelectedStudentIndex={SelectedStudentIndex}
                              setIndex={setIndex}
                              changeGradebookType={changeGradebookType}
                              updateClassGradebookStudentsRequest={updateClassGradebookStudentsRequest}
                              updateClassGradebookIdRequest={updateClassGradebookIdRequest}
                              studentList={getClassGradebookList.students}
                              getClassGradebookList={getClassGradebookList}
                              setCategoryIdCheck={setCategoryIdCheck}
                              setCategoryNameCheck={setCategoryNameCheck}
                              setEventNameCheck={setEventNameCheck}
                              setEventIdCheck={setEventIdCheck}
                              setdateCheck={setdateCheck}
                              updateClassGradebookPopoverColorRequest={updateClassGradebookPopoverColorRequest}
                              updateClassGradebookPopoverInputRequest={updateClassGradebookPopoverInputRequest}
                              addClassGradebookPopoverDataRequest={addClassGradebookPopoverDataRequest}
                              selectedStudentForPopoverDetails={selectedStudentForPopoverDetails}
                              updateClassGradebookScoreCommentRequest={updateClassGradebookScoreCommentRequest}
                              openClassGradebookPopoverRequest={openClassGradebookPopoverRequest}
                              getListStyle={getListStyle}
                              updateClassGradebookDragDataRequest={updateClassGradebookDragDataRequest}
                              updateClassGradebookDragEventDataRequest={updateClassGradebookDragEventDataRequest}
                              history={history}
                            />

                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </ScrollSyncPane>
            </DragDropContext>
              : <div>
                {loader ? "" :
                  <p className='noGradebookDataText'>Get your gradebook started by clicking "+ Grading Event" above!</p>
                }
              </div>}
          </div>
        </div>
        {/* -------------Table List ------ */}
        <div className="avg-grade-table">
          {getClassGradebookList?.category?.length > 0 && <ClassGradeBookAverageFooter getClassGradebookCatList={getClassGradebookList.category} />}
        </div>
      </div>
    </div >
  );

}

ClassGradeBookTable.propTypes = {
  changeGradebookType: PropTypes.func,
  updateClassGradebookIdRequest: PropTypes.func,
  setCategoryIdCheck: PropTypes.func,
  setCategoryNameCheck: PropTypes.func,
  setEventNameCheck: PropTypes.func,
  setEventIdCheck: PropTypes.func,
  setdateCheck: PropTypes.func,
  openClassGradebookPopoverRequest: PropTypes.func,
  ClassData: PropTypes.object,
  isDragDisabled: PropTypes.bool,
  updateClassGradebookStudentsRequest: PropTypes.func,
  updateClassGradebookPopoverColorRequest: PropTypes.func,
  updateClassGradebookPopoverInputRequest: PropTypes.func,
  addClassGradebookPopoverDataRequest: PropTypes.func,
  updateClassGradebookScoreCommentRequest: PropTypes.func,
  updateClassGradebookDragDataRequest: PropTypes.func,
  updateClassGradebookDragEventDataRequest: PropTypes.func
};

export default ClassGradeBookTable