import React from 'react';
import map from 'Assets/ContactUsModule/Address.png';
import mail from 'Assets/ContactUsModule/mail.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../ContactUsDashboardComponent/ContactUsDashboard.scss'


const ContactInfo = (props) => {
    return (

        <Col md={7} lg={7}>
            <p className='descriptionText'>We love notes. And we love supporting teachers. So when teachers drop us notes containing questions or feedback about how we can best provide this support, we’re floating on a cloud. A Chronicle Cloud, of course! Make our day by using the provided form to send us a note about Chronicle Cloud. We promise to get back to you shortly!</p>
            <p className='descriptionText'>Alternatively, our contact info is given below:</p>
            <div >
                <Row>
                    <Col md={1} lg={1} className='addressIconCol'>
                        <div><img src={map} height="30" alt="CoolBrand" /></div>
                    </Col>
                    <Col md={10} lg={10} >
                        <p className='descriptionText'><a href="mailto:support@chroniclecloud.com" target='_blank' rel="noopener noreferrer" >support@chroniclecloud.com</a>.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={1} lg={1} className='addressIconCol'>
                        <div><img src={mail} height="30" alt="CoolBrand" /></div>
                    </Col>
                    <Col md={10} lg={10} >
                        <p className='descriptionText'>100 Cambridge Street #1400, <br />Boston MA 02114, USA</p>
                    </Col>
                </Row>
            </div>


        </Col>

    )
}

export default ContactInfo