import React, { Component } from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import attachedIcon from "Assets/NoteModule/paperclip.svg";
import pencilIcon from "Assets/NoteModule/pencil-alt.png";
import trashIcon from "Assets/NoteModule/trash-alt.png";
import addNoteIcon from "Assets/NoteModule/plusNew.svg";
import loaderImag from "Assets/ProfileModule/PepperyMedium.gif";
import * as routes from "Router/RoutesURL";
import { getGroupNoteStudents } from "Actions/NoteAction/studentNoteAction";
import { getFormattedDate } from "Utils/Helper";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import groupNoteIcon from "Assets/NoteModule/grpnt.png";
import "react-vertical-timeline-component/style.min.css";
import "./GroupNotesTimeline.scss";
import styled from "styled-components";
import starYellow from "Assets/NoteModule/starYellow.svg";
import remiderIconPink from "Assets/NoteModule/remiderIconPink.svg";
import RemiderIconGrey from "Assets/NoteModule/RemiderIconGrey.svg";
import GroupNotes from "Assets/NoteModule/GroupNotes.svg";

class GroupNotesTimeline extends Component {
  constructor(props) {
    super(props);
    const signDate = sessionStorage.getItem("separatorFormat");
    const formatDate = sessionStorage.getItem("dateFormat");
    this.state = {
      show: false,
      selectedIndex: "",
      isWorkingForGroup: false,
      studentGroup: [],
      formatDate: formatDate,
      signDate: signDate,
      newClassListData : []
    };
  }

  // ---------componentWillReceiveProps-------
  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { NoteData } = this.props;
    if (this.state.isWorkingForGroup) {
      if (nextProps.studentData) {
        let AllData = NoteData;
        AllData[this.state.selectedIndex].groupArray = nextProps.studentData;
        this.props.GropuStudentNotes(AllData);
        this.setState({
          studentGroup: nextProps.studentData,
          isWorkingForGroup: false,
        });
      }
    }
  }
  // ---------componentWillReceiveProps-------

  // ---------hide Misellane --------
  handleClose = () => {
    this.setState({ show: false });
  };
  // ---------hide Misellane --------

  // ---------show Misellane --------
  handleShow = () => {
    this.setState({ show: true });
  };
  // ---------show Misellane --------

  // ---------loadMore Note --------
  loadMore = () => {
    this.props.loadMore();
  };
  // ---------loadMore Note --------

  // ---------Set Artifact Check/Uncheck --------
  setArtifact = (item, index) => {
    const data = {
      index,
      student_Note_details: item,
    };
    this.props.setArtifact(data);
  };
  // ---------Set Artifact Check/Uncheck --------

  // ---------Edit Misellane --------
  editMiscellaneous = (data, index) => {
    const value = {
      data: data,
      index: index,
    };
    this.props.editMiscellaneous(value);
  };
  // ---------Edit Misellane --------

  // ---------Delete Misellane --------
  deleteMiscellaneous = (data, index) => {
    const value = {
      data: data,
      index: index,
    };
    this.props.deleteMiscellaneous(value);
  };
  // ---------Delete Misellane --------

  // ---------Delete Student Note --------
  deleteStudentNote = (data, index) => {
    const value = {
      data: data,
      index: index,
    };
    this.props.deleteStudentNote(value);
  };
  // ---------Delete Student Note --------

  // ------Students of Note-----
  studentsOfNote = (value, i) => {
    console.log('value :>> ', value);
    let arr = []
    console.log('this.props.classGroupNoteStudent :>> ', this?.props?.classGroupNoteStudent);
    for (let i = 0; i < this?.props?.classGroupNoteStudent.length; i++) {
      let elem = this?.props?.classGroupNoteStudent[i]
      arr?.push(elem.cc_student_last_name + " " + elem.cc_student_first_name )
      
    }
    console.log('arr :>> ', arr);
    if (value.cc_group_arr.length > 0) {
      this.setState({
        studentGroup:  arr || value.cc_group_arr,
        selectedIndex: i,
      });
    } else {
      this.setState({
        studentGroup: [],
        selectedIndex: i,
        isWorkingForGroup: true,
      });
      const data = {
        note_id: value.cc_note_id,
      };
      this.props.getGroupNoteStudents(data);
    }
  };
  // ------Students of Note-----
  // --------------------Custom Button ------------------
  PinkButton = styled(Button)({
    color: "#BD47E8",
    backgroundColor: "#BD47E81A",
    borderColor: "#BD47E8",
    borderRadius: "8px",
    marginTop: "3px",
    "&:hover": {
      backgroundColor: "#BD47E81A",
      borderColor: "#BD47E8",
    },
    "&:disabled": {
      backgroundColor: "#FFFFFF",
      borderColor: "#00000066",
      color: "#00000066",
    },
    textTransform : 'capitalize',
  });

  RedButton = styled(Button)({
    color: "#FF6A6A",
    backgroundColor: "#FF6A6A1A",
    borderColor: "#FF6A6A",
    borderRadius: "8px",
    marginLeft: "10px",
    marginTop: "3px",
    "&:hover": {
      backgroundColor: "#FF6A6A1A",
      borderColor: "#FF6A6A",
    },
    "&:disabled": {
      backgroundColor: "#FFFFFF",
      borderColor: "#00000066",
      color: "#00000066",
      startIcon: <img src={starYellow} alt="" width="14" height="14" />,
    },
  });
  GreyButton = styled(Button)({
    color: "#AAAAAA",
    backgroundColor: "#AAAAAA1A",
    borderColor: "#AAAAAA",
    borderRadius: "8px",
    marginLeft: "10px",
    marginTop: "3px",
    borderRadius: '8px',
    "&:hover": {
      backgroundColor: "#AAAAAA1A",
      borderColor: "#AAAAAA",
    },
    "&:disabled": {
      backgroundColor: "#FFFFFF",
      borderColor: "#00000066",
      color: "#00000066",
      startIcon: <img src={starYellow} alt="" width="14" height="14" />,
    },
    textTransform : 'capitalize',
  });
  // --------------------Custom Button ------------------

  render() {
    const PinkButton = this.PinkButton;
    const RedButton = this.RedButton;
    const GreyButton = this.GreyButton;
    const {
      NoteData,
      TotalCount,
      studentInfo,
      academicYear,
      props,
      student_enrolled_classes,
      StudentAllNoteDetails,
      class_id,
      editGroupNote,
      GroupNotesLoader,
      classPermission,
    } = this.props;
    
    let { studentGroup } = this.state;
    const popover = (
      
      <Popover id="assign-popover-box">
        {GroupNotesLoader ? (
          <img src={loaderImag} alt="" className="loaderGroupIcon" />
        ) : (
          <div className="popcls">
          <Popover.Content className="p-3">
            {studentGroup &&
              studentGroup.length > 0 &&
              studentGroup.map((item, index) => (
                <div key={index}>
                  {item}{" "}
                  <br />{" "}
                </div>
              ))}
          </Popover.Content>
         </div>
        )}
        </Popover>
    );

    const formatString = (string) => {
      let temp = string;
      temp = temp.replace(/\s+/g, "");
      return temp;
    };

    return (
      <div className="sort-timeline-div">
        <VerticalTimeline layout="2-columns" className="sort-timeline-section">
          {NoteData &&
            NoteData.map((item, i) => {
              // console.log('NoteData112233 :>> ', NoteData);
              return (
                <VerticalTimelineElement
                  key={i}
                  className="vertical-timeline-element--work"
                  date={
                    item.cc_note_show_type === "note"
                      ? item.cc_linked_flag === 1
                        ? getFormattedDate(
                            item.cc_note_add_on,
                            this.state.formatDate,
                            this.state.signDate
                          ) + " Shared"
                        : getFormattedDate(
                            item.cc_note_add_on,
                            this.state.formatDate,
                            this.state.signDate
                          )
                      : getFormattedDate(
                          item.cc_misc_note_add_on,
                          this.state.formatDate,
                          this.state.signDate
                        ) + `, Miscellaneous Note`
                  }
                >
                  {item.cc_note_show_type === "note" ? (
                    /* -----------Norma/ Note-------- */
                    <div>
                      <Row className="timeline-section">
                        <Col xs="4" className="text-left pr-0">
                          {classPermission !== null &&
                          classPermission === "read" ? (
                            <i
                              className={
                                item.cc_note_mark_artified === "no"
                                  ? "fa fa-star-o disabledStarUnSelected"
                                  : "fa fa-star disabledStarSelected"
                              }
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className={
                                item.cc_note_mark_artified === "no"
                                  ? "fa fa-star starUnSelected"
                                  : "fa fa-star starSelected"
                              }
                              aria-hidden="true"
                              onClick={() => {
                                this.setArtifact(item, i);
                              }}
                            ></i>
                          )}
                          {/* {item.cc_note_batch_ids !== null &&
                            item.cc_note_batch_ids.includes(",") && (
                              <img
                                className="usersGroupIcon"
                                src={groupNoteIcon}
                                alt=""
                                width="25"
                              />
                            )} */}
                          
                        </Col>
                        <Col xs="8" className="text-right pl-0 d-flex justify-content-end">
                          {item.cc_note_show_type !== "note" && (
                            <RedButton
                              variant="outlined"
                              size="small"
                              className="redButton"
                            >
                              <img
                                src={GroupNotes}
                                alt=""
                                className="historyIcon"
                              />
                              <span className="GroupText">Group</span>
                            </RedButton>
                          )}
                          {item.cc_note_batch_ids && item.cc_note_batch_ids.length > 0 && (
                            <>
                              {item.cc_note_batch_ids.includes(",") && (
                                <div className="popovercls">
                                <OverlayTrigger
                                  trigger="click"
                                  placement="right"
                                  overlay={popover}
                                >
                                  <div className="grpnewicon" onClick={() => {
                                      this.studentsOfNote(item, i);
                                    }}>
                                  <img
                                    
                                    className="usersGroupIcond"
                                    src={groupNoteIcon}
                                    alt=""
                                    width="25"
                                  />
                                 <p className="mt-0">Group</p>
                                  </div>
                                </OverlayTrigger>
                                </div>
                              )}
                            </>
                          )}
                          
                          {item.cc_note_reminder === null || item.cc_note_reminder === '' ? (
                            <GreyButton
                            style={{width: "140px",height: "30px", marginTop : "2px", borderRadius:8, border : '0.75px solid #AAAAAA', background : '#AAAAAA1A 0% 0% no-repeat padding-box'}}
                              variant="outlined"
                              size="small"
                              className="greyButton"
                            >
                              <img
                                src={RemiderIconGrey}
                                alt=""
                                className="historyIcon"
                              />
                              <span className="no-reminder">No Reminder</span>
                            </GreyButton>
                          ) : (
                            <PinkButton style={{width: "140px",height: "30px", marginTop : "0px"}} variant="outlined" size="small">
                              <img
                                src={remiderIconPink}
                                alt=""
                                className="historyIcon"
                              />
                              <span className="timeline-time">
                                {getFormattedDate(
                                  item.cc_note_reminder_date,
                                  this.state.formatDate,
                                  this.state.signDate
                                )}{" "}
                                {item.cc_note_reminder === "" || !item.cc_note_reminder || item.cc_note_reminder == 'null'
                                  ? ""
                                  : item.cc_note_reminder}
                                  {/* {item.cc_note_reminder && item.cc_note_reminder.length > 0 && item.cc_note_reminder} */}
                              </span>
                            </PinkButton>
                          )}
                        </Col>
                        {item.note_tag_comments &&
                          item.note_tag_comments.length > 0 && (
                            <Col className="text-left timeline-custom-scrollbarWidth">
                                    {/* Define priority for abbreviations */}
                                    {(() => {
                                  // Extract unique abbreviations
                                  const uniqueAbbreviations = Array?.from(
                                    new Set(
                                      item?.note_tag_comments
                                        .filter(subItem => subItem?.tag_comment_text?.length > 0 && subItem?.cc_tag_abbre)
                                        .map(subItem => formatString(subItem?.cc_tag_abbre).slice(0, 4).trim()) // Ensure trimming
                                    )
                                  );

                                  // Define priority for specific abbreviations
                                  const priority = {
                                    'AT': 1, // Audio Summarization
                                    'AS': 2, // Audio Transcription
                                  };

                                  // Sort abbreviations with priority
                                  const sortedAbbreviations = uniqueAbbreviations?.sort((a, b) => {
                                    const aPriority = priority[a] || 100; // Default to lower priority if not in the priority list
                                    const bPriority = priority[b] || 100;
                                    return aPriority - bPriority;
                                  });

                                  // Debugging information


                                  return sortedAbbreviations?.map((uniqueAbbre, subIndex) => (
                                    <div key={subIndex} style={{ display: "inline-flex" }}>
                                      <span className="tag">
                                        <span className="tag-text" maxLength="4">
                                          {uniqueAbbre}
                                        </span>
                                      </span>

                                    </div>
                                  ));
                                })()}
                               {item.note_attachment.length > 0 && (
                                <span className="attached-tag">
                                  <img src={attachedIcon} alt="" />
                                </span>
                              )}
                                  
                                  </Col>
                            // <Col className="text-left timeline-custom-scrollbarWidth" 
                            // // style={{overflowX: 'scroll', overflowY : 'hidden', width: '100%', marginRight : 20}}
                            // >
                            //   {item.note_tag_comments.map(
                            //     (subItem, subIndex) => (
                            //       <div
                            //         key={subIndex}
                            //         style={{ display: "inline-flex" }}
                            //       >
                            //         {subItem.tag_comment_text.length > 0 &&
                            //           subItem.cc_tag_abbre && (
                            //             <span className="tag">
                            //               <span
                            //                 className="tag-text"
                            //                 maxLength="4"
                            //               >
                            //                 {formatString(
                            //                   subItem.cc_tag_abbre
                            //                 ).slice(0, 4)}
                            //               </span>
                            //             </span>
                            //           )}
                            //       </div>
                            //     )
                            //   )}

                             
                            // </Col>
                          )}
                      </Row>

                      <Row className="divider">
                        <hr></hr>
                      </Row>

                      <Row className="timeline-section">
                        <Col
                          xs="6"
                          className="text-left level-score-section pr-0"
                        >
                          <span>
                            <span className="level-text-b">Level:</span>
                            <span className="level-text-n">
                              {" "}
                              {item.cc_note_level === ""
                                ? "-"
                                : item.cc_note_level}
                            </span>
                          </span>
                          <span className="vertical-divider"></span>
                          <span>
                            <span className="level-text-b">Score:</span>
                            <span className="level-text-n">
                              {item.cc_note_score === ""
                                ? "-"
                                : item.cc_note_score}
                            </span>
                          </span>
                        </Col>
                        <Col xs="6" className="text-right pl-0">
                          {classPermission !== null &&
                          classPermission === "read" ? (
                            <Button
                              variant="primary"
                              className="disabledEdit-btn br-8"
                            >
                              <img
                                src={pencilIcon}
                                alt=""
                                className="disabledEditTimeline-btn-img"
                              />
                              <span className="edit-btn-text">Edit</span>
                            </Button>
                          ) : (
                            <Button
                              variant="primary"
                              onClick={() => {
                                editGroupNote(item)
                                // props.history.push(routes.STUDENTADDNOTE, {
                                //   pageType: "Edit",
                                //   data: item,
                                //   studentInfo: studentInfo,
                                //   academicYear: academicYear,
                                //   student_enrolled_classes:
                                //     student_enrolled_classes,
                                //   NotesData: NoteData,
                                //   StudentAllNoteDetails: StudentAllNoteDetails,
                                //   class_id: class_id,
                                //   directAdd: "directAdd",
                                //   classList: this.state.newClassListData,

      // studentInfo: classGroupNoteStudent,
      // academicYear: props?.history?.location?.state?.academicYear,
      // student_enrolled_classes: groupNotesData?.student_enrolled_classes,
      // NotesData: notesData,
      // StudentAllNoteDetails: data = {groupNotesData},
      // class_id: props?.history?.location?.state?.class_id,
      // classDetails: props?.history?.location?.state?.classDetails,
      // studentsInfo: props?.history?.location?.state?.studentsInfo,
      // selectedStudentsForGroupNote: props?.history?.location?.state?.selectedStudentsForGroupNote,
      // academicYearId : props?.history?.location?.state?.academicYearId,
      // groupDetail : props?.history?.location?.state?.groupDetail,
                                // });
                              }}
                              className="edit-btn br-8"
                            >
                              <img
                                src={pencilIcon}
                                alt=""
                                className="timeline-btn-img"
                              />
                              <span className="edit-btn-text">Edit</span>
                            </Button>
                          )}
                          {classPermission !== null &&
                          classPermission === "read" ? (
                            <Button
                              variant="primary"
                              className="disabledTrash-btn br-8"
                            >
                              <img
                                src={trashIcon}
                                alt=""
                                className="timeline-btn-img"
                              />
                              <span className="edit-btn-text">Delete</span>
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                this.deleteStudentNote(item, i);
                              }}
                              variant="primary"
                              className="trash-btn br-8"
                            >
                              <img
                                src={trashIcon}
                                alt=""
                                className="timeline-btn-img"
                              />
                              <span className="edit-btn-text">Delete</span>
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    /* -----------Norma/ Note-------- */

                    /* -----------Miscellaneous Note-------- */
                    <div>
                      <Row
                        className="timeline-section"
                        style={{ 
                          // minHeight: 46,
                           marginLeft: 1 }}
                      >
                        <span className="level-text-b">
                          {item.cc_misc_note_text}
                        </span>
                      </Row>

                      <Row className="divider">
                        <hr></hr>
                      </Row>

                      <Row className="timeline-section">
                        <Col
                          xs="6"
                          className="text-left level-score-section pr-0"
                        ></Col>
                        <Col xs="6" className="text-right pl-0">
                          <Button
                            onClick={() => {
                              this.editMiscellaneous(item, i);
                            }}
                            variant="primary"
                            className="edit-btn br-8"
                            disabled={classPermission !== null && classPermission === "read" ? true : false}
                            style={{opacity : classPermission !== null && classPermission === "read" && "0.65"}}
                          >
                            <img
                              src={pencilIcon}
                              alt=""
                              className="timeline-btn-img"
                            />
                            <span className="edit-btn-text">Edit</span>
                          </Button>
                          <Button
                            onClick={() => {
                              this.deleteMiscellaneous(item, i);
                            }}
                            variant="primary"
                            className="trash-btn br-8"
                            disabled={classPermission !== null && classPermission === "read" ? true : false}
                            style={{opacity : classPermission !== null && classPermission === "read" && "0.65"}}
                          >
                            <img
                              src={trashIcon}
                              alt=""
                              className="timeline-btn-img"
                            />
                            <span className="edit-btn-text">Delete</span>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {/* -----------Miscellaneous Note-------- */}
                </VerticalTimelineElement>
              );
            })}
        </VerticalTimeline>
        {/* <p style={{textAlign : "center"}} className="note-header-total-std-title"> You have {this.props?.StudentAllNoteDetails?.data?.total_note} {this.props?.StudentAllNoteDetails?.data?.total_note > 1 ? "Notes" : "Note"} and <br />
            {this.props?.StudentAllNoteDetails?.data?.total_misc} Misc {this.props?.StudentAllNoteDetails?.data?.total_misc > 1 ?"Notes" : "Note"} -- Keep Going </p> */}
        {/* {TotalCount > NoteData.length && (
          <div className="sort-miscellaneous-div load-more">
            {TotalCount > NoteData.length ? (
              <Button
                className="add-mili-note-btn add-load-more-short"
                onClick={() => {
                  this.loadMore();
                }}
              >
                <i className="material-icons">autorenew</i>
                <span>Load More</span>
              </Button>
            ) : (
              <img src={loaderImag} alt="" className="loaderIconCss" />
            )}
          </div>
        )} */}

        <Modal
          className="delete-modal-warning br-8"
          show={this.state.show}
          onHide={this.handleClose}
          centered={true}
        >
          <Modal.Header>
            <Modal.Title>Delete Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>*Note contain Attachments and Audio Notes.</p>
            <p className="confirm-text">
              Are you sure you want to delete this note?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="default"
              className="cancel-btn"
              onClick={this.handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="default"
              className="delete-btn"
              onClick={this.handleClose}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state,
  studentData: state.studentNote.students_List_Note,
  GroupNotesLoader: state.studentNote.GroupNotesLoader,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getGroupNoteStudents: (data) => dispatch(getGroupNoteStudents(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupNotesTimeline);
