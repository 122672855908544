

import {
    GET_BLOG_LIST_REQUEST,
    GET_BLOG_LIST_SUCCESS,
    GET_BLOG_LIST_FAILURE,

    LOAD_MORE_BLOG_LIST_REQUEST

} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */



// ----------- send Subscribe Request-------------
export function getBlogListRequest(data) {
    return {
        type: GET_BLOG_LIST_REQUEST,
        data,
    };
}

export function getBlogListSuccess(data) {
    return {
        type: GET_BLOG_LIST_SUCCESS,
        data,
    };
}

export function getBlogListFailure(data) {
    return {
        type: GET_BLOG_LIST_FAILURE,
        data,
    };
}

export function loadMoreBlogListRequest() {
    return {
        type: LOAD_MORE_BLOG_LIST_REQUEST,

    };
}







