import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getClassCoreStandard: ["data"],
  getClassCoreStandardSuccess: ["data"],
  getClassCoreStandardFailure: ["error"],

  getCoreStandard: ["data"],
  getCoreStandardSuccess: ["data"],
  getCoreStandardFailure: ["error"],

  addClassCoreStandard: ["data"],
  addClassCoreStandardSuccess: ["data"],
  addClassCoreStandardFailure: ["error"],

  getNoteSpiralTag: ["data"],
  getNoteSpiralTagSuccess: ["data"],
  getNoteSpiralTagFailure: ["error"],

  deleteCustomStandards: ["data"],
  deleteCustomStandardsError: ["data"],
  deleteCustomStandardsSuccess: ["data"],

  editCustomStandards: ["data"],
  editCustomStandardsError: ["data"],
  editCustomStandardsSuccess: ["data"],
});
export const UserClassStandardReducer = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  class_Core_Standard: null,
  core_Standard: null,
  add_Class_Core_Standard: null,
  core_sub_standard: null,
  core_standard_description: null,
  error: null,
  exists: null,
  loader: null,
  speedTagloader: null,
  action: null,
  get_note_spiral_tag: null,
  deleteCustomStandardsData: null,
  deleteLoader: false,
  editLoader: false,
  editCustomStandards: null,
});

/* ------------- Reducers ------------- */
/* getNotSelectedRosters */
export const getClassCoreStandard = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    class_Core_Standard: null,
    loader: true,
    editLoader: false,
    editCustomStandards: null,
  };
};
export const getClassCoreStandardSuccess = (state, action) => {
  const { data } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    class_Core_Standard: data.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};
export const getClassCoreStandardFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error,
    exists: false,
    action: "GET_CLASS_CORE_STANDARD_FAILURE",
    loader: false,
  };
};

/* deleteClassTag */

export const getCoreStandard = (state, action) => {
  return {
    ...state,
    fetching: true,
    error: null,
    core_Standard:
      (action?.data?.fromDashboard && action?.data?.main_id > 0) ||
      action?.data?.fromImport
        ? state.core_Standard
        : null,
    core_standard_description: null,
    // core_sub_standard: null,
    core_sub_standard:
      action?.data?.fromDashboard &&
      action?.data?.main_id > 0 &&
      action?.data?.group_id > 0
        ? state.core_sub_standard
        : null,
    loader: true,
  };
};
export const getCoreStandardSuccess = (state, action) => {
  const { data } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    core_Standard:
      action?.data?.fromDashboard && action?.data?.mainId > 0
        ? state?.core_Standard
        : action?.data?.data,
    core_sub_standard:
      action?.data?.fromDashboard &&
      action?.data?.mainId > 0 &&
      action?.data?.groupId > 0
        ? state?.core_sub_standard
        : action?.data?.mainDecode?.data,
    // core_sub_standard: action?.data?.mainDecode?.data,
    core_standard_description: action?.data?.mainDecode?.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};
export const getCoreStandardFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error,
    exists: false,
    action: "GET_CORE_STANDARD_FAILURE",
    loader: false,
  };
};

export const deleteCustomStandards = (state, action) => {
  return {
    ...state,
    deleteLoader: true,
    deleteCustomStandardsData: null,
    error: null,
  };
};

export const deleteCustomStandardsError = (state, action) => {
  return {
    ...state,
    deleteLoader: false,
    deleteCustomStandardsData: null,
    error: action.data,
  };
};

export const deleteCustomStandardsSuccess = (state, action) => {
  return {
    ...state,
    deleteLoader: false,
    deleteCustomStandardsData: action.data,
    error: null,
  };
};

export const editCustomStandards = (state, action) => {
  return {
    ...state,
    editLoader: true,
    editCustomStandards: null,
    error: null,
  };
};
export const editCustomStandardsError = (state, action) => {
  return {
    ...state,
    editLoader: false,
    editCustomStandards: null,
    error: action.data,
  };
};

export const editCustomStandardsSuccess = (state, action) => {
  return {
    ...state,
    editLoader: false,
    editCustomStandards: action.data,
    error: null,
  };
};

/* deleteClassTag */

export const addClassCoreStandard = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    add_Class_Core_Standard: null,
    loader: true,
  };
};
export const addClassCoreStandardSuccess = (state, action) => {
  const { data } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    add_Class_Core_Standard: data.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};
export const addClassCoreStandardFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error,
    exists: false,
    action: "ADD_CLASS_CORE_STANDARD_FAILURE",
    loader: false,
  };
};

/*-----------getSpiral Note---------------------------*/
export const getNoteSpiralTag = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    get_note_spiral_tag: null,
    loader: true,
  };
};
export const getNoteSpiralTagSuccess = (state, action) => {
  console.group("action", action);
  const { data } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    get_note_spiral_tag: data,
    action: action.type,
    exists: true,
    loader: false,
  };
};
export const getNoteSpiralTagFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error,
    exists: false,
    action: "GET_NOTE_SPIRAL_TAG_FAILURE",
    loader: false,
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CLASS_CORE_STANDARD]: getClassCoreStandard,
  [Types.GET_CLASS_CORE_STANDARD_SUCCESS]: getClassCoreStandardSuccess,
  [Types.GET_CLASS_CORE_STANDARD_FAILURE]: getClassCoreStandardFailure,

  [Types.GET_CORE_STANDARD]: getCoreStandard,
  [Types.GET_CORE_STANDARD_SUCCESS]: getCoreStandardSuccess,
  [Types.GET_CORE_STANDARD_FAILURE]: getCoreStandardFailure,

  [Types.ADD_CLASS_CORE_STANDARD]: addClassCoreStandard,
  [Types.ADD_CLASS_CORE_STANDARD_SUCCESS]: addClassCoreStandardSuccess,
  [Types.ADD_CLASS_CORE_STANDARD_FAILURE]: addClassCoreStandardFailure,

  [Types.GET_NOTE_SPIRAL_TAG]: getNoteSpiralTag,
  [Types.GET_NOTE_SPIRAL_TAG_SUCCESS]: getNoteSpiralTagSuccess,
  [Types.GET_NOTE_SPIRAL_TAG_FAILURE]: getNoteSpiralTagFailure,

  [Types.DELETE_CUSTOM_STANDARDS]: deleteCustomStandards,
  [Types.DELETE_CUSTOM_STANDARDS_SUCCESS]: deleteCustomStandardsSuccess,
  [Types.DELETE_CUSTOM_STANDARDS_ERROR]: deleteCustomStandardsError,

  [Types.EDIT_CUSTOM_STANDARDS]: editCustomStandards,
  [Types.EDIT_CUSTOM_STANDARDS_SUCCESS]: editCustomStandardsSuccess,
  [Types.EDIT_CUSTOM_STANDARDS_ERROR]: editCustomStandardsError,
});
