import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Modal from "react-bootstrap/Modal";
import { gapi } from "gapi-script";
import eye from "Assets/AttendanceModule/eye.svg";
import downloadIcon from "Assets/AttendanceModule/downloadIcon.svg";
import cancelBlack from "Assets/RosterModule/cancelBlack.png";
import localFolderIcon from "Assets/RosterModule/localFolder.png";
import editIcon from "Assets/AttendanceModule/editIcon.png";
import rightArrow from "Assets/AttendanceModule/rightArrow.svg";
import deleteIcon from "Assets/AttendanceModule/delete.svg";
import importIcon from "Assets/AttendanceModule/importIcon.png";
import googleDriveIcon from "Assets/RosterModule/googleDrive.png";
import cancelIcon from "Assets/AttendanceModule/cancel.svg";
import ReactFileReader from "react-file-reader";
import loaderImag from "Assets/ProfileModule/PepperyMedium.gif";
import back from "Assets/CommonComponent/back.png";
import warningIcon from "Assets/NoteModule/warning-icon.png";
import "./standardDashboard.scss";
import StandardContent from "./StandardContent";
import { createNotification } from "Config/notificationtoast";
import { storage } from "../../firebase/firebase";
import { useDispatch, useSelector } from "react-redux";
import { getCoreStandard } from "Actions/ClassAction/ClassStandardsAction";
import DivLoader from "Components/LoadingComponent/DivLoader";
import {
  deleteCustomStandards,
  editCustomStandards,
  importCustomStandards,
} from "Actions/CoreStandardAction/coreStandardAction";
import { ModalTitle } from "react-bootstrap";
import { FirebseStroageFolder, firebaseBucket } from "Config/Constant";
import CommonTextField from "Components/CommonTextField/CommonTextField";
import driveFolderIcon from "Assets/RosterModule/folder.png";
import driveCSVIcon from "Assets/RosterModule/filecsv_icon.png";
import closeIcon from "Assets/NoteModule/close.svg";
import tickIcon from "Assets/NoteModule/tick.svg";
import axios from "axios";

const AllStandardDashboard = (props) => {

  const API_KEY = "AIzaSyCvx39xeCHINDBjRnPEkqE6hRanaWHArsQ";
  const CLIENT_ID =
    "879480850061-996aun73d2hici3kip12k3ejia466ee3.apps.googleusercontent.com";
  // export const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];
  // export const PROJECTID = 'np-chr-dev-mgmt-01';
  // export const SCOPES = 'https://www.googleapis.com/auth/drive'
  // Client ID and API key from the Developer Console
  // const CLIENT_ID = "321808758658-4pqh206hsjrplfbev6qv27u246ns6m6o.apps.googleusercontent.com";
  // const API_KEY = "AIzaSyB4Eo2E8PbjRcJYF5KN4FmyFXze3HtR8Qs";

  // Array of API discovery doc URLs for APIs
  const DISCOVERY_DOCS = [
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
  ];
  const SCOPES = "https://www.googleapis.com/auth/drive";
  // const SCOPES = "https://www.googleapis.com/auth/cloud-platform";

  const { history } = props;
  const dispatch = useDispatch();
  const [showSubTabs, setShowSubTabs] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [defaultClicked, setDefaultClicked] = useState(false);
  const [UploadFrom, setUploadFrom] = useState("drive");
  const [loadMoreCount, setLoadMoreCount] = useState(1);
  const [loadMoreCountStatus, setLoadMoreCountStatus] = useState(false);
  const [standardObj, setstandardObj] = useState(null);
  const [filter, setFilter] = useState("both");
  const [title, setTitle] = useState("");
  const [mainId, setMainId] = useState("");
  const [groupId, setGroupId] = useState("");
  const [gradeId, setGradeId] = useState("");
  const [standardId, setStandardId] = useState("");
  const [uploadFile, setUploadFile] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddStandardModal, setShowAddStandardModal] = useState(false)
  const [addStandardValue, setAddStandardValue] = useState('append')
  const [addStandardInFirebaseUrl, setAddStandardInFirebaseUrl] = useState('')
  const [importedFileHeader, setImportedFileHeader] = useState([])
  const [customStandardFileHeader, setCustomStandardFileHeader] = useState([])
  const [importFileFromFileDrive, setImportFileFromFileDrive] = useState(null)
  //google drive
  const [listDocumentsVisible, setListDocumentsVisibility] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = useState(false);
  const [isFetchingGoogleDriveFiles, setIsFetchingGoogleDriveFiles] =
    useState(false);
  const [signedInUser, setSignedInUser] = useState();
  // const [GoogleAuth, setGoogleAuth] = useState(false);
  // const [GoogleAuthItem, setGoogleAuthItem] = useState(false);
  const [GoogleAuth, setGoogleAuth] = useState(
    sessionStorage?.getItem("GoogleAuth")
      ? JSON.parse(sessionStorage?.getItem("GoogleAuth"))
      : false
  );
  const [GoogleAuthItem, setGoogleAuthItem] = useState(
    sessionStorage.getItem("GoogleAuthItem") &&
      sessionStorage.getItem("GoogleAuthItem")?.length > 0
      ? sessionStorage.getItem("GoogleAuthItem") == "undefined"
        ? false
        : JSON.parse(sessionStorage.getItem("GoogleAuthItem"))
      : false
  );
  const [driveEmail, setDriveEmail] = useState("");
  const [googleSelectedFile, setGoogleSelectedFile] = useState("");
  const [SelectFileModal, setSelectFileModal] = useState(false);
  const [importLoader, setImportLoader] = useState(false);
  const [googleDrivePath, setGoogleDrivePath] = useState([]);
  const [GetFileLoader, setGetFileLoader] = useState(false);
  const [GoogleFiles, setGoogleFiles] = useState([]);

  // let driveEmail = ''
  useEffect(() => {
    sessionStorage.setItem("backpage2" , "smthelse")
    }, [])
  useEffect(() => {
    if (GoogleAuthItem?.hasOwnProperty("cu")) {
      // driveEmail = GoogleAuthItem.cu
      setDriveEmail(GoogleAuthItem?.cu);
    } else if (GoogleAuthItem?.hasOwnProperty("Qt")) {
      // driveEmail = GoogleAuthItem.Qt.zu.replace(/[@]/g, " @");
      setDriveEmail(GoogleAuthItem?.cu);
    }
  }, [GoogleAuthItem]);

  const listFiles = (searchTerm = null) => {
    setSelectFileModal(true);
    setGetFileLoader(true);
    setIsFetchingGoogleDriveFiles(true);
    gapi.client.drive.files
      .list({
        pageSize: 1000,
        // fields: "nextPageToken, files(id, name, mimeType, modifiedTime)",
        fields:
          "nextPageToken, files(id,size, name, mimeType, fileExtension, modifiedTime, webViewLink)",
        q: searchTerm,
      })
      .then(function (response) {
        setIsFetchingGoogleDriveFiles(false);
        setListDocumentsVisibility(true);
        const res = JSON.parse(response.body);
        setDocuments(res.files);
        // setGoogleFiles(res?.files)
        let GoogleFiles = [];
        for (let item of res.files) {
          if (item.fileExtension) {
            if (item.fileExtension === "csv") {
              GoogleFiles.push(item);
            }
          }
          const type = item.mimeType;
          const typeCheck = type.split(".");
          if (typeCheck[typeCheck.length - 1] === "folder") {
            item.fileExtension = "folder";
            GoogleFiles.push(item);
          }
        }
        setGoogleFiles(GoogleFiles);
        setGetFileLoader(false);
        // setSelectFileModal(true)
      });
  };

  /**
   *  Sign in the user upon button click.
   */
  const handleAuthClick = (event) => {
    gapi.auth2.getAuthInstance().signIn();
  };

  /**
   *  Called when the signed in status changes, to update the UI
   *  appropriately. After a sign-in, the API is called.
   */
  const updateSigninStatus = (isSignedIn) => {
    if (isSignedIn) {
      // Set the signed in user
      let some = gapi.auth2.getAuthInstance();
      setSignedInUser(gapi.auth2.getAuthInstance().currentUser.le.wt);
      setIsLoadingGoogleDriveApi(false);
      // list files if user is authenticated
      listFiles();
    } else {
      // prompt user to sign in
      handleAuthClick();
    }
  };

  /**
   *  Sign out the user upon button click.
   */
  const handleSignOutClick = (event) => {
    // setListDocumentsVisibility(false);
    // console.log(gapi)
    // gapi.auth2.getAuthInstance().signOut();
    try {
      gapi.load("client:auth2", () => {
        gapi.client
          .init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES,
            immediate: false,
          })
          .then((res) => {
            gapi.auth2.getAuthInstance().disconnect();
            setGoogleAuth(false);
            sessionStorage.setItem("GoogleAuth", false);
            setGoogleAuthItem(undefined);
            sessionStorage.setItem("GoogleAuthItem", false);
          });
      });
    } catch (error) {
      console.log(error);
    }
  };

  /**
   *  Initializes the API client library and sets up sign-in state
   *  listeners.
   */
  const initClient = () => {
    setIsLoadingGoogleDriveApi(true);
    gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .then(
        function (res) {
          // Listen for sign-in state changes.
          gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

          // Handle the initial sign-in state.
          updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
        },
        function (error) {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    if (signedInUser) {
      try {
        setGoogleAuth(true);
        setGoogleAuthItem(signedInUser);
        sessionStorage.setItem("GoogleAuth", true);
        sessionStorage.setItem("GoogleAuthItem", JSON.stringify(signedInUser));
      } catch (e) {
        createNotification("error", "Something Went Wrong, Not able Login");
        return;
      }
    }
  }, [documents, signedInUser]);

  const handleClientLoad = () => {
    gapi.load("client:auth2", initClient);
  };

  // ----------To Get File From Path ---------
  const getFolderFilesPath = (item) => {
    let GoogleDrivePath = googleDrivePath;
    let CreatePath = [];
    const containsId = GoogleDrivePath.some(
      (dataItem) => dataItem.id === item.id
    );
    // if (!containsId) {
    //   const path = {
    //     name: item.name,
    //     id: item.id,
    //   };
    //   CreatePath.push(path);
    //   setGoogleDrivePath(CreatePath);
    //   getFolderFiles(item);
    // } else {
    //   let filterData = GoogleDrivePath.filter((driveItem) => {
    //     return driveItem.id == item.id
    //   })
    //   console.log(filterData)
    //   setGoogleDrivePath(filterData);
    //   getFolderFiles(item);
    // }
    // if (!containsId) {
    //   const path = {
    //     name: item.name,
    //     id: item.id,
    //   };
    //   CreatePath.push(path);
    // }
    for (let value of GoogleDrivePath) {
      if (item.id === value.id) {
        break;
      } else {
        const path = {
          name: value.name,
          id: value.id,
        };
        CreatePath.push(path);
      }
    }
    setGoogleDrivePath(CreatePath);
    getFolderFiles(item);
  };
  // ----------To Get File From Path ---------

  // ----------To Get File From Folder ---------
  const getFolderFiles = (value) => {
    try {
      gapi.load("client:auth2", async () => {
        let query = `'${value.id}'  in parents and trashed=false`;
        let path = {
          name: value.name,
          id: value.id,
        };
        let googleDrivePathData = googleDrivePath;
        googleDrivePathData.push(path);
        const uniqueArray = Array.from(
          new Set(googleDrivePathData.map((item) => item.id))
        ).map((id) => {
          return googleDrivePathData.find((item) => item.id === id);
        });
        const finalBreadCrumbArr = uniqueArray.slice(
          0,
          uniqueArray.findIndex((item) => item.id === path.id) + 1
        );
        setSelectFileModal(true);
        setGetFileLoader(true);
        setGoogleSelectedFile("");
        setGoogleFiles([]);
        setGoogleDrivePath(finalBreadCrumbArr);
        await gapi.client
          .init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            scope: SCOPES,
            discoveryDocs: DISCOVERY_DOCS,
          })
          .then(() => {
            gapi.client.drive.files
              .list({
                pageSize: 1000,
                fields:
                  "nextPageToken, files(id,size, name, mimeType, fileExtension, modifiedTime, webViewLink)",
                q: query,
              })
              .then((res) => {
                let GoogleFiles = [];
                for (let item of res.result.files) {
                  if (item.fileExtension) {
                    if (item.fileExtension === "csv") {
                      GoogleFiles.push(item);
                    }
                  }
                  const type = item.mimeType;
                  const typeCheck = type.split(".");
                  if (typeCheck[typeCheck.length - 1] === "folder") {
                    item.fileExtension = "folder";
                    GoogleFiles.push(item);
                  }
                }
                setGoogleFiles(GoogleFiles);
                setGetFileLoader(false);
              });
          });
      });
    } catch (e) {
      setGoogleFiles([]);
      setGetFileLoader(false);
    }
  };
  // ----------To Get File From Folder ---------

  // ----------To Get File Read from Drive ---------
  const getFileDetails = () => {
    setUploadFile(true);
    const data = JSON.parse(sessionStorage.getItem("UserData"));
    const FinfFolderNameArray = data.cc_user_email_id.split("@");
    const FolderName = FinfFolderNameArray[0];
    try {
      // setImportLoader(true);
      gapi.load("client:auth2", () => {
        let data = googleSelectedFile;
        gapi.client
          .init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            scope: SCOPES,
            discoveryDocs: DISCOVERY_DOCS,
          })
          .then(async () => {
            const file = data;
            const url = `https://www.googleapis.com/drive/v3/files/${data.id}?alt=media`;
            if (file.webViewLink) {
              var accessToken = gapi.auth.getToken().access_token;
              var xhr = new XMLHttpRequest();
              xhr.open("GET", url);
              xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
              xhr.responseType = "blob";
              xhr.onload = () => {
                if (xhr.status === 200) {
                  const blob = xhr.response;
                  // const str = xhr.responseText;
                  setImportLoader(false);
                  try {
                    if (blob.size === 0) {
                      createNotification(
                        "error",
                        "Selected file is empty. Please select another csv file having content in it"
                      );
                      setGoogleSelectedFile("");
                      return;
                    }

                    const reader = new FileReader();
                    reader.onload = () => {
                      const fileContent = reader.result;

                      // Convert the Blob to a File object (assuming you have a valid name and type)
                      const fileObj = new File([blob], data.name, {
                        type: data.mimeType,
                      });
                     

                      const str = new TextDecoder().decode(fileContent);
                      const reg = new RegExp(/(\r\n?|\n|\t)/g);
                      const student_Data = str.replace(reg, ",#*#");
                      if(customStandards?.length > 0) {
                      const csvHeader = str.slice(0, str.indexOf("\n")).split(",");
                      const csvRows = str.slice(str.indexOf("\n") + 1).split("\n");
                      const array = csvRows.map(i => {
                        const values = i.split(",");
                        const obj = csvHeader.reduce((object, header, index) => {
                          object[header] = values[index];
                          return object;
                        }, {});
                        return obj;
                      });
                      let headerArray = [...new Set(array.map((item) => item?.AKS1))]
                      
                      let customStandardHeaderArray = [...new Set(customStandards?.map((item) => item?.core_standards_text))]
                      
              
                       // Find common strings
                      const commonStrings = headerArray.filter(item => customStandardHeaderArray.includes(item));
                      
                      if(commonStrings?.length > 0) {
                        setShowAddStandardModal(true)
                        setImportFileFromFileDrive(fileObj)
                        setSelectFileModal(false)
                        window.$("#myModalupload").modal("hide");
                        setUploadFile(false)
                      }
                    } else {
                      const uploadTask = storage
                        .ref(
                          `${FirebseStroageFolder}/${FolderName}/standard/custom_standards.csv`
                        )
                        .put(fileObj);
                      uploadTask.on(
                        "state_changed",
                        (snapShot) => {},
                        (err) => {
                          createNotification(
                            "success",
                            "The file was not uploaded. Please try again."
                          );
                        },
                        () => {
                          storage
                            .ref(
                              `${FirebseStroageFolder}/${FolderName}/standard/`
                            )
                            .child("custom_standards.csv")
                            .getDownloadURL()
                            .then((fireBaseUrl) => {
                              importCSVFile(
                                `${firebaseBucket}/${FirebseStroageFolder}/${FolderName}/standard/custom_standards.csv`
                              );
                            });
                        }
                      );
                    }
                    };
                    reader.readAsArrayBuffer(blob);
                  } catch (e) {
                    createNotification(
                      "error",
                      "Something Went Wrong, Not able to read File"
                    );
                    return;
                  }
                } else {
                  createNotification(
                    "error",
                    "Something Went Wrong, Not able to fetch file Detail"
                  );
                  setGetFileLoader(false);
                  setImportLoader(false);
                  // this.setState({
                  //     GetFileLoader: false,
                  //     importLoader: false
                  // })
                  return;
                }
              };
              xhr.onerror = function () {};
              xhr.send();
            } else {
              createNotification(
                "error",
                "Something Went Wrong, Not able to fetch file Detail"
              );
              setGetFileLoader(false);
              setImportLoader(false);
              // this.setState({
              //     GetFileLoader: false,
              //     importLoader: false
              // })
              return;
            }
          });
      });
    } catch (e) {
      createNotification(
        "error",
        "Something Went Wrong, Not able to fetch file Detail"
      );
      setGetFileLoader(false);
      setImportLoader(false);
      // this.setState({
      //     GetFileLoader: false,
      //     importLoader: false
      // })
      return;
    }
  };
  // ----------To Get File Read from Drive ---------

  const handleFileLoad = () => {
    if (UploadFrom == "drive") {
      const path = {
        name: "My drive",
        id: "root",
      };
      // handleClientLoad();
      getFolderFiles(path);
    } else {
      handleImportFiles();
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEditModalClose = () => setShowEditModal(false);
  const handleEditModalShow = () => setShowEditModal(true);

  const handleAllStandardModalClose = () => setShowAddStandardModal(false);
  const handleAllStandardModalShow = () => setShowAddStandardModal(true);

    //set radio value
    const standardValueHandler = (event) => {
      setAddStandardValue(event.target.value);
    };

  useEffect(() => {
    let apiData = {
      main_id: mainId,
      group_id: groupId,
      grade_id: gradeId,
      standard_id: standardId,
      fromDashboard: true,
    };
    dispatch(getCoreStandard(apiData));
  }, [mainId, groupId, gradeId, standardId]);

  const stateData = useSelector((state) => console.log(state));

  const allCoreStandards = useSelector(
    (state) => state?.classStandard?.core_Standard
  );
  const defaultStandards = allCoreStandards?.filter(
    (item) => item?.main_id < 3
  );
  const customStandards = allCoreStandards?.filter((item) => item?.main_id > 3);
  

  const coreSubStandard = useSelector(
    (state) => state?.classStandard?.core_sub_standard
  );

  

  const coreStandardDescription = useSelector(
    (state) => state?.classStandard?.core_standard_description
  );

  const deleteStandardSuccess = useSelector(
    (state) => state?.classStandard?.deleteCustomStandardsData
  );

  const importStandardSuccess = useSelector(
    (state) => state?.UserCoreStandard?.importCustomStandardData
  );

  const editCustomStandardSuccess = useSelector(
    (state) => state?.classStandard?.editCustomStandards
  );
  const editStandardLoader = useSelector(
    (state) => state?.classStandard?.editLoader
  );

  const importCoreStandardLoader = useSelector((state) => state?.UserCoreStandard?.importLoader)
   console.log('importCoreStandardLoader :>> ', importCoreStandardLoader);
  useEffect(() => {
    if (deleteStandardSuccess?.status == 200) {
      setShowSubTabs(false);
      setShowContent(false);
      setDefaultClicked(false);
      setShow(false);
      setGroupId("");
      setGradeId("");
      setMainId("");
    }
  }, [deleteStandardSuccess]);

  useEffect(() => {
    if (editCustomStandardSuccess?.status == 200) {
      setShowSubTabs(false);
      setShowContent(false);
      setDefaultClicked(false);
      setShowEditModal(false);
      setGroupId("");
      setGradeId("");
      setMainId("");
    }
  }, [editCustomStandardSuccess]);

  useEffect(() => {
    if(!importCoreStandardLoader) {
      setUploadFile(false);
      setSelectFileModal(false);
      setGoogleDrivePath([]);
      setGoogleSelectedFile("");
      setGoogleFiles([]);
      setShowAddStandardModal(false)
setAddStandardValue('append')
setAddStandardInFirebaseUrl('')
setImportedFileHeader([])
setImportFileFromFileDrive(null)
    }
  },[importCoreStandardLoader])

  const BackTOHome = () => {
    history.goBack();
  };

  // --------- handleImportFiles Roster  -------
  const handleImportFiles = (files) => {
    setUploadFile(true);
    setImportFileFromFileDrive(files)
    
    if(customStandards?.length > 0) {
      setUploadFile(false);
    let headerArray = []
    var reader = new FileReader();
    reader.onload = function(e) {
        // Use reader.result
        // alert(reader.result)
        const csvHeader = reader.result.slice(0, reader.result.indexOf("\n")).split(",");
        const csvRows = reader.result.slice(reader.result.indexOf("\n") + 1).split("\n");
        
        const array = csvRows.map(i => {
          const values = i.split(",");
          const obj = csvHeader.reduce((object, header, index) => {
            object[header] = values[index];
            return object;
          }, {});
          
          return obj;
        });
    
        // setArray(array);
        
       headerArray = [...new Set(array.map((item) => item?.AKS1))]
        
        setImportedFileHeader(headerArray)
        let customStandardHeaderArray = [...new Set(customStandards?.map((item) => item?.core_standards_text))]
        

         // Find common strings
        const commonStrings = headerArray.filter(item => customStandardHeaderArray.includes(item));
        
        if(commonStrings?.length > 0) {
          setShowAddStandardModal(true)
          window.$("#myModalupload").modal("hide");
        }

    }
    reader.readAsText(files[0]);
  } else {

 
    
    const data = JSON.parse(sessionStorage.getItem("UserData"));
    const docAsFile = files[0];

    const media_Size = docAsFile.size / 1000000;
    // ---------Media Type-------
    const mediaTypeArray = docAsFile.type.split("/");
    const mediaType = mediaTypeArray[1];
    // ---------Media Type-------

    // ---------Image Type-------
    const ImageTypeArray = docAsFile.name.split(".");

    // ---------FolderName-------
    const FinfFolderNameArray = data.cc_user_email_id.split("@");
    const FolderName = FinfFolderNameArray[0];



    const uploadTask = storage
      .ref(
        `${FirebseStroageFolder}/${FolderName}/standard/custom_standards.csv`
      )
      .put(docAsFile);
    uploadTask.on(
      "state_changed",
      (snapShot) => {},
      (err) => {
        createNotification(
          "success",
          "The file was not uploaded. Please try again."
        );
      },
      () => {
        storage
          .ref(`${FirebseStroageFolder}/${FolderName}/standard/`)
          .child("custom_standards.csv")
          .getDownloadURL()
          .then((fireBaseUrl) => {
            
            setAddStandardInFirebaseUrl(`${firebaseBucket}/${FirebseStroageFolder}/${FolderName}/standard/custom_standards.csv`)
            importCSVFile(
              `${firebaseBucket}/${FirebseStroageFolder}/${FolderName}/standard/custom_standards.csv`
            );
          });
      }
    );
  }
  };
  // --------- handleImportFiles Roster  -------

  // ----------To Send File Read Data In API ---------
  const importCSVFile = (value) => {
    let data = {
      core_standard_data: value,
    };
    dispatch(importCustomStandards(data));
  };

  useEffect(() => {
    if (importStandardSuccess?.status == 200) {
      window.$("#myModalupload").modal("hide");
      setUploadFile(false);
      setSelectFileModal(false);
      setGoogleDrivePath([]);
      setGoogleSelectedFile("");
      setGoogleFiles([]);
      setShowAddStandardModal(false)
setAddStandardValue('append')
setAddStandardInFirebaseUrl('')
setImportedFileHeader([])
setImportFileFromFileDrive(null)
setGroupId("");
      setGradeId("");
      setMainId("");
    }
  }, [importStandardSuccess]);

  // ----------To Send File Read Data In API ---------

  //------------download template------------
  const downloadTemplateHandler = async () => {
    try {
      const automationRef = storage.ref("/template/custom_standards.csv");
      const fireBaseUrl = await automationRef.getDownloadURL();
      let element = document.createElement("a");
      element.style.display = "none";
      element.setAttribute("href", fireBaseUrl);
      element.setAttribute("target", "_blank");
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      console.error("Error downloading template:", error);
    }
  };

  //---------sub standards-------------
  const customSubStandardsHandler = (value) => {
    
  };

  const deleteStandardHandler = () => {
    
    let data = {
      main_id: standardObj?.main_id,
    };
    dispatch(deleteCustomStandards(data));
  };

  const editStandardHandler = () => {
    
    let data = {
      previous_core_standards_text: standardObj?.core_standards_text,
      core_standard_data: title
    };
    dispatch(editCustomStandards(data));
  };

  const addStandardFromModalHandler = () => {
    setUploadFile(true)
    const data = JSON.parse(sessionStorage.getItem("UserData"));
    const docAsFile = importFileFromFileDrive?.[0] || importFileFromFileDrive
    
    const media_Size = docAsFile.size / 1000000;
    // ---------Media Type-------
    const mediaTypeArray = docAsFile.type.split("/");
    const mediaType = mediaTypeArray[1];
    // ---------Media Type-------

    // ---------Image Type-------
    const ImageTypeArray = docAsFile.name.split(".");

    // ---------FolderName-------
    const FinfFolderNameArray = data.cc_user_email_id.split("@");
    const FolderName = FinfFolderNameArray[0];

    

    const uploadTask = storage
      .ref(
        `${FirebseStroageFolder}/${FolderName}/standard/custom_standards.csv`
      )
      .put(docAsFile);
    uploadTask.on(
      "state_changed",
      (snapShot) => {},
      (err) => {
        createNotification(
          "success",
          "The file was not uploaded. Please try again."
        );
      },
      () => {
        storage
          .ref(`${FirebseStroageFolder}/${FolderName}/standard/`)
          .child("custom_standards.csv")
          .getDownloadURL()
          .then((fireBaseUrl) => {
            
            setAddStandardInFirebaseUrl(`${firebaseBucket}/${FirebseStroageFolder}/${FolderName}/standard/custom_standards.csv`)
            
    let payloadData = {
      core_standard_data: `${firebaseBucket}/${FirebseStroageFolder}/${FolderName}/standard/custom_standards.csv`,
      updated_type : addStandardValue
    };
    dispatch(importCustomStandards(payloadData));
          });
      }
    );

  }

  return (
    <React.Fragment>
      <div className={"attendance-select-class-main-container"}>
        <div className="header-main-container">
          <div className="header-breadcrumb-main-container">
            <div className="header-breadcrumb-back-btn-div">
              <img
                className="header-breadcrumb-back-btn-img"
                src={back}
                alt=""
                onClick={BackTOHome}
              />
            </div>
            <div className="header-breadcrumb-inner-div">
              <div className="breadcrumb-inner-flex-div">
                <p
                  className="breadcrumb-academic-year-text"
                  onClick={BackTOHome}
                >
                  {props?.location?.state?.data?.academic_year}
                </p>
                <i className="breadcrumb-arrow-icon material-icons">
                  chevron_right
                </i>
                <p className="breadcrumb-attendance-text">Manage Standards</p>
              </div>
            </div>
          </div>

          <div className="header-view-report-main-container standardBtnContainer">
            <Button
              className="template-button-container align-items-center"
              style={{
                border: "solid 1px #669FED !important",
                marginRight: 13,
              }}
              onClick={() => {
                downloadTemplateHandler();
              }}
            >
              <img className="report-btn-icon" src={downloadIcon} alt="" />
              <span className="report-btn-text">Download Template</span>
            </Button>

            <Button
              className="report-btn-container align-items-center"
              data-toggle="modal"
              // data-target="#myModalupload"
              data-target={
                JSON.parse(sessionStorage.getItem("UserData")).user_type ==
                "school_teacher"
                  ? ""
                  : "#myModalupload"
              }
              disabled={showSubTabs}
            >
              <img
                className="report-btn-icon"
                src={importIcon}
                alt=""
                style={{ marginTop: 0 }}
              />
              <span className="report-btn-text"> Import </span>
            </Button>
          </div>
        </div>

        <div className="row">
          <div
            className="col-12 d-flex justify-content-end mt-2"
            style={{ paddingRight: 36 }}
          >
            <Button
              variant="default"
              className={`delete-custom-standard-btn mr-3 align-items-center justify-content-center ${
                defaultClicked ? "d-none" : "d-flex"
              }`}
              style={{ borderRadius: "8px", width: 100 }}
              //   onClick={() => {
              //     deleteStudentHandler(selectStudentId, selectIndex);
              //   }}
              disabled={!showSubTabs}
              // onClick={() => {
              //   setIsDeleted(true)
              // }}

              onClick={handleShow}
            >
              <img
                className="report-btn-icon"
                src={deleteIcon}
                alt=""
                style={{ marginTop: 0, width: "0.9rem" }}
              />
              <span
                className="report-btn-text"
                style={{ marginLeft: "0.5rem" }}
              >
                {" "}
                Delete{" "}
              </span>
            </Button>

            <Button
              variant="default"
              className={`edit-standard-btn-container align-items-center ${
                defaultClicked && "d-none"
              }`}
              style={{ borderRadius: "8px" }}
              //   onClick={() => {
              //     deleteStudentHandler(selectStudentId, selectIndex);
              //   }}
              disabled={!showSubTabs}
              onClick={handleEditModalShow}
            >
              <img
                className="report-btn-icon"
                src={editIcon}
                alt=""
                style={{ marginTop: 0 }}
              />
              <span className="report-btn-text"> Edit </span>
            </Button>
          </div>

          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 mt-5 pr-0">
            {showSubTabs ? (
              <>
                <Card
                  className="subStandardsCard text-left"
                  style={{
                    borderRadius: 8,
                    height: "80vh",
                    overflowY: "scroll",
                  }}
                >
                  <CardHeader
                    className="backToSatndardHeader d-flex align-items-center justify-content-center cursor-pointer"
                    onClick={() => {
                      setShowSubTabs(false);
                      setShowContent(false);
                      setDefaultClicked(false);
                      // setMainId('')
                      setGroupId("");
                      setGradeId("");
                    }}
                  >
                    <img src={rightArrow} alt="back" className="mr-2" /> Back to
                    Standards
                  </CardHeader>
                  <ListGroup variant="flush">
                    {coreSubStandard ? (
                      coreSubStandard?.length > 0 ? (
                        coreSubStandard?.map((coreSubStandardItem) => {
                          return (
                            <ListGroupItem
                              key={coreSubStandardItem?.group_id}
                              className="d-flex align-items-center justify-content-between cursor-pointer"
                              onClick={() => {
                                setShowSubTabs(true);
                                setShowContent(true);
                                setGroupId(coreSubStandardItem?.group_id);
                                setGradeId(coreSubStandardItem?.grade_id);
                              }}
                            >
                              {coreSubStandardItem?.core_standards_text}{" "}
                              <i className="breadcrumb-arrow-icon material-icons">
                                chevron_right
                              </i>
                            </ListGroupItem>
                          );
                        })
                      ) : (
                        <CardBody>
                          <CardText className="text-center">No Data</CardText>
                        </CardBody>
                      )
                    ) : (
                      <div className="d-flex align-items-center justify-content-center">
                        <DivLoader width={"45%"} marginTop={0} />
                      </div>
                    )}
                  </ListGroup>
                </Card>
              </>
            ) : (
              <>
                <Card className="allStandardSidebarCard text-left">
                  <CardHeader className="allStandardsHeader">
                    Custom Standards
                  </CardHeader>
                  <div style={{ maxHeight: 250, overflowY: "scroll" }}>
                    <ListGroup variant="flush">
                      {customStandards ? (
                        customStandards?.length > 0 ? (
                          customStandards?.map((customStandardsObj) => {
                            return (
                              <ListGroupItem
                                className="d-flex align-items-center justify-content-between cursor-pointer"
                                onClick={() => {
                                  setShowSubTabs(true);
                                  // customSubStandardsHandler(customStandardsObj);
                                  setMainId(customStandardsObj?.main_id);
                                  setstandardObj(customStandardsObj);
                                }}
                                key={customStandardsObj?.main_id}
                              >
                                {customStandardsObj?.core_standards_text}{" "}
                                <i className="breadcrumb-arrow-icon material-icons">
                                  chevron_right
                                </i>
                              </ListGroupItem>
                            );
                          })
                        ) : (
                          <CardBody>
                            <CardText className="text-center">
                              No Custom <br />
                              Standards Uploaded
                            </CardText>
                          </CardBody>
                        )
                      ) : (
                        <>
                          <div className="d-flex align-items-center justify-content-center">
                            <DivLoader width={"45%"} marginTop={0} />
                          </div>
                        </>
                      )}
                    </ListGroup>
                  </div>
                  {/*  */}
                </Card>
                <Card
                  className="allStandardDefaultSidebarCard text-left"
                  style={{ marginTop: "-1px" }}
                >
                  <CardHeader className="allStandardsDefaultHeader">
                    Default Standards
                  </CardHeader>
                  <div style={{ minHeight: "50vh", overflowY: "scroll" }}>
                    <ListGroup variant="flush">
                      {defaultStandards ? (
                        defaultStandards?.length > 0 ? (
                          defaultStandards?.map((defaultStandardsItem) => {
                            return (
                              <>
                                <ListGroupItem
                                  key={defaultStandardsItem?.main_id}
                                  className="d-flex align-items-center justify-content-between cursor-pointer"
                                  onClick={() => {
                                    setShowSubTabs(true);
                                    setDefaultClicked(true);
                                    setMainId(defaultStandardsItem?.main_id);
                                  }}
                                >
                                  {defaultStandardsItem?.core_standards_text}{" "}
                                  <i className="breadcrumb-arrow-icon material-icons">
                                    chevron_right
                                  </i>
                                </ListGroupItem>
                              </>
                            );
                          })
                        ) : (
                          <CardBody>
                            <CardText className="text-center">
                              No Standards
                            </CardText>
                          </CardBody>
                        )
                      ) : (
                        <>
                          <div className="d-flex align-items-center justify-content-center">
                            <DivLoader width={"45%"} marginTop={0} />
                          </div>
                        </>
                      )}
                    </ListGroup>
                  </div>
                </Card>
              </>
            )}
          </div>

          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 mt-5">
          {importCoreStandardLoader && <DivLoader width={"30%"}  marginTop={0} />}
            {showContent &&
              (coreStandardDescription ? (
                coreStandardDescription?.length > 0 ? (
                  coreStandardDescription.map((descriptionObj) => {
                    return (
                      <StandardContent
                        descriptionObj={descriptionObj}
                        key={descriptionObj?.standard_id}
                      />
                    );
                  })
                ) : (
                  <Card className="text-left standardContentCard mb-3">
                    <CardBody>
                      <CardText className="text-center">{"No data"}</CardText>
                    </CardBody>
                  </Card>
                )
              ) : (
                <>
                  <div className="d-flex align-items-center justify-content-center">
                    <DivLoader />
                  </div>
                </>
              ))}
          </div>
        </div>

        {/* ---------------Modal Upload------------ */}
        <div
          className="modal right fade"
          id="myModalupload"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel2"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              {/* <div className="modal-header">
              <div className="uploadDocHeaderDiv">
                <div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span className="cancelText" aria-hidden="true">
                      {" "}
                      <img
                        src={cancelBlack}
                        alt=""
                        className="closeIconInModal"
                      />{" "}
                      Cancel
                    </span>
                  </button>
                </div>

                <div>
                 
                </div>
              </div>
            </div> */}
              <div
                style={{ paddingTop: "9px", paddingLeft: "9px" }}
                className="modal-header"
              >
                <div className="uploadDocHeaderDiv">
                  <button
                    type="button"
                    className="closeImport"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span className="cancelText" aria-hidden="true">
                      {" "}
                      <img
                        style={{ paddingRight: "5px" }}
                        src={cancelIcon}
                        alt=""
                        className="closeIconInModal"
                      />{" "}
                      <span>Cancel Import</span>
                    </span>
                  </button>

                  {/* <button
                    style={{ marginRight: "-7px", marginBottom: "-7px" }}
                    type="button"
                    className="close closeround"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span className="cancelText" aria-hidden="true">
                      {" "}
                      <img
                        style={{
                          width: "10px",
                          position: "relative",
                          bottom: 1,
                        }}
                        src={cancelBlack}
                        alt=""
                        className="closeIconInModal"
                      />{" "}
                    </span>
                  </button> */}
                  {UploadFrom == "drop" ? (
                    <ReactFileReader
                      disabled={UploadFrom !== "drive" ? false : true}
                      // disabled={importCoreStandardLoader}
                      handleFiles={handleImportFiles}
                      fileTypes={".csv"}
                    >
                      <Button
                        disabled={
                         importCoreStandardLoader
                          //  ||
                          // (UploadFrom !== "drive" ? false : true)
                        }
                        className="edit-standard-btn-container align-items-center"
                        color="primary"
                        style={{ borderRadius: 8 }}
                      >
                        <span className="importRosterText">Import</span>
                      </Button>
                    </ReactFileReader>
                  ) : (
                    <Button
                      disabled={GoogleAuth ? false : true}
                      onClick={() => {
                        handleFileLoad();
                      }}
                      className="edit-standard-btn-container align-items-center"
                      color="primary"
                      style={{ borderRadius: 8 }}
                    >
                      <span className="importRosterText"> Import</span>
                    </Button>
                  )}
                </div>
              </div>
              {/* {UploadFrom !== "drive" ? (
                    <Button
                      disabled={true}
                      className="importRosterButton"
                      color="primary"
                    >
                      <span className="importRosterText"> Import</span>
                    </Button>
                  ) : (
                    <Button
                      disabled={GoogleAuth ? false : true}
                      onClick={() => {
                        handleFileLoad();
                      }}
                      className="importRosterButton"
                      color="primary"
                    >
                      <span className="importRosterText"> Import</span>
                    </Button>
                  )} */}

              <div className="modal-body">

                {/* --------------- Upload option------------ */}
                <div className="selectMainDivforUpload">
                  <div className="selectOptionInTag">
                    <div
                      className="checkbozCircle"
                      onClick={() => {
                        // this.setState({ UploadFrom: 'drive' })
                        setUploadFrom("drive");
                      }}
                    >
                      {UploadFrom === "drive" && (
                        <div className="checkbozCircleSelected"></div>
                      )}
                    </div>
                    <img
                      src={googleDriveIcon}
                      alt=""
                      className="selectoptionUploadImage"
                    />
                    {!GoogleAuth ? (
                      <p className="selectTagText">Google Drive</p>
                    ) : (
                      <p className="loginEmailText">{driveEmail}</p>
                    )}
                    <div>
                      {!GoogleAuth ? (
                        <Button
                          disabled={UploadFrom === "drive" ? false : true}
                          className="autharizedButton"
                          color="primary"
                          // onClick={() => handleClientLoad()}
                          onClick={() => handleClientLoad()}
                        >
                          <span className="autharizedText"> Authorize</span>
                        </Button>
                      ) : (
                        <Button
                          disabled={UploadFrom === "drive" ? false : true}
                          className="logoutButton"
                          color="primary"
                          onClick={() => handleSignOutClick()}
                        >
                          <span className="logoutText"> Logout</span>
                        </Button>
                      )}
                    </div>
                  </div>

                  <div className="selectOptionInTag">
                    <div
                      className="checkbozCircle"
                      onClick={() => {
                        //  this.setState({ UploadFrom: 'drop' })
                        setUploadFrom("drop");
                      }}
                    >
                      {UploadFrom !== "drive" && (
                        <div className="checkbozCircleSelected"></div>
                      )}
                    </div>
                    <img
                      src={localFolderIcon}
                      alt=""
                      className="selectoptionUploadImageforDrop"
                    />
                    <p className="selectTagText">My Device</p>
                    {/* <div>
                      <ReactFileReader
                        disabled={UploadFrom !== "drive" ? false : true}
                        handleFiles={handleImportFiles}
                        fileTypes={".csv"}
                      >
                        <Button
                          // disabled={UploadFrom !== "drive" ? false : true}
                          disabled={
                            uploadFile ||
                            (UploadFrom !== "drive" ? false : true)
                          }
                          className="autharizedButton"
                          color="primary"
                        >
                          <span className="autharizedText"> Choose</span>
                        </Button>
                      </ReactFileReader>
                    </div> */}
                  </div>
                </div>
                {/* ---------------Upload option------------ */}
                  {/* ---------------Note option------------ */}
                  <div className="uploadNodeDiv mt-3 position-relative">
                  <p
                    className="uploadNoteText"
                    style={{ fontWeight: 600, marginBottom: 10 }}
                  >
                    Note
                  </p>
                  <p className="uploadNoteText">1. Support .CSV files only.</p>
                </div>
                {/* ---------------Note option------------ */}
              </div>
            </div>
          </div>
        </div>

        {/* -----------------Delete Modal ---------------*/}

        <Modal
          centered
          className="delete-modal-warning br-8"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header>
            <Modal.Title>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <img
                  src={warningIcon}
                  alt=""
                  style={{ width: "60px", height: "60px" }}
                />
                <span
                  style={{
                    fontSize: "24px",
                    color: "#ff1f1f",
                    marginTop: "10px",
                  }}
                >
                  Wait!
                </span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="standarddeletepara">
              Are you sure want to delete this{" "}
              <span>{standardObj?.core_standards_text} ?</span>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="default"
              className="delete-modal-warning cancel-btn"
              onClick={handleClose}
              style={{ background: "transparent", color: "#000" }}
            >
              Cancel
            </Button>
            <Button
              variant="default"
              className="delete-modal-warning delete-btn"
              style={{ borderRadius: "8px" }}
              onClick={() => {
                deleteStandardHandler();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/*-------------------Edit Modal--------------------*/}
        <Modal
          centered
          className="delete-modal-warning br-8 edit-standard-modal"
          show={showEditModal}
          // onHide={editStandardLoader ? false : handleEditModalClose}
          // backdrop={editStandardLoader ? "static" : false}
        >
          <Modal.Body
            style={{ paddingBottom: 0 }}
            className="d-flex align-items-center justify-content-center"
          >
            <p className="standarddeletepara mb-0">Change Name of Standard</p>
          </Modal.Body>
          <Modal.Body className="pb-0">
            <CommonTextField
              margin="normal"
              variant="outlined"
              type="Title"
              defaultValue={standardObj?.core_standards_text}
              // onChange={this.handleChange}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              name="Title"
              className="studentAddInput"
              style={{ width: 250, marginTop: 0 }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="default"
              className="delete-modal-warning cancel-btn"
              onClick={handleEditModalClose}
              style={{ background: "transparent", color: "#000" }}
            >
              Cancel
            </Button>
            <Button
              variant="default"
              className="delete-modal-warning save-standard-btn"
              style={{ borderRadius: "8px" }}
              onClick={() => {
                editStandardHandler();
              }}
              disabled={editStandardLoader}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ------------- Files Modal-----------------------*/}
        <Modal
          centered
          className="select-DriveFile-modal br-8"
          show={SelectFileModal}
        >
          <Modal.Header>
            <Modal.Title>Google Drive Import</Modal.Title>
            {googleSelectedFile && (
              <Button
                className="modalSaveTagButton"
                variant="success"
                onClick={() => {
                  getFileDetails();
                }}
                disabled={uploadFile}
              >
                <img src={tickIcon} alt="" height={20} width={20} />
              </Button>
            )}
          </Modal.Header>
          <Modal.Body>
            {importLoader ? (
              <div style={{ width: "100%", textAlign: "center" }}>
                <img src={loaderImag} alt="" className="importDriveLoader" />
              </div>
            ) : (
              <div>
                <div className="inline-Path">
                  <ul className="breadcrumb">
                    {googleDrivePath?.length > 0 &&
                      googleDrivePath.map((value, i) => (
                        <li
                          key={i}
                          onClick={() => {
                            getFolderFilesPath(value);
                          }}
                        >
                          <span className="cursor">{value.name}</span>
                        </li>
                      ))}
                  </ul>
                </div>

                <div className="modal-list-body">
                  {GetFileLoader && (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img src={loaderImag} alt="" className="loaderIconCss" />
                    </div>
                  )}
                  {GoogleFiles && GoogleFiles.length > 0 ? (
                    <>
                      {GoogleFiles &&
                        GoogleFiles.length > 0 &&
                        GoogleFiles.map(
                          (item, index) => (
                            (
                              <div className="file_listDiv" key={index}>
                                {item.fileExtension === "csv" ? (
                                  <div style={{ display: "inline-flex" }}>
                                    <img
                                      src={driveCSVIcon}
                                      alt=""
                                      className="selectoptionUploadFolder"
                                    />

                                    <p className="fileName">{item.name}</p>
                                  </div>
                                ) : (
                                  <div
                                    className="selectFileCursor"
                                    onClick={() => {
                                      getFolderFiles(item);
                                    }}
                                  >
                                    <img
                                      src={driveFolderIcon}
                                      alt=""
                                      className="selectoptionUploadFolder"
                                    />

                                    <p className="fileName">{item.name}</p>
                                  </div>
                                )}
                                {item.fileExtension === "csv" && (
                                  <i
                                    onClick={() => {
                                      setGoogleSelectedFile(item);
                                      // this.setState({
                                      //     googleSelectedFile: item
                                      // })
                                    }}
                                    className={`${
                                      googleSelectedFile.id === item.id
                                        ? "checkBoxSelect"
                                        : "unCheckBoxSelect"
                                    } material-icons`}
                                  >
                                    {googleSelectedFile.id === item.id
                                      ? "check_circle"
                                      : "radio_button_unchecked"}
                                  </i>
                                )}
                              </div>
                            )
                          )
                        )}
                    </>
                  ) : (
                    <>
                      {GetFileLoader ? (
                        <div></div>
                      ) : (
                        <div style={{ textAlign: "center", marginTop: 20 }}>
                          <p className="unSelectedText">No File Avaliable</p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="modalcancleTagButton"
              variant="danger"
              onClick={() => {
                setSelectFileModal(false);
                setGoogleDrivePath([]);
                // this.setState({
                //     SelectFileModal: false
                // })
              }}
              disabled={uploadFile}
            >
              <img src={closeIcon} alt="" height={40} width={30} />
            </Button>
          </Modal.Footer>
        </Modal>

        {/* --------------- Add Standarad Modal ------------------*/}
        <Modal
          centered
          className="delete-modal-warning br-8 edit-standard-modal appendstandardModal"
          show={showAddStandardModal}
          // onHide={editStandardLoader ? false : handleEditModalClose}
          // backdrop={editStandardLoader ? "static" : false}
        >
          <Modal.Body
            style={{ paddingBottom: 0 }}
            className="d-flex align-items-center justify-content-center"
          >
            <p className="addStandardModalPara mb-0">What you want to do with Standards?</p>
          </Modal.Body>
          <Modal.Body className="pb-0">
          <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={addStandardValue}
        onChange={standardValueHandler}
      >
        <FormControlLabel value="append" control={<Radio />} label="Append" />
        <FormControlLabel value="over_write_existing" control={<Radio />} label="Over Write Existing" />
        <FormControlLabel value="create_new" control={<Radio />} label="Create New" />
      </RadioGroup>
    </FormControl>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="default"
              className="delete-modal-warning cancel-btn"
              onClick={handleAllStandardModalClose}
              style={{ background: "transparent", color: "#000" }}
            >
              Cancel
            </Button>
            <Button
              variant="default"
              className="delete-modal-warning save-standard-btn"
              style={{ borderRadius: "8px" }}
              onClick={() => {
                addStandardFromModalHandler()
              }}
              disabled={uploadFile}
            >
              Proceed
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default AllStandardDashboard;
