import React from 'react';

const BlogDetails = (props) => {
    const { location } = props;
    const { state } = location;
    const { item } = state;

    return (
        <div className='blogContainer'>
            <img src={item.fimg_url} className="blogDetailsCardImage" alt="..." />
            <div dangerouslySetInnerHTML={{ __html: item.content.rendered }} />
        </div>
    )
}

export default BlogDetails