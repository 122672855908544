
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getNotificationRequest: ['data'],
    getNotificationSuccess: ['data'],
    getNotificationFailure: ['error'],

    rejectSharedClassRequest: ['data'],
    rejectSharedClassSuccess: ['data'],
    rejectSharedClassFailure: ['error'],

    getUserPlanSpaceDetails: ['data'],
    getUserPlanSpaceDetailsFailure: ['data'],
    getUserPlanSpaceDetailsSuccess: ['data'],

    rejectSharedStudentRequest: ['data'],
    rejectSharedStudentSuccess: ['data'],
    rejectSharedStudentFailure: ['error'],

    deleteNotificationRequest: ['data'],
    deleteNotificationSuccess: ['data'],
    deleteNotificationFailure: ['error'],

    notificationMarkAsReadRequest: ['data'],
    notificationMarkAsReadSuccess: ['data'],
    notificationMarkAsReadFailure: ['error'],

    getNotificationCountRequest: ['data'],
    getNotificationCountSuccess: ['data'],
    getNotificationCountFailure: ['error']

})
export const NotificationReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    Get_Notification: null,
    Reject_Shared_Class:null,
    Reject_Shared_Student:null,
    Delete_Notification: null,
    Notification_Mark_As_Read: null,
    Get_Notification_Count: null,
    Get_user_space: null,
    error: null,
    exists: null,
    loader: null,
    action: null
})

/* ------------- Reducers ------------- */

/* getUserSettingRequest */

export const getNotificationRequest = (state) => {
    return {
        ...state, fetching: true, error: null, Get_Notification: null, loader: true
    }
}
export const getNotificationSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, Get_Notification: data, action: action.type, exists: true, loader: false }
}
export const getNotificationFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_NOTIFICATION_FAILURE', loader: false }
}


/* rejectSharedClassRequest */

export const rejectSharedClassRequest = (state) => {
    return {
        ...state, fetching: true, error: null, Reject_Shared_Class: null, loader: true
    }
}
export const rejectSharedClassSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, Reject_Shared_Class: data, action: action.type, exists: true, loader: false }
}
export const rejectSharedClassFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'REJECT_SHARED_CLASS_FAILURE', loader: false }
}

/* getUserSpaceRequest */

export const getUserPlanSpaceDetails = (state) => {
    return {
        ...state, fetching: true, error: null, Get_user_space: null, loader: true
        }
}

export const getUserPlanSpaceDetailsFailure = (state, {error}) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_USER_PLAN_SPACE_DETAILS_FAILURE', loader: false }
}

export const getUserPlanSpaceDetailsSuccess = (state, action) => {
    return {
        ...state, fetching: false, error: null, Get_user_space: action?.data, action: action.type, exists: true, loader: false
    }
}

/* rejectSharedStudentRequest */

export const rejectSharedStudentRequest = (state) => {
    return {
        ...state, fetching: true, error: null, Reject_Shared_Student: null, loader: true
    }
}
export const rejectSharedStudentSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, Reject_Shared_Student: data, action: action.type, exists: true, loader: false }
}
export const rejectSharedStudentFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'REJECT_SHARED_STUDENT_FAILURE', loader: false }
}


/* deleteNotificationRequest */

export const deleteNotificationRequest = (state) => {
    return {
        ...state, fetching: true, error: null, Delete_Notification: null, loader: true
    }
}
export const deleteNotificationSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, Delete_Notification: data, action: action.type, exists: true, loader: false }
}
export const deleteNotificationFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'DELETE_NOTIFICATION_FAILURE', loader: false }
}


/* notificationMarkAsReadRequest */

export const notificationMarkAsReadRequest = (state) => {
    return {
        ...state, fetching: true, error: null, Notification_Mark_As_Read: null, loader: true
    }
}
export const notificationMarkAsReadSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, Notification_Mark_As_Read: data, action: action.type, exists: true, loader: false }
}
export const notificationMarkAsReadFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'NOTIFICATION_MARK_AS_READ_FAILURE', loader: false }
}

/* getUserSettingRequest */

export const getNotificationCountRequest = (state) => {
    return {
        ...state, fetching: true, error: null, Get_Notification_Count: null, loader: true
    }
}
export const getNotificationCountSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, Get_Notification_Count: data, action: action.type, exists: true, loader: false }
}
export const getNotificationCountFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_NOTIFICATION_COUNT_FAILURE', loader: false }
}


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {


    [Types.GET_NOTIFICATION_REQUEST]: getNotificationRequest,
    [Types.GET_NOTIFICATION_SUCCESS]: getNotificationSuccess,
    [Types.GET_NOTIFICATION_FAILURE]: getNotificationFailure,

    [Types.REJECT_SHARED_CLASS_REQUEST]: rejectSharedClassRequest,
    [Types.REJECT_SHARED_CLASS_SUCCESS]: rejectSharedClassSuccess,
    [Types.REJECT_SHARED_CLASS_FAILURE]: rejectSharedClassFailure,

    [Types.GET_USER_PLAN_SPACE_DETAILS]: getUserPlanSpaceDetails,
    [Types.GET_USER_PLAN_SPACE_DETAILS_FAILURE]: getUserPlanSpaceDetailsFailure,
    [Types.GET_USER_PLAN_SPACE_DETAILS_SUCCESS]: getUserPlanSpaceDetailsSuccess,

    [Types.REJECT_SHARED_STUDENT_REQUEST]: rejectSharedStudentRequest,
    [Types.REJECT_SHARED_STUDENT_SUCCESS]: rejectSharedStudentSuccess,
    [Types.REJECT_SHARED_STUDENT_FAILURE]: rejectSharedStudentFailure,

    [Types.DELETE_NOTIFICATION_REQUEST]: deleteNotificationRequest,
    [Types.DELETE_NOTIFICATION_SUCCESS]: deleteNotificationSuccess,
    [Types.DELETE_NOTIFICATION_FAILURE]: deleteNotificationFailure,

    [Types.NOTIFICATION_MARK_AS_READ_REQUEST]: notificationMarkAsReadRequest,
    [Types.NOTIFICATION_MARK_AS_READ_SUCCESS]: notificationMarkAsReadSuccess,
    [Types.NOTIFICATION_MARK_AS_READ_FAILURE]: notificationMarkAsReadFailure,
   
    [Types.GET_NOTIFICATION_COUNT_REQUEST]: getNotificationCountRequest,
    [Types.GET_NOTIFICATION_COUNT_SUCCESS]: getNotificationCountSuccess,
    [Types.GET_NOTIFICATION_COUNT_FAILURE]: getNotificationCountFailure
})
