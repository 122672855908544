import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({

    getGroupDetailsRequest: ['data'],
    getGroupDetailsSuccess: ['data', 'page'],
    getGroupDetailsFailure: ['error'],

    addMultipleStudentsRequest: ['data'],
    addMultipleStudentsSuccess: ['data', 'page'],
    addMultipleStudentsFailure: ['error'],

    deleteGroupRequest: ['data'],
    deleteGroupSuccess: ['data', 'page'],
    deleteGroupFailure: ['error'],


    addNewStudentInGroupRequest: ['data'],
    addNewStudentInGroupSuccess: ['data', 'page'],
    addNewStudentInGroupFailure: ['error'],


    removeStudentFromGroupRequest: ['data'],
    removeStudentFromGroupSuccess: ['data', 'page'],
    removeStudentFromGroupFailure: ['error'],

    renameGroupRequest: ['data'],
    renameGroupSuccess: ['data', 'page'],
    renameGroupFailure: ['error'],

    createGroupRequest: ['data'],
    createGroupSuccess: ['data', 'page'],
    createGroupFailure: ['error'],

    setGroupNotesState: ['data']

})

export const UserAddGroupReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    getGroupDetails: {
        response_data: [],
    },
    groupsData:{
        response_data: [],
    },
    addMultipleStudents: {
        action: null,
    },
    deleteGroup: {
        action: null,
    },
    addNewStudentInGroup: {
        action: null,
    },
    removeStudentFromGroup: {
        action: null,
    },
    renameGroup: {
        action: null,
    },
    createGroup: {
        action: null,
    },
    error: null,
    exists: null,
    loader: null,
    action: null,
    pagination: null
})

/* ------------- Reducers ------------- */

export const setGroupNotesState = (state) => {
    return {
        ...state,
        getGroupDetails: {
            response_data: [],
        },
        groupsData:{
            response_data: [],
        },
        addMultipleStudents: {
            action: null,
        },
        deleteGroup: {
            action: null,
        },
        addNewStudentInGroup: {
            action: null,
        },
        removeStudentFromGroup: {
            action: null,
        },
        renameGroup: {
            action: null,
        },
        createGroup: {
            action: null,
        },
        error: null,
        exists: null,
        loader: null,
        action: null,
        pagination: null
    }
}

/* getGroupDetails */

export const getGroupDetailsRequest = (state, action) => {
    
    if(action.data.changeClass) {
        return {
            ...state,
            fetching: true, error: null, loader: true, pagination: action.data.pageNo,
            getGroupDetails: {
                response_data: [],
            },
            groupsData:{
                response_data: [],
            },
        }
    } else {
    return {
        ...state, fetching: true, error: null, loader: true, pagination: action.data.pageNo
    }
}
}
export const getGroupDetailsSuccess = (state, action) => {
    const { data } = action;
    
    return { ...state, fetching: false, error: null,  getGroupDetails : data.data.response_data, groupsData: data.data.response_data, action: action.type, exists: true, loader: false }
}
export const getGroupDetailsFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_GROUP_DETAILS_FAILURE', loader: false }
}



/* add Multiple Students */

export const addMultipleStudentsRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, loader: true, pagination: action.data.pageNo
    }
}
export const addMultipleStudentsSuccess = (state, action) => {
    // const { data } = action;
    return { ...state, fetching: false, error: null,  addMultipleStudents : action, exists: true, loader: false }
}
export const addMultipleStudentsFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'ADD_MULTIPLE_STUDENTS_FAILURE', loader: false }
}

/* Delete Group Students */

export const deleteGroupRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, loader: true, pagination: action.data.pageNo
    }
}
export const deleteGroupSuccess = (state, action) => {
    // const { data } = action;
    return { ...state, fetching: false, error: null,  deleteGroup : action, exists: true, loader: false }
}
export const deleteGroupFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'DELETE_GROUP_FAILURE', loader: false }
}

/* add New Student In Group Request */

export const addNewStudentInGroupRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, loader: true, pagination: action.data.pageNo
    }
}
export const addNewStudentInGroupSuccess = (state, action) => {
    // const { data } = action;
    return { ...state, fetching: false, error: null,  addNewStudentInGroup : action, exists: true, loader: false }
}
export const addNewStudentInGroupFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'ADD_STUDENT_GROUP_FAILURE', loader: false }
}

/* remove Student From Group Request */

export const removeStudentFromGroupRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, loader: true, pagination: action.data.pageNo
    }
}
export const removeStudentFromGroupSuccess = (state, action) => {
    // const { data } = action;
    return { ...state, fetching: false, error: null,  removeStudentFromGroup : action, exists: true, loader: false }
}
export const removeStudentFromGroupFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'REMOVE_STUDENT_FROM_GROUP_FAILURE', loader: false }
}

/* remove Student From Group Request */

export const renameGroupRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, loader: true, pagination: action.data.pageNo
    }
}
export const renameGroupSuccess = (state, action) => {
    // const { data } = action;
    return { ...state, fetching: false, error: null,  renameGroup : action, exists: true, loader: false }
}
export const renameGroupFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'RENAME_GROUP_FAILURE', loader: false }
}
/* create Group Request */

export const createGroupRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, loader: true, pagination: action.data.pageNo
    }
}
export const createGroupSuccess = (state, action) => {
    // const { data } = action;
    return { ...state, fetching: false, error: null,  createGroup : action, exists: true, loader: false }
}
export const createGroupFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'CREATE_GROUP_FAILURE', loader: false }
}


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.GET_GROUP_DETAILS_REQUEST]: getGroupDetailsRequest,
    [Types.GET_GROUP_DETAILS_SUCCESS]: getGroupDetailsSuccess,
    [Types.GET_GROUP_DETAILS_FAILURE]: getGroupDetailsFailure,

    [Types.ADD_MULTIPLE_STUDENTS_REQUEST]: addMultipleStudentsRequest,
    [Types.ADD_MULTIPLE_STUDENTS_SUCCESS]: addMultipleStudentsSuccess,
    [Types.ADD_MULTIPLE_STUDENTS_FAILURE]: addMultipleStudentsFailure,

    [Types.DELETE_GROUP_REQUEST]: deleteGroupRequest,
    [Types.DELETE_GROUP_SUCCESS]: deleteGroupSuccess,
    [Types.DELETE_GROUP_FAILURE]: deleteGroupFailure,

    [Types.ADD_NEW_STUDENT_IN_GROUP_REQUEST]: addNewStudentInGroupRequest,
    [Types.ADD_NEW_STUDENT_IN_GROUP_SUCCESS]: addNewStudentInGroupSuccess,
    [Types.ADD_NEW_STUDENT_IN_GROUP_FAILURE]: addNewStudentInGroupFailure,

    [Types.REMOVE_STUDENT_FROM_GROUP_REQUEST]: removeStudentFromGroupRequest,
    [Types.REMOVE_STUDENT_FROM_GROUP_SUCCESS]: removeStudentFromGroupSuccess,
    [Types.REMOVE_STUDENT_FROM_GROUP_FAILURE]: removeStudentFromGroupFailure,

    [Types.RENAME_GROUP_REQUEST]: renameGroupRequest,
    [Types.RENAME_GROUP_SUCCESS]: renameGroupSuccess,
    [Types.RENAME_GROUP_FAILURE]: renameGroupFailure,

    [Types.CREATE_GROUP_REQUEST]: createGroupRequest,
    [Types.CREATE_GROUP_SUCCESS]: createGroupSuccess,
    [Types.CREATE_GROUP_FAILURE]: createGroupFailure,

    [Types.SET_GROUP_NOTES_STATE]: setGroupNotesState,
})