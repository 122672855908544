import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import cancelBlack from 'Assets/RosterModule/cancelBlack.png'
import cloudeIconUpload from 'Assets/ClassModule/cloudeIconUpload.png'
import flashActiveTag from 'Assets/ClassModule/flashActive.png'
import addTag from 'Assets/ClassModule/addTag.png'
import CommonTextField from 'Components/CommonTextField/CommonTextField';
import closeTag from 'Assets/ClassModule/tagClose.png';
import googleDriveIcon from 'Assets/RosterModule/googleDrive.png'
import localFolderIcon from 'Assets/RosterModule/localFolder.png'
import { createNotification } from 'Config/notificationtoast';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import { API_KEY, CLIENT_ID, DISCOVERY_DOCS, SCOPES } from 'Config/Constant';
import ReactFileReader from 'react-file-reader';
import { getClassTag, editClassTag, getSpeedNote, importSpeedNote, deleteClassTag } from 'Actions/ClassAction/classTagAction';
import { gapi } from 'gapi-script';
import closeIcon from 'Assets/NoteModule/close.svg';
import tickIcon from 'Assets/NoteModule/tick.svg';
import driveFolderIcon from 'Assets/RosterModule/folder.png'
import driveCSVIcon from 'Assets/RosterModule/filecsv_icon.png'
import './ClassTag.scss';

class ClassTag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tagVal: "",
            priorityTagSelected: false,
            Changes: false,
            ExitModal: false,
            deleteModal: false,
            Positive_Comments: '',
            Positive_Comments_Abbreviation: '',
            CancelTagIds: '',
            tagData: [],
            tagDummyData: [
                {
                    "cc_tag_abbre": "",
                    "cc_tag_add_on": " ",
                    "cc_tag_checked": "no",
                    "cc_tag_class_id": "",
                    "cc_tag_id": "",
                    "cc_tag_modifyed_on": null,
                    "cc_tag_title": "",
                },

            ],
            SpeedNoteData: [],
            speedNoteModalOpen: false,
            select_Customize_tag_id: '',
            UploadFor: 'Selected',
            UploadFrom: 'drive',

            //Google Drive Para...
            GoogleAuth: JSON.parse(sessionStorage.getItem('GoogleAuth')),
            GoogleAuthItem: JSON.parse(sessionStorage.getItem('GoogleAuthItem')),
            GoogleFiles: [],
            googleSelectedFile: '',
            SelectFileModal: false,
            GetFileLoader: false,
            googleDrivePath: [],
            name: '',
            importLoader: '',
            deleteTagDetails: null
        }

    }
    BackTOHome = () => {
        if (this.state.Changes) {
            this.setState({
                ExitModal: true
            })
            return;
        }
        this.props.history.goBack();
    }

    // ---------UNSAFE_componentWillMount-------
    UNSAFE_componentWillMount() {
        this.getTagData()
    }
    // ---------UNSAFE_componentWillMount-------

    // ---------componentWillReceiveProps-------
    async UNSAFE_componentWillReceiveProps(nextProps) {
        // ---------get Class Tag WillReceiveProps-------
        if (nextProps.classTag.action === 'GET_CLASS_TAG_SUCCESS') {
            if (nextProps.classTag.Tag_Data) {
                if (nextProps.classTag.Tag_Data.length > 0) {
                    this.setState({
                        tagData: nextProps.classTag.Tag_Data
                    })
                } else {
                    this.setState({
                        tagData: this.state.tagDummyData
                    })
                }
            }

        }
        // ---------get Class Tag WillReceiveProps-------

        // ---------get Speed Note Tag WillReceiveProps-------
        if (nextProps.classTag.action === 'GET_SPEED_NOTE_SUCCESS') {
            if (nextProps.classTag.Get_Speed_Note) {
                let AllSpeedNoteData = [];
                let data = nextProps.classTag.Get_Speed_Note;
                Object.keys(data).forEach(function (key) {
                    for (let item of data[key]) {
                        AllSpeedNoteData.push(item)
                    }
                });

                this.setState({
                    SpeedNoteData: AllSpeedNoteData
                })

            }

        }
        // ---------get Speed Note Tag WillReceiveProps-------

        // ---------Add Speed Note Tag WillReceiveProps-------
        if (nextProps.classTag.action === 'IMPORT_SPEED_NOTE_SUCCESS') {
            if (this.state.speedNoteModalOpen) {

                this.setState({
                    SpeedNoteData: [],
                    speedNoteModalOpen: false
                })
                window.$('#myModalTagSpeedAdd').modal('show');
            }

        }
        // ---------Add Speed Note Tag WillReceiveProps-------
    }
    // ---------componentWillReceiveProps-------


    // ---------Get Class Tag Data-------
    getTagData() {
        const { location = {} } = this.props
        const { state = {} } = location;
        const { classDetail = '' } = state;
        const data = {
            "class_id": classDetail.cc_class_id
        }
        this.props.getClassTag(data)
    }
    // ---------Get Class Tag Data-------



    // ---------Remove Class Tag Data-------
    removeTagValue = (item, index) => {
        // let CancelTagIds = this.state.CancelTagIds;
        // let tagArray = this.state.tagData;
        // if (CancelTagIds.length === 0) {
        //     CancelTagIds = item.cc_tag_id
        // }
        // else {
        //     CancelTagIds = CancelTagIds + "," + item.cc_tag_id
        // }
        // if (item.cc_tag_checked === "yes") {
        //     if (index === 0) {
        //         tagArray[index + 1].cc_tag_checked = "yes"
        //     } else {
        //         tagArray[index - 1].cc_tag_checked = "yes"
        //     }
        // }
        // tagArray.splice(index, 1)
        // this.setState({
        //     tagData: tagArray,
        //     CancelTagIds: CancelTagIds,
        //     Changes: true
        // }, () => {
        // })
        this.setState({
            deleteModal: true,
            deleteTagDetails: item
        })

        // this.props.deleteClassTag(item)

    }
    // ---------Remove Class Tag Data-------



    // ---------Add Class Tag Data-------
    addTagValue = () => {
        let data = {
            "cc_tag_abbre": "",
            "cc_tag_add_on": " ",
            "cc_tag_checked": "no",
            "cc_tag_class_id": "",
            "cc_tag_id": "",
            "cc_tag_modifyed_on": null,
            "cc_tag_title": "",
        }
        let tagArray = this.state.tagData;
        tagArray.push(data)
        this.setState({
            tagData: tagArray,
            Changes: true
        })

    }
    // ---------Add Class Tag Data-------


    // ---------Check and Uncheck-------
    check(index, item) {
        let listDataDemo = this.state.tagData;
        for (let value of listDataDemo) {
            if (item.cc_tag_id === value.cc_tag_id) {
                value.cc_tag_checked = 'yes'
            } else {
                value.cc_tag_checked = 'no'
            }
        }
        this.setState({
            tagData: listDataDemo,
            Changes: true
        })

    }
    // ---------Check and Uncheck-------


    // ---------Make Speed Tag  -------
    selectSpeedTag = (item) => {
        if (item.cc_tag_id === '') {
            createNotification('error', 'Please save the new Tag first.');
            return;
        }
        this.setState({
            select_Customize_tag_id: item.cc_tag_id,
            SpeedNoteData: []
        })
        const { location = {} } = this.props
        const { state = {} } = location;
        const { classDetail = '' } = state;
        const data = {
            "customize_tag_id": item.cc_tag_id,
            "class_id": classDetail.cc_class_id,
            "filter_text": ""
        }
        this.props.getSpeedNote(data)
        window.$('#myModalTagSpeedAdd').modal('show');
    }
    // ---------Make Speed Tag  -------


    // ---------open drive Modal  -------
    openUploadDrive = () => {
        window.$('#myModalTagSpeedAdd').modal('hide');
        window.$('#myModalTagupload').modal('show');
    }
    // ---------open drive Modal  -------

    // ---------Save Class Tags  -------
    saveTags = () => {
        let tagsArray = []
        let tagData = this.state.tagData
        // let index = 1
        for (let item of tagData) {
            if (item.cc_tag_title === '') {
                createNotification('error', 'Please enter the Tag name.');
                return;
            }
            if (item.cc_tag_abbre === '') {
                createNotification('error', 'Please enter the abbreviation for Tags.');
                return;
            }
            const data = {
                "checked": item.cc_tag_checked,
                "tag_name": item.cc_tag_title,
                "tag_abbre": item.cc_tag_abbre,
                "tag_id": item.cc_tag_id
            }
            tagsArray.push(data)
            // index++;

        }
        const { location = {} } = this.props
        const { state = {} } = location;
        const { classDetail = '' } = state;
        const value = {
            "class_id": classDetail.cc_class_id,
            "delete_tag_ids": this.state.CancelTagIds,
            "tag_details_array": tagsArray
        }
        this.setState({
            CancelTagIds: '',
            Changes: false
        })
        this.props.editClassTag(value)
    }
    // ---------Save Class Tags  -------

    hasWhiteSpace(s) {
        return (/\s/).test(s);
      }
      
    // ---------handleChange-------
    handleChangeInput = (event, index) => {
        
        let listDataDemo = this.state.tagData;
        if (event.target.name === 'Tag Name') {
            listDataDemo[index].cc_tag_title = event.target.value
        } else {
            const scoreValue = /^[a-z\A-Z\d\-_]+$/;
            let whiteSpace = /^\s+$/;
          
            // if(this.hasWhiteSpace(event.target.value)) {
            //     if(event.target.value.length < 8 
                    
            //         ) {
            //             if((/^[\w]+([-_\s]{1}[a-z0-9]+)*$/).test(event.target.value)) {
            //         listDataDemo[index].cc_tag_abbre = event.target.value
            //             }
            //     }
            // } else {
            //     listDataDemo[index].cc_tag_abbre = event.target.value
            // }
            if(event.target.value.length < 5 
                && (!event.target.value || event.target.value.match(scoreValue))
                ) {
                listDataDemo[index].cc_tag_abbre = event.target.value
            }
            // listDataDemo[index].cc_tag_abbre = event.target.value
        }
        this.setState({
            tagData: listDataDemo,
            Changes: true,
            tagVal: event.target.value
        })

    }

    // --------- handleClassImportFiles Roster  -------
    handleImportFiles = files => {
        try {
            let fileType = files[0].name.split('.');
            if (fileType[fileType.length - 1] !== 'txt') {
                createNotification('error', 'Please select .txt file ');
                return;
            }
            var reader = new FileReader();;
            reader.onload = (e) => {
                if (reader.result === undefined || reader.result === 'undefined' || reader.result === '') {
                    createNotification('error', 'The selected file is empty. Please select another text file with content.');
                    return;
                }
                const str = reader.result;
                let array = str.split("\n");
                let customize_tag_id = []
                // ------Single / Multipule Tag Id------
                if (this.state.UploadFor === 'Selected') {
                    customize_tag_id = JSON.stringify(this.state.select_Customize_tag_id)
                } else {
                    let tagArray = this.state.tagData;
                    for (let item of tagArray) {
                        if (customize_tag_id.length === 0) {
                            customize_tag_id = item.cc_tag_id
                        }
                        else {
                            customize_tag_id = customize_tag_id + "," + item.cc_tag_id
                        }
                    }
                }
                // ------Single / Multipule Tag Id------
                const { location = {} } = this.props
                const { state = {} } = location;
                const { classDetail = '' } = state;
                let value = {
                    class_id: JSON.stringify(classDetail.cc_class_id),
                    customize_tag_id: customize_tag_id,
                    speed_notes_array: array,
                    selected_id: JSON.stringify(this.state.select_Customize_tag_id),
                    filter_text: ""
                }

                this.importTXTFile(value)
            }
            reader.readAsText(files[0]);
        } catch (e) {
            createNotification('error', 'Something Went Wrong, Not able to read File');
            return;
        }
    }
    // --------- handleClassImportFiles Roster  -------


    // ----------To Get Load For SignIN---------
    handleClientLoad = async () => {
        let GoogleAuth = ''
        gapi.load('client:auth2', () => {
            gapi.client.init({
                'apiKey': API_KEY,
                'clientId': CLIENT_ID,
                'scope': SCOPES,
                'discoveryDocs': DISCOVERY_DOCS
            }).then(() => {
                GoogleAuth = gapi.auth2.getAuthInstance()
                GoogleAuth.isSignedIn.listen(this.updateSigninStatus());

            });
        });
    };
    // ----------To Get Load For SignIN---------


    // ----------To SignIn In Google Drive---------
    handleAuthClick = (event) => {
        try {
            gapi.auth2.getAuthInstance().signIn().then((res) => {
                this.setSignedInUser(res)
            });
        } catch (e) {
            createNotification('error', 'Something Went Wrong, Not able Login');
            return;
        }
    };
    // ----------To SignIn In Google Drive---------



    // ----------To Update SignIn In Google Drive---------
    updateSigninStatus = (isSignedIn) => {
        try {
            if (isSignedIn) {
                this.setSignedInUser(gapi.auth2.getAuthInstance().currentUser.je);
            } else {
                this.handleAuthClick();
            }
        } catch (e) {
            createNotification('error', 'Something Went Wrong, Not able Login');
            return;
        }
    };
    // ----------To Update SignIn In Google Drive---------


    // ----------To SignIn Auth Save for Local Google Drive---------
    setSignedInUser = (value) => {
        try {
            this.setState({
                GoogleAuth: true,
                GoogleAuthItem: value
            })
            sessionStorage.setItem('GoogleAuth', true)
            sessionStorage.setItem('GoogleAuthItem', JSON.stringify(value))
        } catch (e) {
            createNotification('error', 'Something Went Wrong, Not able Login');
            return;
        }
    }
    // ----------To SignIn Auth Save for Local Google Drive---------

    // ----------To Get Load For SignOut---------
    handleSignOutClick = (event) => {
        try {
            gapi.load('client:auth2', () => {
                gapi.client.init({
                    'apiKey': API_KEY,
                    'clientId': CLIENT_ID,
                    'scope': SCOPES,
                    'discoveryDocs': DISCOVERY_DOCS
                }).then(() => {
                    var auth2 = gapi.auth2.getAuthInstance();
                    auth2.disconnect();
                    this.setState({
                        GoogleAuth: false,

                    })
                    sessionStorage.setItem('GoogleAuth', false)
                });
            });
        } catch (e) {
            createNotification('error', 'Something Went Wrong, Not able Logout');
            return;
        }
    };
    // ----------To Get Load For SignOut---------

    // ----------To Get File From Drive ---------
    handleFileLoad = () => {
        try {
            gapi.load('client:auth2', async () => {
                window.$('#myModalTagupload').modal('hide');
                this.setState({
                    SelectFileModal: true,
                    GetFileLoader: true,
                    googleSelectedFile: '',
                    GoogleFiles: []
                })
                await gapi.client.init({
                    'apiKey': API_KEY,
                    'clientId': CLIENT_ID,
                    'scope': SCOPES,
                    'discoveryDocs': DISCOVERY_DOCS
                }).then(() => {
                    gapi.client.drive.files
                        .list({
                            pageSize: 1000,
                            fields: 'nextPageToken, files(id,size, name, mimeType, fileExtension, modifiedTime, webViewLink)',
                            q: "'root' in parents and trashed=false"
                        }).then((res) => {
                            let GoogleFiles = []
                            for (let item of res.result.files) {
                                if (item.fileExtension) {
                                    if (item.fileExtension === "txt") {
                                        GoogleFiles.push(item)
                                    }
                                }
                            }
                            this.setState({
                                GoogleFiles: GoogleFiles,
                                GetFileLoader: false,
                            })
                        });
                });

            });
        } catch (e) {
            createNotification('error', 'Something Went Wrong, Not able to fetch files');
            this.setState({
                GoogleFiles: [],
                GetFileLoader: false,
            })
            return;
        }
    };
    // ----------To Get File From Drive ---------


    // ----------To Get File From Drive ---------
    handleFileLoad = () => {
        window.$('#myModalTagupload').modal('hide');
        const path = {
            name: 'My drive',
            id: 'root'
        }
        this.setState({
            googleDrivePath: []
        }, () => {
            this.getFolderFiles(path);
        })


    }
    // ----------To Get File From Drive ---------

    // ----------To Get File From Path ---------
    getFolderFilesPath = (item) => {
        let googleDrivePath = this.state.googleDrivePath;
        let CreatePath = [];
        for (let value of googleDrivePath) {
            if (value.id === item.id) {
                break;
            }
            else {
                const path = {
                    name: value.name,
                    id: value.id
                }
                CreatePath.push(path)
            }
        }
        this.setState({
            googleDrivePath: CreatePath
        }, () => {
            this.getFolderFiles(item);
        })

    }
    // ----------To Get File From Path ---------

    // ----------To Get File From Folder ---------
    getFolderFiles = (value) => {
        try {
            gapi.load('client:auth2', async () => {
                let query = `'${value.id}'  in parents and trashed=false`;
                let path = {
                    name: value.name,
                    id: value.id
                }
                let googleDrivePath = this.state.googleDrivePath;
                googleDrivePath.push(path)
                this.setState({
                    SelectFileModal: true,
                    GetFileLoader: true,
                    googleSelectedFile: '',
                    GoogleFiles: [],
                    googleDrivePath: googleDrivePath
                })
                await gapi.client.init({
                    'apiKey': API_KEY,
                    'clientId': CLIENT_ID,
                    'scope': SCOPES,
                    'discoveryDocs': DISCOVERY_DOCS
                }).then(() => {
                    gapi.client.drive.files
                        .list({
                            pageSize: 1000,
                            fields: 'nextPageToken, files(id,size, name, mimeType, fileExtension, modifiedTime, webViewLink)',
                            q: query
                        }).then((res) => {
                            let GoogleFiles = []
                            for (let item of res.result.files) {
                                if (item.fileExtension) {
                                    if (item.fileExtension === "txt") {
                                        GoogleFiles.push(item)
                                    }
                                }
                                let type = item.mimeType
                                let typeCheck = type.split('.');
                                if (typeCheck[typeCheck.length - 1] === 'folder') {
                                    item.fileExtension = 'folder';
                                    GoogleFiles.push(item)
                                }
                            }
                            this.setState({
                                GoogleFiles: GoogleFiles,
                                GetFileLoader: false,
                            }, () => {
                            })
                        });
                });

            });
        } catch (e) {
            this.setState({
                GoogleFiles: [],
                GetFileLoader: false,
            })
        }
    }
    // ----------To Get File From Folder ---------

    // ----------To Get File Read from Drive ---------
    getFileDetails = () => {
        try {
            this.setState({
                importLoader: true
            })
            gapi.load('client:auth2', () => {
                let data = this.state.googleSelectedFile;
                gapi.client.init({
                    'apiKey': API_KEY,
                    'clientId': CLIENT_ID,
                    'scope': SCOPES,
                    'discoveryDocs': DISCOVERY_DOCS
                }).then(async () => {
                    let file = data
                    let url = `https://www.googleapis.com/drive/v2/files/${data.id}?alt=media`;
                    if (file.webViewLink) {
                        var accessToken = gapi.auth.getToken().access_token;
                        var xhr = new XMLHttpRequest();
                        xhr.open('GET', url);
                        xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                        xhr.onload = () => {
                            if (xhr.status === 200) {
                                const str = xhr.responseText;
                                this.setState({
                                    importLoader: false
                                })
                                try {
                                    if (str === undefined || str === 'undefined' || str === '') {
                                        createNotification('error', 'The selected file is empty. Please select another text file with content. ');
                                        this.setState({
                                            googleSelectedFile: ''
                                        })
                                        return;
                                    }
                                    let array = str.split("\n");
                                    let customize_tag_id = []
                                    // ------Single / Multipule Tag Id------
                                    if (this.state.UploadFor === 'Selected') {
                                        customize_tag_id = JSON.stringify(this.state.select_Customize_tag_id)
                                    } else {
                                        let tagArray = this.state.tagData;
                                        for (let item of tagArray) {
                                            if (customize_tag_id.length === 0) {
                                                customize_tag_id = item.cc_tag_id
                                            }
                                            else {
                                                customize_tag_id = customize_tag_id + "," + item.cc_tag_id
                                            }
                                        }

                                    }
                                    // ------Single / Multipule Tag Id------

                                    const { location = {} } = this.props
                                    const { state = {} } = location;
                                    const { classDetail = '' } = state;
                                    const value = {
                                        class_id: JSON.stringify(classDetail.cc_class_id),
                                        customize_tag_id: customize_tag_id,
                                        speed_notes_array: array,
                                        selected_id: JSON.stringify(this.state.select_Customize_tag_id),
                                        filter_text: ""
                                    }
                                    this.setState({
                                        SelectFileModal: false,
                                    })
                                    this.importTXTFile(value)

                                } catch (e) {
                                    createNotification('error', 'Something Went Wrong, Not able to read File');
                                    return;
                                }
                            } else {
                                createNotification('error', 'Something Went Wrong, Not able to fetch file Detail');
                                this.setState({
                                    GetFileLoader: false,
                                    importLoader: false
                                })
                                return;
                            }

                        };
                        xhr.onerror = function () {
                        };
                        xhr.send();
                    } else {
                        createNotification('error', 'Something Went Wrong, Not able to fetch file Detail');
                        this.setState({
                            GetFileLoader: false,
                            importLoader: false
                        })
                        return;
                    }
                })

            });
        } catch (e) {
            createNotification('error', 'Something Went Wrong, Not able to fetch file Detail');
            this.setState({
                GetFileLoader: false,
                importLoader: false
            })
            return;
        }
    }
    // ----------To Get File Read from Drive ---------

    // ----------To Send File Read Data In API ---------
    importTXTFile = (value) => {
        window.$('#myModalTagupload').modal('hide');
        this.props.importSpeedNote(value);
        window.$('#myModalTagSpeedAdd').modal('show');
        this.setState({
            SpeedNoteData: [],
            googleDrivePath: [],
            speedNoteModalOpen: true
        })

    }
    // ----------To Send File Read Data In API ---------

    render() {
        let { tagData, Changes, SpeedNoteData, GoogleAuthItem, GoogleFiles, GetFileLoader, googleDrivePath, importLoader } = this.state
        const { loader, speedTagloader } = this.props
        let driveEmail = '';

        const { location = {} } = this.props
        const { state = {} } = location;
        const { classPermission } = state;
        const disConfirmSaveTag = [ 'confirmSaveTag',  classPermission !== null && classPermission === "read"? 'opacityProp' : '' ]
        const disCloseTagIcon = [ 'closeTagIcon',  classPermission !== null && classPermission === "read"? 'opacityProp' : '' ]
        // if (GoogleAuthItem) {
        //     driveEmail = GoogleAuthItem.Qt.zu.replace(/[@]/g, ' @')
        // }
        if (GoogleAuthItem) {
            if(GoogleAuthItem.hasOwnProperty("wt")){
               driveEmail = GoogleAuthItem.wt.cu
           }else if(GoogleAuthItem.hasOwnProperty("Qt")){
               driveEmail = GoogleAuthItem.Qt.zu
           }
       }
        return (
            <div className='classTagContainer'>
                {/* ---------Class Back Div ------ */}
                <div className="TagYearMainDiv">
                    <div className="classBackDiv" onClick={() => { this.BackTOHome() }}>
                        <i className="classBackIcon material-icons">chevron_left</i>
                        <p className='classBackYearText'>Class</p>
                    </div>
                    <div>
                        {!Changes?'': classPermission !== null && classPermission === "read"?
                        <div className={disConfirmSaveTag.join(' ')}>
                            <i className="confirmSaveTagIcon material-icons">check</i>
                        </div>:
                        <div className='confirmSaveTag' onClick={() => { this.saveTags() }}>
                            <i className="confirmSaveTagIcon material-icons">check</i>
                        </div>}
                    </div>

                </div>
                {/* ---------Class Back Div ------ */}



                {/* ---------Tag Heading Div ------ */}
                <div className="headingTagMainDiv">
                    <Row style={{ width: '100%' }}>
                        <Col xs="2" className='headingTagMainText'>
                            Snapshot Note
                    </Col>
                        <Col xs="6" className='headingTagMainText'>
                            Tag Name
                    </Col>
                        <Col xs="2" className='headingTagMainText'>
                            <span className="speedNoteheading">Speed Note</span>
                        </Col>
                        <Col xs="2" className='headingTagMainText text-left'>
                            <span className="abbreviationheading">Abbreviation</span>
                        </Col>
                    </Row>

                </div>
                {/* ---------Tag Heading Div ------ */}

                {/* ------ Loader Div----- */}
                {loader && <div>
                    <img src={loaderImag} alt='' className='loaderIconCss' />
                </div>}
                {/* ------ Loader Div----- */}

                {/* ---------Tag Main Div ------ */}
                {tagData.length > 0 && tagData.map((item, index) => (<Row key={index} className="tagMainDiv">
                    <Col xs="col col-11" className='tagValueMainDiv'>
                        <Row className='tagValueRow'>
                            <Col className='centerInRow' xs="col col-2 pl-0 pr-0">
                                <i onClick={() => { this.check(index, item) }} className={`${item.cc_tag_checked === 'yes' ? 'checkBoxColorTag' : 'radio_button_unchecked'}  material-icons`}>{item.cc_tag_checked === 'yes' ? 'check_circle' : 'radio_button_unchecked'}</i>
                            </Col>
                            <Col xs="col col-7 pl-0 pr-0">
                                <CommonTextField
                                    margin="normal"
                                    variant="outlined"
                                    type={item.InputLabel}
                                    value={item.cc_tag_title}
                                    onChange={ classPermission !== null && classPermission === "read"? '' :(e) => this.handleChangeInput(e, index)}
                                    name='Tag Name'
                                    className="inputInputType"
                                    placeholder='Enter Tag'
                                    label='Enter Tag'
                                />
                            </Col>
                            <Col className='centerInRow' xs="col col-2 pl-0 pr-0">

                                <img onClick={() => { this.selectSpeedTag(item) }} src={flashActiveTag} alt='' className='flastTagIcon' />

                            </Col>
                            <Col xs="col col-1 pr-0 pl-0">
                                <CommonTextField
                                    
                                    margin="normal"
                                    variant="outlined"
                                    type={item.AbbreviationLabel}
                                    value={item.cc_tag_abbre}
                                    onChange={classPermission !== null && classPermission === "read"? '' :(e) => this.handleChangeInput(e, index)}
                                    name='Abbreviation'
                                    className="inputInputType"
                                    placeholder='Abbreviation'
                                    label='Abbreviation'
                                />
                                 
                            </Col>
                        </Row>
                    </Col>
                    <Col className='closeTagDiv' xs="col col-1 " >
                        {!tagData.length > 1 ?'': classPermission !== null && classPermission === "read"?
                        <img src={closeTag} alt='' className= {disCloseTagIcon.join(' ')}/>
                        :<img onClick={() => { this.removeTagValue(item, index) }} src={closeTag} alt='' className='closeTagIcon' />
                        }
                    </Col>
                </Row>))}

                {/* ---------Tag Main Div ------ */}
                {/* ---------Add Icon Main Div ------ */}
                {tagData.length < 5 && tagData.length > 0 && <div className='addTagIconDiv' >
                    <img onClick={() => { this.addTagValue() }} src={addTag} alt='' className='addTagIconImage' />
                </div>}
                {/* ---------Add Icon Main Div ------ */}

                {/* ---------------Modal for Link  Div--------------- */}
                <div>
                    <Modal size="sm" show={this.state.ExitModal} centered={true}>


                        <Modal.Body style={{ textAlign: 'center' }}>
                            <div className='TagSaveModalMain'>
                                <p className='TagSaveModalNoteText'>Update Note</p>
                            </div>
                            <p className='TagSaveModalWorningText'>Are you want to save all tag date before exit?</p>
                            <div className='permissionButtonsDiv'>
                                <div className='backButtonTagText' onClick={() => {
                                  this.props.history.goBack();
                                }}>
                                    Back
                                </div>
                                <div>
                                    <Button onClick={() => {
                                        this.setState({
                                            ExitModal: false
                                        })
                                        this.saveTags()
                                        // this.props.deleteClassTag(this.state.deleteTagDetails)
                                    }} className='modalSaveTagButton modalSavveButtonBorderRadius' color="primary">
                                        <span className='modalSaveTagButtonText' > Save</span>
                                    </Button>
                                </div>
                            </div>


                        </Modal.Body>

                    </Modal>
                </div>
                {/* ---------------Modal  Div--------------- */}

                  {/* ---------------Modal for Delete  Div--------------- */}
                  <div>
                    <Modal size="sm" show={this.state.deleteModal} centered={true}>


                        <Modal.Body style={{ textAlign: 'center' }}>
                            <div className='TagSaveModalMain'>
                                <p className='TagSaveModalNoteText'>Delete Note</p>
                            </div>
                            <p className='TagSaveModalWorningText'>Are you sure you want to delete this tag?</p>
                            <div className='permissionButtonsDiv deleteTagButtondiv'>
                                <div className='backButtonTagText' onClick={() => {
                                    this.setState({
                                        deleteModal : !this.state.deleteModal
                                    })
                                }}>
                                    Cancel
                                </div>
                                <div>
                                    <Button onClick={() => {
                                        this.setState({
                                            deleteModal: false
                                        })
                                        // this.saveTags()
                                        this.props.deleteClassTag(this.state.deleteTagDetails)
                                    }} className='modalSaveTagButton modalDeleteTagButton' color="primary">
                                        <span className='modalSaveTagButtonText modalDeleteTagButton' > Delete</span>
                                    </Button>
                                </div>
                            </div>


                        </Modal.Body>

                    </Modal>
                </div>
                {/* ---------------Modal  Div--------------- */}

                {/* ---------------Modal Add Speed Tag------------ */}
                <div className="modal right fade modalTagImport" id="myModalTagSpeedAdd" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className='uploadHeaderTagDiv'>
                                    <div>
                                        <button type="button" className="close closespeedtagbtn" data-dismiss="modal" aria-label="Close"><span className='cancelText' aria-hidden="true">  <img src={cancelBlack} alt='' className='closeIconInModal' /> Cancel</span></button>
                                    </div>
                                    <div>
                                        <img src={cloudeIconUpload} alt='' className='cloudeIconInModal' onClick={() => { this.openUploadDrive() }} />

                                    </div>

                                </div>
                            </div>

                            <div className="modal-body">
                                {speedTagloader && <div>
                                    <img src={loaderImag} alt='' className='loaderIconCss' />
                                </div>}
                                {SpeedNoteData && SpeedNoteData.length > 0 ? <div>
                                    {SpeedNoteData.map((item, index) => (
                                        <div key={index} className='modalSelectTagDiv'>
                                            <p className='modalSelectTagText'>{item.cc_speed_note_text}</p>

                                        </div>))}

                                </div> :
                                    <div>
                                        {speedTagloader ? <div>

                                        </div> : <div className='modalSelectTagDiv'>
                                                <p className='modalSelectTagText'>No Data Found</p>
                                            </div>}
                                    </div>}




                            </div>
                        </div>
                    </div>
                </div>
                {/* ---------------Modal Add Speed Tag------------ */}

                {/* ---------------Modal Upload------------ */}
                <div className="modal right fade modalTagImport" id="myModalTagupload" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className='uploadHeaderTagDiv'>
                                    <div>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span className='cancelText' aria-hidden="true">  <img src={cancelBlack} alt='' className='closeIconInModal' /> Cancel Import</span></button>
                                    </div>
                                    <div>
                                        {this.state.UploadFrom !== 'drive' ? <Button disabled={true} className='importRosterButton importdrivebtn' color="primary" 
                                         style={{
                                            position : 'absolute',
                                            top : 13,
                                            roght : 10,
                                            left : 'auto',
                                            right: 5
                                          }}>
                                            <span className='importRosterText' > Import</span>
                                        </Button> :
                                            <Button disabled={this.state.GoogleAuth ? false : true} onClick={() => { this.handleFileLoad() }} className='importRosterButton importdrivebtn' 
                                            color="primary"
                                            style={{
                                                position : 'absolute',
                                                top : 13,
                                                roght : 10,
                                                left : 'auto',
                                                right: 5
                                              }} >
                                                <span className='importRosterText' > Import</span>
                                            </Button>}
                                        {/* <Button disabled={this.state.UploadFrom === '' ? false : true} className='importRosterButton' color="primary" >
                                            <span className='importRosterText' > Import</span>
                                        </Button>*/}
                                    </div>

                                </div>
                            </div>

                            <div className="modal-body">
                                {/* ---------------Note option------------ */}
                                <div className='uploadNoteTagDiv'>
                                    <p style={{ fontWeight: 600 }}>Note</p>
                                    <p className='uploadNoteTagText'>1. Support .txt file only</p>
                                </div>
                                {/* ---------------Note option------------ */}


                                {/* --------------- Upload option------------ */}
                                <div className='selectMainDivforTagUpload'>
                                    <div className='selectOptionInTag'>
                                        <div className='checkbozTagCircle' onClick={() => { this.setState({ UploadFrom: 'drive' }) }}>
                                            {this.state.UploadFrom === 'drive' && <div className='checkbozCircleTagSelected'></div>}
                                        </div>
                                        <img src={googleDriveIcon} alt='' className='selectoptionUploadImage' />
                                        {!this.state.GoogleAuth ? <p className='selectTagText'>Google Drive</p> : <p className='loginEmailText'>{driveEmail}</p>}
                                        <div>
                                            {!this.state.GoogleAuth ?
                                                <Button disabled={this.state.UploadFrom === 'drive' ? false : true} className='autharizedTagButton' color="primary" onClick={() => this.handleClientLoad()} >
                                                    <span className='autharizedTagText' > Authorize</span>
                                                </Button> :
                                                <Button disabled={this.state.UploadFrom === 'drive' ? false : true} className='logoutButton' color="primary" onClick={() => this.handleSignOutClick()}  >
                                                    <span className='logoutText' > Logout</span>
                                                </Button>}

                                        </div>
                                    </div>

                                    <div className='selectOptionInTag'>
                                        <div className='checkbozTagCircle' onClick={() => { this.setState({ UploadFrom: 'drop' }) }}>
                                            {this.state.UploadFrom !== 'drive' && <div className='checkbozCircleTagSelected'></div>}
                                        </div>
                                        <img src={localFolderIcon} alt='' className='selectoptionUploadImageforTagDrop' />
                                        <p className='selectTagText'>File App</p>
                                        <div>
                                            <ReactFileReader disabled={this.state.UploadFrom !== 'drive' ? false : true} handleFiles={this.handleImportFiles} fileTypes={'.txt'}>

                                                <Button disabled={this.state.UploadFrom !== 'drive' ? false : true} className='autharizedTagButton' color="primary" >
                                                    <span className='autharizedTagText' > Select</span>
                                                </Button>
                                            </ReactFileReader>
                                        </div>
                                    </div>

                                </div>
                                {/* ---------------Upload option------------ */}
                                <div className='selectMultipuleTagDiv'>
                                    <div style={{ display: "flex" }}>
                                        <div className='selectcheckTag' onClick={() => { this.setState({ UploadFor: 'Selected' }) }}>
                                            {this.state.UploadFor === 'Selected' && <div className='selectcheckTagSelected'></div>}
                                        </div>
                                        <p className='selectMultipuleTagText'>Selected Note Tag</p>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div className='selectcheckTag' onClick={() => { this.setState({ UploadFor: 'All' }) }}>
                                            {this.state.UploadFor === 'All' && <div className='selectcheckTagSelected'></div>}
                                        </div>
                                        <p className='selectMultipuleTagText'>All Note Tags</p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                {/* ---------------Modal Upload------------ */}

                {/* ---------------Import File From Drive Modal------------ */}
                <Modal centered className="selectTag-DriveFile-modal br-8" show={this.state.SelectFileModal} >
                    <Modal.Header>
                        <Modal.Title>Google Drive Import</Modal.Title>
                        {this.state.googleSelectedFile && <Button className='modalSaveTagButton' variant="success" onClick={() => { this.getFileDetails() }}>
                            <img src={tickIcon} alt='' height={20} width={20} />
                        </Button>}
                    </Modal.Header>
                    <Modal.Body>
                        {importLoader ? <div style={{ width: '100%', textAlign: 'center' }}>
                            <img src={loaderImag} alt='' className='importDriveLoader' />
                        </div> :
                            <div>
                                <div className='inline-Path'>
                                    <ul className="breadcrumb">
                                        {googleDrivePath.map((value, i) => (<li key={i} onClick={() => { this.getFolderFilesPath(value) }}><span className='cursor'>{value.name}</span></li>))}
                                    </ul>
                                </div>
                                <div className='modal-list-body'>
                                    {GetFileLoader && <div style={{ width: '100%', textAlign: 'center' }}>
                                        <img src={loaderImag} alt='' className='loaderIconCss' />
                                    </div>}
                                    {GoogleFiles && GoogleFiles.length > 0 ? <>
                                        {GoogleFiles && GoogleFiles.length > 0 && GoogleFiles.map((item, index) => (
                                            <div className='file_listDiv' key={index}>
                                                {item.fileExtension === "txt" ? <div style={{ display: 'inline-flex' }}>
                                                    <img src={driveCSVIcon} alt='' className='selectoptionUploadFolder' />

                                                    <p className='fileName'>{item.name}</p>
                                                </div> :
                                                    <div className='selectFileCursor' onClick={() => { this.getFolderFiles(item) }}>
                                                        <img src={driveFolderIcon} alt='' className='selectoptionUploadFolder' />

                                                        <p className='fileName'>{item.name}</p>
                                                    </div>}
                                                {item.fileExtension === "txt" && <i onClick={() => {
                                                    this.setState({
                                                        googleSelectedFile: item
                                                    })
                                                }} className={`${this.state.googleSelectedFile.id === item.id ? 'checkBoxSelect' : 'unCheckBoxSelect'} material-icons`} >
                                                    {this.state.googleSelectedFile.id === item.id ? 'check_circle' : 'radio_button_unchecked'}
                                                </i>}

                                            </div>
                                        ))}
                                    </> :
                                        <>
                                            {GetFileLoader ? <div></div> :
                                                <div style={{ textAlign: 'center', marginTop: 20 }}>
                                                    <p className='unSelectedText'>No File Avaliable</p>
                                                </div>}
                                        </>}
                                </div>
                            </div>}

                    </Modal.Body>


                    <Modal.Footer>
                        <Button className='modalcancleTagButton' variant="danger" onClick={() => {
                            this.setState({
                                SelectFileModal: false
                            })
                        }}>
                            <img src={closeIcon} alt='' height={40} width={30} />
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* ---------------Import File From Drive Modal------------ */}


            </div>
        )
    }
}
const mapStateToProps = state => ({
    state: state,
    classTag: state.classTag,
    loader: state.classTag.loader,
    speedTagloader: state.classTag.speedTagloader
});
const mapDispatchToProps = dispatch => {
    return {
        getClassTag: (data) => dispatch(getClassTag(data)),
        editClassTag: (data) => dispatch(editClassTag(data)),
        getSpeedNote: (data) => dispatch(getSpeedNote(data)),
        importSpeedNote: (data) => dispatch(importSpeedNote(data)),
        deleteClassTag: (data) => dispatch(deleteClassTag(data))


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    ClassTag
);