

import {
    GET_NOT_SELECTED_ROSTERS,
    GET_NOT_SELECTED_ROSTERS_SUCCESS,
    GET_NOT_SELECTED_ROSTERS_FAILURE,

    ADD_SELECTED_ROSTERS,
    ADD_SELECTED_ROSTERS_SUCCESS,
    ADD_SELECTED_ROSTERS_FAILURE

} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */



// -----------Get Not RosterList-------------
export function getNotSelectedRosters(data) {
    return {
        type: GET_NOT_SELECTED_ROSTERS,
        data,
    };
}

export function getNotSelectedRostersSuccess(data) {
    return {
        type: GET_NOT_SELECTED_ROSTERS_SUCCESS,
        data,
    };
}

export function getNotSelectedRostersFailure(data) {
    return {
        type: GET_NOT_SELECTED_ROSTERS_FAILURE,
        data,
    };
}


// -----------Add Select Roster-------------
export function addSelectedRosters(data) {
    return {
        type: ADD_SELECTED_ROSTERS,
        data,
    };
}

export function addSelectedRostersSuccess(data) {
    return {
        type: ADD_SELECTED_ROSTERS_SUCCESS,
        data,
    };
}

export function addSelectedRostersFailure(data) {
    return {
        type: ADD_SELECTED_ROSTERS_FAILURE,
        data,
    };
}








