import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


// import Logger from '../Lib/Logger'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    placeSearch: ['data'],
    placeSearchSuccess: ['data'],
    placeSearchFailure: ['error'],

    selectPlace: ['data'],
    selectPlaceSuccess: ['data'],
    selectPlaceFailure: ['error'],

})

export const UserPlaceSearchReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    placeSearch_Data: null,
    selectedPlace_Data: null,
    error: null,
    exists: null,
    action: null
})

/* ------------- Reducers ------------- */

/* placeSearch */
export const placeSearch = (state) => {
    return {
        ...state, fetching: true, error: null, placeSearch_Data: null,
    }
}
export const placeSearchSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, action: action.type, placeSearch_Data: data, exists: true }
}
export const placeSearchFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, }
}



/* selectPlace */
export const selectPlace = (state) => {
    return {
        ...state, fetching: true, error: null, selectedPlace_Data: null,
    }
}
export const selectPlaceSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, action: action.type, selectedPlace_Data: data, exists: true }
}
export const selectPlaceFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, }
}


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.PLACE_SEARCH]: placeSearch,
    [Types.PLACE_SEARCH_SUCCESS]: placeSearchSuccess,
    [Types.PLACE_SEARCH_FAILURE]: placeSearchFailure,

    [Types.SELECT_PLACE]: selectPlace,
    [Types.SELECT_PLACE_SUCCESS]: selectPlaceSuccess,
    [Types.SELECT_PLACE_FAILURE]: selectPlaceFailure,

})
