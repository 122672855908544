import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({

    getClassList: ['data'],
    getClassListSuccess: ['data'],
    getClassListFailure: ['error'],

    addClass: ['data'],
    addClassSuccess: ['data'],
    addClassFailure: ['error'],

    editClass: ['data'],
    editClassSuccess: ['data'],
    editClassFailure: ['error'],

    deleteClass: ['data'],
    deleteClassSuccess: ['data'],
    deleteClassFailure: ['error'],

    searchClass: ['data'],
    searchClassSuccess: ['data'],
    searchClassFailure: ['error'],


    editClassColorWheel: ['data'],
    editClassColorWheelSuccess: ['data'],
    editClassColorWheelFailure: ['error'],

    setClassSidebarOpen: [''],
    setClassState: ['data']


})

export const UserClassReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    class_Data: null,
    Add_class_Data: null,
    Edit_class_Data: null,
    Delete_class_Data: null,
    search_class: null,
    EditClassColorWheel: null,
    sideMenu: false,
    error: null,
    exists: null,
    loader: null,
    action: null
})

/* ------------- Reducers ------------- */

export const setClassState = (state) => {
    return {
        ...state,
        class_Data: null,
        Add_class_Data: null,
        Edit_class_Data: null,
        Delete_class_Data: null,
        deleteClassLoader: false,
        search_class: null,
        EditClassColorWheel: null,
        sideMenu: false,
        error: null,
        exists: null,
        loader: null,
        action: null
    }
}


/* getClassList */
export const getClassList = (state) => {
    return {
        ...state, fetching: true, error: null, class_Data: null, loader: true
    }
}
export const getClassListSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, class_Data: data, action: action.type, exists: true, loader: false }
}
export const getClassListFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_CLASS_LIST_FAILURE', loader: false }
}


/* Add Class */
export const addClass = (state) => {
    return {
        ...state, fetching: true, error: null, Add_class_Data: null, loader: true
    }
}
export const addClassSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, Add_class_Data: data, action: action.type, exists: true, loader: false }
}
export const addClassFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'ADD_CLASS_FAILURE', loader: false }
}


/* Edit Class */
export const editClass = (state) => {
    return {
        ...state, fetching: true, error: null, Edit_class_Data: null, loader: true
    }
}
export const editClassSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, Edit_class_Data: data, action: action.type, exists: true, loader: false }
}
export const editClassFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'EDIT_CLASS_FAILURE', loader: false }
}


/* Delete Class */
export const deleteClass = (state) => {
    return {
        ...state, fetching: true, error: null, Delete_class_Data: null, loader: true, deleteClassLoader:true
    }
}
export const deleteClassSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, Delete_class_Data: data, action: action.type, exists: true, loader: false , deleteClassLoader: false}
}
export const deleteClassFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'DELETE_CLASS_FAILURE', loader: false, deleteClassLoader: false }
}


export const editClassColorWheel = (state) => {
    return {
        ...state, fetching: true, error: null, EditClassColorWheel: null, loader: true
    }
}
export const editClassColorWheelSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, EditClassColorWheel: data, action: action.type, exists: true, loader: false }
}
export const editClassColorWheelFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'EDIT_CLASS_COLOR_WHEEL_FAILURE', loader: false }
}

export const setClassSidebarOpen = (state, action) => {
    return { ...state, sideMenu: action.data }
}


/* searchClass */
export const searchClass = (state) => {
    return {
        ...state, fetching: true, error: null, search_class: null, loader: true
    }
}
export const searchClassSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, search_class: data, action: action.type, exists: true, loader: false }
}
export const searchClassFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'SEARCH_CLASS_FAILURE', loader: false }
}


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.GET_CLASS_LIST]: getClassList,
    [Types.GET_CLASS_LIST_SUCCESS]: getClassListSuccess,
    [Types.GET_CLASS_LIST_FAILURE]: getClassListFailure,

    [Types.ADD_CLASS]: addClass,
    [Types.ADD_CLASS_SUCCESS]: addClassSuccess,
    [Types.ADD_CLASS_FAILURE]: addClassFailure,

    [Types.EDIT_CLASS]: editClass,
    [Types.EDIT_CLASS_SUCCESS]: editClassSuccess,
    [Types.EDIT_CLASS_FAILURE]: editClassFailure,

    [Types.DELETE_CLASS]: deleteClass,
    [Types.DELETE_CLASS_SUCCESS]: deleteClassSuccess,
    [Types.DELETE_CLASS_FAILURE]: deleteClassFailure,


    [Types.EDIT_CLASS_COLOR_WHEEL]: editClassColorWheel,
    [Types.EDIT_CLASS_COLOR_WHEEL_SUCCESS]: editClassColorWheelSuccess,
    [Types.EDIT_CLASS_COLOR_WHEEL_FAILURE]: editClassColorWheelFailure,

    [Types.SET_CLASS_SIDEBAR_OPEN]: setClassSidebarOpen,

    [Types.SEARCH_CLASS]: searchClass,
    [Types.SEARCH_CLASS_SUCCESS]: searchClassSuccess,
    [Types.SEARCH_CLASS_FAILURE]: searchClassFailure,

    [Types.SET_CLASS_STATE]: setClassState,


})