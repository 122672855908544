

import {
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,

    REGISTER_RESET
} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */
export function register(data) {
    return {
        type: REGISTER,
        data,
    };
}

export function registerSuccess(data) {
    return {
        type: REGISTER_SUCCESS,
        data,
    };
}

export function registerFailure(data) {
    return {
        type: REGISTER_FAILURE,
        data,
    };
}

export function registerReset() {
    return {
        type: REGISTER_RESET,

    };
}



