import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({

    addStudentNote: ['data'],
    addStudentNoteSuccess: ['data'],
    addStudentNoteFailure: ['error'],

    editStudentNote: ['data'],
    editStudentNoteSuccess: ['data'],
    editStudentNoteFailure: ['error'],


    getStudentNote: ['data'],
    getStudentNoteSuccess: ['data'],
    getStudentNoteFailure: ['error'],


    deleteStudentNote: ['data'],
    deleteStudentNoteSuccess: ['data'],
    deleteStudentNoteFailure: ['error'],

    deleteGroupNoteFromSingleStudent: ['data'],
    deleteGroupNoteFromSingleStudentSuccess: ['data'],
    deleteGroupNoteFromSingleStudentError: ['error'],


    getStudentClasses: ['data'],
    getStudentClassesSuccess: ['data'],
    getStudentClassesFailure: ['error'],


    setStudentArtifact: ['data'],
    setStudentArtifactSuccess: ['data'],
    setStudentArtifactFailure: ['error'],


    setMediaAttachmentArtifact: ['data'],
    setMediaAttachmentArtifactSuccess: ['data'],
    setMediaAttachmentArtifactFailure: ['error'],


    addStudentMiscellaneous: ['data'],
    addStudentMiscellaneousSuccess: ['data'],
    addStudentMiscellaneousFailure: ['error'],

    editStudentMiscellaneous: ['data'],
    editStudentMiscellaneousSuccess: ['data'],
    editStudentMiscellaneousFailure: ['error'],

    deleteStudentMiscellaneous: ['data'],
    deleteStudentMiscellaneousSuccess: ['data'],
    deleteStudentMiscellaneousFailure: ['error'],

    getGroupNoteStudents: ['data'],
    getGroupNoteStudentsSuccess: ['data'],
    getGroupNoteStudentsFailure: ['error'],

    getAudioFromVertex: ['data'],
    getAudioFromVertexError: ['error'],
    getAudioFromVertexSuccess: ['data'],

    getAudioSummaryFromVertex: ['data'],
    getAudioSummaryFromVertexError: ['error'],
    getAudioSummaryFromVertexSuccess: ['data'],
    resetStudentNotes: ['data']
})

export const UserStudentNoteReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    Add_Student_Note: null,
    Edit_Student_Note: null,
    Get_Student_Note: null,
    Delete_Student_Note: null,
    Delete_Group_Note_From_Single_Student:null,
    Get_Student_Classes: null,
    Set_Student_Artifact: null,
    Set_Media_Attachment_Artifact: null,
    Add_Student_Miscellaneous: null,
    Edit_Student_Miscellaneous: null,
    Delete_Student_Miscellaneous: null,
    students_List_Note: null,
    error: null,
    exists: null,
    loader: null,
    Notesloader: null,
    GroupNotesLoader: null,
    action: null,
    getTranscribeAudio: null,
    isLoadingGetTranscribeAudio: false,
    getTranscribeAudioSuccess: null,
    getTranscribeAudioError: null,
    getAudioSummaryVertextSuccess: null,
    isLoadingGetsummaryAudio: false,
    getAudioSummaryVertextError: null
})

/* ------------- Reducers ------------- */


/* addStudentNote */

export const addStudentNote = (state) => {
    return {
        ...state, fetching: true, error: null, Add_Student_Note: null, Notesloader: true,
        getTranscribeAudio: null,
    isLoadingGetTranscribeAudio: false,
    getTranscribeAudioSuccess: null,
    getTranscribeAudioError: null,
    getAudioSummaryVertextSuccess: null,
    isLoadingGetsummaryAudio: false,
    getAudioSummaryVertextError: null
    }
}
export const addStudentNoteSuccess = (state, action) => {
    sessionStorage.removeItem('noteCommentData')
    sessionStorage.removeItem('finalTranscribedAudio')
    sessionStorage.removeItem('finalNoteCommentCardsAfterRemove')
    const { data } = action
    return { ...state, fetching: false, error: null, Add_Student_Note: data, action: action.type, exists: true, Notesloader: false }
}
export const addStudentNoteFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'ADD_STUDENT_NOTE_FAILURE', Notesloader: false }
}


/* editStudentNote */

export const editStudentNote = (state) => {
    return {
        ...state, fetching: true, error: null, Edit_Student_Note: null, Notesloader: true
    }
}
export const editStudentNoteSuccess = (state, action) => {
    sessionStorage.removeItem('noteCommentData')
    sessionStorage.removeItem('finalTranscribedAudio')
    sessionStorage.removeItem('finalNoteCommentCardsAfterRemove')
    const { data } = action
    return { ...state, fetching: false, error: null, Edit_Student_Note: data, action: action.type, exists: true, Notesloader: false }
}
export const editStudentNoteFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'EDIT_STUDENT_NOTE_FAILURE', Notesloader: false }
}



/* getStudentNote */

export const getStudentNote = (state) => {
    sessionStorage.removeItem('noteCommentData')
    sessionStorage.removeItem('finalTranscribedAudio')
    sessionStorage.removeItem('finalNoteCommentCardsAfterRemove')
    return {
        ...state, fetching: true, error: null, Get_Student_Note: null, Notesloader: true
    }
}
export const getStudentNoteSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, Get_Student_Note: data, action: action.type, exists: true, Notesloader: false }
}
export const getStudentNoteFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_STUDENT_NOTE_FAILURE', Notesloader: false }
}

/* deleteStudentNote */
export const deleteStudentNote = (state) => {
    sessionStorage.removeItem('noteCommentData')
    sessionStorage.removeItem('finalTranscribedAudio')
    sessionStorage.removeItem('finalNoteCommentCardsAfterRemove')
    return {
        ...state, fetching: true, error: null, Delete_Student_Note: null, Notesloader: true,
        getTranscribeAudio: null,
    isLoadingGetTranscribeAudio: false,
    getTranscribeAudioSuccess: null,
    getTranscribeAudioError: null,
    getAudioSummaryVertextSuccess: null,
    isLoadingGetsummaryAudio: false,
    getAudioSummaryVertextError: null
    }
}
export const deleteStudentNoteSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, Delete_Student_Note: data, action: action.type, exists: true, Notesloader: false }
}
export const deleteStudentNoteFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'DELETE_STUDENT_NOTE_FAILURE', Notesloader: false }
}

/* deletegroupnotesinglestudent*/
export const deleteGroupNoteFromSingleStudent = (state) => {
    return {
        ...state, fetching: true, error: null, Delete_Group_Note_From_Single_Student: null, Notesloader: true
    }
}

export const deleteGroupNoteFromSingleStudentError = (state,  { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT_SUCCESS', Notesloader: false }
}

export const deleteGroupNoteFromSingleStudentSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, Delete_Group_Note_From_Single_Student: data, action: action.type, exists: true, Notesloader: false }
}


/* getStudentClasses */

export const getStudentClasses = (state) => {
    return {
        ...state, fetching: true, error: null, Get_Student_Classes: null, loader: true
    }
}
export const getStudentClassesSuccess = (state, action) => {

    const { data } = action
    
    return { ...state, fetching: false, error: null, Get_Student_Classes: data.data, action: action.type, exists: true, loader: false }
}
export const getStudentClassesFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_STUDENT_CLASSES_FAILURE', loader: false }
}


/* setStudentArtifact */

export const setStudentArtifact = (state) => {
    return {
        ...state, fetching: true, error: null, Set_Student_Artifact: null, Notesloader: true
    }
}
export const setStudentArtifactSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, Set_Student_Artifact: data.response_data, action: action.type, exists: true, Notesloader: false }
}
export const setStudentArtifactFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'SET_STUDENT_ARTIFACT_FAILURE', Notesloader: false }
}
/* set Media Attachment Artifact */

export const setMediaAttachmentArtifact = (state) => {
    return {
        ...state, fetching: true, error: null, Set_Media_Attachment_Artifact: null, Notesloader: true
    }
}
export const setMediaAttachmentArtifactSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, Set_Media_Attachment_Artifact: data.response_data, action: action.type, exists: true, Notesloader: false }
}
export const setMediaAttachmentArtifactFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'SET_MEDIA_ATTACHMENT_ARTIFACT_FAILURE', Notesloader: false }
}




/* addStudentMiscellaneous */

export const addStudentMiscellaneous = (state) => {
    return {
        ...state, fetching: true, error: null, Add_Student_Miscellaneous: null, Notesloader: true
    }
}
export const addStudentMiscellaneousSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, Add_Student_Miscellaneous: data.data, action: action.type, exists: true, Notesloader: true }
}
export const addStudentMiscellaneousFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'ADD_STUDENT_MISCELLANEOUS_FAILURE', Notesloader: false }
}


/* editStudentMiscellaneous */

export const editStudentMiscellaneous = (state) => {
    return {
        ...state, fetching: true, error: null, Edit_Student_Miscellaneous: null, Notesloader: true
    }
}
export const editStudentMiscellaneousSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, Edit_Student_Miscellaneous: data.response_data, action: action.type, exists: true, Notesloader: true }
}
export const editStudentMiscellaneousFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'EDIT_STUDENT_MISCELLANEOUS_FAILURE', Notesloader: false }
}


/* deleteStudentMiscellaneous */

export const deleteStudentMiscellaneous = (state) => {
    return {
        ...state, fetching: true, error: null, Delete_Student_Miscellaneous: null, Notesloader: true
    }
}
export const deleteStudentMiscellaneousSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, Delete_Student_Miscellaneous: data.response_data, action: action.type, exists: true, Notesloader: true }
}
export const deleteStudentMiscellaneousFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'DELETE_STUDENT_MISCELLANEOUS_FAILURE', Notesloader: false }
}


/* getGroupNoteStudents */

export const getGroupNoteStudents = (state) => {
    return {
        ...state, fetching: true, error: null, students_List_Note: null, GroupNotesLoader: true
    }
}
export const getGroupNoteStudentsSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, students_List_Note: data.response_data, action: action.type, exists: true, GroupNotesLoader: false }
}
export const getGroupNoteStudentsFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_GROUP_NOTE_STUDENTS_FAILURE', GroupNotesLoader: false }
}



export const getAudioFromVertex = (state) => {
    return {
        ...state, fetching: true, error: null,  getTranscribeAudio: null,
        isLoadingGetTranscribeAudio: true,
        getTranscribeAudioSuccess: null,
        getTranscribeAudioError: null,
        getAudioSummaryVertextSuccess: null,
        isLoadingGetsummaryAudio: false,
        getAudioSummaryVertextError: null
    }
}
export const getAudioFromVertexSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, action: action.type,  getTranscribeAudio: action?.data,
        isLoadingGetTranscribeAudio: false,
        getTranscribeAudioSuccess: action?.data,
        getTranscribeAudioError: null, }
}
export const getAudioFromVertexError = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_AUDIO_FROM_VERTEX_ERROR', getTranscribeAudio: null,
    isLoadingGetTranscribeAudio: false,
    getTranscribeAudioSuccess: null,
    getTranscribeAudioError: error }
}



export const getAudioSummaryFromVertex = (state) => {
    return {
        ...state, fetching: true, error: null,  getAudioSummaryVertextSuccess: null,
        isLoadingGetsummaryAudio: true,
        getAudioSummaryVertextError: null
    }
}
export const getAudioSummaryFromVertexSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, action: action.type,  getAudioSummaryVertextSuccess: action?.data,
        isLoadingGetsummaryAudio: false,
        getAudioSummaryVertextError: null }
}
export const getAudioSummaryFromVertexError = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_AUDIO_SUMMARY_FROM_VERTEX_ERROR', getAudioSummaryVertextSuccess: null,
    isLoadingGetsummaryAudio: false,
    getAudioSummaryVertextError: error }
}

export const resetStudentNotes = (state) => {
    sessionStorage.removeItem('noteCommentData')
    sessionStorage.removeItem('finalTranscribedAudio')
    sessionStorage.removeItem('finalNoteCommentCardsAfterRemove')
    return {
        ...state, 
        Add_Student_Note: null,
        Edit_Student_Note: null,
        Get_Student_Note: null,
        Delete_Student_Note: null,
        Delete_Group_Note_From_Single_Student:null,
        Get_Student_Classes: null,
        Set_Student_Artifact: null,
        Set_Media_Attachment_Artifact: null,
        Add_Student_Miscellaneous: null,
        Edit_Student_Miscellaneous: null,
        Delete_Student_Miscellaneous: null,
        students_List_Note: null,
        error: null,
        exists: null,
        loader: null,
        Notesloader: null,
        GroupNotesLoader: null,
        action: null,
        getTranscribeAudio: null,
        isLoadingGetTranscribeAudio: false,
        getTranscribeAudioSuccess: null,
        getTranscribeAudioError: null,
        getAudioSummaryVertextSuccess: null,
        isLoadingGetsummaryAudio: false,
        getAudioSummaryVertextError: null
    }
}




/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {


    [Types.GET_GROUP_NOTE_STUDENTS]: getGroupNoteStudents,
    [Types.GET_GROUP_NOTE_STUDENTS_SUCCESS]: getGroupNoteStudentsSuccess,
    [Types.GET_GROUP_NOTE_STUDENTS_FAILURE]: getGroupNoteStudentsFailure,


    [Types.ADD_STUDENT_NOTE]: addStudentNote,
    [Types.ADD_STUDENT_NOTE_SUCCESS]: addStudentNoteSuccess,
    [Types.ADD_STUDENT_NOTE_FAILURE]: addStudentNoteFailure,

    [Types.EDIT_STUDENT_NOTE]: editStudentNote,
    [Types.EDIT_STUDENT_NOTE_SUCCESS]: editStudentNoteSuccess,
    [Types.EDIT_STUDENT_NOTE_FAILURE]: editStudentNoteFailure,


    [Types.GET_STUDENT_NOTE]: getStudentNote,
    [Types.GET_STUDENT_NOTE_SUCCESS]: getStudentNoteSuccess,
    [Types.GET_STUDENT_NOTE_FAILURE]: getStudentNoteFailure,


    [Types.DELETE_STUDENT_NOTE]: deleteStudentNote,
    [Types.DELETE_STUDENT_NOTE_SUCCESS]: deleteStudentNoteSuccess,
    [Types.DELETE_STUDENT_NOTE_FAILURE]: deleteStudentNoteFailure,

    [Types.DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT]: deleteGroupNoteFromSingleStudent,
    [Types.DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT_SUCCESS]: deleteGroupNoteFromSingleStudentSuccess,
    [Types.DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT_ERROR]: deleteGroupNoteFromSingleStudentError,


    [Types.GET_STUDENT_CLASSES]: getStudentClasses,
    [Types.GET_STUDENT_CLASSES_SUCCESS]: getStudentClassesSuccess,
    [Types.GET_STUDENT_CLASSES_FAILURE]: getStudentClassesFailure,


    [Types.SET_STUDENT_ARTIFACT]: setStudentArtifact,
    [Types.SET_STUDENT_ARTIFACT_SUCCESS]: setStudentArtifactSuccess,
    [Types.SET_STUDENT_ARTIFACT_FAILURE]: setStudentArtifactFailure,


    [Types.ADD_STUDENT_MISCELLANEOUS]: addStudentMiscellaneous,
    [Types.ADD_STUDENT_MISCELLANEOUS_SUCCESS]: addStudentMiscellaneousSuccess,
    [Types.ADD_STUDENT_MISCELLANEOUS_FAILURE]: addStudentMiscellaneousFailure,

    [Types.EDIT_STUDENT_MISCELLANEOUS]: editStudentMiscellaneous,
    [Types.EDIT_STUDENT_MISCELLANEOUS_SUCCESS]: editStudentMiscellaneousSuccess,
    [Types.EDIT_STUDENT_MISCELLANEOUS_FAILURE]: editStudentMiscellaneousFailure,

    [Types.DELETE_STUDENT_MISCELLANEOUS]: deleteStudentMiscellaneous,
    [Types.DELETE_STUDENT_MISCELLANEOUS_SUCCESS]: deleteStudentMiscellaneousSuccess,
    [Types.DELETE_STUDENT_MISCELLANEOUS_FAILURE]: deleteStudentMiscellaneousFailure,

    [Types.GET_AUDIO_FROM_VERTEX]: getAudioFromVertex,
    [Types.GET_AUDIO_FROM_VERTEX_SUCCESS]: getAudioFromVertexSuccess,
    [Types.GET_AUDIO_FROM_VERTEX_ERROR]: getAudioFromVertexError,

    [Types.GET_AUDIO_SUMMARY_FROM_VERTEX]: getAudioSummaryFromVertex,
    [Types.GET_AUDIO_SUMMARY_FROM_VERTEX_SUCCESS]: getAudioSummaryFromVertexSuccess,
    [Types.GET_AUDIO_SUMMARY_FROM_VERTEX_ERROR]: getAudioSummaryFromVertexError,
    
    [Types.RESET_STUDENT_NOTES]: resetStudentNotes
    







})
