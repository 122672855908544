

import {
    SET_PAYMENT_DATA_REQUEST,
    SET_PAYMENT_DATA_SUCCESS,
    SET_PAYMENT_DATA_FAILURE,

    ADD_USER_SUBSCRIPTION_REQUEST,
    ADD_USER_SUBSCRIPTION_SUCCESS,
    ADD_USER_SUBSCRIPTION_FAILURE,

    ADD_PAYMENT_STATUS_SUCCESS,

} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */



// ----------- Set Payment  Request-------------
export function setPaymentDataRequest(data) {
    return {
        type: SET_PAYMENT_DATA_REQUEST,
        data,
    };
}

export function setPaymentDataSuccess(data) {
    return {
        type: SET_PAYMENT_DATA_SUCCESS,
        data,
    };
}

export function setPaymentDataFailure(data) {
    return {
        type: SET_PAYMENT_DATA_FAILURE,
        data,
    };
}


// ----------- Set Payment  Request-------------
export function addUserSubscriptionRequest(data) {
    return {
        type: ADD_USER_SUBSCRIPTION_REQUEST,
        data,
    };
}

export function addUserSubscriptionSuccess(data) {
    return {
        type: ADD_USER_SUBSCRIPTION_SUCCESS,
        data,
    };
}

export function addUserSubscriptionFailure(data) {
    return {
        type: ADD_USER_SUBSCRIPTION_FAILURE,
        data,
    };
}


export function addPaymentStatusSuccess(data) {
    return {
        type: ADD_PAYMENT_STATUS_SUCCESS,
        data,
    };
}






