import {
    GET_NOTIFICATION_REQUEST,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_FAILURE,

    REJECT_SHARED_CLASS_REQUEST ,
    REJECT_SHARED_CLASS_SUCCESS,
    REJECT_SHARED_CLASS_FAILURE,

    REJECT_SHARED_STUDENT_REQUEST,
    REJECT_SHARED_STUDENT_SUCCESS,
    REJECT_SHARED_STUDENT_FAILURE,


    DELETE_NOTIFICATION_REQUEST,
    DELETE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_FAILURE,


    NOTIFICATION_MARK_AS_READ_REQUEST,
    NOTIFICATION_MARK_AS_READ_SUCCESS,
    NOTIFICATION_MARK_AS_READ_FAILURE,
    

    GET_NOTIFICATION_COUNT_REQUEST,
    GET_NOTIFICATION_COUNT_SUCCESS,
    GET_NOTIFICATION_COUNT_FAILURE,
    GET_USER_PLAN_SPACE_DETAILS,
    GET_USER_PLAN_SPACE_DETAILS_FAILURE,
    GET_USER_PLAN_SPACE_DETAILS_SUCCESS

} from '../constants';

// -----------Get Notification Details-------------
export function getNotificationRequest(data) {
    return {
        type: GET_NOTIFICATION_REQUEST,
        data,
    };
}

export function getNotificationSuccess(data, page) {
    return {
        type: GET_NOTIFICATION_SUCCESS,
        data,
        page,
    };
}

export function getNotificationFailure(data) {
    return {
        type: GET_NOTIFICATION_FAILURE,
        data,
    };
}



// -----------Reject Shared Class Details-------------

export function rejectSharedClassRequest(data) {
    return {
        type: REJECT_SHARED_CLASS_REQUEST,
        data,
    };
}

export function rejectSharedClassSuccess(data, page) {
    return {
        type: REJECT_SHARED_CLASS_SUCCESS,
        data,
        page,
    };
}

export function rejectSharedClassFailure(data) {
    return {
        type: REJECT_SHARED_CLASS_FAILURE,
        data,
    };
}

// -----------Reject Shared Student Details-------------


export function rejectSharedStudentRequest(data) {
    return {
        type: REJECT_SHARED_STUDENT_REQUEST,
        data,
    };
}

export function rejectSharedStudentSuccess(data, page) {
    return {
        type: REJECT_SHARED_STUDENT_SUCCESS,
        data,
        page,
    };
}

export function rejectSharedStudentFailure(data) {
    return {
        type: REJECT_SHARED_STUDENT_FAILURE,
        data,
    };
}

// ----------Get User Space Details------------------
export function getUserPlanSpaceDetails(data) {
    return {
        type: GET_USER_PLAN_SPACE_DETAILS,
        data,
    };
}

export function getUserPlanSpaceDetailsFailure(data) {
    return {
        type: GET_USER_PLAN_SPACE_DETAILS_FAILURE,
        data,
    }
}

export function getUserPlanSpaceDetailsSuccess(data) {
    return {
        type: GET_USER_PLAN_SPACE_DETAILS_SUCCESS,
        data,
    }
}

// -----------Delete Notification Details-------------

export function deleteNotificationRequest(data) {
    return {
        type: DELETE_NOTIFICATION_REQUEST,
        data,
    };
}

export function deleteNotificationSuccess(data, page) {
    return {
        type: DELETE_NOTIFICATION_SUCCESS,
        data,
        page,
    };
}

export function deleteNotificationFailure(data) {
    return {
        type: DELETE_NOTIFICATION_FAILURE,
        data,
    };
}


// -----------Mark As Read Notification Details-------------

export function notificationMarkAsReadRequest(data) {
    return {
        type: NOTIFICATION_MARK_AS_READ_REQUEST,
        data,
    };
}

export function notificationMarkAsReadSuccess(data, page) {
    return {
        type: NOTIFICATION_MARK_AS_READ_SUCCESS,
        data,
        page,
    };
}

export function notificationMarkAsReadFailure(data) {
    return {
        type: NOTIFICATION_MARK_AS_READ_FAILURE,
        data,
    };
}



// -----------Get Notification Details-------------
export function getNotificationCountRequest(data) {
    return {
        type: GET_NOTIFICATION_COUNT_REQUEST,
        data,
    };
}

export function getNotificationCountSuccess(data, page) {
    return {
        type: GET_NOTIFICATION_COUNT_SUCCESS,
        data,
        page,
    };
}

export function getNotificationCountFailure(data) {
    return {
        type: GET_NOTIFICATION_COUNT_FAILURE,
        data,
    };
}

