import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getRubricList: ['data'],
    getRubricListSuccess: ['data'],
    getRubricListFailure: ['error'],

    addRubric: ['data'],
    addRubricSuccess: ['data'],
    addRubricFailure: ['error'],

    viewRubricDetails: ['data'],
    viewRubricDetailsSuccess: ['data'],
    viewRubricDetailsFailure: ['error'],

    deleteCriteria: ['data'],
    deleteCriteriaSuccess: ['data'],
    deleteCriteriaFailure: ['error'],

    viewStudentsAssessmentDetails: ['data'],
    viewStudentsAssessmentDetailsSuccess: ['data'],
    viewStudentsAssessmentDetailsFailure: ['error'],

    saveAssessment: ['data'],
    saveAssessmentSuccess: ['data'],
    saveAssessmentFailure: ['error'],

    deleteRubric: ['data'],
    deleteRubricSuccess: ['data'],
    deleteRubricFailure: ['error'],

    forwardRubricsReport: ['data'],
    forwardRubricsReportSuccess: ['data'],
    forwardRubricsReportFailure: ['error'],

    getDynamicRubricsCriteria : ['data'],
getDynamicRubricsCriteriaError: ['error'],
getDynamicRubricsCriteriaSuccess: ['data'],

deletePreviousCriteriaData: ['data'],
deletePreviousCriteriaDataError: ['error'],
deletePreviousCriteriaDataSuccess: ['data']

})

export const UserRubricsReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    Get_Rubric_List: null,
    Add_New_Rubrics: null,
    View_Rubric_Details: null,
    Delete_Criteria: null,
    View_Students_Assessment_Details: null,
    Save_Assessment: null,
    Delete_Rubric: null,
    Send_Rub_Email: null,

    error: null,
    exists: null,
    loader: null,
    speedTagloader: null,
    action: null,

    Get_Dynamic_Criteria : null,
    Dynamic_Criteria_Loader : null,
    Get_Dynamic_Criteria_Success: null,
    Get_Dynamic_Criteria_Error : null,

    Delete_Criteria_Data  : null,
    Delete_Criteria_Data_Success : null,
    Delete_Criteria_Data_Error : null,
    Delete_Criteria_Loader : false
})

/* ------------- Reducers ------------- */

export const getRubricList = (state) => {
    return {
        ...state,
        fetching: true,
        error: null,
        Get_Rubric_List: null,
        loader: true,
        Get_Dynamic_Criteria:null,
        Get_Dynamic_Criteria_Error: null,
        Dynamic_Criteria_Loader : false
    }
}

export const getRubricListSuccess = (state, action) => {
    const { data } = action
    return {
        ...state,
        fetching: false,
        error: null,
        Get_Rubric_List: data,
        action: action.type, exists: true,
        loader: false
    }
}

export const getRubricListFailure = (state, { error }) => {
    return {
        ...state,
        fetching: false,
        error: error,
        exists: false,
        action: 'GET_RUBRIC_LIST_FAILURE',
        loader: false
    }
}

export const addRubric = (state) => {
    return {
        ...state,
        fetching: true,
        error: null,
        Add_New_Rubrics: null,
        loader: true,
        Get_Dynamic_Criteria:null,
        Get_Dynamic_Criteria_Error: null,
        Dynamic_Criteria_Loader : false
    }
}

export const addRubricSuccess = (state, action) => {
    const { data } = action
    return {
        ...state,
        fetching: false,
        error: null,
        Add_New_Rubrics: data.data,
        action: action.type,
        exists: true,
        loader: false
    }
}

export const addRubricFailure = (state, { error }) => {
    return {
        ...state,
        fetching: false,
        error: error,
        exists: false,
        action: 'ADD_RUBRIC_FAILURE',
        loader: false
    }
}

export const viewRubricDetails = (state) => {
    return {
        ...state,
        fetching: true,
        error: null,
        View_Rubric_Details: null,
        loader: true
    }
}

export const viewRubricDetailsSuccess = (state, action) => {
    const { data } = action
    return {
        ...state,
        fetching: false,
        error: null,
        View_Rubric_Details: data.data,
        action: action.type, exists: true,
        loader: false
    }
}

export const viewRubricDetailsFailure = (state, { error }) => {
    return {
        ...state,
        fetching: false,
        error: error,
        exists: false,
        action: 'VIEW_RUBRIC_DETAILS_FAILURE',
        loader: false
    }
}

export const deleteCriteria = (state) => {
    return {
        ...state,
        fetching: true,
        error: null,
        Delete_Criteria: null,
        loader: true
    }
}

export const deleteCriteriaSuccess = (state, action) => {
    const { data } = action
    return {
        ...state,
        fetching: false,
        error: null,
        Delete_Criteria: data,
        action: action.type, exists: true,
        loader: false
    }
}

export const deleteCriteriaFailure = (state, { error }) => {
    return {
        ...state,
        fetching: false,
        error: error,
        exists: false,
        action: 'DELETE_CRITERIA_FAILURE',
        loader: false
    }
}

export const viewStudentsAssessmentDetails = (state) => {
    return {
        ...state,
        fetching: true,
        error: null,
        View_Students_Assessment_Details: null,
        loader: true
    }
}

export const viewStudentsAssessmentDetailsSuccess = (state, action) => {
    const { data } = action
    return {
        ...state,
        fetching: false,
        error: null,
        View_Students_Assessment_Details: data.data,
        action: action.type, exists: true,
        loader: false
    }
}

export const viewStudentsAssessmentDetailsFailure = (state, { error }) => {
    return {
        ...state,
        fetching: false,
        error: error,
        exists: false,
        action: 'VIEW_STUDENTS_ASSESSMENT_DETAILS_FAILURE',
        loader: false
    }
}

export const saveAssessment = (state) => {
    return {
        ...state,
        fetching: true,
        error: null,
        Save_Assessment: null,
        loader: true
    }
}

export const saveAssessmentSuccess = (state, action) => {
    const { data } = action
    return {
        ...state,
        fetching: false,
        error: null,
        Save_Assessment: data,
        action: action.type, exists: true,
        loader: false
    }
}

export const saveAssessmentFailure = (state, { error }) => {
    return {
        ...state,
        fetching: false,
        error: error,
        exists: false,
        action: 'SAVE_ASSESSMENT_FAILURE',
        loader: false
    }
}

export const deleteRubric = (state) => {
    return {
        ...state,
        fetching: true,
        error: null,
        Delete_Rubric: null,
        loader: true
    }
}

export const deleteRubricSuccess = (state, action) => {
    const { data } = action
    return {
        ...state,
        fetching: false,
        error: null,
        Delete_Rubric: data,
        action: action.type, exists: true,
        loader: false
    }
}

export const deleteRubricFailure = (state, { error }) => {
    return {
        ...state,
        fetching: false,
        error: error,
        exists: false,
        action: 'DELETE_RUBRIC_FAILURE',
        loader: false
    }
}


export const forwardRubricsReport = (state) => {
    return {
        ...state,
        fetching: true,
        error: null,
        Send_Email: null,
    }
}

export const forwardRubricsReportSuccess = (state, action) => {
    const { data } = action
    return {
        ...state,
        fetching: false,
        error: null,
        Send_Rub_Email: data.response_data,
        action: action.type,
        exists: true,
        loader: false
    }
}

export const forwardRubricsReportFailure = (state, { error }) => {
    return {
        ...state,
        fetching: false,
        error,
        exists: false,
        action: 'FORWARD_RUBRICS_REPORT_FAILURE',
        loader: false
    }
}

export const getDynamicRubricsCriteria = (state, action) => {
    return {
        ...state,
        Get_Dynamic_Criteria:null,
        Get_Dynamic_Criteria_Error: null,
        Dynamic_Criteria_Loader : true,
        Delete_Criteria_Data  : null,
        Delete_Criteria_Data_Success : null,
        Delete_Criteria_Data_Error : null,
        Delete_Criteria_Loader : false
    }
}

export const getDynamicRubricsCriteriaSuccess = (state, action) => {
    return {
        ...state,
        Get_Dynamic_Criteria:action?.data,
        Get_Dynamic_Criteria_Error: null,
        Dynamic_Criteria_Loader : false
    }
}

export const getDynamicRubricsCriteriaError = (state, {error}) => {
    return {
        ...state,
        Get_Dynamic_Criteria: null,
        Get_Dynamic_Criteria_Error: error,
        Dynamic_Criteria_Loader : false
    }
}

export const deletePreviousCriteriaData = (state, action) => {
    return {
        ...state,
        Delete_Criteria_Data  : null,
        Delete_Criteria_Data_Success : null,
        Delete_Criteria_Data_Error : null,
        Delete_Criteria_Loader : true
    }
}

export const deletePreviousCriteriaDataSuccess = (state, action) => {
    return {
        ...state,
        Delete_Criteria_Data  : null,
        Delete_Criteria_Data_Success : action?.data,
        Delete_Criteria_Data_Error : null,
        Delete_Criteria_Loader : false
    }
}

export const deletePreviousCriteriaDataError = (state, action) => {
    return {
        ...state,
        Delete_Criteria_Data  : null,
        Delete_Criteria_Data_Success : null,
        Delete_Criteria_Data_Error : action?.data,
        Delete_Criteria_Loader : false
    }
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_RUBRIC_LIST]: getRubricList,
    [Types.GET_RUBRIC_LIST_SUCCESS]: getRubricListSuccess,
    [Types.GET_RUBRIC_LIST_FAILURE]: getRubricListFailure,

    [Types.ADD_RUBRIC]: addRubric,
    [Types.ADD_RUBRIC_SUCCESS]: addRubricSuccess,
    [Types.ADD_RUBRIC_FAILURE]: addRubricFailure,

    [Types.VIEW_RUBRIC_DETAILS]: viewRubricDetails,
    [Types.VIEW_RUBRIC_DETAILS_SUCCESS]: viewRubricDetailsSuccess,
    [Types.VIEW_RUBRIC_DETAILS_FAILURE]: viewRubricDetailsFailure,

    [Types.DELETE_CRITERIA]: deleteCriteria,
    [Types.DELETE_CRITERIA_SUCCESS]: deleteCriteriaSuccess,
    [Types.DELETE_CRITERIA_FAILURE]: deleteCriteriaFailure,

    [Types.VIEW_STUDENTS_ASSESSMENT_DETAILS]: viewStudentsAssessmentDetails,
    [Types.VIEW_STUDENTS_ASSESSMENT_DETAILS_SUCCESS]: viewStudentsAssessmentDetailsSuccess,
    [Types.VIEW_STUDENTS_ASSESSMENT_DETAILS_FAILURE]: viewStudentsAssessmentDetailsFailure,

    [Types.SAVE_ASSESSMENT]: saveAssessment,
    [Types.SAVE_ASSESSMENT_SUCCESS]: saveAssessmentSuccess,
    [Types.SAVE_ASSESSMENT_FAILURE]: saveAssessmentFailure,

    [Types.DELETE_RUBRIC]: deleteRubric,
    [Types.DELETE_RUBRIC_SUCCESS]: deleteRubricSuccess,
    [Types.DELETE_RUBRIC_FAILURE]: deleteRubricFailure,

    [Types.FORWARD_RUBRICS_REPORT]: forwardRubricsReport,
    [Types.FORWARD_RUBRICS_REPORT_SUCCESS]: forwardRubricsReportSuccess,
    [Types.FORWARD_RUBRICS_REPORT_FAILURE]: forwardRubricsReportFailure,

    [Types.GET_DYNAMIC_RUBRICS_CRITERIA]: getDynamicRubricsCriteria,
    [Types.GET_DYNAMIC_RUBRICS_CRITERIA_SUCCESS]: getDynamicRubricsCriteriaSuccess,
    [Types.GET_DYNAMIC_RUBRICS_CRITERIA_ERROR]: getDynamicRubricsCriteriaError,

    [Types.DELETE_PREVIOUS_CRITERIA_DATA]: deletePreviousCriteriaData,
    [Types.DELETE_PREVIOUS_CRITERIA_DATA_SUCCESS]: deletePreviousCriteriaDataSuccess,
    [Types.DELETE_PREVIOUS_CRITERIA_DATA_ERROR]: deletePreviousCriteriaDataError
})


