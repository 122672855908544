import React from 'react';
import * as moment from 'moment';
import * as routes from "Router/RoutesURL";
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';

const BlogCards = (props) => {
    const { item, history } = props
    const goDetails = (item) => {
        history.push(routes.BLOGDETAILS, { item: item })
    }
    return (
        <Card className='blogCards' onClick={() => { goDetails(item) }}>
            <Card.Img src={item.fimg_url} className="blogCardImage" alt="..." />
            <Card.Body >
                <Card.Title dangerouslySetInnerHTML={{ __html: item.title.rendered }} className='blogCardTitle' />
                <div dangerouslySetInnerHTML={{ __html: item.excerpt.rendered }} className="blogCardDiscription" />
                <p className="blogCardTime">{moment(item.date).format('ll')}</p>
            </Card.Body>

        </Card>
    )
}
BlogCards.propTypes = {
    item: PropTypes.object,
};

export default BlogCards