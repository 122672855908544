import {
    GET_VIEW_LESSON_PLAN_LIST,
    GET_VIEW_LESSON_PLAN_LIST_SUCCESS,
    GET_VIEW_LESSON_PLAN_LIST_FAILURE,

    ADD_LESSON_PLAN,
    ADD_LESSON_PLAN_SUCCESS,
    ADD_LESSON_PLAN_FAILURE,

    GET_VIEW_QUESTION_LIST,
    GET_VIEW_QUESTION_LIST_SUCCESS,
    GET_VIEW_QUESTION_LIST_FAILURE,

    COPY_LESSON_PLAN,
    COPY_LESSON_PLAN_SUCCESS,
    COPY_LESSON_PLAN_FAILURE,

    DELETE_LESSON_PLAN,
    DELETE_LESSON_PLAN_SUCCESS,
    DELETE_LESSON_PLAN_FAILURE,

    VIEW_LESSON_PLAN_DETAILS,
    VIEW_LESSON_PLAN_DETAILS_SUCCESS,
    VIEW_LESSON_PLAN_DETAILS_FAILURE,

    SAVE_EXECUTION_LESSON_PLAN,
    SAVE_EXECUTION_LESSON_PLAN_SUCCESS,
    SAVE_EXECUTION_LESSON_PLAN_FAILURE,

    SAVE_EVALUATION_LESSON_PLAN,
    SAVE_EVALUATION_LESSON_PLAN_SUCCESS,
    SAVE_EVALUATION_LESSON_PLAN_FAILURE,

    DELETE_DYNAMIC_QUESTION_LESSON_PLAN,
    DELETE_DYNAMIC_QUESTION_LESSON_PLAN_SUCCESS,
    DELETE_DYNAMIC_QUESTION_LESSON_PLAN_FAILURE,

    GET_VIEW_IMPORT_LESSON_PLAN_LIST,
    GET_VIEW_IMPORT_LESSON_PLAN_LIST_SUCCESS,
    GET_VIEW_IMPORT_LESSON_PLAN_LIST_FAILURE,

    GET_IMPORT_TOPIC_LIST,
    GET_IMPORT_TOPIC_LIST_SUCCESS,
    GET_IMPORT_TOPIC_LIST_FAILURE,

    SAVE_IMPORT_LESSON_PLAN_RATING,
    SAVE_IMPORT_LESSON_PLAN_RATING_SUCCESS,
    SAVE_IMPORT_LESSON_PLAN_RATING_FAILURE,

    IMPORT_LESSON_PLAN,
    IMPORT_LESSON_PLAN_SUCCESS,
    IMPORT_LESSON_PLAN_FAILURE,

    GET_LESSON_PLAN_SCHOOL_TEACHER,
    GET_LESSON_PLAN_SCHOOL_TEACHER_SUCCESS,
    GET_LESSON_PLAN_SCHOOL_TEACHER_FAILURE,

    SHARE_LESSON_PLAN,
    SHARE_LESSON_PLAN_SUCCESS,
    SHARE_LESSON_PLAN_FAILURE,
    GET_LP_TEXT,
    GET_LP_TEXT_ERROR,
    GET_LP_TEXT_SUCCESS

} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */



// -----------Get Class Att List-------------
export function getViewLessonPlanList(data) {
    return {
        type: GET_VIEW_LESSON_PLAN_LIST,
        data,
    };
}

export function getViewLessonPlanListSuccess(data) {
    return {
        type: GET_VIEW_LESSON_PLAN_LIST_SUCCESS,
        data,
    };
}

export function getViewLessonPlanListFailure(data) {
    return {
        type: GET_VIEW_LESSON_PLAN_LIST_FAILURE,
        data,
    };
}

export function getViewQuestionList(data) {
    return {
        type: GET_VIEW_QUESTION_LIST,
        data,
    };
}

export function getViewQuestionListSuccess(data) {
    return {
        type: GET_VIEW_QUESTION_LIST_SUCCESS,
        data,
    };
}

export function getViewQuestionListFailure(data) {
    return {
        type: GET_VIEW_QUESTION_LIST_FAILURE,
        data,
    };
}


// -----------Add lesson plan------------
export function addLessonPlan(data) {
    return {
        type: ADD_LESSON_PLAN,
        data,
    };
}

export function addLessonPlanSuccess(data) {
    return {
        type: ADD_LESSON_PLAN_SUCCESS,
        data,
    };
}

export function addLessonPlanFailure(data) {
    return {
        type: ADD_LESSON_PLAN_FAILURE,
        data,
    };
}

// -----------copy lesson plan------------
export function copyLessonPlan(data) {
    return {
        type: COPY_LESSON_PLAN,
        data,
    };
}

export function copyLessonPlanSuccess(data) {
    return {
        type: COPY_LESSON_PLAN_SUCCESS,
        data,
    };
}

export function copyLessonPlanFailure(data) {
    return {
        type: COPY_LESSON_PLAN_FAILURE,
        data,
    };
}

// -----------delete lesson plan------------
export function deleteLessonPlan(data) {
    return {
        type: DELETE_LESSON_PLAN,
        data,
    };
}

export function deleteLessonPlanSuccess(data) {
    return {
        type: DELETE_LESSON_PLAN_SUCCESS,
        data,
    };
}

export function deleteLessonPlanFailure(data) {
    return {
        type: DELETE_LESSON_PLAN_FAILURE,
        data,
    };
}


// -----------view lesson plan details------------
export function viewLessonPlanDetails(data) {
    return {
        type: VIEW_LESSON_PLAN_DETAILS,
        data,
    };
}

export function viewLessonPlanDetailsSuccess(data) {
    return {
        type: VIEW_LESSON_PLAN_DETAILS_SUCCESS,
        data,
    };
}

export function viewLessonPlanDetailsFailure(data) {
    return {
        type: VIEW_LESSON_PLAN_DETAILS_FAILURE,
        data,
    };
}

// -----------save execution lesson plan------------
export function saveExecutionLessonPlan(data) {
    return {
        type: SAVE_EXECUTION_LESSON_PLAN,
        data,
    };
}

export function saveExecutionLessonPlanSuccess(data) {
    return {
        type: SAVE_EXECUTION_LESSON_PLAN_SUCCESS,
        data,
    };
}

export function saveExecutionLessonPlanFailure(data) {
    return {
        type: SAVE_EXECUTION_LESSON_PLAN_FAILURE,
        data,
    };
}

// -----------save evaluation lesson plan------------
export function saveEvaluationLessonPlan(data) {
    return {
        type: SAVE_EVALUATION_LESSON_PLAN,
        data,
    };
}

export function saveEvaluationLessonPlanSuccess(data) {
    return {
        type: SAVE_EVALUATION_LESSON_PLAN_SUCCESS,
        data,
    };
}

export function saveEvaluationLessonPlanFailure(data) {
    return {
        type: SAVE_EVALUATION_LESSON_PLAN_FAILURE,
        data,
    };
}
// -----------delete Dynamic Question lesson plan------------
export function deleteDynamicQuestionLessonPlan(data) {
    return {
        type: DELETE_DYNAMIC_QUESTION_LESSON_PLAN,
        data,
    };
}

export function deleteDynamicQuestionLessonPlanSuccess(data) {
    return {
        type: DELETE_DYNAMIC_QUESTION_LESSON_PLAN_SUCCESS,
        data,
    };
}

export function deleteDynamicQuestionLessonPlanFailure(data) {
    return {
        type: DELETE_DYNAMIC_QUESTION_LESSON_PLAN_FAILURE,
        data,
    };
}

export function getViewImportLessonPlanList(data) {
    return {
        type: GET_VIEW_IMPORT_LESSON_PLAN_LIST,
        data,
    };
}

export function getViewImportLessonPlanListSuccess(data) {
    return {
        type: GET_VIEW_IMPORT_LESSON_PLAN_LIST_SUCCESS,
        data,
    };
}

export function getViewImportLessonPlanListFailure(data) {
    return {
        type: GET_VIEW_IMPORT_LESSON_PLAN_LIST_FAILURE,
        data,
    };
}

export function getImportTopicList(data) {
    return {
        type: GET_IMPORT_TOPIC_LIST,
        data,
    };
}

export function getImportTopicListSuccess(data) {
    return {
        type: GET_IMPORT_TOPIC_LIST_SUCCESS,
        data,
    };
}

export function getImportTopicListFailure(data) {
    return {
        type: GET_IMPORT_TOPIC_LIST_FAILURE,
        data,
    };
}


export function saveImportLessonPlanRating(data) {
    return {
        type: SAVE_IMPORT_LESSON_PLAN_RATING,
        data,
    };
}

export function saveImportLessonPlanRatingSuccess(data) {
    return {
        type: SAVE_IMPORT_LESSON_PLAN_RATING_SUCCESS,
        data,
    };
}

export function saveImportLessonPlanRatingFailure(data) {
    return {
        type: SAVE_IMPORT_LESSON_PLAN_RATING_FAILURE,
        data,
    };
}
// -----------import lesson plan------------
export function importLessonPlan(data) {
    return {
        type: IMPORT_LESSON_PLAN,
        data,
    };
}

export function importLessonPlanSuccess(data) {
    return {
        type: IMPORT_LESSON_PLAN_SUCCESS,
        data,
    };
}

export function importLessonPlanFailure(data) {
    return {
        type: IMPORT_LESSON_PLAN_FAILURE,
        data,
    };
}

// -----------get Lesson Plan School Teacher------------
export function getLessonPlanSchoolTeacher(data) {
    return {
        type: GET_LESSON_PLAN_SCHOOL_TEACHER,
        data,
    };
}

export function getLessonPlanSchoolTeacherSuccess(data) {
    return {
        type: GET_LESSON_PLAN_SCHOOL_TEACHER_SUCCESS,
        data,
    };
}

export function getLessonPlanSchoolTeacherFailure(data) {
    return {
        type: GET_LESSON_PLAN_SCHOOL_TEACHER_FAILURE,
        data,
    };
}
// -----------share lesson plan------------
export function shareLessonPlan(data) {
    return {
        type: SHARE_LESSON_PLAN,
        data,
    };
}

export function shareLessonPlanSuccess(data) {
    return {
        type: SHARE_LESSON_PLAN_SUCCESS,
        data,
    };
}

export function shareLessonPlanFailure(data) {
    return {
        type: SHARE_LESSON_PLAN_FAILURE,
        data,
    };
}

export function getLpText(data)  {
    return {
        type : GET_LP_TEXT,
        data
    }
}

export function getLpTextError(data)  {
    return {
        type : GET_LP_TEXT_ERROR,
        data
    }
}

export function getLpTextSuccess(data)  {
    return {
        type : GET_LP_TEXT_SUCCESS,
        data
    }
}
