import {

    GET_CLASS_GRADEBOOK_REQUEST,
    GET_CLASS_GRADEBOOK_SUCCESS,
    GET_CLASS_GRADEBOOK_FAILURE,

    ADD_NEW_CLASS_GRADEBOOK_CATEGORY_REQUEST,
    ADD_NEW_CLASS_GRADEBOOK_CATEGORY_SUCCESS,
    ADD_NEW_CLASS_GRADEBOOK_CATEGORY_FAILURE,

    UPGRADE_POP_UP_OPEN,
    UPGRADE_POP_UP_OPEN_GRADEBOOK,

    ADD_NEW_CLASS_GRADEBOOK_EVENT_REQUEST,
    ADD_NEW_CLASS_GRADEBOOK_EVENT_SUCCESS,
    ADD_NEW_CLASS_GRADEBOOK_EVENT_FAILURE,


    DELETE_CLASS_GRADEBOOK_EVENT_REQUEST,
    DELETE_CLASS_GRADEBOOK_EVENT_SUCCESS,
    DELETE_CLASS_GRADEBOOK_EVENT_FAILURE,

    UPDATE_CLASS_GRADEBOOK_EVENT_REQUEST,
    UPDATE_CLASS_GRADEBOOK_EVENT_SUCCESS,
    UPDATE_CLASS_GRADEBOOK_EVENT_FAILURE,

    UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_REQUEST,
    UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_SUCCESS,
    UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_FAILURE,

    UPDATE_CLASS_GRADEBOOK_DRAG_DATA_REQUEST,
    UPDATE_CLASS_GRADEBOOK_DRAG_DATA_SUCCESS,
    UPDATE_CLASS_GRADEBOOK_DRAG_DATA_FAILURE,

    UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_REQUEST,
    UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_SUCCESS,
    UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_FAILURE,


    UPDATE_CLASS_GRADEBOOK_ID_REQUEST,

    UPDATE_CLASS_GRADEBOOK_STUDENTS_REQUEST,

    UPDATE_CLASS_GRADEBOOK_CATEGORY_INPUT_REQUEST,

    UPDATE_CLASS_GRADEBOOK_COLOR_REQUEST,

    UPDATE_CLASS_GRADEBOOK_ALL_COLOR_REQUEST,

    UPDATE_CLASS_GRADEBOOK_INPUT_REQUEST,

    ADD_CLASS_GRADEBOOK_POPOVER_DATA_REQUEST,

    UPDATE_CLASS_GRADEBOOK_POPOVER_COLOR_REQUEST,

    UPDATE_CLASS_GRADEBOOK_POPOVER_INPUT_REQUEST,

    UPDATE_CLASS_GRADEBOOK_NULL_REQUEST,

    OPEN_CLASS_GRADEBOOK_POPOVER_REQUEST,

    NEW_CLASS_GRADEBOOK_REQUEST,
    SET_GRADEBOOK_DATA_STATE,



} from '../constants';

// -----------Get Class Gradebook-------------
export function getClassGradebookRequest(data) {
    return {
        type: GET_CLASS_GRADEBOOK_REQUEST,
        data,
    };
}

export function getClassGradebookSuccess(data) {
    return {
        type: GET_CLASS_GRADEBOOK_SUCCESS,
        data,
    };
}

export function getClassGradebookFailure(data) {
    return {
        type: GET_CLASS_GRADEBOOK_FAILURE,
        data,
    };
}



// -----------Get New Class Gradebook Category-------------
export function addNewClassGradebookCategoryRequest(data) {
    return {
        type: ADD_NEW_CLASS_GRADEBOOK_CATEGORY_REQUEST,
        data,
    };
}

export function addNewClassGradebookCategorySuccess(data) {
    return {
        type: ADD_NEW_CLASS_GRADEBOOK_CATEGORY_SUCCESS,
        data,
    };
}

export function upgradePopUpOpenGradebook(data) {
    return {
        type: UPGRADE_POP_UP_OPEN_GRADEBOOK,
        data,
    };
}

export function addNewClassGradebookCategoryFailure(data) {
    return {
        type: ADD_NEW_CLASS_GRADEBOOK_CATEGORY_FAILURE,
        data,
    };
}

// -----------Get New Class Gradebook Event-------------
export function addNewClassGradebookEventRequest(data) {
    return {
        type: ADD_NEW_CLASS_GRADEBOOK_EVENT_REQUEST,
        data,
    };
}

export function addNewClassGradebookEventSuccess(data) {
    return {
        type: ADD_NEW_CLASS_GRADEBOOK_EVENT_SUCCESS,
        data,
    };
}

export function addNewClassGradebookEventFailure(data) {
    return {
        type: ADD_NEW_CLASS_GRADEBOOK_EVENT_FAILURE,
        data,
    };
}

// -----------Update Class Gradebook Event-------------
export function updateClassGradebookEventRequest(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_EVENT_REQUEST,
        data,
    };
}

export function updateClassGradebookEventSuccess(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_EVENT_SUCCESS,
        data,
    };
}

export function updateClassGradebookEventFailure(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_EVENT_FAILURE,
        data,
    };
}

// -----------Update Class Gradebook Score Comment-------------
export function updateClassGradebookScoreCommentRequest(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_REQUEST,
        data,
    };
}

export function updateClassGradebookScoreCommentSuccess(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_SUCCESS,
        data,
    };
}

export function updateClassGradebookScoreCommentFailure(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_FAILURE,
        data,
    };
}


// -----------Get Delete Class Gradebook Event-------------
export function deleteClassGradebookEventRequest(data) {
    return {
        type: DELETE_CLASS_GRADEBOOK_EVENT_REQUEST,
        data,
    };
}

export function deleteClassGradebookEventSuccess(data) {
    return {
        type: DELETE_CLASS_GRADEBOOK_EVENT_SUCCESS,
        data,
    };
}

export function deleteClassGradebookEventFailure(data) {
    return {
        type: DELETE_CLASS_GRADEBOOK_EVENT_FAILURE,
        data,
    };
}

// -----------update Class Gradebook Popover Drag Data Request-------------
export function updateClassGradebookDragDataRequest(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_DRAG_DATA_REQUEST,
        data,
    };
}

export function updateClassGradebookDragDataSuccess(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_DRAG_DATA_SUCCESS,
        data,
    };
}

export function updateClassGradebookDragDataFailure(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_DRAG_DATA_FAILURE,
        data,
    };
}

// -----------update Class Gradebook Event Drag Data Request-------------
export function updateClassGradebookDragEventDataRequest(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_REQUEST,
        data,
    };
}

export function updateClassGradebookDragEventDataSuccess(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_SUCCESS,
        data,
    };
}

export function updateClassGradebookDragEventDataFailure(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_FAILURE,
        data,
    };
}



// -----------update Class Gradebook Students Request-------------
export function updateClassGradebookStudentsRequest(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_STUDENTS_REQUEST,
        data,
    };
}

// -----------update Class Gradebook Students Request-------------
export function updateClassGradebookCategoryInputRequest(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_CATEGORY_INPUT_REQUEST,
        data,
    };
}

// -----------update Class Gradebook Id Request-------------
export function updateClassGradebookIdRequest(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_ID_REQUEST,
        data,
    };
}
// -----------update Class Gradebook Color Request-------------
export function updateClassGradebookColorRequest(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_COLOR_REQUEST,
        data,
    };
}

// -----------update Class Gradebook All Color Request-------------
export function updateClassGradebookAllColorRequest(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_ALL_COLOR_REQUEST,
        data,
    };
}

// -----------update Class Gradebook Input Request-------------
export function updateClassGradebookInputRequest(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_INPUT_REQUEST,
        data,
    };
}

// -----------update Class Gradebook Popover Data Request-------------
export function addClassGradebookPopoverDataRequest(data) {
    return {
        type: ADD_CLASS_GRADEBOOK_POPOVER_DATA_REQUEST,
        data,
    };
}

// -----------update Class Gradebook Popover Color Request-------------
export function updateClassGradebookPopoverColorRequest(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_POPOVER_COLOR_REQUEST,
        data,
    };
}

// -----------update Class Gradebook Popover Input Request-------------
export function updateClassGradebookPopoverInputRequest(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_POPOVER_INPUT_REQUEST,
        data,
    };
}

// -----------update Class Gradebook Null Request-------------
export function updateClassGradebookNullRequest(data) {
    return {
        type: UPDATE_CLASS_GRADEBOOK_NULL_REQUEST,
        data,
    };
}

// -----------update Class Gradebook Null Request-------------
export function openClassGradebookPopoverRequest(data) {
    return {
        type: OPEN_CLASS_GRADEBOOK_POPOVER_REQUEST,
        data,
    };
}


// -----------New Class Gradebook Request-------------
export function NewClassGradebookRequest(data) {
    return {
        type: NEW_CLASS_GRADEBOOK_REQUEST,
        data,
    };
}

export function setGradebookDataState(data) {
    return {
      type: SET_GRADEBOOK_DATA_STATE,
      data,
    };
  }
