import React from 'react';
import { connect } from 'react-redux';
import BlogDashboard from './BlogDashboardComponent/BlogDashboard';
import { getBlogListRequest, loadMoreBlogListRequest } from 'Actions/BlogAction/blogAction'


const BlogIndex = (props) => {
    return (
        <BlogDashboard {...props} />
    )
}
const mapStateToProps = state => ({
    state: state,
    blogList: state.blog.blogs,
    TotalBlogs: state.blog.TotalBlogs,
    loader: state.blog.loader
});
const mapDispatchToProps = dispatch => {
    return {
        getBlogListRequest: () => dispatch(getBlogListRequest()),
        loadMoreBlogListRequest: () => dispatch(loadMoreBlogListRequest()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    BlogIndex
);