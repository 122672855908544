import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import Loader from "../Components/LoadingComponent/loader";
import Header from "../Components/HeaderComponent/Header";
import FooterDashboard from "../Components/FooterComponent/FooterDashboard";
import {
  LOGIN,
  FORGOT,
  REGISTER,
  VERIFICATION,
  USERPROFILE,
  USERSUBSCRIPTION,
  USERPAYPAL,
} from "../Router/RoutesURL";
import "react-toastify/dist/ReactToastify.css";
import "./AppContainer.css";
import { useDispatch } from "react-redux";
import { getNotificationRequest } from "Actions/NotificationAction/notificationAction";
import { getSubscriptionPlanRequest, getStripeProducts } from 'Actions/SubscriptionPlanAction/subscriptionPlanAction';

function AppContainer({ children, location, history }) {
  const { pathname } = location;
  const dispatch = useDispatch();
  let ShowHeader = false;
  if (
    !(
      pathname === LOGIN ||
      pathname === FORGOT ||
      pathname === REGISTER ||
      pathname === VERIFICATION ||
      pathname === USERSUBSCRIPTION ||
      pathname === USERPAYPAL
    )
  ) {
    ShowHeader = true;
  }

  useEffect(() => {
    if (sessionStorage && sessionStorage.getItem("deleteUSer")) {
      return;
    } else {
      if (
        location.pathname == "/" ||
        location.pathname == "/login" ||
        location.pathname == "/registeruser" ||
        location.pathname == "/forgotpassword" ||
        location.pathname == "/verification" ||
        location.pathname == "/usersubscription" ||
        location.pathname == "/usersubscriptionpayment" ||
        location.pathname == "/home/ClassAttendance" ||
        location.pathname == "/home/studentnoteadd" ||
        location.pathname == "/home/classnoteadd"
      ) {
      } else {
        let apiData = {
          page_no: 1,
        };
        dispatch(getNotificationRequest(apiData));
      }
    }
    if (location) {
      document.getElementById("scroller").scroll(0, 0);
    }
    if(sessionStorage && sessionStorage.getItem('userHasFreePlan') == '0') {
      dispatch(getSubscriptionPlanRequest())
      dispatch(getStripeProducts())
    }
  }, [location]);

  return (
    <Fragment>
      <div className="container-flex">
        <div className="container-body">
          {ShowHeader && (
            <Header location={location} history={history} children={children} />
          )}
          {ShowHeader ? (
            <div className="container-children" id="scroller">
              <div
                className={
                  pathname === USERPROFILE
                    ? "container-children"
                    : "container-childrenPage"
                }
              >
                {children}
              </div>
              <FooterDashboard
                location={location}
                history={history}
                children={children}
              />
            </div>
          ) : (
            <div className="container-children" id="scroller">
              {children}
            </div>
          )}

          <ToastContainer />
        </div>

        <Loader />
      </div>
    </Fragment>
  );
}

AppContainer.propTypes = {
  children: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default AppContainer;
