
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

export default class CommonTextField extends Component {

    render() {
        const {
            margin,
            variant,
            type,
            value,
            onChange,
            name,
            className,
            placeholder,
            disabled,
            maxLength,
            onClick,
            defaultValue,
            style,
            dataTestId
        } = this.props;

        return (
            <TextField
                data-testid={dataTestId}
                margin={margin}
                variant={variant}
                type={type}
                value={value}
                onChange={onChange}
                onClick={onClick}
                name={name}
                placeholder={placeholder}
                className={className}
                defaultValue={defaultValue}
                // label={placeholder}
                disabled={disabled}
                maxLength={maxLength}
                style={style}
                InputProps={{ disableUnderline: true }}
            />

        );
    }
}