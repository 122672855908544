import { call, takeLatest, put } from 'redux-saga/effects';
import { createNotification } from 'Config/notificationtoast';
import userDataUsageReducer from 'Redux/Reducers/DataUsageReducer/dataUsageReducer';

import {
    GET_DATA_USAGE_REQUEST,
} from 'Actions/constants';
import {
    GetDataUsage_List_API,
} from 'Config/Api';
import request from 'Config/request';
import { decryptData, encryptData, logOut } from 'Utils/Helper';


export function* userDataUsageModuleSaga() {
    yield takeLatest(GET_DATA_USAGE_REQUEST, getDataUsageRequest);

}

// ---------- Get Data Usage Gradebook-----
function* getDataUsageRequest() {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = GetDataUsage_List_API;
    const BODY = {
        "user_id": data.cc_user_id,
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(userDataUsageReducer.getDataUsageSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(userDataUsageReducer.getDataUsageFailure(MainDecode));

        }
    } catch (error) {
        yield put(userDataUsageReducer.getDataUsageFailure());
        createNotification('warning', 'Something went wrong');

    }
}
// ---------- Get Data Usage Gradebook------
