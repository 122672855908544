import React from "react";
import loaderImag from "Assets/ProfileModule/PepperyMedium.gif";

const DivLoader = (props) => {
  const { datatestid, width, marginTop } = props;

  return (
    <img
      data-testid={datatestid}
      src={loaderImag}
      alt=""
      className="loaderIconCss"
      style={{ width: width, marginTop: marginTop }}
    />
  );
};
export default DivLoader;
