

import {
    GET_ACADEMIC_LIST,
    GET_ACADEMIC_LIST_SUCCESS,
    GET_ACADEMIC_LIST_FAILURE,

    GET_ACADEMIC_SCORE,
    GET_ACADEMIC_SCORE_SUCCESS,
    GET_ACADEMIC_SCORE_FAILURE,

    ADD_ACADEMIC_YEARS,
    ADD_ACADEMIC_YEARS_SUCCESS,
    ADD_ACADEMIC_YEARS_FAILURE,

    EDIT_ACADEMIC_YEARS,
    EDIT_ACADEMIC_YEARS_SUCCESS,
    EDIT_ACADEMIC_YEARS_FAILURE,

    DELETE_ACADEMIC_YEARS,
    DELETE_ACADEMIC_YEARS_SUCCESS,
    DELETE_ACADEMIC_YEARS_FAILURE,

    GET_ACADEMIC_YEARS,
    GET_ACADEMIC_YEARS_SUCCESS,
    GET_ACADEMIC_YEARS_FAILURE,

    GET_ROSTER_CLASS_BY_ACADEMIC_YEAR,
    GET_ROSTER_CLASS_BY_ACADEMIC_YEAR_SUCCESS,
    GET_ROSTER_CLASS_BY_ACADEMIC_YEAR_FAILURE,

    GET_ACADEMIC_RESET
} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */


// -----------Get AcademicList-------------
export function getAcademicList(data) {
    return {
        type: GET_ACADEMIC_LIST,
        data,
    };
}

export function getAcademicListSuccess(data) {
    return {
        type: GET_ACADEMIC_LIST_SUCCESS,
        data,
    };
}

export function getAcademicListFailure(data) {
    return {
        type: GET_ACADEMIC_LIST_FAILURE,
        data,
    };
}

// -----------Get AcademicClassRosterList-------------
export function getRosterClassByAcademicYear(data) {

    return {
        type: GET_ROSTER_CLASS_BY_ACADEMIC_YEAR,
        data,
    };
}

export function getRosterClassByAcademicYearSuccess(data) {
    return {
        type: GET_ROSTER_CLASS_BY_ACADEMIC_YEAR_SUCCESS,
        data,
    };
}

export function getRosterClassByAcademicYearFailure(data) {
    return {
        type: GET_ROSTER_CLASS_BY_ACADEMIC_YEAR_FAILURE,
        data,
    };
}


// -----------Get Score-------------
export function getAcademicScore(data) {
    return {
        type: GET_ACADEMIC_SCORE,
        data,
    };
}

export function getAcademicScoreSuccess(data) {
    return {
        type: GET_ACADEMIC_SCORE_SUCCESS,
        data,
    };
}

export function getAcademicScoreFailure(data) {
    return {
        type: GET_ACADEMIC_SCORE_FAILURE,
        data,
    };
}
// -----------Get Score-------------


// -----------Add Academic Year -------------
export function addAcademicYears(data) {
    return {
        type: ADD_ACADEMIC_YEARS,
        data,
    };
}

export function addAcademicYearsSuccess(data) {
    return {
        type: ADD_ACADEMIC_YEARS_SUCCESS,
        data,
    };
}

export function addAcademicYearsFailure(data) {
    return {
        type: ADD_ACADEMIC_YEARS_FAILURE,
        data,
    };
}
// -----------Add Academic Year------------


// -----------edit Academic Year -------------
export function editAcademicYears(data) {
    return {
        type: EDIT_ACADEMIC_YEARS,
        data,
    };
}

export function editAcademicYearsSuccess(data) {
    return {
        type: EDIT_ACADEMIC_YEARS_SUCCESS,
        data,
    };
}

export function editAcademicYearsFailure(data) {
    return {
        type: EDIT_ACADEMIC_YEARS_FAILURE,
        data,
    };
}
// -----------edit Academic Year------------


// -----------delete Academic Year -------------
export function deleteAcademicYears(data) {
    return {
        type: DELETE_ACADEMIC_YEARS,
        data,
    };
}

export function deleteAcademicYearsSuccess(data) {
    return {
        type: DELETE_ACADEMIC_YEARS_SUCCESS,
        data,
    };
}

export function deleteAcademicYearsFailure(data) {
    return {
        type: DELETE_ACADEMIC_YEARS_FAILURE,
        data,
    };
}
// -----------delete Academic Year------------



// -----------Get Academic Years-------------
export function getAcademicYears(data) {
    return {
        type: GET_ACADEMIC_YEARS,
        data,
    };
}

export function getAcademicYearsSuccess(data) {
    return {
        type: GET_ACADEMIC_YEARS_SUCCESS,
        data,
    };
}

export function getAcademicYearsFailure(data) {
    return {
        type: GET_ACADEMIC_YEARS_FAILURE,
        data,
    };
}
// -----------Get Academic Years-------------


export function getAcademicReset(data) {
    return {
        type: GET_ACADEMIC_RESET,
        data,
    }
}
