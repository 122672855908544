import { call, takeLatest, put } from 'redux-saga/effects';
import axios from 'axios'
import UserClassAttReducer from 'Redux/Reducers/ClassReducer/classAttReducer';
import { createNotification } from 'Config/notificationtoast';
import {
    GET_CLASS_ATT_LIST,
    ADD_CLASS_ATT,
    GET_CLASS_ATT_BY_DATE_RANGE,
    SEND_EMAIL_REPORT
} from 'Actions/constants';

import {
    GetClassAttList_API,
    AddClassAtt_API,
    GetClassAttByDateRange_API,
    SendEmail_API
} from 'Config/Api';

import { chroniclecloud_code } from 'Config/Constant';
import { decryptData, encryptData, logOut } from 'Utils/Helper';
import request from 'Config/request';
import {  getRosterImage } from "Config/commonFirebaseImage";


export function* userClassAttModuleSaga() {
    yield takeLatest(GET_CLASS_ATT_LIST, getClassAttList);
    yield takeLatest(ADD_CLASS_ATT, addClassAtt);
    yield takeLatest(GET_CLASS_ATT_BY_DATE_RANGE, getClassAttByDateRange);
    yield takeLatest(SEND_EMAIL_REPORT, sendEmailReport);
}

function* getClassAttList(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${GetClassAttList_API}?user_id=${data.cc_user_id}&class_id=${typeof(action.data.class_id) == "object" ? action.data.class_id.cc_class_id : action.data.class_id}&date=${action.data.date}&platform=web`;

    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            for (let studentAttData of MainDecode?.data?.student_details) {
                if (studentAttData.cc_student_pic_url) {
                    if (!(studentAttData.cc_student_pic_url.includes("https") === true)) {
                        studentAttData.cc_student_pic_url = yield getRosterImage(studentAttData.cc_student_pic_url);
                    }
                }
            }
            yield put(UserClassAttReducer.getClassAttListSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassAttReducer.getClassAttListFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassAttReducer.getClassAttListFailure(false));
    }
}

function* addClassAtt(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = AddClassAtt_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "date": action.data.date,
        "student_attendance_array": action.data.student_attendance_array,
        "platform" : "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);

        if (MainDecode.status == 200) {
            yield put(UserClassAttReducer.addClassAttSuccess(MainDecode));
            createNotification('success', MainDecode.message);
            yield put(UserClassAttReducer.getClassAttList(action.data));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassAttReducer.addClassAttFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassAttReducer.addClassAttFailure(false));
    }
}

function* getClassAttByDateRange(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${GetClassAttByDateRange_API}?user_id=${data.cc_user_id}&class_id=${typeof(action.data.class_id) == "object" ? action.data.class_id.cc_class_id : action.data.class_id }&start_date=${action.data.startDate}&end_date=${action.data.endDate}&platform=web`;
    const BODY = {
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "end_date": action.data.endDate,
        "start_date": action.data.startDate,
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            yield put(UserClassAttReducer.getClassAttByDateRangeSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassAttReducer.getClassAttByDateRangeFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassAttReducer.getClassAttByDateRangeFailure(false));
    }
}


function* sendEmailReport(action) {
    const URL = SendEmail_API;
    const { toEmailId, subject, messageBody, shareFile } = action.data;
    const fd = new FormData();
    // fd.append('chroniclecloud_code', chroniclecloud_code);
    fd.append('email_id', toEmailId);
    fd.append('subject', subject);
    fd.append('message_body', messageBody);
    fd.append('share_file', shareFile);

    axios.post(URL, fd).then(response => {
        const MainDecode = decryptData(response.data.res)
        if (MainDecode.status === 200) {
            createNotification('success', "Email Sent");
        }
    }).catch((error) => {
        createNotification('warning', 'Something went wrong');
    })
}
