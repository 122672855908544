import React from 'react';
import { connect } from 'react-redux';
import TermsDashboard from './TermsDashboardComponent/TermsDashboard';


const TermsIndex = (props) => {
    return (
        <TermsDashboard {...props} />
    )
}
const mapStateToProps = state => ({
    state: state,

});
const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    TermsIndex
);