import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getUserSettingRequest: ['data'],
    getUserSettingSuccess: ['data'],
    getUserSettingFailure: ['error'],


    updateUserSettingRequest: ['data'],
    updateUserSettingSuccess: ['data'],
    updateUserSettingFailure: ['error'],

})
export const SettingReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    Get_User_Setting: null,
    Update_User_Setting: null,
    error: null,
    exists: null,
    loader: null,
    action: null
})

/* ------------- Reducers ------------- */

/* getUserSettingRequest */

export const getUserSettingRequest = (state) => {
    return {
        ...state, fetching: true, error: null, Get_User_Setting: null, loader: true
    }
}
export const getUserSettingSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, Get_User_Setting: data, action: action.type, exists: true, loader: false }
}
export const getUserSettingFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_USER_SETTING_FAILURE', loader: false }
}

/* updateUserSettingRequest */
export const updateUserSettingRequest = (state) => {
    return {
        ...state, fetching: true, error: null, Update_User_Setting: null, loader: true
    }
}
export const updateUserSettingSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, Update_User_Setting: data, action: action.type, exists: true, loader: false }
}
export const updateUserSettingFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'UPDATE_USER_SETTING_FAILURE', loader: false }
}



/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {


    [Types.GET_USER_SETTING_REQUEST]: getUserSettingRequest,
    [Types.GET_USER_SETTING_SUCCESS]: getUserSettingSuccess,
    [Types.GET_USER_SETTING_FAILURE]: getUserSettingFailure,

    [Types.UPDATE_USER_SETTING_REQUEST]: updateUserSettingRequest,
    [Types.UPDATE_USER_SETTING_SUCCESS]: updateUserSettingSuccess,
    [Types.UPDATE_USER_SETTING_FAILURE]: updateUserSettingFailure,
   
})