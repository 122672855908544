import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


// import Logger from '../Lib/Logger'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    forgot: ['data'],
    forgotSuccess: ['data'],
    forgotFailure: ['error'],

})

export const UserForgotReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    forgot_Data: null,
    error: null,
    exists: null,
    action: null
})

/* ------------- Reducers ------------- */

/* signin */
export const forgot = (state) => {
    return {
        ...state, fetching: true, error: null, forgot_Data: null,
    }
}
export const forgotSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, forgot_Data: data, action: action.type, exists: true }
}
export const forgotFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, }
}


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.FORGOT]: forgot,
    [Types.FORGOT_SUCCESS]: forgotSuccess,
    [Types.FORGOT_FAILURE]: forgotFailure,

})