import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import tickIcon from 'Assets/FormativeAssessmentModule/tick.svg';
import addCommentIcon from 'Assets/ClassGradeBookModule/addComment.svg';
import hideCommentIcon from 'Assets/ClassGradeBookModule/hideCommentIcon.png';
import { ColourCode } from 'Config/Constant'
import closeIcon from 'Assets/ClassGradeBookModule/close.svg';
import "./ClassGradebookColorPopover.scss";
import CommonTextField from 'Components/CommonTextField/CommonTextField';
import TextField from '@material-ui/core/TextField';

const ClassGradebookColorPopover = (props) => {
    const { sIndex, eIndex, cIndex, ClassData, setValue, PopOverStudentData, updateClassGradebookPopoverColorRequest, updateClassGradebookPopoverInputRequest, updateClassGradebookScoreCommentRequest, closePopOver, showTextArea, setShowTextAreaCheck } = props
    const [score, setScore] = useState(PopOverStudentData.grading_event_student_score);
    const [comment, setComment] = useState(PopOverStudentData.grading_event_student_comments);
    const [saveDisable, setSaveDisable] = useState(PopOverStudentData.grading_event_student_score ? false : true)
    
    // ------change grade Color---
    const changeColor = (colorCode) => {
        const data = {
            colorCode: colorCode,
            cIndex: cIndex,
            eIndex: eIndex,
            sIndex: sIndex
        }
        updateClassGradebookPopoverColorRequest(data)
    }
    // ------change grade Color---
    const [num, setnum] = useState(PopOverStudentData.grading_event_student_score)

    const changeUpdates = (e, type) => {
        // const scoreValue = /^[0-9]{0,2}$/;

        if(e.target.value.length == 0) {
            setnum('')
        } else {
            //CHECK IF NUMBER
            var pattern = /^[0-9]+/;
        if(pattern.test(e.target.value[0])) {
            const scoreValue = /^[0-9]{0,3}(\.[0-9]{0,2})?$/;
                if (e.target.value.match(scoreValue)) {
                    setnum(e.target.value)
                }
        } else {
            if(e.target.value.length < 4) {
            setnum(e.target.value) 
            }
        }
        }

        
        //WORKING
        // if (type === "score") {
        //     let scoreValue =  /^[0-9]{0,3}$/;
        //     // let scoreValue = /^[A-F][+-]$/;
        //     if (e.target.value.match(scoreValue)) {
        //         setnum(e.target.value)
        //     }
        // //     if(e.target.value.length === 0) {
        // //         setSaveDisable(true)
        // //         setScore(e.target.value)
        // //     }
        // //     if (e.target.value.length < 3 && (!e.target.value || e.target.value.match(scoreValue))) {
        // //         const data = {
        // //             type: type === 'score' ? true : false,
        // //             value: (e.target.value),
        // //             cIndex: cIndex,
        // //             eIndex: eIndex,
        // //             sIndex: sIndex
        // //         }
        // //         // setScore(e.target.value)
        // //         // setSaveDisable(false)
        // //         updateClassGradebookPopoverInputRequest(data)
        // //     }
        // // } else {
        // //     const data = {
        // //         type: type === 'score' ? true : false,
        // //         value: e.target.value,
        // //         cIndex: cIndex,
        // //         eIndex: eIndex,
        // //         sIndex: sIndex
        // //     }
        // //     // setComment(e.target.value)
        // //     // updateClassGradebookPopoverInputRequest(data)
        // }



        console.log("object");
    }
    // ------change grade Input---
    const changeUpdate = (e, type) => {
        // const scoreValue = /^[0-9]{0,2}$/;
        // setnum(e.target.value)
        if (type === "score") {
            let scoreValue =  /^[0-9]{0,3}$/;
            if(e.target.value.length === 0) {
                setSaveDisable(true)
                setScore(e.target.value)
            }
            if (e.target.value.length < 4 && (!e.target.value || e.target.value.match(scoreValue))) {
                const data = {
                    type: type === 'score' ? true : false,
                    value: (e.target.value),
                    cIndex: cIndex,
                    eIndex: eIndex,
                    sIndex: sIndex
                }
                // setScore(e.target.value)
                // setSaveDisable(false)
                updateClassGradebookPopoverInputRequest(data)
            }
        } else {
            const data = {
                type: type === 'score' ? true : false,
                value: e.target.value,
                cIndex: cIndex,
                eIndex: eIndex,
                sIndex: sIndex
            }
            // setComment(e.target.value)
            updateClassGradebookPopoverInputRequest(data)
        }
    }
    // ------change grade Input---

    // ------Save grade ---
    const saveGrade = () => {
        const data = {
            "eventId": PopOverStudentData.grading_event_id,
            "comments": PopOverStudentData.grading_event_student_comments,
            "colorCode": PopOverStudentData.grading_event_student_color_code,
            "score": num,
            "studentId": PopOverStudentData.cc_student_id || PopOverStudentData.grading_event_student_id,
            "classId": ClassData.cc_class_id,
        }
        
        updateClassGradebookScoreCommentRequest(data)
        closePopOver(cIndex, eIndex, sIndex)
    }
    // ------Save grade ---

    const popOverHide = () => {
        closePopOver(cIndex, eIndex, sIndex)
    }

    return (
        <UncontrolledPopover
            trigger="legacy"
            placement="bottom"
            isOpen={setValue}
            target={`mypopOver${cIndex}${eIndex}${sIndex}`}
            className="score-input-popover number-arrow"
        >
            <PopoverBody>
                <div >
                    <img src={closeIcon} className="closeIcon" alt="" width="20" height="20" onClick={() => { popOverHide() }} />
                    <div>
                        {!showTextArea && <div className="score-color-popover-positioned show popover bs-popover-top">
                            <div className="arrow" ></div>
                            {ColourCode.map((colorCode, i) => (
                                <Button
                                    key={i}
                                    variant="default"
                                    className="color-btn"
                                    style={{ background: colorCode }}
                                    onClick={() => { changeColor(colorCode) }}
                                    // disabled = {(!PopOverStudentData.grading_event_student_score && !num) || (ClassData.cc_class_share_permission === 'read')}
                                    disabled = {(ClassData.cc_class_share_permission === 'read')}
                                >
                                    {colorCode === PopOverStudentData.grading_event_student_color_code ? <img src={tickIcon} alt='' /> : ""}
                                </Button>))}
                        </div>}
                        <div  className="number-arrow editgradebookpopover" style={{ display: 'inline-block' }}>
                            {/* <Form.Control
                                className={`input-box ${PopOverStudentData.grading_event_student_color_code}`}
                                placeholder=""
                                aria-label="Score"
                                type="number"
                                // value={PopOverStudentData.grading_event_student_score}
                                value={PopOverStudentData.grading_event_student_score}
                                as="input"
                                style={{ border: PopOverStudentData.grading_event_student_color_code && `1px solid ${PopOverStudentData.grading_event_student_color_code}`, color: PopOverStudentData.grading_event_student_color_code, background: `${PopOverStudentData.grading_event_student_color_code !== "" ? `${PopOverStudentData.grading_event_student_color_code}18` : ""}` }}
                                onChange={(e) => { changeUpdate(e, 'score') }}
                            /> */}
                            <TextField
                variant="outlined"
                // type={type}
                value={num}
                onChange={(event) => {changeUpdates(event,"score")}}
                // name={name}
                // placeholder={placeholder}
                className={`input-box ${PopOverStudentData.grading_event_student_color_code}`}
                // label={placeholder}
                // disabled={disabled}
                // maxLength={maxLength}
                style={{ border: PopOverStudentData.grading_event_student_color_code && `1px solid ${PopOverStudentData.grading_event_student_color_code}`, color: PopOverStudentData.grading_event_student_color_code, background: `${PopOverStudentData.grading_event_student_color_code !== "" ? `${PopOverStudentData.grading_event_student_color_code}18` : ""}` }}
                InputProps={{ disableUnderline: true }}
                disabled={ClassData?.cc_class_share_permission === 'read'}
            />
                            {/* <CommonTextField
                                    // margin="normal"
                                    variant="outlined"
                                    // type='number'
                                    // value={item.grading_event_student_score ? item.grading_event_student_score : ''}
                                    value={PopOverStudentData.grading_event_student_score}
                                    // onChange={(e) => changeInput(e, "score", index)}
                                    onChange={(event) => {changeUpdate(event,"score")}}
                                    name="grading_event_student_score"
                                    // className="InfoInputType"
                                    // className="score-input text-center"
                                    className={`input-box ${PopOverStudentData.grading_event_student_color_code}`}
                                    style={{ border: PopOverStudentData.grading_event_student_color_code && `1px solid 
                                    ${PopOverStudentData.grading_event_student_color_code}`, 
                                    color: PopOverStudentData.grading_event_student_color_code, 
                                    background: `${PopOverStudentData.grading_event_student_color_code !== "" ? `${PopOverStudentData.grading_event_student_color_code}18` : ""}` }}
                                    placeholder="score" /> */}
                            {!showTextArea && <Button variant="default" className="addCommentBtn" onClick={() => { setShowTextAreaCheck(!showTextArea) }} disabled={ClassData.cc_class_share_permission === 'read'}>
                                <img src={addCommentIcon} className="" alt="" width="20" height="20" />
                            </Button>}
                            {showTextArea && <Button variant="default" className="addCommentBtn" onClick={() => { setShowTextAreaCheck(!showTextArea) }}>
                                <img src={hideCommentIcon} className="" alt="" width="20" height="20" />
                            </Button>}
                            <Button variant="default" className={showTextArea ? "saveBtnWhenComment" : "saveBtn"} onClick={saveGrade} disabled={ClassData.cc_class_share_permission === 'read'}>
                                Save
                            </Button>
                        </div>
                    </div>
                    {showTextArea && (
                        <div>
                            <Form.Control onChange={(e) => { changeUpdate(e, 'comment') }} as="textarea" rows="6" placeholder="Enter comments" value={PopOverStudentData.grading_event_student_comments} />
                        </div>
                    )}
                </div>
            </PopoverBody>
        </UncontrolledPopover>
    )
}
ClassGradebookColorPopover.propTypes = {
    setValue: PropTypes.bool,
    sIndex: PropTypes.number,
    eIndex: PropTypes.number,
    ClassData: PropTypes.object,
    cIndex: PropTypes.number,
    closePopOver: PropTypes.func,
    PopOverStudentData: PropTypes.object,
    updateClassGradebookPopoverColorRequest: PropTypes.func,
    updateClassGradebookPopoverInputRequest: PropTypes.func,
    updateClassGradebookScoreCommentRequest: PropTypes.func,

};
export default ClassGradebookColorPopover