import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


// import Logger from '../Lib/Logger'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getRoster: ['data'],
    getRosterSuccess: ['data'],
    getRosterFailure: ['error'],

    editRoster: ['data'],
    editRosterSuccess: ['data'],
    editRosterFailure: ['error'],


    // -----------Contact--------
    addRosterContact: ['data'],
    addRosterContactSuccess: ['data'],
    addRosterContactFailure: ['error'],

    editRosterContact: ['data'],
    editRosterContactSuccess: ['data'],
    editRosterContactFailure: ['error'],

    deleteRosterContact: ['data'],
    deleteRosterContactSuccess: ['data'],
    deleteRosterContactFailure: ['error'],
    // -----------Contact--------


    // -----------Info--------
    addRosterInfo: ['data'],
    addRosterInfoSuccess: ['data'],
    addRosterInfoFailure: ['error'],

    editRosterInfo: ['data'],
    editRosterInfoSuccess: ['data'],
    editRosterInfoFailure: ['error'],

    deleteRosterInfo: ['data'],
    deleteRosterInfoSuccess: ['data'],
    deleteRosterInfoFailure: ['error'],
    // -----------Info--------

    addLinkRoster: ['data'],
    addLinkRosterSuccess: ['data'],
    addLinkRosterFailure: ['error'],

})

export const UserRosterProfileReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    get_roster_Data: null,
    edit_roster_Data: null,
    add_roster_Contact: null,
    edit_roster_Contact: null,
    delete_roster_Contact: null,
    add_roster_Info: null,
    edit_roster_Info: null,
    delete_roster_Info: null,
    link_roster_Data: null,
    error: null,
    exists: null,
    loader: null,
    action: null
})

/* ------------- Reducers ------------- */


/* getRoster */
export const getRoster = (state) => {
    return {
        ...state, fetching: true, error: null, get_roster_Data: null, loader: true
    }
}
export const getRosterSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, get_roster_Data: data, action: action.type, exists: true, loader: false }
}
export const getRosterFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_ROSTER_FAILURE', loader: false }
}

/* EditRoster */
export const editRoster = (state) => {
    return {
        ...state, fetching: true, error: null, edit_roster_Data: null, loader: true
    }
}
export const editRosterSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, edit_roster_Data: data, action: action.type, exists: true, loader: false }
}
export const editRosterFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'EDIT_ROSTER_FAILURE', loader: false }
}

// -----------Contact--------

/* Add Roster Contact */
export const addRosterContact = (state) => {
    return {
        ...state, fetching: true, error: null, add_roster_Contact: null, loader: true
    }
}
export const addRosterContactSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, add_roster_Contact: data, action: action.type, exists: true, loader: false }
}
export const addRosterContactFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'ADD_ROSTER_CONTACT_FAILURE', loader: false }
}

/* Edit Roster Contact */
export const editRosterContact = (state) => {
    return {
        ...state, fetching: true, error: null, edit_roster_Contact: null, loader: true
    }
}
export const editRosterContactSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, edit_roster_Contact: data, action: action.type, exists: true, loader: false }
}
export const editRosterContactFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'EDIT_ROSTER_CONTACT_FAILURE', loader: false }
}


/* Delete Roster Contact */
export const deleteRosterContact = (state) => {
    return {
        ...state, fetching: true, error: null, delete_roster_Contact: null, loader: true
    }
}
export const deleteRosterContactSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, delete_roster_Contact: data, action: action.type, exists: true, loader: false }
}
export const deleteRosterContactFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'DELETE_ROSTER_CONTACT_FAILURE', loader: false }
}
// -----------Contact--------



// -----------Info--------

/* Add Roster Info */
export const addRosterInfo = (state) => {
    return {
        ...state, fetching: true, error: null, add_roster_Info: null, loader: true
    }
}
export const addRosterInfoSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, add_roster_Info: data, action: action.type, exists: true, loader: false }
}
export const addRosterInfoFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'ADD_ROSTER_INFO_FAILURE', loader: false }
}

/* Edit Roster Info */
export const editRosterInfo = (state) => {
    return {
        ...state, fetching: true, error: null, edit_roster_Info: null, loader: true
    }
}
export const editRosterInfoSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, edit_roster_Info: data, action: action.type, exists: true, loader: false }
}
export const editRosterInfoFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'EDIT_ROSTER_INFO_FAILURE', loader: false }
}


/* Delete Roster Info */
export const deleteRosterInfo = (state) => {
    return {
        ...state, fetching: true, error: null, delete_roster_Info: null, loader: true
    }
}
export const deleteRosterInfoSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, delete_roster_Info: data, action: action.type, exists: true, loader: false }
}
export const deleteRosterInfoFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'DELETE_ROSTER_INFO_FAILURE', loader: false }
}
// -----------Info--------


/* add Link Roster */
export const addLinkRoster = (state) => {
    return {
        ...state, fetching: true, error: null, link_roster_Data: null, loader: true
    }
}
export const addLinkRosterSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, link_roster_Data: data, action: action.type, exists: true, loader: false }
}
export const addLinkRosterFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'ADD_LINK_ROSTER_FAILURE', loader: false }
}




/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {



    [Types.GET_ROSTER]: getRoster,
    [Types.GET_ROSTER_SUCCESS]: getRosterSuccess,
    [Types.GET_ROSTER_FAILURE]: getRosterFailure,

    [Types.EDIT_ROSTER]: editRoster,
    [Types.EDIT_ROSTER_SUCCESS]: editRosterSuccess,
    [Types.EDIT_ROSTER_FAILURE]: editRosterFailure,


    // -----------Contact--------
    [Types.ADD_ROSTER_CONTACT]: addRosterContact,
    [Types.ADD_ROSTER_CONTACT_SUCCESS]: addRosterContactSuccess,
    [Types.ADD_ROSTER_CONTACT_FAILURE]: addRosterContactFailure,


    [Types.EDIT_ROSTER_CONTACT]: editRosterContact,
    [Types.EDIT_ROSTER_CONTACT_SUCCESS]: editRosterContactSuccess,
    [Types.EDIT_ROSTER_CONTACT_FAILURE]: editRosterContactFailure,

    [Types.DELETE_ROSTER_CONTACT]: deleteRosterContact,
    [Types.DELETE_ROSTER_CONTACT_SUCCESS]: deleteRosterContactSuccess,
    [Types.DELETE_ROSTER_CONTACT_FAILURE]: deleteRosterContactFailure,
    // -----------Contact--------


    // -----------Info--------
    [Types.ADD_ROSTER_INFO]: addRosterInfo,
    [Types.ADD_ROSTER_INFO_SUCCESS]: addRosterInfoSuccess,
    [Types.ADD_ROSTER_INFO_FAILURE]: addRosterInfoFailure,


    [Types.EDIT_ROSTER_INFO]: editRosterInfo,
    [Types.EDIT_ROSTER_INFO_SUCCESS]: editRosterInfoSuccess,
    [Types.EDIT_ROSTER_INFO_FAILURE]: editRosterInfoFailure,

    [Types.DELETE_ROSTER_INFO]: deleteRosterInfo,
    [Types.DELETE_ROSTER_INFO_SUCCESS]: deleteRosterInfoSuccess,
    [Types.DELETE_ROSTER_INFO_FAILURE]: deleteRosterInfoFailure,
    // -----------Info--------


    [Types.ADD_LINK_ROSTER]: addLinkRoster,
    [Types.ADD_LINK_ROSTER_SUCCESS]: addLinkRosterSuccess,
    [Types.ADD_LINK_ROSTER_FAILURE]: addLinkRosterFailure,






})
