import React, { useState, useEffect } from 'react';
import {  useDispatch, useSelector } from 'react-redux';
import { getRosterEnrolled, editRosterEnrolled } from 'Actions/RosterAction/rosterEnrolledAction';
import { createNotification } from 'Config/notificationtoast';
import DivLoader from 'Components/LoadingComponent/DivLoader';
import classIcon from 'Assets/RosterModule/class.png'
import noteIcon from 'Assets/RosterModule/note.png'
import gradebookIcon from 'Assets/RosterModule/gradebook.png'
import userShareIcon from 'Assets/RosterModule/userShare.png'
import * as routes from "Router/RoutesURL";
import { getFormattedDate } from "Utils/Helper";
import { getStudentGradebookNullRequest } from 'Actions/GradebookAction/studentGradebookAction';
import { Button } from 'reactstrap';
import './RosterEnrolled.scss';
import { useHistory } from 'react-router-dom';

const RosterEnrolled = () => {
    // constructor(props) {
    //     super(props);

        // const signDate = sessionStorage.getItem('separatorFormat')
        // const formatDate = sessionStorage.getItem('dateFormat')
        // this.state = {
        //     PageFrom: '',
        //     studentId: '',
        //     academicYear: '',
        //     classIdForGradebook: '',
        //     studentInfo: [],
        //     page_no: 1,
        //     level: '',

        //     EnrolledData: [],
        //     changesNotes: [],
        //     edit: false,
        //     classPermission: '',
        //     formatDate: formatDate,
        //     signDate: signDate
        // }
        // this.handleInputChange = this.handleInputChange.bind(this);

    // }
    // ---------BackTO-------

    const [PageFrom, setPageFrom] = useState('');
    const [studentId, setstudentId] = useState('');
    const [academicYear, setacademicYear] = useState('');
    const [classIdForGradebook, setclassIdForGradebook] = useState('');
    const [studentInfo, setstudentInfo] = useState([]);
    const [page_no, setpage_no] = useState(1);
    const [level, setlevel] = useState('');
    const [EnrolledData, setEnrolledData] = useState([]);
    const [changesNotes, setchangesNotes] = useState([]);
    const [edit, setedit] = useState(false);
    const [classPermission, setclassPermission] = useState('');
    const [formatDate, setformatDate] = useState(sessionStorage.getItem('dateFormat'));
    const [signDate, setsignDate] = useState(sessionStorage.getItem('separatorFormat'))
    const dispatch = useDispatch();
   const history = useHistory();
   const loader = useSelector((state) => state.rosterEnrilled.loader);
   const rosterEnrolledData = useSelector((state) => state.rosterEnrilled)
    const BackTOHome = () => {
        history.goBack()
    }
    // ---------BackTO-------


    // ---------UNSAFE_componentWillMount-------
    // UNSAFE_componentWillMount() {
    //     this.getRosterEnrolled()
    // }
    useEffect(() => {
        getRosterEnrolledd()
    }, [])

    // ---------UNSAFE_componentWillMount-------

    // ---------componentWillReceiveProps-------
    // async UNSAFE_componentWillReceiveProps(nextProps) {
    //     // ---------get Roster Enrolled -------
    //     if (nextProps.rosterEnrolledData.action === 'GET_ROSTER_ENROLLED_SUCCESS') {
    //         if (nextProps.rosterEnrolledData.get_roster_Enrolled && nextProps.rosterEnrolledData.get_roster_Enrolled.status === 200) {
    //             if (nextProps.rosterEnrolledData.get_roster_Enrolled.data) {
    //                 let tempOnLoadData = nextProps.rosterEnrolledData.get_roster_Enrolled.data
    //                 // for (let item of tempOnLoadData) {
    //                 //     item.note_details1 = item.note_details
    //                 // }
    //                 this.setState({
    //                     EnrolledData: tempOnLoadData
    //                 })
    //                 if (this.props.history.location.state.BackPage === 'Roster') {
    //                     this.setState({
    //                         classIdForGradebook: tempOnLoadData[0].cc_class_id
    //                     })
    //                 }
    //                 // note_details
    //                 // this.setState({
    //                 //     EnrolledData: nextProps.rosterEnrolledData.get_roster_Enrolled.response_data
    //                 // })
    //             } else {
    //                 this.setState({
    //                     EnrolledData: []
    //                 })
    //             }
    //         }


    //     }
    // //     // ---------get Roster Enrolled-------
    // }
    useEffect(() => {
        if (rosterEnrolledData.action === 'GET_ROSTER_ENROLLED_SUCCESS') {
            if (rosterEnrolledData.get_roster_Enrolled && rosterEnrolledData.get_roster_Enrolled.status == 200) {
                if (rosterEnrolledData.get_roster_Enrolled.data) {
                    let tempOnLoadData = rosterEnrolledData.get_roster_Enrolled.data
                    // for (let item of tempOnLoadData) {
                    //     item.note_details1 = item.note_details
                    // }
                    // this.setState({
                    //     EnrolledData: tempOnLoadData
                    // })
                    setEnrolledData(tempOnLoadData);

                    if (history.location.state.BackPage === 'Roster') {
                        // this.setState({
                        //     classIdForGradebook: tempOnLoadData[0].cc_class_id
                        // })
                        setclassIdForGradebook(tempOnLoadData[0].cc_class_id)
                    }
                    // note_details
                    // this.setState({
                    //     EnrolledData: nextProps.rosterEnrolledData.get_roster_Enrolled.response_data
                    // })
                } else {
                    // this.setState({
                    //     EnrolledData: []
                    // })
                    setEnrolledData([])
                }
            }


        }
    }, [rosterEnrolledData])

    // ---------componentWillReceiveProps-------
    function getRosterEnrolledd () {
        // const { location = {} } = this.props
        // const { state = {} } = location;
        // const { academicYear = '', studentInfo = '', From = '', classId = '', classPermission = '' } = state;
        const {academicYear = ""} = history.location.state;
        const {studentInfo = ""} = history.location.state;
        const {From = ""} = history.location.state;
        const {classId = ""} = history.location.state;
        const {classPermission = ""} = history.location.state;


        // this.setState({
        //     PageFrom: From,
        //     studentId: studentInfo.cc_student_id,
        //     academicYear: academicYear,
        //     studentInfo: studentInfo,
        //     classIdForGradebook: classId,
        //     classPermission: classPermission

        // })
        setPageFrom(From);
        setstudentId(studentInfo.cc_student_id);
        setacademicYear(academicYear);
        setstudentInfo(studentInfo);
        setclassIdForGradebook(classId);
        setclassPermission(classPermission);

        const data = {
            "student_id": studentInfo.cc_student_id,
            "page_no": page_no
        }
        dispatch(getRosterEnrolled(data))
    }


    // ---------handleChange-------
    const handleInputChange = (event, subIndex, Index) => {
        let EnrolledData = EnrolledData;
        if (event.target.name === "cc_note_level") {
            if (event.target.value.length < 3) {
                EnrolledData[Index].note_details[subIndex].cc_note_level = event.target.value;
                setEnrolledData(EnrolledData)
                let pushValue = {
                    "student_note_id": EnrolledData[Index].note_details[subIndex].cc_note_id,
                    "student_score": EnrolledData[Index].note_details[subIndex].cc_note_score,
                    "student_level": EnrolledData[Index].note_details[subIndex].cc_note_level,
                    "last_edited": 0
                }

                let changesNotes = changesNotes;
                let findIndex = '';
                let status = false;
                if (changesNotes.length > 0) {
                    let arrayIndex = 0;
                    for (let item of changesNotes) {
                        if (item.student_note_id === pushValue.student_note_id) {
                            status = true;
                            findIndex = arrayIndex;
                        } else {
                        }
                        arrayIndex++
                    }
                    if (status === true) {
                        changesNotes.splice(findIndex, 1);
                        changesNotes.push(pushValue)
                    } else {
                        changesNotes.push(pushValue)
                    }
                }
                else {
                    changesNotes.push(pushValue)
                }
            }
        }else{
            EnrolledData[Index].note_details[subIndex].cc_note_score = event.target.value;
            setEnrolledData(EnrolledData)
            let pushValue = {
                "student_note_id": EnrolledData[Index].note_details[subIndex].cc_note_id,
                "student_score": EnrolledData[Index].note_details[subIndex].cc_note_score,
                "student_level": EnrolledData[Index].note_details[subIndex].cc_note_level,
                "last_edited": 0
            }

            let changesNotes = changesNotes;
            let findIndex = '';
            let status = false;
            if (changesNotes.length > 0) {
                let arrayIndex = 0;
                for (let item of changesNotes) {
                    if (item.student_note_id === pushValue.student_note_id) {
                        status = true;
                        findIndex = arrayIndex;
                    } else {
                    }
                    arrayIndex++
                }
                if (status === true) {
                    changesNotes.splice(findIndex, 1);
                    changesNotes.push(pushValue)
                } else {
                    changesNotes.push(pushValue)
                }
            }
            else {
                changesNotes.push(pushValue)
            }
        }
    }
    // ---------handleChange-------

    // ---------cancelChange-------
    const cancelChanges = (index) => {
        // const { rosterEnrolledData } = this.props
        let data = EnrolledData;
        data[index].note_details = data[index].note_details1
        let array = [];
        // this.setState({
        //     EnrolledData: data,
        //     changesNotes: array,
        //     edit: false
        // }, () => {

        // })
        setEnrolledData(data);
        setchangesNotes(array);
        setedit(false)


    }
    // ---------cancelChange-------

    const updateValue = () => {
        let arrya = changesNotes;
        if (arrya.length === 0) {
            createNotification('error', 'Please change the value of Level/Score.');
            return;
        }
        let DefultIndex = 0;
        for (let item of arrya) {
            if (DefultIndex === (arrya.length - 1)) {
                item.last_edited = 1
            } else {
                item.last_edited = 0
            }
            DefultIndex++
        }
        // arrya[arrya.length - 1].last_edited = 1;
        // const { studentId, page_no } = this.state;
        const data = {
            "student_id": studentId,
            "page_no": page_no,
            "student_details_array": arrya

        }
        // this.setState({
        //     edit: false
        // })
        setedit(false)
        editRosterEnrolled(data)
    }

    // render() {
        // const { loader,
        //         history,
        //         getStudentGradebookNullRequest } = this.props
        // let { EnrolledData, academicYear, studentInfo, classPermission } = this.state
        const disShareOptionDivEnrolled = ['shareOptionDivEnrolled', classPermission !== null && classPermission === "read"? 'opacityProp':'']

        return (
            <div className='RosterEnrolledContainer'>
                {/* ---------Back to class ------ */}
                <div className="enrolledToRosterMainDiv">
                    <div className="enrolledToRosterBackDiv" onClick={() => BackTOHome()}>
                        <i className="enrolledBackIcon material-icons">chevron_left</i>

                        <p className='enrolledToRosterText'>{history.location.state.BackPage} </p>
                        {/* <p className='enrolledToRosterText'>{PageFrom === 'Academic' ? "Notes" : "Roster"} </p> */}
                    </div>
                    <div>
                        {/* ------------options Div----------  */}
                        <div className='studentOptionDivEnrolled'>
                            {/* ------------Class Div----------  */}
                            <div className='classOptionDivEnrolled' >
                                <img src={classIcon} alt='' className='optionIconsEnrolled' />
                                <p className='optionTextEnrolled'>Classes</p>
                            </div>

                            {/* ------------Class Div----------  */}
                            {/* ------------Class Div----------  */}
                            <div className='noteOptionDivEnrolled' onClick={() => {
                                history.push(routes.STUDENTNOTE, { id: studentInfo.cc_student_id, academicYearIDFromClassNote : history?.location?.state?.academicYearIDFromClassNote, studentInfo: studentInfo, academicYear: academicYear, classId: classIdForGradebook, pageBYClass: false, BackPage: 'Roster Enrolled', classPermission: classPermission })
                            }}>
                                <img src={noteIcon} alt='' className='optionIconsEnrolled' />
                                <p className='optionTextEnrolled'>Notes</p>

                            </div>

                            {/* ------------Class Div----------  */}

                            {/* ------------Class Div----------  */}
                            <div className='gradebookOptionDivEnrolled' onClick={() => {
                                getStudentGradebookNullRequest()
                                history.push(routes.STUDENTGRADEBOOK, { id: studentInfo.cc_student_id, academicYearIDFromClassNote : history?.location?.state?.academicYearIDFromClassNote, academicYear: academicYear, classId: classIdForGradebook, studentInfo: studentInfo, BackPage: 'Roster Enrolled', classPermission: classPermission })
                            }}>
                                <img src={gradebookIcon} alt='' className='optionGreadeBookIconsEnrolled' />
                                <p className='optionGreadeBookTextEnrolled'>GradeBook</p>
                            </div>

                            {/* ------------Class Div----------  */}
                            {/* ------------Class Div----------  */}
                            {classPermission !== null && classPermission === "read"?
                            <div className={disShareOptionDivEnrolled.join(' ')} >
                                <img src={userShareIcon} alt='' className='optionIconsEnrolled' />
                                <p className='optionTextEnrolled'>Share</p>
                            </div>:
                            <div className='shareOptionDivEnrolled' onClick={() => {
                                history.push(routes.STUDENTSHARE, {
                                    studentInfo: studentInfo.cc_student_id
                                   });
                            }}>
                                <img src={userShareIcon} alt='' className='optionIconsEnrolled' />
                                <p className='optionTextEnrolled'>Share</p>
                            </div>}
                            {/* ------------Class Div----------  */}
                        </div>
                        {/* ------------options Div----------  */}
                    </div>
                </div>
                {/* ---------Back to class  ------ */}

                {/* ---------Roster Class Container Heading-------- */}
                {/* <div>
                    <p className='RosterEnrolledHeading'>Enrolled Class ({academicYear})</p>
                </div> */}

                {/* ------ Loader Div----- */}
                {loader && <div>
                    <DivLoader />
                </div>}
                {/* ------ Loader Div----- */}
                {/* ---------Roster Class Container Heading-------- */}

                {/* ---------Roster Class Enrolled List-------- */}

                {EnrolledData && EnrolledData.length > 0 && EnrolledData.map((item, index) => (<div key={index} className='rosterClassListMain'>
                    {/* -------------Heading Div---------- */}
                    <div className='rosterClassHeadingDiv'>
                        <div>
                            <p className='rosterClassText'>{item.cc_class_name}</p>
                        </div>
                        {item.note_details && item.note_details.length > 0 ? <div>
                            {edit === item.cc_class_id ? <div>
                                <Button onClick={updateValue} className='rosterUpdateButton' color="primary" >
                                    <span className='rosterButtonText' > Update</span>
                                </Button>
                                <span onClick={() => {
                                    cancelChanges(index)

                                }} className='rosterClassCancelText'>Cancel</span>
                            </div> :
                            <div>
                                {classPermission !== null && classPermission === 'read' && <div style={{ marginTop: 5, cursor: 'default', opacity:0.5}}>
                                    <i className="rosterClassEditIcon material-icons">edit</i>
                                    <span className='rosterClassEditText'>Edit</span>
                                </div>}

                                {classPermission !== null && classPermission !== 'read' && <div onClick={() => {
                                    // this.setState({
                                    //     edit: item.cc_class_id,
                                    //     changesNotes: []
                                    // })
                                    setedit(item.cc_class_id);
                                    setchangesNotes([]);
                                }} style={{ marginTop: 5, cursor: 'pointer' }}>
                                    <i className="rosterClassEditIcon material-icons">edit</i>
                                    <span className='rosterClassEditText'>Edit</span>
                                </div>}
                            </div>}
                            </div>
                            :
                            <div>
                                <p className='rosterClassText'>Note Not Found</p>
                            </div>}
                    </div>
                    {/* -------------Heading Div---------- */}

                    {/* -------------Details Div---------- */}
                    {item.note_details && item.note_details.length > 0 && item.note_details.map((subItem, SubIndex) => (<div key={SubIndex} className='rosterClassDetails'>
                        <div className='rosterClassDetailsParent' >
                            <div className='rosterClassDetailsListMain'>
                                <div>
                                    <p className='rosterClassDateText'>{getFormattedDate(subItem.cc_note_reminder_date, formatDate, signDate)}</p>
                                </div>
                                <div>

                                    {edit === item.cc_class_id ?
                                        <div className='inputMainDivFOrInline'>
                                            <div style={{ display: 'inline-flex' }} >
                                                <p className='levelTextWithINput'>Level</p>
                                                <input type='text' value={subItem.cc_note_level}
                                                    // onChange={this.handleChange}
                                                    onChange={(e) => handleInputChange(e, SubIndex, index)}
                                                    name="cc_note_level"
                                                    className="levelInput"
                                                    placeholder="Enter Level"></input>

                                            </div>
                                            <p className='horizantalLine'>|</p>
                                            <div style={{ display: 'inline-flex' }}>
                                                <p className='levelTextWithINput'> Score</p>
                                                <input type='text' value={subItem.cc_note_score}
                                                    // onChange={this.handleChange}
                                                    onChange={(e) => handleInputChange(e, SubIndex, index)}
                                                    name="cc_note_score"
                                                    className="levelInput"
                                                    placeholder="Enter Level"></input>
                                            </div>

                                        </div> : <p className='rosterClassDateText'>Level: {subItem.cc_note_level ? subItem.cc_note_level : '-'} | Score: {subItem.cc_note_score ? subItem.cc_note_score : '-'}</p>}
                                </div>
                            </div>
                            <div className={SubIndex === item.note_details.length - 1 ? 'rosterClassDetailsLastMain' : 'rosterClassDetailsMain'} >
                                <div>
                                    <p className='rosterClassDetailsText'>{subItem.tag_comment_text}</p>
                                </div>
                            </div>

                        </div>
                    </div>))}


                    {/* -------------Details Div---------- */}


                </div>))}

                {/* ---------Roster Class Enrolled List-------- */}
                {EnrolledData.length === 0 && <div className='noRoster'>
                    {loader ? "" :
                        <p className='tapToAddText'>No Enrolled Class </p>}

                </div>}



            </div>
        )
    // }
}
const mapStateToProps = state => ({
    state: state,
    rosterEnrolledData: state.rosterEnrilled,
    loader: state.rosterEnrilled.loader
});
// const mapDispatchToProps = dispatch => {
//     return {
//         getRosterEnrolled: (data) => dispatch(getRosterEnrolled(data)),
//         editRosterEnrolled: (data) => dispatch(editRosterEnrolled(data)),
//         getStudentGradebookNullRequest: (data) => dispatch(getStudentGradebookNullRequest(data))

//     };
// };
export default RosterEnrolled