

import {
    ADD_CLASS_ROSTER,
    ADD_CLASS_ROSTER_SUCCESS,
    ADD_CLASS_ROSTER_FAILURE,


    GET_CLASS_ROSTER_LIST,
    GET_CLASS_ROSTER_LIST_SUCCESS,
    GET_CLASS_ROSTER_LIST_FAILURE,

    DELETE_CLASS_ROSTER,
    DELETE_CLASS_ROSTER_SUCCESS,
    DELETE_CLASS_ROSTER_FAILURE,

    UPDATE_ROSTER_LEVEL,
    UPDATE_ROSTER_LEVEL_SUCCESS,
    UPDATE_ROSTER_LEVEL_FAILURE,

    SEARCH_CLASS_ROSTER,
    SEARCH_CLASS_ROSTER_SUCCESS,
    SEARCH_CLASS_ROSTER_FAILURE,

    IMPORT_CLASS_ROSTER,
    IMPORT_CLASS_ROSTER_SUCCESS,
    IMPORT_CLASS_ROSTER_FAILURE,
    SET_CLASS_ROSTER_STATE,



} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */

// -----------add Roster-------------
export function addClassRoster(data) {
    return {
        type: ADD_CLASS_ROSTER,
        data,
    };
}

export function addClassRosterSuccess(data) {
    return {
        type: ADD_CLASS_ROSTER_SUCCESS,
        data,
    };
}

export function addClassRosterFailure(data) {
    return {
        type: ADD_CLASS_ROSTER_FAILURE,
        data,
    };
}


// -----------Get RosterList-------------
export function getClassRosterList(data) {
    return {
        type: GET_CLASS_ROSTER_LIST,
        data,
    };
}

export function getClassRosterListSuccess(data) {
    return {
        type: GET_CLASS_ROSTER_LIST_SUCCESS,
        data,
    };
}

export function getClassRosterListFailure(data) {
    return {
        type: GET_CLASS_ROSTER_LIST_FAILURE,
        data,
    };
}


// -----------delete Roster-------------
export function deleteClassRoster(data) {
    return {
        type: DELETE_CLASS_ROSTER,
        data,
    };
}

export function deleteClassRosterSuccess(data) {
    return {
        type: DELETE_CLASS_ROSTER_SUCCESS,
        data,
    };
}

export function deleteClassRosterFailure(data) {
    return {
        type: DELETE_CLASS_ROSTER_FAILURE,
        data,
    };
}


// -----------Update Class Roster Level-------------
export function updateRosterLevel(data) {
    return {
        type: UPDATE_ROSTER_LEVEL,
        data,
    };
}

export function updateRosterLevelSuccess(data) {
    return {
        type: UPDATE_ROSTER_LEVEL_SUCCESS,
        data,
    };
}

export function updateRosterLevelFailure(data) {
    return {
        type: UPDATE_ROSTER_LEVEL_FAILURE,
        data,
    };
}



// -----------Search RosterList-------------
export function searchClassRoster(data) {
    return {
        type: SEARCH_CLASS_ROSTER,
        data,
    };
}

export function searchClassRosterSuccess(data) {
    return {
        type: SEARCH_CLASS_ROSTER_SUCCESS,
        data,
    };
}

export function searchClassRosterFailure(data) {
    return {
        type: SEARCH_CLASS_ROSTER_FAILURE,
        data,
    };
}


// -----------Import Class Roster-------------
export function importClassRoster(data) {
    return {
        type: IMPORT_CLASS_ROSTER,
        data,
    };
}

export function importClassRosterSuccess(data) {
    return {
        type: IMPORT_CLASS_ROSTER_SUCCESS,
        data,
    };
}

export function importClassRosterFailure(data) {
    return {
        type: IMPORT_CLASS_ROSTER_FAILURE,
        data,
    };
}

export function setClassRosterState(data) {
    return {
        type: SET_CLASS_ROSTER_STATE,
        data
    }
}