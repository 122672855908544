import React, { useState, useEffect, useRef } from 'react';
import SelectSearch from 'react-select-search';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import arrow from 'Assets/AttendanceModule/arrow.png';
import { getFormattedDate } from 'Utils/Helper';

import * as routes from "Router/RoutesURL";
import './ClassRubricsDashboard.scss';

const ImportRubrics = (props) => {
    const {
        classeslist,
        getRubricList,
        getRubricListState,
        loader,
        history,
        setShowImportPage,
    } = props;
    const [rubricsListAll, setRubricsListAll] = useState([]);
    const [rubricsCount, setRubricsCount] = useState("");
    const [filterType, setFilterType] = useState("all");
    const [searchInputRub, setSearchInputRub] = useState('');
    const [mainResponse, setMainResponse] = useState([]);
    const [selectedClassSecond, setSelectedClassSecond] = useState("");
    const [classdetailSecond, setClassdetailSecond] = useState({});
    const [selectedClassNameSecond, setSelectedClassNameSecond] = useState("");
    const signDate = sessionStorage.getItem('separatorFormat')
    const formatDate = sessionStorage.getItem('dateFormat')
    const _ = require('lodash');
    const rubricsSelectedClassDataOne = JSON.parse(sessionStorage.getItem('rubricsSelectedClass'))

    useEffect(() => {
        if (rubricsSelectedClassDataOne && rubricsSelectedClassDataOne.cc_class_id) {
            sessionStorage.setItem("rubricsSelectedClassSecond", JSON.stringify(rubricsSelectedClassDataOne));
            setSelectedClassSecond(rubricsSelectedClassDataOne.cc_class_id);
            setClassdetailSecond(rubricsSelectedClassDataOne);
            setSelectedClassNameSecond(rubricsSelectedClassDataOne.cc_class_name);
            setSearchInputRub("");
            const apiData = {
                "class_id": rubricsSelectedClassDataOne.cc_class_id,
                "rubric_status": filterType,
                "start_date": "",
                "end_date": ""
            }
            getRubricList(apiData)
        }
    }, [])

    useEffect(() => {
        if (selectedClassSecond && getRubricListState && getRubricListState?.data?.rubricArr) {
            setRubricsCount(getRubricListState?.data?.rubricArr.length);
            setRubricsListAll(getRubricListState?.data?.rubricArr);
            setMainResponse(getRubricListState?.data?.rubricArr);
        }
    }, [getRubricListState])

    const getDateFormatAsApi = date => {
        const FormatDate = date.toISOString().split('T')[0]
        return FormatDate
    }

    const searchInput = useRef();
    const classDataList = classeslist;
    const newClassDataList = classeslist?.map((obj, i) => (
        {
            ...obj,
            name: classDataList[i].cc_class_name,
            value: classDataList[i].cc_class_id,
        }
    ));

    const newArr = newClassDataList?.map((name, i) => ({ ['name']: newClassDataList[i].cc_class_name, ['value']: newClassDataList[i].cc_class_id, ["data"]: newClassDataList[i] }));

    const options = [
        {
            type: "group",
            name: "",
            items: newArr !== undefined ? newArr : [{ name: "", value: "", data: "" },]
        }
    ];

    const handleFilter = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return options;
            }
            const updatedItems = items.map((list) => {
                const newItems = list.items.filter((item) => {
                    return item.name.toLowerCase().includes(searchValue.toLowerCase());
                });
                return { ...list, items: newItems };
            });
            return updatedItems;
        };
    };

    const handleChange = (value, ...args) => {
        sessionStorage.setItem("rubricsSelectedClassSecond", JSON.stringify(args[0].data));
        setSelectedClassSecond(args[0].value);
        setClassdetailSecond(args[0].data);
        setSelectedClassNameSecond(args[0].name);
        if (args[0].data.student_count > 0) {
            const apiData = {
                "class_id": args[0].value,
                "rubric_status": filterType,
                "start_date": "",
                "end_date": ""
            }
            getRubricList(apiData)
        }
    };

    const BackTOHome = () => {
        sessionStorage.setItem("rubricsSelectedClassSecond", JSON.stringify(""));
        setShowImportPage(false);
    }

    const openEditRubric = (item) => {

        history.push(routes.ADDNEWRUBRICS, {
            ClassDetails: rubricsSelectedClassDataOne,
            selectionType: "edit",
            SelectedRubric: item,
            Backpage: "importRubrics"
        })
    }

    const searchItems = (searchValue) => {
        setSearchInputRub(searchValue)
        if (searchValue !== '') {
            const filteredData = mainResponse.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInputRub.toLowerCase())
            })
            setRubricsListAll(filteredData)
        }
        else {
            setRubricsListAll(mainResponse)
        }
    }

    return (
        <div className='classRubricsContainer'>
            <div>
                <div className='RubricsMainDiv'>
                    <div className="rubBackDivmain">
                        <div className='rubricsImportBackDiv' onClick={BackTOHome} >
                            <i className='importBackIcon material-icons'>chevron_left</i>
                        </div>
                        <div className="vrLineRub"></div>
                        <div className='rubricsDetailsDiv'>
                            <div className='displayFlexClassRubrics'>
                                <p className='rubricsGrade2'>Import Rubrics</p>
                            </div>
                        </div>
                    </div>
                    {<div className="dropdownsRubrics-main-container">
                        <div className="iconDiv"><i className="fa fa-search searchFiltersIcon"></i></div>
                        <SelectSearch
                            ref={searchInput}
                            options={options}
                            filterOptions={handleFilter}
                            value={{ name: selectedClassNameSecond, value: 0 }}
                            name="Workshop"
                            placeholder="Search class*"
                            search
                            onChange={handleChange}
                        />
                    </div>}
                    <div className='buttonMarginTop'></div>
                </div>
                <div>
                    {<div className="importRubHeaderTwo">
                        <div className='lessonDetailTxtDiv'>
                            <div className="wrappersSearchDiv">
                                <div className="iconDiv"><i className="fa fa-search searchFilterIcon"></i></div>
                                <input icon='Search'
                                    className="inputBoxComp"
                                    placeholder='Enter Keywords…'
                                    onChange={(e) => searchItems(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="subDivs">
                            {rubricsSelectedClassDataOne.cc_class_name === selectedClassNameSecond ?
                                <div className="importClassName">
                                    <p className="classNameTagline">— Select rubrics to copy in the same class.</p></div>
                                : <div className="importClassName">
                                    <p className="classNameTagline">— Select rubrics to import in the</p>
                                    <p className="classNameTag">{rubricsSelectedClassDataOne.cc_class_name}</p>
                                    <p className="classNameTagline">class.</p>
                                </div>}
                        </div>
                    </div>}
                    {loader && <div>
                        <img src={loaderImag} alt='' className='loaderIconCss' />
                    </div>}

                    {!selectedClassSecond && <div className='rubContainersDiv'>
                        <img src={arrow} alt='' className='rubricsArrowImage' />
                        <div className='rubContainerTextDiv'>
                            <span className='rubContainerText'>Please select class from which you want to copy the rubrics</span>
                        </div>
                    </div>}
                    {selectedClassSecond && classdetailSecond.student_count < 1 && <div className='rubContainersDiv'>
                        <p className='rubContainerTextNoStd'>Please add students to the class to use rubrics module.</p>
                    </div>}
                    {selectedClassSecond && classdetailSecond.student_count > 0 && getRubricListState && rubricsListAll.length < 1 && <div className='rubContainersDiv'>
                        <p className='rubContainerTextNoStd'>No rubrics to import.</p>
                    </div>}
                    <Row className="mainColRubRow">
                        <Col md="12" className="mainColRubrics">
                            <Row className="groupPanels">

                                {selectedClassSecond !== "" && getRubricListState && classdetailSecond.student_count > 0 && rubricsListAll.map((item, index) => {
                                    return (
                                        <Col md="4" lg="3" className="mainCardDivsRub" key={index}>
                                            <div className="classCardDivs">
                                                <div className='classDetailsCards'>
                                                    <div className="topDiv">
                                                        <div className='textLeft'
                                                            onClick={() => { openEditRubric(item) }}>
                                                            <div>
                                                                <p className='lessonTitle'>{_.truncate(item.cc_rubric_title, {
                                                                    'length': 22,
                                                                    'omission': '...'
                                                                })}</p>
                                                                <div className='headingTitles'>
                                                                    <p className='attTitleName'> {getFormattedDate(item.cc_rubric_date, formatDate, signDate)} </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bottomDiv">
                                                        <div className='classTimeline'>
                                                            <div className='headingTitles'>
                                                                <p className='attTitleName'> Last Edit: </p>
                                                                <p className='head'>{getFormattedDate(item.cc_rubric_updated_at, formatDate, signDate)}</p>
                                                            </div>
                                                            <div className='headingTitlesRight'>
                                                                {item.cc_rubric_status === "drafted" ?
                                                                    <div className='lessonPlan'><p className='statusTextPlan'>{item.cc_rubric_status}</p></div>
                                                                    : item.cc_rubric_status === "completed" ? <div className='lessonEval'><p className='statusTextEval'>{item.cc_rubric_status}</p></div>
                                                                        : <div className='lessonPlan'><p className='statusTextPlan'>{item.cc_rubric_status}</p></div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>)
}
export default ImportRubrics;