import React from 'react';
import { connect } from 'react-redux';
import FormativeAssessmentDashboard from './FormativeAssessmentDashboardComponent/FormativeAssessmentDashboard';
import {
    getFormativeAssessmentRequest,
    getAssessmentStudentsListRequest,
    getAllStudentsListRequest,
    addAssessmentTagRequest,
    editAssessmentTagRequest,
    deleteAssessmentTagRequest
} from 'Actions/FormativeAssessmentAction/FormativeAssessmentAction';
import { getClassList } from 'Actions/ClassAction/classAction';
import { updateColorAssessmentTagRequired, updateSelectedAssessmentTagRequired } from 'Actions/FormativeAssessmentAction/FormativeAssessmentAction';
import { updateClassGradebookNullRequest } from 'Actions/GradebookAction/classGradebookAction';




const FormativeAssessmentIndex = (props) => {
    return (
        <FormativeAssessmentDashboard {...props} />
    )
}
const mapStateToProps = state => ({
    state: state,
    classeslist: state.class,
    FormativeAssessmentTags: state.FormativeAssessment.getFormativeAssiss,
    AllStudent: state.FormativeAssessment.getAssissTagStudent,
    loader: state.FormativeAssessment.loader,
    getSelectdIdForTag: state.FormativeAssessment.getSelectdIdForTag,
    getTagStudentLoader: state.FormativeAssessment.getTagStudentLoader,
    getAllStudentsStatus: state.FormativeAssessment.getAllStudentsStatus,
});
const mapDispatchToProps = dispatch => {
    return {
        getClassList: (data) => dispatch(getClassList(data)),
        getFormativeAssessmentRequest: (data) => dispatch(getFormativeAssessmentRequest(data)),
        getAssessmentStudentsListRequest: (data) => dispatch(getAssessmentStudentsListRequest(data)),
        getAllStudentsListRequest: (data) => dispatch(getAllStudentsListRequest(data)),
        addAssessmentTagRequest: (data) => dispatch(addAssessmentTagRequest(data)),
        editAssessmentTagRequest: (data) => dispatch(editAssessmentTagRequest(data)),
        deleteAssessmentTagRequest: (data) => dispatch(deleteAssessmentTagRequest(data)),
        updateColorAssessmentTagRequired: (data) => dispatch(updateColorAssessmentTagRequired(data)),
        updateSelectedAssessmentTagRequired: (data) => dispatch(updateSelectedAssessmentTagRequired(data)),

        updateClassGradebookNullRequest: (data) => dispatch(updateClassGradebookNullRequest(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    FormativeAssessmentIndex
);