import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getStudentGradebookRequest: ['data'],
    getStudentGradebookSuccess: ['data'],
    getStudentGradebookFailure: ['error'],

    updateStudentGradebookRequest: ['data'],
    updateStudentGradebookSuccess: ['data'],
    updateStudentGradebookFailure: ['erroe'],


    UpdateStudentGradebookAverageRequest: ['data'],

    UpdateStudentGradebookCheckboxRequest: ['data'],

    UpdateStudentGradebookInputRequest: ['data'],

    UpdateStudentGradebookColorRequest: ['data'],

    getStudentGradebookNullRequest: ['']
})


export const UserStudentGradebookReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    studentGradebookList: null,
    studentClassList: null,
    selectedClassId: '',
    backPage: '',
    eventAverage: 0,
    eventCount: 0,
    error: null,
    exists: null,
    loader: null,
    action: null,
})

/* ------------- Reducers ------------- */

/* get Student GradeBook */

export const getStudentGradebookRequest = (state, action) => {
    const { data } = action;
    return {
        ...state, fetching: true, error: null, loader: true, studentGradebookList: [], selectedClassId: data.classId, backPage: data.backPage
    }
}
export const getStudentGradebookSuccess = (state, action) => {
    const { data } = action
    
    return {
        ...state,
        fetching: false,
        error: null,
        studentGradebookList: data.data.response_data.category,
        studentClassList: data.data.student_enrolled_classes,
        eventAverage: 0,
        eventCount: data.data.response_data.event_count,
        // selectedClassId: classId,
        action: action.type, exists: true, loader: false
    }
}
export const getStudentGradebookFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_STUDENT_GRADEBOOK_FAILURE', loader: false }
}


/* update Student GradeBook */

export const updateStudentGradebookRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, loader: true,
    }
}
export const updateStudentGradebookSuccess = (state, action) => {
    return {
        ...state, fetching: false, error: null, action: action.type, exists: true, loader: false
    }
}
export const updateStudentGradebookFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'UPDATE_STUDENT_GRADEBOOK_FAILURE', loader: false }
}


/* get Student GradeBook Average */
export const UpdateStudentGradebookAverageRequest = (state, action) => {
    const { data } = action
    return {
        ...state, eventAverage: data,
    }
}

/* get Student GradeBook Checkbox */
export const UpdateStudentGradebookCheckboxRequest = (state, action) => {
    const { data } = action
    if (state.studentGradebookList[data.cIndex].category_events[data.eIndex].isToggleActive) {
        state.studentGradebookList[data.cIndex].category_events[data.eIndex].isToggleActive = !state.studentGradebookList[data.cIndex].category_events[data.eIndex].isToggleActive
    }
    else {
        state.studentGradebookList[data.cIndex].category_events[data.eIndex].isToggleActive = true
    }
    return {
        ...state, studentGradebookList: state.studentGradebookList
    }
}



/* get Student GradeBook Checkbox */
export const UpdateStudentGradebookInputRequest = (state, action) => {
    const { data } = action
    if(data?.resetValue) {
        return {
            ...state, studentGradebookList:sessionStorage.getItem('studentGradebookScore') ? JSON.parse(sessionStorage.getItem('studentGradebookScore')) : state.studentGradebookList
        }
    } else {
    if (data.type) {
        if (data.value.length < 7) {
           state.studentGradebookList[data.cIndex].category_events[data.eIndex].grading_event_student_score = data.value
        }
    }
    else {
       state.studentGradebookList[data.cIndex].category_events[data.eIndex].grading_event_student_comments = data.value
    }
    return {
        ...state, studentGradebookList:state.studentGradebookList
    }
}
}


export const UpdateStudentGradebookColorRequest = (state, action) => {
    const { data } = action
    if (state.studentGradebookList[data.cIndex].category_events[data.eIndex].grading_event_student_color_code) {
        state.studentGradebookList[data.cIndex].category_events[data.eIndex].grading_event_student_color_code = state.studentGradebookList[data.cIndex].category_events[data.eIndex].grading_event_student_color_code === data.colorCode ? '' : data.colorCode
    }
    else {
        state.studentGradebookList[data.cIndex].category_events[data.eIndex].grading_event_student_color_code = data.colorCode
    }
    return {
        ...state, studentGradebookList: state.studentGradebookList
    }
}

export const getStudentGradebookNullRequest = (state, action) => {
    return {
        ...state, studentGradebookList: {},
        studentClassList: {},
        selectedClassId: '',
    }
}
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.GET_STUDENT_GRADEBOOK_REQUEST]: getStudentGradebookRequest,
    [Types.GET_STUDENT_GRADEBOOK_SUCCESS]: getStudentGradebookSuccess,
    [Types.GET_STUDENT_GRADEBOOK_FAILURE]: getStudentGradebookFailure,

    [Types.UPDATE_STUDENT_GRADEBOOK_REQUEST]: updateStudentGradebookRequest,
    [Types.UPDATE_STUDENT_GRADEBOOK_SUCCESS]: updateStudentGradebookSuccess,
    [Types.UPDATE_STUDENT_GRADEBOOK_FAILURE]: updateStudentGradebookFailure,



    [Types.UPDATE_STUDENT_GRADEBOOK_AVERAGE_REQUEST]: UpdateStudentGradebookAverageRequest,

    [Types.UPDATE_STUDENT_GRADEBOOK_CHECKBOX_REQUEST]: UpdateStudentGradebookCheckboxRequest,

    [Types.UPDATE_STUDENT_GRADEBOOK_INPUT_REQUEST]: UpdateStudentGradebookInputRequest,

    [Types.UPDATE_STUDENT_GRADEBOOK_COLOR_REQUEST]: UpdateStudentGradebookColorRequest,

    [Types.GET_STUDENT_GRADEBOOK_NULL_REQUEST]: getStudentGradebookNullRequest,




})