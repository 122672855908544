import {
    GET_RUBRIC_LIST,
    GET_RUBRIC_LIST_SUCCESS,
    GET_RUBRIC_LIST_FAILURE,

    ADD_RUBRIC,
    ADD_RUBRIC_SUCCESS,
    ADD_RUBRIC_FAILURE,

    VIEW_RUBRIC_DETAILS,
    VIEW_RUBRIC_DETAILS_SUCCESS,
    VIEW_RUBRIC_DETAILS_FAILURE,

    DELETE_CRITERIA,
    DELETE_CRITERIA_SUCCESS,
    DELETE_CRITERIA_FAILURE,

    SAVE_ASSESSMENT,
    SAVE_ASSESSMENT_SUCCESS,
    SAVE_ASSESSMENT_FAILURE,

    VIEW_STUDENTS_ASSESSMENT_DETAILS,
    VIEW_STUDENTS_ASSESSMENT_DETAILS_SUCCESS,
    VIEW_STUDENTS_ASSESSMENT_DETAILS_FAILURE,

    DELETE_RUBRIC,
    DELETE_RUBRIC_SUCCESS,
    DELETE_RUBRIC_FAILURE,

    FORWARD_RUBRICS_REPORT,
    FORWARD_RUBRICS_REPORT_SUCCESS,
    FORWARD_RUBRICS_REPORT_FAILURE,
    GET_DYNAMIC_RUBRICS_CRITERIA,
    GET_DYNAMIC_RUBRICS_CRITERIA_ERROR,
    GET_DYNAMIC_RUBRICS_CRITERIA_SUCCESS,
    DELETE_PREVIOUS_CRITERIA_DATA,
    DELETE_PREVIOUS_CRITERIA_DATA_ERROR,
    DELETE_PREVIOUS_CRITERIA_DATA_SUCCESS,
} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */



// -----------Get Class Att List-------------
export function getRubricList(data) {
    return {
        type: GET_RUBRIC_LIST,
        data,
    };
}

export function getRubricListSuccess(data) {
    return {
        type: GET_RUBRIC_LIST_SUCCESS,
        data,
    };
}

export function getRubricListFailure(data) {
    return {
        type: GET_RUBRIC_LIST_FAILURE,
        data,
    };
}

// -----------add New Rubric-------------
export function addRubric(data) {
    return {
        type: ADD_RUBRIC,
        data,
    };
}

export function addRubricSuccess(data) {
    return {
        type: ADD_RUBRIC_SUCCESS,
        data,
    };
}

export function addRubricFailure(data) {
    return {
        type: ADD_RUBRIC_FAILURE,
        data,
    };
}

// ----------- view Rubric details-------------
export function viewRubricDetails(data) {
    return {
        type: VIEW_RUBRIC_DETAILS,
        data,
    };
}

export function viewRubricDetailsSuccess(data) {
    return {
        type: VIEW_RUBRIC_DETAILS_SUCCESS,
        data,
    };
}

export function viewRubricDetailsFailure(data) {
    return {
        type: VIEW_RUBRIC_DETAILS_FAILURE,
        data,
    };
}

// -----------delete Criteria-------------
export function deleteCriteria(data) {
    return {
        type: DELETE_CRITERIA,
        data,
    };
}

export function deleteCriteriaSuccess(data) {
    return {
        type: DELETE_CRITERIA_SUCCESS,
        data,
    };
}

export function deleteCriteriaFailure(data) {
    return {
        type: DELETE_CRITERIA_FAILURE,
        data,
    };
}

// ----------view students Assessment details-------------
export function viewStudentsAssessmentDetails(data) {
    return {
        type: VIEW_STUDENTS_ASSESSMENT_DETAILS,
        data,
    };
}

export function viewStudentsAssessmentDetailsSuccess(data) {
    return {
        type: VIEW_STUDENTS_ASSESSMENT_DETAILS_SUCCESS,
        data,
    };
}

export function viewStudentsAssessmentDetailsFailure(data) {
    return {
        type: VIEW_STUDENTS_ASSESSMENT_DETAILS_FAILURE,
        data,
    };
}

// -----------save Assessment-------------
export function saveAssessment(data) {
    return {
        type: SAVE_ASSESSMENT,
        data,
    };
}

export function saveAssessmentSuccess(data) {
    return {
        type: SAVE_ASSESSMENT_SUCCESS,
        data,
    };
}

export function saveAssessmentFailure(data) {
    return {
        type: SAVE_ASSESSMENT_FAILURE,
        data,
    };
}

// -----------delete Rubric-------------
export function deleteRubric(data) {
    return {
        type: DELETE_RUBRIC,
        data,
    };
}

export function deleteRubricSuccess(data) {
    return {
        type: DELETE_RUBRIC_SUCCESS,
        data,
    };
}

export function deleteRubricFailure(data) {
    return {
        type: DELETE_RUBRIC_FAILURE,
        data,
    };
}

// -----------Send Email------------
export function forwardRubricsReport(data) {
    return {
        type: FORWARD_RUBRICS_REPORT,
        data,
    };
}

export function forwardRubricsReportSuccess(data) {
    return {
        type: FORWARD_RUBRICS_REPORT_SUCCESS,
        data,
    };
}

export function forwardRubricsReportFailure(data) {
    return {
        type: FORWARD_RUBRICS_REPORT_FAILURE,
        data,
    };
}
export function getDynamicRubricsCriteria(data) {
    return {
        type: GET_DYNAMIC_RUBRICS_CRITERIA,
        data
    }
}
export function getDynamicRubricsCriteriaError(data) {
    return {
        type: GET_DYNAMIC_RUBRICS_CRITERIA_ERROR,
        data
    }
}

export function getDynamicRubricsCriteriaSuccess(data) {
    return {
        type: GET_DYNAMIC_RUBRICS_CRITERIA_SUCCESS,
        data
    }
}

export function deletePreviousCriteriaData(data) {
    return {
        type: DELETE_PREVIOUS_CRITERIA_DATA,
        data
    }
}

export function deletePreviousCriteriaDataError(data) {
    return {
        type: DELETE_PREVIOUS_CRITERIA_DATA_ERROR,
        data
    }
}

export function deletePreviousCriteriaDataSuccess(data) {
    return {
        type: DELETE_PREVIOUS_CRITERIA_DATA_SUCCESS,
        data
    }
}

