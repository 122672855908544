import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getNotSelectedRosters: ['data'],
    getNotSelectedRostersSuccess: ['data'],
    getNotSelectedRostersFailure: ['error'],

    addSelectedRosters: ['data'],
    addSelectedRostersSuccess: ['data'],
    addSelectedRostersFailure: ['error'],





})

export const UserClassRosterSelectReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    notSelectedRoster: null,
    selectedData: null,
    error: null,
    exists: null,
    loader: null,
    action: null
})

/* ------------- Reducers ------------- */

/* getNotSelectedRosters */
export const getNotSelectedRosters = (state) => {
    return {
        ...state, fetching: true, error: null, notSelectedRoster: null, loader: true
    }
}
export const getNotSelectedRostersSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, notSelectedRoster: data.data, action: action.type, exists: true, loader: false }
}
export const getNotSelectedRostersFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'NOT_SELECT_ROSTER_LIST_FAILURE', loader: false }
}


/* getNotSelectedRosters */
export const addSelectedRosters = (state) => {
    return {
        ...state, fetching: true, error: null, selectedData: null, notSelectedRoster:null, loader: true
    }
}
export const addSelectedRostersSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, selectedData: data, action: action.type, exists: true, loader: false }
}
export const addSelectedRostersFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'ADD_SELECTED_ROSTERS_FAILURE', loader: false }
}








/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.GET_NOT_SELECTED_ROSTERS]: getNotSelectedRosters,
    [Types.GET_NOT_SELECTED_ROSTERS_SUCCESS]: getNotSelectedRostersSuccess,
    [Types.GET_NOT_SELECTED_ROSTERS_FAILURE]: getNotSelectedRostersFailure,

    [Types.ADD_SELECTED_ROSTERS]: addSelectedRosters,
    [Types.ADD_SELECTED_ROSTERS_SUCCESS]: addSelectedRostersSuccess,
    [Types.ADD_SELECTED_ROSTERS_FAILURE]: addSelectedRostersFailure,


})
