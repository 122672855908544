import React, { useState, useEffect } from 'react';
import * as moment from 'moment';
import ReactRating from "react-rating";
import emptyCloud from "Assets/LessonPlanModule/emptyCloud.svg";
import blueCloud from "Assets/LessonPlanModule/blueCloud.svg";
import deSelectedTickIcon from 'Assets/LessonPlanModule/deSelectedTickIcon.svg';
import playIcon from 'Assets/NoteModule/play.svg';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif';
import './EditRatingImport.scss';

const EditRatingImport = (props) => {
    const {
        viewLessonPlanDetailsState,
        viewLessonPlanDetails,
        lessonPlanId,
        getData,
        selectedStudentsLessonsForImport,
        importCount,
        totalRating,
        changeRating,
        isImported,
        classDetail,
        ratingByTotalUsers,
        questionAnswers,
        setQuestionAnswers,
        loader
    } = props;
    
    const [lpCreatedAt, setLpCreatedAt] = useState("");
    const [lpPlannedAt, setLpPlannedAt] = useState("");
    const [lpTitle, setLpTitle] = useState("");
    const [lpTopic, setLpTopic] = useState("");
    const [pictureVideoList, setPictureVideoList] = useState([]);
    const [audioList, setAudioList] = useState([]);
    const [urlList, setUrlList] = useState([""]);
    const headTitlesRate = ['headTitlesRate', totalRating >= 3.5 ? "greenRate" : totalRating < 3.5 && totalRating >= 2 ? 'yellowRate' : totalRating < 2 ? 'redRate' : ""]
    
    useEffect(() => {
        const apiData = {
            "class_id": classDetail?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
            "lesson_plan_id": lessonPlanId,
        }
        viewLessonPlanDetails(apiData)
    }, [])

    useEffect(() => {
        if (viewLessonPlanDetailsState) {
            const {
                cc_lp_notification_minutes,
                cc_lp_other_urls,
                cc_lp_planned_at,
                cc_lp_created_at,
                cc_lp_title,
                cc_lp_topic,
                attachment_details_array
            } = viewLessonPlanDetailsState.data;

            const urlsEditList = cc_lp_other_urls.split(',');
            const audiosArr = [];
            const multimediaArr = [];
            attachment_details_array.map(val => {
                const valObj = {};
                if (val.cc_attachment_type === "image" || val.cc_attachment_type === "video") {
                    valObj["cc_attachment_fullurl"] = val.cc_attachment_fullurl;
                    valObj["cc_attachment_url"] = val.cc_attachment_url;
                    valObj["cc_attachment_size"] = val.cc_attachment_size;
                    valObj["cc_attachment_type"] = val.cc_attachment_type;
                    valObj["cc_attachment_name"] = val.cc_attachment_name;
                    valObj["cc_media_mark_artified"] = val.cc_media_mark_artified;
                    multimediaArr.push(valObj);
                }
                if (val.cc_attachment_type === "audio") {
                    valObj["cc_attachment_fullurl"] = val.cc_attachment_fullurl;
                    valObj["cc_attachment_url"] = val.cc_attachment_url;
                    valObj["cc_attachment_size"] = val.cc_attachment_size;
                    valObj["cc_attachment_type"] = val.cc_attachment_type;
                    valObj["cc_attachment_name"] = val.cc_attachment_name;
                    valObj["cc_media_mark_artified"] = val.cc_media_mark_artified;
                    audiosArr.push(valObj);
                }
            })
            setLpCreatedAt(cc_lp_created_at);
            setLpPlannedAt(cc_lp_planned_at);
            setLpTitle(cc_lp_title);
            setLpTopic(cc_lp_topic);
            setUrlList(urlsEditList);
            setPictureVideoList(multimediaArr);
            setAudioList(audiosArr);
        }
    }, [viewLessonPlanDetailsState])

    useEffect(() => {
        viewLessonPlanDetailsState && viewLessonPlanDetailsState.data.question_answer_array.map(val => {
            const valObj = {};
            if (val.cc_lp_questionnaire_view_tab === "planning" && lessonPlanId == viewLessonPlanDetailsState.data.cc_lp_id) {
                valObj["id"] = val.cc_lp_questionnaire_id;
                valObj["question_id"] = JSON.stringify(val.cc_question_id);
                valObj["question_view_tab"] = val.cc_lp_questionnaire_view_tab;
                valObj["type"] = val.cc_lp_questionnaire_type;
                valObj["answer"] = val.cc_lp_questionnaire_response;
                valObj["question_text"] = val.cc_lp_questionnaire_question_text;
                questionAnswers.push(valObj)
            }
        })
        setQuestionAnswers(questionAnswers);
    }, [viewLessonPlanDetailsState])

    return (
        <div className="editRatingContainer">
            {<div className="ratingsAddDiv">
                <div className='ratingTitleEditDIv'>
                    <div className='importTitleDiv'>
                        {isImported === 1 ? <img src={deSelectedTickIcon} alt="" width="28" height="18" /> : ""}
                        {isImported === 0 ? <span className='checBoxDiv' onClick={() => { getData(lessonPlanId) }}>
                            {selectedStudentsLessonsForImport.includes(lessonPlanId) === true ?
                                <i className="checkBoxfilter material-icons">{"radio_button_checked"}</i>
                                :
                                <i className="checkBoxfilter material-icons">{"radio_button_unchecked"}</i>
                            }
                        </span> : ""}
                        <p className='importText'>Import Plan</p>
                    </div>
                    {viewLessonPlanDetailsState && <div className='editRatings'>
                        <p className='importText'>Rating: </p>
                        <div className='ratingComp'>
                            <ReactRating
                                onChange={(e) => changeRating(e, lessonPlanId)}
                                fractions={10}
                                placeholderRating={totalRating}
                                emptySymbol={<img src={emptyCloud} className="icon" />}
                                placeholderSymbol={<img src={blueCloud} className="icon" />}
                                fullSymbol={<img src={blueCloud} className="icon" />}
                            />
                        </div>
                    </div>}
                </div>
            </div>}
            {loader && <div>
                <img src={loaderImag} alt='' className='loaderIconCss' />
            </div>}
            {viewLessonPlanDetailsState && <div className="planTitleDiv">
                <div className='algnDivs'>
                    <div className="planTitle">
                        <p className="headTitlesPlan">Plan Title: </p>
                        <p className="headTitles">{lpTitle}</p>
                    </div>
                    <div className="planTitle">
                        <p className="headTitlesPlan">Planned on: </p>
                        <p className="headTitles">{lpCreatedAt}</p>
                    </div>
                </div>
                <div className='algnDivs'>
                    <div className="planTitle">
                        <p className="headTitlesPlan">Topic: </p>
                        <p className="headTitles">{lpTopic}</p>
                    </div>
                    <div className="planTitle">
                        <p className="headTitlesPlan">Planned for: </p>
                        <p className="headTitles">{lpPlannedAt}</p>
                    </div>
                </div>
                <div className='algnDivs'>
                    <div className="planTitle">
                        <p className="headTitlesPlan">Total import: </p>
                        <p className="headTitles">{importCount} times</p>
                    </div>
                    <div className="planTitle">
                        <p className="headTitlesPlan">Total ratings: </p>
                        <span className="headTitlesSpan"><p className={headTitlesRate.join(' ')}>{totalRating}</p> <p className='headTitles'>({ratingByTotalUsers} users)</p></span>
                    </div>
                </div>
            </div>}


            <div className="allSubDivs">
                {questionAnswers && questionAnswers.map((val, k) => {
                    return (
                        <div className="rationalDiv" key={k}>
                            <div className="rationalTitleBox">
                                <p className="rationalTitle">{val.question_text}</p>
                            </div>
                            <div className="rationalInputBox">
                                <div className="rationalInputText"><p className='answerText'>{val.answer === "" ? "NA" : val.answer}</p></div>
                            </div>
                        </div>
                    )
                })}
                {viewLessonPlanDetailsState && <div className="rationalDiv">
                    <div className="rationalTitleBox">
                        <p className="rationalTitle">Multimedia (Photos / videos)</p>
                    </div>
                    <div className="rationalInputBox">
                        <div className="audio-sec">
                            <div className="picture-video-sec">
                                {pictureVideoList.length > 0 ? (
                                    pictureVideoList.map((item, i) => {
                                        return (
                                            <div key={i}>
                                                {item.cc_attachment_type !== 'image' ? (
                                                    <div className="note">
                                                        <div className="img-div">
                                                            <img src={playIcon} className="playIcon" alt='' width="18" height="18" />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="note" style={{ backgroundImage: `url(${item.cc_attachment_fullurl})` }}>
                                                        <div className='img-div-only'>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })) : (<p className='answerText'>No Multimedia Notes</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>}
                {viewLessonPlanDetailsState && <div className="rationalDiv">
                    <div className="rationalTitleBox">
                        <p className="rationalTitle">Audio Note</p>
                    </div>
                    <div className="rationalInputBox">
                        <div className="audio-sec">
                            <div className="audit-sec">
                                {audioList.length > 1 ? audioList.map((item, index) => {
                                    return (
                                        <div key={index} className="audio-div">
                                            <div className="audio-sub-div">
                                                <p className="audio-filename">{item.cc_attachment_name === '' ? "Audio Note" : item.cc_attachment_name}</p>
                                                <audio controls className="player" preload="false" controlsList="nodownload" src={item.cc_attachment_fullurl} ></audio>
                                            </div>
                                        </div>
                                    )
                                }) : <p className='answerText'>No Audio Notes</p>}
                            </div>
                        </div>
                    </div>
                </div>}
                {viewLessonPlanDetailsState && <div className="rationalDivUrls">
                    <div className="rationalTitleBox">
                        <p className="rationalTitle">Add URL(s)</p>
                    </div>
                    <div className="rationalInputBox">
                        {urlList && urlList.map((valu, l) => {
                            return (<div className="rationalInputTextDiv" key={l}>
                                <input name="message" type="text" className="rationalInputText" value={valu} placeholder="Enter link" />
                            </div>
                            )
                        })}
                    </div>
                </div>}
            </div>
        </div>
    );
}
export default EditRatingImport
