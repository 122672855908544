



import {

    GET_CLASS_NOTE_REQUEST,
    GET_CLASS_NOTE_SUCCESS,
    GET_CLASS_NOTE_FAILURE,

    GET_CLASS_NOTE_NULL_REQUEST,
    GET_CLASS_GROUP_NOTES_DATA,
    GET_CLASS_GROUP_NOTES_DATA_ERROR,
    GET_CLASS_GROUP_NOTES_DATA_SUCCESS,
    CLASS_GROUP_NOTE_DELETE,
    CLASS_GROUP_NOTE_DELETE_ERROR,
    CLASS_GROUP_NOTE_DELETE_SUCCESS,
    CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT,
    CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT_ERROR,
    CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT_SUCCESS,
    CLASS_GROUP_NOTE_ARTIFACT,
    CLASS_GROUP_NOTE_ARTIFACT_ERROR,
    CLASS_GROUP_NOTE_ARTIFACT_SUCCESS,
    RESET_CLASS_GROUP_NOTE

} from '../constants';

// -----------Get Class Note-------------
export function getClassNoteRequest(data) {
    return {
        type: GET_CLASS_NOTE_REQUEST,
        data,
    };
}

export function getClassNoteSuccess(data, page) {
    return {
        type: GET_CLASS_NOTE_SUCCESS,
        data,
        page,
    };
}

export function getClassNoteFailure(data) {
    return {
        type: GET_CLASS_NOTE_FAILURE,
        data,
    };
}


// -----------Get Class Note Blank-------------
export function getClassNoteNullRequest(data) {
    return {
        type: GET_CLASS_NOTE_NULL_REQUEST,
        data,
    };
}

//------------Get Class Group Note-------------
export function getClassGroupNotesData(data) {
    return {
        type: GET_CLASS_GROUP_NOTES_DATA,
        data
    }
}

export function getClassGroupNotesDataError(data) {
    return {
        type: GET_CLASS_GROUP_NOTES_DATA_ERROR,
        data
    }
}

export function getClassGroupNotesDataSuccess(data, page) {
    return {
        type: GET_CLASS_GROUP_NOTES_DATA_SUCCESS,
        data,
        page
    }
}

export function classGroupNoteDelete(data) {
    return {
        type : CLASS_GROUP_NOTE_DELETE,
        data
    }
}

export function classGroupNoteDeleteError(data) {
    return {
        type : CLASS_GROUP_NOTE_DELETE_ERROR,
        data,
    }
}

export function classGroupNoteDeleteSuccess(data) {
    return {
        type : CLASS_GROUP_NOTE_DELETE_SUCCESS,
        data
    }
}

export function classGroupNoteAttachementArtifact(data) {
    return {
        type : CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT,
        data
    }
}

export function classGroupNoteAttachementArtifactError(data) {
    return {
        type : CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT_ERROR,
        data
    }
}

export function classGroupNoteAttachementArtifactSuccess(data) {
    return {
        type : CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT_SUCCESS,
        data
    }
}


export function classGroupNoteArtifact(data) {
    return {
        type : CLASS_GROUP_NOTE_ARTIFACT,
        data
    }
}

export function classGroupNoteArtifactError(data) {
    return {
        type : CLASS_GROUP_NOTE_ARTIFACT_ERROR,
        data
    }
}

export function classGroupNoteArtifactSuccess(data) {
    return {
        type : CLASS_GROUP_NOTE_ARTIFACT_SUCCESS,
        data
    }
}


export const resetClassGroupNote = (data) => {
    return {
        type : RESET_CLASS_GROUP_NOTE,
        data
    }
}




