import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import DatePicker from 'react-datepicker'
import Timer from 'react-compound-timer'
import { setMinutes, setHours } from "date-fns";
import historyIcon from 'Assets/NoteModule/history.svg';
import deleteUrl from 'Assets/LessonPlanModule/deleteUrl.png';
import * as moment from 'moment';
import DivLoader from "Components/LoadingComponent/DivLoader";
import standardIcon from 'Assets/ClassModule/standard.png'
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import MicRecorder from 'mic-recorder-to-mp3';
import StudentNoteProgressbarComponent from '../../NoteModule/StudentNoteModule/StudentNoteProgressbarComponent/StudentNoteProgressbar';
import { createNotification } from 'Config/notificationtoast';
import playIcon from 'Assets/NoteModule/play.svg';
import closeIcon from 'Assets/NoteModule/close.svg';
import tickIcon from 'Assets/NoteModule/tick.svg';
import deleteIcon from 'Assets/NoteModule/delete.svg';
import closeMediaIcon from 'Assets/NoteModule/closeMedia.svg';
import addIcon from 'Assets/NoteModule/add.svg';
import startRecordingIcon from 'Assets/NoteModule/startRecording.svg';
import note_pdf from 'Assets/NoteModule/note_pdf.png';
import note_doc from 'Assets/NoteModule/note_doc.png';
import note_csv from 'Assets/NoteModule/note_csv.png';
import note_ppt from 'Assets/NoteModule/note_ppt.png';
import note_xls from 'Assets/NoteModule/note_xls.png';
import stopIcon from 'Assets/NoteModule/stop.svg';
import { FirebseStroageFolder } from 'Config/Constant';
import { storage } from "../../../firebase/firebase";
import { getRandomName } from "Utils/Helper";
import calendarIcon from 'Assets/NoteModule/calendar.svg';
import * as routes from "Router/RoutesURL";
import { getFormattedDate } from 'Utils/Helper';
import IframeGoogleDoc from '../../NoteModule/StudentNoteModule/IframeGoogleDoc'
import './Planning.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getLpText } from 'Actions/ClassAction/ClassLessonPlanAction';

const Planning = (props) => {
    const {
        questionsList,
        saveData,
        setSaveData,
        ClassDetails,
        apiCallPlanning,
        viewLessonPlanDetailsState,
        selectionType,
        lessonPlanId,
        setApiDatas,
        selectedTab,
        history,
        academicYearId,
        academicYear,
        classId,
        classPermission,
        lesson_plan_id,
        statequestion_answer_array
    } = props;
    console.log('viewLessonPlanDetailsState :>> ', viewLessonPlanDetailsState);
    const dispatch = useDispatch()
    const getCurrentTime = () => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };
    const [title, setTitle] = useState(sessionStorage.getItem('LPTitle')?.trim().length > 0 ? sessionStorage.getItem('LPTitle') : '');
    const [topic, setTopic] = useState(sessionStorage.getItem('LPTopic')?.trim().length > 0 ? sessionStorage.getItem('LPTopic') : '');
    const [selectedDate, setSelectedDate] = useState(sessionStorage.getItem('LPFormatDate')?.length > 0 ? new Date(sessionStorage.getItem('LPFormatDate')) : new Date());
    const [mainDate, setMainDate] = useState("");
    const [minsDuration, setMinsDuration] = useState(["0"]);
    const [selectedTime, setSelectedTime] = useState(sessionStorage.getItem('LPReminderTime')?.length > 0 ? sessionStorage.getItem('LPReminderTime') : getCurrentTime());
    const [uploadMediaStatus, setUploadMediaStatus] = useState(false);
    const [mediaUploadPercentage, setMediaUploadPercentage] = useState(0);
    const [pictureVideoList, setPictureVideoList] = useState(sessionStorage.getItem("LPPictureVideoList")?.length > 0 ? JSON.parse(sessionStorage.getItem('LPPictureVideoList')) : []);
    const [docList, setDocList] = useState(sessionStorage.getItem("LPDocList")?.length > 0 ? JSON.parse(sessionStorage.getItem("LPDocList")) : []);
    const [audioList, setAudioList] = useState(sessionStorage.getItem("LPAudioList")?.length > 0 ? JSON.parse(sessionStorage.getItem("LPAudioList")) : []);
    const [isSaved, setIsSaved] = useState(false);
    const [showVideoModalState, setShowVideoModalState] = useState(false)
    const [selectedVideo, setSelectedVideo] = useState({})
    const [showImageModalState, setShowImageModalState] = useState(false)
    const [selectedImage, setSelectedImage] = useState({})
    const [urlList, setUrlList] = useState(sessionStorage.getItem('LPUrllist')?.length > 0 ? JSON.parse(sessionStorage.getItem("LPUrllist")) : [""]);
    const [selectedDoc, setSelectedDoc] = useState("");
    const [showDocPopUp, setShowDocPopUp] = useState(false);
    const [record, setRecord] = useState(false);
    const [show, setShow] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [auditTitle, setAuditTitle] = useState("");
    const [questionAnswers, setQuestionAnswers] = useState(sessionStorage.getItem('LPPlaiingQuestion')?.length > 0 ? JSON.parse(sessionStorage.getItem('LPPlaiingQuestion')) :  []);
    const [uploadAudioStatus, setUploadAudioStatus] = useState(false);
    const signDate = sessionStorage.getItem('separatorFormat');
    const formatDate = sessionStorage.getItem('dateFormat');
    const [checkedVal, setcheckedVal] = useState(sessionStorage.getItem('LPNotification') == 'true' ? true : false);
    // const formatClock = sessionStorage.getItem('clockFormat')
    const [recorder] = useState(new MicRecorder({ bitRate: 128 }));
    const [showEditModal, setShowEditModal] = useState(false);
    const [countdown, setCountdown] = useState(4);
    const apiData = {};

const handleEditModalClose = () => setShowEditModal(false);
const handleEditModalShow = () => setShowEditModal(true);

    useEffect(() => {
        const dateParam = getFormattedDate(selectedDate, formatDate, signDate);
        setMainDate(dateParam)
    }, [selectedDate])

    useEffect(() => {
        if (selectedTab === "Planning") {
            setSaveData("planning")
        }
    })
    useEffect(() => {
        if (selectedDoc) {
            setShowDocPopUp(true);
        }
    }, [selectedDoc])

    useEffect(() => {
        const timer = setInterval(() => {
          if (countdown > 0) {
            setCountdown(countdown - 1);
          }
        }, 1000);
    
        return () => {
          clearInterval(timer);
        };

       

      }, [countdown]);
   console.log('checkedVal , minsDuration  :>> ', checkedVal , minsDuration);
      const getLpTextSuucessState = useSelector((state) => state?.classLessonPlan?.get_lesson_plan_text_success)
      const getLPTextLoader = useSelector((state) => state?.classLessonPlan?.get_lesson_plan_text_loader)

      useEffect(() => {
        if(getLpTextSuucessState?.status == 200) {
            setShowEditModal(false)
            if(getLpTextSuucessState?.data?.length == 0) {
                createNotification('error', 'No Result Found')
            } else {
            const convertedArray = questionAnswers?.map((item, index) => {
                return {
                    ...item,
                    answer : getLpTextSuucessState?.data?.[index]?.passage
                }
            })
              if(convertedArray?.length > 0) {
              setQuestionAnswers(convertedArray)
              sessionStorage.setItem("LPPlaiingQuestion", JSON.stringify(convertedArray))
              }
            }
        }
      },[getLpTextSuucessState])

      useEffect(() => {
        if(!getLPTextLoader) {
            setShowEditModal(false)
        }
      },[getLPTextLoader])

    useEffect(() => {
        if ((selectionType === "edit" ||  selectionType === "addNew") && viewLessonPlanDetailsState) {
            const {
                cc_lp_notification_fixed_time,
                cc_lp_notification_minutes,
                cc_lp_other_urls,
                cc_lp_planned_at,
                cc_lp_title,
                cc_lp_topic,
                attachment_details_array
            } = viewLessonPlanDetailsState?.data;

            const urlsEditList = cc_lp_other_urls?.split(',');
            const remMinutes = cc_lp_notification_minutes?.split(',');
            const dateMomentObject = moment(cc_lp_planned_at || new Date(), "YYYY/MM/DD");
            const dateObject = dateMomentObject.toDate();
            const audiosArr = [];
            const multimediaArr = [];
            const otherArr = [];
            attachment_details_array && attachment_details_array.map(val => {
                const valObj = {};
                if (val.cc_attachment_type === "image" || val.cc_attachment_type === "video") {
                    valObj["cc_attachment_fullurl"] = val.cc_attachment_fullurl;
                    valObj["cc_attachment_url"] = val.cc_attachment_url;
                    valObj["cc_attachment_size"] = val.cc_attachment_size;
                    valObj["cc_attachment_type"] = val.cc_attachment_type;
                    valObj["cc_attachment_name"] = val.cc_attachment_name;
                    valObj["cc_media_mark_artified"] = val.cc_media_mark_artified;
                    valObj["cc_attachment_operation"] = val.cc_attachment_operation
                    multimediaArr.push(valObj);
                }
                if (val.cc_attachment_type === "other") {
                    valObj["cc_attachment_fullurl"] = val.cc_attachment_fullurl;
                    valObj["cc_attachment_url"] = val.cc_attachment_url;
                    valObj["cc_attachment_size"] = val.cc_attachment_size;
                    valObj["cc_attachment_type"] = val.cc_attachment_type;
                    valObj["cc_attachment_name"] = val.cc_attachment_name;
                    valObj["cc_media_mark_artified"] = val.cc_media_mark_artified;
                    valObj["cc_attachment_operation"] = val.cc_attachment_operation
                    otherArr.push(valObj);
                }
                if (val.cc_attachment_type === "audio") {
                    valObj["cc_attachment_fullurl"] = val.cc_attachment_fullurl;
                    valObj["cc_attachment_url"] = val.cc_attachment_url;
                    valObj["cc_attachment_size"] = val.cc_attachment_size;
                    valObj["cc_attachment_type"] = val.cc_attachment_type;
                    valObj["cc_attachment_name"] = val.cc_attachment_name;
                    valObj["cc_media_mark_artified"] = val.cc_media_mark_artified;
                    valObj["cc_attachment_operation"] = val.cc_attachment_operation
                    audiosArr.push(valObj);
                }
            })
            setTitle(cc_lp_title);
            setTopic(cc_lp_topic);
            setSelectedDate(dateObject);
            setUrlList(urlsEditList ? urlsEditList : ['']);
            setSelectedTime(cc_lp_notification_fixed_time?.split(':').slice(0, 2).join(':'));
            setMinsDuration(remMinutes || ['0']);
            if(selectionType == 'edit' && !remMinutes?.includes('0'))
            {
        //    if(remMinutes?.length > 0) {
            setcheckedVal(true)
        //    }
            }
            setPictureVideoList(multimediaArr);
            setDocList(otherArr);
            setAudioList(audiosArr);
        }
    }, [selectionType, viewLessonPlanDetailsState])

    useEffect(() => {
        if (selectionType === "edit") {

            
            viewLessonPlanDetailsState && viewLessonPlanDetailsState?.data?.question_answer_array?.map(val => {
                const valObj = {};
                if (val.cc_lp_questionnaire_view_tab === "planning") {
                    valObj["id"] = JSON.stringify(val.cc_lp_questionnaire_id);
                    valObj["question_id"] = JSON.stringify(val.cc_question_id);
                    valObj["question_view_tab"] = val.cc_lp_questionnaire_view_tab;
                    valObj["type"] = val.cc_lp_questionnaire_type;
                    valObj["answer"] = val.cc_lp_questionnaire_response != null ? val.cc_lp_questionnaire_response : "";
                    valObj["question_text"] = val.cc_lp_questionnaire_question_text;
                    questionAnswers.push(valObj)
                }
            })
            setQuestionAnswers(questionAnswers);
        }
        if (selectionType === "addNew") {
          
            questionsList && questionsList.data?.map(val => {
                const valObj = {};
                if (val.cc_question_view_tab === "planning") {
                    valObj["question_id"] = JSON.stringify(val.cc_question_id);
                    valObj["question_view_tab"] = val.cc_question_view_tab;
                    valObj["type"] = "static";
                    valObj["answer"] = "";
                    valObj["question_text"] = val.cc_question_text;
                    questionAnswers.push(valObj)
                }
            })
            setQuestionAnswers(sessionStorage.getItem('LPPlaiingQuestion')?.length > 0  ? JSON.parse(sessionStorage.getItem('LPPlaiingQuestion')) : questionAnswers);
        }
    }, [questionsList, selectionType])

    useEffect(() => {
            let dateHere = sessionStorage.getItem('LPFormatDate')?.length > 0 ? new Date(sessionStorage.getItem('LPFormatDate')) : selectedDate
        if (saveData === "planning") {
            const FormatDate =  new Date(dateHere.getTime() - (dateHere.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
            const urlsStr = urlList && urlList.join(",");
            const minDuration = minsDuration?.length > 0 && minsDuration?.join(",");

            let attachment_array = [];
            for (let item of pictureVideoList) {
                let attachment = {
                    "cc_attachment_url": item.cc_attachment_url,
                    "cc_attachment_size": item.cc_attachment_size,
                    "cc_attachment_type": item.cc_attachment_type,
                    "cc_attachment_operation": item.cc_attachment_operation,
                    "cc_attachment_name": ""
                }
                attachment_array.push(attachment)
            }
            for (let item of docList) {
                let attachment = {
                    "cc_attachment_url": item.cc_attachment_url,
                    "cc_attachment_size": item.cc_attachment_size,
                    "cc_attachment_type": item.cc_attachment_type,
                    "cc_attachment_operation": item.cc_attachment_operation,
                    "cc_attachment_name": ""
                }
                attachment_array.push(attachment)
            }
            for (let item of audioList) {
                let attachment = {
                    "cc_attachment_url": item.cc_attachment_url,
                    "cc_attachment_size": item.cc_attachment_size,
                    "cc_attachment_type": item.cc_attachment_type,
                    "cc_attachment_operation": item.cc_attachment_operation,
                    "cc_attachment_name": item.cc_attachment_name
                }
                attachment_array.push(attachment)
            }
            apiData['topic'] = sessionStorage.getItem('LPTopic')?.length > 0 ? sessionStorage.getItem('LPTopic')  : topic;
            apiData['title'] = sessionStorage.getItem('LPTitle')?.length > 0 ? sessionStorage.getItem('LPTitle')  : title;
            apiData['planned_at'] = FormatDate;
            apiData['notification_minutes'] = minDuration === false ? ["0"] : minDuration;
            apiData['notification_fixed_time'] = selectedTime || getCurrentTime();
            apiData['other_urls'] = urlsStr;
            apiData['question_answer_array'] = sessionStorage.getItem('LPPlaiingQuestion')?.length > 0 ? JSON.parse(sessionStorage.getItem('LPPlaiingQuestion')) : questionAnswers;
            apiData['attachment_details_array'] = attachment_array;
            apiData['lesson_plan_id'] = lessonPlanId || lesson_plan_id;
            apiData["class_id"] = ClassDetails?.cc_class_id > 0 ?  JSON.stringify(ClassDetails?.cc_class_id) : sessionStorage.getItem("Class_ID_for_LP");
            apiData['history'] = history
            apiData["selectionType"] = selectionType
            apiData['checked'] = checkedVal
            console.log('apiData123 :>> ', apiData , checkedVal);
            // if (checkedVal == false) {
            //     console.log('object123123 :>> ');
            // }
            setApiDatas(apiData);
            // setSelectedDate(dateHere)
        }
    }, [selectedDate,
        minsDuration,
        audioList,
        docList,
        pictureVideoList,
        topic,
        title,
        urlList,
        selectedTime,
        questionAnswers, checkedVal])
  console.log('selectedTime :>> ', selectedTime);
    useEffect(() => {
        if (apiCallPlanning === false) {
            setTitle("");
            setTopic("");
            setSelectedDate( sessionStorage.getItem('LPFormatDate')?.length > 0 ? new Date(sessionStorage.getItem('LPFormatDate')) : new Date());
            setMainDate("");
            setUrlList([""]);
            setSelectedTime(getCurrentTime());
            setMinsDuration(["1440"]);
            setPictureVideoList([]);
            setDocList([]);
            setAudioList([]);
            let answersdata = questionAnswers && questionAnswers.map(value => {
                let answerValue = { ...value }
                answerValue = { ...answerValue, answer: "" }
                return answerValue
            })
            setQuestionAnswers(answersdata);
        }
    }, [apiCallPlanning])
//   useEffect(() => {
//    if (checkedVal == false) {
//     setMinsDuration(["0"])
//    }
//   }, [checkedVal])
  
    const SetTime = (date) => {
        sessionStorage.setItem('LPReminderTime',moment(date).format("HH:mm"))
        setSelectedTime(moment(date).format("HH:mm"));
    }
    // ----------------------- Video upload ----------------------

    // ---------Get Image and Video-------
    const getMediaHandle = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        e.target.value = '';
        if (file !== undefined) {
            if (file.type.indexOf("image") !== -1 || file.type.indexOf("video") !== -1) {
                reader.onloadend = () => {
                    uploadMediaFile(file)
                }
                reader.readAsDataURL(file);
            } else if ((file.type.indexOf("msword") !== -1 || file.type.indexOf("vnd.ms-excel") !== -1 || file.type.indexOf("vnd.ms-powerpoint") !== -1 || file.type.indexOf("mspowerpoint") !== -1 || file.type.indexOf("powerpoint") !== -1 || file.type.indexOf("x-mspowerpoint") !== -1 || file.type.indexOf("pdf") !== -1 || file.type.indexOf("csv") !== -1 || file.type.indexOf("doc") !== -1 || file.type.indexOf("docx") !== -1)) {
                reader.onloadend = () => {
                    uploadDocFile(file)
                }
                reader.readAsDataURL(file);
            } else {
                alert("Please select Image, Video, Pdf, Doc, Docx, Xls, Ppt, pptx, Csv file only.")
            }
        }
    }
    // ---------Get Image and Video-------

    // ---------doc Upload on Firebase-------
    const uploadDocFile = (e) => {
        const data = JSON.parse(sessionStorage.getItem('UserData'))
        const docAsFile = e;

        setUploadMediaStatus(true);

        const media_Size = (docAsFile.size / 1000000);
        // ---------Media Type-------
        const mediaTypeArray = docAsFile.type.split("/")
        const mediaType = mediaTypeArray[1]
        // ---------Media Type-------

        // ---------Image Type-------
        const ImageTypeArray = docAsFile.name.split(".")
        const ImageType = ImageTypeArray[ImageTypeArray.length - 1]

        // ---------Image Type-------

        // ---------DummyName-------
        const GetRendomName = getRandomName()
        const DummyName = GetRendomName + '.' + ImageType;
        // ---------DummyName-------

        // ---------FolderName-------
        const FinfFolderNameArray = data.cc_user_email_id.split("@")
        const FolderName = FinfFolderNameArray[0]
        // ---------FolderName-------
    
        const uploadTask = storage.ref(`${FirebseStroageFolder}/${FolderName}/lessonPlan/lessonPlan-${classId || ClassDetails.cc_class_id}/${DummyName}`).put(docAsFile)
        
        uploadTask.on('state_changed',
            (snapShot) => {
                const progress = Math.round(
                    (snapShot.bytesTransferred / snapShot.totalBytes) * 100
                );
                setMediaUploadPercentage(progress);

            }, (err) => {
                setUploadMediaStatus(false);

                createNotification('success', 'Multimedia not uploaded. Please give it another shot!');
            }, () => {
                storage.ref(`${FirebseStroageFolder}/${FolderName}/lessonPlan/lessonPlan-${classId || ClassDetails.cc_class_id}`).child(DummyName).getDownloadURL()
                    .then(fireBaseUrl => {
                        let value = {
                            cc_attachment_fullurl: fireBaseUrl,
                            cc_attachment_url: `${FirebseStroageFolder}/${FolderName}/lessonPlan/lessonPlan-${classId || ClassDetails.cc_class_id}/${DummyName}`,
                            cc_attachment_type: "other",
                            cc_attachment_name: mediaType,
                            cc_attachment_size: media_Size,
                            cc_media_mark_artified: "no",
                            cc_attachment_operation: "add"
                        }
                        let DummyList = [...docList];
                        DummyList.push(value)
                        setUploadMediaStatus(false);
                        setDocList(DummyList);
                        sessionStorage.setItem('LPDocList',JSON.stringify(DummyList))
                        setIsSaved(false);
                        createNotification('success', 'Multimedia Upload successful!');
                    })
            })
    }

    // ---------Image Upload on Firebase-------
    const uploadMediaFile = (e) => {
        const data = JSON.parse(sessionStorage.getItem('UserData'))
        const imageAsFile = e;
        setUploadMediaStatus(true);
        const media_Size = (imageAsFile.size / 1000000);
        // ---------Media Type-------
        const mediaTypeArray = imageAsFile.type.split("/")
        const mediaType = mediaTypeArray[0]
        // ---------Media Type-------

        // ---------Image Type-------
        const ImageTypeArray = imageAsFile.name.split(".")
        const ImageType = ImageTypeArray[ImageTypeArray.length - 1]
        // ---------Image Type-------

        // ---------DummyName-------
        const GetRendomName = getRandomName()
        const DummyName = GetRendomName + '.' + ImageType;
        // ---------DummyName-------

        // ---------FolderName-------
        const FinfFolderNameArray = data.cc_user_email_id.split("@")
        const FolderName = FinfFolderNameArray[0]
        // ---------FolderName-------


        const uploadTask = storage.ref(`${FirebseStroageFolder}/${FolderName}/lessonPlan/lessonPlan-${classId || ClassDetails?.cc_class_id}/${DummyName}`).put(imageAsFile)
        uploadTask.on('state_changed',
            (snapShot) => {
                const progress = Math.round(
                    (snapShot.bytesTransferred / snapShot.totalBytes) * 100
                );
                setMediaUploadPercentage(progress);
            }, (err) => {
                setUploadMediaStatus(false);
                createNotification('success', 'Multimedia not uploaded. Please give it another shot!');
            }, () => {
                storage.ref(`${FirebseStroageFolder}/${FolderName}/lessonPlan/lessonPlan-${classId || ClassDetails.cc_class_id}/`).child(DummyName).getDownloadURL()
                    .then(fireBaseUrl => {
                        let value = {
                            cc_attachment_fullurl: fireBaseUrl,
                            cc_attachment_url: `${FirebseStroageFolder}/${FolderName}/lessonPlan/lessonPlan-${classId || ClassDetails.cc_class_id}/${DummyName}`,
                            cc_attachment_type: mediaType,
                            cc_attachment_name: mediaType,
                            cc_attachment_size: media_Size,
                            cc_media_mark_artified: "no",
                            cc_attachment_operation: "add"
                        }
                        let DummyList = [...pictureVideoList];
                        DummyList.push(value)
                        setUploadMediaStatus(false);
                        setPictureVideoList(DummyList);
                        sessionStorage.setItem('LPPictureVideoList',JSON.stringify(DummyList))
                        setIsSaved(false);
                        createNotification('success', 'Multimedia Upload successful!');
                    })
            })
    }
    // ---------Image Upload on Firebase-------

    // ----------------------- Video upload ----------------------
    // ---------Video Show on Modal -------
    const handleVideoModalShow = (item) => {
        let tmp = item
        setShowVideoModalState(true);
        setSelectedVideo(tmp);
        // this.setState({ showVideoModalState: true, selectedVideo: tmp })
    }
    // ---------Video Show on Modal -------

    // ---------Video Hide on Modal -------
    const handleVideoModalClose = () => {
        setShowVideoModalState(false);
        setSelectedVideo([]);
    }
    // ---------Video Hide on Modal -------

    // ---------Image Show on Modal -------
    const handleImageModalShow = (item) => {
        let tmp = item
        setShowImageModalState(true);
        setSelectedImage(tmp);
    }
    // ---------Image Show on Modal -------

    // ---------Image Hide on Modal -------
    const handleImageModalClose = () => {
        setShowImageModalState(false);
        setSelectedImage([]);
    }
    // ---------Image Hide on Modal -------

    // --------------------Delete  Audio/Video/Image from Firebase  ------------------
    const onDeleteMedia = async (i, type, url) => {

        let DummyArray = []
        if (type === 'audio') {
            DummyArray = [...audioList]
        } else if (type === 'other') {
            DummyArray = [...docList]
        } else {
            DummyArray = [...pictureVideoList]
        }
        const data = JSON.parse(sessionStorage.getItem('UserData'))
        // ---------FolderName-------
        const FinfFolderNameArray = data.cc_user_email_id.split("@")
        const FolderName = FinfFolderNameArray[0];
        // ---------FolderName-------
        const fileName = DummyArray[i].cc_attachment_url;
        // const mediaType = DummyArray[i].cc_attachment_type;
        DummyArray.splice(i, 1)
        try {

            if (type === 'audio') {
                
                const dumyArr = [...DummyArray];
                
                let data = audioList.find((item) => item.cc_attachment_url == url)
                data.cc_attachment_operation = 'remove'
            
                let newArr = [...audioList]

                if(selectionType == 'addNew') {
                    setAudioList(dumyArr);
                    sessionStorage.setItem('LPAudioList', JSON.stringify(dumyArr))
                } else {
                setAudioList(newArr);
                sessionStorage.setItem('LPAudioList', JSON.stringify(newArr))
                }
                // setDocList(dumyArr);
                setIsSaved(false);
                // let data = pictureVideoList.find((item) => item.cc_attachment_url == url)
                // data.cc_attachment_operation = 'remove'
                // let newArr = [...pictureVideoList]
                // // let data2 = [...dumyArr, data]
                // if(selectionType == 'addNew') {
                //     setAudioList(dumyArr);
                // } else {
                // setAudioList(newArr);
                // }
                // setIsSaved(false);
                // setAudioList(dumyArr);
                // setIsSaved(false);
            } else if (type === "other") {
               
                
                const dumyArr = [...DummyArray];
           
                let data = docList.find((item) => item.cc_attachment_url == url)
                data.cc_attachment_operation = 'remove'
              

                let newArr = [...docList]
              
                if(selectionType == 'addNew') {
                    setDocList(dumyArr);
                    sessionStorage.setItem('LPDocList',JSON.stringify(dumyArr))
                } else {
                setDocList(newArr);
                sessionStorage.setItem('LPDocList',JSON.stringify(newArr))
                }
                // setDocList(dumyArr);
                setIsSaved(false);
                // const dumyArr = [...DummyArray];
                // let data = docList.find((item) => item.cc_attachment_url == url)
                // data.cc_attachment_operation = 'remove'
                // console.log('pictureVideoList',docList)
                
                // // console.log('data',data)
                // // let data2 = [...dumyArr, data]
                // // console.log('data2',data2)
                // if(selectionType == 'addNew') {
                //     setDocList(dumyArr);
                // } else {
                // setDocList(newArr);
                // }
                // // setDocList(dumyArr);
                // setIsSaved(false);
            } else {
          
                let dumyArr = [...DummyArray]
                // let dumyArr = pictureVideoList
                let data = pictureVideoList.find((item) => item.cc_attachment_url == url)
                data.cc_attachment_operation = 'remove'
          
                let newArr = [...pictureVideoList]
                if(selectionType == 'addNew') {
                    setPictureVideoList(dumyArr);
                    sessionStorage.setItem('LPPictureVideoList',JSON.stringify(dumyArr))
                } else {
                setPictureVideoList(newArr);
                sessionStorage.setItem('LPPictureVideoList',JSON.stringify(newArr))
                }
                setIsSaved(false);
            }
            await storage.ref(`${url}`).delete()
            // createNotification('success', 'Multimedia deleted successfully.');
        } catch (e) {
            createNotification('error', 'The Multimedia was not deleted. Please try again.');
        }
    }
    // -----------------Delete  Audio/Video/Image from Firebase  -------------
    // ---------Doc Show on Modal -------
    const handleDocModalShow = (item) => {
        const mediaTypeArray = item.cc_attachment_url.split(".")
        const mediaType = mediaTypeArray[1]
        const encodeUrlStr = encodeURIComponent(item.cc_attachment_fullurl);
        setSelectedDoc(encodeUrlStr)
    }
    // ---------Doc Show on Modal -------

    const handleDocModalClose = () => {
        setShowDocPopUp(false);
        setSelectedDoc("");
    }

    // -------------------- Audio Modal ------------------
    // ---------Audio Modal open-------
    const handleShowAudioModal = () => {
        setShow(true);
        setRecord(false);
        setIsEnabled(false);
        setIsSaved(false);
    }
    // ----------Audio Modal open-------

    // ----------Audio Recording Start-------
    const startRecorder = () => {
        recorder.start()
            .then(() => {
                setRecord(true)
            })
            .catch(e => {
                console.error(e);
            });
    }
    // ----------Audio Recording Start-------

    // ----------Save Audio Recording-------
    const stopRecording = () => {
        setShow(false);
        setRecord(false);
        setIsEnabled(false);
        setIsSaved(false);
        recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                setRecord(false);
                const file = new File(buffer, 'Recorded_audio.mp3', {
                    type: blob.type,
                    lastModified: Date.now(),
                });
                const data = JSON.parse(sessionStorage.getItem('UserData'))
                setUploadAudioStatus(true);
                const media_Size = (blob.size / 1000000);
                // ---------DummyName-------

                const GetRendomName = getRandomName();
                const DummyName = GetRendomName + '.mp3';
                // ---------DummyName-------

                // ---------FolderName-------
                const FindFolderNameArray = data.cc_user_email_id.split("@")
                const FolderName = FindFolderNameArray[0]
                // ---------FolderName-------

                const uploadTask = storage.ref(`${FirebseStroageFolder}/${FolderName}/lessonPlan/lessonPlan-${classId || ClassDetails.cc_class_id}/${DummyName}`).put(file)
                uploadTask.on('state_changed',
                    (snapShot) => {
                        const progress = Math.round(
                            (snapShot.bytesTransferred / snapShot.totalBytes) * 100
                        );
                        setMediaUploadPercentage(progress)
                    }, (err) => {
                        setUploadAudioStatus(false);
                        createNotification('error', 'Audio Not Uploaded. Please give it another shot!');
                    }, () => {
                        storage.ref(`${FirebseStroageFolder}/${FolderName}/lessonPlan/lessonPlan-${classId || ClassDetails.cc_class_id}/`).child(DummyName).getDownloadURL()

                            .then(fireBaseUrl => {
                                let value = {
                                    cc_attachment_fullurl: fireBaseUrl,
                                    cc_attachment_url: `${FirebseStroageFolder}/${FolderName}/lessonPlan/lessonPlan-${classId || ClassDetails.cc_class_id}/${DummyName}`,
                                    cc_attachment_name: auditTitle,
                                    cc_attachment_type: 'audio',
                                    cc_attachment_size: media_Size,
                                    cc_media_mark_artified: "no",
                                    cc_attachment_operation: "add"
                                }
                                let DummyList = [...audioList];
                                DummyList.push(value)
                                setUploadAudioStatus(false);
                                setAudioList(DummyList);
                                sessionStorage.setItem('LPAudioList', JSON.stringify(DummyList))
                                setAuditTitle("");
                                setIsSaved(false);
                                // createNotification('success', 'Great Job! Audio Upload Successful!');
                            })
                    })
            });
    }
    // ----------Save Audio Recording-------

    // ----------Set Audio Title-------
    const setAudioTitle = (e) => {
        if (e.target.value !== undefined) {
            setAuditTitle(e.target.value);
            setIsSaved(false);
        }
    }
    // ----------Set Audio Title-------

    // ----------Edit Audio Title-------
    const enableAudioNameEditing = () => {
        setIsEnabled(true)
    }
    // ----------Edit Audio Title-------

    // ---------Audio Modal Close-------

    const handleClose = () => {
        recorder.stop();
        setShow(false);
        setRecord(false);
        setIsEnabled(false);
        setIsSaved(false);
    }

    // const handlePause = () => {
    //     recorder.stop();
    //     setRecord(false);
    //     setIsEnabled(false);
    // }
    // ---------Audio Modal Close-------

    const addUrlList = (value, index) => {
        let newUrlList = [...urlList];
        newUrlList.map((val, k) => {
            if (index === k) {
                newUrlList[index] = value;
                sessionStorage.setItem('LPUrllist', JSON.stringify(newUrlList))
                return setUrlList(newUrlList)
            } else {
                sessionStorage.setItem('LPUrllist',JSON.stringify(newUrlList))
                return setUrlList(newUrlList)
            }
        })
    }

    const addMoreUrls = () => {
        let newUrlList = [...urlList];
        newUrlList.push("");
        sessionStorage.setItem('LPUrllist',JSON.stringify(newUrlList))
        setUrlList(newUrlList);
    }

    const deleteUrls = (data, index) => {
        let newUrlList = [...urlList];
        const filteredItems = newUrlList.filter(item => item !== data)
        sessionStorage.setItem('LPUrllist',JSON.stringify(filteredItems))
        setUrlList(filteredItems);
    }

    const addQuestionsAns = (val, data) => {

        let answersdata = questionAnswers && questionAnswers.map(value => {
            let answerValue = { ...value }
            if (answerValue.question_id === data.question_id) {
                answerValue = { ...answerValue, answer: val }
            } else {
                answerValue = { ...answerValue }
            }
            return answerValue
        })
        sessionStorage.setItem("LPPlaiingQuestion", JSON.stringify(answersdata))
        setQuestionAnswers(answersdata);
    }

    const addMinsDuration = (val) => {
        if(val == 0) {
            let reminderMinutes = ["0"]
            setMinsDuration(reminderMinutes)
            sessionStorage.setItem('LPMinutes', JSON.stringify(reminderMinutes))
        } else {
            
            let reminderMinutes = [...minsDuration];
            let index = reminderMinutes.indexOf('0')
            if(index > -1) {
                reminderMinutes.splice(index, 1)
                // sessionStorage.setItem('LPMinutes', JSON.stringify(reminderMinutes))
            }
        if (!reminderMinutes.includes(val)) {
            reminderMinutes.push(val);
            setMinsDuration(reminderMinutes);
            sessionStorage.setItem('LPMinutes', JSON.stringify(reminderMinutes))
        } else {
            var filteredMinutes = reminderMinutes.filter(e => e !== val);
            setMinsDuration(filteredMinutes || 0);
            sessionStorage.setItem('LPMinutes', JSON.stringify(filteredMinutes) || 0)
        }
    }
    }

    const generateLpTextHandler = () => {
        let data = {
            plan_title : sessionStorage.getItem('LPTitle')?.length > 0 ? sessionStorage.getItem('LPTitle') : title,
            plan_topic : sessionStorage.getItem('LPTopic')?.length > 0 ? sessionStorage.getItem('LPTopic') : topic
        }
        dispatch(getLpText(data))
    }

    return (
        <div className="planningContainer " style={{borderRadius : "12px" , height : "600px"}}>
        <div className="planningContainer row" style={{borderRadius : "22px", 
        // width: "100%", 
        left: "16px", position: "relative" , overflow: "initial"}}>
<div className='col-lg-6 col-md-12 pr-0'>
<div className="planTitleDiv row">
  <div className="planTitlePlanning col-lg-3 col-md-6 col-sm-12">
    <p className="headTitlesPlan">Plan Title</p>
    <input
      type="text"
      className="mailDiv"
      name="email"
      placeholder="Enter title"
      id="email"
      value={
        sessionStorage.getItem('LPTitle')?.length > 0
          ? sessionStorage.getItem('LPTitle')
          : title
      }
      onChange={e => {
        setTitle(e.target.value);
        sessionStorage.setItem('LPTitle', e.target.value);
      }}
      maxLength="32"
    />
  </div>
  <div className="planTopic col-lg-3 col-md-6 col-sm-12 ml-auto mr-auto">
    <p className="headTitlesPlan">Topic</p>
    <input
      type="text"
      className="mailDiv"
      name="email"
      placeholder="Enter topic / chapter"
      id="email"
      value={
        sessionStorage.getItem('LPTopic')?.length > 0
          ? sessionStorage.getItem('LPTopic')
          : topic
      }
      onChange={e => {
        setTopic(e.target.value);
        sessionStorage.setItem('LPTopic', e.target.value);
      }}
      maxLength="32"
    />
  </div>
  <div className="planStandards col-lg-6 col-md-12 ml-auto" style={{ padding : '0 15px 25px 0' }}>
    <Button className="mr-3 generateAIBtn" onClick={() => {
      setShowEditModal(true);
      setCountdown(3);
      generateLpTextHandler()
    }}
    disabled={title?.trim().length == 0 || topic?.trim().length == 0}
    // disabled={(title?.length == 0 || !sessionStorage.getItem('LPTitle')) || (topic?.length == 0 || !sessionStorage.getItem('LPTopic'))}
    >
      <span className="viewAttText">Generate via AI</span>
    </Button>
    <Button className="addNewLesson" style={{ width: 140 }} onClick={() => {
      history.push(routes.CLASSCORESTANDARDS, {
        classDetail: ClassDetails,
        academicYearId: academicYearId,
        academicYear: academicYear,
        classId: classId || sessionStorage.getItem('Class_ID_for_LP'),
        classPermission: classPermission
      });
    }}>
      <span className="viewAttText">Get via Standards</span>
    </Button>
  </div>
</div>
</div>

<div className='col-lg-6 col-md-12'>

            <div className="planScheduleDiv row">
                <div className="planDateTitle col-4">
                    <p className="headTitlesPlan">Plan Date</p>
                    <div className="dateSubBlock">
                        <div className="dayDateTextDiv" >
                            <p className="dayDateText">{mainDate}</p></div>
                        <DatePicker
                            onChange={date => { setSelectedDate(date); sessionStorage.setItem('LPFormatDate',date) }}
                            customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
                            minDate={selectionType === "addNew" ? moment().toDate() : ""}
                            // minDate = {selectionType == 'addNew' ? (sessionStorage.getItem('LPFormatDate')?.length > 0 ? new Date(sessionStorage.getItem('LPFormatDate')) : moment().toDate()) : ""}
                        />
                    </div>
                </div>
                <div className="planDateTitlePlanning col-4" >
                    <p className="headTitlesPlan">Add Reminder</p>
                    <div className="dateSubBlock">
                        <div className="dayDateTextDiv" >
                            <p className="dayDateText">{selectedTime || getCurrentTime()}</p></div>
                           { console.log('selectedTime :>> ', selectedTime)}
                        <DatePicker
                            className='fghf'
                            selected={new Date()}
                            onChange={date => { SetTime(date) }}
                            showTimeSelect
                            // timeFormat={formatClock === "24 Hours" ? "HH:mm:ss" : "haa"}
                            timeFormat={"HH:mm"}
                            timeIntervals={30}
                            minDate={new Date()}
                            minTime={setMinutes(new Date(), 60)}
                            maxTime={setHours(setMinutes(new Date(), 45), 23)}
                            maxDate={new Date()}
                            customInput={<img src={historyIcon} className="remIcon" alt="" width="18" height="18" />}
                            dateFormat="Pp"
                            timeCaption="Hour"
                        />
                    </div>
                </div>
                <div className="planNotification d-flex flex-wrap align-items-center">
                    <div className='d-flex'>
                    <input
  onChange={(event) => {
    if (event.currentTarget.checked) {
      setcheckedVal(true);
    } else {
      setcheckedVal(false);
      setMinsDuration(["0"]);
    }
    sessionStorage.setItem('LPNotification', JSON.stringify(event.currentTarget.checked));
  }}

                    checked={sessionStorage.getItem('LPNotification') == 'true' || checkedVal}
                    style={{marginRight : "8px"}} type="checkbox" name="none" id="" />
                    <p className="headTitlesPlan">Notification <span className='headTitlesPlan__before'>(Before)</span></p>
                    </div>
                    <div style={(!checkedVal) ? {opacity: "0.5", pointerEvents: "none",maxWidth : 274} : {cursor : "pointer",maxWidth : 274}}  className="notificationSubBlock d-flex flex-wrap justify-content-around w-100 mt-auto" >
                        <div className='filterTimeDiv ' onClick={() => { addMinsDuration("10") }}>
                        <p className={`${checkedVal && ( sessionStorage.getItem('LPMinutes')?.length > 0 && JSON.parse(sessionStorage.getItem('LPMinutes')?.includes("10")) || minsDuration?.includes("10")) ? 'filterTimeDiv__filterText_fill' : 'filterTimeDiv__filterText'}`}>10 mins</p>
                        </div>
                        <div className='filterTimeDiv' onClick={() => { addMinsDuration("30") }}>
                        <p className={`${checkedVal && ( sessionStorage.getItem('LPMinutes')?.length > 0 && JSON.parse(sessionStorage.getItem('LPMinutes')?.includes("30")) || minsDuration?.includes("30")) ? 'filterTimeDiv__filterText_fill' : 'filterTimeDiv__filterText'}`}>30 mins</p>
                        </div>
                        <div className='filterTimeDiv' onClick={() => { addMinsDuration("60") }}>
                        <p className={`${checkedVal && ( sessionStorage.getItem('LPMinutes')?.length > 0 && JSON.parse(sessionStorage.getItem('LPMinutes')?.includes("60")) || minsDuration?.includes("60")) ? 'filterTimeDiv__filterText_fill' : 'filterTimeDiv__filterText'}`}>01 hour</p>
                        </div>
                        <div className='filterTimeDiv' onClick={() => { addMinsDuration("1440") }}>
                           <p className={`${checkedVal && ( sessionStorage.getItem('LPMinutes')?.length > 0 && JSON.parse(sessionStorage.getItem('LPMinutes')?.includes("1440")) || minsDuration?.includes("1440")) ? 'filterTimeDiv__filterText_fill' : 'filterTimeDiv__filterText'}`}>01 Day</p>
                        {/* </div> */}
                        </div>
                        {/* <div className='filterTimeDiv' onClick={() => { addMinsDuration("0") }}>
                            <i className="checkBoxfilter material-icons">{minsDuration?.includes("0") ? 'check_circle' : 'radio_button_unchecked'}</i>
                            <p className='filterText'>None</p>
                        </div> */}
                    </div>
                </div>

            </div>
            </div>
</div>
            <div className="allSubDivs">
                {questionAnswers && questionAnswers.map((val, k) => {
                    return (
                        <div className="rationalDiv" key={k}>
                            <div className="rationalTitleBox">
                                <p className="rationalTitle backendQuestionCaps">{val.question_text}</p>
                            </div>
                            <div className="rationalInputBox">
                                <textarea name="message" className="rationalInputText" id="message" rows="4" value={val.answer} onChange={e => addQuestionsAns(e.target.value, val)}
                                    placeholder="Enter description here.."></textarea>
                            </div>
                        </div>
                    )
                })}

                <div className="rationalDiv">
                    <div className="rationalTitleBox">
                        <p className="rationalTitle">Multimedia (<span style={{position : 'relative', top: 1}}>Photos/ Videos/ Pdf/ Doc/ Ppt/ Xls/ Csv</span>)</p>
                    </div>
                    <div className="rationalInputBox">
                        <div className="audio-sec">
                            <Button className="btn-add-media">
                                {uploadMediaStatus ?
                                    /* ---------------Progress Bar------------ */
                                    <StudentNoteProgressbarComponent value={mediaUploadPercentage} type={'media'} />
                                    /* ---------------Progress Bar------------ */
                                    // <img src={loaderImag} alt='' className='loaderUploadIcon' /> 
                                    :
                                    <img className="add-media" src={addIcon} alt='' />}
                                <Form.Control type="file" accept="video/*|image/*|pdf/*|doc/*|docx/*|gif/*|jpg/*|jpeg/*|png/*|xls/*|ppt/*|pptx/*|csv/*" onChange={e => { getMediaHandle(e) }} />
                            </Button>
                            <div className="picture-video-sec">
                                {pictureVideoList.length > 0 &&
                                    pictureVideoList.filter((item) => item?.cc_attachment_operation != 'remove').map((item, i) => {
                                     
                                        return (
                                            <div key={i}>
                                                {item.cc_attachment_type !== 'image' ? (
                                                    <div className="note">
                                                        <div className="img-div">
                                                            <img src={playIcon} className="playIcon" alt='' width="18" height="18" onClick={() => { handleVideoModalShow(item) }} />
                                                        </div>
                                                        <img className="deleteBtn" src={closeMediaIcon} alt='' width="14" height="14" onClick={() => { onDeleteMedia(i, 'video', item.cc_attachment_url) }} />
                                                    </div>
                                                ) : (
                                                    <div className="note" style={{ backgroundImage: `url(${item.cc_attachment_fullurl})` }}>
                                                        <div className='img-div-only' onClick={() => { handleImageModalShow(item) }} >
                                                            {/* <img src={eyeIcon} className="playIcon" alt='' width="18" height="18" /> */}
                                                        </div>
                                                        <img className="deleteImgBtn" src={closeMediaIcon} alt='' width="14" height="14" onClick={() => { onDeleteMedia(i, 'video', item.cc_attachment_url) }} />

                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                                {docList.length > 0 && docList.filter((item) => item?.cc_attachment_operation != 'remove').map((item, i) => {
                                    return (
                                        <div key={i}>
                                            <div className="note">
                                                <div className="img-divDoc">
                                                    {item.cc_attachment_url.split(".").pop() === "pdf" && <img src={note_pdf} alt='' className="csvIcon" onClick={() => { handleDocModalShow(item) }} />}
                                                    {item.cc_attachment_url.split(".").pop() === "doc" || item.cc_attachment_url.split(".").pop() === "docx" ? <img src={note_doc} alt='' className="csvIcon" onClick={() => { handleDocModalShow(item) }} /> : ""}
                                                    {item.cc_attachment_url.split(".").pop() === "ppt" || item.cc_attachment_url.split(".").pop() === "pptx" ? <img src={note_ppt} alt='' className="csvIcon" onClick={() => { handleDocModalShow(item) }} /> : ""}
                                                    {item.cc_attachment_url.split(".").pop() === "xls" && <img src={note_xls} alt='' className="csvIcon" onClick={() => { handleDocModalShow(item) }} />}
                                                    {item.cc_attachment_url.split(".").pop() === "csv" && <img src={note_csv} alt='' className="csvIcon" onClick={() => { handleDocModalShow(item) }} />}
                                                </div>
                                                <img className="deleteBtn" src={closeMediaIcon} alt='' width="14" height="14" onClick={() => { onDeleteMedia(i, 'other', item.cc_attachment_url) }} />
                                            </div>
                                        </div>
                                    )
                                })}
                                {docList.length < 0 || pictureVideoList.length < 0 &&
                                    <p className="multimedia-suggestion">As a best practice remember to add media<br></br> notes to support your text notes</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rationalDiv">
                    <div className="rationalTitleBox">
                        <p className="rationalTitle">Audio Note</p>
                    </div>
                    <div className="rationalInputBox">
                        <div className="audio-sec">
                            <Button className="btn-add-media" style={{ marginTop: 3 }} onClick={handleShowAudioModal}>
                                {uploadAudioStatus ?
                                    <StudentNoteProgressbarComponent className='upload_progress' value={mediaUploadPercentage} type={'media'} />
                                    // <img src={loaderImag} alt='' className='loaderUploadIcon' /> 
                                    :
                                    <img className="add-media" src={addIcon} alt='' />}
                            </Button>
                            <div className="audit-sec">
                                {audioList.length > 0 && audioList.filter((item) => item?.cc_attachment_operation != 'remove').map((item, index) => {
                                    return (
                                        <div key={index} className="audio-div">
                                            <div className="audio-sub-div">
                                                <p className="audio-filename">{item.cc_attachment_name === '' ? "Audio Note" : item.cc_attachment_name}</p>
                                                <audio controls className="player" preload="false" controlsList="nodownload" src={item.cc_attachment_fullurl} ></audio>
                                            </div>
                                            <img className="deleteBtn" src={closeMediaIcon} alt='' width="14" height="14" onClick={() => { onDeleteMedia(index, 'audio', item.cc_attachment_url) }} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {/* ---------------Audio panel------------ */}
                    </div>
                </div>
                <div className="rationalDivUrls">
                    <div className="rationalTitleBox">
                        <p className="rationalTitle">Add URL(s)</p><p className="rationalSubTitle" onClick={addMoreUrls}>+ Add More</p>
                    </div>
                    <div className="rationalInputBox">
                        {urlList && urlList.map((valu, l) => {
                            return (<div className="rationalInputTextDiv" key={l}>
                                <input name="message" type="text" className="rationalInputText" value={valu} onChange={e => addUrlList(e.target.value, l)}
                                    placeholder="Enter link" />
                                {/* {valu !== "" && */}
                                 <img src={deleteUrl} alt='' onClick={() => { deleteUrls(valu, l) }} />
                                {/* //  } */}
                                 </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            {/* ---------------Record Audio Modal------------ */}
            <Modal className="add-medias-modal br-8" show={show} onHide={handleClose}>
                <Modal.Header className='Planning-header'>
                    <Modal.Title>
                        <Button variant="default" className="pencilBtn" onClick={enableAudioNameEditing} style={{minWidth : 64}}>
                            <i className="fa fa-pencil"></i>
                        </Button>
                        {isEnabled ? (
                            <Form.Control type="text" placeholder="Enter Audio Title Here" onChange={(e) => { setAudioTitle(e) }} />
                        ) : (
                            "Audio Note - " + moment().format('LT')
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="timer" >
                        <Timer
                            lastUnit="m"
                            initialTime={0}
                            checkpoints={[
                                {
                                    time: 60000 * 3,
                                    callback: () => stopRecording(),
                                },
                            ]}
                            startImmediately={!record}
                        >
                            {({ start, resume, pause, stop, reset, timerState }) => (<div>
                                <div className={record ? "display-none record-btn text-center" : "record-btn text-center"}>
                                    <Button className="recordOnOff" onClick={startRecorder}>
                                        <img src={startRecordingIcon} onClick={reset} alt='' />
                                    </Button>
                                    <p className="record-info">Recording Duration Limit: 3 Mins</p>
                                </div>
                                <div className={!record ? "display-none record-btn text-center" : "record-btn text-center"} >
                                    {/* <div> */}
                                    <div>
                                    <Button className="stopIcon" onClick={pause}>
                                        <img src={stopIcon} alt='' />
                                    </Button>
                                    <Button className="playAfterPause" onClick={resume} style={{width : 57, height : 'auto'}}>
                                        <img src={playIcon} className="playIconPause" alt='' width="35" height="35" />
                                    </Button>
                                    </div>
                                    <br />
                                    <div className='timer_Div' style={{ width: 20 }}>
                                        0<Timer.Hours />:0<Timer.Minutes />:<Timer.Seconds />
                                    </div>
                                </div>
                            </div>)}
                        </Timer>
                    </div>
                    {/* <audio controls className="player" preload="false" controlsList="nodownload" src={audioDetails.blobURL} />      */}
                </Modal.Body>
                <Modal.Footer>
                    {!record ? (
                        <Button variant="danger" className="dangerCrossBtn" onClick={handleClose}>
                            <img src={closeIcon} alt='' />
                        </Button>
                    ) : (
                        <>
                         <Button className="deleteIcon" onClick={handleClose} style={{backgroundColor:'#EB4041', borderRadius: '8px', width: 64}}>
                                    {/* <img src={deleteIcon} alt='' style={{backgroundColor:'#EB4041 !important'}} /> */}

                                    <i className = "fa fa-trash-o"  style = {{fontSize: "25px" , color : "white" , backgroundColor : "rgb(235, 64, 65)"}} ></i>
                                </Button>
                                <div style={{ width: 20 }}>

                                </div>
                                <Button variant="success" onClick={stopRecording} style={{backgroundColor:'#4AD245', borderRadius: '8px', width: 64}}>
                                    <img src={tickIcon} alt='' />
                                </Button>
                            {/* <Button variant="danger" className="deleteIcon" onClick={handleClose}>
                                <img src={deleteIcon} alt='' />
                            </Button>
                            <div style={{ width: 20 }}>

                            </div>
                            <Button variant="success" className="sucessBtn" onClick={stopRecording}>
                                <img src={tickIcon} alt='' />
                            </Button> */}
                        </>
                    )}
                </Modal.Footer>
            </Modal>
            {/* ---------------Record Audio Modal------------ */}

            {/* ---------------Play Video Modal------------ */}
            <Modal className="played-video-modal br-8" show={showVideoModalState} onHide={handleVideoModalClose}>
                <Modal.Header className='Planning-header'>
                    <Button variant="default" className="delete-btn" onClick={handleVideoModalClose}>
                        <img src={closeIcon} alt='' />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    {selectedVideo.cc_attachment_url !== undefined && (
                        <video width="750" height="455" controls src={selectedVideo.cc_attachment_fullurl}></video>
                    )}
                </Modal.Body>
            </Modal>
            {/* ---------------Play Video Modal------------ */}

            {/* ---------------Image View Modal------------ */}
            <Modal className="played-video-modal br-8" show={showImageModalState} onHide={handleImageModalClose}>
                <Modal.Header className='Planning-header'>
                    <Button variant="default" className="delete-btn" onClick={handleImageModalClose}>
                        <img src={closeIcon} alt='' />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    {selectedImage.cc_attachment_url !== undefined && (
                        <img src={selectedImage.cc_attachment_fullurl} alt="" style={{ width: "100%", height: "100%" }} />
                    )}
                </Modal.Body>
            </Modal>

            <Modal centered className="doc-modal-warning br-8" show={showDocPopUp} >
                <Modal.Header className='Planning-header'>
                    <Button variant="default" className="delete-btn" onClick={handleDocModalClose}>
                        <img src={closeIcon} alt='' />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <IframeGoogleDoc url={selectedDoc} />
                </Modal.Body>
            </Modal>
            {/* ---------------Image View Modal------------ */}

             {/* ---------------Prepare Modal --------------*/}
             <Modal
          centered
          className="delete-modal-warning br-8 generateDataModal"
          show={showEditModal}
        //   onHide={handleEditModalClose}
          // backdrop={editStandardLoader ? "static" : false}
        >
          <Modal.Body className="pb-0">
                <div class="sk-fading-circle">
  <div class="sk-circle1 sk-circle"></div>
  <div class="sk-circle2 sk-circle"></div>
  <div class="sk-circle3 sk-circle"></div>
  <div class="sk-circle4 sk-circle"></div>
  <div class="sk-circle5 sk-circle"></div>
  <div class="sk-circle6 sk-circle"></div>
  <div class="sk-circle7 sk-circle"></div>
  <div class="sk-circle8 sk-circle"></div>
  <div class="sk-circle9 sk-circle"></div>
  <div class="sk-circle10 sk-circle"></div>
  <div class="sk-circle11 sk-circle"></div>
  <div class="sk-circle12 sk-circle"></div>
</div>
          

           <p className={`pleaseWaitText`}>please wait...</p>
           <p className='prepareText'>Preparing your lesson plan</p>
          
            
           { countdown <= 2  && 
           <p className='dataPara'>For <span>{sessionStorage.getItem('LPTitle')?.length > 0 ? sessionStorage.getItem('LPTitle') : title}</span></p>
            }
            {countdown <= 0 &&  
            <p className='dataPara'>Under <span>{sessionStorage.getItem('LPTopic')?.length > 0 ? sessionStorage.getItem('LPTopic') : topic}</span></p>
            }
            {
            (countdown > 0) && 
            <div className="snippet d-flex justify-content-center mb-4" data-title="dot-flashing">
            <div className="stage">
              <div className="dot-flashing"></div>
            </div>
          </div>
           }
         
          
           
          </Modal.Body>
          {/* <Modal.Footer>
            <Button
              variant="default"
              className="delete-modal-warning cancel-btn"
              onClick={handleEditModalClose}
              style={{ background: "transparent", color: "#000" }}
            >
              Cancel
            </Button>
            <Button
              variant="default"
              className="delete-modal-warning save-standard-btn"
              style={{ borderRadius: "8px" }}
              onClick={() => {
                // editStandardHandler();
              }}
            //   disabled={editStandardLoader}
            >
              Save
            </Button>
          </Modal.Footer> */}
        </Modal>

        </div>
    );
}
export default Planning