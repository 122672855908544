import React from 'react';
import { connect } from 'react-redux';
import SubscriptionsDashboard from './SubscriptionsDashboardComponent/SubscriptionsDashboard';
import { getSubscriptionPlanRequest } from 'Actions/SubscriptionPlanAction/subscriptionPlanAction'



const SubscriptionsIndex = (props) => {
    return (
        <SubscriptionsDashboard {...props} />

    )
}
const mapStateToProps = state => ({
    state: state,
    Plans: state.SubscriptionPlan.plans

});
const mapDispatchToProps = dispatch => {
    return {
        getSubscriptionPlanRequest: (data) => dispatch(getSubscriptionPlanRequest(data)),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    SubscriptionsIndex
);
