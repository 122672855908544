import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import AssessmentStudentProfile from '../../AddAssessmentStudentProfileComponent/AddStudentProfiles';
import classIcon from 'Assets/FormativeAssessmentModule/class.png';
import gradebookIcon from 'Assets/FormativeAssessmentModule/gradebook.png';
import calendarIcon from 'Assets/FormativeAssessmentModule/calendar.svg';
import thumbIcon from 'Assets/FormativeAssessmentModule/thumb.svg';
import pencilIcon from 'Assets/FormativeAssessmentModule/pencil-alt.png';
import trashIcon from 'Assets/FormativeAssessmentModule/trash-alt.png';
import closeIcon from 'Assets/FormativeAssessmentModule/close.svg';
import tickIcon from 'Assets/FormativeAssessmentModule/tick.svg';
import './AddAssessment.scss';

// import * as routes from "../../../Router/RoutesURL";

class AddAssessment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: "Jan 03, 2020"
        };
    }

    render() {

        const DateCustomInput = ({ value, onClick }) => (
            <img src={calendarIcon} className="calendarIcon" alt='' width="18" height="18" onClick={onClick} />
        );

        let arr = [{}, {}, {}, {}, {}, {}]

        return (
            <div className='assessmentMainContainer'>
                <Row>

                    {/* ---------------left panel------------ */}
                    <Col md="4" lg="3" className="left-section">
                        <Card className="br-8">
                            <Card.Body>

                                {/* ---------------Back Button------------ */}
                                <Button className="back-btn" variant="secondary" onClick={() => { this.props.history.goBack() }}>
                                    <i className="fa fa-chevron-left" aria-hidden="true"></i>Academic Year
                                </Button>
                                {/* ---------------Back Button------------ */}

                                {/* ---------------Action------------ */}
                                <Row className="profile-card">
                                    <Col xs="6" className="pr-2">
                                        <Button variant="primary" className="class-btn br-8">
                                            <img src={classIcon} alt='' className='noteOptionIcons' />
                                            Classes
                                        </Button>
                                    </Col>
                                    <Col xs="6" className="pl-2">
                                        <Button variant="primary" className="gradebook-btn br-8">
                                            <img src={gradebookIcon} alt='' className='noteOptionIcons' />
                                            Gradebook
                                        </Button>
                                    </Col>
                                </Row>
                                {/* ---------------Action------------ */}

                            </Card.Body>
                        </Card>

                        {/* ---------------Classroom Section------------ */}
                        <Card className="br-8 mt-15">
                            <Card.Body>
                                <div className="cardTitle"><p className="attchement-card">Tags</p></div>
                                <Card.Text as="div">
                                    <Row>
                                        {arr.map(item => {
                                            return (
                                                <Col xs="12" className="single-attchement">
                                                    <label className="title">Good in Equations</label>
                                                    <div className="action">
                                                        <Button variant="default" className="edit-btn">
                                                            <img src={pencilIcon} alt='' className='img' />
                                                            Edit
                                                        </Button>
                                                        <Button variant="default" className="trash-btn">
                                                            <img src={trashIcon} alt='' className='img' />
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        {/* ---------------Classroom Section------------ */}

                    </Col>
                    {/* ---------------left panel------------ */}


                    <Col md="8" lg="9" className="right-section">
                        <Card className="header">
                            <Card.Body>
                                <Row>
                                    <Col xs="6" className="pr-2 title">
                                        Add Assessment
                                    </Col>
                                    <Col xs="6" className="text-right">
                                        <Button variant="danger" className="actionBtn cancel">
                                            <img src={closeIcon} alt='' />
                                        </Button>
                                        <Button variant="success" className="actionBtn save">
                                            <img src={tickIcon} alt='' />
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className="sub-header mt-15 br-8">
                            <Card.Body>
                                <Row>
                                    <Col xs="12" className="title">

                                        <DatePicker
                                            // selected={new date()} 
                                            // onChange={date => {this.setDate(date)}}
                                            customInput={<DateCustomInput />}
                                        />

                                        <div className="date">{this.state.date ? this.state.date : "Jan 03, 2020"}</div>

                                        <FormControl
                                            placeholder="Add Assessment Tag"
                                            aria-label="Add-Assessment-Tag"
                                            className="input"
                                        />
                                        <img src={thumbIcon} className="thumbIcon" alt='' width="18" height="22" />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                        <Row>
                            <AssessmentStudentProfile />
                        </Row>

                    </Col>
                </Row>

            </div>
        )
    }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    AddAssessment
);
