import {
    SEND_CONTACT_US_REQUEST,
    SEND_CONTACT_US_SUCCESS,
    SEND_CONTACT_US_FAILURE,

} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */



// ----------- Contact Us Request-------------
export function sendContactUsRequest(data) {

    return {
        type: SEND_CONTACT_US_REQUEST,
        data,
    };
}

export function sendContactUsSuccess(data) {
    return {
        type: SEND_CONTACT_US_SUCCESS,
        data,
    };
}

export function sendContactUsFailure(data) {
    return {
        type: SEND_CONTACT_US_FAILURE,
        data,
    };
}







