import React from 'react';
import { connect } from 'react-redux';
import StudentGreadeBookProfile from './StudentGradeBookProfileComponent/StudentGreadeBookProfile';
import {
    getStudentGradebookRequest,
    updateStudentGradebookRequest,
    UpdateStudentGradebookAverageRequest,
    UpdateStudentGradebookCheckboxRequest,
    UpdateStudentGradebookInputRequest,
    UpdateStudentGradebookColorRequest
} from 'Actions/GradebookAction/studentGradebookAction';

const StudentGradeBookIndex = (props) => {
    return (
        <StudentGreadeBookProfile {...props} />
    )
}
const mapStateToProps = state => ({
    state: state,
    studentGradebookList: state.UserStudentGradebook.studentGradebookList,
    studentClassList: state.UserStudentGradebook.studentClassList,
    eventAverage: state.UserStudentGradebook.eventAverage,
    eventCount: state.UserStudentGradebook.eventCount,
    selectedClassId: state.UserStudentGradebook.selectedClassId,
    loader: state.UserStudentGradebook.loader

});
const mapDispatchToProps = dispatch => {
    return {
        getStudentGradebookRequest: (data) => dispatch(getStudentGradebookRequest(data)),
        updateStudentGradebookRequest: (data) => dispatch(updateStudentGradebookRequest(data)),

        UpdateStudentGradebookAverageRequest: (data) => dispatch(UpdateStudentGradebookAverageRequest(data)),
        UpdateStudentGradebookCheckboxRequest: (data) => dispatch(UpdateStudentGradebookCheckboxRequest(data)),
        UpdateStudentGradebookInputRequest: (data) => dispatch(UpdateStudentGradebookInputRequest(data)),
        UpdateStudentGradebookColorRequest: (data) => dispatch(UpdateStudentGradebookColorRequest(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    StudentGradeBookIndex
);