import { combineReducers } from 'redux'

/* ------------- Assemble The Reducers ------------- */
const rootReducer = combineReducers({

    // ---------Auth Module --------
    login: require('./AuthModuleReducer/loginReducer').reducer,
    forgot: require('./AuthModuleReducer/forgotReducer').reducer,
    register: require('./AuthModuleReducer/registerReducer').reducer,
    verify: require('./AuthModuleReducer/verifyReducer').reducer,
    resendVerify: require('./AuthModuleReducer/verifyReducer').reducer,
    placeSearch: require('./AuthModuleReducer/placeSearchReducer').reducer,
    selectPlace: require('./AuthModuleReducer/placeSearchReducer').reducer,
    // ---------Auth Module --------


    // ---------Academic Module --------
    academic: require('./AcademicReducer/academicReducer').reducer,

    // ---------Academic Module --------

    // ---------Profile Module --------
    profile: require('./ProfileReducer/profileReducer').reducer,

    // ---------Profile Module --------

    // ---------Roster Module --------
    roster: require('./RosterReducer/rosterReducer').reducer,
    rosterProfile: require('./RosterReducer/rosterProfileReducer').reducer,
    rosterEnrilled: require('./RosterReducer/rosterEnrolledReducer').reducer,

    // ---------Roster Module --------


    // ---------Roster Module --------
    class: require('./ClassReducer/classReducer').reducer,
    classRoster: require('./ClassReducer/classRosterReducer').reducer,
    classRosterSelect: require('./ClassReducer/classSelectRosterReducer').reducer,
    classTag: require('./ClassReducer/classTagReducer').reducer,
    classTagEdit: require('./ClassReducer/classTagReducer').reducer,
    classStandard: require('./ClassReducer/classStandardsReducer').reducer,
    classShare: require('./ClassReducer/classShareReducer').reducer,
    classAttendance: require('./ClassReducer/classAttReducer').reducer,
    classRubrics: require('./ClassReducer/classRubricsReducer').reducer,
    classLessonPlan: require('./ClassReducer/classLessonPlanReducer').reducer,
    // ---------Roster Module --------


    // ---------Note Module --------
    studentNote: require('./NoteReducer/studentNoteReducer').reducer,
    classNote: require('./NoteReducer/classNoteReducer').reducer,
    groupNote: require('./NoteReducer/groupNoteReducer').reducer,
    addGroup:  require('./NoteReducer/addGroupReducer').reducer,
    // ---------Note Module --------

    // ---------FormativeAssessment Module --------
    FormativeAssessment: require('./FormativeAssessmentReducer/FormativeAssessmentReducer').reducer,
    // ---------FormativeAssessment Module --------


    // ---------UserClassGradebook Module --------
    UserClassGradebook: require('./GradebookReducer/classGradebookReducer').reducer,
    UserStudentGradebook: require('./GradebookReducer/studentGradebookReducer').reducer,
    // ---------UserClassGradebook Module --------

    // ---------User Core Standard Module --------
    UserCoreStandard: require('./CoreStandardReducer/coreStandardReducer').reducer,
    // ---------User Core Standard Module --------

    // ---------Blog Module --------
    blog: require('./BlogReducer/blogReducer').reducer,
    // ---------Blog Module --------


    // ---------Data Usage Module --------
    DataUsage: require('./DataUsageReducer/dataUsageReducer').reducer,
    // ---------Data Usage Module --------

    // ---------Contact Us Module --------
    ContactUs: require('./ContactUsReducer/contactUsReducer').reducer,
    // ---------Contact Us Module --------

    // ---------Subscription Plan Module --------
    SubscriptionPlan: require('./SubscriptionPlanReducer/subscriptionPlanReducer').reducer,
    // ---------Subscription Plan Module --------

    // ---------Setting Module --------
    Setting: require('./SettingReducer/SettingReducer').reducer,
    // ---------Setting Module --------

    // ---------Setting Module --------
    Notification: require('./NotificationReducer/notificationReducer').reducer,
    // ---------Setting Module --------

    // ---------Pay Pal Payment Module --------
    PayPalPayment: require('./PayPalPaymentReducer/payPalPaymentReducer').reducer,
    // ---------Pay Pal Payment Module --------

    // ---------Footer Module --------
    footer: require('./FooterReducer/footerReducer').reducer,
    // ---------Footer Module --------

    // ---------Common Module --------
    loading: require('./CommonReducer/loadingReducer').reducer,
    // ---------Common Module --------

})

export default rootReducer;
