import 'whatwg-fetch';

function parseJSON(response) {
  return response.json ? response.json() : response;
}

/**
 * Checks if a network request came back fine, and throws an error if 
   not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an 
 * error
*/
function checkStatus(response, checkToken = true) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  return parseJSON(response).then(responseFormatted => {
    const error = new Error(response.statusText);
    error.response = response;
    error.response.payload = responseFormatted;
    throw error;
  });
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
*/
export default function request(url, options = {}) {
  // Set headers
  if (!options.headers) {
    options.headers = Object.assign({
      'Content-Type': 'application/json',
      ...(options.method == 'POST' || options.method == 'PUT' || options.method == 'GET') && {['Authorization'] : `Bearer ${sessionStorage.getItem('authToken')}`}
    }, options.headers, {});
  }

  // Stringify body object
  if (options && options.body) {
    options.body = JSON.stringify(options.body);

  }

  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
}