import { call, takeLatest, put } from 'redux-saga/effects';
import UserPlaceSearchReducer from 'Redux/Reducers/AuthModuleReducer/placeSearchReducer';
import UserLoadingReducer from 'Redux/Reducers/CommonReducer/loadingReducer';
// import { createNotification } from 'Config/notificationtoast';
import { PLACE_SEARCH, SELECT_PLACE } from 'Actions/constants';
import { Place_API } from 'Config/Api';
import { chroniclecloud_code } from 'Config/Constant';
import request from 'Config/request';
import { decryptData, encryptData } from 'Utils/Helper';
export function* userPlaceSearchModuleSaga() {
  yield takeLatest(PLACE_SEARCH, doSearchPlace);
  yield takeLatest(SELECT_PLACE, doSelectPlace);

}


function* doSearchPlace(action) {
  // yield put(UserLoadingReducer.loading(true));
  // const URL = `${base_url_API}api/place`;
  const URL = `${Place_API}?searchString=${action.data.value}&type=teacher_register&platform=web&page=${1}&limit=${100}`;
  const BODY = {
    // "chroniclecloud_code": chroniclecloud_code,
    // "input": action.data.value,
    // "type": 'textsearch',
    // "sessiontoken": action.data.sessiontoken

    //-------------New Payload------------

    "searchString": action.data.value,
    "platform": "web",
    "page": 1,
    "limit": 100,
  }
  let response;
  try {
    response = yield call(request, URL, {
      method: 'GET',
      // body: { "data": encryptData(BODY) },
    });
    const MainDecode = decryptData(response.res);
    if (MainDecode.status === 200) {
      // yield put(UserLoadingReducer.loadingSuccess(false));
      yield put(UserPlaceSearchReducer.placeSearchSuccess(MainDecode.data));

    } else {
      // yield put(UserLoadingReducer.loadingSuccess(false));
      yield put(UserPlaceSearchReducer.placeSearchFailure(false));
    }
  } catch (error) {

    yield put(UserLoadingReducer.loadingSuccess(false));
    yield put(UserPlaceSearchReducer.placeSearchFailure(false));

  }
}

function* doSelectPlace(action) {
  yield put(UserLoadingReducer.loading(true));
  // const URL = `${base_url_API}api/place`;
  const URL = Place_API;
  const BODY = {
    "chroniclecloud_code": chroniclecloud_code,
    "input": action.data.place_id,
    "type": 'details',
    "sessiontoken": action.data.sessiontoken
  }

  let response;
  try {
    response = yield call(request, URL, {
      method: 'POST',
      body: { "data": encryptData(BODY) },
    });
    const MainDecode = decryptData(response.res);

    if (MainDecode.status === 200) {
      yield put(UserLoadingReducer.loadingSuccess(false));
      yield put(UserPlaceSearchReducer.selectPlaceSuccess(MainDecode.response_data.result));

    } else {
      yield put(UserLoadingReducer.loadingSuccess(false));
      yield put(UserPlaceSearchReducer.selectPlaceFailure(false));
    }
  } catch (error) {

    yield put(UserLoadingReducer.loadingSuccess(false));
    yield put(UserPlaceSearchReducer.selectPlaceFailure(false));

  }
}



