import { call, takeLatest, put } from 'redux-saga/effects';
import UserVerifyReducer from 'Redux/Reducers/AuthModuleReducer/verifyReducer';
import UserLoadingReducer from 'Redux/Reducers/CommonReducer/loadingReducer';
import { createNotification } from 'Config/notificationtoast';
import { VERIFY, RESEND_VERIFY } from 'Actions/constants';
// import { setUserIdOnFirebase } from "Config/commonFirebaseImage";
import { Verification_API, Resend_Otp_API } from 'Config/Api';
import request from 'Config/request';
import { decryptData, encryptData } from 'Utils/Helper';

export function* userVerifyModuleSaga() {
  yield takeLatest(VERIFY, doVerifySaga);
  yield takeLatest(RESEND_VERIFY, doResendVerifySaga);
}

function* doVerifySaga(action) {
  yield put(UserLoadingReducer.loading(true));
  // const URL = `${base_url_API}api_user_verfication`;
  const firebaseToken = sessionStorage.getItem("firebaseToken");
  const URL = Verification_API;
  const BODY = {
    // "chroniclecloud_code": chroniclecloud_code,
    // "user_email_id": action.data.user_email_id,
    // "user_verification_code": action.data.user_verification_code,
    // "device_udid": device_udid,
    // "device_token_id": device_token_id,
    // "device_type": device_type,
    //
    "user_email_id": action.data.user_email_id,
    "user_verification_code": action.data.otp_no,
    "platform": "web"
  }

  let response;
  try {
    response = yield call(request, URL, {
      method: 'POST',
      body: { "data": encryptData(BODY) },
    });
    const MainDecode = decryptData(response.res);
    if (MainDecode.status === 200) {
      sessionStorage.setItem("UDID", MainDecode.data.cc_user_device_udid)
      sessionStorage.setItem("UserData", JSON.stringify(MainDecode.data))
      // sessionStorage.setItem("UserData", (MainDecode.response_data))
      // sessionStorage.setItem("IsLogin", true)
      sessionStorage.setItem("keepMeSinged", true)
      sessionStorage.setItem("GoogleAuth", false)
      sessionStorage.setItem("GoogleAuthItem", false)
      // setUserIdOnFirebase();
      createNotification('success', MainDecode.message);
      yield put(UserLoadingReducer.loadingSuccess(false));
      yield put(UserVerifyReducer.verifySuccess(MainDecode));

    } else {

      createNotification('error', MainDecode.message);
      yield put(UserLoadingReducer.loadingSuccess(false));
      yield put(UserVerifyReducer.verifyFailure(false));
    }
  } catch (error) {
    yield put(UserVerifyReducer.verifyFailure(false));
    createNotification('warning', 'Something went wrong');
    yield put(UserLoadingReducer.loadingSuccess(false));

  }
}

function* doResendVerifySaga(action) {
  yield put(UserLoadingReducer.loading(true));
  const URL = Resend_Otp_API;
  // const URL = `${base_url_API}api_re_email_verfication_code`;
  const BODY = {
    // "chroniclecloud_code": chroniclecloud_code,
    // "user_email_id": action.data.user_email_id,
    // "device_udid": device_udid,

    //--------New Payload------------
    "email": action.data.user_email_id,
    "platform": "web"
  }

  let response;
  try {
    response = yield call(request, URL, {
      method: 'PUT',
      body: { "data": encryptData(BODY) },
    });
    const MainDecode = decryptData(response.res);
    if (MainDecode.status === 200) {

      createNotification('success', MainDecode.message);
      yield put(UserLoadingReducer.loadingSuccess(false));
      yield put(UserVerifyReducer.resendVerifySuccess(MainDecode));

    } else {

      createNotification('error', MainDecode.message);
      yield put(UserLoadingReducer.loadingSuccess(false));
      yield put(UserVerifyReducer.resendVerifyFailure(false));
    }
  } catch (error) {
    yield put(UserVerifyReducer.resendVerifyFailure(false));
    createNotification('warning', 'Something went wrong');
    yield put(UserLoadingReducer.loadingSuccess(false));

  }
}

