// export const HEADER = "/";

export const LOGIN = "/";
export const FORGOT = "/forgotpassword";
export const REGISTER = "/registeruser";
export const VERIFICATION = "/verification";
export const USERSUBSCRIPTION = '/usersubscription'
export const USERPAYPAL = '/usersubscriptionpayment'


// ------------Home-----

export const HOME = "/home";

// ------------ACADEMICDASHBOARD-----
export const ACADEMICDASHBOARD = `${HOME}/academic`;
// ------------ACADEMICDASHBOARD-----

// ------------USERPROFILE-----
export const USERPROFILE = `${HOME}/profile`;
// ------------USERPROFILE-----

// ------------ROSTER-----
export const ROSTER = `${HOME}/roster`;
export const STUDENTPROFILE = `${HOME}/studentProfile`;
export const ROSTERENRILLED = `${HOME}/RosterClassEnrolled`;
export const ROSTERSHARE = `${HOME}/RosterShare`;
export const STUDENTSHARE = `${HOME}/StudentShare`;
// ------------ROSTER-----

// ------------NOTE-----
export const STUDENTNOTE = `${HOME}/studentnote`;
export const STUDENTADDNOTE = `${HOME}/studentnoteadd`;
// ------------NOTE-----

// ------------STUDENTGRADEBOOK----------
export const STUDENTGRADEBOOK = `${HOME}/studentGradebook`;
// ------------STUDENTGRADEBOOK-----------

// ------------CLASSGRADEBOOK----------
export const CLASSGRADEBOOK = `${HOME}/classGradebook`;
export const ADDCLASSGRADEBOOK = `${HOME}/addClassGradebook`;
// ------------CLASSGRADEBOOK-----------

// ------------FORMATIVE ASSESSMENT----------
export const FORMATIVEASSESSMENT = `${HOME}/formativeAssessment`;
export const ADDASSESSMENT = `${HOME}/addAssessment`;
// ------------FORMATIVE ASSESSMENT-----------

// ------------CLASS NOTE----------
export const CLASSNOTE = `${HOME}/classnote`;
export const CLASSADDNOTE = `${HOME}/classnoteadd`;
export const CLASSADDGROUPNOTE = `${HOME}/classnoteaddgroup`;
export const CLASSGROUPNOTEVIEW = `${HOME}/classnotegroupview`;
// ------------CLASS NOTE-----------
// ------------NOTE CLASS SELECT ----------
export const NOTESELECTCLASS = `${HOME}/noteselectclass`;
// ------------CLASS NOTE-----------

// ------------CLASS-----
export const CLASS = `${HOME}/Class`;
export const SINGLECLASS = `${HOME}/SelectedClass`;
export const CLASSROSTER = `${CLASS}/roster`;
export const CLASSCORESTANDARDS = `${CLASS}/coreStandards`;
export const CLASSSHARE = `${HOME}/ClassShare`;
export const CLASSATTENDANCE = `${HOME}/ClassAttendance`;
export const CLASSRUBRICS = `${HOME}/ClassRubrics`;
export const VIEWRUBRIC = `${HOME}/ViewRubric`;
export const ADDNEWRUBRICS = `${HOME}/AddNewRubrics`;
export const CLASSLESSONPLAN = `${HOME}/ClassLessonPlan`;
export const ADDNEWLESSONPLAN = `${HOME}/AddNewLessonPlan`;
export const ATTENDANCE = `${HOME}/Attendance`;

export const CLASSTAG = `${CLASS}/tag`;
export const CLASSROSTERSELECT = `${CLASS}/selectRoster`;

// ------------CLASS-----

export const AllSTANDARDS = `${HOME}/allStandards`

export const DATAUSAGE = `${HOME}/dataUsage`;

export const UPGRADEPLAN = `${HOME}/upgradePlan`;

export const SUBSCRIPTIONS = `${HOME}/subscriptions`;

export const SETTINGS = `${HOME}/settings`;

export const BLOG = `${HOME}/blog`;
export const BLOGDETAILS = `${BLOG}/blogDetails/`;


export const ABOUT = `${HOME}/about`;

export const TERMS = `${HOME}/terms`;

export const CONTACTUS = `${HOME}/contactUs`;
export const PAYPAL = `${HOME}/paypal`;

export const FAQ = `${HOME}/faq`;






