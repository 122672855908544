import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getViewLessonPlanList: ["data"],
  getViewLessonPlanListSuccess: ["data"],
  getViewLessonPlanListFailure: ["error"],

  addLessonPlan: ["data"],
  addLessonPlanSuccess: ["data"],
  addLessonPlanFailure: ["error"],

  getViewQuestionList: ["data"],
  getViewQuestionListSuccess: ["data"],
  getViewQuestionListFailure: ["error"],

  copyLessonPlan: ["data"],
  copyLessonPlanSuccess: ["data"],
  copyLessonPlanFailure: ["error"],

  deleteLessonPlan: ["data"],
  deleteLessonPlanSuccess: ["data"],
  deleteLessonPlanFailure: ["error"],

  viewLessonPlanDetails: ["data"],
  viewLessonPlanDetailsSuccess: ["data"],
  viewLessonPlanDetailsFailure: ["error"],

  saveExecutionLessonPlan: ["data"],
  saveExecutionLessonPlanSuccess: ["data"],
  saveExecutionLessonPlanFailure: ["error"],

  saveEvaluationLessonPlan: ["data"],
  saveEvaluationLessonPlanSuccess: ["data"],
  saveEvaluationLessonPlanFailure: ["error"],

  deleteDynamicQuestionLessonPlan: ["data"],
  deleteDynamicQuestionLessonPlanSuccess: ["data"],
  deleteDynamicQuestionLessonPlanFailure: ["error"],

  getViewImportLessonPlanList: ["data"],
  getViewImportLessonPlanListSuccess: ["data"],
  getViewImportLessonPlanListFailure: ["error"],

  getImportTopicList: ["data"],
  getImportTopicListSuccess: ["data"],
  getImportTopicListFailure: ["error"],

  saveImportLessonPlanRating: ["data"],
  saveImportLessonPlanRatingSuccess: ["data"],
  saveImportLessonPlanRatingFailure: ["error"],

  shareLessonPlan: ["data"],
  shareLessonPlanSuccess: ["data"],
  shareLessonPlanFailure: ["error"],

  getLessonPlanSchoolTeacher: ["data"],
  getLessonPlanSchoolTeacherSuccess: ["data"],
  getLessonPlanSchoolTeacherFailure: ["error"],

  importLessonPlan: ["data"],
  importLessonPlanSuccess: ["data"],
  importLessonPlanFailure: ["error"],

  setClassLessonPlanState: ["data"],

  getLpText: ["data"],
  getLpTextError: ["error"],
  getLpTextSuccess: ["data"],
});

export const UserLessonPlanReducer = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  Get_Lesson_Plan_List: null,
  Get_Question_List: null,
  Add_Lesson_Plan: null,
  Copy_Lesson_Plan: null,
  Delete_Lesson_Plan: null,
  View_Lesson_Plan_Details: null,
  Save_Execution_Lesson_Plan: null,
  Save_Evaluation_Lesson_Plan: null,
  Delete_Dynamic_Question_Lesson_Plan: null,
  Get_View_Import_Lesson_Plan_List: null,
  Get_Import_Topic_List: null,
  Save_Import_Lesson_Plan_Rating: null,
  Share_Lesson_Plan: null,
  Get_Teacher_List_Lesson_Plan: null,
  Import_Lesson_Plan: null,
  error: null,
  exists: null,
  loader: null,
  speedTagloader: null,
  action: null,
});

/* ------------- Reducers ------------- */

export const setClassLessonPlanState = (state) => {
  return {
    ...state,
    Get_Lesson_Plan_List: null,
    Get_Question_List: null,
    Add_Lesson_Plan: null,
    Copy_Lesson_Plan: null,
    Delete_Lesson_Plan: null,
    View_Lesson_Plan_Details: null,
    Save_Execution_Lesson_Plan: null,
    Save_Evaluation_Lesson_Plan: null,
    Delete_Dynamic_Question_Lesson_Plan: null,
    Get_View_Import_Lesson_Plan_List: null,
    Get_Import_Topic_List: null,
    Save_Import_Lesson_Plan_Rating: null,
    Share_Lesson_Plan: null,
    Get_Teacher_List_Lesson_Plan: null,
    Import_Lesson_Plan: null,
    error: null,
    exists: null,
    loader: null,
    speedTagloader: null,
    action: null,
    get_lesson_plan_text: null,
    get_lesson_plan_text_error: null,
    get_lesson_plan_text_exists: false,
    get_lesson_plan_text_loader: false,
    get_lesson_plan_text_success: null,
  };
};

export const getViewLessonPlanList = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    Get_Lesson_Plan_List: null,
    loader: true,
    get_lesson_plan_text: null,
    get_lesson_plan_text_error: null,
    get_lesson_plan_text_exists: false,
    get_lesson_plan_text_loader: true,
    get_lesson_plan_text_success: null,
  };
};

export const getViewLessonPlanListSuccess = (state, action) => {
  const { data } = action;
  return {
    ...state,
    fetching: false,
    error: null,
    Get_Lesson_Plan_List: data.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};

export const getViewLessonPlanListFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error: error,
    exists: false,
    action: "GET_VIEW_LESSON_PLAN_LIST_FAILURE",
    loader: false,
  };
};

export const addLessonPlan = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    Add_Lesson_Plan: null,
    loader: true,
  };
};

export const addLessonPlanSuccess = (state, action) => {
  // const { data } = action
  return {
    ...state,
    fetching: false,
    error: null,
    Add_Lesson_Plan: action.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};

export const addLessonPlanFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error: error,
    exists: false,
    action: "ADD_LESSON_PLAN_FAILURE",
    loader: false,
  };
};
export const getViewQuestionList = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    Get_Question_List: null,
    loader: true,
  };
};

export const getViewQuestionListSuccess = (state, action) => {
  // const { data } = action
  return {
    ...state,
    fetching: false,
    error: null,
    Get_Question_List: action.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};

export const getViewQuestionListFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error: error,
    exists: false,
    action: "GET_VIEW_QUESTION_LIST_FAILURE",
    loader: false,
  };
};

export const copyLessonPlan = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    Copy_Lesson_Plan: null,
    loader: true,
  };
};

export const copyLessonPlanSuccess = (state, action) => {
  // const { data } = action
  return {
    ...state,
    fetching: false,
    error: null,
    Copy_Lesson_Plan: action.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};

export const copyLessonPlanFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error: error,
    exists: false,
    action: "COPY_LESSON_PLAN_FAILURE",
    loader: false,
  };
};

export const deleteLessonPlan = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    Delete_Lesson_Plan: null,
    loader: true,
  };
};

export const deleteLessonPlanSuccess = (state, action) => {
  // const { data } = action
  return {
    ...state,
    fetching: false,
    error: null,
    Delete_Lesson_Plan: action.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};

export const deleteLessonPlanFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error: error,
    exists: false,
    action: "DELETE_LESSON_PLAN_FAILURE",
    loader: false,
  };
};

export const viewLessonPlanDetails = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    View_Lesson_Plan_Details: null,
    loader: true,
  };
};

export const viewLessonPlanDetailsSuccess = (state, action) => {
  // const { data } = action

  return {
    ...state,
    fetching: false,
    error: null,
    View_Lesson_Plan_Details: action.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};

export const viewLessonPlanDetailsFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error: error,
    exists: false,
    action: "VIEW_LESSON_PLAN_DETAILS_FAILURE",
    loader: false,
  };
};

export const saveExecutionLessonPlan = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    Save_Execution_Lesson_Plan: null,
    loader: true,
  };
};

export const saveExecutionLessonPlanSuccess = (state, action) => {
  // const { data } = action
  return {
    ...state,
    fetching: false,
    error: null,
    Save_Execution_Lesson_Plan: action.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};

export const saveExecutionLessonPlanFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error: error,
    exists: false,
    action: "SAVE_EXECUTION_LESSON_PLAN_FAILURE",
    loader: false,
  };
};

export const saveEvaluationLessonPlan = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    Save_Evaluation_Lesson_Plan: null,
    loader: true,
  };
};

export const saveEvaluationLessonPlanSuccess = (state, action) => {
  // const { data } = action
  return {
    ...state,
    fetching: false,
    error: null,
    Save_Evaluation_Lesson_Plan: action.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};

export const saveEvaluationLessonPlanFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error: error,
    exists: false,
    action: "SAVE_EVALUATION_LESSON_PLAN_FAILURE",
    loader: false,
  };
};
// ----------deleteDynamicQuestionLessonPlan-------------

export const deleteDynamicQuestionLessonPlan = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    Delete_Dynamic_Question_Lesson_Plan: null,
    loader: true,
  };
};

export const deleteDynamicQuestionLessonPlanSuccess = (state, action) => {
  // const { data } = action
  return {
    ...state,
    fetching: false,
    error: null,
    Delete_Dynamic_Question_Lesson_Plan: action.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};

export const deleteDynamicQuestionLessonPlanFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error: error,
    exists: false,
    action: "DELETE_DYNAMIC_QUESTION_LESSON_PLAN_FAILURE",
    loader: false,
  };
};
// ----------Import Lesson Plan-------------
export const getViewImportLessonPlanList = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    Get_View_Import_Lesson_Plan_List: null,
    loader: true,
  };
};

export const getViewImportLessonPlanListSuccess = (state, action) => {
  // const { data } = action
  return {
    ...state,
    fetching: false,
    error: null,
    Get_View_Import_Lesson_Plan_List: action.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};

export const getViewImportLessonPlanListFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error: error,
    exists: false,
    action: "GET_VIEW_IMPORT_LESSON_PLAN_LIST_FAILURE",
    loader: false,
  };
};

export const getImportTopicList = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    Get_Import_Topic_List: null,
    loader: true,
  };
};

export const getImportTopicListSuccess = (state, action) => {
  // const { data } = action
  return {
    ...state,
    fetching: false,
    error: null,
    Get_Import_Topic_List: action.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};

export const getImportTopicListFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error: error,
    exists: false,
    action: "GET_IMPORT_TOPIC_LIST_FAILURE",
    loader: false,
  };
};

export const saveImportLessonPlanRating = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    Save_Import_Lesson_Plan_Rating: null,
    loader: true,
  };
};

export const saveImportLessonPlanRatingSuccess = (state, action) => {
  // const { data } = action
  return {
    ...state,
    fetching: false,
    error: null,
    Save_Import_Lesson_Plan_Rating: action.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};

export const saveImportLessonPlanRatingFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error: error,
    exists: false,
    action: "SAVE_IMPORT_LESSON_PLAN_RATING_FAILURE",
    loader: false,
  };
};

export const importLessonPlan = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    Import_Lesson_Plan: null,
    loader: true,
  };
};

export const importLessonPlanSuccess = (state, action) => {
  // const { data } = action
  return {
    ...state,
    fetching: false,
    error: null,
    Import_Lesson_Plan: action.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};

export const importLessonPlanFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error: error,
    exists: false,
    action: "IMPORT_LESSON_PLAN_FAILURE",
    loader: false,
  };
};

export const shareLessonPlan = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    Share_Lesson_Plan: null,
    loader: true,
  };
};

export const shareLessonPlanSuccess = (state, action) => {
  // const { data } = action
  return {
    ...state,
    fetching: false,
    error: null,
    Share_Lesson_Plan: action.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};

export const shareLessonPlanFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error: error,
    exists: false,
    action: "SHARE_LESSON_PLAN_FAILURE",
    loader: false,
  };
};
export const getLessonPlanSchoolTeacher = (state) => {
  return {
    ...state,
    fetching: true,
    error: null,
    Get_Teacher_List_Lesson_Plan: null,
    loader: true,
  };
};

export const getLessonPlanSchoolTeacherSuccess = (state, action) => {
  // const { data } = action

  return {
    ...state,
    fetching: false,
    error: null,
    Get_Teacher_List_Lesson_Plan: action.data,
    action: action.type,
    exists: true,
    loader: false,
  };
};

export const getLessonPlanSchoolTeacherFailure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    error: error,
    exists: false,
    action: "GET_LESSON_PLAN_SCHOOL_TEACHER_FAILURE",
    loader: false,
  };
};

export const getLpText = (state) => {
  return {
    ...state,
    get_lesson_plan_text: null,
    get_lesson_plan_text_error: null,
    get_lesson_plan_text_exists: false,
    get_lesson_plan_text_loader: true,
    get_lesson_plan_text_success: null,
  };
};

export const getLpTextError = (state, action) => {
  return {
    ...state,
    get_lesson_plan_text: null,
    get_lesson_plan_text_error: action?.data,
    get_lesson_plan_text_exists: false,
    get_lesson_plan_text_loader: false,
    get_lesson_plan_text_success: null,
  };
};

export const getLpTextSuccess = (state, action) => {
  return {
    ...state,
    get_lesson_plan_text: null,
    get_lesson_plan_text_error: null,
    get_lesson_plan_text_exists: true,
    get_lesson_plan_text_loader: false,
    get_lesson_plan_text_success: action?.data,
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_VIEW_LESSON_PLAN_LIST]: getViewLessonPlanList,
  [Types.GET_VIEW_LESSON_PLAN_LIST_SUCCESS]: getViewLessonPlanListSuccess,
  [Types.GET_VIEW_LESSON_PLAN_LIST_FAILURE]: getViewLessonPlanListFailure,

  [Types.ADD_LESSON_PLAN]: addLessonPlan,
  [Types.ADD_LESSON_PLAN_SUCCESS]: addLessonPlanSuccess,
  [Types.ADD_LESSON_PLAN_FAILURE]: addLessonPlanFailure,

  [Types.GET_VIEW_QUESTION_LIST]: getViewQuestionList,
  [Types.GET_VIEW_QUESTION_LIST_SUCCESS]: getViewQuestionListSuccess,
  [Types.GET_VIEW_QUESTION_LIST_FAILURE]: getViewQuestionListFailure,

  [Types.COPY_LESSON_PLAN]: copyLessonPlan,
  [Types.COPY_LESSON_PLAN_SUCCESS]: copyLessonPlanSuccess,
  [Types.COPY_LESSON_PLAN_FAILURE]: copyLessonPlanFailure,

  [Types.DELETE_LESSON_PLAN]: deleteLessonPlan,
  [Types.DELETE_LESSON_PLAN_SUCCESS]: deleteLessonPlanSuccess,
  [Types.DELETE_LESSON_PLAN_FAILURE]: deleteLessonPlanFailure,

  [Types.VIEW_LESSON_PLAN_DETAILS]: viewLessonPlanDetails,
  [Types.VIEW_LESSON_PLAN_DETAILS_SUCCESS]: viewLessonPlanDetailsSuccess,
  [Types.VIEW_LESSON_PLAN_DETAILS_FAILURE]: viewLessonPlanDetailsFailure,

  [Types.SAVE_EXECUTION_LESSON_PLAN]: saveExecutionLessonPlan,
  [Types.SAVE_EXECUTION_LESSON_PLAN_SUCCESS]: saveExecutionLessonPlanSuccess,
  [Types.SAVE_EXECUTION_LESSON_PLAN_FAILURE]: saveExecutionLessonPlanFailure,

  [Types.SAVE_EVALUATION_LESSON_PLAN]: saveEvaluationLessonPlan,
  [Types.SAVE_EVALUATION_LESSON_PLAN_SUCCESS]: saveEvaluationLessonPlanSuccess,
  [Types.SAVE_EVALUATION_LESSON_PLAN_FAILURE]: saveEvaluationLessonPlanFailure,

  [Types.DELETE_DYNAMIC_QUESTION_LESSON_PLAN]: deleteDynamicQuestionLessonPlan,
  [Types.DELETE_DYNAMIC_QUESTION_LESSON_PLAN_SUCCESS]:
    deleteDynamicQuestionLessonPlanSuccess,
  [Types.DELETE_DYNAMIC_QUESTION_LESSON_PLAN_FAILURE]:
    deleteDynamicQuestionLessonPlanFailure,

  [Types.GET_VIEW_IMPORT_LESSON_PLAN_LIST]: getViewImportLessonPlanList,
  [Types.GET_VIEW_IMPORT_LESSON_PLAN_LIST_SUCCESS]:
    getViewImportLessonPlanListSuccess,
  [Types.GET_VIEW_IMPORT_LESSON_PLAN_LIST_FAILURE]:
    getViewImportLessonPlanListFailure,

  [Types.GET_IMPORT_TOPIC_LIST]: getImportTopicList,
  [Types.GET_IMPORT_TOPIC_LIST_SUCCESS]: getImportTopicListSuccess,
  [Types.GET_IMPORT_TOPIC_LIST_FAILURE]: getImportTopicListFailure,

  [Types.SAVE_IMPORT_LESSON_PLAN_RATING]: saveImportLessonPlanRating,
  [Types.SAVE_IMPORT_LESSON_PLAN_RATING_SUCCESS]:
    saveImportLessonPlanRatingSuccess,
  [Types.SAVE_IMPORT_LESSON_PLAN_RATING_FAILURE]:
    saveImportLessonPlanRatingFailure,

  [Types.GET_LESSON_PLAN_SCHOOL_TEACHER]: getLessonPlanSchoolTeacher,
  [Types.GET_LESSON_PLAN_SCHOOL_TEACHER_SUCCESS]:
    getLessonPlanSchoolTeacherSuccess,
  [Types.GET_LESSON_PLAN_SCHOOL_TEACHER_FAILURE]:
    getLessonPlanSchoolTeacherFailure,

  [Types.SHARE_LESSON_PLAN]: shareLessonPlan,
  [Types.SHARE_LESSON_PLAN_SUCCESS]: shareLessonPlanSuccess,
  [Types.SHARE_LESSON_PLAN_FAILURE]: shareLessonPlanFailure,

  [Types.IMPORT_LESSON_PLAN]: importLessonPlan,
  [Types.IMPORT_LESSON_PLAN_SUCCESS]: importLessonPlanSuccess,
  [Types.IMPORT_LESSON_PLAN_FAILURE]: importLessonPlanFailure,

  [Types.SET_CLASS_LESSON_PLAN_STATE]: setClassLessonPlanState,

  [Types.GET_LP_TEXT]: getLpText,
  [Types.GET_LP_TEXT_ERROR]: getLpTextError,
  [Types.GET_LP_TEXT_SUCCESS]: getLpTextSuccess,
});
