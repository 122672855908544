import React, { useEffect, useState } from 'react';
import { csv } from 'csvtojson';
import axios from 'axios';
import './CsvView.scss';

const CsvView = (props) => {
    const { showCsvUrl } = props;
    const [csvData, setCsvData] = useState(null);

    const getFile = async () => {
        try {
            let res = await axios.get(showCsvUrl);
            csv({
                noheader: true,
                output: "csv"
            })
                .fromString(res.data)
                .then((csvRow) => {
                    setCsvData(csvRow);
                });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getFile();
    }, [])

    useEffect(() => {
        
        if(!csvData) {
            getFile()
        }
    },[csvData])

    return (
        <div className="csvContainer">
            <p className="reportTitle">Csv report</p>
            <table style={{ width: '100%', minWidth: '400px' }}>
                <tbody>
                    {csvData ?
                        csvData.map((row, i) => {
                            return (
                                <tr key={i}>
                                    {
                                        row.map((col, x) => {
                                            return (
                                                <td key={x}>
                                                    <p>{col}</p>
                                                </td>
                                            );
                                        })
                                    }
                                </tr>
                            );
                        })
                        : ""}
                </tbody>
            </table>
        </div>
    );
}
export default CsvView;