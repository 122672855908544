import React from 'react';
import { connect } from 'react-redux';
import FAQDashboard from './FAQDashboardComponent/FAQDashboard';


const FAQIndex = (props) => {
    return (
        <FAQDashboard {...props} />
    )
}
const mapStateToProps = state => ({
    state: state,

});
const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    FAQIndex
);