import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getDataUsageRequest: ['data'],
    getDataUsageSuccess: ['data'],
    getDataUsageFailure: ['error'],

})

export const userDataUsageReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    DataUsage: [],
    error: null,
    exists: null,
    loader: null,
    action: null
})

/* ------------- Reducers ------------- */

/* Get Blog Request */
export const getDataUsageRequest = (state) => {
    return { ...state, fetching: true, error: null, loader: true }
}
export const getDataUsageSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, loader: false, DataUsage: data.data }
}
export const getDataUsageFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_DATA_USAGE_FAILURE', loader: false }
}



/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.GET_DATA_USAGE_REQUEST]: getDataUsageRequest,
    [Types.GET_DATA_USAGE_SUCCESS]: getDataUsageSuccess,
    [Types.GET_DATA_USAGE_FAILURE]: getDataUsageFailure,



})
