import {

    GET_STUDENT_GRADEBOOK_REQUEST,
    GET_STUDENT_GRADEBOOK_SUCCESS,
    GET_STUDENT_GRADEBOOK_FAILURE,

    UPDATE_STUDENT_GRADEBOOK_REQUEST,
    UPDATE_STUDENT_GRADEBOOK_SUCCESS,
    UPDATE_STUDENT_GRADEBOOK_FAILURE,


    UPDATE_STUDENT_GRADEBOOK_AVERAGE_REQUEST,

    UPDATE_STUDENT_GRADEBOOK_CHECKBOX_REQUEST,

    UPDATE_STUDENT_GRADEBOOK_INPUT_REQUEST,

    UPDATE_STUDENT_GRADEBOOK_COLOR_REQUEST,

    GET_STUDENT_GRADEBOOK_NULL_REQUEST,



} from '../constants';

// -----------Get Student Gradebook-------------
export function getStudentGradebookRequest(data) {
    return {
        type: GET_STUDENT_GRADEBOOK_REQUEST,
        data,
    };
}

export function getStudentGradebookSuccess(data) {
    return {
        type: GET_STUDENT_GRADEBOOK_SUCCESS,
        data,
    };
}

export function getStudentGradebookFailure(data) {
    return {
        type: GET_STUDENT_GRADEBOOK_FAILURE,
        data,
    };
}

// -----------Update Student Gradebook-------------
export function updateStudentGradebookRequest(data) {
    return {
        type: UPDATE_STUDENT_GRADEBOOK_REQUEST,
        data,
    };
}

export function updateStudentGradebookSuccess(data) {
    return {
        type: UPDATE_STUDENT_GRADEBOOK_SUCCESS,
        data,
    };
}

export function updateStudentGradebookFailure(data) {
    return {
        type: UPDATE_STUDENT_GRADEBOOK_FAILURE,
        data,
    };
}


// -----------Get Student Gradebook Average-------------
export function UpdateStudentGradebookAverageRequest(data) {
    return {
        type: UPDATE_STUDENT_GRADEBOOK_AVERAGE_REQUEST,
        data,
    };
}

// -----------Get Student Gradebook Checkbox-------------
export function UpdateStudentGradebookCheckboxRequest(data) {
    return {
        type: UPDATE_STUDENT_GRADEBOOK_CHECKBOX_REQUEST,
        data,
    };
}


// -----------Get Student Gradebook Input-------------
export function UpdateStudentGradebookInputRequest(data) {
    return {
        type: UPDATE_STUDENT_GRADEBOOK_INPUT_REQUEST,
        data,
    };
}


// -----------Get Student Gradebook Color-------------
export function UpdateStudentGradebookColorRequest(data) {
    return {
        type: UPDATE_STUDENT_GRADEBOOK_COLOR_REQUEST,
        data,
    };
}


export function getStudentGradebookNullRequest(data) {
    return {
        type: GET_STUDENT_GRADEBOOK_NULL_REQUEST,
        data
    }
}
