

import {
    ADD_ROSTER,
    ADD_ROSTER_SUCCESS,
    ADD_ROSTER_FAILURE,


    GET_ROSTER_LIST,
    GET_ROSTER_LIST_SUCCESS,
    GET_ROSTER_LIST_FAILURE,

    DELETE_ROSTER,
    DELETE_ROSTER_SUCCESS,
    DELETE_ROSTER_FAILURE,

    SEARCH_ROSTER,
    SEARCH_ROSTER_SUCCESS,
    SEARCH_ROSTER_FAILURE,

    IMPORT_ROSTER,
    IMPORT_ROSTER_SUCCESS,
    IMPORT_ROSTER_FAILURE,

    SET_ROSTER_SIDEBAR_OPEN,
    SET_ROSTER_STATE

} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */

// -----------add Roster-------------
export function addRoster(data) {
    return {
        type: ADD_ROSTER,
        data,
    };
}

export function addRosterSuccess(data) {
    return {
        type: ADD_ROSTER_SUCCESS,
        data,
    };
}

export function addRosterFailure(data) {
    return {
        type: ADD_ROSTER_FAILURE,
        data,
    };
}


// -----------Get RosterList-------------
export function getRosterList(data) {
    return {
        type: GET_ROSTER_LIST,
        data,
    };
}

export function getRosterListSuccess(data) {
    return {
        type: GET_ROSTER_LIST_SUCCESS,
        data,
    };
}

export function getRosterListFailure(data) {
    return {
        type: GET_ROSTER_LIST_FAILURE,
        data,
    };
}


// -----------delete Roster-------------
export function deleteRoster(data) {
    return {
        type: DELETE_ROSTER,
        data,
    };
}

export function deleteRosterSuccess(data) {
    return {
        type: DELETE_ROSTER_SUCCESS,
        data,
    };
}

export function deleteRosterFailure(data) {
    return {
        type: DELETE_ROSTER_FAILURE,
        data,
    };
}


// -----------Search RosterList-------------
export function searchRoster(data) {
    return {
        type: SEARCH_ROSTER,
        data,
    };
}

export function searchRosterSuccess(data) {
    return {
        type: SEARCH_ROSTER_SUCCESS,
        data,
    };
}

export function searchRosterFailure(data) {
    return {
        type: SEARCH_ROSTER_FAILURE,
        data,
    };
}


// -----------import Roster -------------
export function importRoster(data) {
    return {
        type: IMPORT_ROSTER,
        data,
    };
}

export function importRosterSuccess(data) {
    return {
        type: IMPORT_ROSTER_SUCCESS,
        data,
    };
}

export function importRosterFailure(data) {
    return {
        type: IMPORT_ROSTER_FAILURE,
        data,
    };
}



export function setRosterSidebarOpen(data) {
    return {
        type: SET_ROSTER_SIDEBAR_OPEN,
        data,
    };
}

export function setRosterState(data) {
    return {
        type : SET_ROSTER_STATE,
        data,
    };
}