import {

    GET_GROUP_DETAILS_REQUEST,
    GET_GROUP_DETAILS_SUCCESS,
    GET_GROUP_DETAILS_FAILURE,

    ADD_MULTIPLE_STUDENTS_REQUEST,
    ADD_MULTIPLE_STUDENTS_SUCCESS,
    ADD_MULTIPLE_STUDENTS_FAILURE,

    DELETE_GROUP_REQUEST,
    DELETE_GROUP_SUCCESS,
    DELETE_GROUP_FAILURE,

    ADD_NEW_STUDENT_IN_GROUP_REQUEST,
    ADD_NEW_STUDENT_IN_GROUP_SUCCESS,
    ADD_NEW_STUDENT_IN_GROUP_FAILURE,

    REMOVE_STUDENT_FROM_GROUP_REQUEST,
    REMOVE_STUDENT_FROM_GROUP_SUCCESS,
    REMOVE_STUDENT_FROM_GROUP_FAILURE,

    RENAME_GROUP_REQUEST,
    RENAME_GROUP_SUCCESS,
    RENAME_GROUP_FAILURE,

    CREATE_GROUP_REQUEST,
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_FAILURE,
    SET_GROUP_NOTES_STATE

} from '../constants';

// -----------Get Group Details-------------
export function getGroupDetailsRequest(data) {
    return {
        type: GET_GROUP_DETAILS_REQUEST,
        data,
    };
}

export function getGroupDetailsSuccess(data, page) {
    return {
        type: GET_GROUP_DETAILS_SUCCESS,
        data,
        page,
    };
}

export function getGroupDetailsFailure(data) {
    return {
        type: GET_GROUP_DETAILS_FAILURE,
        data,
    };
}


// -----------Add Multiple Students Request-------------
export function addMultipleStudentsRequest(data) {
    return {
        type: ADD_MULTIPLE_STUDENTS_REQUEST,
        data,
    };
}

export function addMultipleStudentsSuccess(data, page) {
    return {
        type: ADD_MULTIPLE_STUDENTS_SUCCESS,
        data,
        page,
    };
}

export function addMultipleStudentsFailure(data) {
    return {
        type: ADD_MULTIPLE_STUDENTS_FAILURE,
        data,
    };
}

// -----------Delete Group Request-------------
export function deleteGroupRequest(data) {
    return {
        type: DELETE_GROUP_REQUEST,
        data,
    };
}

export function deleteGroupSuccess(data, page) {
    return {
        type: DELETE_GROUP_SUCCESS,
        data,
        page,
    };
}

export function deleteGroupFailure(data) {
    return {
        type: DELETE_GROUP_FAILURE,
        data,
    };
}
// -----------add New Student In Group Request-------------
export function addNewStudentInGroupRequest(data) {
    return {
        type: ADD_NEW_STUDENT_IN_GROUP_REQUEST,
        data,
    };
}

export function addNewStudentInGroupSuccess(data, page) {
    return {
        type: ADD_NEW_STUDENT_IN_GROUP_SUCCESS,
        data,
        page,
    };
}

export function addNewStudentInGroupFailure(data) {
    return {
        type: ADD_NEW_STUDENT_IN_GROUP_FAILURE,
        data,
    };
}

// -----------remove Student From Group Request-------------
export function removeStudentFromGroupRequest(data) {
    return {
        type: REMOVE_STUDENT_FROM_GROUP_REQUEST,
        data,
    };
}

export function removeStudentFromGroupSuccess(data, page) {
    return {
        type: REMOVE_STUDENT_FROM_GROUP_SUCCESS,
        data,
        page,
    };
}

export function removeStudentFromGroupFailure(data) {
    return {
        type: REMOVE_STUDENT_FROM_GROUP_FAILURE,
        data,
    };
}

// -----------renameGroup Request-------------
export function renameGroupRequest(data) {
    return {
        type: RENAME_GROUP_REQUEST,
        data,
    };
}

export function renameGroupSuccess(data, page) {
    return {
        type: RENAME_GROUP_SUCCESS,
        data,
        page,
    };
}

export function renameGroupFailure(data) {
    return {
        type: RENAME_GROUP_FAILURE,
        data,
    };
}

// -----------create Group Request-------------
export function createGroupRequest(data) {
    return {
        type: CREATE_GROUP_REQUEST,
        data,
    };
}

export function createGroupSuccess(data, page) {
    return {
        type: CREATE_GROUP_SUCCESS,
        data,
        page,
    };
}

export function createGroupFailure(data) {
    return {
        type: CREATE_GROUP_FAILURE,
        data,
    };
}

export function setGroupNotesState(data) {
    return {
        type: SET_GROUP_NOTES_STATE,
        data
    }
}