import { call, takeLatest, put } from 'redux-saga/effects';
import UserFormativeAssessmentReducer from 'Redux/Reducers/FormativeAssessmentReducer/FormativeAssessmentReducer';
import { createNotification } from 'Config/notificationtoast';
import {
    GET_FORMATIVE_ASSESSMENT_REQUEST,
    GET_ASSESSMENT_STUDENTS_LIST_REQUEST,
    GET_ALL_STUDENTS_LIST_REQUEST,
    ADD_ASSESSMENT_TAG_REQUEST,
    EDIT_ASSESSMENT_TAG_REQUEST,
    DELETE_ASSESSMENT_TAG_REQUEST
} from 'Actions/constants';
import {
    GetAssessment_tag_API,
    GetAssessment_tag_student_API,
    AddAssessment_tag_API,
    EditAssessment_tag_API,
    DeleteAssessment_tag_API,
    GetRosterWithoutClass_API,
} from 'Config/Api';
import { chroniclecloud_code } from 'Config/Constant';
import request from 'Config/request';
import { logOut } from 'Utils/Helper';
import { getRosterImage } from "Config/commonFirebaseImage";
import { decryptData, encryptData } from 'Utils/Helper';

export function* userFormativeAssessmentModuleSaga() {
    yield takeLatest(GET_FORMATIVE_ASSESSMENT_REQUEST, getFormativeAssessmentRequest);
    yield takeLatest(GET_ASSESSMENT_STUDENTS_LIST_REQUEST, getAssessmentStudentsListRequest);
    yield takeLatest(GET_ALL_STUDENTS_LIST_REQUEST, getAllStudentsListRequest);
    yield takeLatest(ADD_ASSESSMENT_TAG_REQUEST, addAssessmentTagRequest);
    yield takeLatest(EDIT_ASSESSMENT_TAG_REQUEST, editAssessmentTagRequest);
    yield takeLatest(DELETE_ASSESSMENT_TAG_REQUEST, deleteAssessmentTagRequest);
}

// ---------- Get Assessment Tag-----
function* getFormativeAssessmentRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${GetAssessment_tag_API}?user_id=${data.cc_user_id}&class_id=${action.data.classId}&platform=web`;

    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            
            yield put(UserFormativeAssessmentReducer.getFormativeAssessmentSuccess(MainDecode));
            if (MainDecode?.data?.assessment_details && MainDecode?.data?.assessment_details.length > 0) {

                const APIData = {
                    "page_no": "1",
                    "assessment_tag_id": MainDecode?.data.assessment_details[0].assessment_tag_id
                }
                yield put(UserFormativeAssessmentReducer.getAssessmentStudentsListRequest(APIData));
            }
            else {

                const APIData = {
                    "page_no": "full",
                    "academic_year": action.data.academicYearId,
                    "class_id": action.data.classId,
                    "filter_by": "both",
                    "sort_by": "sort_by_none"
                }
                yield put(UserFormativeAssessmentReducer.getAllStudentsListRequest(APIData));
            }
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserFormativeAssessmentReducer.getFormativeAssessmentFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserFormativeAssessmentReducer.getFormativeAssessmentFailure(false));

    }
}
// ---------- Get Assessment Tag-----

// ---------- Get Assessment Tag student-----
function* getAssessmentStudentsListRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = GetAssessment_tag_student_API;
    const BODY = {
        "user_id": data.cc_user_id,
        "page_no": "full",
        "assessment_tag_id": action.data.assessment_tag_id,
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response?.res);
        if (MainDecode.status === 200) {

            let Data = MainDecode?.data?.final_result;

            // yield put(UserFormativeAssessmentReducer.getAssessmentStudentsListSuccess(MainDecode));
            for (let rosterItem of Data[0]) {
                if (rosterItem.cc_student_pic_url) {
                    if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
                        rosterItem.cc_student_pic_url = yield getRosterImage(rosterItem.cc_student_pic_url);
                    }
                }
                let ImageData = MainDecode;
                ImageData.data = Data;
                yield put(UserFormativeAssessmentReducer.getAssessmentStudentsListSuccess(ImageData));
            }
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserFormativeAssessmentReducer.getAssessmentStudentsListFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserFormativeAssessmentReducer.getAssessmentStudentsListFailure(false));

    }
}
// ---------- Get Assessment Tag student-----

// ---------- Get Assessment Tag student-----
function* getAllStudentsListRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))

    const URL = `${GetRosterWithoutClass_API}?user_id=${data.cc_user_id}&academic_year_id=${action.data.academic_year}&class_id=${action.data.class_id}&page_no=${action.data.page_no}&filter_by=${'both'}&platform=web`;

    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
        });
        const MainDecode = decryptData(response.res);

        if (MainDecode.status === 200) {
            yield put(UserFormativeAssessmentReducer.getAllStudentsListSuccess(MainDecode));
            for (let rosterItem of MainDecode?.data?.student_data) {
                if (rosterItem.cc_student_pic_url) {
                    if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
                        rosterItem.cc_student_pic_url = yield getRosterImage(rosterItem.cc_student_pic_url);
                    }
                }
                yield put(UserFormativeAssessmentReducer.getAllStudentsListSuccess(MainDecode));
            }
            let tempArr = { data: MainDecode?.data?.student_data }
            yield put(UserFormativeAssessmentReducer.getAllStudentsListSuccess(tempArr));

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserFormativeAssessmentReducer.getAllStudentsListFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserFormativeAssessmentReducer.getAllStudentsListFailure(false));

    }
}
// ---------- Get Assessment Tag student-----

// ---------- Add Assessment Tag student-----
function* addAssessmentTagRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = AddAssessment_tag_API;
    const BODY = {
        "user_id": data.cc_user_id,
        "class_id": action.data.classId,
        "assessment_tag_name": action.data.assessmentTagName,
        "date": action.data.date,
        "student_details_array": action.data.studentDetailsArray,
        "platform": "web"
    }

    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            // createNotification('success', MainDecode.message);
            yield put(UserFormativeAssessmentReducer.addAssessmentTagSuccess(MainDecode));
            const apiValue = {
                "classId": action.data.classId,
                "academicYearId": action.data.academicYearId,
                "AddTagSuccess" : true
            }
            yield put(UserFormativeAssessmentReducer.getFormativeAssessmentRequest(apiValue));
            // let data ={
            //     "assessment_tag_id": MainDecode?.data,
            // }
            // yield put(UserFormativeAssessmentReducer.getAssessmentStudentsListRequest(data));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserFormativeAssessmentReducer.addAssessmentTagFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserFormativeAssessmentReducer.addAssessmentTagFailure(false));

    }
    // finally {
    //     const apiValue = {
    //         "classId": action.data.classId,
    //         "academicYearId": action.data.academicYearId,
    //     }
    //     // yield put(UserFormativeAssessmentReducer.getFormativeAssessmentRequest(apiValue));
    // }

}
// ---------- Add Assessment Tag student-----

// ---------- Edit Assessment Tag student-----
function* editAssessmentTagRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = EditAssessment_tag_API;
    const BODY = {
        "user_id": data.cc_user_id,
        "class_id": action.data.classId,
        "assessment_tag_name": action.data.assessmentTagName,
        "date": action.data.date,
        "student_details_array": action.data.studentDetailsArray,
        "assessment_tag_id": action.data.assessmentTagId,
        "platform": "web"

    }

    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            createNotification('success', MainDecode.message);
            yield put(UserFormativeAssessmentReducer.editAssessmentTagSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserFormativeAssessmentReducer.editAssessmentTagFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserFormativeAssessmentReducer.editAssessmentTagFailure(false));

    }
    finally {
        const apiValue = {
            "classId": action.data.classId,
            "academicYearId": action.data.academicYearId,
        }
        yield put(UserFormativeAssessmentReducer.getFormativeAssessmentRequest(apiValue));
    }
}
// ---------- Edit Assessment Tag student-----



// ---------- delete Assessment Tag student-----
function* deleteAssessmentTagRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = DeleteAssessment_tag_API;
    const BODY = {
        "user_id": data.cc_user_id,
        "assessment_tag_id": action.data.assessmentTagId,
        "platform": "web"

    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            // createNotification('success', MainDecode.message);
            // const apiValue = {
            //     "classId": action.data.classId,
            //     "academicYearId": action.data.academicYearId,
            // }
            // yield put(UserFormativeAssessmentReducer.getFormativeAssessmentRequest(apiValue));
            yield put(UserFormativeAssessmentReducer.deleteAssessmentTagSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserFormativeAssessmentReducer.deleteAssessmentTagFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserFormativeAssessmentReducer.deleteAssessmentTagFailure(false));
    }
    finally {
        const apiValue = {
            "classId": action.data.classId,
            "academicYearId": action.data.academicYearId,
        }
        yield put(UserFormativeAssessmentReducer.getFormativeAssessmentRequest(apiValue));
    }
}
// ---------- delete Assessment Tag student-----




