import React, {useEffect, useRef, useState, useCallback} from "react";
import './IframeGoogleDoc.scss';

 const IframeGoogleDoc = ({url}) => {

    const [iframeTimeoutId, setIframeTimeoutId] = useState();
  const iframeRef = useRef(null);

  const getIframeLink  = useCallback(() => {
    return `https://docs.google.com/gview?url=${url}&embedded=true`;
  },[url])

  const updateIframeSrc = useCallback(() =>  {
    if (iframeRef.current) {
    iframeRef.current.src = getIframeLink();
  }}, [getIframeLink])


  useEffect(() => {
    const intervalId = setInterval(
      updateIframeSrc, 1000 * 3
    );
    setIframeTimeoutId(intervalId)
  }, [updateIframeSrc])

  function iframeLoaded() {
    clearInterval(iframeTimeoutId);
  }
 
    return (
      <div className="iFrameContainer">
        <iframe
            onLoad={iframeLoaded}
            onError={updateIframeSrc}
            ref={iframeRef}
            src={getIframeLink()}
            frameBorder="0"
            scrolling="auto"
            height="500px"
            width="700px"
        />
        </div>
    );
}
export default IframeGoogleDoc