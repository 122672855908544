



import {
    GET_CLASS_TAG,
    GET_CLASS_TAG_SUCCESS,
    GET_CLASS_TAG_FAILURE,

    ADD_CLASS_TAG,
    ADD_CLASS_TAG_SUCCESS,
    ADD_CLASS_TAG_FAILURE,

    EDIT_CLASS_TAG,
    EDIT_CLASS_TAG_SUCCESS,
    EDIT_CLASS_TAG_FAILURE,

    DELETE_CLASS_TAG,
    DELETE_CLASS_TAG_SUCCESS,
    DELETE_CLASS_TAG_FAILURE,

    GET_SPEED_NOTE,
    GET_SPEED_NOTE_SUCCESS,
    GET_SPEED_NOTE_FAILURE,

    IMPORT_SPEED_NOTE,
    IMPORT_SPEED_NOTE_SUCCESS,
    IMPORT_SPEED_NOTE_FAILURE,
    GET_CLASS_TAG_EDIT,
    GET_CLASS_TAG_SUCCESS_EDIT,
    GET_CLASS_TAG_FAILURE_EDIT,
   
    // GET_NOTE_CORE_STANDARD,
    // GET_NOTE_CORE_STANDARD_SUCCESS,
    // GET_NOTE_CORE_STANDARD_FAILURE,
  

} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */



// -----------GetClass Tag-------------
export function getClassTag(data) {
    return {
        type: GET_CLASS_TAG,
        data,
    };
}

export function getClassTagSuccess(data) {
    return {
        type: GET_CLASS_TAG_SUCCESS,
        data,
    };
}

export function getClassTagFailure(data) {
    return {
        type: GET_CLASS_TAG_FAILURE,
        data,
    };
}

export function getClassTagEdit(data) {
    return {
        type: GET_CLASS_TAG_EDIT,
        data,
    };
}

export function getClassTagSuccessEdit(data) {
    return {
        type: GET_CLASS_TAG_SUCCESS_EDIT,
        data,
    };
}

export function getClassTagFailureEdit(data) {
    return {
        type: GET_CLASS_TAG_FAILURE_EDIT,
        data,
    };
}
// -----------AddClass Tag-------------
export function addClassTag(data) {
    return {
        type: ADD_CLASS_TAG,
        data,
    };
}

export function addClassTagSuccess(data) {
    return {
        type: ADD_CLASS_TAG_SUCCESS,
        data,
    };
}

export function addClassTagFailure(data) {
    return {
        type: ADD_CLASS_TAG_FAILURE,
        data,
    };
}

// -----------UpdateClass Tag-------------
export function editClassTag(data) {
    return {
        type: EDIT_CLASS_TAG,
        data,
    };
}

export function editClassTagSuccess(data) {
    return {
        type: EDIT_CLASS_TAG_SUCCESS,
        data,
    };
}

export function editClassTagFailure(data) {
    return {
        type: EDIT_CLASS_TAG_FAILURE,
        data,
    };
}


// -----------DeleteClass Tag-------------
export function deleteClassTag(data) {
    return {
        type: DELETE_CLASS_TAG,
        data,
    };
}

export function deleteClassTagSuccess(data) {
    return {
        type: DELETE_CLASS_TAG_SUCCESS,
        data,
    };
}

export function deleteClassTagFailure(data) {
    return {
        type: DELETE_CLASS_TAG_FAILURE,
        data,
    };
}


// -----------Get Speed Note-------------
export function getSpeedNote(data) {
    return {
        type: GET_SPEED_NOTE,
        data,
    };
}

export function getSpeedNoteSuccess(data) {
    return {
        type: GET_SPEED_NOTE_SUCCESS,
        data,
    };
}

export function getSpeedNoteFailure(data) {
    return {
        type: GET_SPEED_NOTE_FAILURE,
        data,
    };
}

// export function getNoteCores(data) {
//     return {
//         type: GET_NOTE_CORE_STANDARD,
//         data,
//     };
// }

// export function getNoteCoresSuccess(data) {
//     return {
//         type: GET_NOTE_CORE_STANDARD_SUCCESS,
//         data,
//     };
// }

// export function getNoteCoresFailure(data) {
//     return {
//         type: GET_NOTE_CORE_STANDARD_FAILURE,
//         data,
//     };
// }

// -----------Import Class Speed Note-------------
export function importSpeedNote(data) {
    return {
        type: IMPORT_SPEED_NOTE,
        data,
    };
}

export function importSpeedNoteSuccess(data) {
    return {
        type: IMPORT_SPEED_NOTE_SUCCESS,
        data,
    };
}

export function importSpeedNoteFailure(data) {
    return {
        type: IMPORT_SPEED_NOTE_FAILURE,
        data,
    };
}








