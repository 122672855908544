import { call, takeLatest, put } from 'redux-saga/effects';
import UserRosterProfileReducer from 'Redux/Reducers/RosterReducer/rosterProfileReducer';
import UserLoadingReducer from 'Redux/Reducers/CommonReducer/loadingReducer';
import { createNotification } from 'Config/notificationtoast';
import {
    GET_ROSTER, EDIT_ROSTER,
    ADD_ROSTER_CONTACT,
    EDIT_ROSTER_CONTACT,
    DELETE_ROSTER_CONTACT,
    ADD_ROSTER_INFO,
    EDIT_ROSTER_INFO,
    DELETE_ROSTER_INFO,
    ADD_LINK_ROSTER
} from 'Actions/constants';
import {
    GetRosterProfile_API,
    EditRosterProfile_API,

    AddRosterContact_API,
    EditRosterContact_API,
    DeleteRosterContact_API,

    AddRosterInfo_API,
    EditRosterInfo_API,
    DeleteRosterInfo_API,

    LinkRosterWithParent_API,

} from 'Config/Api';
import { chroniclecloud_code } from 'Config/Constant';
import { decryptData, encryptData, logOut } from 'Utils/Helper';
import request from 'Config/request';


export function* userRosterProfileModuleSaga() {
    yield takeLatest(GET_ROSTER, getRoster);
    yield takeLatest(EDIT_ROSTER, editRoster);

    // -------Add Contact Section---------
    yield takeLatest(ADD_ROSTER_CONTACT, addRosterContact);
    yield takeLatest(EDIT_ROSTER_CONTACT, editRosterContact);
    yield takeLatest(DELETE_ROSTER_CONTACT, deleteRosterContact);

    // -------Add Info Section---------
    yield takeLatest(ADD_ROSTER_INFO, addRosterInfo);
    yield takeLatest(EDIT_ROSTER_INFO, editRosterInfo);
    yield takeLatest(DELETE_ROSTER_INFO, deleteRosterInfo);

    // -------Link---------
    yield takeLatest(ADD_LINK_ROSTER, addLinkRoster);

}

function* getRoster(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    yield put(UserLoadingReducer.loading(true));
    const URL = `${GetRosterProfile_API}?user_id=${data.cc_user_id}&student_id=${action.data.student_id}&platform=web`;
    const BODY = JSON.stringify({
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "student_id": action.data.student_id,

    })
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            yield put(UserRosterProfileReducer.getRosterSuccess(MainDecode));
            yield put(UserLoadingReducer.loadingSuccess(false));

        } else {
            createNotification('error', MainDecode.message);
            yield put(UserRosterProfileReducer.getRosterFailure(MainDecode));
            yield put(UserLoadingReducer.loadingSuccess(false));
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRosterProfileReducer.getRosterFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));

    }
}

function* editRoster(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    yield put(UserLoadingReducer.loading(true));
    const URL = EditRosterProfile_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "student_id": action.data.student_id,
        "student_pic_size": action.data.student_pic_size,
        "student_first_name": action.data.student_first_name,
        "student_last_name": action.data.student_last_name,
        "student_email": action.data.student_email,
        "student_pic_url": action.data.student_pic_url,
        "platform": "web"

    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            let value = {
                "student_id": action.data.student_id,
            }
            yield put(UserRosterProfileReducer.getRoster(value));
            yield put(UserRosterProfileReducer.editRosterSuccess(MainDecode));
            createNotification('success', MainDecode.message);

            yield put(UserLoadingReducer.loadingSuccess(false));

        } else {
            createNotification('error', MainDecode.message);
            yield put(UserRosterProfileReducer.editRosterFailure(MainDecode));
            yield put(UserLoadingReducer.loadingSuccess(false));
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRosterProfileReducer.editRosterFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));

    }
}

// -------Add Contact Section---------
function* addRosterContact(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    yield put(UserLoadingReducer.loading(true));
    const URL = AddRosterContact_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "student_id": action.data.student_id,
        "student_contact_title": action.data.student_contact_title,
        "student_contact_name": action.data.student_contact_name,
        "student_contact_email_id": action.data.student_contact_email_id,
        "platform" : 'web'
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res)
        if (MainDecode.status === 200) {
            let value = {
                "student_id": action.data.student_id,
            }
            yield put(UserRosterProfileReducer.getRoster(value));
            yield put(UserRosterProfileReducer.addRosterContactSuccess(MainDecode));
            createNotification('success', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));

        } else {
            createNotification('error', MainDecode.message);
            yield put(UserRosterProfileReducer.addRosterContactFailure(MainDecode));
            yield put(UserLoadingReducer.loadingSuccess(false));
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRosterProfileReducer.addRosterContactFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));

    }
}

function* editRosterContact(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    yield put(UserLoadingReducer.loading(true));
    const URL = EditRosterContact_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "student_contact_id": action.data.student_contact_id,
        "student_id": action.data.student_id,
        "student_contact_title": action.data.student_contact_title,
        "student_contact_name": action.data.student_contact_name,
        "student_contact_email_id": action.data.student_contact_email_id,
        "platform" : "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res)
        if (MainDecode.status === 200) {
            let value = {
                "student_id": action.data.student_id,
            }
            yield put(UserRosterProfileReducer.getRoster(value));
            yield put(UserRosterProfileReducer.addRosterContactSuccess(MainDecode));
            createNotification('success', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));

        } else {
            createNotification('error', MainDecode.message);
            yield put(UserRosterProfileReducer.addRosterContactFailure(MainDecode));
            yield put(UserLoadingReducer.loadingSuccess(false));
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRosterProfileReducer.addRosterContactFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));

    }
}

function* deleteRosterContact(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    yield put(UserLoadingReducer.loading(true));
    const URL = DeleteRosterContact_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": JSON.stringify(data.cc_user_id),
        // "device_udid": sessionStorage.getItem('UDID'),
        "student_contact_detail_id": action.data.student_contact_detail_id,
        "platform" : "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res)
        if (MainDecode.status === 200) {
            let value = {
                "student_id": action.data.student_id,
            }
            yield put(UserRosterProfileReducer.getRoster(value));
            yield put(UserRosterProfileReducer.deleteRosterContactSuccess(MainDecode));
            createNotification('success', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));

        } else {
            createNotification('error', MainDecode.message);
            yield put(UserRosterProfileReducer.deleteRosterContactFailure(MainDecode));
            yield put(UserLoadingReducer.loadingSuccess(false));
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRosterProfileReducer.deleteRosterContactFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));

    }
}


// -------Add Info Section---------

function* addRosterInfo(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    yield put(UserLoadingReducer.loading(true));
    const URL = AddRosterInfo_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": JSON.stringify(data.cc_user_id),
        // "device_udid": sessionStorage.getItem('UDID'),
        "student_id": JSON.stringify(action.data.student_id),
        "student_info_details": action.data.student_info_details,
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            // body: { "data": btoa(BODY) },
            body: { "data": encryptData(BODY) },

        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            let value = {
                "student_id": action.data.student_id,
            }
            yield put(UserRosterProfileReducer.getRoster(value));
            yield put(UserRosterProfileReducer.addRosterInfoSuccess(MainDecode));
            createNotification('success', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));

        } else {
            createNotification('error', MainDecode.message);
            yield put(UserRosterProfileReducer.addRosterInfoFailure(MainDecode));
            yield put(UserLoadingReducer.loadingSuccess(false));
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
        }
    } catch (error) {
        createNotification('error', "Please use the keyboard for special characters.");
        yield put(UserRosterProfileReducer.addRosterInfoFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));

    }

}

function* editRosterInfo(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    yield put(UserLoadingReducer.loading(true));
    const URL = EditRosterInfo_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "student_id": action.data.student_id,
        "student_info_details": action.data.student_info_details,
        "student_info_id": action.data.student_info_id,
        "platform"  :"web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            let value = {
                "student_id": action.data.student_id,
            }
            yield put(UserRosterProfileReducer.editRosterInfoSuccess(MainDecode));
            yield put(UserRosterProfileReducer.getRoster(value));
            createNotification('success', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));

        } else {
            createNotification('error', MainDecode.message);
            yield put(UserRosterProfileReducer.editRosterInfoFailure(MainDecode));
            yield put(UserLoadingReducer.loadingSuccess(false));
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRosterProfileReducer.editRosterInfoFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));

    }


}

function* deleteRosterInfo(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    yield put(UserLoadingReducer.loading(true));
    const URL = DeleteRosterInfo_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "student_info_id": action.data.student_info_id,
        "platform" : 'web'
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res)
        if (MainDecode.status === 200) {
            let value = {
                "student_id": action.data.student_id,
            }
            yield put(UserRosterProfileReducer.getRoster(value));
            yield put(UserRosterProfileReducer.deleteRosterInfoSuccess(MainDecode));
            createNotification('success', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));

        } else {
            createNotification('error', MainDecode.message);
            yield put(UserRosterProfileReducer.deleteRosterInfoFailure(MainDecode));
            yield put(UserLoadingReducer.loadingSuccess(false));
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRosterProfileReducer.deleteRosterInfoFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));

    }

}


// -------Link---------
function* addLinkRoster(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    yield put(UserLoadingReducer.loading(true));
    const URL = LinkRosterWithParent_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": JSON.stringify(data.cc_user_id),
        // "device_udid": sessionStorage.getItem('UDID'),
        "student_id": JSON.stringify(action.data.student_id),
        "parent_name": action.data.parent_name,
        "parent_email": action.data.parent_email,
        "parent_relation": action.data.parent_relation,
        "is_acknowledged": true ? "1" : "0",
        "platform": "web"

    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            let value = {
                "student_id": action.data.student_id,
            }
            yield put(UserRosterProfileReducer.getRoster(value));
            yield put(UserRosterProfileReducer.addLinkRosterSuccess(MainDecode));
            createNotification('success', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));

        } else {
            createNotification('error', MainDecode.message);
            yield put(UserRosterProfileReducer.addLinkRosterFailure(MainDecode));
            yield put(UserLoadingReducer.loadingSuccess(false));
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRosterProfileReducer.addLinkRosterFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));

    }
}





