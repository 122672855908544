import React from 'react'
import { connect } from 'react-redux';
import ClassLessonPlanDashboard from './ClassLessonPlanDashboard';
import {
    getViewLessonPlanList,
    addLessonPlan,
    copyLessonPlan,
    deleteLessonPlan,
    viewLessonPlanDetails,
    getViewImportLessonPlanList,
    getImportTopicList,
    saveImportLessonPlanRating,
    getLessonPlanSchoolTeacher,
    shareLessonPlan,
    importLessonPlan
} from 'Actions/ClassAction/ClassLessonPlanAction';

const ClassLessonPlanIndex = (props) => {
    return (
        <div>
            <ClassLessonPlanDashboard {...props} />
        </div>
    )
}
const mapStateToProps = state => ({

    state: state,
    lessonPlanListAll: state.classLessonPlan.Get_Lesson_Plan_List,
    addLessonPlanList: state.classLessonPlan.Add_Lesson_Plan,
    copyLessonPlanState: state.classLessonPlan.Copy_Lesson_Plan,
    deleteLessonPlanState: state.classLessonPlan.Delete_Lesson_Plan,
    viewLessonPlanDetailsState: state.classLessonPlan.View_Lesson_Plan_Details,
    saveEvaluationLessonPlanState: state.classLessonPlan.Save_Evaluation_Lesson_Plan,
    saveExecutionLessonPlanState: state.classLessonPlan.Save_Execution_Lesson_Plan,
    getViewImportLessonPlanListState: state.classLessonPlan.Get_View_Import_Lesson_Plan_List,
    getImportTopicListState: state.classLessonPlan.Get_Import_Topic_List,
    saveImportLessonPlanRatingState: state.classLessonPlan.Save_Import_Lesson_Plan_Rating,
    getLessonPlanSchoolTeacherState: state.classLessonPlan.Get_Teacher_List_Lesson_Plan,
    shareLessonPlanState: state.classLessonPlan.Share_Lesson_Plan,
    importLessonPlanState: state.classLessonPlan.Import_Lesson_Plan,
});
const mapDispatchToProps = dispatch => {
    return {
        getViewLessonPlanList: (data) => dispatch(getViewLessonPlanList(data)),
        addLessonPlan: (data) => dispatch(addLessonPlan(data)),
        copyLessonPlan: (data) => dispatch(copyLessonPlan(data)),
        deleteLessonPlan: (data) => dispatch(deleteLessonPlan(data)),
        viewLessonPlanDetails: (data) => dispatch(viewLessonPlanDetails(data)),
        getViewImportLessonPlanList: (data) => dispatch(getViewImportLessonPlanList(data)),
        getImportTopicList: (data) => dispatch(getImportTopicList(data)),
        saveImportLessonPlanRating: (data) => dispatch(saveImportLessonPlanRating(data)),
        getLessonPlanSchoolTeacher: (data) => dispatch(getLessonPlanSchoolTeacher(data)),
        shareLessonPlan: (data) => dispatch(shareLessonPlan(data)),
        importLessonPlan: (data) => dispatch(importLessonPlan(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    ClassLessonPlanIndex
);
