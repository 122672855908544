import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { getStandardIds, getStandardIdsFourth, getClassCoreStandardIds, getClassCoreStandardIdsFourth } from "Utils/Helper";
import tickIcon from 'Assets/FormativeAssessmentModule/tick.svg';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif';
import UpgradePopUp from '../../upgradePopUp/upgradePopUp'
import './ClassCoreStandardsDashboard.scss';

const ClassCoreStandardsDashboard = (props) => {
    const { getClassCoreStandard,
        CoreStandard,
        getCoreStandard,
        addClassCoreStandard, location, history, state } = props;
    const { classDetail, classPermission } = location.state;
    const { classStandard } = props.state;
    const { loader, class_Core_Standard, action } = state.classStandard;
    const [firstPage, setFirstPage] = useState([]);
    const [secondPage, setSecondPage] = useState([]);
    const [fourthPage, setFourthPage] = useState([]);
    const [select, setSelect] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [mainId, setMainId] = useState('');
    const [gradeId, setGradeId] = useState('');
    const [groupId, setGroupId] = useState('');
    const [standardId, setStandardId] = useState('');
    const [showBack, setShowBack] = useState(false);
    const [showData, setShowData] = useState(false);
    const [selectAll, setSelectAll] = useState([]);
    const [multipleSelected, setMultipleSelected] = useState(false)
    const [fourthPageIds, setFourthPageIds] = useState([])
    const [classCoreStandardIds, setClassCoreStandardIds] = useState([])
    const [classCoreStandardIdsfourth, setClassCoreStandardIdsfourth] = useState([])
    const [newThirdCore, setNewThirdCore] = useState([])
    const [showPopUp, setShowPopUp] = useState(false)

    const selectedCore = [];
    const checkedCore = [];
    const _ = require('lodash');

    useEffect(() => {
        if (pageNo === 3 && class_Core_Standard && CoreStandard && CoreStandard.length > 0) {
            const classCoreStandardIds = getClassCoreStandardIds(class_Core_Standard)
            const uniqueClassCoreStandardIds = [...new Set(classCoreStandardIds)];
            setClassCoreStandardIds(uniqueClassCoreStandardIds)

            const mainCoreStds = CoreStandard && CoreStandard.map(value => {
                let stdCore = { ...value };
                const arrData = value.arr.map(core => {
                    if (classCoreStandardIds.includes(core.standard_id)) {
                        return { ...core, "checked": 1 }
                    } else {
                        return { ...core, "checked": 0 }
                    }
                })
                stdCore = { ...stdCore, arr: arrData }

                return stdCore

            })


            const thirdCore = mainCoreStds && mainCoreStds.map(val => {
                const crr = [];
                val.arr.map(valData => {
                    if (valData.checked === 0) {
                        return crr.push(valData)
                    }
                })
                if (crr.length === val.arr.length) {
                    return { ...val, "checked": 0 }
                } else {
                    return { ...val, "checked": 1 }
                }
            })
            setNewThirdCore(thirdCore)
        }
        // else {
        //     setNewThirdCore([])
        // }
    }, [CoreStandard, class_Core_Standard])

    useEffect(() => {
        if (pageNo === 1) {
            let apiData = {
                "main_id": "",
                "group_id": "",
                "grade_id": "",
                "standard_id": ""
            }
            getCoreStandard(apiData)
        }

    }, [])

    useEffect(() => {
        if (classStandard && classStandard.action === 'ADD_CLASS_CORE_STANDARD_FAILURE') {
            if (classStandard.error && (classStandard.error.message === 'Note limit has been exceeded. Please upgrade the plan'
                || classStandard.error.message === "Media limit has been exceeded. Please upgrade the plan"
                || classStandard.error.message === "Please update your subscription plan")) {
                setShowPopUp(true);
            }
        }
    }, [classStandard])

    useEffect(() => {
        if (pageNo === 3) {
            newThirdCore && newThirdCore.map(val => {
                val && val.arr.map(data => {
                    if (data.checked === 1) {
                        checkedCore.push({ "standard_id": data.standard_id.toString(), "sub_standard_id": 0 })
                    }
                })
            })
            const idsData = getStandardIds(newThirdCore);
            if (checkedCore.length === idsData.length) {
                setMultipleSelected(true);
            } else {
                setMultipleSelected(false);
            }
        }

        if (pageNo === 4) {
            fourthPage && fourthPage.map(val => {
                if (val.checked === 1) {
                    checkedCore.push({ "standard_id": val.standard_id.toString(), "sub_standard_id": val.sub_standard_id })
                }
            })

            if (checkedCore.length === fourthPageIds.length) {
                setMultipleSelected(true);
            } else {
                setMultipleSelected(false);
            }
        }
    }, [class_Core_Standard, newThirdCore, classCoreStandardIds, fourthPage, classCoreStandardIdsfourth])

    useEffect(() => {
        if (mainId && pageNo === 2) {
            let apiData = {
                "main_id": mainId,
                "group_id": "",
                "grade_id": "",
                "standard_id": ""
            }
            getCoreStandard(apiData)
        }

        if (mainId && pageNo === 3) {
            let apiData = {
                "main_id": mainId,
                "group_id": groupId,
                "grade_id": gradeId,
                "standard_id": ""
            }
            getCoreStandard(apiData);
            let dataApi = {
                "cc_class_id": classDetail.cc_class_id,
            }
            getClassCoreStandard(dataApi)
        }
        if (mainId && pageNo === 4) {
            let apiData = {
                "main_id": mainId,
                "group_id": groupId,
                "grade_id": gradeId,
                "standard_id": standardId
            }
            getCoreStandard(apiData);
            let dataApi = {
                "cc_class_id": classDetail.cc_class_id,
            }
            getClassCoreStandard(dataApi)
        }
    }, [mainId, groupId, gradeId, standardId])

    useEffect(() => {
        if (pageNo === 4) {
            const idsData = getStandardIdsFourth(fourthPage);
            setFourthPageIds(idsData)

            const classCoreStandardId = getClassCoreStandardIdsFourth(class_Core_Standard)
            const uniqueClassCoreStandardId = [...new Set(classCoreStandardId)];
            setClassCoreStandardIdsfourth(uniqueClassCoreStandardId)
        }
    }, [fourthPage, class_Core_Standard])

    useEffect(() => {
        if (pageNo === 1) {
            setFirstPage(CoreStandard)
        }
        if (pageNo === 2) {
            setSecondPage(CoreStandard)
        }
        if (pageNo === 4) {
            let mainCoreStds = CoreStandard && CoreStandard.map(value => {
                let stdCore = { ...value };
                if (classCoreStandardIdsfourth.includes(value.sub_standard_id)) {
                    return { ...value, "checked": 1 }
                } else {
                    return { ...value, "checked": 0 }
                }
            })
            setFourthPage(mainCoreStds)
        }
    }, [CoreStandard, class_Core_Standard])

    const BackTOHome = () => {
        history.goBack();
        setSelect(false);
        setShowBack(true);
    }

    const ForwardMenu = (val) => {
        setSelect(true);
        setShowBack(true);
        setPageNo(2);
        setMainId(val);
    }

    const BacktoStd = () => {
        setShowBack(false);
        setSelect(false);
        setShowData(false);
        setPageNo(pageNo - 1);
    }

    const showStandard = (value) => {
        setShowData(true);
        setPageNo(3);
        setGradeId(value.grade_id);
        setGroupId(value.group_id);
    }

    const showCoreStandard = (value) => {
        setShowData(true);
        setPageNo(4);
        setStandardId(value.standard_id)
    }

    const handleClosePopUp = event => {
        setShowPopUp(false);
    }

    const unselctMultiple = () => {
        if (pageNo === 3) {
            const unSelectAll = newThirdCore && newThirdCore.map(val => {
                let thirdPageData = { ...val };
                const dataArr = val && val.arr.map(data => {
                    if (data.checked === 1) {
                        return { ...data, checked: 0 }
                    } else {
                        return { ...data }
                    }
                })
                thirdPageData = { ...thirdPageData, checked: 0, arr: dataArr }
                return thirdPageData
            })
            setNewThirdCore(unSelectAll)
        }
        if (pageNo === 4) {
            const dataFourthPage = fourthPage && fourthPage.map(val => {
                return ({ ...val, checked: 0 })
            })
            setFourthPage(dataFourthPage)
        }
        setSelectAll([])
    }

    const selectallTitle = (value) => {
        const updatedThirdPage = newThirdCore && newThirdCore.map(val => {
            let thirdPageData = { ...val };
            if (value.section_title === val.section_title) {
                const dataArr = value && value.arr.map(data => {
                    if (data.checked === 0) {
                        return { ...data, checked: 1 }
                    } else {
                        return { ...data }
                    }
                })
                thirdPageData = { ...thirdPageData, checked: 1, arr: dataArr }
                return thirdPageData
            } else {
                return { ...val }
            }
        })
        setNewThirdCore(updatedThirdPage)
    }

    const deSelectallTitle = (value) => {
        const updatedThirdPage = newThirdCore && newThirdCore.map(val => {
            let thirdPageData = { ...val };
            if (value.section_title === val.section_title) {
                const dataArr = value && value.arr.map(data => {
                    if (data.checked === 1) {
                        return { ...data, checked: 0 }
                    } else {
                        return { ...data }
                    }
                })
                thirdPageData = { ...thirdPageData, checked: 0, arr: dataArr }
                return thirdPageData
            } else {
                return { ...val }
            }
        })
        setNewThirdCore(updatedThirdPage)
    }

    const deSelectCore = (core, coreStd) => {
        const updatedThirdPage = newThirdCore && newThirdCore.map(val => {
            let thirdPageData = { ...val };
            if (coreStd.section_title === val.section_title) {
                const dataArr = val && val.arr.map(data => {
                    if (core.standard_id === data.standard_id && data.checked === 1) {
                        return { ...data, checked: 0 }
                    } else {
                        return { ...data }
                    }
                })
                thirdPageData = { ...thirdPageData, checked: 0, arr: dataArr }
                return thirdPageData
            } else {
                return { ...val }
            }
        })

        const thirdCore = updatedThirdPage && updatedThirdPage.map(val => {
            const crr = [];
            val.arr.map(valData => {
                if (valData.checked === 0) {
                    return crr.push(valData)
                }
            })
            if (crr.length === val.arr.length) {
                return { ...val, "checked": 0 }
            } else {
                return { ...val, "checked": 1 }
            }
        })
        setNewThirdCore(thirdCore)
    }

    const selectCore = (core, coreStd) => {
        const updatedThirdPage = newThirdCore && newThirdCore.map(val => {
            let thirdPageData = { ...val };
            if (coreStd.section_title === val.section_title) {
                const dataArr = val && val.arr.map(data => {
                    if (core.standard_id === data.standard_id && data.checked === 0) {
                        return { ...data, checked: 1 }
                    } else {
                        return { ...data }
                    }
                })
                thirdPageData = { ...thirdPageData, checked: 0, arr: dataArr }
                return thirdPageData
            } else {
                return { ...val }
            }
        })

        const thirdCore = updatedThirdPage && updatedThirdPage.map(val => {
            const crr = [];
            val.arr.map(valData => {
                if (valData.checked === 0) {
                    return crr.push(valData)
                }
            })
            if (crr.length === val.arr.length) {
                return { ...val, "checked": 0 }
            } else {
                return { ...val, "checked": 1 }
            }
        })
        setNewThirdCore(thirdCore)
    }

    const unSelectFourthCore = (coreStd) => {
        const updatedFourthPage = fourthPage && fourthPage.map(val => {
            if (coreStd.sub_standard_id === val.sub_standard_id && val.checked === 1) {
                return { ...val, checked: 0 }
            } else {
                return { ...val }
            }
        })
        setFourthPage(updatedFourthPage);
    }

    const selectFourthCore = (coreStd) => {
        const updatedFourthPage = fourthPage && fourthPage.map(val => {
            if (coreStd.sub_standard_id === val.sub_standard_id && val.checked === 0) {
                return { ...val, checked: 1 }
            } else {
                return { ...val }
            }
        })
        setFourthPage(updatedFourthPage);
    }

    const selectMultiple = () => {

        if (pageNo === 3) {
            const allSelect = newThirdCore && newThirdCore.map(val => {
                let thirdPageData = { ...val };
                const dataArr = val && val.arr.map(data => {
                    if (data.checked === 0) {
                        return { ...data, checked: 1 }
                    } else {
                        return { ...data }
                    }
                })
                thirdPageData = { ...thirdPageData, checked: 1, arr: dataArr }
                return thirdPageData
            })
            setNewThirdCore(allSelect)
        }

        if (pageNo === 4 && CoreStandard) {
            const dataFourthPage = fourthPage && fourthPage.map(val => {
                return ({ ...val, checked: 1 })
            })
            setFourthPage(dataFourthPage)
            CoreStandard && CoreStandard.map(value => {
                selectedCore.push({ "standard_id": value.standard_id.toString(), "sub_standard_id": value.sub_standard_id })
                if (!class_Core_Standard.some(i => selectAll.includes(i.standard_id))) {
                    class_Core_Standard.push({ "standard_id": value.standard_id });
                }
                return selectedCore
            })
        }
        setSelectAll(selectedCore)
    }

    const saveClassCoreStandard = () => {
        const sendToBackend = [];
        const removedCoreStd = [];
        if (pageNo === 3) {
            newThirdCore && newThirdCore.map(val => {
                val && val.arr.map(data => {
                    if (data.checked === 1) {
                        sendToBackend.push({ "standard_id": data.standard_id.toString(), "sub_standard_id": 0 })
                    } else {
                        removedCoreStd.push({ "standard_id": data.standard_id.toString(), "sub_standard_id": 0 })
                    }
                })
            })
            class_Core_Standard && class_Core_Standard.map(value => {
                sendToBackend.push({ "standard_id": value.standard_id.toString(), "sub_standard_id": 0 });
            })

            for (var i = sendToBackend.length - 1; i >= 0; i--) {
                for (var j = 0; j < removedCoreStd.length; j++) {
                    if (sendToBackend[i] && (sendToBackend[i].standard_id === removedCoreStd[j].standard_id)) {
                        sendToBackend.splice(i, 1);
                    }
                }
            }
        }
        if (pageNo === 4) {
            fourthPage && fourthPage.map(val => {
                if (val.checked === 1) {
                    sendToBackend.push({ "standard_id": val.standard_id.toString(), "sub_standard_id": val.sub_standard_id })
                }
            })
        }
        const uniqueCores = [...new Set(sendToBackend)];
        let apiData = {
            "class_id": classDetail?.cc_class_id || props?.location?.state?.classId,
            "core_standard_details_array": uniqueCores,
            "main_id": mainId,
            "group_id": groupId,
            "grade_id": gradeId,
            "standard_id": standardId
        }
        addClassCoreStandard(apiData);
    }

    useEffect(() => {
        if (pageNo === 3 && action === "ADD_CLASS_CORE_STANDARD_SUCCESS") {
            let dataApi = {
                "cc_class_id": classDetail.cc_class_id,
            }
            getClassCoreStandard(dataApi)
            let apiData = {
                "main_id": mainId,
                "group_id": groupId,
                "grade_id": gradeId,
                "standard_id": ""
            }
            getCoreStandard(apiData);

        }

        if (pageNo === 4 && action === "ADD_CLASS_CORE_STANDARD_SUCCESS") {
            let apiData = {
                "main_id": mainId,
                "group_id": groupId,
                "grade_id": gradeId,
                "standard_id": standardId
            }
            getCoreStandard(apiData);
            let dataApi = {
                "cc_class_id": classDetail.cc_class_id,
            }
            getClassCoreStandard(dataApi)
        }
    }, [action])

    return (
        <div>
            <div className='classCoreStandardsContainer'>
                <div className="CoreStandardsMainDiv">
                    <div className="rightBorder">
                        <div className="classBackDiv" onClick={BackTOHome}>
                            <i className="classBackIcon material-icons">chevron_left</i>
                            <p className='classBackYearText'>Class</p>
                        </div>
                    </div>
                    {showData && class_Core_Standard && CoreStandard ? <div className="selectAllDiv">
                        {multipleSelected ? <div><i className="checkBoxfilters material-icons" onClick={unselctMultiple}>{"radio_button_checked"}</i></div>
                            : <div> <i className="checkBoxfilters material-icons" onClick={selectMultiple}>{"radio_button_unchecked"}</i></div>}
                        <div className="selectAllTextDiv"> <p className='selectAllText'>Select All</p></div>
                        {classPermission !== null && classPermission === "read" ? <div className="saveTickDiv">
                            <Button variant="success" className="actionBtn disabledSave">
                                <img src={tickIcon} alt='' />
                            </Button></div> :
                            <div className="saveTickDiv">
                                <Button onClick={saveClassCoreStandard} variant="success" className="actionBtn save">
                                    <img src={tickIcon} alt='' />
                                </Button></div>}
                    </div> : <div className='rightBoxTitle'><p className='standardsTitle'>Core Standards</p></div>}
                </div>
            </div>
            <div className='standardsSubDiv'>
                <div className="standardsBaseDiv">
                    <div className="leftStandard">
                        {showBack && <div className="backToStdDiv" onClick={BacktoStd}>
                            <i className="classBackStdIcon material-icons">chevron_left</i>
                            <p className='backToStd'>Back to Standards</p>
                        </div>}
                        {loader && pageNo !== 3 && <div>
                            <img src={loaderImag} alt='' className='loaderIconCss' />
                        </div>}
                        {!select && firstPage && firstPage.map((val, j) => (
                            <div key={j}>
                                <div className="classBackDiv" onClick={() => { ForwardMenu(val.main_id) }} key={j}>
                                    <p className='standardsTextHead'>{val.core_standards_text}</p>
                                    {val.core_standards_count === 1 ? <i className="classForIcon material-icons">chevron_right</i> : ""}
                                </div>
                            </div>
                        ))}
                        {select && secondPage && secondPage.map((value, k) => (
                            <div key={k}>
                                <div className="classBackDiv" onClick={() => { showStandard(value) }} key={k}>
                                    <p className='standardsTextHead'>{value.core_standards_text}</p>
                                    {value.core_standards_count === 1 ? <i className="classForIcon material-icons">chevron_right</i> : ""}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={`${!showData ? ' rightStandard justyCentre' : 'stdDetailsData'}`}>
                        {showData && loader && <div>
                            <img src={loaderImag} alt='' className='loaderIconCss' />
                        </div>}
                        {!showData && <div className='standardsDetails'>
                            <p className='noStandardsTitle'>Select any Standard to associate with the class</p>
                        </div>}

                        {showData && pageNo === 3 && newThirdCore && newThirdCore.map((coreStd, l) => (
                            <div key={l}>
                                <div className="stdDetailsDatadiv">
                                    <div className="stdTitleDiv">
                                        <span className="divTickStd">
                                            {coreStd.checked === 1 ? <img onClick={() => { deSelectallTitle(coreStd) }} src={tickIcon} className="tickMarkImg " alt="" />
                                                : <img onClick={() => { selectallTitle(coreStd) }} src={tickIcon} className="notickMarkImg pointer" alt="" />}
                                            <p className="stdTitle">{coreStd.section_title}</p>
                                        </span>
                                    </div>
                                    {coreStd && coreStd.arr.map((core, m) => (
                                        <div key={m} className="mainPageForIcon description">
                                            {core.checked === 1 ? <img onClick={() => { deSelectCore(core, coreStd) }} src={tickIcon} className="tickMarkImg " alt="" />
                                                : <img onClick={() => { selectCore(core, coreStd) }} src={tickIcon} className="notickMarkImg pointer" alt="" />}
                                            <p className="stdDetails">
                                                {core.description}
                                            </p>
                                            {core.core_standards_count === 1 ? <div className="mainPageForIcon" onClick={() => { showCoreStandard(core) }} > <i className="material-icons">chevron_right</i></div> : ""}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                        {showData && pageNo === 4 && fourthPage && fourthPage.map((coreStd, l) => (
                            <div >
                                <div className="stdDetailsDatadiv">
                                    <div className="mainPageForIcon description">
                                        {coreStd.checked === 1 ?
                                            <img onClick={() => { unSelectFourthCore(coreStd) }} src={tickIcon} className="tickMarkImg " alt="" />
                                            : <img onClick={() => { selectFourthCore(coreStd) }} src={tickIcon} className="notickMarkImg pointer" alt="" />}
                                        <p className="stdDetails">
                                            {coreStd.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {showPopUp && <UpgradePopUp handleClosePopUp={handleClosePopUp} />}
        </div>
    )
}

ClassCoreStandardsDashboard.propTypes = {
    getClassCoreStandard: PropTypes.func,
    addClassCoreStandard: PropTypes.func,
    getClassCoreStandard: PropTypes.func,
    getCoreStandard: PropTypes.func,
    location: PropTypes.object,
    state: PropTypes.object,
}

export default ClassCoreStandardsDashboard;