import React from "react";
import AvatarEditor from "react-avatar-editor";
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import dashIcon from 'Assets/CommonComponent/dashIcon.svg';
import plusIcon from 'Assets/CommonComponent/plusIcon.svg';
import './ReactAvatar.scss';

class ReactAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.setEditorRef = this.setEditorRef.bind(this);
    this.handleZoomSlider = this.handleZoomSlider.bind(this);

    
    this.state = {
      cropperOpen: true,
      img: this.props.Userprofile,
      zoom: 2,
      croppedImg:
        "https://upload.wikimedia.org/wikipedia/commons/0/09/Man_Silhouette.png"
    };
  }

  handleZoomSlider(event, value) {
    let state = this.state;
    state.zoom = value;
    this.setState(state);
  }

  handleSave(e) {
    if (this.editor) {
      const canvasScaled = this.editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();

      let state = this.state;
      state.img = null;
      state.croppedImg = croppedImg;
      this.setState(state);
      this.props.handleCroppedUrl(this.state.croppedImg)
      if(this.props.open === false){
        state.cropperOpen = false;
      }
    }
  }
  handleCancel() {
    let state = this.state;
    state.cropperOpen = false;
    this.setState(state);
  this.props.handleModalClose();
  }

  setEditorRef(editor) {
    this.editor = editor;
  }

  render() {
    const { cropperOpen, img, zoom } = this.state;
    return (
        <div style={{ height: 386 }}>
          {cropperOpen && (
            <div
              className="cropper-wrapper"
              style={{
                position: "absolute",
                top: 0,
                width: "328px",
                height: "386px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <label
                  style={{
                    fontSize: 18,
                    fontFamily: 'roboto',
                    marginTop: 15,
                    marginBottom: 15,
                    fontWeight: 600,
                    color:'white'
                  }}
                >
                  Adjust Profile Image
                </label>
              <AvatarEditor
                ref={this.setEditorRef}
                image={img}
                width={200}
                height={200}
                border={10}
                color={[255, 255, 255, 0.6]} // RGBA
                rotate={0}
                scale={zoom}
              />
              <div 
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                  marginRight: 50
                }}
              >
                <label
                  style={{
                    fontSize: 14,
                    fontFamily: 'roboto',
                    marginRight: 15,
                    marginBottom: 0,
                    fontWeight: 600,
                    color:'white'
                  }}
                >
                  Zoom
                </label>
                <img src={dashIcon} className='zoomIcon' height="20" style = "color:blue;" alt="CoolBrand" style={{marginRight: 10}}/>

                <Slider
                  min={1}
                  max={10}
                  step={0.1}
                  value={zoom}
                  onChange={this.handleZoomSlider}
                  style={{ width: 130 }}
                />
                <img src={plusIcon} className='zoomIcon' height="20" style = "color:blue;" alt="CoolBrand" style={{marginLeft: 10}}/>

              </div>
              <div className = 'btnDiv'>
                <Button className = 'cancelBtn BR-8'
                  onClick={this.handleCancel}
                  >Cancel</Button>
                <Button className = 'saveBtn BR-8'
                  onClick={this.handleSave}
                >Save</Button>
              </div>
            </div>
          )}
        </div>
    );
  }
}
ReactAvatar.propTypes = {
  handleCroppedUrl: PropTypes.func,
  open: PropTypes.bool
};
export default ReactAvatar;
