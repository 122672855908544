import React from "react";
import thumbIcon from 'Assets/FormativeAssessmentModule/thumb.svg';
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import './CoreStandardPopover.scss';

const CoreStandardPoover = (props) => {
    const { CoreStandardList, setAssissmentTagNameCheck } = props;
    const setCoreStandard = (value) => {
        setAssissmentTagNameCheck(value)
    }

    return (
        <div style={{position : 'relative'}}
       
        >
            <img src={thumbIcon} id={"mypopover"} className="thumbIcon" alt='' width="18" height="22" />
            <UncontrolledPopover trigger="legacy"
                placement="bottom"
                // isOpen={true}
                target={"mypopover"}
                // toggle={() => selectSpeedTag(item, index)}
                style={{ borderRadius: 20 }}
                className='assessmentCoreStandardPopover'
            >
                <PopoverBody>
                    <div className='tagDiv'>
                        {CoreStandardList.map((item, index) => (
                            <div key={index} className='selectTagDiv' onClick={() => { setCoreStandard(item.description) }}>
                                <p className='selectTagText'>{item.description}</p>
                            </div>))}
                    </div>
                </PopoverBody>
            </UncontrolledPopover>
        </div>
    )
}

export default CoreStandardPoover