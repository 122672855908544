import React from 'react';
import PropTypes from 'prop-types';
import paymentFailed from 'Assets/PayPalModule/paymentFailed.png';
import Button from 'react-bootstrap/Button';


const PaypalFailed = (props) => {
    const { gotoPaypal,goToAcademic } = props

    return (
        <div className='paymentSucessDiv'>
            <img src={paymentFailed} alt={''} className='paymentFailedImage' />
            <br />
            {/* ------ Redirect Button------ */}
            <div className='paypalButtonDiv'>
                <Button className='retryPaymentButton' onClick={gotoPaypal}>Retry Payment</Button>
                <Button className='goHomeButton' onClick={goToAcademic}>Return to Home</Button>
            </div>
            {/* ------ Redirect Button------ */}
        </div>
    )
}
PaypalFailed.propTypes = {
    goToAcademic: PropTypes.func,
    gotoPaypal: PropTypes.func,
}
export default PaypalFailed;