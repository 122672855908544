import { BASE_URL, BASE_MICROSERVICE_URL, AUTH_BASE_URL, BASE_MICROSERVICE_URL_PLAN } from "./Constant";

export let base_url_API = BASE_URL + "v3/";

export const base_Microservice_url = BASE_MICROSERVICE_URL
export const base_url_Auth = AUTH_BASE_URL;

// ---------Auth Module---------
export const Login_API = base_url_Auth + 'teacher/teacherLogin';
export const Forgot_API = base_url_Auth + 'teacher/teacherForgotPassword';
// export const Place_API = base_url_Auth + 'api/place';
export const Place_API = base_url_Auth + 'admin/searchSchool';
export const Register_API = base_url_Auth + 'teacher/teacherRegistration';
// export const Verification_API = base_url_Auth + 'api_user_verfication';
export const Verification_API = base_url_Auth + 'teacher/teacherEnterOtp';
// export const Resend_Otp_API = base_url_Auth + 'api_re_email_verfication_code';
export const Resend_Otp_API = base_url_Auth + 'teacher/reEmailVerficationCode';
// ---------Auth Module---------

// ---------Academic Module---------
// export const GetAcademicList_API = base_url_API + 'api_get_user_academic_year';
// export const GetClassRosterByAcademicYear_API = base_url_API + 'api_get_class_roster_by_academic_year'
// export const GetAcademicYearsList_API = base_url_API + 'api_academic_year_list';
// export const GetAcademicScore_API = base_url_API + 'api_get_score';
// export const AddAcademicYear_API = base_url_API + 'web_api_add_academic_year';
// export const EditAcademicYear_API = base_url_API + 'api_update_academic_year';
// export const DeleteAcademicYear_API = base_url_API + 'api_delete_academic_year';

// export const GetAcademicList_API = base_Microservice_url + 'academic/apiGetAcademicYear';// user academic year list data
export const GetAcademicList_API = base_Microservice_url + 'academic/apiGetAcademicYearSingle';// user academic year list data
export const GetAcademicYearsList_API = base_Microservice_url + 'academic/apiAcademicYearList'; // popUp list data (Master data)
export const GetClassRosterByAcademicYear_API = base_Microservice_url + 'student/apiGetClassRosterByAcademicYear'
export const GetAcademicScore_API = base_Microservice_url + 'student/getScore';
export const AddAcademicYear_API = base_Microservice_url + 'academic/createAcademicYear';
export const EditAcademicYear_API = base_Microservice_url + 'academic/updateAcademicYear';
export const DeleteAcademicYear_API = base_Microservice_url + 'academic/deleteAcademicYear';

// ---------Academic Module---------

// ---------Profile Module---------
export const UpdateUser_API = base_url_Auth + 'teacher/apiUpdateUserProfile';
export const deleteUser_API = base_Microservice_url + 'teacher/updateUserStatus';
// ---------Profile Module---------


// -------------------Roster Module---------
export const AddRoster_API = base_Microservice_url + 'student/addStudent';
export const AddMultipleRoster = base_Microservice_url + 'student/apiAddClassStudent';
export const GetRoster_API = base_Microservice_url + 'student/apiGetRoster';
export const DeleteRoster_API = base_Microservice_url + 'student/deleteStudent';
export const DeleteClassRoster_API = base_Microservice_url + 'student/deleteStudent';

// -----Search Roster Module------
export const SearchRoster_API = base_Microservice_url + 'student/searchStudentByAcademicYear';

// -----Search Roster Module------

// -----Import Roster Module------
export const importRoster_API = base_Microservice_url + 'student/apiImportRoster';

// -----Import Roster Module------


// -----Roster Profile Module------
export const GetRosterProfile_API = base_Microservice_url + 'student/getStudentProfile';
export const EditRosterProfile_API = base_Microservice_url + 'student/apiUpdateStudent';

export const AddRosterContact_API = base_Microservice_url + 'student/apiAddStudentContact';
export const EditRosterContact_API = base_Microservice_url + 'student/apiEditStudentContact';
export const DeleteRosterContact_API = base_Microservice_url + 'student/apiDeleteStudentContactDetails';

export const AddRosterInfo_API = base_Microservice_url + 'student/apiAddStudentInfo';
export const EditRosterInfo_API = base_Microservice_url + 'student/apiEditStudentInfo';
export const DeleteRosterInfo_API = base_Microservice_url + 'student/deleteStudentInfo';

export const LinkRosterWithParent_API = base_Microservice_url + 'student/apiInviteParent';


// -----Roster Profile Module------



// -----Roster Enrolled Module------
export const GetRosterEnrolled_API = base_Microservice_url + 'student/getStudentEnrolledClass';
export const EditRosterEnrolled_API = base_Microservice_url + 'student/addStudentScoreLevel';

// -----Roster Enrolled Module------


// -------------------Roster Module---------


// -------------------Class Module----------
export const GetClass_API = base_Microservice_url + 'class/classList';
export const AddClass_API = base_Microservice_url + 'class/createClass';
export const EditClass_API = base_Microservice_url + 'class/updateClass';
export const EditClassColorWheel_API = base_Microservice_url + 'class/updateClassWheelDetails';
export const SearchClass_API = base_Microservice_url + 'class/searchClass';
export const DeleteClass_API = base_Microservice_url + 'class/deleteClass';
export const GetRosterWithoutClass_API = base_Microservice_url + 'student/apiGetClassStudent';

export const GetClassTag_API = base_Microservice_url + 'notes/getCustomizeTags';
export const EditClassTag_API = base_Microservice_url + 'notes/apiEditCustomizeTag';
export const DeleteClassTag_API = base_Microservice_url + 'notes/deleteClassTag';
export const AddClassTag_API = base_url_API + 'api_get_assessment_tag';
export const GetSpeedNote_API = base_Microservice_url + 'notes/get_speed_note';
export const importSpeedNote_API = base_Microservice_url + 'notes/addSpeedNote';
export const GetNoteCore_API = base_Microservice_url + 'notes/apiGetCoreStandardsByClass';
export const GetClassAttList_API = base_Microservice_url + 'student/apiGetClassAttendanceStudent';
export const AddClassAtt_API = base_Microservice_url + 'student/apiAddClassAttendance';
export const GetClassAttByDateRange_API = base_Microservice_url + 'student/apiGetViewAttendanceList';
export const SendEmail_API = base_Microservice_url + 'student/api_share_file';

export const importCustomStandard_API = base_Microservice_url + 'lesson_plan/apiImportCoreStandard'
export const deleteCustomStandard_API = base_Microservice_url + 'lesson_plan/deleteStandard'
export const editCustomStandard_API = base_Microservice_url + 'lesson_plan/editStandard'


// -----lesson plan Module-----
export const GetViewLessonPlan_API = base_Microservice_url + 'lesson_plan/viewLessonPlan';
export const AddLessonPlan_API = base_Microservice_url + 'lesson_plan/apiAddLessonPlan';
export const GetViewQuestionsList_API = base_Microservice_url + 'lesson_plan/apiGetViewQuestionList';
export const CopyLesssonPlan_API = base_Microservice_url + 'lesson_plan/apiCopyLessonPlan';
export const DeleteLesssonPlan_API = base_Microservice_url + 'lesson_plan/apiDeleteLessonPlan';
export const ViewLesssonPlanDetails_API = base_Microservice_url + 'lesson_plan/apiViewLessonPlanDetails';
export const SaveExecution_API = base_Microservice_url + 'lesson_plan/apiSaveLessonPlanExecution';
export const SaveEvaluation_API = base_Microservice_url + 'lesson_plan/apiSaveLessonPlanEvaluation';
export const DeleteDynamicQuestion_API = base_Microservice_url + 'lesson_plan/apiDeleteQuestionLessonPlan';
export const ImportLessonPlan_API = base_Microservice_url + 'lesson_plan/apiImportLessonPlan';
export const GetViewImportLessonPlan_API = base_Microservice_url + 'lesson_plan/apiGetImportLessonPlanList';
export const GetImportTopicList_API = base_Microservice_url + 'lesson_plan/apiGetClassTopicList';
export const SaveImportLessonPlanRating_API = base_Microservice_url + 'lesson_plan/apiSaveLessonPlanRating';
export const ShareLessonPlan_API = base_Microservice_url + 'lesson_plan/apiShareLessonPlan';
export const getLpText_API = base_Microservice_url + 'vertex_ai/getTextForLP'
// -----lesson plan Module------

// -----Rubrics Module------
// export const GetRubricsList_API = base_url_API + 'api_get_rubric_list'
export const GetRubricsList_API = base_Microservice_url + 'rubric/apiGetRubricList'
// export const AddNewRubrics_API = base_url_API + 'api_add_rubric'
export const AddNewRubrics_API = base_Microservice_url + 'rubric/apiAddRubric'
// export const ViewRubricDetails_API = base_url_API + 'api_view_rubric_details'
export const ViewRubricDetails_API = base_Microservice_url + 'rubric/apiViewRubricDetails'
// export const DeleteCriteria_API = base_url_API + 'api_delete_rubric_criteria'
export const DeleteCriteria_API = base_Microservice_url + 'rubric/apiDeleteRubricCriteria'

// export const SaveAssessment_API = base_url_API + 'api_save_rubric_assessment'
export const SaveAssessment_API = base_Microservice_url + 'rubric/apiSaveRubricAssessment'
// export const ViewStudentsAssessmentDetails_API = base_url_API + 'api_view_rubric_assessment_details'
export const ViewStudentsAssessmentDetails_API = base_Microservice_url + 'rubric/apiViewRubricAssessmentDetails'
// export const DeleteRubric_API = base_url_API + 'api_delete_rubric_details'
export const DeleteRubric_API = base_Microservice_url + 'rubric/apiDeleteRubricDetails'

export const SendRubricEmail_API = base_Microservice_url + 'rubric/apiShareFile';

export const getDynamicCriteria_API = base_Microservice_url + 'vertex_ai/getTextForRubric';
// -----Rubrics Module------

export const GetClassCoreStandard_API = base_Microservice_url + 'lesson_plan/apiGetClassCoreStandard';
export const GetSchoolTeacher_API = base_Microservice_url + 'lesson_plan/apiGetSchoolTeacher';
export const ShareClass_API = base_Microservice_url + 'class/apiShareClass';
// export const ShareStudent_API = base_url_API + 'api_share_student';
export const ShareStudent_API = base_Microservice_url + 'student/apiShareStudent';

export const GetCoreStandard_API = base_Microservice_url + 'lesson_plan/apiGetCoreStandards';
export const AddClassCoreStandard_API = base_Microservice_url + 'lesson_plan/apiAddClassCoreStandard';

export const DeletePreviousCriteria_API = base_Microservice_url + 'rubric/apiDeleteRubricCriteriaByRubricId';


// -----Import Roster Module------
export const importClassRoster_API = base_Microservice_url + 'student/apiImportRoster';

// -----Import Roster Module------

// -------------------Class Module----------
export const getClassGroupNotesData_API = base_Microservice_url + 'notes/apiGetStudentAndNotesByGroupId'
export const classGroupNoteDelete_API = base_Microservice_url + 'notes/api_delete_group_note'

// -------------------Note Module----------
export const AddStudentNote_API = base_Microservice_url + 'notes/apiAddNote';
export const EditStudentNote_API = base_Microservice_url + 'notes/api_edit_note';
export const GetStudentNote_API = base_Microservice_url + 'notes/apiStudentNotes';
export const DeleteStudentNote_API = base_Microservice_url + 'notes/api_delete_note';
export const DeleteGroupNoteFromSingleStudent_API = base_Microservice_url + 'notes/api_delete_note';
export const SetClassgroupNoteArtifact_API = base_Microservice_url + '/notes/apiMarkNoteArtifact';

export const GetGroupDetails_API = base_Microservice_url + 'notes/api_get_group_details';
export const AddMultipleStudents_API = base_Microservice_url + 'notes/api_add_student_multiple_group';
export const AddNewGroup_API = base_Microservice_url + 'notes/api_create_group';
export const DeleteGroup_API = base_Microservice_url + 'notes/api_delete_student_group';

export const AddGroupNote_API = base_Microservice_url + 'notes/apiAddNote';
export const EditGroupNote_API = base_url_API + 'api_edit_note';
export const EditClassGroupNote_API = base_Microservice_url + 'notes/api_edit_group_note'
export const GetGroupNote_API = base_url_API + 'api_student_notes';
export const DeleteGroupNote_API = base_url_API + 'api_delete_note';
export const AddNewStudentInGroup_API = base_Microservice_url + 'notes/api_add_student_group_new';
export const RemoveStudentFromGroup_API = base_Microservice_url + 'notes/api_remove_student_from_group';
export const RenameGroup_API = base_Microservice_url + 'notes/api_update_group';
export const CreateGroup_API = base_Microservice_url + 'notes/api_create_group';

export const SetStudentArtifact_API = base_Microservice_url + '/notes/apiMarkNoteArtifact';
export const SetMediaAttachmentArtifact_API = base_Microservice_url + 'notes/apiMarkAttachmentArtifact';
export const AddStudentMiscellaneous_API = base_Microservice_url + 'notes/api_add_miscellaneous_notes';
export const EditStudentMiscellaneous_API = base_Microservice_url + 'notes/api_edit_miscellaneous_notes';
export const DeleteStudentMiscellaneous_API = base_Microservice_url + 'notes/api_delete_misc_note';
export const GetStudentsForNote_API = base_url_API + 'api_get_students_for_note';

export const getAudioFromVertex_API = base_Microservice_url + 'vertex_ai/getTextDataFromSpeech';
export const getAudioSummaryVertex_API = base_Microservice_url + 'vertex_ai/getSummaryForNote';

// -------------------Note Module----------



// ---------FormativeAssessment Module --------
// export const GetAssessment_tag_API = base_url_API + 'api_get_assessment_tag';
// export const AddAssessment_tag_API = base_url_API + 'api_add_assessment_tag';
// export const EditAssessment_tag_API = base_url_API + 'api_edit_assessment_tag';
// export const DeleteAssessment_tag_API = base_url_API + 'api_delete_assessment_tag';
// export const GetAssessment_tag_student_API = base_url_API + 'api_get_assessment_tag_students';
export const GetAssessment_tag_API = base_Microservice_url + 'assessment/getAssessmentTag';
export const AddAssessment_tag_API = base_Microservice_url + 'assessment/apiAddAssessmentTag';
export const EditAssessment_tag_API = base_Microservice_url + 'assessment/apiEditAssessmentTag';
export const DeleteAssessment_tag_API = base_Microservice_url + 'assessment/deleteAssessmentTag';
export const GetAssessment_tag_student_API = base_Microservice_url + 'assessment/apiGetAssessmentTagStudents';
export const GetAssessment_Class_student_API = base_Microservice_url + 'assessment/apiGetClassStudent';

// ---------FormativeAssessment Module --------


// ---------Class GradeBook Module --------
// export const GetClassGradebook_List_API = base_url_API + 'api_get_class_gradebook';
// export const AddClassGradebook_Category_API = base_url_API + 'api_add_event_category';
// export const AddClassGradebook_Event_API = base_url_API + 'api_add_grading_event';
// export const UpdateClassGradebook_Event_API = base_url_API + 'api_update_grading_event';
// export const UpdateClassGradebook_ScoreComment_API = base_url_API + 'api_update_event_score_and_comment';
// export const DeleteClassGradebook_Event_API = base_url_API + 'api_delete_grading_event';
// export const UpdateClassGradebook_Index_API = base_url_API + 'api_update_event_category_index'
// export const UpdateClassGradebook_EventIndex_API = base_url_API + 'api_update_grading_event_index'

export const GetClassGradebook_List_API = base_Microservice_url + 'gradebook/apiGetClassGradebook';
export const AddClassGradebook_Category_API = base_Microservice_url + 'gradebook/apiAddEventCategory';
export const AddClassGradebook_Event_API = base_Microservice_url + 'gradebook/apiAddGradingEvent';
export const UpdateClassGradebook_Event_API = base_Microservice_url + 'gradebook/apiUpdateGradingEvent';
export const UpdateClassGradebook_ScoreComment_API = base_Microservice_url + 'gradebook/apiUpdateEventScoreAndComment';
export const DeleteClassGradebook_Event_API = base_Microservice_url + 'gradebook/apiDeleteGradingEvent';
export const UpdateClassGradebook_Index_API = base_Microservice_url + 'gradebook/apiUpdateEventCategoryIndex'
export const UpdateClassGradebook_EventIndex_API = base_Microservice_url + 'gradebook/apiUpdateGradingEventIndex'

// ---------Class GradeBook Module --------

// ---------Student GradeBook Module --------
// export const GetStudentGradebook_List_API = base_Microservice_url + 'gradebook/viewStudentGradebook';
// export const UpdateStudentGradebook_List_API = base_url_API + 'api_update_grading_score_and_comment';

export const GetStudentGradebook_List_API = base_Microservice_url + 'gradeBook/viewStudentGradebook';
export const UpdateStudentGradebook_List_API = base_Microservice_url + 'gradebook/apiUpdateGradingScoreAndComment';

// ---------Student GradeBook Module --------

// ---------Class Core Standard Module --------
export const GetClassCoreStandard_List_API = base_url_API + 'class_core_standard';
// ---------Class Core Standard Module --------

export const GetDataUsage_List_API = base_Microservice_url + 'subscription/updateUserSpace'

// ---------Class Core Standard Module --------
export const SendSubscribe_API = base_url_API + 'api_notify_email';
// ---------Class Core Standard Module --------


// ---------Subscription Plan Module --------
export const SubscriptionPlan_API = BASE_MICROSERVICE_URL_PLAN + 'subscription/getSubscriptionList'
export const getStripeProducts_API = BASE_MICROSERVICE_URL_PLAN + 'subscription/getAllPrices'
export const changeUserSubscription_API = BASE_MICROSERVICE_URL_PLAN + 'subscription/newSubscription'
export const getUserPaymentDetails_API = base_Microservice_url + 'subscription/getStripeCustomerDetails'
// ---------Subscription Plan Module --------

// ---------Setting Module --------
export const GetSetting_API = base_Microservice_url + 'teacher/getUserSettings'
export const updateSetting_API = base_Microservice_url + 'teacher/updateUserSetting'
// ---------Setting Module --------

// ---------Notification Module --------
// export const GetMyNotificationList_API = base_url_API + 'api_get_my_notification_list'
// export const ApprovedRejectSharedClass_API = base_url_API + 'api_approved_reject_shared_class'
// export const ApprovedRejectSharedStudent_API = base_url_API + 'api_approved_reject_shared_student'
// export const DeleteNotification_API = base_url_API + 'api_delete_notification'
// export const NotificationsMarkAsRead_API = base_url_API + 'api_all_notifications_mark_as_read'
// export const NotificationsCount_API = base_url_API + 'api_get_user_notification_count'


export const GetMyNotificationList_API = base_Microservice_url + 'student/apiGetMyNotificationList'
export const ApprovedRejectSharedClass_API = base_Microservice_url + 'notification/approvedRejectSharedClass';
export const ApprovedRejectSharedClassBySchoolTeacher_API = base_Microservice_url + 'notification/approvedRejectSharedClassBySchoolTeacher';
export const ApprovedRejectSharedStudent_API = base_Microservice_url + 'notification/apiApprovedRejectShareStudent'
export const ApprovedRejectSharedStudentBySchoolTeacher_API = base_Microservice_url + 'notification/apiApprovedRejectShareStudentBySchoolTeacher' // NEW API FOR STUDENT SHARING
export const DeleteNotification_API = base_Microservice_url + 'notification/apiDeleteNotification'
export const NotificationsMarkAsRead_API = base_Microservice_url + 'notification/apiAllNotificationsMarkAsRead'
export const NotificationsCount_API = base_Microservice_url + 'notification/apiGetUserNotificationCount'
export const GetUserSpaceRequest_API = base_Microservice_url + 'teacher/get_plan_space_details'
// ---------Notification Module --------

// ---------Set Payment StatusModule --------
export const SetPaymentStatus_API = BASE_MICROSERVICE_URL_PLAN + 'subscription/saveAgreement';
// ---------Set Payment StatusModule --------


// ---------Set Payment Info Module --------
export const SetPaymentInfo_API = base_url_API + 'api_add_user_subscription';
// ---------Set Payment Info Module --------

// ---------Contact Us Module --------
export const SetContactUs_API = base_url_API + 'api_contact_us'
// ---------Contact Us Module --------
