import { call, takeLatest, put } from 'redux-saga/effects';
import UserForgotReducer from 'Redux/Reducers/AuthModuleReducer/forgotReducer';
import UserLoadingReducer from 'Redux/Reducers/CommonReducer/loadingReducer';
import { createNotification } from 'Config/notificationtoast';
import { FORGOT } from 'Actions/constants';
import { Forgot_API } from 'Config/Api';
import {
  chroniclecloud_code,
  device_udid
} from 'Config/Constant';
import request from 'Config/request';
import { decryptData, encryptData } from 'Utils/Helper';

export function* userForgotModuleSaga() {
  yield takeLatest(FORGOT, doForgot);
}

function* doForgot(action) {
  yield put(UserLoadingReducer.loading(true));
  // const URL = `${base_url_API}api_forgot_password`;
  const URL = Forgot_API;
  const BODY = {
    // "chroniclecloud_code": chroniclecloud_code,
    // "user_email_id": action.data.user_email_id,
    // "device_udid": device_udid,
    "teacher_email": action.data.user_email_id,
    "platform": "web"
  }

  let response;
  try {
    response = yield call(request, URL, {
      method: 'POST',
      body: { "data": encryptData(BODY) },
    });
    const MainDecode = decryptData(response.res);
   
    if (MainDecode.status === 200) {
      createNotification('success', MainDecode.message);
      yield put(UserLoadingReducer.loadingSuccess(false));
      yield put(UserForgotReducer.forgotSuccess(MainDecode));
    } else {

      createNotification('error', MainDecode.message);
      yield put(UserLoadingReducer.loadingSuccess(false));
      yield put(UserForgotReducer.forgotFailure(false));
    }
  } catch (error) {
    yield put(UserForgotReducer.forgotFailure(false));
    createNotification('warning', 'Somthing went wrong');
    yield put(UserLoadingReducer.loadingSuccess(false));


  }
}

