import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './StudentProfiles.scss';

const AssessmentStudentProfile = (props) => {
    const { AllStudent, getAllStudentsStatus } = props;
    const _ = require('lodash');
    return (
        AllStudent.map((item, index) => {
            return (
                /* ---------------show Student Section------------ */
                <Col key={index} sm="6">
                    <Card className="mt-15 br-8">
                        <Card.Body className="addMainDiv" style={{ borderRight: item.cc_ref_student_color_code ? `6px solid ${item.cc_ref_student_color_code}` : '6px solid #ffffff' }}  >
                            <Row className="profile">
                                <Col xs="7" md="12" lg="12" style={{ padding: 2, display: "flex", alignItems: "center"  }}>
                                    <div className="img-div">
                                        {item.cc_student_pic_url?.includes('https') ?
                                            <Card.Img className="imgGoal" variant="top" alt='' src={item.cc_student_pic_url} />
                                            :
                                            <div className='studentProfileImageDiv'>
                                                <div className='imageDummyRosterProfiles'>
                                                    <h5 className='rosterProfileImageText'>{item.cc_student_first_name.charAt(0)}{item.cc_student_last_name.charAt(0)}</h5>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="details w-100">
                                        {/* <span className="name">{item.cc_student_last_name} {item.cc_student_first_name}</span> */}
                                        <span className="name">{_.truncate(item.cc_student_last_name + " " + item.cc_student_first_name, {
                                                'length': 20,
                                                'omission': '...'
                                            })}</span>
                                        <br></br>
                                        <span className="mail FAstudentprofilesmail"
                                         style={{display : (item?.cc_student_email_id || item?.cc_student_email_id?.length > 0) && 'inline-block'}}
                                        >
                                            {/* {_.truncate(item.cc_student_email_id, {
                                                'length': 40,
                                                'omission': '...'
                                            })} */}
                                            {item.cc_student_email_id}
                                            </span>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                /* ---------------show Student Section------------ */
            )
        })
    )
}


export default AssessmentStudentProfile

