import React, { Component } from 'react';
import { Button } from 'reactstrap';
import viewOnlyIcon from 'Assets/RosterModule/viewOnly.png'
import createCopyIcon from 'Assets/RosterModule/createCopy.png'
import collaborateIcon from 'Assets/RosterModule/collaborate.png'
import cancelBlack from 'Assets/RosterModule/cancelBlack.png'
import { connect } from 'react-redux';
import Profile2 from 'Assets/AcademicModule/profile2.png';
import './RosterShare.scss';

class RosterShare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teacher: [{ id: 1 }, { id: 122 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 },],
            seletedTeacher: '',
            SelectShareOption: ''
        }
    }

    render() {
        let { teacher, seletedTeacher, SelectShareOption } = this.state
        return (
            <div className='RosterShareContainer'>
                {/* ------------Heading------------- */}
                <div className='mainHeading'>
                    <div>
                        <p className='headingSchoolName'>Christian Eminent</p>
                    </div>
                    <div>

                        {seletedTeacher ? <Button className='openPermissionModalButton' color="primary" data-toggle="modal" data-target="#myModalPermission" >
                            <span className='checkPermissionText' > Check Permission</span>
                        </Button> :
                            <p className='headingSelectTeacher'>— Select Teacher to share student</p>}
                    </div>
                </div>
                {/* ------------Heading------------- */}

                {/* ------------Teacher Card------------- */}
                {teacher && teacher.map((item, index) => (<div key={index} className='teacherMainDiv'>
                    <div className='teacherCard'>
                        <div className='teacherProfileImageDiv'>
                            {/* <div className='teacherDummyDivProfile'>
                                <h5 className='teacherImageDummyText'>RR</h5>
                            </div> */}
                            <img src={Profile2} alt='' className='teacherShareProfileImage' />

                        </div>
                        <div className='teacherShareTextInfoDiv' >
                            <p className='teacherShareName'>Rahul,Roy</p>
                            <p className='teacherShareEmail'>rahul@gmail.com</p>

                        </div>
                        <div className='teacherShareCheckBoxDiv'>
                            <i onClick={() => { this.setState({ seletedTeacher: item.id }) }} className="checkBoxColorTeacherShare material-icons">{this.state.seletedTeacher === item.id ? 'check_circle' : 'radio_button_unchecked'}</i>

                        </div>

                    </div>

                </div>))}
                {/* ------------Teacher Card------------- */}

                {/* ---------------Modal Upload------------ */}
                <div className="modal right fade" id="myModalPermission" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className='permissionHeaderDiv'>
                                    <div>
                                        <p className='permissionText'>Select Permission</p>
                                    </div>
                                    <div>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span className='cancelText' aria-hidden="true">  <img src={cancelBlack} alt='' className='closeIconPermissionModal' /> Cancel</span></button>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-body">

                                {/* --------------- Upload option------------ */}
                                <div className='selectMainDivforShareTeacher'>
                                    <div className='selectOptionInTeacherShareType'>
                                        <div className='checkbozCircleTeacherShare' onClick={() => { this.setState({ SelectShareOption: 'View' }) }}>
                                            {SelectShareOption === 'View' && <div className='checkbozCircleSelectedTeacherShare'></div>}
                                        </div>
                                        <img src={viewOnlyIcon} alt='' className='selectoptionShareTeacherImage' />
                                        <p className='selectOptionShareTeacherText'>View Only</p>
                                    </div>
                                    <div className='selectOptionInTeacherShareType'>
                                        <div className='checkbozCircleTeacherShare' onClick={() => { this.setState({ SelectShareOption: 'Copy' }) }}>
                                            {SelectShareOption === 'Copy' && <div className='checkbozCircleSelectedTeacherShare'></div>}
                                        </div>
                                        <img src={createCopyIcon} alt='' className='selectoptionShareTeacherImage' />
                                        <p className='selectOptionShareTeacherText'>Create Copy</p>
                                    </div>
                                    <div className='selectOptionInTeacherShareType'>
                                        <div className='checkbozCircleTeacherShare' onClick={() => { this.setState({ SelectShareOption: 'Collaborate' }) }}>
                                            {SelectShareOption === 'Collaborate' && <div className='checkbozCircleSelectedTeacherShare'></div>}
                                        </div>
                                        <img src={collaborateIcon} alt='' className='selectoptionShareTeacherImage' />
                                        <p className='selectOptionShareTeacherText'>Collaborate</p>
                                    </div>


                                    <div>
                                        <Button disabled={SelectShareOption ? false : true} className='sendPermissionButton' color="primary" >
                                            <span className='sendPermissionText' > Send</span>
                                        </Button>
                                    </div>
                                </div>
                                {/* ---------------Upload option------------ */}


                            </div>
                        </div>
                    </div>
                </div>
                {/* ---------------Modal Add Student------------ */}



            </div>
        )
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    RosterShare
);