import React from 'react';
import { connect } from 'react-redux';
import { getUserSettingRequest, updateUserSettingRequest } from 'Actions/SettingAction/settingAction';
import SettingsDashboard from './SettingsDashboardComponent/SettingsDashboard';

const SettingsIndex = (props) => {
    return (
        <SettingsDashboard {...props} />
    )
}

const mapStateToProps = state => ({
    state: state,
    getUserSetting: state.Setting.Get_User_Setting,
    updateUserSetting:state.Setting.Update_User_Setting
});

const mapDispatchToProps = dispatch => {
    return {
        getUserSettingRequest:  (data) => dispatch(getUserSettingRequest(data)),
        updateUserSettingRequest:  (data) => dispatch(updateUserSettingRequest(data)),
    };
};

export default  connect(mapStateToProps, mapDispatchToProps)(SettingsIndex);
