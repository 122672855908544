import { createStore, applyMiddleware } from 'redux';
import CreateSagaMiddleware from 'redux-saga';
import { SagaMain } from "../Saga/SagaMain";
import rootReducer from "./Reducers/index";

const SagaMiddleware = CreateSagaMiddleware();

// let initialState = {};
const store = createStore(rootReducer, applyMiddleware(SagaMiddleware));
SagaMiddleware.run(SagaMain);

export default store;