
import React from 'react';
import Slider from 'react-rangeslider';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';
import 'react-rangeslider/lib/index.css';
import './Range.scss';

const Range = (props) => {
    const { volume, volumeYellow, handleOnChangeGreen, handleOnChangeYellow, greenCheck } = props
    return (
        <Card.Body>
            <div className='green'>
                <p className='cardRangeTitle' >Green highlight till {volume} days</p>
                <Slider
                    min={1}
                    max={7}
                    value={volume}
                    orientation="horizontal"
                    tooltip={false}
                    onChange={handleOnChangeGreen}
                />
            </div>
            <div className='yellow'>
                <p className='cardRangeTitle' >{greenCheck ? `Yellow highlight between ${volume} to ${volume + 1} days`:
                `Yellow highlight between ${volume} to ${volumeYellow>volume?volumeYellow:volume} days`}</p>
                <Slider
                    min={0}
                    max={14}
                    value={greenCheck ? volume-1 : volumeYellow}
                    orientation="horizontal"
                    tooltip={false}
                    onChange={handleOnChangeYellow}
                />
            </div>

            <div>
                <p className='cardRangeTitle'>Red highlight after {volumeYellow>volume?volumeYellow:volume} days</p>
            </div>
        </Card.Body>
    )
}
Range.propTypes = {
    volume: PropTypes.number,
    volumeYellow: PropTypes.number,
    greenCheck: PropTypes.bool,
    handleOnChangeGreen: PropTypes.func,
    handleOnChangeYellow: PropTypes.func
};
export default Range