import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/analytics'
import "firebase/performance";
import 'firebase/messaging';

var firebaseConfig = {
  // apiKey: "AIzaSyAa7NbkvcnmjQ2E8GMVr47dSBrBI1kjGXM",
  // authDomain: "chronicle-cloud-167518.firebaseapp.com",
  // databaseURL: "https://chronicle-cloud-167518.firebaseio.com",
  // projectId: "chronicle-cloud-167518",
  // storageBucket: "chronicle-cloud-167518.appspot.com",
  // messagingSenderId: "667932603706",
  // appId: "1:667932603706:web:2614cd1287caaf7c1d4c03",
  // measurementId: "G-CJMG2FFGGX"
  // apiKey: "AIzaSyDouK7jNFimZykL5XZNgYfS8_xNXEDhZxs",
  // authDomain: "np-chr-dev-mgmt-01.firebaseapp.com",
  // projectId: "np-chr-dev-mgmt-01",
  // storageBucket: "us-central-01-bucket-dev",
  // messagingSenderId: "885674553350",
  // appId: "1:885674553350:web:40fe83035d851751af5387"


  apiKey: "AIzaSyCQbpqHXyH5Eqr57HpAMgQtB4MoCeBF6q4",
  authDomain: "np-chr-stg-mgmt-01.firebaseapp.com",
  projectId: "np-chr-stg-mgmt-01",
  // storageBucket: "np-chr-stg-mgmt-01.appspot.com",
  storageBucket: "us-central-01-bucket-stg",
  messagingSenderId: "879480850061",
  appId: "1:879480850061:web:fb26839a77e25ccdb644c4",
  measurementId: "G-F7DEEJZXZE"

//   apiKey: "AIzaSyC0ng_p5nxvZ9TUCltio88ZWAMbVlsbWKY",
// authDomain: "np-chr-stg-us-central-01.firebaseapp.com",
// projectId: "np-chr-stg-us-central-01",
// storageBucket: "np-chr-stg-us-central-01.appspot.com",
// messagingSenderId: "184656335265",
// appId: "1:184656335265:web:0ef3b2564d587005213be0",
// measurementId: "G-G9K9H68HTV"
}
// Initialize Firebase

const firebaseAnalytics = firebase.initializeApp(firebaseConfig);
const firebaseSetProperties = firebase.analytics();
const perf = firebase.performance();
const storage = firebase.storage();
// const messaging = firebase.messaging();


let messaging = null;

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging()
}




const getToken = (setTokenFound) => {
  if (messaging && messaging?.getToken) {
    // return messaging.getToken({ vapidKey: 'BHWqeWRl3ReBgDgMNPVDPSIPyqyoyH0fjx8AV45CQ8VRl_xZ_ghuVr_oH3yyBfqsxn-XlN-6DohvUcPUPidNYDo' }
     // return messaging.getToken({ vapidKey: 'BHWqeWRl3ReBgDgMNPVDPSIPyqyoyH0fjx8AV45CQ8VRl_xZ_ghuVr_oH3yyBfqsxn-XlN-6DohvUcPUPidNYDo' }
    // {/* for dev*/}
    // return messaging.getToken({ vapidKey: 'BMBKxTo5c3vX-kVk8HsJODD3A7h7vM78njtCPIayThCKP7U-ubqQzU5XYVdouGrBxLvM5jcFqnFK-SIc0ZlNAbs' }
    {/* for stg*/}
    return messaging.getToken({ vapidKey: 'BFx1v5wvk6EzP6iweM6WpGbJDs0CZFDBo3fCSCP4SyOUl17fpuxOp__lIyUiKBB6qGzPiP0DIsJwTIQJ_mEtEHE' }
    ).then((currentToken) => {
      console.log('currentToken',currentToken)
      if (currentToken && currentToken.length > 0) {
        console.log('current token for client: ', currentToken);
        sessionStorage.setItem("FBDeviceToken", currentToken)
        setTokenFound(true);
      } else {
        setTokenFound(false);
      }
    }).catch((error) => {
      console.log(error)
    });
  } else {
    setTokenFound(false);
  }
}

const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export {
  storage, firebaseAnalytics, firebaseSetProperties, perf, getToken, messaging, onMessageListener, firebase as default
}