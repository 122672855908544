import {

    ADD_GROUP_NOTE,
    ADD_GROUP_NOTE_SUCCESS,
    ADD_GROUP_NOTE_FAILURE,

    EDIT_GROUP_NOTE,
    EDIT_GROUP_NOTE_SUCCESS,
    EDIT_GROUP_NOTE_FAILURE,

    GET_GROUP_NOTE,
    GET_GROUP_NOTE_SUCCESS,
    GET_GROUP_NOTE_FAILURE,

    DELETE_GROUP_NOTE,
    DELETE_GROUP_NOTE_SUCCESS,
    DELETE_GROUP_NOTE_FAILURE,
    UPGRADE_POP_UP_OPEN

} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */



// -----------Get Student Note-------------
export function getGroupNote(data) {
    return {
        type: GET_GROUP_NOTE,
        data,
    };
}

export function getGroupNoteSuccess(data) {
    return {
        type: GET_GROUP_NOTE_SUCCESS,
        data,
    };
}

export function getGroupNoteFailure(data) {
    return {
        type: GET_GROUP_NOTE_FAILURE,
        data,
    };
}


// -----------Edit Student Note-------------
export function editGroupNote(data, funObje) {
    return {
        type: EDIT_GROUP_NOTE,
        data,
        funObje
    };
}

export function editGroupNoteSuccess(data) {
    return {
        type: EDIT_GROUP_NOTE_SUCCESS,
        data,
    };
}

export function editGroupNoteFailure(data) {
    return {
        type: EDIT_GROUP_NOTE_FAILURE,
        data,
    };
}

// -----------Add Student Note-------------
export function addGroupNote(data, funObje) {
    return {
        type: ADD_GROUP_NOTE,
        data,
        funObje,
    };
}

export function addGroupNoteSuccess(data) {
    return {
        type: ADD_GROUP_NOTE_SUCCESS,
        data,
    };
}

export function addGroupNoteFailure(data) {
    return {
        type: ADD_GROUP_NOTE_FAILURE,
        data,
    };
}


export function upgradePopUpOpen(data) {
    return {
        type: UPGRADE_POP_UP_OPEN,
        data,
    };
}
// -----------Delete Student Note-------------
export function deleteGroupNote(data) {
    return {
        type: DELETE_GROUP_NOTE,
        data,
    };
}

export function deleteGroupNoteSuccess(data) {
    return {
        type: DELETE_GROUP_NOTE_SUCCESS,
        data,
    };
}

export function deleteGroupNoteFailure(data) {
    return {
        type: DELETE_GROUP_NOTE_FAILURE,
        data,
    };
}
