import { call, takeLatest, put } from 'redux-saga/effects';
import { createNotification } from 'Config/notificationtoast';
import userContactUsReducer from 'Redux/Reducers/ContactUsReducer/contactUsReducer';
import UserLoadingReducer from 'Redux/Reducers/CommonReducer/loadingReducer';
import { SEND_CONTACT_US_REQUEST } from 'Actions/constants';
import { SetContactUs_API } from 'Config/Api';
import { chroniclecloud_code } from 'Config/Constant';
import request from 'Config/request';
import { logOut } from 'Utils/Helper';


export function* userContactUsModuleSaga() {
    yield takeLatest(SEND_CONTACT_US_REQUEST, sendContactUsRequest);

}

// ---------- Get Data Usage Gradebook-----
function* sendContactUsRequest(action) {
    yield put(UserLoadingReducer.loading(true));
    const URL = SetContactUs_API;
    const BODY = JSON.stringify({
        "chroniclecloud_code": chroniclecloud_code,
        "name": action.data.name,
        "email": action.data.email,
        "subject": action.data.subject,
        "message": action.data.message
    })
    try {
        let response;
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": btoa(BODY) },
        });
        const MainDecode = JSON.parse(atob(response));
        yield put(UserLoadingReducer.loadingSuccess(false));
        if (MainDecode.status === 'Success') {
            createNotification('success', MainDecode.message);

            yield put(userContactUsReducer.sendContactUsSuccess(MainDecode));

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(userContactUsReducer.sendContactUsFailure(MainDecode));

        }
    } catch (error) {
        yield put(userContactUsReducer.sendContactUsFailure());
        yield put(UserLoadingReducer.loadingSuccess(false));
        createNotification('warning', 'Something went wrong');

    }
}
// ---------- Get Data Usage Gradebook------
