import React from 'react';
import { connect } from 'react-redux';
import ContactUsDashboard from './ContactUsDashboardComponent/ContactUsDashboard';
import { sendContactUsRequest } from 'Actions/ContactUsAction/contactUsAction';


const ContactUsIndex = (props) => {
    return (
        <ContactUsDashboard {...props} />
    )
}
const mapStateToProps = state => ({
    state: state,

});
const mapDispatchToProps = dispatch => {
    return {
        sendContactUsRequest: (data) => dispatch(sendContactUsRequest(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    ContactUsIndex
);