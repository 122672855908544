import { call, takeLatest, put } from 'redux-saga/effects';
import UserClassRosterReducer from 'Redux/Reducers/ClassReducer/classRosterReducer';
import UserClassRosterSelectReducer from 'Redux/Reducers/ClassReducer/classSelectRosterReducer';
import UserLoadingReducer from 'Redux/Reducers/CommonReducer/loadingReducer';
import { createNotification } from 'Config/notificationtoast';
import { GET_CLASS_ROSTER_LIST, ADD_CLASS_ROSTER, DELETE_CLASS_ROSTER, UPDATE_ROSTER_LEVEL, SEARCH_CLASS_ROSTER, IMPORT_CLASS_ROSTER } from 'Actions/constants';
import { decryptData, encryptData, getRandomColor } from "Utils/Helper";

import {
    AddRoster_API,
    GetRoster_API,
    SearchRoster_API,
    DeleteClassRoster_API,
    importClassRoster_API,
    EditRosterEnrolled_API,
} from 'Config/Api';
import {
    chroniclecloud_code
} from 'Config/Constant';
import { logOut } from 'Utils/Helper';
import request from 'Config/request';

export function* userClassRosterModuleSaga() {
    yield takeLatest(GET_CLASS_ROSTER_LIST, getClassRosterList);
    yield takeLatest(ADD_CLASS_ROSTER, addClassRoster);
    yield takeLatest(SEARCH_CLASS_ROSTER, searchClassRoster);
    yield takeLatest(DELETE_CLASS_ROSTER, deleteClassRoster);
    yield takeLatest(UPDATE_ROSTER_LEVEL, updateRosterLevel);
    yield takeLatest(IMPORT_CLASS_ROSTER, importClassRoster);
}

function* addClassRoster(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    // yield put(UserLoadingReducer.loading(true));
    // const URL = `${base_url_API}api_add_student_details`;
    const URL = AddRoster_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        // "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        // "student_color_code": getRandomColor(),
        // "academic_year_id": action.data.academic_year_id,
        // "student_pic_size": action.data.student_pic_size,
        // "student_first_name": action.data.student_first_name,
        // "student_last_name": action.data.student_last_name,
        // "student_registration_email_id": action.data.student_registration_email_id,
        // "student_secondary_email_id": action.data.student_secondary_email_id,
        // "student_pic_url": action.data.student_pic_url,
        // "student_id": action.data.student_id,
        // "class_id": action.data.class_id,
        // "by_class_sharing": action.data.by_class_sharing
        "role": data.user_type,
        "first_name": action.data.student_first_name,
        "last_name": action.data.student_last_name,
        "email": action.data.student_registration_email_id,
        "student_profile_url": action.data.student_pic_url,
        // "school_id": data.school_id,
        "teacher_id": data.cc_user_id,
        "academic_year_id": action.data.academic_year_id,
        "student_color_code": getRandomColor(),
        "class_id": `${action.data.class_id}`,
        "student_pic_size": `${action.data.student_pic_size}`,
        "by_class_sharing": action.data.by_class_sharing,
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            let value = {
                class_id: action.data.class_id,
                filter_by: action.data.addByViewSingleClass ? "by_class" :  action.data.filter_by,
                page_no: action.data.page_no,
                academic_year: action.data.academic_year_id,
                search_data : "",
                limit: action.data.limit
            }
            yield put(UserClassRosterReducer.addClassRosterSuccess(MainDecode));
            yield put(UserClassRosterReducer.getClassRosterList(value));
            // createNotification('success', MainDecode.message);

            // yield put(UserLoadingReducer.loadingSuccess(false));

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassRosterReducer.addClassRosterFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassRosterReducer.addClassRosterFailure(false));
    }
}


function* getClassRosterList(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${GetRoster_API}?user_id=${data.cc_user_id}&academic_year=${action.data.academic_year}&class_id=${action.data.class_id}&page_no=${action.data.page_no}&limit=${action?.data?.limit ? (action?.data?.limit > 0 ? action.data.limit : '10') : '10'}&orderBy=${action.data.orderBy ? action.data.orderBy : "firstName"}&filter_by=${action.data.filter_by}&search_data=${action.data.search_data}&platform=web`;
    const BODY = JSON.stringify({
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "filter_by": action.data.filter_by,
        "page_no": action.data.page_no,
        "academic_year": action.data.academic_year,
        "orderBy": action.data.orderBy ? action.data.orderBy : "firstName",
        "search_data": action.data.search_data
    })
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            yield put(UserClassRosterReducer.getClassRosterListSuccess(MainDecode));
            // yield put(UserLoadingReducer.loadingSuccess(false));

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            // createNotification('error', MainDecode.message);
            yield put(UserClassRosterReducer.getClassRosterListFailure(MainDecode));

        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassRosterReducer.getClassRosterListFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));

    }
}

function* deleteClassRoster(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = DeleteClassRoster_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        // "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        // "student_id": action.data.student_id,
        "class_id": action.data.class_id,
        "role": data.user_type,
        "student_id" : action.data.student_id,
        "is_deleted": "1",
        "platform": "web"

    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            let value = {
                class_id: action.data.class_id,
                filter_by: action.data.fromViewSingleClass ? 'by_class' : action.data.filter_by,
                page_no: action.data.page_no,
                academic_year: action.data.academic_year,
                search_data : "",
                limit: action?.data?.limit
            }

            yield put(UserClassRosterReducer.deleteClassRosterSuccess(MainDecode));
            yield put(UserClassRosterReducer.getClassRosterList(value));
            if(action.data.fromViewSingleClass) {
                let data = {
                    "class_id": action.data.class_id,
                    "filter_by": "not_in_current_class",
                    "sort_by": "sort_by_first_name",
                    "page_no": 1,
                    "academic_year": action.data.academic_year,
                    "search_text": "",
                    "by_date": "",
                    "date_flag": "",
    
                }
                yield put(UserClassRosterSelectReducer.getNotSelectedRosters(data));
            }
            // createNotification('success', MainDecode.message);

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassRosterReducer.deleteClassRosterFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassRosterReducer.deleteClassRosterFailure(false));
        // yield put(UserLoadingReducer.loadingSuccess(false));
    }
}

function* updateRosterLevel(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = EditRosterEnrolled_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "student_id": JSON.stringify(action.data.student_id),
        // "device_udid": sessionStorage.getItem('UDID'),
        "student_details_array": (action.data.student_details_array),
        // "screen_name": action.data.screen_name,
        "class_id": JSON.stringify(action.data.class_id),
        "screen_name": "class_roster",
        "platform" : "web"

    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            let value = {
                class_id: action.data.class_id,
                filter_by: action.data.filter_by,
                page_no: action.data.page_no,
                academic_year: action.data.academic_year,
                search_data : "",
                limit: action?.data?.limit,
                orderBy: action?.data?.orderBy
            }

            yield put(UserClassRosterReducer.updateRosterLevelSuccess(MainDecode));
            yield put(UserClassRosterReducer.getClassRosterList(value));
            createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassRosterReducer.updateRosterLevelFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassRosterReducer.updateRosterLevelFailure(false));
    }
}


function* searchClassRoster(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${SearchRoster_API}?user_id=${data?.cc_user_id}&search_string=${action.data.search_data}&academic_year_id=${action.data.academic_year}&class_id=${action.data.class_id}&page=1&limit=${action?.data?.limit ? action?.data?.limit > 0 ? action?.data?.limit : '10' : '10'}&platform=web`;
    const BODY = JSON.stringify({

        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "search_data": action.data.search_data,
        // "student_type": action.data.student_type,
        "academic_year": action.data.academic_year,
    })
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserClassRosterReducer.searchClassRosterSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassRosterReducer.searchClassRosterFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassRosterReducer.searchClassRosterFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));
    }
}


function* importClassRoster(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = importClassRoster_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "student_data": action.data.student_data,
        "academic_year_id": action.data.academic_year_id,
        "platform" : 'web'
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res)
        if (MainDecode.status === 200) {
            let value = {
                "class_id": action.data.class_id,
                "filter_by": action.data.filter_by,
                "page_no": action.data.page_no,
                "academic_year": action.data.academic_year_id,
                "search_data" : "",
                "limit": action.data.limit
            }

            yield put(UserClassRosterReducer.importClassRosterSuccess(MainDecode));
            yield put(UserClassRosterReducer.getClassRosterList(value));
            // createNotification('success', MainDecode.message);

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassRosterReducer.importClassRosterFailure(MainDecode));

        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassRosterReducer.importClassRosterFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));

    }
}