import { call, takeLatest, put } from 'redux-saga/effects';
import UserClassShareReducer from 'Redux/Reducers/ClassReducer/classShareReducer';
import { createNotification } from 'Config/notificationtoast';
import {
    GET_SCHOOL_TEACHER,
    SHARE_STUDENT,
    SHARE_CLASS
} from 'Actions/constants';
import {
    GetSchoolTeacher_API,
    ShareStudent_API,
    ShareClass_API,
} from 'Config/Api';
import { chroniclecloud_code, device_token_id, device_type } from 'Config/Constant';
import { logOut } from 'Utils/Helper';
import request from 'Config/request';
import { getImage } from "Config/commonFirebaseImage";
import { decryptData, encryptData } from 'Utils/Helper';

export function* userClassShareModuleSaga() {
    yield takeLatest(GET_SCHOOL_TEACHER, getSchoolTeacher);
    yield takeLatest(SHARE_STUDENT, shareStudent);
    yield takeLatest(SHARE_CLASS, shareClass);
}

function* getSchoolTeacher(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    // const URL = GetSchoolTeacher_API;
    const URL = `${GetSchoolTeacher_API}?user_id=${data.cc_user_id}&school_name=${data.cc_user_school_name}&page=${action.data.page_no}&limit=${action.data.limit ? action.data.limit : '500'}&platform=web`;
    const BODY = JSON.stringify({
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "school_name": data.cc_user_school_name,
        "page_no": action.data.page_no
    })
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            for (let shareData of MainDecode.data.teacher_data) {
                if (shareData.cc_user_pic) {
                    if (!(shareData.cc_user_pic.includes("https") === true)) {
                        shareData.cc_user_pic = yield getImage(shareData.cc_user_pic);
                    }
                }
            }
            yield put(UserClassShareReducer.getSchoolTeacherSuccess(MainDecode));
            createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassShareReducer.getSchoolTeacherFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassShareReducer.getSchoolTeacherFailure(false));
    }
}

function* shareStudent(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = ShareStudent_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        // "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        // "permision_type": action.data.permisionType,
        // "student_id": action.data.studentId,
        // "shared_user_id": action.data.sharedUserId,
        // "device_token_id": sessionStorage.getItem("FBDeviceToken"),
        // "device_type": device_type

        "user_id": data.cc_user_id,
        "student_id": action.data.studentId,
        "permision_type": action.data.permisionType,
        "device_token_id": sessionStorage.getItem("FBDeviceToken"),
        "device_type": device_type,
        "shared_user_id":action.data.sharedUserId,
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserClassShareReducer.shareStudentSuccess(MainDecode));
            createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            if (MainDecode.message === "Note limit has been exceeded. Please upgrade the plan"
                || "Media limit has been exceeded. Please upgrade the plan"
                || "Please update your subscription plan") {
                yield put(UserClassShareReducer.upgradePopUpOpen(MainDecode));
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassShareReducer.shareStudentFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassShareReducer.shareStudentFailure(false));
    }
}

function* shareClass(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = ShareClass_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "permision_type": action.data.permisionType,
        "class_id": action.data.classId,
        "shared_user_id": action.data.sharedUserId,
        "platform": "web"
        // "device_token_id": "string",
        // "device_type": "string",
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserClassShareReducer.shareClassSuccess(MainDecode));
            createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassShareReducer.shareClassFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassShareReducer.shareClassFailure(false));
    }
}