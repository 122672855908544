
import {

    GET_FORMATIVE_ASSESSMENT_REQUEST,
    GET_FORMATIVE_ASSESSMENT_SUCCESS,
    GET_FORMATIVE_ASSESSMENT_FAILURE,

    GET_ASSESSMENT_STUDENTS_LIST_REQUEST,
    GET_ASSESSMENT_STUDENTS_LIST_SUCCESS,
    GET_ASSESSMENT_STUDENTS_LIST_FAILURE,

    GET_ALL_STUDENTS_LIST_REQUEST,
    GET_ALL_STUDENTS_LIST_SUCCESS,
    GET_ALL_STUDENTS_LIST_FAILURE,

    ADD_ASSESSMENT_TAG_REQUEST,
    ADD_ASSESSMENT_TAG_SUCCESS,
    ADD_ASSESSMENT_TAG_FAILURE,

    EDIT_ASSESSMENT_TAG_REQUEST,
    EDIT_ASSESSMENT_TAG_SUCCESS,
    EDIT_ASSESSMENT_TAG_FAILURE,

    DELETE_ASSESSMENT_TAG_REQUEST,
    DELETE_ASSESSMENT_TAG_SUCCESS,
    DELETE_ASSESSMENT_TAG_FAILURE,


    UPDATE_COLOR_ASSESSMENT_TAG_REQUIRED,

    UPDATE_SELECTED_ASSESSMENT_TAG_REQUIRED


} from '../constants';

// -----------Get Formative Assessment-------------
export function getFormativeAssessmentRequest(data) {
    return {
        type: GET_FORMATIVE_ASSESSMENT_REQUEST,
        data,
    };
}

export function getFormativeAssessmentSuccess(data) {
    return {
        type: GET_FORMATIVE_ASSESSMENT_SUCCESS,
        data,
    };
}

export function getFormativeAssessmentFailure(data) {
    return {
        type: GET_FORMATIVE_ASSESSMENT_FAILURE,
        data,
    };
}




// -----------Get Assessment Tag Student-------------
export function getAssessmentStudentsListRequest(data) {
    return {
        type: GET_ASSESSMENT_STUDENTS_LIST_REQUEST,
        data,
    };
}

export function getAssessmentStudentsListSuccess(data) {
    return {
        type: GET_ASSESSMENT_STUDENTS_LIST_SUCCESS,
        data,
    };
}

export function getAssessmentStudentsListFailure(data) {
    return {
        type: GET_ASSESSMENT_STUDENTS_LIST_FAILURE,
        data,
    };
}


// -----------Get All Students List-------------
export function getAllStudentsListRequest(data) {
    return {
        type: GET_ALL_STUDENTS_LIST_REQUEST,
        data,
    };
}

export function getAllStudentsListSuccess(data) {
    return {
        type: GET_ALL_STUDENTS_LIST_SUCCESS,
        data,
    };
}

export function getAllStudentsListFailure(data) {
    return {
        type: GET_ALL_STUDENTS_LIST_FAILURE,
        data,
    };
}


// -----------Add  Assessment Tag-------------
export function addAssessmentTagRequest(data) {
    return {
        type: ADD_ASSESSMENT_TAG_REQUEST,
        data,
    };
}

export function addAssessmentTagSuccess(data) {
    return {
        type: ADD_ASSESSMENT_TAG_SUCCESS,
        data,
    };
}

export function addAssessmentTagFailure(data) {
    return {
        type: ADD_ASSESSMENT_TAG_FAILURE,
        data,
    };
}


// -----------Edit  Assessment Tag-------------
export function editAssessmentTagRequest(data) {
    return {
        type: EDIT_ASSESSMENT_TAG_REQUEST,
        data,
    };
}

export function editAssessmentTagSuccess(data) {
    return {
        type: EDIT_ASSESSMENT_TAG_SUCCESS,
        data,
    };
}

export function editAssessmentTagFailure(data) {
    return {
        type: EDIT_ASSESSMENT_TAG_FAILURE,
        data,
    };
}


// -----------Delete  Assessment Tag-------------
export function deleteAssessmentTagRequest(data) {
    return {
        type: DELETE_ASSESSMENT_TAG_REQUEST,
        data,
    };
}

export function deleteAssessmentTagSuccess(data) {
    return {
        type: DELETE_ASSESSMENT_TAG_SUCCESS,
        data,
    };
}

export function deleteAssessmentTagFailure(data) {
    return {
        type: DELETE_ASSESSMENT_TAG_FAILURE,
        data,
    };
}



// update Color Assessment Tag Required
export function updateColorAssessmentTagRequired(data) {
    return {
        type: UPDATE_COLOR_ASSESSMENT_TAG_REQUIRED,
        data,
    };
}

// update Selected Assessment Tag Required
export function updateSelectedAssessmentTagRequired(data) {
    return {
        type: UPDATE_SELECTED_ASSESSMENT_TAG_REQUIRED,
        data,
    };
}


