import React from 'react';
import { connect } from 'react-redux';
import UpgradePlanDashboard from './UpgradePlanDashboardComponent/UpgradePlanDashboard';
import { getSubscriptionPlanRequest, getStripeProducts } from 'Actions/SubscriptionPlanAction/subscriptionPlanAction';
import { getDataUsageRequest } from 'Actions/DataUsageAction/dataUsageAction';
import { addPaymentStatusSuccess } from 'Actions/PayPalPaymentAction/payPalPaymentAction';
import { getUserPaymentDetails } from 'Actions/AuthAction/loginAction';


const UpgradePlanIndex = (props) => {
    return (
        <UpgradePlanDashboard {...props} />
    )
}
const mapStateToProps = state => ({
    state: state,
    Plans: state.SubscriptionPlan.plans,
    loader: state.SubscriptionPlan.loader,
    DataUsage: state.DataUsage.DataUsage,
    Usageloader: state.DataUsage.loader,
    stripePlans: state?.SubscriptionPlan?.stripeProducts?.data,
    userPaymentDetailsData:state?.login?.userPaymentDetails

});
const mapDispatchToProps = dispatch => {
    return {
        getSubscriptionPlanRequest: (data) => dispatch(getSubscriptionPlanRequest(data)),
        getDataUsageRequest: (data) => dispatch(getDataUsageRequest(data)),
        addPaymentStatusSuccess: (data) => dispatch(addPaymentStatusSuccess(data)),
        getStripeProducts: (data) => dispatch(getStripeProducts(data)),
        getUserPaymentDetails: (data) => dispatch(getUserPaymentDetails(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    UpgradePlanIndex
);
