import React, { Fragment, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { connect, useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from 'react-bootstrap/Modal';
import back from "Assets/CommonComponent/back.png";
import addNoteIcon from "Assets/NoteModule/plusNew.svg";
import "./ClassNoteView.scss";
import * as routes from "../../../../Router/RoutesURL";
import { classGroupNoteArtifact, classGroupNoteAttachementArtifact, classGroupNoteDelete, getClassGroupNotesData } from "Actions/NoteAction/classNoteAction";
import { getNotesImage } from "Config/commonFirebaseImage";
import playIcon from "Assets/NoteModule/play.svg";
import closeIcon from 'Assets/NoteModule/close.svg';
import note_pdf from "Assets/NoteModule/note_pdf.png";
import note_doc from "Assets/NoteModule/note_doc.png";
import note_csv from "Assets/NoteModule/note_csv.png";
import note_ppt from "Assets/NoteModule/note_ppt.png";
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import note_xls from "Assets/NoteModule/note_xls.png";
import warningIcon from "Assets/NoteModule/warning-icon.png";
import micIcon from "Assets/NoteModule/mic.svg";
import StudentNoteTimeline from "Screens/NoteModule/StudentNoteModule/StudentNoteTimelineComponent/StudentNoteTimeline";
import GroupNotesTimeline from "./GroupNotesTimeline";
import GroupNoteExpandedTimeline from "./GroupNoteExpandedTimeline";
import Carousel from "Screens/NoteModule/StudentNoteModule/StudentNoteDashboardComponent/Carousel";
import { Item } from "Screens/NoteModule/StudentNoteModule/StudentNoteDashboardComponent/components";
import IframeGoogleDoc from "Screens/NoteModule/StudentNoteModule/IframeGoogleDoc";
import { createNotification } from "Config/notificationtoast";

function ClassNoteView(props) {

  const dispatch = useDispatch();
  const [tabView, setTabView] = useState("timeLine");
  const [filterBy, setFilterBy] = useState("both");
  const [deleteGroupNoteData, setDeleteGroupNoteData] = useState({})
  const [confirmationShow, setConfirmationShow] = useState(false);
  const [showAttachement, setShowAttachment] = useState(false)
  const [showDocAttachement, setShowDocAttachement] = useState(false)
  const [showDocAttachementURL, setShowDocAttachementURL] = useState('')
  const [showDocAttachementName, setShowDocAttachementName] = useState('')
  const [showDocAttachementType, setShowDocAttachementType] = useState('')
  const [showDocAttachementId, setShowDocAttachementId] = useState(0)
  const [isDocMediaArtified, setIsDocMediaArtified] = useState('no')
  const [showAttachementURL, setShowAttachementURL] = useState('')
  const [showAttachementName, setShowAttachementName] = useState('')
  const [showAttachementType, setShowAttachementType] = useState('')
  const [showAttachementId, setShowAttachementId] = useState(0)
  const [isMediaArtified, setIsMediaArtified] = useState('no')
  const [selectedDocIndex, setSelectedDocIndex] = useState(0)
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0)
  const [classPermission, setClassPermission] = useState('')
  const [pageNo, setPageNo] = useState(1);
  const [groupNotesData, setGroupNotesData] = useState(null);
  const [notesData, setNotesData] = useState([]);
  const [totalNoteCount, setTotalNoteCount] = useState(0);
  const [classCoreStandardn, setClassCoreStandard] = useState([]);
  const [notesAttachementData, setNotesAttachmentData] = useState(null);
  const [videoAttachment, setVideoAttachment] = useState(null);
  const [audioAttachment, setAudioAttachment] = useState([]);
  const [docAttachment, setDocAttachment] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  

  const classGroupNoteStudent = useSelector(
    (state) => state?.classNote?.getClassGroupNote.student_list
  );


  const classGroupNotesDataHere = useSelector(
    (state) => state?.classNote?.getClassGroupNote
  );
  console.log('classGroupNotesDataHere :>> ', classGroupNotesDataHere);
  const loader = useSelector((state) => state?.classNote?.loader)



  useEffect(() => {
    const data = {
      academicYearId: props?.history.location.state.academicYearId,
      studentId:
        props?.history?.location?.state?.selectedStudentsForGroupNote[
        props?.history?.location?.state?.selectedStudentsForGroupNote
          ?.length - 1
        ] || 0,
      classId: props?.history?.location?.state?.class_id,
      groupId: props?.history?.location?.state?.groupDetail?.cc_group_id,
      pageNo: pageNo,
      filter: filterBy,
      limit: 100,
    };
    dispatch(getClassGroupNotesData(data));
  }, [filterBy]);

  useEffect(() => {
    setGroupNotesData(classGroupNotesDataHere);
  }, [classGroupNotesDataHere]);

  useEffect(() => {
    let AttachemtData = [];
    setTotalNoteCount(groupNotesData?.note_count);
    setNotesData(groupNotesData?.response_data);
    for (let i = 0; i < groupNotesData?.response_data?.length; i++) {
      for (
        let j = 0;
        j < groupNotesData?.response_data?.[i]?.note_attachment?.length;
        j++
      ) {
        AttachemtData.push(
          groupNotesData?.response_data?.[i]?.note_attachment[j]
        );
      }
    }
    setNotesAttachmentData(AttachemtData);
    forceUpdate()
    // if (pageNo > 1) {
    //   let AllNoteData = notesData;
    //   for (let item of groupNotesData?.response_data) {
    //     AllNoteData.push(item);
    //   }
    //   setNotesData(AllNoteData)
    //   setTotalNoteCount(groupNotesData?.note_count)
    //   AttachemtData = AllNoteData;
    // } else {
    //     setGroupNotesData(groupNotesData)
    //   this.setState({
    //     StudentAllNoteDetails: nextProps.notesData.Get_Student_Note,
    //     class_core_standard:
    //       nextProps.notesData.Get_Student_Note.class_core_standard,
    //     NotesData: nextProps?.notesData?.Get_Student_Note?.data?.response_data,
    //     student_details: nextProps.notesData.Get_Student_Note.student_details,
    //     student_enrolled_classes: nextProps.notesData.Get_Student_Classes,
    //     totalNoteCount:
    //       nextProps?.notesData?.Get_Student_Note?.data.total_count,
    //     classPermission:
    //       nextProps.notesData.Get_Student_Classes?.[0]
    //         ?.cc_class_share_permission,
    //   });
    //   AttachemtData =
    //     nextProps?.notesData?.Get_Student_Note?.data?.response_data;
    // }
  }, [groupNotesData]);
   const classnoteviewstate = useSelector((state) => state)
   console.log('classnoteviewstate :>> ', classnoteviewstate);
  useEffect(() => {
    getAttachementFirebaseData(notesAttachementData);
  }, [notesAttachementData]);

  const getAttachementFirebaseData = async (data) => {

    let videoAttachment = [];
    let audioAttachment = [];
    let docAttachment = [];
    if (data?.length > 0) {
      for (let attachmnt of data) {
        if (attachmnt.cc_attachment_url.includes("https") === true) {
          if (attachmnt.cc_attachment_type === "audio") {
            audioAttachment.push(attachmnt);
            attachmnt.cc_attachment_fullurl = attachmnt.cc_attachment_url;
          } else if (attachmnt.cc_attachment_type === "other") {
            docAttachment.push(attachmnt);
            attachmnt.cc_attachment_fullurl = attachmnt.cc_attachment_url;
          } else {
            videoAttachment.push(attachmnt);
            attachmnt.cc_attachment_fullurl = attachmnt.cc_attachment_url;
          }
        } else {
          let fullUrl = await getNotesImage(
            attachmnt.cc_attachment_url,
            props?.history?.location?.state?.class_id
          );

          attachmnt.cc_attachment_fullurl = fullUrl;
          if (attachmnt.cc_attachment_type === "audio") {
            audioAttachment.push(attachmnt);
          } else if (attachmnt.cc_attachment_type === "other") {
            docAttachment.push(attachmnt);
          } else {
            videoAttachment.push(attachmnt);
          }
        }
        setVideoAttachment(videoAttachment);
        setAudioAttachment(audioAttachment);
        setDocAttachment(docAttachment);
        forceUpdate();
      }
    } else {
      setVideoAttachment([])
      setAudioAttachment([]);
      setDocAttachment([]);
      forceUpdate()
    }
  };

  const OpenAttachementModal = (item) => {
    let attachmentIndex = videoAttachment.indexOf(item)
    setShowAttachment(true)
    setShowAttachementURL(item.cc_attachment_fullurl)
    setShowAttachementName(item.cc_attachment_name)
    setShowAttachementType(item.cc_attachment_type)
    setShowAttachementId(item.cc_attachment_id)
    setIsMediaArtified(item.cc_media_mark_artified)
    setSelectedMediaIndex(attachmentIndex)
  };

  const OpenDocAttachementModal = (value) => {
    let attachmentIndex = docAttachment.indexOf(value)
    let encodeUrlStr = encodeURIComponent(value.cc_attachment_fullurl)
    setShowDocAttachement(true)
    setShowDocAttachementURL(encodeUrlStr)
    setShowDocAttachementName(value.cc_attachment_name)
    setShowDocAttachementType(value.cc_attachment_type)
    setShowDocAttachementId(value.cc_attachment_id)
    setIsDocMediaArtified(value.cc_media_mark_artified)
    setSelectedDocIndex(attachmentIndex)
  };

  const deleteGroupNote = (value) => {
    setDeleteGroupNoteData(value)
    setConfirmationShow(true)
  }

  const confirmationDelete = () => {
    const data = {
      note_ids: deleteGroupNoteData?.data?.cc_note_batch_ids,
      academicYearId: props?.history.location.state.academicYearId,
      studentId:
        props?.history?.location?.state?.selectedStudentsForGroupNote[
        props?.history?.location?.state?.selectedStudentsForGroupNote
          ?.length - 1
        ],
      classId: props?.history?.location?.state?.class_id,
      groupId: props?.history?.location?.state?.groupDetail?.cc_group_id,
      pageNo: pageNo,
      filter: filterBy,
      limit: 100,
    }
    dispatch(classGroupNoteDelete(data))
    setConfirmationShow(false)
  };

  const addGroupNoteHandler = () => {
    console.log('loader :>> ', loader);
    if(props?.history?.location?.state?.selectedStudentsForGroupNote?.length > 1) {
    props?.history?.push(routes.CLASSADDNOTE, {
      studentsInfo: props?.history?.location?.state?.studentsInfo,
      selectedStudentsForGroupNote: props?.history?.location?.state?.selectedStudentsForGroupNote,
      classDetails: props?.history?.location?.state?.classDetails,
      pageType: 'New',
      data: '',
      academicYear: props?.history?.location?.state?.academicYear,
      student_enrolled_classes: props?.history?.location?.state?.student_enrolled_classes,
      NotesData: "",
      StudentAllNoteDetails: [],
      class_id: props?.history?.location?.state?.class_id,
      academicYearId: props?.history?.location?.state?.academicYearId,
      groupDetail: props?.history?.location?.state?.groupDetail,
      classGroupNotesDataHere : classGroupNotesDataHere
    })
    } else {
      createNotification('error', 'Add at least two students in the Group to create Note.');
    }
  }

  const editGroupNoteHandler = (value) => {
    let customObj = {
      data: groupNotesData
    }
    console.log('NotesData 9966 :>> ', notesData);
    props.history.push(routes.CLASSADDNOTE, {
      pageType: "Edit",
      data: value,
      studentInfo: classGroupNoteStudent,
      academicYear: props?.history?.location?.state?.academicYear,
      student_enrolled_classes: groupNotesData?.student_enrolled_classes,
      NotesData: notesData,
      StudentAllNoteDetails: customObj,
      class_id: props?.history?.location?.state?.class_id,
      classDetails: props?.history?.location?.state?.classDetails,
      studentsInfo: props?.history?.location?.state?.studentsInfo,
      selectedStudentsForGroupNote: props?.history?.location?.state?.selectedStudentsForGroupNote,
      academicYearId: props?.history?.location?.state?.academicYearId,
      groupDetail: props?.history?.location?.state?.groupDetail,
      classGroupNotesDataHere : classGroupNotesDataHere
    });
  }

  const onSlide = (item) => {
    if (item === "prev") {
      let idVal = selectedMediaIndex - 1
      if (idVal >= 0) {
        setSelectedMediaIndex(idVal)
      } else {
        setSelectedMediaIndex(videoAttachment.length - 1)
      }
    }
    if (item === "next") {
      let idVal = selectedMediaIndex
      if (idVal >= videoAttachment.length - 1) {
        setSelectedMediaIndex(0)
      } else {
        setSelectedMediaIndex(idVal + 1)
      }
    }
  }

  const setMediaArtifact = (item) => {
    console.log('item :>> ', item);
  
    // Determine the attachment type: 'video' or 'audio'
    const isAudio = item?.cc_attachment_type === "audio";
    const isVideo = item?.cc_attachment_type === "video";
  
    const updateMediaValue = (mediaList) => {
      return mediaList.map((val, i) => {
        if (val?.cc_attachment_id === item?.cc_attachment_id) {
          return { ...val, cc_media_mark_artified: item?.cc_media_mark_artified === "no" ? "yes" : "no" };
        } else {
          return { ...val };
        }
      });
    };
  
    if (item?.cc_media_mark_artified === "no" || item?.cc_media_mark_artified === "") {
      let mediaValue = isVideo ? updateMediaValue(videoAttachment) : updateMediaValue(audioAttachment);
      isVideo ? setVideoAttachment(mediaValue) : setAudioAttachment(mediaValue);  // Handle both video and audio attachments
  
      const apiData = {
        "cc_attachment_id": item?.cc_attachment_id,
        "cc_media_mark_artified": "yes",
        "class_id": props?.history?.location?.state?.class_id,
        "academicYearId": props?.history.location.state.academicYearId,
        "studentId":
          props?.history?.location?.state?.selectedStudentsForGroupNote[
          props?.history?.location?.state?.selectedStudentsForGroupNote?.length - 1
          ],
        "classId": props?.history?.location?.state?.class_id,
        "groupId": props?.history?.location?.state?.groupDetail?.cc_group_id,
        "pageNo": pageNo,
        "filter": filterBy,
        "limit": 100,
      };
      dispatch(classGroupNoteAttachementArtifact(apiData));
    } else {
      let mediaValue = isVideo ? updateMediaValue(videoAttachment) : updateMediaValue(audioAttachment);
      isVideo ? setVideoAttachment(mediaValue) : setAudioAttachment(mediaValue);  // Handle both video and audio attachments
  
      const apiData = {
        "cc_attachment_id": item?.cc_attachment_id,
        "cc_media_mark_artified": "no",
        "class_id": props?.history?.location?.state?.class_id,
        "academicYearId": props?.history.location.state.academicYearId,
        "studentId":
          props?.history?.location?.state?.selectedStudentsForGroupNote[
          props?.history?.location?.state?.selectedStudentsForGroupNote?.length - 1
          ],
        "classId": props?.history?.location?.state?.class_id,
        "groupId": props?.history?.location?.state?.groupDetail?.cc_group_id,
        "pageNo": pageNo,
        "filter": filterBy,
        "limit": 100,
      };
      dispatch(classGroupNoteAttachementArtifact(apiData));
    }
  };
  

  const setDocArtifact = (item) => {
    if (item.cc_media_mark_artified === "no" || "") {
      let mediaValue = docAttachment.map((val, i) => {
        if (val.cc_attachment_id === item.cc_attachment_id) {
          return { ...val, cc_media_mark_artified: "yes" }
        } else {
          return { ...val }
        }
      })
      setDocAttachment(mediaValue)
      const apiData = {
        "cc_attachment_id": item?.cc_attachment_id,
        "cc_media_mark_artified": "yes",
        "class_id": props?.history?.location?.state?.class_id,
        "academicYearId": props?.history.location.state.academicYearId,
        "studentId": props?.history?.location?.state?.selectedStudentsForGroupNote[
          props?.history?.location?.state?.selectedStudentsForGroupNote
            ?.length - 1
        ],
        "classId": props?.history?.location?.state?.class_id,
        "groupId": props?.history?.location?.state?.groupDetail?.cc_group_id,
        "pageNo": pageNo,
        "filter": filterBy,
        "limit": 100,
      }
      dispatch(classGroupNoteAttachementArtifact(apiData))
      // this.props.setMediaAttachmentArtifact(apiData)
    } else {
      let mediaValue = docAttachment.map((val, i) => {
        if (val?.cc_attachment_id === item?.cc_attachment_id) {
          return { ...val, cc_media_mark_artified: "no" }
        } else {
          return { ...val }
        }
      })
      setDocAttachment(mediaValue)
      const apiData = {
        "cc_attachment_id": item?.cc_attachment_id,
        "cc_media_mark_artified": "no",
        "class_id": props?.history?.location?.state?.class_id,
        "academicYearId": props?.history.location.state.academicYearId,
        "studentId": props?.history?.location?.state?.selectedStudentsForGroupNote[
          props?.history?.location?.state?.selectedStudentsForGroupNote
            ?.length - 1
        ],
        "classId": props?.history?.location?.state?.class_id,
        "groupId": props?.history?.location?.state?.groupDetail?.cc_group_id,
        "pageNo": pageNo,
        "filter": filterBy,
        "limit": 100,
      }
      dispatch(classGroupNoteAttachementArtifact(apiData))
      // this.props.setMediaAttachmentArtifact(apiData)
    }
  }

  //---------- set artifcat--------------
  const setArtifact = (data) => {
    let cc_note_mark_artified = ''
    if (data?.student_Note_details?.cc_note_mark_artified === "no") {
        cc_note_mark_artified = 'yes';
    } else {
        cc_note_mark_artified = 'no'
    }
    const note_batch_ids = data?.student_Note_details?.cc_note_batch_ids.split(',').map(id => parseInt(id));
    const student_ids = data?.student_Note_details?.cc_student_arr.split(',').map(id => parseInt(id));
    const student_details_array = student_ids.map((student_id, index) => {
      const student_note_id = note_batch_ids[index];
      return {
        student_id: student_id.toString(),
        student_note_id: student_note_id,
        student_marked: cc_note_mark_artified
      };
    });
    let data2 = {
      "class_id": props?.history?.location?.state?.class_id,
      "student_details_array": student_details_array,
      "academicYearId": props?.history.location.state.academicYearId,
      "studentId": props?.history?.location?.state?.selectedStudentsForGroupNote[
        props?.history?.location?.state?.selectedStudentsForGroupNote
          ?.length - 1
      ],
      "groupId": props?.history?.location?.state?.groupDetail?.cc_group_id,
      "pageNo": pageNo,
      "filter": filterBy,
      limit: 100,
      }
      dispatch(classGroupNoteArtifact(data2))
    // const { location = {} } = this.props
    // const { state = {} } = location;
    // const { studentInfo = '' } = state;

    // let cc_note_mark_artified = ''
    // if (data?.student_Note_details?.cc_note_mark_artified === "no") {
    //     cc_note_mark_artified = 'yes';
    // } else {
    //     cc_note_mark_artified = 'no'
    // }
    // const { class_id, filter_by } = this.state;

    // const value = {
    //     "class_id": class_id,
    //     "student_details_array": [{
    //         "student_id": data?.student_Note_details?.cc_student_id,
    //         "student_note_id": data?.student_Note_details?.cc_note_id,
    //         "student_marked": cc_note_mark_artified
    //     }],
    //     "student_id": studentInfo?.cc_student_id,
    //     "filter_by": filter_by,
    //     "page_no": 1,
    //     "student_attachment_id": data?.student_Note_details?.note_attachment[0]?.cc_attachment_id,
    //     "academic_id" : this.props?.history?.location?.state?.academicYearIDFromClassNote
    // }
    // this.setState({
    //     page_no: 1
    // })

    // this.props.setStudentArtifact(value);
}
  //---------- set artifact ------------

  const BackToDash = () => {
    props.history.replace("/home/academic");
  };

  return (
    <Fragment>
      <div className="ClassGroupNoteViewComponent">
        <div className="CNAG-classNoteAddGroupkMainContainer">
          <Row className="mb-3">
            <Col md="12" lg="12" className="CNAG-main-section">
              <Card className="header">
                <Card.Body>
                  <Row>
                    <Col xs="12" md="6" className="CNAG-header-title">
                      {/* <div className="CNAG-border-right">
                                            <Button className="back-btn" variant="secondary" onClick={() => { history.goBack() }}>
                                                <i className="fa fa-chevron-left" aria-hidden="true"></i>Class Notes {academicYear}
                                            </Button>
                                        </div> */}
                      {/* {classDetails && <span className="year">{classDetails.cc_class_name} Group</span>} */}
                      <div className="CNAG-border-right">
                        {/* <img
                          className="calIcon"
                          src={back}
                          alt=""
                          width="60"
                          height="40"
                          onClick={() => {
                            BackToDash();
                          }}
                        /> */}
                        <div className='backBttn classNoteViewBackBtn' style={{ width: '40px', height: '40px', borderRadius: '0.5rem', alignItems: 'center', display: 'flex', marginLeft: '10px', justifyContent: 'center' }}
                          // onClick={goToHome}
                          onClick={() => { props?.history?.goBack() }}
                        >
                          <i className='attClassBackIcon material-icons' style={{ color: '#ffffff', fontSize: '30px', marginBottom: '3px' }} >chevron_left </i>
                        </div>
                        <div className="CNAG-DetailTextDiv">
                          <div
                            className={`CNAG-DisplayFlex ${props.history.location.state.BackPage ==
                              "ClassDB" && "w-100"
                              }`}
                          >
                            {/* <p className='CNAG-currentYear' onClick={() => {  BackToDash()}}><b>{academicYear}</b></p> */}
                            {/* <i className="classForIcon material-icons">chevron_right</i> */}
                            {props.history.location.state.BackPage ==
                              "ClassDB" && (
                                <>
                                  <p
                                    className="stdNoteGrade1"
                                    onClick={() => {
                                      props.history.goBack();
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Class
                                  </p>
                                  <i className="classForIcon material-icons">
                                    chevron_right
                                  </i>
                                </>
                              )}
                            {/* <p className='stdNoteGrade1' onClick={() => { BackTOHome() }}>Roster</p> */}
                            {/* <i className="classForIcon material-icons">chevron_right</i> */}
                            <p className="CNAG-stdNoteGrade2">
                              {
                                props?.history?.location?.state?.groupDetail
                                  ?.cc_group_name
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {/* <Col xs="12" md="4" className="m-auto CNAG-searchBar">
                                        <div className="CNAG-dropdownMainContainer">
                                            <div className="iconDiv"><i className="fa fa-search CNAG-searchFiltersIcon"></i></div>
                                           
                                            <SelectSearch
                                                ref={searchInput}
                                                options={allClassses}
                                                // filterOptions={handleFilter}
                                                value={(sessionStorage.getItem('noteClassName') && sessionStorage.getItem('noteClassName').length > 0) ? {name : sessionStorage.getItem('noteClassName'), value: 0} :  (isSelected ? { name: selectedClassName2 ? selectedClassName2 : classDetails?.details?.cc_class_name, value: 0 } : { name: selectedClassName ? selectedClassName : classDetails?.details?.cc_class_name, value: 0 })}
                                                name="Workshop"
                                                placeholder="Search class*"
                                                search
                                                onChange={handleChange}
                                                disabled={addNewGroupBtnShow === true || props?.location?.state?.backpage == "singlecls"}
                                                filterOptions={fuzzySearch}
                                                open={true}
                                            />
                                        </div>
                                    </Col> */}
                    <Col xs="12" md="6" className="m-auto">
                      {/* {addNewGroupBtnShow ?
                                            <>
                                                <Button onClick={() => {
                                                addNewGroupInList();
                                                    // setShowActionBtnOnSave(false)
                                                }
                                            } variant="default" className="CNAG-saveCloseBtn d-flex flex-wrap justify-content-center align-items-center"
                                                style={{ background : "#2ec428", color: '#fff', width: 100, height: 40, borderRadius : 8}}
                                                disabled={isGroupName.length == 0 ? true : false}
                                                >
                                                    <img src={tick} alt='' />&nbsp; Save
                                                </Button>
                                                <Button onClick={() => handleCancel()} variant="default" className="CNAG-saveCloseBtn d-flex flex-wrap justify-content-center align-items-center"
                                                style={{background : "#ea3b3b", color: '#fff', width: 100, height: 40, borderRadius: 8 }}
                                                >
                                                    <img src={closeIcon} alt='' /> Cancel
                                                </Button>
                                            </>
                                        : */}
                      <Button
                        onClick={() => addGroupNoteHandler()}
                        variant="primary"
                        className={`CNAG-add-note`}
                      // style={{opacity : isAddGroupBtnDisable || disableGroup || changeClassData?.[0]?.cc_class_share_permission =='read' || classDetails?.details?.cc_class_share_permission == 'read' && "0.65"}}
                      disabled ={loader}
                      >
                        <img
                          src={addNoteIcon}
                          alt=""
                          className="CNAG-add-note-img"
                        />
                        Group Note
                      </Button>
                      {/* } */}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col
              md="12"
              lg="12"
              className="CNAG-main-section CNAG-main-section-second-header"
            >
              <Card className="header">
                <Card.Body>
                  <Row>
                    <Col xs="12" md="6" className="CNAG-header-title">
                      {/* <div className="CNAG-border-right">
                                            <Button className="back-btn" variant="secondary" onClick={() => { history.goBack() }}>
                                                <i className="fa fa-chevron-left" aria-hidden="true"></i>Class Notes {academicYear}
                                            </Button>
                                        </div> */}
                      {/* {classDetails && <span className="year">{classDetails.cc_class_name} Group</span>} */}
                      <div className="CNAG-border-right">
                        <span className="totalNotesSpan">Total Notes : {groupNotesData?.total_count}</span>
                        <div className="CNAG-DetailTextDiv">
                          <div
                            className={`CNAG-DisplayFlex ${props.history.location.state.BackPage ==
                              "ClassDB" && "w-100"
                              }`}
                          >
                            {/* <p className='CNAG-currentYear' onClick={() => {  BackToDash()}}><b>{academicYear}</b></p> */}
                            {/* <i className="classForIcon material-icons">chevron_right</i> */}
                            {props.history.location.state.BackPage ==
                              "ClassDB" && (
                                <>
                                  <p
                                    className="stdNoteGrade1"
                                    onClick={() => {
                                      props.history.goBack();
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Class
                                  </p>
                                  <i className="classForIcon material-icons">
                                    chevron_right
                                  </i>
                                </>
                              )}
                            {/* <p className='stdNoteGrade1' onClick={() => { BackTOHome() }}>Roster</p> */}
                            {/* <i className="classForIcon material-icons">chevron_right</i> */}
                            <span
                              className="btn-group ViewBtn btn-group-sm"
                              role="group"
                              aria-label="Basic example"
                            >
                              <div
                                defaultActiveKey="tab_sort_timeline"
                                id=""
                                className="section-tabs"
                              >
                                <div
                                  eventKey="tab_sort_timeline"
                                  title="Timeline"
                                  className={
                                    tabView === "timeLine"
                                      ? "blueTimeLineTxt d-flex flex-wrap align-items-center"
                                      : " whiteTimeLineTxt d-flex flex-wrap align-items-center"
                                  }
                                  onClick={() => {
                                    setTabView("timeLine");
                                  }}
                                >
                                  Timeline
                                </div>
                                <div
                                  eventKey="tab_expanded_timeline"
                                  title="Expanded View"
                                  className={
                                    tabView === "expanded"
                                      ? "blueExpandedTxt d-flex flex-wrap align-items-center"
                                      : " whiteExpandedTxt d-flex flex-wrap align-items-center"
                                  }
                                  onClick={() => {
                                    setTabView("expanded");
                                  }}
                                >
                                  Expanded View
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {/* <Col xs="12" md="4" className="m-auto CNAG-searchBar">
                                        <div className="CNAG-dropdownMainContainer">
                                            <div className="iconDiv"><i className="fa fa-search CNAG-searchFiltersIcon"></i></div>
                                           
                                            <SelectSearch
                                                ref={searchInput}
                                                options={allClassses}
                                                // filterOptions={handleFilter}
                                                value={(sessionStorage.getItem('noteClassName') && sessionStorage.getItem('noteClassName').length > 0) ? {name : sessionStorage.getItem('noteClassName'), value: 0} :  (isSelected ? { name: selectedClassName2 ? selectedClassName2 : classDetails?.details?.cc_class_name, value: 0 } : { name: selectedClassName ? selectedClassName : classDetails?.details?.cc_class_name, value: 0 })}
                                                name="Workshop"
                                                placeholder="Search class*"
                                                search
                                                onChange={handleChange}
                                                disabled={addNewGroupBtnShow === true || props?.location?.state?.backpage == "singlecls"}
                                                filterOptions={fuzzySearch}
                                                open={true}
                                            />
                                        </div>
                                    </Col> */}
                    <Col xs="12" md="6" className="m-auto groupNpteView">
                      {/* {addNewGroupBtnShow ?
                                            <>
                                                <Button onClick={() => {
                                                addNewGroupInList();
                                                    // setShowActionBtnOnSave(false)
                                                }
                                            } variant="default" className="CNAG-saveCloseBtn d-flex flex-wrap justify-content-center align-items-center"
                                                style={{ background : "#2ec428", color: '#fff', width: 100, height: 40, borderRadius : 8}}
                                                disabled={isGroupName.length == 0 ? true : false}
                                                >
                                                    <img src={tick} alt='' />&nbsp; Save
                                                </Button>
                                                <Button onClick={() => handleCancel()} variant="default" className="CNAG-saveCloseBtn d-flex flex-wrap justify-content-center align-items-center"
                                                style={{background : "#ea3b3b", color: '#fff', width: 100, height: 40, borderRadius: 8 }}
                                                >
                                                    <img src={closeIcon} alt='' /> Cancel
                                                </Button>
                                            </>
                                        : */}
                      <div className="filterDiv">
                        {filterBy === "both" ? (
                          <div className="showAllBtn filterSubDiv blueActiveButton">
                            <p className="filterText">Show All</p>
                          </div>
                        ) : (
                          <div
                            className="showAllDisBtn filterSubDiv"
                            onClick={() => {
                              setFilterBy("both");
                            }}
                          >
                            <p className="filterText">Show All</p>
                          </div>
                        )}
                        {filterBy === "marked_yes" ? (
                          <div className="artifactsNote filterSubDiv blueActiveButton">
                            <p className="filterText">Artifacts</p>
                          </div>
                        ) : (
                          <div
                            className="artifactsNote filterSubDiv "
                            onClick={() => {
                              setFilterBy("marked_yes");
                            }}
                          >
                            <p className="filterText">Artifacts</p>
                          </div>
                        )}
                      </div>

                      {/* } */}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <div className="mt-3">
            <Row>
              {/* ---------------left panel------------ */}
              <Col md="4" lg="3" className="left-section-groupNoteCard">
                <Card className="br-8 ">
                  <Card.Body>
                    {/* ---------------Profile Section------------ */}

                    {/* ---------------Profile Details------------ */}
                    <Card.Text as="div">
                      <Row className="profile-card">
                        <Col xs="12" className="profile-col">
                          <p>
                            <span className="groupNamespan">
                              {props?.history?.location?.state?.groupDetail?.cc_group_name?.replace(
                                /(.{13})..+/,
                                "$1…"
                              )}
                            </span>{" "}
                            <span className="totalNotesSpan">
                              Total Notes : {groupNotesData?.total_count}
                            </span>
                          </p>
                          {classGroupNoteStudent?.map((item, index) => {
                            return (
                              <Fragment>
                                <div className="profile-card-img">
                                  {item?.cc_student_pic_url === "" ? (
                                    <h5 className="noteImageDummyText">
                                      {item?.cc_student_first_name?.charAt(0)}
                                      {item?.cc_student_last_name?.charAt(0)}
                                    </h5>
                                  ) : (
                                    <Card.Img
                                      className="profile-img"
                                      variant="top"
                                      alt=""
                                      src={item?.cc_student_pic_url}
                                    />
                                  )}
                                </div>
                              </Fragment>
                            );
                          })}
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>

                {/* ---------------Classroom Section------------ */}
                <Card className="br-8 mt-15 mb-15">
                  <Card.Body>
                    <Card.Title className="classRoom-card-title">
                      Classroom Attachments{" "}
                      <div className="classroom-attchements">
                        <div> {loader ? <img src={loaderImag} alt='' className='loaderIconCss' /> : ""}</div>
                        {videoAttachment &&
                          videoAttachment.map((item, i) => {
                            if (item?.cc_attachment_type == "image") {
                              return (
                                <Fragment>
                                  <div
                                    key={i}
                                    className="note"
                                    onClick={() => {
                                      OpenAttachementModal(item);
                                    }}
                                  >
                                    {item.cc_attachment_type === "image" && (
                                      <div className="imageArti">
                                        <img
                                          src={item.cc_attachment_fullurl}
                                          alt=""
                                          width="60"
                                          height="60"
                                        />
                                        {item.cc_media_mark_artified ===
                                          "yes" && (
                                            <span className="badgeDot"></span>
                                          )}
                                      </div>
                                    )}
                                  </div>
                                </Fragment>
                              );
                            }

                            if (item?.cc_attachment_type == "video") {
                              return (
                                <Fragment>
                                  <div
                                    key={i}
                                    className="note"
                                    onClick={() => {
                                      OpenAttachementModal(item);
                                    }}
                                    style={{
                                      backgroundImage: `url(${item.cc_attachment_fullurl})`,
                                    }}
                                  >
                                    {item.cc_attachment_type !== "image" && (
                                      <div className="img-div">
                                        <img
                                          src={playIcon}
                                          alt=""
                                          width="18"
                                          height="18"
                                        />
                                        {item.cc_media_mark_artified ===
                                          "yes" && (
                                            <span className="badgeDot"></span>
                                          )}
                                      </div>
                                    )}
                                  </div>
                                </Fragment>
                              );
                            }
                          })}

                        {docAttachment.map((item, i) => {
                          return (
                            <div
                              key={i}
                              className="noteDoc"
                              onClick={() => {
                                OpenDocAttachementModal(item);
                              }}
                            >
                              {item.cc_attachment_url.split(".").pop() ===
                                "pdf" && (
                                  <div className="imageArti">
                                    <img
                                      src={note_pdf}
                                      alt=""
                                      width="60"
                                      height="60"
                                    />
                                    {!loader && item.cc_media_mark_artified === "yes" && (
                                      <span className="badgeDot"></span>
                                    )}
                                  </div>
                                )}
                              {item.cc_attachment_url.split(".").pop() ===
                                "doc" ||
                                item.cc_attachment_url.split(".").pop() ===
                                "docx" ? (
                                <div className="imageArti">
                                  <img
                                    src={note_doc}
                                    alt=""
                                    width="60"
                                    height="60"
                                  />
                                  {!loader && item.cc_media_mark_artified === "yes" && (
                                    <span className="badgeDot"></span>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              {item.cc_attachment_url.split(".").pop() ===
                                "ppt" ||
                                item.cc_attachment_url.split(".").pop() ===
                                "pptx" ? (
                                <div className="imageArti">
                                  <img
                                    src={note_ppt}
                                    alt=""
                                    width="60"
                                    height="60"
                                  />
                                  {!loader && item.cc_media_mark_artified === "yes" && (
                                    <span className="badgeDot"></span>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              {item.cc_attachment_url.split(".").pop() ===
                                "xls" ||
                                item.cc_attachment_url.split(".").pop() ===
                                "xlsx" ? (
                                <div className="imageArti">
                                  <img
                                    src={note_xls}
                                    alt=""
                                    width="60"
                                    height="60"
                                  />
                                  {!loader && item.cc_media_mark_artified === "yes" && (
                                    <span className="badgeDot"></span>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              {item.cc_attachment_url.split(".").pop() ===
                                "csv" && (
                                  <div className="imageArti">
                                    <img
                                      src={note_csv}
                                      alt=""
                                      width="60"
                                      height="60"
                                    />
                                    {!loader && item.cc_media_mark_artified === "yes" && (
                                      <span className="badgeDot"></span>
                                    )}
                                  </div>
                                )}
                            </div>
                          );
                        })}

                        {audioAttachment.length > 0 &&
                          audioAttachment.map((item, idx) => {
                            return (
                              <div
                                key={idx}
                                className="note"
                                style={{
                                  backgroundImage: `url(${item.cc_attachment_fullurl})`,
                                }}
                              >
                                <div
                                  className="img-div"
                                  onClick={() => {
                                    OpenAttachementModal(item);
                                  }}
                                >
                                  <img
                                    src={micIcon}
                                    alt=""
                                    width="18"
                                    height="18"
                                  />
                                  {!loader && item.cc_media_mark_artified === "yes" && (
                                    <span className="badgeDot"></span>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </Card.Title>
                    <Card.Text as="div">
                      <Row className="profile-card-attachments">
                        <Col xs="12">
                          {/* ---------------Tab Layout------------ */}
                          {/* <Tabs defaultActiveKey="tab1" id="" className="section-tabs"> */}

                          {/* ---------------Tab Layout (Videos/Images)------------ */}

                          {/* ---------------Tab Layout (Videos/Images)------------ */}

                          {/* ---------------Tab Layout (Audio)------------ */}

                          {/* ---------------Tab Layout (Audio)------------ */}
                          {/* </Tabs> */}
                          {/* ---------------Tab Layout------------ */}
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
                {/* ---------------Classroom Section------------ */}
              </Col>
              {/* ---------------left panel------------ */}

              <Col md="8" lg="9" className="right-section">
                {loader && <div>
                  <img src={loaderImag} alt='' className='loaderIconCss' />
                </div>}
                <Card className="timeline-section mt-15 br-8">
                  <Card.Body>
                    {tabView == "timeLine" && (
                      <Fragment>
                        {notesData?.length > 0 ? (
                          <div id="studentHeight">
                            <GroupNotesTimeline
                              type={"show"}
                              NoteData={notesData}
                              props={props}
                              TotalCount={totalNoteCount}
                              academicYear={
                                props?.history?.location?.state?.academicYear
                              }
                              class_id={
                                props?.history?.location?.state?.class_id
                              }
                              student_enrolled_classes={
                                groupNotesData?.student_enrolled_classes
                              }
                              classPermission={
                                props?.history?.location?.state?.classDetails
                                  ?.details?.cc_class_share_permission
                              }
                              academicYearIDFromStudentNoteDashboard={
                                props?.history?.location?.state?.academicYearId
                              }
                              studentInfo={props?.history?.location?.state?.studentsInfo}
                              deleteStudentNote={deleteGroupNote}
                              editGroupNote={editGroupNoteHandler}
                              setArtifact={setArtifact}
                              setMediaArtifact={setMediaArtifact}
                              classGroupNoteStudent={classGroupNoteStudent}

                            />
                          </div>
                        ) : <>
                       {!loader && <p style={{    fontSize: "larger", color: "gray" , fontWeight: "600" ,     position: "relative", top: "72%" , bottom: "-130px"
                          }}> 
                        NO NOTE FOUND
                        </p>}
                        </> }
                      </Fragment>
                    )}

                    {tabView == "expanded" && (
                      <Fragment>
                    {
  notesData?.length > 0 ? (
    <div id="studentHeight">
     <GroupNoteExpandedTimeline
  type={"show"}
  NoteData={
    (() => {
      if (props?.history?.location?.state?.pageType !== 'Edit') {
        const audioNotes = notesData.filter(note => 
          note.cc_tag_title === "Audio Summarization" || note.cc_tag_title === "Audio Transcription"
        );
        const otherNotes = notesData.filter(note => 
          note.cc_tag_title !== "Audio Summarization" && note.cc_tag_title !== "Audio Transcription"
        );
        return [...audioNotes, ...otherNotes];
      } else {
        return notesData;
      }
    })()
  }
  props={props}
  TotalCount={totalNoteCount}
  academicYear={props?.history?.location?.state?.academicYear}
  class_id={props?.history?.location?.state?.class_id}
  student_enrolled_classes={groupNotesData?.student_enrolled_classes}
  classPermission={props?.history?.location?.state?.classDetails?.details?.cc_class_share_permission}
  academicYearIDFromStudentNoteDashboard={props?.history?.location?.state?.academicYearId}
  OpenAttachementModal={OpenAttachementModal}
  OpenDocAttachementModal={OpenDocAttachementModal}
  deleteStudentNote={deleteGroupNote}
  editGroupNote={editGroupNoteHandler}
  setArtifact={setArtifact}
  setMediaArtifact={setMediaArtifact}  // Make sure this line is included
  studentInfo={props?.history?.location?.state?.studentsInfo}
  showAttachementId={showAttachementId}
  audioAttachment={audioAttachment}
/>

    </div>
  )
:
 <p style={{    fontSize: "larger", color: "gray" , fontWeight: "600" ,     position: "relative", top: "72%" , bottom: "-130px"
}}> 
                        NO NOTE FOUND
                        </p>
}

                      </Fragment>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>

        {/* --------------Confirmation Modal------------ */}
        <Modal
          centered
          className="delete-modal-warning br-8"
          show={confirmationShow}
        >
          <Modal.Header>
            <Modal.Title>
              <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
                <img
                  src={warningIcon}
                  alt=""
                  style={{ width: "60px", height: "60px" }}
                />
                <span
                  style={{
                    fontSize: "24px",
                    color: "#ff1f1f",
                    marginTop: "10px",
                  }}
                >
                  DELETE WAIT!
                </span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>
                If you delete this group note all student group notes will be
                deleted.
              </p>
            </div>
            <p>
              Are you sure want to delete this note?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="default"
              className="cancel-btn"
              onClick={() => {
                setConfirmationShow(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="default"
              className="delete-btn BR-8"
              onClick={() => {
                confirmationDelete();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {/* --------------Confirmation Modal------------ */}

        {/*------------- Modal View Attachment Video/imgae ------------------------*/}
        <Modal centered className="plays-video-modal br-8" show={showAttachement} >
          <Modal.Header className="mediaHeader">
            <div className="mediaHeaderBtns"
              style={{ justifyContent: classPermission !== null && classPermission === 'read' && 'flex-end' }}
            >
              {
                classPermission !== null && classPermission === 'read' ? '' :
                  <span className="mediaArti">
                    Mark As Artifact
                    {/* {this.state.videoAttachment && this.state.videoAttachment.length > 0 && this.state.videoAttachment.map((val, i) => (<img src={closeIcon} alt='' />
                                ))} */}

                    {videoAttachment && videoAttachment.length > 0 && videoAttachment.map((val, i) => (
                      val.cc_attachment_id === showAttachementId ? <i id='ab' className={val.cc_media_mark_artified === "yes" ? "fa fa-star starSelected" : "fa fa-star-o starUnSelected"} key={i} aria-hidden="true" onClick={() => { setMediaArtifact(val, i) }}></i> : ""
                    ))}
                  </span>
              }

              <button type="button" className="delete-btn" onClick={() => {
                setShowAttachment(false)
                setShowAttachementURL('')
                setShowAttachementName('')
                setShowAttachementId('')
              }}
                style={{ width: 40, height: 40, borderRadius: 8, }}
              >
                <img src={closeIcon} alt='' />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>

            {showAttachementType !== 'audio' ?
              <div>
                {showAttachementType === 'image' ?
                  <div>
                    <Carousel title="Carousel" onSlide={onSlide} posImage={selectedMediaIndex !== 0 ? selectedMediaIndex : videoAttachment.length} totalAttachment={videoAttachment.length}>
                      {videoAttachment && videoAttachment.length > 0 && videoAttachment.map(val => (

                        val.cc_attachment_type === "image" ?
                          <Item img={val.cc_attachment_type === "image" && val.cc_attachment_fullurl} />
                          : ""
                      ))}
                    </Carousel>
                  </div>
                  :
                  <div>
                    <video width="750" height="400" style={{ border: "12px solid #e9e9e9" }} controls src={showAttachementURL} ></video>
                  </div>}
              </div>
              :
              <div className="text-center">
                <p>{showAttachementName}</p>
                <audio controls className="player" preload="false" controlsList="nodownload" src={showAttachementURL} />
              </div>}
          </Modal.Body>
        </Modal>


        <Modal centered className="play-video-modal br-8" show={showDocAttachement} >
          <Modal.Header className="mediaHeader">
            <div className="mediaHeaderBtns"
              style={{ justifyContent: classPermission !== null && classPermission === 'read' && 'flex-end' }}
            >
              {
                classPermission !== null && classPermission === 'read' ? "" :
                  <span className="docArti">Mark As Artifact
                    {docAttachment && docAttachment.length > 0 && docAttachment.map((val, i) => (
                      val.cc_attachment_id === showDocAttachementId ? <i className={val.cc_media_mark_artified == "yes" ? "fa fa-star starSelected" : "fa fa-star-o starUnSelected"} key={i} aria-hidden="true"
                        onClick={() => { setDocArtifact(val, i) }}
                      ></i> : ""
                    ))}
                  </span>
              }

              <button type="button" variant="default" className="delete-btn" onClick={() => {
                setShowDocAttachement(false)
                setShowDocAttachementURL('')
                setShowDocAttachementName('')
                setShowDocAttachementId('')
                setSelectedDocIndex(0)
                // this.setState({
                //     showDocAttachement: false,
                //     showDocAttachementURL: "",
                //     showDocAttachementName: "",
                //     showDocAttachementId: "",
                //     selectedDocIndex: 0
                // })
              }}
                style={{ width: 40, height: 40, borderRadius: 8 }}
              >
                <img src={closeIcon} alt='' />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <IframeGoogleDoc url={showDocAttachementURL} />
          </Modal.Body>
        </Modal>

      </div>
    </Fragment>
  );
}

export default ClassNoteView;
