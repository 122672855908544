import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


// import Logger from '../Lib/Logger'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getAcademicList: ['data'],
    getAcademicListSuccess: ['data'],
    getAcademicListFailure: ['error'],

    getAcademicScore: ['data'],
    getAcademicScoreSuccess: ['data'],
    getAcademicScoreFailure: ['error'],


    addAcademicYears: ['data'],
    addAcademicYearsSuccess: ['data'],
    addAcademicYearsFailure: ['error'],

    editAcademicYears: ['data'],
    editAcademicYearsSuccess: ['data'],
    editAcademicYearsFailure: ['error'],

    deleteAcademicYears: ['data'],
    deleteAcademicYearsSuccess: ['data'],
    deleteAcademicYearsFailure: ['error'],

    getAcademicYears: ['data'],
    getAcademicYearsSuccess: ['data'],
    getAcademicYearsFailure: ['error'],

    getRosterClassByAcademicYear: ['data'],
    getRosterClassByAcademicYearSuccess: ['data'],
    getRosterClassByAcademicYearFailure: ['data'],
    getAcademicReset: ['data']

})

export const UserAcademicReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    academic_Data: null,
    academic_Score: null,
    academic_Years: null,
    add_Academic_Years: null,
    delete_Academic_Years: null,
    academic_Roster: null,
    error: null,
    exists: null,
    loader: null,
    action: null
})

/* ------------- Reducers ------------- */

export const getAcademicReset = (state) => {
    return {
        ...state,
        academic_Data: null,
        academic_Score: null,
        academic_Years: null,
        add_Academic_Years: null,
        delete_Academic_Years: null,
        academic_Roster: null,
        error: null,
        exists: null,
        loader: null,
        action: null,
        indexing : null
    }
}
/* getAcademicList */
export const getAcademicList = (state) => {
    return {
        ...state, fetching: true, error: null, academic_Data: null, loader: true
    }
}
export const getAcademicListSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, academic_Data: data , action: action.type, exists: true, loader: false }
}
export const getAcademicListFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_ACADEMIC_LIST_FAILURE', loader: false }
}


/* getAcademicScore */

export const getAcademicScore = (state) => {
    return {
        ...state, fetching: false, error: null, academic_Score: null, loader: false
    }
}
export const getAcademicScoreSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, academic_Score: data, action: action.type, exists: true }
}
export const getAcademicScoreFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_ACADEMIC_SCORE_FAILURE', loader: false }
}


/* addAcademicYears */
export const addAcademicYears = (state) => {
    return {
        ...state, fetching: true, error: null, add_Academic_Years: null, loader: true
    }
}
export const addAcademicYearsSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, add_Academic_Years: data, action: action.type, exists: true, loader: false }
}
export const addAcademicYearsFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'ADD_ACADEMIC_YEARS_FAILURE', loader: false }
}

/* editAcademicYears */

export const editAcademicYears = (state) => {
    return {
        ...state, fetching: true, error: null, delete_Academic_Years: null, loader: true
    }
}
export const editAcademicYearsSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, delete_Academic_Years: data, action: action.type, exists: true, loader: false }
}
export const editAcademicYearsFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'EDIT_ACADEMIC_YEARS_FAILURE', loader: false }
}


/* deleteAcademicYears */

export const deleteAcademicYears = (state) => {
    return {
        ...state, fetching: true, error: null, delete_Academic_Years: null, loader: true , indexing : true
    }
}
export const deleteAcademicYearsSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, delete_Academic_Years: data, action: action.type, exists: true, loader: false , indexing : true }
}
export const deleteAcademicYearsFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'DELETE_ACADEMIC_YEARS_FAILURE', loader: false }
}


/* getAcademicYears */

export const getAcademicYears = (state) => {
    return {
        ...state, fetching: true, error: null, academic_Years: null , indexing: false
    }
}
export const getAcademicYearsSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, academic_Years: data, action: action.type, exists: true, indexing : false}
}
export const getAcademicYearsFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_ACADEMIC_YEARS_FAILURE', loader: false, indexing : false }
}

/* getRosterClassByAcademicYear */

export const getRosterClassByAcademicYear = (state, action) => {
      console.log('getting here in getclassbyacyear reducer &&&&&' , action.data);
    return {
        ...state, 
        fetching: action?.data?.userInitialLogin == 'true' ? false : true,
        error: null, academic_Roster: null , indexing : false , loaded : false
    }
    
}
export const getRosterClassByAcademicYearSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, academic_Roster: data, action: action.type, exists: true, loader: false , loaded : true}
}
export const getRosterClassByAcademicYearFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_ROSTER_CLASS_BY_ACADEMIC_YEAR_FAILURE', loader: false , loaded : true}
}
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.GET_ACADEMIC_LIST]: getAcademicList,
    [Types.GET_ACADEMIC_LIST_SUCCESS]: getAcademicListSuccess,
    [Types.GET_ACADEMIC_LIST_FAILURE]: getAcademicListFailure,


    [Types.GET_ACADEMIC_SCORE]: getAcademicScore,
    [Types.GET_ACADEMIC_SCORE_SUCCESS]: getAcademicScoreSuccess,
    [Types.GET_ACADEMIC_SCORE_FAILURE]: getAcademicScoreFailure,

    [Types.ADD_ACADEMIC_YEARS]: addAcademicYears,
    [Types.ADD_ACADEMIC_YEARS_SUCCESS]: addAcademicYearsSuccess,
    [Types.ADD_ACADEMIC_YEARS_FAILURE]: addAcademicYearsFailure,

    [Types.EDIT_ACADEMIC_YEARS]: editAcademicYears,
    [Types.EDIT_ACADEMIC_YEARS_SUCCESS]: editAcademicYearsSuccess,
    [Types.EDIT_ACADEMIC_YEARS_FAILURE]: editAcademicYearsFailure,

    [Types.DELETE_ACADEMIC_YEARS]: deleteAcademicYears,
    [Types.DELETE_ACADEMIC_YEARS_SUCCESS]: deleteAcademicYearsSuccess,
    [Types.DELETE_ACADEMIC_YEARS_FAILURE]: deleteAcademicYearsFailure,


    [Types.GET_ACADEMIC_YEARS]: getAcademicYears,
    [Types.GET_ACADEMIC_YEARS_SUCCESS]: getAcademicYearsSuccess,
    [Types.GET_ACADEMIC_YEARS_FAILURE]: getAcademicYearsFailure,

    [Types.GET_ROSTER_CLASS_BY_ACADEMIC_YEAR]: getRosterClassByAcademicYear,
    [Types.GET_ROSTER_CLASS_BY_ACADEMIC_YEAR_SUCCESS]: getRosterClassByAcademicYearSuccess,
    [Types.GET_ROSTER_CLASS_BY_ACADEMIC_YEAR_FAILURE]: getRosterClassByAcademicYearFailure,

    [Types.GET_ACADEMIC_RESET] : getAcademicReset,
})
