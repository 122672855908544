import React from 'react';
import { connect } from 'react-redux';
import ClassNoteDashboard from './ClassNoteDashboardComponent/ClassNoteDashboard';
import { getClassNoteRequest } from 'Actions/NoteAction/classNoteAction';

const ClassNoteIndex = (props) => {
    return (
        <ClassNoteDashboard {...props} />
    )
}
const mapStateToProps = state => ({
    state: state,
    classNoteDetails: state.classNote,
    classNoteData: state.classNote.getClassNote,
    loader: state.classNote.loader
});
const mapDispatchToProps = dispatch => {
    return {
        getClassNoteRequest: (data) => dispatch(getClassNoteRequest(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    ClassNoteIndex
);