import React from 'react';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import '../DataUsageDashboardComponent/DataUsageDashboard.scss'
import { getFlooredFixedTwoDigit } from 'Utils/Helper';


const DataUsageProgressBar = (props) => {
    const { user_space_response } = props
    return (
        <div>
            {/* --------Image--------- */}
            <Row>
                <Col md={12} lg={12} className='progressSpaceBtwDiv' >
                    <p className='progressBarHeading'>Image</p>
                    <p className='progressBarValue'>{getFlooredFixedTwoDigit(user_space_response.total_image_space,2)}</p>
                </Col>

                <div className="progress">
                    <div className="progress-bar progressbar progressbarfirst" style={{ width: `${user_space_response.total_image_space / user_space_response.total_alloted_space * 100}%` }} aria-valuemin='100' aria-valuemax='100'></div>
                </div>
            </Row>
            {/* --------Image--------- */}


            {/* --------Audio--------- */}
            <Row>
                <Col md={12} lg={12} className='progressSpaceBtwDiv' >
                    <p className='progressBarHeading'>Audio</p>
                    <p className='progressBarValue'>{getFlooredFixedTwoDigit(user_space_response.total_audio_space,2)}</p>
                </Col>

                <div className="progress">
                    <div className="progress-bar progressbar progressbarfirst" style={{ width: `${user_space_response.total_audio_space / user_space_response.total_alloted_space * 100}%` }} aria-valuemin='100' aria-valuemax='100'></div>
                </div>
            </Row>
            {/* --------Audio--------- */}


            {/* --------Video--------- */}
            <Row>
                <Col md={12} lg={12} className='progressSpaceBtwDiv' >
                    <p className='progressBarHeading'>Video</p>
                    <p className='progressBarValue'>{getFlooredFixedTwoDigit(user_space_response.total_video_space, 2)}</p>
                </Col>

                <div className="progress">
                    <div className="progress-bar progressbar progressbarfirst" style={{ width: `${user_space_response.total_video_space / user_space_response.total_alloted_space * 100}%` }} aria-valuemin='100' aria-valuemax='100'></div>
                </div>
            </Row>
            {/* --------Video--------- */}

            {/* --------Other--------- */}
            <Row>
                <Col md={12} lg={12} className='progressSpaceBtwDiv' >
                    <p className='progressBarHeading'>other</p>
                    <p className='progressBarValue'>{getFlooredFixedTwoDigit(user_space_response.total_other_space, 2)}</p>
                </Col>

                <div className="progress">
                    <div className="progress-bar progressbar progressbarfirst" style={{ width: `${user_space_response.total_other_space / user_space_response.total_alloted_space * 100}%` }} aria-valuemin='100' aria-valuemax='100'></div>
                </div>
            </Row>
            {/* --------Other--------- */}

        </div>



    )
}
DataUsageProgressBar.propTypes = {
    user_space_response: PropTypes.object,
};

export default DataUsageProgressBar