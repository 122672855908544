import {
    GET_SCHOOL_TEACHER,
    GET_SCHOOL_TEACHER_SUCCESS,
    GET_SCHOOL_TEACHER_FAILURE,
    
    SHARE_CLASS,
    SHARE_CLASS_SUCCESS,
    SHARE_CLASS_FAILURE,

    SHARE_STUDENT,
    SHARE_STUDENT_SUCCESS,
    SHARE_STUDENT_FAILURE,
    UPGRADE_POP_UP_OPEN
} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */



// -----------get School Teacher-------------
export function getSchoolTeacher(data) {
    return {
        type: GET_SCHOOL_TEACHER,
        data,
    };
}

export function getSchoolTeacherSuccess(data) {
    return {
        type: GET_SCHOOL_TEACHER_SUCCESS,
        data,
    };
}

export function getSchoolTeacherFailure(data) {
    return {
        type: GET_SCHOOL_TEACHER_FAILURE,
        data,
    };
}

// -----------share class-------------
export function shareClass(data) {
    return {
        type: SHARE_CLASS,
        data,
    };
}

export function shareClassSuccess(data) {
    return {
        type: SHARE_CLASS_SUCCESS,
        data,
    };
}

export function shareClassFailure(data) {
    return {
        type: SHARE_CLASS_FAILURE,
        data,
    };
}



// -----------share Student-------------
export function shareStudent(data) {
    return {
        type: SHARE_STUDENT,
        data,
    };
}

export function shareStudentSuccess(data) {
    return {
        type: SHARE_STUDENT_SUCCESS,
        data,
    };
}

export function shareStudentFailure(data) {
    return {
        type: SHARE_STUDENT_FAILURE,
        data,
    };
}

export function upgradePopUpOpen(data) {
    return {
        type: UPGRADE_POP_UP_OPEN,
        data,
    };
}