import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getFormativeAssessmentRequest: ['data'],
    getFormativeAssessmentSuccess: ['data'],
    getFormativeAssessmentFailure: ['error'],

    getAssessmentStudentsListRequest: ['data'],
    getAssessmentStudentsListSuccess: ['data'],
    getAssessmentStudentsListFailure: ['error'],

    getAllStudentsListRequest: ['data'],
    getAllStudentsListSuccess: ['data'],
    getAllStudentsListFailure: ['error'],

    addAssessmentTagRequest: ['data'],
    addAssessmentTagSuccess: ['data'],
    addAssessmentTagFailure: ['error'],

    editAssessmentTagRequest: ['data'],
    editAssessmentTagSuccess: ['data'],
    editAssessmentTagFailure: ['error'],

    deleteAssessmentTagRequest: ['data'],
    deleteAssessmentTagSuccess: ['data'],
    deleteAssessmentTagFailure: ['error'],

    updateColorAssessmentTagRequired: ['data'],

    updateSelectedAssessmentTagRequired: ['data']





})

export const UserFormativeAssessmentReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    getFormativeAssiss: [],
    getAssissTagStudent: [],
    getAllStudentsList: [],
    getSelectdIdForTag: '',
    error: null,
    exists: null,
    loader: null,
    getTagStudentLoader: null,
    getAllStudentsStatus: false,
    action: null,
    pagination: null
})

/* ------------- Reducers ------------- */



/* getFormativeAssessment */

export const getFormativeAssessmentRequest = (state, action) => {
    
    return {
        ...state, fetching: true, error: null, loader: true, getAllStudentsStatus: false, getSelectdIdForTag: ''
    }
}
export const getFormativeAssessmentSuccess = (state, action) => {
    const { data } = action;
    let selectdId = ''
   
    // if (data && data.response_data && data.response_data.length > 0) {
    //     selectdId = data.response_data[0].assessment_tag_id
    // }
    return { ...state, fetching: false, error: null, getFormativeAssiss: data, getSelectdIdForTag: selectdId, action: action.type, exists: true, loader: false }
}
export const getFormativeAssessmentFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_FORMATIVE_ASSESSMENT_FAILURE', loader: false }
}

/* getAssessmentTagStudent */
export const getAssessmentStudentsListRequest = (state, action) => {
    
    return {
        ...state, fetching: true, error: null, getTagStudentLoader: true, getSelectdIdForTag: action?.data?.assessment_tag_id || ''
    }
}
export const getAssessmentStudentsListSuccess = (state, action) => {
    const { data } = action;
    let value = data;
    value.data = data.data[0]

    return { ...state, fetching: false, error: null, getAssissTagStudent: value, action: action.type, exists: true, getTagStudentLoader: false }
}
export const getAssessmentStudentsListFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_ASSESSMENT_STUDENTS_LIST_FAILURE', getTagStudentLoader: false }
}


/* getAssessmentTagStudent */
export const getAllStudentsListRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, getTagStudentLoader: true,
    }
}
export const getAllStudentsListSuccess = (state, action) => {
    const { data } = action;
    return { ...state, fetching: false, error: null, getAssissTagStudent: data, action: action.type, exists: true, getTagStudentLoader: false, getAllStudentsStatus: true }
}
export const getAllStudentsListFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_ALL_STUDENTS_LIST_FAILURE', getTagStudentLoader: false }
}


/* addAssessmentTagRequest */

export const addAssessmentTagRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, loader: true, getAllStudentsStatus: false
    }
}
export const addAssessmentTagSuccess = (state, action) => {
    const { data } = action;
    return { ...state, fetching: false, error: null, getFormativeAssiss: data, action: action.type, exists: true, loader: false }
}
export const addAssessmentTagFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'ADD_ASSESSMENT_TAG_FAILURE', loader: false }
}


/* editAssessmentTagRequest */

export const editAssessmentTagRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, loader: true, getAllStudentsStatus: false
    }
}
export const editAssessmentTagSuccess = (state, action) => {
    const { data } = action;
    return { ...state, fetching: false, error: null, getFormativeAssiss: data, action: action.type, exists: true, loader: false }
}
export const editAssessmentTagFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'EDIT_ASSESSMENT_TAG_FAILURE', loader: false }
}

/* deleteAssessmentTagRequest */

export const deleteAssessmentTagRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, loader: true, getAllStudentsStatus: false
    }
}
export const deleteAssessmentTagSuccess = (state, action) => {
    const { data } = action;
    return { ...state, fetching: false, error: null, getFormativeAssiss: data, action: action.type, exists: true, loader: false }
}
export const deleteAssessmentTagFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'DELETE_ASSESSMENT_TAG_FAILURE', loader: false }
}

/* update Color Assessment Tag Required */
export const updateColorAssessmentTagRequired = (state, action) => {
    const { data } = action;
    if (data.deSeletColor) {
        for (let item of state.getAssissTagStudent.data) {
            item.cc_ref_student_color_code = ''
        }
    } else {
        if (state.getAssissTagStudent.data[data.itemIndex].cc_ref_student_color_code) {
            state.getAssissTagStudent.data[data.itemIndex].cc_ref_student_color_code = state.getAssissTagStudent.data[data.itemIndex].cc_ref_student_color_code === data.colorCode ? '' : data.colorCode;
        } else {
            state.getAssissTagStudent.data[data.itemIndex].cc_ref_student_color_code = data.colorCode
        }
    }
    return { ...state, getAssissTagStudent: state.getAssissTagStudent }
    // return { ...state, fetching: false, error: null, getAssissTagStudent: data, action: action.type, exists: true, loader: false }
}

/* Selected Assessment Tag Required */
export const updateSelectedAssessmentTagRequired = (state, action) => {
    const { data } = action;
    const selectdId = data
    return { ...state, getSelectdIdForTag: selectdId }

}




/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.GET_FORMATIVE_ASSESSMENT_REQUEST]: getFormativeAssessmentRequest,
    [Types.GET_FORMATIVE_ASSESSMENT_SUCCESS]: getFormativeAssessmentSuccess,
    [Types.GET_FORMATIVE_ASSESSMENT_FAILURE]: getFormativeAssessmentFailure,

    [Types.GET_ASSESSMENT_STUDENTS_LIST_REQUEST]: getAssessmentStudentsListRequest,
    [Types.GET_ASSESSMENT_STUDENTS_LIST_SUCCESS]: getAssessmentStudentsListSuccess,
    [Types.GET_ASSESSMENT_STUDENTS_LIST_FAILURE]: getAssessmentStudentsListFailure,

    [Types.GET_ALL_STUDENTS_LIST_REQUEST]: getAllStudentsListRequest,
    [Types.GET_ALL_STUDENTS_LIST_SUCCESS]: getAllStudentsListSuccess,
    [Types.GET_ALL_STUDENTS_LIST_FAILURE]: getAllStudentsListFailure,

    [Types.ADD_ASSESSMENT_TAG_REQUEST]: addAssessmentTagRequest,
    [Types.ADD_ASSESSMENT_TAG_SUCCESS]: addAssessmentTagSuccess,
    [Types.ADD_ASSESSMENT_TAG_FAILURE]: addAssessmentTagFailure,

    [Types.EDIT_ASSESSMENT_TAG_REQUEST]: editAssessmentTagRequest,
    [Types.EDIT_ASSESSMENT_TAG_SUCCESS]: editAssessmentTagSuccess,
    [Types.EDIT_ASSESSMENT_TAG_FAILURE]: editAssessmentTagFailure,

    [Types.DELETE_ASSESSMENT_TAG_REQUEST]: deleteAssessmentTagRequest,
    [Types.DELETE_ASSESSMENT_TAG_SUCCESS]: deleteAssessmentTagSuccess,
    [Types.DELETE_ASSESSMENT_TAG_FAILURE]: deleteAssessmentTagFailure,

    [Types.UPDATE_COLOR_ASSESSMENT_TAG_REQUIRED]: updateColorAssessmentTagRequired,
    [Types.UPDATE_SELECTED_ASSESSMENT_TAG_REQUIRED]: updateSelectedAssessmentTagRequired,





})
