import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getClassGradebookRequest: ['data'],
    getClassGradebookSuccess: ['data'],
    getClassGradebookFailure: ['error'],

    addNewClassGradebookCategoryRequest: ['data'],
    addNewClassGradebookCategorySuccess: ['data'],
    addNewClassGradebookCategoryFailure: ['error'],

    addNewClassGradebookEventRequest: ['data'],
    addNewClassGradebookEventSuccess: ['data'],
    addNewClassGradebookEventFailure: ['error'],

    updateClassGradebookEventRequest: ['data'],
    updateClassGradebookEventSuccess: ['data'],
    updateClassGradebookEventFailure: ['error'],


    deleteClassGradebookEventRequest: ['data'],
    deleteClassGradebookEventSuccess: ['data'],
    deleteClassGradebookEventFailure: ['error'],

    updateClassGradebookScoreCommentRequest: ['data'],
    updateClassGradebookScoreCommentSuccess: ['data'],
    updateClassGradebookScoreCommentFailure: ['error'],

    updateClassGradebookDragDataRequest: ['data'],
    updateClassGradebookDragDataSuccess: ['data'],
    updateClassGradebookDragDataFailure: ['error'],

    updateClassGradebookDragEventDataRequest: ['data'],
    updateClassGradebookDragEventDataSuccess: ['data'],
    updateClassGradebookDragEventDataFailure: ['error'],

    upgradePopUpOpenGradebook: ['data'],



    updateClassGradebookIdRequest: ['data'],

    updateClassGradebookStudentsRequest: ['data'],

    updateClassGradebookColorRequest: ['data'],

    updateClassGradebookAllColorRequest: ['data'],

    updateClassGradebookInputRequest: ['data'],

    addClassGradebookPopoverDataRequest: ['data'],

    updateClassGradebookPopoverColorRequest: ['data'],

    updateClassGradebookPopoverInputRequest: ['data'],

    updateClassGradebookNullRequest: ['data'],

    openClassGradebookPopoverRequest: ['data'],

    NewClassGradebookRequest: ['data'],

    setGradebookDataState: ['data']

})


export const UserClassGradebookReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    allStudentList: {},
    selectedCategoryList: {},
    selectedCategoryId: '',
    selectedCategoryName: '',
    selectedEventId: '',
    selectedEventName: '',
    selectedEventList: {},
    getClassGradebookList: {},
    getNewGradebookCategory: {},
    checkSaveCategoryStatus: false,
    selectedCategoryDetails: [],
    selectedStudentForPopoverDetails: {},
    upgrade_PopUp_Open: null,
    error: null,
    exists: null,
    loader: null,
    action: null,
    pagination: null
})

/* ------------- Reducers ------------- */

export const setGradebookDataState = (state) => {
    return {
        ...state,
        allStudentList: {},
    selectedCategoryList: {},
    selectedCategoryId: '',
    selectedCategoryName: '',
    selectedEventId: '',
    selectedEventName: '',
    selectedEventList: {},
    getClassGradebookList: {},
    getNewGradebookCategory: {},
    checkSaveCategoryStatus: false,
    selectedCategoryDetails: [],
    selectedStudentForPopoverDetails: {},
    upgrade_PopUp_Open: null,
    error: null,
    exists: null,
    loader: null,
    action: null,
    pagination: null
    }
}


/* getClass GradeBookNote */

export const getClassGradebookRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, loader: true, getClassGradebookList : null, pagination: action.data.pageNo, allStudentList: {}, selectedCategoryList: {}, selectedStudentForPopoverDetails: {}
    }
}
export const getClassGradebookSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, getClassGradebookList: data.data, allStudentList: data.data.students, action: action.type, exists: true, loader: false }
}
export const getClassGradebookFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_CLASS_GRADEBOOK_FAILURE', loader: false }
}

/* add Class Gradebook Category Request  */
export const addNewClassGradebookCategoryRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, loader: true, checkSaveCategoryStatus: false
    }
}
export const addNewClassGradebookCategorySuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, selectedCategoryDetails: data, error: null, action: action.type, exists: true, loader: false, checkSaveCategoryStatus: true }
}

export const upgradePopUpOpenGradebook = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, upgrade_PopUp_Open: data, action: action.type, exists: true, Notesloader: false }
}

export const addNewClassGradebookCategoryFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'ADD_NEW_CLASS_GRADEBOOK_CATEGORY_FAILURE', loader: false }
}


/* add Class Gradebook Event Request  */
export const addNewClassGradebookEventRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, loader: true,
    }
}
export const addNewClassGradebookEventSuccess = (state, action) => {
    return { ...state, fetching: false, error: null, action: action.type, exists: true, loader: false, checkSaveCategoryStatus: false }
}
export const addNewClassGradebookEventFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'ADD_NEW_CLASS_GRADEBOOK_EVENT_FAILURE', loader: false }
}

/* update Class Gradebook Event Request  */
export const updateClassGradebookEventRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, loader: true,
    }
}
export const updateClassGradebookEventSuccess = (state, action) => {

    return { ...state, fetching: false, error: null, action: action.type, exists: true, loader: false, checkSaveCategoryStatus: false }
}
export const updateClassGradebookEventFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'UPDATE_CLASS_GRADEBOOK_EVENT_FAILURE', loader: false }
}

/* update Class Gradebook Event Request  */
export const updateClassGradebookScoreCommentRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, loader: true,
    }
}
export const updateClassGradebookScoreCommentSuccess = (state, action) => {
    return { ...state, fetching: false, error: null, action: action.type, exists: true, loader: false, checkSaveCategoryStatus: false }
}
export const updateClassGradebookScoreCommentFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_FAILURE', loader: false }
}

/* delete Class Gradebook Event Request  */
export const deleteClassGradebookEventRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, loader: true,
    }
}
export const deleteClassGradebookEventSuccess = (state, action) => {
    return { ...state, fetching: false, error: null, action: action.type, exists: true, loader: false }
}
export const deleteClassGradebookEventFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'DELETE_CLASS_GRADEBOOK_EVENT_FAILURE', loader: false }
}

/* update Class Gradebook Drag Data Request  */
export const updateClassGradebookDragDataRequest = (state, action) => {
    const { data } = action
    state.getClassGradebookList.category = data.Value
    return { ...state, getClassGradebookList: state.getClassGradebookList }
}
export const updateClassGradebookDragDataSuccess = (state, action) => {
    return { ...state, fetching: false, error: null, action: action.type, exists: true, loader: false }
}
export const updateClassGradebookDragDataFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'UPDATE_CLASS_GRADEBOOK_DRAG_DATA_FAILURE', loader: false }
}

/* update Class Gradebook Drag Event Data Request  */
export const updateClassGradebookDragEventDataRequest = (state, action) => {
    const { data } = action
    state.getClassGradebookList.category = data.Value
    return { ...state, getClassGradebookList: state.getClassGradebookList }
}
export const updateClassGradebookDragEventDataSuccess = (state, action) => {
    return { ...state, fetching: false, error: null, action: action.type, exists: true, loader: false }
}
export const updateClassGradebookDragEventDataFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_FAILURE', loader: false }
}



// -----------update Class Gradebook Color Request-------------
export const updateClassGradebookIdRequest = (state, action) => {
    const { data } = action

    return {
        ...state, selectedCategoryList: data.category, selectedEventList: data.event ? data.event : []
    }
}


// -----------update Class Gradebook Students Request-------------
export const updateClassGradebookStudentsRequest = (state, action) => {
    const { data } = action
    let index = 0;
    for (let item of data) {
        item.cc_student_pic_url = state.getClassGradebookList.students[index].cc_student_pic_url;
        index++
    }
    return { ...state, allStudentList: data }
}
// -----------update Class Gradebook Color Request-------------
export const updateClassGradebookColorRequest = (state, action) => {
    const { data } = action
    if (state.allStudentList[data.index].grading_event_student_color_code) {
        state.allStudentList[data.index].grading_event_student_color_code = state.allStudentList[data.index].grading_event_student_color_code === data.colorCode ? '' : data.colorCode;
    } else {
        state.allStudentList[data.index].grading_event_student_color_code = data.colorCode
    }
    return { ...state, allStudentList: state.allStudentList }
}


// -----------update Class Gradebook All Color Request-------------
export const updateClassGradebookAllColorRequest = (state, action) => {
    const { data } = action
    for (let item of state.allStudentList) {
        item.grading_event_student_color_code = item.grading_event_student_color_code === data.colorCode ? '' : data.colorCode;
    }

    return { ...state, allStudentList: state.allStudentList }
}

// -----------update Class Gradebook Input Request-------------
export const updateClassGradebookInputRequest = (state, action) => {
    const { data } = action
    if (data.type) {
        if(data.inputType == 'grades') {
            if(data.value.length == 0) {
                state.allStudentList[data.index].grading_event_student_score_error = '';
                state.allStudentList[data.index].grading_event_student_score = ''
            } else {
            if (data.value.length < 4) {
                // state.allStudentList[data.index].grading_event_student_score = data.value;
              
                // state.allStudentList[data.index].grading_event_student_score = data.value;

                // PREVENT USER TO INPUT GRADES HERE

                // if (/^[a-zA-Z]/.test(data.value)) {
                //     state.allStudentList[data.index].grading_event_student_score = data.value;
                //     // Check if the second character is either '+' or '-'
                //     if (/^[a-zA-Z][+-]/.test(data.value)) {
                //         state.allStudentList[data.index].grading_event_student_score = data.value;
                //     } else {
                //       // If the second character is not '+' or '-', prevent updating the input value
                //       state.allStudentList[data.index].grading_event_student_score = ''
                //     }
                //   } else {
                //     // If the first character is not an alphabet, prevent updating the input value
                //     state.allStudentList[data.index].grading_event_student_score = ''
                //   }

                //WORK FOR GRADES VALIDATION AFTER USER INPUT

                // const scoreValue = /^[-+]?[A-F][A-F]?[+-]?$/; // Need to keep as fallback
                const scoreValue = /^[A-Z][A-Z]?[+-]?$/
                state.allStudentList[data.index].grading_event_student_score = data.value;
                if(!scoreValue.test(data.value)) {
                    state.allStudentList[data.index].grading_event_student_score_error = 'Please enter Valid Grade';
                } else {
                    state.allStudentList[data.index].grading_event_student_score_error = '';
                }
            }
        }
        } 
        else {
            if(data.value.length == 0) {
                state.allStudentList[data.index].grading_event_student_score_error = '';
                state.allStudentList[data.index].grading_event_student_score = ''
            } else {
            if (data.value.length < 7) {
            state.allStudentList[data.index].grading_event_student_score = data.value;
            state.allStudentList[data.index].grading_event_student_score_error = '';
            }
        }
    }
    }
    else {
        state.allStudentList[data.index].grading_event_student_comments = data.value
    }

    return { ...state, allStudentList: state.allStudentList }
}

export const addClassGradebookPopoverDataRequest = (state, action) => {
    const { data } = action
    return { ...state, selectedStudentForPopoverDetails: data }
}
// -----------update Class Gradebook Popover Color Request-------------
export const updateClassGradebookPopoverColorRequest = (state, action) => {
    const { data } = action
    state.selectedStudentForPopoverDetails.grading_event_student_color_code = state.selectedStudentForPopoverDetails.grading_event_student_color_code === data.colorCode ? '' : data.colorCode
    return { ...state, selectedStudentForPopoverDetails: state.selectedStudentForPopoverDetails }

}

export const updateClassGradebookPopoverInputRequest = (state, action) => {
    const { data } = action
    if (data.type) {
        if (data.value.length < 6) {
            state.selectedStudentForPopoverDetails.grading_event_student_score = data.value
        }
    } else {
        state.selectedStudentForPopoverDetails.grading_event_student_comments = data.value
    }
    return { ...state, selectedStudentForPopoverDetails: state.selectedStudentForPopoverDetails }
}


/* update Class Gradebook Null Request */
export const updateClassGradebookNullRequest = (state, action) => {
    return {
        ...state, getClassGradebookList: {},
    }
}

/* open/close Class Gradebook Popover Request */
export const openClassGradebookPopoverRequest = (state, action) => {
    const { data } = action;
    if (state.getClassGradebookList.category[data.cIndex].category_events[data.eIndex].event_student[data.sIndex].openPopover) {
        state.getClassGradebookList.category[data.cIndex].category_events[data.eIndex].event_student[data.sIndex].openPopover = !state.getClassGradebookList.category[data.cIndex].category_events[data.eIndex].event_student[data.sIndex].openPopover
    }
    else {
        state.getClassGradebookList.category[data.cIndex].category_events[data.eIndex].event_student[data.sIndex].openPopover = true
    }

    return { ...state, fetching: false, error: null, getClassGradebookList: state.getClassGradebookList, exists: true, loader: false }

}

/* open New Class Gradebook Cat Request */
export const NewClassGradebookRequest = (state, action) => {
    return {
        ...state, fetching: true, error: null, allStudentList: state.getClassGradebookList.students
    }
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.GET_CLASS_GRADEBOOK_REQUEST]: getClassGradebookRequest,
    [Types.GET_CLASS_GRADEBOOK_SUCCESS]: getClassGradebookSuccess,
    [Types.GET_CLASS_GRADEBOOK_FAILURE]: getClassGradebookFailure,

    [Types.ADD_NEW_CLASS_GRADEBOOK_CATEGORY_REQUEST]: addNewClassGradebookCategoryRequest,
    [Types.ADD_NEW_CLASS_GRADEBOOK_CATEGORY_SUCCESS]: addNewClassGradebookCategorySuccess,
    [Types.ADD_NEW_CLASS_GRADEBOOK_CATEGORY_FAILURE]: addNewClassGradebookCategoryFailure,

    [Types.ADD_NEW_CLASS_GRADEBOOK_EVENT_REQUEST]: addNewClassGradebookEventRequest,
    [Types.ADD_NEW_CLASS_GRADEBOOK_EVENT_SUCCESS]: addNewClassGradebookEventSuccess,
    [Types.ADD_NEW_CLASS_GRADEBOOK_EVENT_FAILURE]: addNewClassGradebookEventFailure,


    [Types.UPDATE_CLASS_GRADEBOOK_EVENT_REQUEST]: updateClassGradebookEventRequest,
    [Types.UPDATE_CLASS_GRADEBOOK_EVENT_SUCCESS]: updateClassGradebookEventSuccess,
    [Types.UPDATE_CLASS_GRADEBOOK_EVENT_FAILURE]: updateClassGradebookEventFailure,


    [Types.DELETE_CLASS_GRADEBOOK_EVENT_REQUEST]: deleteClassGradebookEventRequest,
    [Types.DELETE_CLASS_GRADEBOOK_EVENT_SUCCESS]: deleteClassGradebookEventSuccess,
    [Types.DELETE_CLASS_GRADEBOOK_EVENT_FAILURE]: deleteClassGradebookEventFailure,

    [Types.UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_REQUEST]: updateClassGradebookScoreCommentRequest,
    [Types.UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_SUCCESS]: updateClassGradebookScoreCommentSuccess,
    [Types.UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_FAILURE]: updateClassGradebookScoreCommentFailure,

    [Types.UPDATE_CLASS_GRADEBOOK_DRAG_DATA_REQUEST]: updateClassGradebookDragDataRequest,
    [Types.UPDATE_CLASS_GRADEBOOK_DRAG_DATA_SUCCESS]: updateClassGradebookDragDataSuccess,
    [Types.UPDATE_CLASS_GRADEBOOK_DRAG_DATA_FAILURE]: updateClassGradebookDragDataFailure,


    [Types.UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_REQUEST]: updateClassGradebookDragEventDataRequest,
    [Types.UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_SUCCESS]: updateClassGradebookDragEventDataSuccess,
    [Types.UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_FAILURE]: updateClassGradebookDragEventDataFailure,



    [Types.UPDATE_CLASS_GRADEBOOK_ID_REQUEST]: updateClassGradebookIdRequest,

    [Types.UPDATE_CLASS_GRADEBOOK_STUDENTS_REQUEST]: updateClassGradebookStudentsRequest,

    [Types.UPDATE_CLASS_GRADEBOOK_COLOR_REQUEST]: updateClassGradebookColorRequest,

    [Types.UPDATE_CLASS_GRADEBOOK_ALL_COLOR_REQUEST]: updateClassGradebookAllColorRequest,

    [Types.UPDATE_CLASS_GRADEBOOK_INPUT_REQUEST]: updateClassGradebookInputRequest,

    [Types.ADD_CLASS_GRADEBOOK_POPOVER_DATA_REQUEST]: addClassGradebookPopoverDataRequest,


    [Types.UPDATE_CLASS_GRADEBOOK_POPOVER_COLOR_REQUEST]: updateClassGradebookPopoverColorRequest,

    [Types.UPDATE_CLASS_GRADEBOOK_POPOVER_INPUT_REQUEST]: updateClassGradebookPopoverInputRequest,

    [Types.UPDATE_CLASS_GRADEBOOK_NULL_REQUEST]: updateClassGradebookNullRequest,

    [Types.OPEN_CLASS_GRADEBOOK_POPOVER_REQUEST]: openClassGradebookPopoverRequest,

    [Types.NEW_CLASS_GRADEBOOK_REQUEST]: NewClassGradebookRequest,

    [Types.UPGRADE_POP_UP_OPEN_GRADEBOOK]: upgradePopUpOpenGradebook,

    [Types.SET_GRADEBOOK_DATA_STATE]: setGradebookDataState


})
