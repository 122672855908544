import React from 'react';
import Card from 'react-bootstrap/Card';
import './TermsDashboard.scss'


const TermsDashboard = (props) => {
    return (
        <div className='termsContainer'>
            <Card className='cardOfTerms'>
                <p className='TermsHeading'>Terms & Conditions</p>
                <div>
                    <p className='questionText'>Chronicle EULA</p>
                    <p className='descriptionText'>This is a legal agreement between you and Synapse LLC stating the terms that govern your use of the Synapse LLC mobile application (the “Application”). This agreement, together with all updates, additional terms, software licenses, and all of Synapse LLC rules and policies, collectively constitute the “Agreement” between you and Synapse LLC. By installing the Application you are indicating that you agree to these terms. If you do not agree to these terms, do not install or use the Application. You must accept and abide by these terms as presented to you. Changes, additions, or deletions are not acceptable, and Synapse LLC may refuse access to the Application for noncompliance with any part of the Agreement.</p>
                </div>
                <div>
                    <p className='questionText'>License</p>
                    <p className='descriptionText'>The Application is licensed, not sold, to you for use only under the terms of this Agreement. Synapse LLC, as the licensor, reserves all rights not expressly granted to you. This license granted to you for the Application by Synapse LLC is limited to a non-transferable license to use the Application on any Web device that you own or control and as permitted by the Usage Rules set forth in the App Store Terms and Conditions (the “Usage Rules”). This license does not allow you to use the Application on any mobile device that you do not own or control, and you may not distribute or make the Application available over a network where it could be used by multiple devices at the same time. You may not rent, lease, lend, sell, redistribute or sublicense the Application. You may not copy (except as expressly permitted by this license and the Usage Rules), decompile, reverse engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Application, any updates, or any part thereof (except as and only to the extent any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any open sourced components included with the Application). Any attempt to do so is a violation of the rights of Synapse LLC and its licensors. If you breach this restriction, you may be subject to prosecution and damages. The terms of the license will govern any upgrades provided by Synapse LLC that replace and/or supplement the original Application unless such upgrade is accompanied by a separate license in which case the terms of that license will govern.</p>
                </div>
                <div>
                    <p className='questionText'>Consent to Use of Data</p>
                    <p className='descriptionText'>You agree that Synapse LLC may collect and use technical data and related information, including but not limited to technical information about your device, system and application software, and peripherals, that is gathered periodically to facilitate the provision of software updates, product support, and other services to you (if any) related to the Application. Synapse LLC may use this information, as long as it is in a form that does not personally identify you, to improve its products or to provide services or technologies to you.</p>
                </div>

                <div>
                    <p className='questionText'>Termination</p>
                    <p className='descriptionText'>The license is effective until terminated by you or Synapse LLC. Your rights under this license will terminate automatically without notice from Synapse LLC if you fail to comply with any term(s) of this license. Upon termination of the license, you shall cease all use of the Application, and destroy all copies, full or partial, of the Application.</p>
                </div>

                <div>
                    <p className='questionText'>Services: Third-Party Material</p>
                    <p className='descriptionText'>The Application may enable access to Synapse LLC and third party services and websites (collectively and individually, “Services”). Use of the Services may require Internet access and that you accept additional terms of service. Certain Services may display, include or make available content, data, information, applications or materials from third parties (“Third Party Materials”) or provide links to certain third party websites. By using the Services, you acknowledge and agree that Synapse LLC is not responsible for examining or evaluating the content, accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect of such Third Party Materials or websites. Synapse, LLC does not warrant or endorse and does not assume and will not have any liability or responsibility to you or any other person for any Services, Third Party Materials or websites, or for any other materials, products, or services of third parties. Third Party Materials and links to other websites are provided solely as a convenience to you. Location data provided by any Services is for basic navigational purposes only and is not intended to be relied upon in situations where precise location information is needed or where erroneous, inaccurate or incomplete location data may lead to death, personal injury, property or environmental damage. Alerts and/or notification data provided by any Services is for convenience only and you understand that multiple factors including network availability may affect notification data delivery, which is not guaranteed. Neither Synapse LLC nor any of its content providers guarantee the availability, accuracy, completeness, reliability, or timeliness of location data or notification data displayed by any Services.</p>
                    <p className='descriptionText'>You agree that any Services contain proprietary content, information, and material that is protected by applicable intellectual property and other laws, including but not limited to copyright and that you will not use such proprietary content, information or materials in any way whatsoever except for permitted use of the Services. No portion of the Services may be reproduced in any form or by any means. You agree not to modify, rent, lease, loan, sell, distribute, or create derivative works based on the Services, in any manner, and you shall not exploit the Services in any unauthorized way whatsoever, including but not limited to, by trespass or burdening network capacity. You further agree not to use the Services in any manner to harass, abuse, stalk, threaten, defame or otherwise infringe or violate the rights of any other party, and that Synapse LLC is not in any way responsible for any such use by you, nor for any harassing, threatening, defamatory, offensive or illegal messages or transmissions that you may receive as a result of using any of the Services.</p>
                    <p className='descriptionText'>In addition, third party Services and Third Party Materials that may be accessed from displayed on or linked to from the mobile devices are not available in all languages or in all countries. Synapse LLC makes no representation that such Services and Materials are appropriate or available for use in any particular location. To the extent you choose to access such Services or Materials, you do so at your own initiative and are responsible for compliance with any applicable laws, including but not limited to applicable local laws. Synapse LLC and its licensors reserve the right to change, suspend, remove, or disable access to any Services at any time without notice. In no event will Synapse LLC be liable for the removal of or disabling of access to any such Services. Synapse LLC may also impose limits on the use of or access to certain Services, in any case, and without notice or liability.</p>
                </div>
                <div>
                    <p className='questionText'>No Warranty</p>
                    <p className='descriptionText'>YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE APPLICATION IS AT YOUR SOLE RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY, AND EFFORT IS WITH YOU. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE APPLICATION AND ANY SERVICES PERFORMED OR PROVIDED BY THE APPLICATION ARE PROVIDED “AS IS” AND “AS AVAILABLE”, WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND Synapse, LLC HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE APPLICATION AND ANY SERVICES, EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND NON-INFRINGEMENT OF THIRD PARTY RIGHTS. Synapse LLC DOES NOT WARRANT AGAINST INTERFERENCE WITH YOUR ENJOYMENT OF THE APPLICATION, THAT THE FUNCTIONS CONTAINED IN, OR SERVICES PERFORMED OR PROVIDED BY, THE APPLICATION WILL MEET YOUR REQUIREMENTS, THAT THE OPERATION OF THE APPLICATION OR SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS IN THE APPLICATION OR SERVICES WILL BE CORRECTED. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY Synapse, LLC OR ITS AUTHORIZED REPRESENTATIVES SHALL CREATE A WARRANTY. SHOULD THE APPLICATION OR SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.</p>
                </div>
                <div>
                    <p className='questionText'>Limitation of Liability</p>
                    <p className='descriptionText'>TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL Synapse, LLC BE LIABLE FOR PERSONAL INJURY, OR ANY INCIDENTAL, SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE APPLICATION, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT OR OTHERWISE) AND EVEN IF RIPPLE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU.</p>
                </div>

                <div>
                    <p className='questionText'>Exportation</p>
                    <p className='descriptionText'>You may not use or otherwise export or re-export the Application except as authorized by United States law and the laws of the jurisdiction in which the Application was obtained. In particular, but without limitation, the Application may not be exported or re-exported (a) into any U.S.embargoed countries or (b) to anyone on the U.S. Treasury Department’s list of Specially Designated Nationals or the U.S. Department of Commerce Denied Person’s List or Entity List. By using the Application, you represent and warrant that you are not located in any such country or on any such list. You also agree that you will not use these products for any purposes prohibited by United States law, including, without limitation, the development, design, manufacture or production of nuclear, missiles, or chemical or biological weapons.</p>
                </div>

                <div>
                    <p className='questionText'>U.S. Government Restricted Rights</p>
                    <p className='descriptionText'>The Application and related documentation are “Commercial Items”, as that term is defined at 48 C.F.R. §2.101, consisting of “Commercial Computer Software” and “Commercial Computer Software Documentation”, as such terms are used in 48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable. Consistent with 48 C.F.R. §12.212 or 48 C.F.R. §227.7202-1 through 227.7202-4, as applicable, the Commercial Computer Software and Commercial Computer Software Documentation are being licensed to U.S. Government end users (a) only as Commercial Items and (b) with only those rights as are granted to all other end users pursuant to the terms and conditions herein. Unpublished-rights are reserved under the copyright laws of theUnited States.</p>
                </div>

                <div>
                    <p className='questionText'>Applicable Law</p>
                    <p className='descriptionText'>The laws of the State of Connecticut, excluding its conflicts of law rules, govern this license and your use of the Application. Your use of the Application may also be subject to other local, state, national, or international laws. The sole and exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the state and federal courts located in New Haven, Connecticut, U.S.A. Both you and Synapse, LLC consent to the jurisdiction of such courts and agree that process may be served in the manner provided herein for giving of notices or otherwise as allowed by Connecticut state or federal law. The parties agree that the UN Convention on Contracts for the International Sale of Goods (Vienna, 1980) shall not apply to this Agreement or to any dispute or transaction arising out of this Agreement.</p>
                </div>

                <div>
                    <p className='questionText'>Intellectual Property</p>
                    <p className='descriptionText'>You do not acquire under this Agreement any intellectual property or other proprietary rights, including without limitation, any patents, inventions, improvements, designs, trademarks, including any applications for same, copyright, rights in any confidential information or trade-secrets, in or relating in any way to the Application. Any grants not expressly granted herein are reserved.</p>
                    <p className='descriptionText'>Except where otherwise specified, the contents of the Application are copyright (c) 2013 Synapse LLC. All rights reserved. The contents of the Application are subject to protection underU.S.and foreign copyright laws. You are not permitted to use the copyrighted content outside of the normal functions of the Application without the prior written consent of Synapse LLC.</p>
                </div>


                <div>
                    <p className='questionText'>Third-Party Beneficiary</p>
                    <p className='descriptionText'>You acknowledge and agree that Apple, and Apple’s subsidiaries, are third party beneficiaries of this agreement, and that, upon your acceptance of these terms and conditions, Apple will have the right (and will be deemed to have accepted the right) to enforce this agreement against you as a third party beneficiary thereof.</p>
                </div>

                <div>
                    <p className='questionText'>Indemnification</p>
                    <p className='descriptionText'>You agree to indemnify, defend and hold Synapse LLC, its partners, licensors, affiliates, contractors, officers, directors, employees and agents harmless from all damages, losses, and expenses arising directly or indirectly from (a) any negligent acts, omissions or willful misconduct by you, (b) your use of the Application, (c) any breach of this Agreement by you, and/or (d) your violation of any law or of any rights of any third party.</p>
                </div>
                <div>
                    <p className='questionText'>Equitable Remedies</p>
                    <p className='descriptionText'>You hereby agree that if the terms of this Agreement are not specifically enforced, Synapse LLC will be irreparably damaged, and therefore you agree that Synapse LLC shall be entitled, without bond, other security, proof of damages, to appropriate equitable remedies with respect any of this Agreement, in addition to any other available remedies.</p>
                </div>

                <div>
                    <p className='questionText'>Assignment</p>
                    <p className='descriptionText'>Synapse LLC may assign this Agreement without notice to you. You shall not assign this Agreement without the prior written consent of Synapse LLC (such consent may be withheld at Synapse LLC discretion).</p>
                </div>

                <div>
                    <p className='questionText'>Assignment</p>
                    <p className='descriptionText'>Synapse LLC may assign this Agreement without notice to you. You shall not assign this Agreement without the prior written consent of Synapse LLC (such consent may be withheld at Synapse LLC discretion).</p>
                </div>

                <div>
                    <p className='questionText'>Notices</p>
                    <p className='descriptionText'>If you have any questions or concerns about this Agreement, you may contact us at:</p>
                </div>

                <div>
                    <p className='questionText'>Notices</p>
                    <p className='descriptionText'>If you have any questions or concerns about this Agreement, you may contact us at:</p>
                    <p className='descriptionText'>Synapse LLC <br /> Legal Department <br />
                        100 Cambridge Street, #1400 <br />
                        Boston MA 02114 <br />
                        +1 857-203-7739</p>
                    <p className='descriptionText'>Email: privacy@chroniclecloud.com</p>
                </div>

                <div>
                    <p className='questionText'>Entire Agreement</p>
                    <p className='descriptionText'>Except as otherwise provided, herein, this Agreement constitutes the entire agreement between the parties respecting the Application and there are no provisions, representations or collateral agreements between the parties other than as set out in this Agreement. Synapse LLC reserves the right to make changes to this Agreement by providing you with notice of the change by including it in an update in the Apple App Store or by any other reasonable method. If you continue to use the Application after notice of the change has been given, you shall be deemed to have accepted this change. <a href="https://www.chroniclecloud.com/" target='_blank' rel="noopener noreferrer">Chronicle Cloud</a> is the world’s first App by the teachers, for the teachers. Chronicle provides multiple features to allow teachers to capture notes and assessments on students in one central place, and share them with students and teachers.</p>
                    <p className='descriptionText'>Chronicle product team brings over 30 years of teaching experience and is an early adopter of technology, constantly trying to increase their teaching efficacy by using different applications. Chronicle has emerged as a product-of-choice for teachers across the US. More than a thousand teachers have downloaded and used the first paid-version of the App during the last 2 years.</p>
                    <p className='descriptionText'>Driven by our passion to support teachers, in December 2017, Chronicle team has a new gift for teachers – a fully functioning free Chronicle Cloud, where we have added a ton of new features including cloud backup and sharing right from the <a href="https://itunes.apple.com/us/app/chronicle-cloud/id1326291372?ls=1&mt=8" target='_blank' rel="noopener noreferrer">App</a>.</p>
                    <p className='descriptionText'>We look forward to partnering with teachers, schools and school district administration to further build the Chronicle into the most widely used App for teachers in the world!</p>
                </div>

            </Card>
        </div>
    )
}

export default TermsDashboard