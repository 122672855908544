import React from 'react';
import { connect } from 'react-redux';
import ClassShareDashboard from './ClassShareDashboard';
import {
    getSchoolTeacher,
    shareClass,
  
} from 'Actions/ClassAction/ClassShareAction';

const ClassShareIndex = (props) => {
    return (
        <ClassShareDashboard {...props} />
    )
} 

const mapStateToProps = state => ({
    state: state,
    shareStudentData:state.classShare
});
const mapDispatchToProps = dispatch => {
    return {
        getSchoolTeacher: (data) => dispatch(getSchoolTeacher(data)),
        shareClass: (data) => dispatch(shareClass(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    ClassShareIndex
);