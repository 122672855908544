

import {
    GET_CLASS_ATT_LIST,
    GET_CLASS_ATT_LIST_SUCCESS,
    GET_CLASS_ATT_LIST_FAILURE,

    ADD_CLASS_ATT,
    ADD_CLASS_ATT_SUCCESS,
    ADD_CLASS_ATT_FAILURE,

    GET_CLASS_ATT_BY_DATE_RANGE,
    GET_CLASS_ATT_BY_DATE_RANGE_SUCCESS,
    GET_CLASS_ATT_BY_DATE_RANGE_FAILURE,

    SEND_EMAIL_REPORT,
    SEND_EMAIL_REPORT_SUCCESS,
    SEND_EMAIL_REPORT_FAILURE,
    

} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */



// -----------Get Class Att List-------------
export function getClassAttList(data) {
    return {
        type: GET_CLASS_ATT_LIST,
        data,
    };
}

export function getClassAttListSuccess(data) {
    return {
        type: GET_CLASS_ATT_LIST_SUCCESS,
        data,
    };
}

export function getClassAttListFailure(data) {
    return {
        type: GET_CLASS_ATT_LIST_FAILURE,
        data,
    };
}


// -----------Add Class Att------------
export function addClassAtt(data) {
    return {
        type: ADD_CLASS_ATT,
        data,
    };
}

export function addClassAttSuccess(data) {
    return {
        type: ADD_CLASS_ATT_SUCCESS,
        data,
    };
}

export function addClassAttFailure(data) {
    return {
        type: ADD_CLASS_ATT_FAILURE,
        data,
    };
}

// -----------Get Class Att By Date------------
export function getClassAttByDateRange(data) {
    return {
        type: GET_CLASS_ATT_BY_DATE_RANGE,
        data,
    };
}

export function getClassAttByDateRangeSuccess(data) {
    return {
        type: GET_CLASS_ATT_BY_DATE_RANGE_SUCCESS,
        data,
    };
}

export function getClassAttByDateRangeFailure(data) {
    return {
        type: GET_CLASS_ATT_BY_DATE_RANGE_FAILURE,
        data,
    };
}


// -----------Send Email------------
export function sendEmailReport(data) {
    return {
        type: SEND_EMAIL_REPORT,
        data,
    };
}

export function sendEmailReportSuccess(data) {
    return {
        type: SEND_EMAIL_REPORT_SUCCESS,
        data,
    };
}

export function sendEmailReportFailure(data) {
    return {
        type: SEND_EMAIL_REPORT_FAILURE,
        data,
    };
}
