import {
    ADD_STUDENT_NOTE,
    ADD_STUDENT_NOTE_SUCCESS,
    ADD_STUDENT_NOTE_FAILURE,
    EDIT_STUDENT_NOTE,
    EDIT_STUDENT_NOTE_SUCCESS,
    EDIT_STUDENT_NOTE_FAILURE,
    GET_STUDENT_NOTE,
    GET_STUDENT_NOTE_SUCCESS,
    GET_STUDENT_NOTE_FAILURE,
    DELETE_STUDENT_NOTE,
    DELETE_STUDENT_NOTE_SUCCESS,
    DELETE_STUDENT_NOTE_FAILURE,
    GET_STUDENT_CLASSES,
    GET_STUDENT_CLASSES_SUCCESS,
    GET_STUDENT_CLASSES_FAILURE,
    SET_STUDENT_ARTIFACT,
    SET_STUDENT_ARTIFACT_SUCCESS,
    SET_STUDENT_ARTIFACT_FAILURE,
    ADD_STUDENT_MISCELLANEOUS,
    ADD_STUDENT_MISCELLANEOUS_SUCCESS,
    ADD_STUDENT_MISCELLANEOUS_FAILURE,
    EDIT_STUDENT_MISCELLANEOUS,
    EDIT_STUDENT_MISCELLANEOUS_SUCCESS,
    EDIT_STUDENT_MISCELLANEOUS_FAILURE,
    DELETE_STUDENT_MISCELLANEOUS,
    DELETE_STUDENT_MISCELLANEOUS_SUCCESS,
    DELETE_STUDENT_MISCELLANEOUS_FAILURE,
    GET_GROUP_NOTE_STUDENTS,
    GET_GROUP_NOTE_STUDENTS_SUCCESS,
    GET_GROUP_NOTE_STUDENTS_FAILURE,
    SET_MEDIA_ATTACHMENT_ARTIFACT,
    SET_MEDIA_ATTACHMENT_ARTIFACT_SUCCESS,
    SET_MEDIA_ATTACHMENT_ARTIFACT_FAILURE,
    DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT,
    DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT_ERROR,
    DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT_SUCCESS,
    GET_AUDIO_FROM_VERTEX,
    GET_AUDIO_FROM_VERTEX_ERROR,
    GET_AUDIO_FROM_VERTEX_SUCCESS,
    GET_AUDIO_SUMMARY_FROM_VERTEX,
    GET_AUDIO_SUMMARY_FROM_VERTEX_ERROR,
    GET_AUDIO_SUMMARY_FROM_VERTEX_SUCCESS,
    RESET_STUDENT_NOTES,
  } from "../constants";
  
  /**
   * Fetches the login details of the user if already in DB
   *
   * @param  {data} data in the form
   *
   * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
   */
  
  // -----------Get Student Note-------------
  export function getStudentNote(data) {
    return {
      type: GET_STUDENT_NOTE,
      data,
    };
  }
  
  export function getStudentNoteSuccess(data) {
    return {
      type: GET_STUDENT_NOTE_SUCCESS,
      data,
    };
  }
  
  export function getStudentNoteFailure(data) {
    return {
      type: GET_STUDENT_NOTE_FAILURE,
      data,
    };
  }
  
  // -----------Edit Student Note-------------
  export function editStudentNote(data) {
    return {
      type: EDIT_STUDENT_NOTE,
      data,
    };
  }
  
  export function editStudentNoteSuccess(data) {
    return {
      type: EDIT_STUDENT_NOTE_SUCCESS,
      data,
    };
  }
  
  export function editStudentNoteFailure(data) {
    return {
      type: EDIT_STUDENT_NOTE_FAILURE,
      data,
    };
  }
  
  // -----------Add Student Note-------------
  export function addStudentNote(data) {
    return {
      type: ADD_STUDENT_NOTE,
      data,
    };
  }
  
  export function addStudentNoteSuccess(data) {
    return {
      type: ADD_STUDENT_NOTE_SUCCESS,
      data,
    };
  }
  
  export function addStudentNoteFailure(data) {
    return {
      type: ADD_STUDENT_NOTE_FAILURE,
      data,
    };
  }
  
  // -----------Delete Student Note-------------
  export function deleteStudentNote(data) {
    return {
      type: DELETE_STUDENT_NOTE,
      data,
    };
  }
  
  export function deleteStudentNoteSuccess(data) {
    return {
      type: DELETE_STUDENT_NOTE_SUCCESS,
      data,
    };
  }
  
  export function deleteStudentNoteFailure(data) {
    return {
      type: DELETE_STUDENT_NOTE_FAILURE,
      data,
    };
  }
  
  // -----------Get Student Classes-------------
  export function getStudentClasses(data) {
    return {
      type: GET_STUDENT_CLASSES,
      data,
    };
  }
  
  export function getStudentClassesSuccess(data) {
    return {
      type: GET_STUDENT_CLASSES_SUCCESS,
      data,
    };
  }
  
  export function getStudentClassesFailure(data) {
    return {
      type: GET_STUDENT_CLASSES_FAILURE,
      data,
    };
  }
  
  // -----------Set Student Artifact-------------
  export function setStudentArtifact(data) {
    return {
      type: SET_STUDENT_ARTIFACT,
      data,
    };
  }
  
  export function setStudentArtifactSuccess(data) {
    return {
      type: SET_STUDENT_ARTIFACT_SUCCESS,
      data,
    };
  }
  
  export function setStudentArtifactFailure(data) {
    return {
      type: SET_STUDENT_ARTIFACT_FAILURE,
      data,
    };
  }
  // -----------Set Media Attachment Artifact-------------
  export function setMediaAttachmentArtifact(data) {
    return {
      type: SET_MEDIA_ATTACHMENT_ARTIFACT,
      data,
    };
  }
  
  export function setMediaAttachmentArtifactSuccess(data) {
    return {
      type: SET_MEDIA_ATTACHMENT_ARTIFACT_SUCCESS,
      data,
    };
  }
  
  export function setMediaAttachmentArtifactFailure(data) {
    return {
      type: SET_MEDIA_ATTACHMENT_ARTIFACT_FAILURE,
      data,
    };
  }
  // -----------Set Media Attachment Artifact-------------
  
  // -----------Add Student Miscellaneous-------------
  export function addStudentMiscellaneous(data) {
    return {
      type: ADD_STUDENT_MISCELLANEOUS,
      data,
    };
  }
  
  export function addStudentMiscellaneousSuccess(data) {
    return {
      type: ADD_STUDENT_MISCELLANEOUS_SUCCESS,
      data,
    };
  }
  
  export function addStudentMiscellaneousFailure(data) {
    return {
      type: ADD_STUDENT_MISCELLANEOUS_FAILURE,
      data,
    };
  }
  
  // -----------edit Student Miscellaneous-------------
  export function editStudentMiscellaneous(data) {
    return {
      type: EDIT_STUDENT_MISCELLANEOUS,
      data,
    };
  }
  
  export function editStudentMiscellaneousSuccess(data) {
    return {
      type: EDIT_STUDENT_MISCELLANEOUS_SUCCESS,
      data,
    };
  }
  
  export function editStudentMiscellaneousFailure(data) {
    return {
      type: EDIT_STUDENT_MISCELLANEOUS_FAILURE,
      data,
    };
  }
  
  // -----------delete Student Miscellaneous-------------
  export function deleteStudentMiscellaneous(data) {
    return {
      type: DELETE_STUDENT_MISCELLANEOUS,
      data,
    };
  }
  
  export function deleteStudentMiscellaneousSuccess(data) {
    return {
      type: DELETE_STUDENT_MISCELLANEOUS_SUCCESS,
      data,
    };
  }
  
  export function deleteStudentMiscellaneousFailure(data) {
    return {
      type: DELETE_STUDENT_MISCELLANEOUS_FAILURE,
      data,
    };
  }
  
  export function deleteGroupNoteFromSingleStudent(data) {
    return {
      type: DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT,
      data,
    };
  }
  
  export function deleteGroupNoteFromSingleStudentError(data) {
    return {
      type: DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT_ERROR,
      data,
    };
  }
  
  export function deleteGroupNoteFromSingleStudentSuccess(data) {
    return {
      type: DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT_SUCCESS,
      data,
    };
  }
  
  // -----------delete Student Miscellaneous-------------
  export function getGroupNoteStudents(data) {
    return {
      type: GET_GROUP_NOTE_STUDENTS,
      data,
    };
  }
  
  export function getGroupNoteStudentsSuccess(data) {
    return {
      type: GET_GROUP_NOTE_STUDENTS_SUCCESS,
      data,
    };
  }
  
  export function getGroupNoteStudentsFailure(data) {
    return {
      type: GET_GROUP_NOTE_STUDENTS_FAILURE,
      data,
    };
  }
  
  export function getAudioFromVertex(data) {
    return {
      type: GET_AUDIO_FROM_VERTEX,
      data,
    };
  }
  
  export function getAudioFromVertexError(data) {
    return {
      type: GET_AUDIO_FROM_VERTEX_ERROR,
      data,
    };
  }
  
  export function getAudioFromVertexSuccess(data) {
    return {
      type: GET_AUDIO_FROM_VERTEX_SUCCESS,
      data,
    };
  }
  export function getAudioSummaryFromVertex(data) {
    return {
      type: GET_AUDIO_SUMMARY_FROM_VERTEX,
      data,
    };
  }
  
  export function getAudioSummaryFromVertexError(data) {
    return {
      type: GET_AUDIO_SUMMARY_FROM_VERTEX_ERROR,
      data,
    };
  }
  
  export function getAudioSummaryFromVertexSuccess(data) {
    return {
      type: GET_AUDIO_SUMMARY_FROM_VERTEX_SUCCESS,
      data,
    };
  }
  
  export function resetStudentNotes(data) {
    return {
      type: RESET_STUDENT_NOTES,
      data
    }
  }
  