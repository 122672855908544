import { call, takeLatest, put } from 'redux-saga/effects';
import SettingReducer from 'Redux/Reducers/SettingReducer/SettingReducer';
import { createNotification } from 'Config/notificationtoast';
import UserLoadingReducer from 'Redux/Reducers/CommonReducer/loadingReducer';

import {
    GET_USER_SETTING_REQUEST,
    UPDATE_USER_SETTING_REQUEST
} from 'Actions/constants';

import {
    GetSetting_API,
    updateSetting_API
} from 'Config/Api';

import { chroniclecloud_code } from 'Config/Constant';
import { decryptData, encryptData, logOut } from 'Utils/Helper';
import request from 'Config/request';


export function* settingSaga() {
    yield takeLatest(GET_USER_SETTING_REQUEST, getUserSettingRequest);
    yield takeLatest(UPDATE_USER_SETTING_REQUEST, updateUserSettingRequest);
}


// ---------- Get User Setting-----
function* getUserSettingRequest(action) {

    let data = JSON.parse(sessionStorage.getItem('UserData'))
    yield put(UserLoadingReducer.loading(true));
    const URL = `${GetSetting_API}?user_id=${data.cc_user_id}&platform=web`;
    // const BODY = JSON.stringify({
    //     "chroniclecloud_code": chroniclecloud_code,
    //     "device_udid": sessionStorage.getItem('UDID'),
    //     "user_id": data.cc_user_id
    // })
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res)
        
        if (MainDecode.status === 200) {
            sessionStorage.setItem("dateFormat", JSON.stringify(MainDecode.data.cc_user_date_format))
            sessionStorage.setItem("separatorFormat", JSON.stringify(MainDecode.data.cc_user_seperator))
            sessionStorage.setItem("clockFormat", JSON.stringify(MainDecode.data.cc_user_clock_format))
            yield put(SettingReducer.getUserSettingSuccess(MainDecode));
            // createNotification('success', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLoadingReducer.loadingSuccess(false));
            yield put(SettingReducer.getUserSettingFailure(MainDecode));
        }
    } catch (error) {
        yield put(UserLoadingReducer.loadingSuccess(false));
        createNotification('warning', 'Something went wrong');
        yield put(SettingReducer.getUserSettingFailure(false));

    }
}
// ---------- Get User Setting-----

// ---------- Update User Setting-----
function* updateUserSettingRequest(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = updateSetting_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "user_email_id": data.cc_user_email_id,
        "user_password": data.cc_user_password,
        "user_first_name": data.cc_user_first_name,
        "user_last_name": data.cc_user_last_name,
        "user_school_name": data.cc_user_school_name,
        "user_pic": data.cc_user_pic || "",
        "user_green_note_days": action.data.user_green_note_days,
        "user_yellow_note_days": action.data.user_yellow_note_days,
        "user_date_format": action.data.user_date_format,
        "user_seperator": action.data.user_seperator,
        "user_clock_format": action.data.user_clock_format,
        "platform" : "web"
    }
  
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
       
        const MainDecode = decryptData(response.res)
       
        if (MainDecode.status === 200) {
            yield put(SettingReducer.updateUserSettingSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(SettingReducer.updateUserSettingFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        
        yield put(SettingReducer.updateUserSettingFailure(false));

    }
}
// ---------- Update User Setting -----

