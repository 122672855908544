import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


// import Logger from '../Lib/Logger'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    loading: ['status'],
    loadingSuccess: ['status'],
    loadingFailure: ['error'],

})

export const UserLoadingReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    loading: false,
    error: null,
    exists: null,
})

/* ------------- Reducers ------------- */

/* signin */
export const loading = (state) => {

    return {
        ...state, fetching: true, error: null, loading: true,
    }
}
export const loadingSuccess = (state, action) => {
    return { ...state, fetching: false, error: null, loading: false, exists: true }
}

// export const loadingSuccess = (state, action) => {
//     return { ...state, fetching: false, error: null, loading: false, exists: true }
// }
export const loadingFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, loading: false }
}




/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.LOADING]: loading,
    [Types.LOADING_SUCCESS]: loadingSuccess,
    [Types.LOADING_FAILURE]: loadingFailure,

})
