import React from 'react';
import { connect } from 'react-redux';
import DataUsageDashboard from './DataUsageDashboardComponent/DataUsageDashboard';
import { getDataUsageRequest } from 'Actions/DataUsageAction/dataUsageAction';


const DataUsageIndex = (props) => {
    return (
        <DataUsageDashboard {...props} />
    )
}
const mapStateToProps = state => ({
    state: state,
    DataUsage: state.DataUsage.DataUsage,
    loader: state.DataUsage.loader,

});
const mapDispatchToProps = dispatch => {
    return {
        getDataUsageRequest: (data) => dispatch(getDataUsageRequest(data)),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    DataUsageIndex
);