import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
// import { getImage } from "Config/commonFirebaseImage";


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({

    getClassNoteRequest: ['data'],
    getClassNoteSuccess: ['data', 'page'],
    getClassNoteFailure: ['error'],

    getClassGroupNotesData: ['data'],
    getClassGroupNotesDataSuccess: ['data', 'page'],
    getClassGroupNotesDataError: ['error'],

    classGroupNoteDelete: ['data'],
    classGroupNoteDeleteSuccess: ['data'],
    classGroupNoteDeleteError: ['error'],

    classGroupNoteAttachementArtifact: ['data'],
    classGroupNoteAttachementArtifactSuccess: ['data'],
    classGroupNoteAttachementArtifactError: ['error'],

    classGroupNoteArtifact: ['data'],
    classGroupNoteArtifactSuccess: ['data'],
    classGroupNoteArtifactError: ['error'],


    resetClassGroupNote: ['data'],



    getClassNoteNullRequest: ['data'],



})


export const UserClassNoteReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    getClassNote: {
        response_data: [],
        total_count: ''
    },
    getClassGroupNote: {
        response_data: [],
        total_count: ''
    },
    Class_Group_Note_Delete: null,
    Class_Group_Note_Attachement_Artifact: null,
    Class_Group_Note_Artifact: null,
    error: null,
    exists: null,
    loader: null,
    action: null,
    pagination: null
})

/* ------------- Reducers ------------- */



/* getClassNote */

export const getClassNoteRequest = (state, action) => {
    if (action.data.pageNo > 1) {
        return {
            ...state,
            fetching: true, error: null, loader: true,
            pagination: action.data.pageNo
        }
    } else {
        return {
            ...state, fetching: true, error: null, loader: true, pagination: action.data.pageNo,
            getClassNote: {
                response_data: [],
                total_count: ''
            },
        }
    }
}
export const getClassNoteSuccess = (state, action) => {
    const { data, page } = action;

    let getClassNotes = {
        total_count: data.data.total_count,
        cc_user_green_note_days: data.data.cc_user_green_note_days,
        cc_user_yellow_note_days: data.data.cc_user_yellow_note_days,
        class_core_standard: data.data.class_core_standard,
        note_count: data.data.note_count,
        note_limit: data.data.note_limit,
        subscription_title: data.data.subscription_title,
    }

    if (page > 1) {
        getClassNotes.response_data = [...state.getClassNote.response_data.concat(data.data.student_data)]
    } else {
        getClassNotes.response_data = data.data.student_data
    }
    return { ...state, fetching: false, error: null, getClassNote: getClassNotes, action: action.type, exists: true, loader: false }
}
export const getClassNoteFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_CLASS_NOTE_FAILURE', loader: false }
}

/*getClassGroupNotes*/
export const getClassGroupNotesData = (state, action) => {
    if (action.data.pageNo > 1) {
        return {
            ...state,
            fetching: true, error: null, loader: action?.data?.loader == false ? false : true,
            pagination: action.data.pageNo
        }
    } else {
        return {
            ...state, fetching: true, error: null, loader: action?.data?.loader == false ? false : true, pagination: action.data.pageNo,
            getClassGroupNote: {
                response_data: [],
                total_count: ''
            },
        }
    }
}
export const getClassGroupNotesDataSuccess = (state, action) => {
    const { data, page } = action;
    let getClassGroupNote = {
        total_count: data?.data?.total_count,
        class_core_standard: data?.data?.class_core_standard,
        note_count: data?.data?.total_note,
        student_list: data?.data?.student_list,
        student_enrolled_classes: data?.data?.student_enrolled_classes,
        student_details: data?.data?.student_details,
        // total_note: data?.data?.total_note
    }

    if (page > 1) {
        getClassGroupNote.response_data = [...state.getClassGroupNote.response_data.concat(data?.data?.response_data)]
    } else {
        getClassGroupNote.response_data = data?.data?.response_data
    }
    return { ...state, fetching: false, error: null, getClassGroupNote: getClassGroupNote, action: action.type, exists: true, loader: false }
}
export const getClassGroupNotesDataError = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_CLASS_GROUP_NOTES_DATA_ERROR', loader: false }
}


/* deleteClassGroupNote */
export const classGroupNoteDelete = (state) => {
    return {
        ...state, error: null, Class_Group_Note_Delete: null, loader: true
    }
}
export const classGroupNoteDeleteSuccess = (state, action) => {

    const { data } = action
    return { ...state, error: null, Class_Group_Note_Delete: data, action: action.type, exists: true, loader: false }
}
export const classGroupNoteDeleteError = (state, { error }) => {
    return { ...state, error, exists: false, action: 'CLASS_GROUP_NOTE_DELETE_ERROR', loader: false }
}


/* set Media Attachment Artifact */

export const classGroupNoteAttachementArtifact = (state) => {
    return {
        ...state, fetching: true, error: null, Class_Group_Note_Attachement_Artifact: null, Notesloader: true
    }
}
export const classGroupNoteAttachementArtifactSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, Class_Group_Note_Attachement_Artifact: data.response_data, action: action.type, exists: true, Notesloader: false }
}
export const classGroupNoteAttachementArtifactError = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT_ERROR', Notesloader: false }
}

/* class group note artifact */
export const classGroupNoteArtifact = (state) => {
    return {
        ...state, fetching: true, error: null, Class_Group_Note_Artifact: null, Notesloader: true
    }
}
export const classGroupNoteArtifactSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, Class_Group_Note_Artifact: data.response_data, action: action.type, exists: true, Notesloader: false }
}
export const classGroupNoteArtifactError = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'CLASS_GROUP_NOTE_ARTIFACT_ERROR', Notesloader: false }
}

/* getClassNoteBlank */

export const getClassNoteNullRequest = (state, action) => {
    return {
        ...state, getClassNote: {},
    }
}

export const resetClassGroupNote = (state) => {
    return {
        ...state, 
        getClassNote: {
            response_data: [],
            total_count: ''
        },
        getClassGroupNote: {
            response_data: [],
            total_count: ''
        },
        Class_Group_Note_Delete: null,
        Class_Group_Note_Attachement_Artifact: null,
        Class_Group_Note_Artifact: null,
        error: null,
        exists: null,
        loader: null,
        action: null,
        pagination: null
    }
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.GET_CLASS_NOTE_REQUEST]: getClassNoteRequest,
    [Types.GET_CLASS_NOTE_SUCCESS]: getClassNoteSuccess,
    [Types.GET_CLASS_NOTE_FAILURE]: getClassNoteFailure,

    [Types.GET_CLASS_GROUP_NOTES_DATA]: getClassGroupNotesData,
    [Types.GET_CLASS_GROUP_NOTES_DATA_SUCCESS]: getClassGroupNotesDataSuccess,
    [Types.GET_CLASS_GROUP_NOTES_DATA_ERROR]: getClassGroupNotesDataError,

    [Types.CLASS_GROUP_NOTE_DELETE]: classGroupNoteDelete,
    [Types.CLASS_GROUP_NOTE_DELETE_SUCCESS]: classGroupNoteDeleteSuccess,
    [Types.CLASS_GROUP_NOTE_DELETE_ERROR]: classGroupNoteDeleteError,

    [Types.CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT]: classGroupNoteAttachementArtifact,
    [Types.CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT_SUCCESS]: classGroupNoteAttachementArtifactSuccess,
    [Types.CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT_ERROR]: classGroupNoteAttachementArtifactError,

    [Types.CLASS_GROUP_NOTE_ARTIFACT]: classGroupNoteArtifact,
    [Types.CLASS_GROUP_NOTE_ARTIFACT_SUCCESS]: classGroupNoteArtifactSuccess,
    [Types.CLASS_GROUP_NOTE_ARTIFACT_ERROR]: classGroupNoteArtifactError,


    [Types.RESET_CLASS_GROUP_NOTE]: resetClassGroupNote,








    [Types.GET_CLASS_NOTE_NULL_REQUEST]: getClassNoteNullRequest,


})
