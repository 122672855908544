import React from 'react';
import Card from 'react-bootstrap/Card';
import './FAQDashboard.scss'

const FAQDashboard = (props) => {

    return (
        <div className='faqContainer'>
            <Card className='cardOfFAQ'>
                <p className='faqHeading'>FAQs</p>

                {/* ------- General ------- */}
                <div>
                    <p className='faqHeadingType'>General</p>
                    <div>
                        <p className='faqQuestion'>1. Chronicle Cloud is an application for teachers, but can students and/or parents also access this data?</p>
                        <p className='faqDescriptionText'>Chronicle Cloud is not a learning management system, although some similarities may exist. Chronicle Cloud is designed solely for teachers; however, a teacher is welcome to share data, via email or face-to-face meetings, with students and/or parents as they deem appropriate. Otherwise, it is not designed to be used by students and/or parents.</p>
                    </div>
                    <div>
                        <p className='faqQuestion'>2. What kind of notes can a teacher take using Chronicle Cloud?</p>
                        <p className='faqDescriptionText'>Chronicle Cloud is a platform for conferring, observational, and/or any other anecdotal notes. Notes for any class can be customized with up to five text field entry boxes, and within each note, a teacher can capture and/or upload/attach multiple images (JPEG, PNG), audio files, and videos (MP4). All notes can be applied to an individual or a group of students.</p>
                    </div>
                    <div>
                        <p className='faqQuestion'>3. Can I print my notes?</p>
                        <p className='faqDescriptionText'>Notes can be exported to Google Drive or Dropbox as a .txt file. This file can then be downloaded and printed.</p>
                    </div>
                    <div>
                        <p className='faqQuestion'>4. How do Chronicle Cloud’s sharing and collaboration features work?</p>
                        <p className='faqDescriptionText'>Teachers can share students and use Chronicle Cloud’s note-taking platform to collaborate. A user (teacher) can grant access rights (editing and viewing privileges) to other teachers within the same school only. The ability to track and restore revision changes is not a functionality at the moment, nor do Chronicle Cloud’s sharing capabilities apply to the gradebook. These may be considered for future versions.</p>
                    </div>
                    <div>
                        <p className='faqQuestion'>5. I don’t really need a gradebook. Is there an option to exclude it?</p>
                        <p className='faqDescriptionText'>At the moment, no. Chronicle Cloud is designed to empower teachers with the unique ability to seamlessly integrate qualitative and quantitative data. The gradebook itself has been conceptually formed to provide flexibility, allowing teachers to use it traditionally and/or non-traditionally as a complement to their notes. Simply not using the gradebook, however, is always an option.</p>
                    </div>
                </div>
                {/* ------- General ------- */}

                {/* ------- Technical ------- */}
                <div>
                    <p className='faqHeadingType'>Technical</p>
                    <div>
                        <p className='faqQuestion'>6. Which platforms and devices does Chronicle Cloud support?</p>
                        <p className='faqDescriptionText'>Chronicle Cloud is available on IPad, Android, supporting iOS 10 and above, and Website. Plans to branch into other platforms and devices are in the works!</p>
                    </div>
                    <div>
                        <p className='faqQuestion'>7. Can one device share multiple Chronicle Cloud accounts?</p>
                        <p className='faqDescriptionText'>Chronicle Cloud’s intended use is that teachers collect data using their own device.</p>
                    </div>
                    <div>
                        <p className='faqQuestion'>8. I currently use one of the Chronicle Legacy apps (Chronicle, Chronicle Notes, Chronicle U). How do I migrate my data to Chronicle Cloud?</p>
                        <p className='faqDescriptionText'>Please follow these steps: 1. From your Chronicle Legacy app, export your data to Google Drive (exported data will be in CHR format with the extension .CHR). 2. Open your new Chronicle Cloud app and upload/import this .CHR file from Google Drive. 3. After your data is successfully migrated, you will receive an email. 4. Use your same credentials to log in to Chronicle Cloud.</p>
                    </div>
                    <div>
                        <p className='faqQuestion'>9. How do I receive technical support?</p>
                        <p className='faqDescriptionText'>You can email us directly at support@chroniclecloud.com, or you can use our website to submit a help ticket: <a href="https://chroniclecloud.com/#/contact" target='_blank' rel="noopener noreferrer">https://chroniclecloud.com/#/contact</a>.</p>
                    </div>
                </div>
                {/* ------- Technical ------- */}

                {/* ------- Subscriptions ------- */}
                <div>
                    <p className='faqHeadingType'>Subscriptions</p>
                    <div>
                        <p className='faqQuestion'>10. How do Premium and Professional subscription plans differ?</p>
                        <p className='faqDescriptionText'>The application is completely free without any restriction on functionalities. The upgrade subscriptions only offer additional data storage.</p>
                    </div>
                    <div>
                        <p className='faqQuestion'>11. What if I need more data than the Professional plan provides?</p>
                        <p className='faqDescriptionText'>1 GB should suffice the heaviest of Chronicle Cloud users, but if a need for more data ever presents itself, our support team will gladly assist you: support@chroniclecloud.com.</p>
                    </div>
                    <div>
                        <p className='faqQuestion'>12. What payment options do you offer?</p>
                        <p className='faqDescriptionText'>We support In-App Purchases.</p>
                    </div>
                    <div>
                        <p className='faqQuestion'>13. If desired, how do I upgrade my plan?</p>
                        <p className='faqDescriptionText'>You have two options: 1. Log in to your Chronicle Cloud app > Go to Data Usage > select Upgrade Plan > choose the desired plan. 2. Log in to the Chronicle Cloud website and purchase the plan of your choice.</p>
                    </div>
                    <div>
                        <p className='faqQuestion'>14. What’s the process for canceling my plan?</p>
                        <p className='faqDescriptionText'>On your iOS device, go to Settings and select iTunes & App Store > Apple ID > View Apple ID > Subscription > Cancel Subscription.</p>
                    </div>
                    <div>
                        <p className='faqQuestion'>15. I teach 10 months of the year. Do I have to continue paying through the remaining 2 months?</p>
                        <p className='faqDescriptionText'>Although you are not locked into an annual subscription and can cancel at any time, we strongly recommend continuing your paid subscription through those remaining two months. There are logistical reasons for continuing your subscription, but additionally, it is always best practice to retain student data for a period of time after the completion of an academic year.</p>
                    </div>
                    <div>
                        <p className='faqQuestion'>16. Do you provide multi-licensing discounts?</p>
                        <p className='faqDescriptionText'>Chronicle Cloud is designed to promote collaborative teaching – so, yes, we do provide specialized plans and discounts! For further information, please contact us at sales@chroniclecloud.com.</p>
                    </div>
                </div>
                {/* ------- Subscriptions ------- */}

            </Card>
        </div>
    )
}

export default FAQDashboard