import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    sendContactUsRequest: ['data'],
    sendContactUsSuccess: ['data'],
    sendContactUsFailure: ['error'],

})

export const userContactUsReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    ContactData: [],
    error: null,
    exists: null,
    loader: null,
    action: null
})

/* ------------- Reducers ------------- */

/* Send Contact Us Request */
export const sendContactUsRequest = (state) => {
    return { ...state, fetching: true, error: null, loader: true }
}
export const sendContactUsSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, loader: false, ContactData: data }
}
export const sendContactUsFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'SEND_CONTACT_US_FAILURE', loader: false }
}



/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.SEND_CONTACT_US_REQUEST]: sendContactUsRequest,
    [Types.SEND_CONTACT_US_SUCCESS]: sendContactUsSuccess,
    [Types.SEND_CONTACT_US_FAILURE]: sendContactUsFailure,

})
