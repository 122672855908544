import {
    GET_CLASS_CORE_STANDARD,
    GET_CLASS_CORE_STANDARD_SUCCESS,
    GET_CLASS_CORE_STANDARD_FAILURE,
    
    GET_CORE_STANDARD,
    GET_CORE_STANDARD_SUCCESS,
    GET_CORE_STANDARD_FAILURE,
    
    ADD_CLASS_CORE_STANDARD,
    ADD_CLASS_CORE_STANDARD_SUCCESS,
    ADD_CLASS_CORE_STANDARD_FAILURE,

    GET_NOTE_SPIRAL_TAG,
    GET_NOTE_SPIRAL_TAG_SUCCESS,
    GET_NOTE_SPIRAL_TAG_FAILURE
} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */



// -----------GetClass Tag-------------
export function getClassCoreStandard(data) {
    return {
        type: GET_CLASS_CORE_STANDARD,
        data,
    };
}

export function getClassCoreStandardSuccess(data) {
    return {
        type: GET_CLASS_CORE_STANDARD_SUCCESS,
        data,
    };
}

export function getClassCoreStandardFailure(data) {
    return {
        type: GET_CLASS_CORE_STANDARD_FAILURE,
        data,
    };
}


// -----------AddClass Tag-------------
export function getCoreStandard(data) {
    return {
        type: GET_CORE_STANDARD,
        data,
    };
}

export function getCoreStandardSuccess(data) {
    return {
        type: GET_CORE_STANDARD_SUCCESS,
        data,
    };
}

export function getCoreStandardFailure(data) {
    return {
        type: GET_CORE_STANDARD_FAILURE,
        data,
    };
}


// -----------Import Class Speed Note-------------
export function addClassCoreStandard(data) {
    return {
        type: ADD_CLASS_CORE_STANDARD,
        data,
    };
}

export function addClassCoreStandardSuccess(data) {
    return {
        type: ADD_CLASS_CORE_STANDARD_SUCCESS,
        data,
    };
}

export function addClassCoreStandardFailure(data) {
    return {
        type: ADD_CLASS_CORE_STANDARD_FAILURE,
        data,
    };
}


//----------IMPORT CORE NOTES ON SPIRAL------------
export function getNoteSpiralTag(data) {
    return {
        type: GET_NOTE_SPIRAL_TAG,
        data,
    };
}

export function getNoteSpiralTagSuccess(data) {
    return {
        type: GET_NOTE_SPIRAL_TAG_SUCCESS,
        data,
    };
}

export function getNoteSpiralTagFailure(data) {
    return {
        type: GET_NOTE_SPIRAL_TAG_FAILURE,
        data,
    };
}

