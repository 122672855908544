import React from 'react';
import { connect } from 'react-redux';
import ClassCoreStandardsDashboard from './ClassCoreStandardsDashboard';
import {
    getClassCoreStandard,
    getCoreStandard,
    addClassCoreStandard
  
} from 'Actions/ClassAction/ClassStandardsAction';

const ClassCoreStandardsIndex = (props) => {
    return (
        <ClassCoreStandardsDashboard {...props} />
    )
}

const mapStateToProps = state => ({
    state: state,
    getClassCoreStandard: state.classStandard.core_Standard,
    CoreStandard: state.classStandard.core_Standard,
});
const mapDispatchToProps = dispatch => {
    return {
        getClassCoreStandard: (data) => dispatch(getClassCoreStandard(data)),
        getCoreStandard: (data) => dispatch(getCoreStandard(data)),
        addClassCoreStandard: (data) => dispatch(addClassCoreStandard(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    ClassCoreStandardsIndex
);