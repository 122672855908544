

import {
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,

} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */


// -----------Get AcademicList-------------
export function updateUser(data) {
    return {
        type: UPDATE_USER,
        data,
    };
}

export function updateUserSuccess(data) {
    return {
        type: UPDATE_USER_SUCCESS,
        data,
    };
}

export function updateUserFailure(data) {
    return {
        type: UPDATE_USER_FAILURE,
        data,
    };
}

export function deleteUser(data) {
    return {
        type: DELETE_USER,
        data
    }
}

export function deleteUserSuccess(data) {
    return {
        type: DELETE_USER_SUCCESS,
        data
    }
}

export function deleteUserFailure(data){
    return {
        type: DELETE_USER_FAILURE,
        data
    }
}

