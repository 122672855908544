import React, { useState, useEffect, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import { connect, useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import back from 'Assets/CommonComponent/back.png';
import addNoteIcon from 'Assets/NoteModule/plusNew.svg';
import Popover from "react-bootstrap/Popover";
import OutsideClickHandler from 'react-outside-click-handler';
import ClassNoteAddGroupPanel from '../ClassNoteAddGroupPanelComponent/ClassNoteAddGroupPanel';
import { getStudentList, getGroupLists } from "Utils/Helper";
import UpgradePopUp from '../../../upgradePopUp/upgradePopUp'
import saveIcon from '../../../../Assets/NoteModule/saveBtnSquare.svg';
import cancelIcon from '../../../../Assets/NoteModule/closeBtnSquare.svg';
import tickIcon from '../../../../Assets/NoteModule/tick.svg';
import tick from 'Assets/NoteModule/tick.svg';
import closeIcon from 'Assets/NoteModule/close.svg';
import GrpIcon from "Assets/NoteModule/GrpIconNew.png"
// import tickIcon from 'Assets/NoteModule/tick.svg';
import DivLoader from 'Components/LoadingComponent/DivLoader';
import './ClassNoteAddGroup.scss';
import { getGroupDetailsRequest, setGroupNotesState } from 'Actions/NoteAction/groupDetailsAction';
import SelectSearch from 'react-select-search';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import { fuzzySearch } from "react-select-search";
import { createNotification } from 'Config/notificationtoast';
import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';
import warningIcon from 'Assets/NoteModule/warning-icon.png'
import ScrollContainer from 'react-indiana-drag-scroll'
import { resetClassGroupNote } from 'Actions/NoteAction/classNoteAction';
const ClassNoteAddGroup = (props) => {
    const { history, location, class_id } = props;
 
    const dispatch = useDispatch()
    const { classDetails, pageNo, academicYear } = location.state || "";
    const { classListData, selectedClass } = history.location.state;
    const { getGroupDetailsRequest, groupDetails, groupsData, addMultipleStudentsRequest, addMultipleStudents, deleteGroupRequest, deleteGroup,
        addNewStudentInGroupRequest, addNewStudentInGroup, removeStudentFromGroupRequest, removeStudentFromGroup, renameGroupRequest, renameGroup, createGroup, createGroupRequest } = props;
    const { loader } = groupDetails
    const [enabledCheck, setEnabledCheck] = useState(false);
    const [showtick, setShowtick] = useState(true);
    const [open, setOpen] = useState(false);
    const [cancelled, setCancelled] = useState(false);
    const [groupList, setGroupList] = useState([]);
    const [editGroup, setEditGroup] = useState(false);
    const [addedStudents, setAddedStudents] = useState([]);
    const [isGroupName, setGroupName] = useState('');
    const [showAddNewGroup, setShowAddNewGroup] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState();
    const [newStudentsList, setNewStudentsList] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false);
    const [newAddedGroups, setNewAddedGroups] = useState([newStudentsList]);
    const [newArr, setNewArr] = useState([]);
    const [removeStudentArr, setRemoveStudentArr] = useState([])
    const [allClassses, setAllClasses] = useState([])
    const [isSelected, setIsSelected] = useState(true);
    const [disableGroup, setDisablrGroup] = useState(false)
    const [showActionBtnOnSave, setShowActionBtnOnSave] = useState(false)
    const [selectedClassId, setSelectedClassId] = useState(0)
    const [totalStudentList, setTotalStudentList] = useState(0)
    const [goBackModal, setGoBackModal] = useState(false)
    const [removeStudentConfirmationModal, SetremoveStudentConfirmationModal] = useState(false)
    const [removedStudentsName, setRemovedStudentsName] = useState('')
    const [payloadForAPI, setPayloadForAPI] = useState({})
    const [removeStudentGroupName, setRemoveStudentGroupName] = useState('')
    const [groupEdit, setGroupEdit] = useState(false)
    const [isAddGroupBtnDisable, setIsAddGroupBtnDisable] = useState(false)
    const [customProps, setCustomProps] = useState({})
    const [changeClassData, setChangeClassData] = useState(null)
    const [selectedClassName, setSelectedClassName] = useState(selectedClass ? selectedClass : classDetails?.cc_class_name)
    const selectedClassName2 = selectedClass ? selectedClass[0].name : classDetails.cc_class_name

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);


    useEffect(() => {
        dispatch(resetClassGroupNote())
    },[])
    

    // const [count, setCount] = useState(0);
    // const [nameEdit, setNameEdit] = useState(false);
    // const [enabledEdit, setEnabledEdit] = useState(false);
    // const [expandPanel, setExpandPanel] = useState(-1);

    const getGroupDetail = groupDetails.getGroupDetails;
    const getClassId = props.location.state.class_id;
    const [addNewGroupBtnShow, setAddNewGroupBtnShow] = useState(false);

  

    useEffect(() => {
        console.log('effectnewStudentsList')
    },[newStudentsList])

    useEffect(() => {
        // if (classDetails) {
            console.log('getGroupDetailsRequest')
        let apiData = {
            "class_id": selectedClassId > 0 ? selectedClassId : (sessionStorage.getItem('notesclassId') && sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : getClassId),
            "pageNo": "full",
            changeClass : true
        }
        getGroupDetailsRequest(apiData);
        setShowActionBtnOnSave(false)
    }, [classDetails, pageNo, getGroupDetailsRequest])


    useEffect(() => {
        if (addMultipleStudents.type === "ADD_MULTIPLE_STUDENTS_SUCCESS") {
            let apiData = {
                "class_id": selectedClassId > 0 ? selectedClassId : (sessionStorage.getItem('notesclassId') && sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : getClassId),
                "pageNo": "full"
            }
            getGroupDetailsRequest(apiData);
            setShowActionBtnOnSave(false)
        }
    }, [addMultipleStudents, getClassId, pageNo, getGroupDetailsRequest])

    useEffect(() => {
        if (deleteGroup.type === "DELETE_GROUP_SUCCESS") {
            let apiData = {
                "class_id":  selectedClassId > 0 ? selectedClassId : (sessionStorage.getItem('notesclassId') && sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : getClassId),
                "pageNo": "full"
            }
            getGroupDetailsRequest(apiData);
            setShowActionBtnOnSave(false)
        }
    }, [deleteGroup, getClassId, pageNo, getGroupDetailsRequest])

    useEffect(() => {
        if (groupDetails && groupDetails.action === "CREATE_GROUP_FAILURE" || "RENAME_GROUP_FAILURE") {
            if (groupDetails.error && (groupDetails.error.message === "Note limit has been exceeded. Please upgrade the plan"
                || groupDetails.error.message === "Media limit has been exceeded. Please upgrade the plan"
                || groupDetails.error.message === "Please update your subscription plan")) {
                setShowPopUp(true);
            }
        }
    }, [groupDetails])

    useEffect(() => {
        if (removeStudentFromGroup.type === "REMOVE_STUDENT_FROM_GROUP_SUCCESS") {
            let apiData = {
                "class_id":selectedClassId > 0 ? selectedClassId : (sessionStorage.getItem('notesclassId') && sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : getClassId),
                "pageNo": "full"
            }
            getGroupDetailsRequest(apiData);
            setShowActionBtnOnSave(false)
        }
    }, [removeStudentFromGroup, getClassId, pageNo, getGroupDetailsRequest])

    useEffect(() => {
        if (renameGroup.type === "RENAME_GROUP_SUCCESS") {
            let apiData = {
                "class_id": selectedClassId > 0 ? selectedClassId : (sessionStorage.getItem('notesclassId') && sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : getClassId),
                "pageNo": "full"
            }
            getGroupDetailsRequest(apiData);
            setShowActionBtnOnSave(false)
        }
    }, [renameGroup, getClassId, pageNo, getGroupDetailsRequest])

    useEffect(() => {
        if (createGroup.type === "CREATE_GROUP_SUCCESS") {
            setShowAddNewGroup(false);
            setShowActionBtnOnSave(false)
            let apiData = {
                "class_id": selectedClassId > 0 ? selectedClassId : (sessionStorage.getItem('notesclassId') && sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : getClassId),
                "pageNo": "full"
            }
            getGroupDetailsRequest(apiData);
        }
    }, [createGroup, getClassId, pageNo, getGroupDetailsRequest])

    useEffect(() => {
        if (addNewStudentInGroup.type === "ADD_NEW_STUDENT_IN_GROUP_SUCCESS") {
            setAddedStudents([]);
            setShowAddNewGroup(false);
            setShowActionBtnOnSave(false)
            let apiData = {
                "class_id":  selectedClassId > 0 ? selectedClassId : (sessionStorage.getItem('notesclassId') && sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : getClassId),
                "pageNo": "full"
            }
            getGroupDetailsRequest(apiData);
        }
    }, [addNewStudentInGroup, getClassId, pageNo, getGroupDetailsRequest])

    useEffect(() => {
        const StudentList = getStudentList(getGroupDetail);
        const [newStudentlist] = StudentList
        
        setNewStudentsList(newStudentlist);
        setRemoveStudentArr([])
    }, [groupDetails, getGroupDetail])

    useEffect(() => {
        
    }, [newStudentsList])

    useEffect(() => {
        
    },[addedStudents])

    useEffect(() => {

    }, [newAddedGroups])

    const editGroupHandler = (value) => {
        
        setGroupEdit(value)
    }

    const disableAddGroupBtnFunction = (value) => {
        setIsAddGroupBtnDisable(value)
    }
    

    const disabledGroupEditActionButton = () => {
        setEnabledCheck(false);
        setCancelled(true);
        setAddedStudents([]);
        setEditGroup(false);
        let apiData = {
            "class_id": selectedClassId > 0 ? selectedClassId : (sessionStorage.getItem('notesclassId') && sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : getClassId),
            "pageNo": pageNo
        }
        getGroupDetailsRequest(apiData);
        setGroupName("");
    }

    const enabledGroupEditActionButton = (value) => {
        setEnabledCheck(false)
        setCancelled(true);
        setEditGroup(false)
        if (editGroup) {
            let addedStudent = [...new Set(addedStudents)];
            let removeStudents = [...new Set(removeStudentArr)];
            let parentGroupData = JSON.parse(sessionStorage.getItem('groupOfStudentsInNotes'))
            const groupsList = getGroupLists(getGroupDetail);
          
            let childGroupData = parentGroupData.filter((item) => item?.cc_group_id == groupsList?.[0]?.cc_group_id )
         
            setRemoveStudentGroupName(groupsList?.[0]?.cc_group_name)    

            const set1 = new Set(childGroupData?.[0]?.student_list);
            const set2 = new Set(groupsList?.[0]?.student_list);

            const arraysAreEqual = childGroupData?.[0]?.student_list.length === groupsList?.[0]?.student_list.length && Array.from(set1).every(item => set2.has(item));
           

            if(arraysAreEqual) {
                if(addedStudent.length == 0) {
                    SetremoveStudentConfirmationModal(true)
                    let apiData = {
                        "class_id": getClassId,
                        "studentId": addedStudent?.length > 0 ? addedStudent : [],
                        "remove_student_id" : removeStudents?.length > 0 ? removeStudents :  [],
                        "groupId": selectedGroup.toString(),
                    }
                    let filteredStudentName = newStudentsList.filter((students) => removeStudents.includes(students?.cc_student_id))
                    let studentNames = filteredStudentName.map(student => `${student.cc_student_first_name} ${student.cc_student_last_name}`);
                  
                    setRemovedStudentsName(studentNames.join(', '))
                    setPayloadForAPI(apiData)
                } else{
                    if(removeStudents.length > 0 && removeStudents.every((studentID) => childGroupData?.[0]?.student_list.includes(studentID))) {
                        let filteredStudentName = newStudentsList.filter((students) => removeStudents.includes(students?.cc_student_id))
                        let studentNames = filteredStudentName.map(student => `${student.cc_student_first_name} ${student.cc_student_last_name}`);
                       
                        setRemovedStudentsName(studentNames.join(', '))
                        SetremoveStudentConfirmationModal(true)
                        let apiData = {
                            "class_id": getClassId,
                            "studentId": addedStudent?.length > 0 ? addedStudent : [],
                            "remove_student_id" : removeStudents?.length > 0 ? removeStudents :  [],
                            "groupId": selectedGroup.toString(),
                        }
                        
                        setPayloadForAPI(apiData)
                        // addNewStudentInGroupRequest(apiData)
                    } else {
                SetremoveStudentConfirmationModal(false)
                let apiData = {
                    "class_id": getClassId,
                    "studentId": addedStudent?.length > 0 ? addedStudent : [],
                    "remove_student_id" : removeStudents?.length > 0 ? removeStudents :  [],
                    "groupId": selectedGroup.toString(),
                }
              
                addNewStudentInGroupRequest(apiData)
            }
            }
            } 
            else {
            if(removeStudents.length > 0) {
                if(addedStudent.length == 0) {
                    SetremoveStudentConfirmationModal(true)
                    let filteredStudentName = newStudentsList.filter((students) => removeStudents.includes(students?.cc_student_id))
                    let studentNames = filteredStudentName.map(student => `${student.cc_student_first_name} ${student.cc_student_last_name}`);
                    
                    setRemovedStudentsName(studentNames.join(', '))
                    SetremoveStudentConfirmationModal(true)
                } else {
                        let allRemovedStudentsAdded = removeStudents.every((studentID) => addedStudent.includes(studentID))
                        if(!allRemovedStudentsAdded ) {
                            let filteredStudentName = newStudentsList.filter((students) => removeStudents.includes(students?.cc_student_id))
                            let studentNames = filteredStudentName.map(student => `${student.cc_student_first_name} ${student.cc_student_last_name}`);
                         
                            setRemovedStudentsName(studentNames.join(', '))
                            SetremoveStudentConfirmationModal(true)
                            SetremoveStudentConfirmationModal(true)
                        }
                }
                 let apiData = {
                        "class_id": getClassId,
                        "studentId": addedStudent?.length > 0 ? addedStudent : [],
                        "remove_student_id" : removeStudents?.length > 0 ? removeStudents :  [],
                        "groupId": selectedGroup.toString(),
                    }
                 
                    setPayloadForAPI(apiData)
                    // addNewStudentInGroupRequest(apiData);
            } else {
                let apiData = {
                    "class_id": getClassId,
                    "studentId": addedStudent?.length > 0 ? addedStudent : [],
                    "remove_student_id" : removeStudents?.length > 0 ? removeStudents :  [],
                    "groupId": selectedGroup.toString(),
                }
               
                addNewStudentInGroupRequest(apiData);
            }
        }
        }
        if (!editGroup) {
            if (isGroupName !== "") {
                let apiData = {
                    "groupName": isGroupName.trim(),
                    "groupId": selectedGroup.toString(),
                }
                renameGroupRequest(apiData);
            }
            setGroupName("");
        }
    }

    const getData = (info) => {
        setOpen(true);
    }

    const disableCheck = (value) => {
        setShowtick(false);
    }

    const enableCheck = (value) => {
        setShowtick(true);
    }

    const handleClosePopUp = event => {
        setShowPopUp(false);
    }

    const saveModalData = (profiles) => {
        setOpen(false);
        let groupsAssociated = []
        profiles && profiles.associated_group.map(val => {
            if (val.cc_group_id !== "All" && val.is_associated === 1) {
                if (!groupsAssociated.includes(val.cc_group_id)) {
                    groupsAssociated.push(val.cc_group_id)
                }
            }
            return groupsAssociated
        })
        if (getClassId) {
            let apiData = {
                "class_id": getClassId,
                "studentId": profiles.cc_student_id,
                "group_ids": groupsAssociated,
            }
            addMultipleStudentsRequest(apiData);
        }
    }

    // const closeModal = (value) => {
    //     setOpen(false);
    //     setNewStudentsList(groupsData[0][0].student_list);
    // }

    const getSelectedGroup = (value) => {
        setSelectedGroup(value)
    }

    const addMultipleModal = (groupId, profileId) => {
        let studentData = newStudentsList && newStudentsList.map(value => {
            let studentValue = { ...value }
            if (value.cc_student_id === profileId) {
                let associatGroup = value.associated_group.map(val => {
                    if (groupId === "All") {
                        return { ...val, is_associated: 1 }
                    } else if (groupId !== "All" && val.cc_group_id === groupId) {
                        return { ...val, is_associated: 1 }
                    } else {
                        return { ...val }
                    }
                })
                studentValue = { ...studentValue, associated_group: associatGroup }
            }
            return studentValue
        })
        let newAddedGroup = [...studentData];
        setNewAddedGroups(newAddedGroup)
        setNewStudentsList(newAddedGroup)
    }

    const removeMultipleModal = (groupId, profileId) => {
        let studentData = newStudentsList && newStudentsList.map(value => {
            let studentValue = { ...value }
            if (value.cc_student_id === profileId) {
                let associatGroup = value.associated_group.map(val => {
                    if (groupId === "All") {
                        return { ...val, is_associated: 0 }
                    } else if (groupId !== "All" && val.cc_group_id === groupId) {
                        return { ...val, is_associated: 0 }
                    } else {
                        return { ...val }
                    }
                })
                studentValue = { ...studentValue, associated_group: associatGroup }
            }
            return studentValue
        })
        let newAddedGroup = [...studentData];
        setNewAddedGroups(newAddedGroup)
        setNewStudentsList(newAddedGroup)
    }


    useEffect(() => {
        if (!editGroup) {
            const groupsList = getGroupLists(getGroupDetail);
            
            setGroupList(groupsList);
        }
    }, [groupDetails, editGroup, getGroupDetail])


    const handleRemoveStudent = (groupList, value, id) => {

        let groupData = groupList.map(val => {
            if (val.cc_group_id === value.cc_group_id) {
                var newArray = val.student_list.filter(e => e !== id)
                return { ...val, student_list: newArray }
            } else {
                return { ...val }
            }
        })

        
        // setAddedStudents([...addedStudents, id]);
        setRemoveStudentArr([...removeStudentArr, id])
        setAddedStudents(addedStudents.filter(e => e !== id));

        let studentData = newStudentsList && newStudentsList.map(student => {
            let studentValue = { ...student }
            if (student.cc_student_id === id) {
                let associatStudents = student.associated_group.map(val => {
                    if (value.cc_group_id === 0) {
                        if (val.cc_group_id === 0 && val.cc_group_name === "New Group") {
                            return { ...val, is_associated: 0 }
                        }
                        else {
                            return { ...val }
                        }
                    } else {
                        if (val.cc_group_id === value.cc_group_id) {
                            return { ...val, is_associated: 0 }
                        } else {
                            return { ...val }
                        }
                    }
                })
                studentValue = { ...studentValue, associated_group: associatStudents }
            }
            return studentValue
        })
        

        setGroupList(groupData);
        setNewStudentsList(studentData);
        // if (!showAddNewGroup) {
        //     let apiData = {
        //         "studentId": id,
        //         "groupId": selectedGroup.toString(),
        //     }
        //     removeStudentFromGroupRequest(apiData);
        // }
    }

    useEffect(() => {
        
    },[removeStudentArr])

    useEffect(() => {
  
    },[addedStudents])

    // const editAddStudents = (id) => {
    //     if (!addedStudents.includes(id)) {
    //         console.log('upperif')
    //         // for (let student of newStudentsList) {
    //         //     if (student.cc_student_id === id) {
    //         //         student.associated_group.map(val => {
    //         //             if (val.cc_group_id === selectedGroup) {
    //         //                 if (val.is_associated === 0) {
    //         //                     val.is_associated = 1
    //         //                 }
    //         //             }
    //         //         })
    //         //     }
    //         // }
    //         let studentData = newStudentsList && newStudentsList.map(student => {
    //             let studentValue = { ...student }
    //             if (student.cc_student_id === id) {
    //                 let associatStudents = student.associated_group.map(val => {
    //                     if (selectedGroup === 0) {
    //                         if (val.cc_group_id === 0 && val.cc_group_name === "New Group") {
    //                             if(val.is_associated == 0) {
    //                                 return {...val, is_associated: 1}
    //                             } else {
    //                             return { ...val, is_associated: 0 }
    //                             }
    //                         }
    //                         else {
    //                             return { ...val }
    //                         }
    //                     } else {
    //                         if (val.cc_group_id === selectedGroup) {
    //                             if(val.is_associated == 0) {
    //                                 return {...val, is_associated: 1}
    //                             } else {
    //                                 return {...val, is_associated: 0}
    //                             }
    //                             // return { ...val, is_associated: 1 }
    //                         } else {
    //                             return { ...val }
    //                         }
    //                     }
    //                 })
    //                 studentValue = { ...studentValue, associated_group: associatStudents }
    //             }
    //             return studentValue
    //         })
    //         console.log(studentData)
    //         if (!addedStudents.includes(id)) {
               
    //             setAddedStudents([...addedStudents, id]);
    //         }
    //         let newData = [...groupList];
    //         let data = newData.find((item) => item.cc_group_id === selectedGroup);
    //         let addedStudent = [...new Set(data.student_list)];
    //         console.log(addedStudent)
    //         if (!addedStudent.includes(id)) {
               
    //             // setAddedStudents([...addedStudents, id]);
    //             data.student_list = [...addedStudent, id]
    //         } else {
    //             let newArray = data.student_list.filter(e => e !== id)
    //             console.log(newArray)
    //             data.student_list = newArray
    //         }
    //         // data.student_list = [...addedStudent, id]
    //         console.log('newData',newData)
    //         console.log('upperrebelowmore',removeStudentArr)
    //         // const selectedData = groupList.map((item) => {
    //         //     if(item.cc_group_id === selectedGroup) {
    //         //    return {
    //         //     ...item,
    //         //     student_list: [...item.student_list, id]
    //         //   }
              
    //         // }});
    //         //   console.log('selectedData',selectedData)
    //         // let groupData = groupList.map(val => {
    //         //     if (val.cc_group_id === selectedGroup) {
    //         //         var newArray = val.student_list.push(id)
    //         //         console.log(newArray)
    //         //         // return { ...val, student_list: newArray }
    //         //     } 
    //         //     // else {
    //         //     //     return { ...val }
    //         //     // }
    //         // })
    //         // console.log(groupData)
    //         setGroupList(newData);
    //         console.log(newStudentsList)
    //         console.log('studentData',studentData)
    //         setNewStudentsList(studentData);
    //         console.log(removeStudentArr)
    //     } else {
    //         console.log('upperelse')
    //         // for (let student of newStudentsList) {
    //         //     if (student.cc_student_id === id) {
    //         //         student.associated_group.map(val => {
    //         //             if (val.cc_group_id === selectedGroup) {
    //         //                 if (val.is_associated === 1) {
    //         //                     val.is_associated = 0
    //         //                 }
    //         //             }
    //         //         })
    //         //     }
    //         // }
    //         // let groupData = groupList.map(val => {
    //         //     if (val.cc_group_id === selectedGroup) {
    //         //         var newArray = val.student_list.filter(e => e !== id)
    //         //         return { ...val, student_list: newArray }
    //         //     } else {
    //         //         return { ...val }
    //         //     }
    //         // })
    //         let studentData = newStudentsList && newStudentsList.map(student => {
    //             let studentValue = student 
    //             if (student.cc_student_id === id) {
    //                 let associatStudents = student.associated_group.map(val => {
    //                     if (selectedGroup === 0) {
    //                         if (val.cc_group_id === 0 && val.cc_group_name === "New Group") {
    //                             if(val.is_associated == 0) {
    //                                 return {...val, is_associated: 1}
    //                             } else {
    //                                 return { ...val, is_associated: 0 }
    //                             }
                                
    //                         }
    //                         else {
    //                             return { ...val }
    //                         }
    //                     } else {
    //                         console.log('elseselectgroup',selectedGroup,val.cc_group_id, val.is_associated)
    //                         if (val.cc_group_id === selectedGroup) {
    //                             // return { ...val, is_associated: 0 }
    //                             if(val.is_associated == 0) {
    //                                 return {...val, is_associated: 1}
    //                             } else if(val.is_associated == 1) {
    //                                 return {...val, is_associated: 0}
    //                             }
    //                         } else {
    //                             return { ...val }
    //                         }
    //                     }
    //                 })
    //                 studentValue = { ...studentValue, associated_group: associatStudents }
    //             }
    //             return studentValue
    //         })
    //         console.log(studentData)
    //         if (!addedStudents.includes(id)) {
               
    //             setAddedStudents([...addedStudents, id]);
    //         }
    //         let newData = [...groupList];
    //         let data = newData.find((item) => item.cc_group_id === selectedGroup);
    //         let addedStudent = [...new Set(data.student_list)];
    //         console.log(addedStudent)
    //         if (!addedStudent.includes(id)) {
               
    //             // setAddedStudents([...addedStudents, id]);
    //             data.student_list = [...addedStudent, id]
    //         } else {
    //             let newArray = data.student_list.filter(e => e !== id)
    //             console.log(newArray)
    //             data.student_list = newArray
    //         }
    //         // data.student_list = [...addedStudent, id]
    //         console.log('newData',newData)
    //         console.log('upperrebelowmore',removeStudentArr)
    //         // const selectedData = groupList.map((item) => {
    //         //     if(item.cc_group_id === selectedGroup) {
    //         //    return {
    //         //     ...item,
    //         //     student_list: [...item.student_list, id]
    //         //   }
              
    //         // }});
    //         //   console.log('selectedData',selectedData)
    //         // let groupData = groupList.map(val => {
    //         //     if (val.cc_group_id === selectedGroup) {
    //         //         var newArray = val.student_list.push(id)
    //         //         console.log(newArray)
    //         //         // return { ...val, student_list: newArray }
    //         //     } 
    //         //     // else {
    //         //     //     return { ...val }
    //         //     // }
    //         // })
    //         // console.log(groupData)
    //         console.log('groupdata',newData)
    //         setGroupList(newData);
    //         console.log(newStudentsList)
    //         console.log('finalremoval',removeStudentArr)
    //         setRemoveStudentArr([...removeStudentArr, id])
    //         if(selectedGroup > 0 & showtick) {
    //             setNewStudentsList(newStudentsList);
    //         } else {
    //         setNewStudentsList(studentData);
    //         }
    //         // setNewStudentsList(studentData);

    //         if (addedStudents.includes(id)) {
    //             var newAddedStudents = addedStudents.filter(e => e !== id)
    //             setAddedStudents(newAddedStudents);
    //             console.log('if',newAddedStudents)
    //         }
    //         console.log('else',newAddedStudents)
    //     }
    //     console.log('outer',newAddedStudents)


    // }

    // const editAddStudents = (id) => {
    //     if (!addedStudents.includes(id)) {
    //         for (let student of newStudentsList) {
    //             if (student.cc_student_id === id) {
    //                 student.associated_group.map(val => {
    //                     if (val.cc_group_id === selectedGroup) {
    //                         val.is_associated = 1;
    //                     }
    //                 });
    //             }
    //         }
    //         setAddedStudents([...addedStudents, id]);
    //         let newData = [...groupList];
    //         let data = newData.find((item) => item.cc_group_id === selectedGroup);
    //         data.student_list = [...data.student_list, id];
    //         setGroupList(newData);
    //         setNewStudentsList(newStudentsList);
    //     } else {
    //         for (let student of newStudentsList) {
    //             if (student.cc_student_id === id) {
    //                 student.associated_group.map(val => {
    //                     if (val.cc_group_id === selectedGroup) {
    //                         val.is_associated = 0;
    //                     }
    //                 });
    //             }
    //         }
    //         setNewStudentsList(newStudentsList);
    //         setAddedStudents(addedStudents.filter(e => e !== id));
    //     }
    // }
 
    const editAddStudents = (id) => {
    
        // if (!addedStudents.includes(id)) {
        let student = newStudentsList.find(s => s.cc_student_id === id);
        if (!student) return;
        let group = student.associated_group.find(g => g.cc_group_id === selectedGroup);
        if (!group) return;
        group.is_associated = group.is_associated === 1 ? 0 : 1;
        // setArray([...array]);
        
        setNewStudentsList([...newStudentsList])
        let newData = [...groupList];
        
        let data = newData.find((item) => item.cc_group_id === selectedGroup);
        let addedStudent = [...new Set(data.student_list)];
       
    if (!addedStudent.includes(id)) {
             if(selectedGroup > 0) {
               
             }   
        setAddedStudents([...addedStudents, id]);
        setRemoveStudentArr(removeStudentArr.filter(e => e !== id));
        data.student_list = [...addedStudent, id]
    } else {
        // var newAddedStudents = addedStudents.filter(e => e !== id)
        // console.log('newAddedStudents',newAddedStudents)
        //         setAddedStudents(newAddedStudents);
        setAddedStudents(addedStudents.filter(e => e !== id));
        // setRemoveStudentArr([...removeStudentArr, id])
              let newArray = data.student_list.filter(e => e !== id)
        
        data.student_list = newArray
        setRemoveStudentArr([...removeStudentArr, id])

    }
    // let newData = [...groupList];
    // let data = newData.find((item) => item.cc_group_id === selectedGroup);
    // let addedStudent = [...new Set(data.student_list)];
    // console.log(689, addedStudent)
    // console.log(690, data)
    // console.log(691, newData)
    // if (!addedStudent.includes(id)) {

    //     // setAddedStudents([...addedStudents, id]);
    //     data.student_list = [...addedStudent, id]
    //     console.log(696, data)
    // } 
    // else {
    //     let newArray = data.student_list.filter(e => e !== id)
    //     console.log(newArray)
    //     data.student_list = newArray
    //     setRemoveStudentArr([...removeStudentArr, id])
    // }
    
    setGroupList(newData);
    forceUpdate()
// } else {
//     console.log('upperelse')
// }
    }
    
    

    const onChangeName = (name) => {
        setGroupName(name)
        let groupNewList = groupList.map(val => {
            if (val.cc_group_id === selectedGroup) {
                return { ...val, cc_group_name: name }
            } else {
                return { ...val }
            }
        })
        return (setGroupList(groupNewList))
    }

    const isAllowExpand = (item) => {
        if (newStudentsList && newStudentsList.length > 0 && item.cc_group_id !== 0 && item.cc_group_name !== "New Group") {
            let newStudents = newStudentsList && newStudentsList.map(val => {
                let stdList = { ...val }
                var newArray = val.associated_group.filter(e => e.cc_group_id !== 0)
                stdList = { ...stdList, associated_group: newArray }
                return stdList
            })
            var newGroups = groupList.filter(e => e.cc_group_id !== 0);
            setGroupList(newGroups);
            setNewStudentsList(newStudents);
            setEnabledCheck(false)
        }
    }

    const addNewGroupInList = (value) => {
        if(isGroupName.trim().length <= 0) {
            createNotification('error', 'Please enter valid group name');
        } else {
        if(!/^[a-zA-Z ]*$/.test(isGroupName)) {
            createNotification('error', 'Please enter valid group name');
        } else {
        setEnabledCheck(false)
        setCancelled(true);
        setEditGroup(false);
        setAddedStudents([]);
        setShowAddNewGroup(false);
        setAddNewGroupBtnShow(false);
        setShowActionBtnOnSave(false);
        let studentArr = [];
        newStudentsList.map(val => {
            val.associated_group.map(value => {
                if (value.cc_group_id === 0 && value.cc_group_name === "New Group") {
                    if (value.is_associated === 1) {
                        studentArr.push(val.cc_student_id);
                    }
                }
                return studentArr
            })
            return studentArr
        })
        
        if (getClassId) {
            let apiData = {
                "group_class_id": selectedClassId > 0 ? selectedClassId : getClassId,
                // "group_class_id": getClassId,
                "student_ids": studentArr,
                "group_name": isGroupName ? isGroupName.trim() : "New Group",
                "list_type": "mutiple_student"
            }
            createGroupRequest(apiData);
            }
        }
    }
    }
    const onOutside = () => {
        setNewStudentsList(groupsData[0][0].student_list);
        // setRemoveStudentArr([])
    }

    useEffect(() => {
     
        if(groupsData?.[0]?.[0]?.student_list.length == 0) {
            setDisablrGroup(true) 
            setTotalStudentList(0)
            
        } else {
            setDisablrGroup(false)
            setTotalStudentList(groupsData?.[0]?.[0]?.student_list.length)
        }
    },[groupsData])

    // ---------BackTO-------
    const BackToDash = () => {
        props.history.replace("/home/academic")
        dispatch(setGroupNotesState())
        // this.props.history.goBack();
    }
    // ---------BackTO-------
    // ---------BackTO-------
    // const BackTOHome = () => {
    //     // this.props.history.replace("/home/academic")
    //     if (this.props.history.location.state.BackPage === "Academic Year") {
    //         this.props.history.replace("/home/academic")
    //     } else {
    //         this.props.history.goBack();
    //     }
    // }
    // ---------BackTO-------

    // ---------Select Class On Change--------
    // selectOnChange(e) {
    //     this.setState({
    //         isSubmit: false,
    //         page_no: 1,
    //         class_id: event.target.value
    //     }, () => {
    //         this.getRosterNotes()
    //     })

    // }
    // ---------Select Class On Change--------


    // const addnewGroup = () => {
    //     setCount(count)
    //     setShowAddNewGroup(true);
    //     enableCheck();
    //     setNameEdit(true);
    //     setEnabledEdit(true);
    //     setCancelled(false);
    //     setGroupName("");
    //     getSelectedGroup(count);
    //     setShowtick(true);
    //     handleAddNewStudent(0, count)
    //     setExpandPanel(0);
    //     newStudentlist.map(val => {
    //         return (
    //             val.associated_group.push({ cc_group_id: count, cc_group_name: "New Group", is_associated: 1 })
    //         )
    //     })
    //     groupLists.unshift({ "cc_group_name": "New Group", "cc_group_id": count, "student_list": students })
    //     setGroupLists(groupLists);
    //     setNewStudentsList(newStudentlist)
    // }

    const searchInput = useRef();
        useEffect(() => {
            if (classListData) {
                
                let newData = classListData?.map((item) => ({
                    ...item,
                    name : item.name,
                    value: item.value
                }))
                
                setAllClasses(newData)
                const classDataList = classListData
                const newClassDataList = classDataList?.map((obj, i) => (
                    {
                        ...obj,
                        name: classDataList[i].cc_class_name,
                        value: classDataList[i].cc_class_id
                    }

                ));
                const newArr = newClassDataList?.map((name, i) => ({ ['name']: newClassDataList[i].cc_class_name, ['value']: newClassDataList[i].cc_class_id }));
                setNewArr(newArr)
            }
        }, [classListData])

    const options = [
        {
            type: "group",
            name: "",
            items: classListData !== undefined ? classListData : newArr
        }
    ];

    const handleFilter = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return options;
            }
            const updatedItems = items.map((list) => {
                const newItems = list.items.filter((item) => {
                    return item.name.toLowerCase().includes(searchValue.toLowerCase());
                });
                return { ...list, items: newItems };
            });
            return updatedItems;
        };
    };

    const handleChange = (...args) => {
        
        // searchInput.current.querySelector("input").value = "";
        setIsSelected(false)
        setSelectedClassId(args[0])
        sessionStorage.setItem('notesclassId',args[0])
        sessionStorage.setItem('noteClassName',args[1].name)
        sessionStorage.setItem('classIDForNotes',args[0])
        setSelectedClassName(args[1].name)
        setChangeClassData([args?.[1]?.details])
        if (args.length > 0) {
            getGroupList(args[0])
        }

    };


    const getGroupList = (value) => {
        const apiData = {
            "pageNo": pageNo,
            "class_id": value,
            changeClass : true
        }
        getGroupDetailsRequest(apiData);

    }


    const hideModal = ['', open && !enabledCheck ? 'CNAG-student-popover' : 'hideModal']
    const pointer = ['img-div', enabledCheck ? 'pointer' : '']
    const rosterProfileImageText = ['CNAG-rosterProfileImageText', enabledCheck ? 'pointer' : '']

    const childRef = useRef();

    const handleAddNewGroup = () => {

        setAddNewGroupBtnShow(true)
        childRef.current.addnewGroup();
        setCustomProps({ ...customProps, expanded: true });
    };

    const handleCancel = () => {

        setAddNewGroupBtnShow(false)
        childRef.current.onCancelAddNewGroup()
    }

    return (
        <div className='ClassNoteAddGroupSCSS'>
            <div className='CNAG-classNoteAddGroupkMainContainer'>
                <Row>
                    <Col md="12" lg="12" className="CNAG-main-section">
                        <Card className="header">
                            <Card.Body>
                                <Row>
                                    <Col xs="12" md="4" className="CNAG-header-title m-auto">
                                        {/* <div className="CNAG-border-right">
                                            <Button className="back-btn" variant="secondary" onClick={() => { history.goBack() }}>
                                                <i className="fa fa-chevron-left" aria-hidden="true"></i>Class Notes {academicYear}
                                            </Button>
                                        </div> */}
                                        {/* {classDetails && <span className="year">{classDetails.cc_class_name} Group</span>} */}
                                        <div className='CNAG-border-right'>
                                            <img className='calIcon' src={back} alt='' width='60' height='40' onClick={() => { addNewGroupBtnShow || groupEdit? setGoBackModal(true) : 
                                                        BackToDash() }} />
                                            <div className='CNAG-DetailTextDiv'>
                                                <div className={`CNAG-DisplayFlex ${props.history.location.state.BackPage == "ClassDB" && 'w-100'}`}>
                                                    <p className='CNAG-currentYear' onClick={() => { 
                                                        addNewGroupBtnShow || groupEdit ? setGoBackModal(true) : 
                                                        BackToDash()
                                                        
                                                        }}><b>{academicYear}</b></p>
                                                    <i className="classForIcon material-icons">chevron_right</i>
                                                    {
                                                        props.history.location.state.BackPage == "ClassDB" && <>
                                                        <p className='stdNoteGrade1' onClick={() => { props.history.goBack() }} style={{cursor : 'pointer'}}>Class</p> 
                                                     <i className="classForIcon material-icons">chevron_right</i> 
                                                        </>
                                                    }
                                                    {/* <p className='stdNoteGrade1' onClick={() => { BackTOHome() }}>Roster</p> */}
                                                    {/* <i className="classForIcon material-icons">chevron_right</i> */}
                                                    <p className='CNAG-stdNoteGrade2'>Group Note </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs="12" md="4" className="m-auto CNAG-searchBar">
                                        <div className="CNAG-dropdownMainContainer">
                                            <div className="iconDiv"><i className="fa fa-search CNAG-searchFiltersIcon"></i></div>
                                           
                                            <SelectSearch
                                                ref={searchInput}
                                                options={allClassses}
                                                // filterOptions={handleFilter}
                                                value={(sessionStorage.getItem('noteClassName') && sessionStorage.getItem('noteClassName').length > 0) ? {name : sessionStorage.getItem('noteClassName'), value: 0} :  (isSelected ? { name: selectedClassName2 ? selectedClassName2 : classDetails?.details?.cc_class_name, value: 0 } : { name: selectedClassName ? selectedClassName : classDetails?.details?.cc_class_name, value: 0 })}
                                                name="Workshop"
                                                placeholder="Search class*"
                                                search
                                                onChange={handleChange}
                                                disabled={addNewGroupBtnShow === true || props?.location?.state?.backpage == "singlecls"}
                                                filterOptions={fuzzySearch}
                                                open={true}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs="12" md="4" className="m-auto">
                                        {addNewGroupBtnShow ?
                                            <>
                                                <Button onClick={() => {
                                                addNewGroupInList();
                                                    // setShowActionBtnOnSave(false)
                                                }
                                            } variant="default" className="CNAG-saveCloseBtn d-flex flex-wrap justify-content-center align-items-center"
                                                style={{ background : "#2ec428", color: '#fff', width: 100, height: 40, borderRadius : 8}}
                                                disabled={isGroupName.length == 0 ? true : false}
                                                >
                                                    {/* <img src={saveIcon} alt='' /> */}
                                                    <img src={tick} alt='' />&nbsp; Save
                                                </Button>
                                                <Button onClick={() => handleCancel()} variant="default" className="CNAG-saveCloseBtn d-flex flex-wrap justify-content-center align-items-center"
                                                style={{background : "#ea3b3b", color: '#fff', width: 100, height: 40, borderRadius: 8 }}
                                                >
                                                    {/* <img src={cancelIcon} alt='' /> */}
                                                    <img src={closeIcon} alt='' /> Cancel
                                                </Button>
                                            </>
                                        :
                                            <Button onClick={() => handleAddNewGroup()}
                                                variant="primary" className={`CNAG-add-note ${(isAddGroupBtnDisable || disableGroup || changeClassData?.[0]?.cc_class_share_permission =='read' || classDetails?.details?.cc_class_share_permission == 'read') && 'CNAG-add-note-disable'}`} disabled={isAddGroupBtnDisable || disableGroup || changeClassData?.[0]?.cc_class_share_permission =='read' || classDetails?.details?.cc_class_share_permission == 'read'}
                                                style={{opacity : isAddGroupBtnDisable || disableGroup || changeClassData?.[0]?.cc_class_share_permission =='read' || classDetails?.details?.cc_class_share_permission == 'read' && "0.65"}}
                                                >
                                                <img src={addNoteIcon} alt='' className='CNAG-add-note-img' />
                                                Group
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <div className="CNAG-student-section">
                            <div className={`CNAG-changedCard ${totalStudentList == 0 && !loader && 'CNAG-changedCardFontCenter'}`}>
                                {
                                    totalStudentList == 0 && !loader ? <>
                                    <div>
<p className='noneStudents'>  Please add students to the class to use Group Notes module.</p>
</div>
</> : 
                                
                                <Row>
                                    <Col xs="12" className="CNAG-slider">
                                        <div className="CNAG-left">
                                            {/* All Students<br></br> */}
                                            <div className="CNAG-suggestion">— Tap on the student to assign to multiple groups</div>
                                        </div>
                                        {/* <ScrollContainer vertical = {false} className="scroll-container"> */}


                                        <div className="CNAG-right">
                                            
                                                {newStudentsList && newStudentsList.length > 0 && newStudentsList.map((profiles, index) => {
                                              
                                                const studentProfile = ['CNAG-student-profile', !enabledCheck && profiles.associated_group_count === 0 ? 'mt-0' : 'mt-0']
                                                return (
                                                    
                                                    <OverlayTrigger
                                                        trigger="click"
                                                        rootClose
                                                        key={index}
                                                        placement="right-start"
                                                        className="CNAG-OverlayTrigger"
                                                        overlay={
                                                            <Popover  id={`popover-positioned-bottom`}
                                                                className={hideModal.join(' ')}
                                                            // style={{ borderRadius: "20px", backgroundColor: "white", width: "200px" }}
                                                            >
                                                                {!showAddNewGroup && <OutsideClickHandler
                                                                    onOutsideClick={() => {
                                                                        onOutside()
                                                                    }}
                                                                >
                                                                    {<Popover.Title as="div" className='CNAG-popover-header'
                                                                    // style={{ borderRadius: "20px 20px 0px 0px", backgroundColor: "white" }}
                                                                    >
                                                                        {<Row className="header-line">
                                                                            <span className="student-popup-text">Select Group</span>
                                                                            <Button variant="default" className="student-popup-action-btn pull-left"
                                                                                onClick={() => { saveModalData(profiles) }}>Done</Button>
                                                                            {/* <Button variant="default" className="student-popup-action-btn pull-right"
                                                                                onClick={() => { closeModal(false) }}>Cancel</Button> */}
                                                                        </Row>}
                                                                    </Popover.Title>}
                                                                    {<Popover.Content>
                                                                        {profiles.associated_group.map((val, k) => {
                                                                            return (
                                                                                <span className='CNAG-checkBoxDiv' key={k}>
                                                                                    <Col className='CNAG-popoverList'>
                                                                                        <Row>
                                                                                            <span className='CNAG-checkBoxDiv' >
                                                                                                {(val.is_associated) === 1 ?
                                                                                                    // <i className="checkBoxfilters material-icons" onClick={() => { removeMultipleModal(val.cc_group_id, profiles.cc_student_id) }}>{"radio_button_checked"}</i>

                                                                                                    <CheckCircleRoundedIcon
                                                                                                        color='primary'
                                                                                                        fontSize='small'
                                                                                                        onClick={() => { removeMultipleModal(val.cc_group_id, profiles.cc_student_id) }} />

                                                                                                    // <span className='notickMarkImg' onClick={() => { removeMultipleModal(val.cc_group_id, profiles.cc_student_id) }}>
                                                                                                    //     <img src={tickIcon} className="tickMarkImg" alt="" />
                                                                                                    // </span>
                                                                                                    :
                                                                                                    <RadioButtonUncheckedRoundedIcon
                                                                                                        fontSize='small'
                                                                                                        onClick={() => { addMultipleModal(val.cc_group_id, profiles.cc_student_id) }} />
                                                                                                    // <i className="checkBoxfilters material-icons" onClick={() => { addMultipleModal(val.cc_group_id, profiles.cc_student_id) }}>{"radio_button_unchecked"}</i>
                                                                                                    // <span className='notickMarkImg' onClick={() => { addMultipleModal(val.cc_group_id, profiles.cc_student_id) }}>
                                                                                                    //     <img src={tickIcon} className="notickMarkImg" alt="" />
                                                                                                    // </span>
                                                                                                }
                                                                                            </span>
                                                                                            <span className='CNAG-popoverListText'>
                                                                                                {val.cc_group_name}
                                                                                            </span>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </span>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Popover.Content>}
                                                                </OutsideClickHandler>}
                                                            </Popover>
                                                        }
                                                    >
                                                        <div className={studentProfile.join(' ')}  onClick={() => { 
                                                            editGroup ? editAddStudents(profiles.cc_student_id) : getData(profiles.cc_student_id) }}>
                                                            {/* {!enabledCheck && profiles.associated_group_count > 0 ?
                                                                (<img src={Group} className="groupMarkImg" alt="" />
                                                                ) : ""} */}
                                                                {/* <i className='fa fa-circle-o'></i> */}
                                                            {enabledCheck && showtick &&
                                                                profiles.associated_group.map((val, j) => {
                                                                    if (val.cc_group_id === selectedGroup) {
                                                                        if (val.is_associated === 1) {
                                                                            return (
                                                                                <img  src={tickIcon} key={j} className="CNAG-tickMarkImg " alt="" />
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                // <img  key={j} className="CNAG-notickMarkImg pointer" alt="" />
                                                                                <div style={{width: 14, height:14, borderRadius: '50%', position: 'absolute', border: 'solid 1px #000',  transform: 'translate(56px, 3px)', opacity: '0.4', cursor : 'pointer' ,    zIndex: 5, left: "-56px"}}></div>
                                                                                // <i key={j} class="fa fa-circle-o radcircle pointer" aria-hidden="true"></i>

                                                                            )
                                                                        }
                                                                    }
                                                                })}
                                                            {profiles.cc_student_pic_url ?
                                                                <div className={pointer.join(' ')} key={index}>
                                                                    {profiles?.associated_group_count > 0 && !addNewGroupBtnShow && !editGroup  && <img style={{position: "absolute",  left: "35px", top: "-1px" , width : "22px" , height : "22px"}} width={17} height={17} src= {GrpIcon} alt="" />}
                                                                    <Card.Img variant="top" alt='' src={profiles.cc_student_pic_url} />
                                                                    {/* <i className='fa fa-circle-o'></i> */}
                                                                </div>
                                                                : <> <div id='abc' className={pointer.join(' ')} key={index}>
                                                                  {/* <i style={{position: "absolute",  left: "0px", top: "3px"}} className='fa fa-circle-o'></i> */}
                                                                  {profiles?.associated_group_count > 0 && !addNewGroupBtnShow && !editGroup  && <img style={{position: "absolute",  left: "35px", top: "-1px" , width : "22px" , height : "22px"}} width={17} height={17} src= {GrpIcon} alt="" />}
                                                                    <h5 className={rosterProfileImageText.join(' ')}>{profiles.cc_student_first_name.charAt(0)}{profiles.cc_student_last_name.charAt(0)}</h5>
                                                                </div> </>}
                                                               
                                                            {/* <label>{profiles.cc_student_last_name} {profiles.cc_student_first_name}</label> */}
                                                        </div>
                                                    </OverlayTrigger>
                                                )
                                            })}
                                               
                                        </div>
                                        {/* </ScrollContainer> */}
                                    </Col>
                                </Row>
}
                            </div>
                        </div>
                    </Col >
                </Row >

     {/* --------------Confirmation Modal------------ */}
            {/* <Modal centered className="delete-modal-warning br-8" show={confirmationForBack} >
                <Modal.Header>
                    <Modal.Title>Info </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>*Are you sure you want to {confirmationType === 'back' ? " go back " : " change note "} without saving? </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="cancel-btn" onClick={confirmationToBack}>
                        Yes
                    </Button>
                    <Button variant="default" className="delete-btn" onClick={() => {
                        setConfirmationForBack(false);
                    }} >
                        No
                    </Button>
                </Modal.Footer>
            </Modal> */}

<Modal centered className="delete-modal-warning br-8" show={goBackModal} >
                    <Modal.Header>
                        <Modal.Title>
                            <div style={{display : 'flex', flexDirection: 'column'}}>
                                <img src={warningIcon} alt="" style={{ width: "60px", height: "60px"}} />
                                <span style={{fontSize: '24px', color: '#ff1f1f', marginTop: '10px'}}>Wait!</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Do you want to go back your data will be lost?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className="cancel-btn-div cancel-btn-div-note" onClick={() => {   setGoBackModal(false); setGroupEdit(false) }}
                        style={{marginRight : 18}}
                        >
                            Cancel
                        </Button>
                        <Button variant="default" className="goback-btn-div goback-btn-div-note" onClick={() => { 
                            BackToDash()
        
        }}>
                            Go back
                        </Button>
                    </Modal.Footer>
                </Modal>

{/* remove student confirmation modal*/}

<Modal centered className="delete-modal-warning br-8" show={removeStudentConfirmationModal} >
                    <Modal.Header>
                        <Modal.Title>
                            <div style={{display : 'flex', flexDirection: 'column'}}>
                                <img src={warningIcon} alt="" style={{ width: "60px", height: "60px"}} />
                                <span style={{fontSize: '24px', color: '#ff1f1f', marginTop: '10px'}}>Wait!</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{`If ${removedStudentsName} leaves this ${removeStudentGroupName} group, all of his group notes will become personal notes. Please confirm.?`}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className="cancel-btn-div cancel-btn-div-note" onClick={() => {   SetremoveStudentConfirmationModal(false); setGroupEdit(false) }}
                        style={{marginRight : 18}}
                        >
                            Cancel
                        </Button>
                        <Button variant="default" className="goback-btn-div goback-btn-div-note" onClick={() => { 
                            addNewStudentInGroupRequest(payloadForAPI)
                            SetremoveStudentConfirmationModal(false)
                            setGroupEdit(false)
        
        }}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>


                {loader ? <div> < DivLoader /> </div > : ""}
                <ClassNoteAddGroupPanel ref={childRef} enabledCheck={enabledCheck} classDetails={classDetails} academicYear={academicYear} history={history} newStudentlist={newStudentsList} setNewStudentsList={setNewStudentsList} groupLists={groupList} cancelled={cancelled} setAddedStudents={setAddedStudents}
                    deleteGroupRequest={deleteGroupRequest} setCancelled={setCancelled} disableCheck={disableCheck} showtick={showtick} enableCheck={enableCheck} getSelectedGroup={getSelectedGroup} setGroupName={setGroupName} setShowAddNewGroup={setShowAddNewGroup} isAllowExpand={isAllowExpand}
                    handleRemoveStudent={handleRemoveStudent} setEditGroup={setEditGroup} isGroupName={isGroupName} onChangeName={onChangeName} setShowtick={setShowtick} showAddNewGroup={showAddNewGroup} addNewGroupInList={addNewGroupInList} setEnabledCheck={setEnabledCheck} enabledGroupEditActionButton={enabledGroupEditActionButton} disabledGroupEditActionButton={disabledGroupEditActionButton}
                    setRemoveStudentArr={setRemoveStudentArr} addNewGroupBtnShow={addNewGroupBtnShow} showActionBtnOnSave={showActionBtnOnSave}  setGoBackModal={setGoBackModal} 
                    editGroupHandler={editGroupHandler} groupEdit={groupEdit} setCustomProps={setCustomProps} customProps={customProps} changeClassData={changeClassData} loader = {loader}  
                    disableAddGroupBtnFunction={disableAddGroupBtnFunction} academicYearId={props?.history?.location?.state?.academicYearId}            
               />
                {showPopUp && <UpgradePopUp handleClosePopUp={handleClosePopUp} />}
            </div >
        </div >
    )
}

// export default ClassNoteAddGroup;

const mapStateToProps = state => ({
    groupsData: state.addGroup.groupsData,
    // state: state,
    // classData: state,
    // loader: state.class.loader
});
const mapDispatchToProps = dispatch => {
    return {
        getGroupDetailsRequest: (data) => dispatch(getGroupDetailsRequest(data)),
        // getClassList: (data) => dispatch(getClassList(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(
    ClassNoteAddGroup
);