import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getSchoolTeacher: ['data'],
    getSchoolTeacherSuccess: ['data'],
    getSchoolTeacherFailure: ['error'],

    shareStudent: ['data'],
    shareStudentSuccess: ['data'],
    shareStudentFailure: ['error'],

    shareClass: ['data'],
    shareClassSuccess: ['data'],
    shareClassFailure: ['error'],
    upgradePopUpOpen: ['data']

})
export const UserClassShareReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    class_share: null,
    share_Student: null,
    share_Class: null,
    upgrade_PopUp_Open: null,
    error: null,
    exists: null,
    loader: null,
    speedTagloader: null,
    action: null
})

/* ------------- Reducers ------------- */
/* getNotSelectedRosters */
export const getSchoolTeacher = (state) => {
    return {
        ...state, fetching: true, error: null, class_share: null, loader: true
    }
}
export const getSchoolTeacherSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, class_share: data, action: action.type, exists: true, loader: false }
}
export const getSchoolTeacherFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_SCHOOL_TEACHER_FAILURE', loader: false }
}

/* share Student */

export const shareStudent = (state) => {
    return {
        ...state, fetching: true, error: null, share_Student: null, loader: true
    }
}
export const shareStudentSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, share_Student: data.response_data, action: action.type, exists: true, loader: false }
}
export const shareStudentFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'SHARE_STUDENT_FAILURE', loader: false }
}

/* share Class */

export const shareClass = (state) => {
    return {
        ...state, fetching: true, error: null, share_Class: null, loader: true
    }
}
export const shareClassSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, share_Class: data.response_data, action: action.type, exists: true, loader: false }
}
export const shareClassFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'SHARE_CLASS_FAILURE', loader: false }
}

export const upgradePopUpOpen = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, upgrade_PopUp_Open: data, action: action.type, exists: true, Notesloader: false }
}



/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.GET_SCHOOL_TEACHER]: getSchoolTeacher,
    [Types.GET_SCHOOL_TEACHER_SUCCESS]: getSchoolTeacherSuccess,
    [Types.GET_SCHOOL_TEACHER_FAILURE]: getSchoolTeacherFailure,

    [Types.SHARE_STUDENT]: shareStudent,
    [Types.SHARE_STUDENT_SUCCESS]: shareStudentSuccess,
    [Types.SHARE_STUDENT_FAILURE]: shareStudentFailure,


    [Types.SHARE_CLASS]: shareClass,
    [Types.SHARE_CLASS_SUCCESS]: shareClassSuccess,
    [Types.SHARE_CLASS_FAILURE]: shareClassFailure,

    [Types.UPGRADE_POP_UP_OPEN]: upgradePopUpOpen
})
