import {
    GET_CORE_STANDARD_LIST_REQUEST,
    GET_CORE_STANDARD_LIST_SUCCESS,
    GET_CORE_STANDARD_LIST_FAILURE,
    IMPORT_CUSTOM_STANDARDS,
    IMPORT_CUSTOM_STANDARDS_ERROR,
    IMPORT_CUSTOM_STANDARDS_SUCCESS,
    DELETE_CUSTOM_STANDARDS,
    DELETE_CUSTOM_STANDARDS_ERROR,
    DELETE_CUSTOM_STANDARDS_SUCCESS,
    EDIT_CUSTOM_STANDARDS,
    EDIT_CUSTOM_STANDARDS_ERROR,
    EDIT_CUSTOM_STANDARDS_SUCCESS,
  } from "../constants";
  
  // -----------Get Core Standard List-------------
  export function getCoreStandardListRequest(data) {
    return {
      type: GET_CORE_STANDARD_LIST_REQUEST,
      data,
    };
  }
  
  export function getCoreStandardListSuccess(data) {
    return {
      type: GET_CORE_STANDARD_LIST_SUCCESS,
      data,
    };
  }
  
  export function getCoreStandardListFailure(data) {
    return {
      type: GET_CORE_STANDARD_LIST_FAILURE,
      data,
    };
  }
  
  export function importCustomStandards(data) {
    return {
      type: IMPORT_CUSTOM_STANDARDS,
      data,
    };
  }
  export function importCustomStandardsError(data) {
    return {
      type: IMPORT_CUSTOM_STANDARDS_ERROR,
      data,
    };
  }
  
  export function importCustomStandardsSuccess(data) {
    return {
      type: IMPORT_CUSTOM_STANDARDS_SUCCESS,
      data,
    };
  }
  
  export function deleteCustomStandards(data) {
    return {
      type: DELETE_CUSTOM_STANDARDS,
      data,
    };
  }
  export function deleteCustomStandardsError(data) {
    return {
      type: DELETE_CUSTOM_STANDARDS_ERROR,
      data,
    };
  }
  
  export function deleteCustomStandardsSuccess(data) {
    return {
      type: DELETE_CUSTOM_STANDARDS_SUCCESS,
      data,
    };
  }
  
  
  export function editCustomStandards(data) {
    return {
      type: EDIT_CUSTOM_STANDARDS,
      data,
    };
  }
  
  export function editCustomStandardsError(data) {
    return {
      type: EDIT_CUSTOM_STANDARDS_ERROR,
      data,
    };
  }
  
  export function editCustomStandardsSuccess(data) {
    return {
      type: EDIT_CUSTOM_STANDARDS_SUCCESS,
      data,
    };
  }
  