import React from 'react';
import Card from 'react-bootstrap/Card';
import './AboutDashboard.scss'


const AboutDashboard = (props) => {
    return (
        <div className='aboutContainer'>
            <Card className='cardOfAbout'>
                <p className='aboutHeading'>About</p>
                <p className='descriptionText'>A pair of married classroom teachers (working a few doors down the hall from each other) ventured into the ed-tech market with the release of Chronicle 1.0, a localized web app. Well-received, Chronicle earned positive reviews from ed-tech blogs and websites.</p>
                <p className='descriptionText'>Thousands of downloads later came the creation of Chronicle Cloud, an web app harnessing the power of cloud-based technology. Like its predecessor, Chronicle Cloud receives praise for its ability to enhance teaching methodologies. This includes Chronicle Cloud’s 2020 recognition as a <a href="https://www.techlearning.com/features/15-sites-for-differentiated-instruction?utm_source=Selligent&utm_medium=email&utm_campaign=14038&utm_content=Tech%26Learning+2%2F19%2F20+&utm_term=1123530&m_i=Ji6fqJKW9_7DW9yDp2PTdlr6BVwZOH4ouymD8zGMGzS9bzw8P_Rih9UCXsUIuQ8tlGjj44noEbwqcMYEzc5rR4ujWMKBGeJJJR&M_BT=631333428031" target='_blank' rel="noopener noreferrer"> top app/site to support differentiated instruction.</a> </p>
                <p className='descriptionText'>The rising of Chronicle Cloud could not have happened without <a href="https://www.thesynapses.com" target='_blank' rel="noopener noreferrer">Synapse LLC</a>, an established mobile and web application company composed of highly-skilled and experienced professionals successfully delivering technical solutions to companies all over the world.</p>
                <p className='descriptionText'>Synapse’s expertise provides the design, security, and infrastructure integrity required by an application as rich and versatile as Chronicle Cloud. It is in the collaborative and innovative efforts of qualified professionals — educators, graphic designers, developers, and software engineers — that Chronicle Cloud propels itself forward as a next-generation application for teachers. </p>
                <p className='descriptionText'>The Chronicle Cloud team constantly uses feedback by teachers (like you!) to breathe life into the application. We are wholly dedicated to the mission of fostering positive change. Every day. One extraordinary teacher at a time.</p>
            </Card>
        </div>
    )
}

export default AboutDashboard