import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getClassAttList: ['data'],
    getClassAttListSuccess: ['data'],
    getClassAttListFailure: ['error'],

    addClassAtt: ['data'],
    addClassAttSuccess: ['data'],
    addClassAttFailure: ['error'],

    getClassAttByDateRange: ['data'],
    getClassAttByDateRangeSuccess: ['data'],
    getClassAttByDateRangeFailure: ['error'],

    sendEmailReport: ['data'],
    sendEmailReportSuccess: ['data'],
    sendEmailReportFailure: ['error'],
})

export const UserClassAttReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    Get_Att_List: null,
    Add_Att_List: null,
    Get_Att_List_By_Date: null,
    Send_Email: null,
    error: null,
    exists: null,
    loader: null,
    speedTagloader: null,
    action: null
})

/* ------------- Reducers ------------- */

/* getNotSelectedRosters */
export const getClassAttList = (state) => {
    return {
        ...state,
        fetching: true,
        error: null,
        Get_Att_List: null,
        loader: true
    }
}

export const getClassAttListSuccess = (state, action) => {

    const { data } = action
    return {
        ...state,
        fetching: false,
        error: null,
        Get_Att_List: data.data.student_details,
        action: action.type, exists: true,
        loader: false
    }
}

export const getClassAttListFailure = (state, { error }) => {
    return {
        ...state,
        fetching: false,
        error,
        exists: false,
        action: 'GET_CLASS_ATT_LIST_FAILURE',
        loader: false
    }
}

/* deleteClassTag */

export const addClassAtt = (state) => {
    return {
        ...state,
        fetching: true,
        error: null,
        Add_Att_List: null,
        loader: true
    }
}

export const addClassAttSuccess = (state, action) => {
    const { data } = action
    return {
        ...state,
        fetching: false,
        error: null,
        Add_Att_List: action.data,
        action: action.type,
        exists: true,
        loader: false
    }
}

export const addClassAttFailure = (state, { error }) => {
    return {
        ...state,
        fetching: false,
        error,
        exists: false,
        action: 'ADD_CLASS_ATT_FAILURE',
        loader: false
    }
}



/* deleteClassTag */

export const getClassAttByDateRange = (state) => {
    return {
        ...state,
        fetching: true,
        error: null,
        Get_Att_List_By_Date: null,
        loader: true
    }
}

export const getClassAttByDateRangeSuccess = (state, action) => {
    const { data } = action
    return {
        ...state,
        fetching: false,
        error: null,
        Get_Att_List_By_Date: data.data.student_list,
        action: action.type,
        exists: true,
        loader: false
    }
}

export const getClassAttByDateRangeFailure = (state, { error }) => {
    return {
        ...state,
        fetching: false,
        error,
        exists: false,
        action: 'GET_CLASS_ATT_BY_DATE_RANGE_FAILURE',
        loader: false
    }
}




export const sendEmailReport = (state) => {
    return {
        ...state,
        fetching: true,
        error: null,
        Send_Email: null,
        loader: true
    }
}

export const sendEmailReportSuccess = (state, action) => {
    const { data } = action
    return {
        ...state,
        fetching: false,
        error: null,
        Send_Email: data.response_data,
        action: action.type,
        exists: true,
        loader: false
    }
}

export const sendEmailReportFailure = (state, { error }) => {
    return {
        ...state,
        fetching: false,
        error,
        exists: false,
        action: 'SEND_EMAIL_REPORT_FAILURE',
        loader: false
    }
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.GET_CLASS_ATT_LIST]: getClassAttList,
    [Types.GET_CLASS_ATT_LIST_SUCCESS]: getClassAttListSuccess,
    [Types.GET_CLASS_ATT_LIST_FAILURE]: getClassAttListFailure,

    [Types.ADD_CLASS_ATT]: addClassAtt,
    [Types.ADD_CLASS_ATT_SUCCESS]: addClassAttSuccess,
    [Types.ADD_CLASS_ATT_FAILURE]: addClassAttFailure,

    [Types.GET_CLASS_ATT_BY_DATE_RANGE]: getClassAttByDateRange,
    [Types.GET_CLASS_ATT_BY_DATE_RANGE_SUCCESS]: getClassAttByDateRangeSuccess,
    [Types.GET_CLASS_ATT_BY_DATE_RANGE_FAILURE]: getClassAttByDateRangeFailure,

    [Types.SEND_EMAIL_REPORT]: sendEmailReport,
    [Types.SEND_EMAIL_REPORT_SUCCESS]: sendEmailReportSuccess,
    [Types.SEND_EMAIL_REPORT_FAILURE]: sendEmailReportFailure,

})
