import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import plusIcon from "Assets/NoteModule/plus.svg";
import CardHeaderGroup from "Assets/NoteModule/CardHeaderGroup.svg";
import flashIcon from "Assets/NoteModule/flash.svg";
import webLinkIcon from "Assets/NoteModule/webLink.png";
import { createNotification } from 'Config/notificationtoast';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import { getSpeedNote } from 'Actions/ClassAction/classTagAction';
import { PopoverHeader, PopoverBody, UncontrolledPopover } from "reactstrap";
import Form from 'react-bootstrap/Form';
import { chroniclecloud_code, link_Url } from 'Config/Constant';
import "./ClassNoteCommentCard.scss";
import { getNoteSpiralTag } from "Actions/ClassAction/ClassStandardsAction";

const ClassNoteCommentCard = (props) => {
    const {
        CommentData,
        type,
        allData, width,
        speedTagloader,
        setCommentData,
        classTag,
        getSpeedNote,
        getNoteSpiralTag,
        coreStandardNotes,
        quality_points
     } = props;
     
    const [AllSpeedNoteData, setAllSpeedNoteData] = useState([]);
    const [SpeedNoteData, setSpeedNoteData] = useState([]);
    const [searchText, setSearchText] = useState('');
    let CommentFinalData = [];

    useEffect(() => {
        if (speedTagloader === true) {
            setCommentData(JSON.parse(sessionStorage.getItem('CommentData')));
        }
    }, [speedTagloader])

    useEffect(() => {
        if (CommentData) {
            for (let item of CommentData) {
                if (item.tag_comment_text.length > 0) {
                    CommentFinalData.push(item)
                }
            }
        }
    }, [])

    useEffect(() => {
        

        if (classTag && classTag.action === "GET_SPEED_NOTE_SUCCESS") {
            if (classTag && classTag.Get_Speed_Note) {
                let SpeedNote = [];
                let data = classTag.Get_Speed_Note;
                Object.keys(data).forEach(function (key) {
                    for (let item of data[key]) {
                        SpeedNote.push(item)
                    }
                });
                setAllSpeedNoteData(SpeedNote);
                setSpeedNoteData(SpeedNote);
            }
        }
    }, [classTag])

    // ---------Add New Tag-------
    const AddNewTag = (index) => {
        const { CommentData, pageType } = props;
        let CommentDataEdit = CommentData;
        let data = []
        if (CommentDataEdit[index].tag_comment_text === '') {
            createNotification('error', ' Please Fill ' + CommentDataEdit[index].cc_tag_title);
            return;
        }
        if (pageType === 'Edit') {
            data = {
                cc_tag_abbre: CommentDataEdit[index].cc_tag_abbre,
                cc_tag_title: CommentDataEdit[index].cc_tag_title,
                tag_comment_add_on: new Date(),
                tag_comment_id: "",
                tag_comment_note_id: CommentDataEdit[index].tag_comment_note_id,
                tag_comment_student_id: CommentDataEdit[index].tag_comment_student_id,
                tag_comment_text: "",
                tag_id: '',
            }
        } else {
            data = {
                cc_tag_abbre: CommentDataEdit[index].cc_tag_abbre,
                cc_tag_add_on: new Date(),
                cc_tag_checked: "no",
                cc_tag_class_id: '',
                cc_tag_id: '',
                cc_tag_modifyed_on: new Date(),
                tag_comment_text: '',
                cc_tag_title: CommentDataEdit[index].cc_tag_title
            }
        }
        let mainIndex = index + 1
        CommentDataEdit.splice(mainIndex, 0, data);
        setCommentData(CommentDataEdit)
    }
    // ---------Add New Tag-------

    // ---------Input Handle change-------
    const OnChangeTextArea = (event, index) => {
        const { CommentData } = props;
        let CommentDataEdit = CommentData
        CommentDataEdit[index].tag_comment_text = event.target.value;
        setCommentData(CommentDataEdit)
    }
    // ---------Input Handle change-------

    // ---------addSpeedNote-------
    const addSpeedNote = (value, index) => {
        const { CommentData } = props;
        let CommentDataEdit = CommentData
        CommentDataEdit[index].tag_comment_text = value;
        CommentDataEdit[index].editOpen = !CommentDataEdit[index].editOpen;
        setCommentData(CommentDataEdit)
    }
    // ---------addSpeedNote-------

    // ---------addCoreStandard-------
    const addCoreStandard = (value, index) => {
        const { CommentData } = props;
        let CommentDataEdit = CommentData
        CommentDataEdit[index].tag_comment_text = value;
        CommentDataEdit[index].editOpen2 = !CommentDataEdit[index].editOpen2;
        setCommentData(CommentDataEdit)
    }
    // ---------addCoreStandard-------

    // ---------Submit assignment--------
    const openNewTab = (item) => {
        const { studentInfo, StudentAllNoteDetails } = props;
        if (StudentAllNoteDetails.student_details.storage_flag === 0) {
            createNotification('error', StudentAllNoteDetails.student_details.storage_message);
            return;
        }
        let data = JSON.parse(sessionStorage.getItem('UserData'))
        const BODY = JSON.stringify({
            "chroniclecloud_code": chroniclecloud_code,
            "user_id": data.cc_user_id,
            "note_id": item.cc_note_id,
            "cmd": "get_student_note",
            "submit": "submit",
            "device_udid": sessionStorage.getItem('UDID'),
            "class_id": item.cc_note_class_id,
            "student_id": studentInfo.cc_student_id
        })
        let url = link_Url + btoa(BODY)
        window.open(url)
        // window.open("https://dev-teachers.chroniclecloud.com/?token=eyJjaHJvbmljbGVjbG91ZF9jb2RlIjoiWTJoeWIyNXBZMnhsZFhObGNueFFZWE56ZDI5eVpDTXhNRFU9IiwidXNlcl9pZCI6IjE1NTQiLCJub3RlX2lkIjoiMTUwMDMiLCJjbWQiOiJnZXRfc3R1ZGVudF9ub3RlIiwic3VibWl0Ijoic3VibWl0IiwiZGV2aWNlX3VkaWQiOiJBNUQxNkY0Qy05NDM3LTQ2RTktQUE2My00QUVGNzNBMEJDMzEiLCJjbGFzc19pZCI6IjI5OTciLCJzdHVkZW50X2lkIjoiMTg5NzEifQ%3D%3D")
    }
    // ---------Submit assignment--------

    // ---------Make Speed Tag  -------
    const selectSpeedTag = (item, index) => {
        const { CommentData, class_id, pageType } = props;
        let data = [];
        setSpeedNoteData([]);
        setAllSpeedNoteData([]);
        if (pageType === 'Edit') {
            if (item.tag_id === '') {
                createNotification('error', 'Please save the new Tag first.');
                return;
            } else {
                data = {
                    "customize_tag_id": item.tag_id,
                    "class_id": class_id,
                    "filter_text": ""
                }
            }
        } else {
            if (item.cc_tag_id === '') {
                createNotification('error', 'Please save the new Tag first.');
                return;
            }
            else {
                data = {
                    "customize_tag_id": item.cc_tag_id,
                    "class_id": class_id,
                    "filter_text": ""
                }
            }
        }
        let CommentDataEdit = CommentData;
        CommentDataEdit[index].editOpen = !CommentDataEdit[index].editOpen;
        window.sessionStorage.setItem("CommentData", JSON.stringify(CommentDataEdit))
        if (CommentDataEdit[index].editOpen) {
            getSpeedNote(data)
        }
    }
    // ---------Make Speed Tag  -------

    // ---------Select Core Standard  -------

    const selectCS = (item, index) => {
        const { CommentData, class_id, pageType } = props;
        let data = [];
        setSpeedNoteData([]);
        setAllSpeedNoteData([]);
        if (pageType === 'Edit') {
            if (item.tag_id === '') {
                createNotification('error', 'Please save the new Tag first.');
                return;
            } else {
                data = {
                    "customize_tag_id": item.tag_id,
                    "class_id": class_id,
                    "filter_text": ""
                }
            }
        } else {
            if (item.cc_tag_id === '') {
                createNotification('error', 'Please save the new Tag first.');
                return;
            }
            else {
                data = {
                    "customize_tag_id": item.cc_tag_id,
                    "class_id": class_id,
                    "filter_text": ""
                }
            }
        }
        let CommentDataEdit = CommentData;
        CommentDataEdit[index].editOpen2 = !CommentDataEdit[index].editOpen2;
        window.sessionStorage.setItem("CommentData", JSON.stringify(CommentDataEdit))
        if (CommentDataEdit[index].editOpen2) {
            getNoteSpiralTag(data)
        }
    }
        const selectCoreStandard = (item, index) => {
            const { CommentData, class_id, pageType } = props;
            let data = [];
            setSpeedNoteData([]);
            setAllSpeedNoteData([]);
            if (pageType === 'Edit') {
                if (item.tag_id === '') {
                    createNotification('error', 'Please save the new Tag first.');
                    return;
                } else {
                    data = {
                        "customize_tag_id": item.tag_id,
                        "class_id": class_id,
                        "filter_text": ""
                    }
                }
            } else {
                if (item.cc_tag_id === '') {
                    createNotification('error', 'Please save the new Tag first.');
                    return;
                }
                else {
                    data = {
                        "customize_tag_id": item.cc_tag_id,
                        "class_id": class_id,
                        "filter_text": ""
                    }
                }
            }
            let CommentDataEdit = CommentData;
            CommentDataEdit[index].editOpen2 = !CommentDataEdit[index].editOpen2;
            window.sessionStorage.setItem("CommentData", JSON.stringify(CommentDataEdit))
            if (CommentDataEdit[index].editOpen2) {
                getSpeedNote(data)
            }
        }
    // ---------Select Core Standard  -------

    // ---------Local Auto search  -------
    const handleSearchText = (event) => {
        setSearchText(event.target.value);
        let tempArray = [];
        for (let item of AllSpeedNoteData) {
            let text = item.cc_speed_note_text.toLowerCase();
            let search = event.target.value.toLowerCase()
            if (text.includes(search) === true) {
                tempArray.push(item)
            }
        };
        setSpeedNoteData(tempArray);
    }
    // ---------Local Auto search  -------

    return (
        <>
            {type === 'view' ?
                <Row className="timeline-section-div">
                    {/* ------Show comment card Section----- */}
                    {CommentFinalData && CommentFinalData.length > 0 && CommentFinalData.map((item, index) => (

                        <Col xs="12" key={index} md={width} className="adv-note-div">
                            <Card className="adv-note">
                                <Card.Header>
                                    <span className="header-text">
                                        {type === 'Edit' ? <img src={flashIcon} alt="" width="18" height="18" /> : ""}{" "}
                                        {item.cc_tag_title?.replace(/(.{25})..+/, "$1…")}

                                    </span>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {item.tag_comment_text}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                    {/* ------Show Assignment card Section----- */}
                    <Col  style={allData.cc_work_assignment == "" ? {display : "none"} : {display : "block", marginRight : "20px" , marginLeft : "10px"} } xs="12" md={width} className="adv-note-ass-div">
                        <Card  className="adv-note-ass">
                            <Card.Header>
                                <span className="header-text">
                                    {type === 'Edit' ? <img src={flashIcon} alt="" width="18" height="18" /> : ""}{" "}
                                    Assignment
                                </span>

                                {allData.cc_to_show_submission_option === 0 ? "" : <Button onClick={() => { openNewTab(allData) }}>
                                    <img src={webLinkIcon} alt="" width="16" height="16" /><span className='submit-text'>Submitted Work</span>
                                </Button>}
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    {allData.cc_work_assignment}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* ------Show Assignment card Section----- */}
                    {/* ------Show comment card Section----- */}
                    <Row className="otherCommentBoxes">
                        {/* ------Edit comment card Section----- */}
                        {CommentData && CommentData.length > 0 && CommentData.map((item, index) => (
                         <Col style={item.tag_comment_text == "" ? {display : "none"} : {display : "contents", marginRight : "20px" , marginLeft : "10px"} } key={index} xs="12" md={width} className="adv-note-div">
                         <Card style={{width :"265px" , marginLeft : "10px"}} className="adv-note">
                                    <Card.Header>
                                        {/* ------Title of card----- */}
                                        <span className="header-text">
                                            <img style={{ cursor: 'pointer', marginRight: "5px" }} id={`mypopover${index}`} src={flashIcon} alt="" width="18" height="18"
                                            />

                                            {item.cc_tag_title?.replace(/(.{25})..+/, "$1…")}
                                        </span>
                                    </Card.Header>
                                    <Card.Body className='padding0'>
                                        {/* -----comment card for edit---- */}
                                        <Card.Text>
                                            <Form.Control
                                                name='tag_comment_text'
                                                className='editTextArea'
                                                as="textarea"
                                                rows="7"
                                                value={item.tag_comment_text}

                                            />
                                        </Card.Text>
                                        {/* -----comment card for edit---- */}
                                    </Card.Body>
                                </Card>
                            </Col>))}
                        {/* ------Edit comment card Section----- */}
                    </Row>
                </Row>
                :
                <Row>
                    {/* ------Edit comment card Section----- */}
                    {CommentData && CommentData.length > 0 && CommentData.map((item, index) => (
                        
                        <Col key={index} xs="12" md={width} className="adv-note-div">
                            <Card className="adv-note">
                                <Card.Header>
                                    {/* ------Title of card----- */}
                                    <span className="header-text">
                                        <img style={{ cursor: 'pointer', marginRight: "5px" }} id={`mypopover${index}`} src={flashIcon} alt="" 
                                        width="18" height="18" 
                                        onClick={() => { selectSpeedTag(item, index) }}
                                        />
                                        {item.cc_tag_title?.replace(/(.{25})..+/, "$1…")}
                                    </span>
                                    {/* ------Title of card----- */}

                                    {/* ------popover for add Speed Tag----- */}
                                    <UncontrolledPopover trigger="legacy"
                                        placement="right"
                                        isOpen={item.editOpen}
                                        target={`mypopover${index}`}
                                        toggle={() => selectSpeedTag(item, index)}
                                        style={{ borderRadius: 20 }}
                                        className='popoverTag'
                                    >
                                        <PopoverHeader>
                                            Speed Notes
                                        </PopoverHeader>
                                        <PopoverBody>
                                            <div className="searchTagdiv">
                                                <div className="input-group-prepend border-0">
                                                    <button id="button-addon4" type="button" className="btn btn-link text-info searchIconDiv"><i className="fa fa-search searchIcon"></i></button>
                                                </div>
                                                <input
                                                    margin="normal"
                                                    type='text'
                                                    value={searchText}
                                                    onChange={handleSearchText}
                                                    name="searchText"
                                                    className='searchTagInput'
                                                    placeholder="Search Speed Note Here"
                                                ></input>
                                            </div>
                                            {SpeedNoteData && SpeedNoteData.length > 0 ?
                                                <div className='tagDiv'>
                                                    {SpeedNoteData.map((item, i) => (
                                                        <div key={i} className='selectTagDiv' onClick={() => { addSpeedNote(item.cc_speed_note_text, index) }}>
                                                            <p className='selectTagText'>{item.cc_speed_note_text}</p>
                                                        </div>))}
                                                </div>
                                                :
                                                <div>
                                                    {speedTagloader ?
                                                        <div>
                                                            <img src={loaderImag} alt='' className='loaderIconCss' />
                                                        </div>
                                                        :
                                                        <div className='selectTagDiv'>
                                                            <p className='selectTagText'>No Data Found</p>
                                                        </div>}
                                                </div>}
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                    {/* ------popover for add Speed Tag----- */}

                                    {/* -----Add new comment card ---- */}
                                    <Button onClick={() => { AddNewTag(index) }} style={{ marginLeft: "8px" }}>
                                        <img src={plusIcon} alt="" width="18" height="18" />
                                    </Button>
                                    <Button >
                                        <img src={CardHeaderGroup} alt="" style={{width:"18", height:"18"}} onClick={() => { selectCS(item, index) }} id={`mypopover2${index}`} />
                                    </Button>
                                    {/* -----Add new comment card ---- */}
                                    {/* ------popover for core standard----- */}
                                    <UncontrolledPopover trigger="legacy"
                                    placement="right"
                                    isOpen={item.editOpen2}
                                    target={`mypopover2${index}`}
                                    toggle={() => selectCS(item, index)}
                                    style={{ borderRadius: 20 }}
                                    className='popoverTag'
                                >
                                    <PopoverHeader>
                                        Core Standard
                                    </PopoverHeader>
                                    <PopoverBody>
                                        {/* <div className="searchTagdiv">
                                            <div className="input-group-prepend border-0">
                                                <button id="button-addon4" type="button" className="btn btn-link text-info searchIconDiv"><i className="fa fa-search searchIcon"></i></button>
                                            </div>
                                            <input
                                                margin="normal"
                                                type='text'
                                                value={searchText}
                                                onChange={handleSearchText}
                                                name="searchText"
                                                className='searchTagInput'
                                                placeholder="Search Speed Note Here"
                                            ></input>
                                        </div> */}
                                        {coreStandardNotes && coreStandardNotes.length > 0 ?
                                            <div className='tagDiv'>
                                                {coreStandardNotes.map((item, i) => (
                                                    
                                                    <div key={i} className='selectTagDiv' onClick={() => { addCoreStandard(item.arr[0].description, index) }}>
                                                        <p className='selectTagText'>{item.arr[0].description}</p>
                                                    </div>))
                                                    
                                                    }
                                            </div>
                                            :
                                            <div>
                                                {speedTagloader ?
                                                    <div>
                                                        <img src={loaderImag} alt='' className='loaderIconCss' />
                                                    </div>
                                                    :
                                                    <div className='selectTagDiv'>
                                                        <p className='selectTagText'>No Data Found</p>
                                                    </div>}
                                            </div>}
                                    </PopoverBody>
                                    </UncontrolledPopover>
                                    {/* ------popover for core standard----- */}
                                </Card.Header>
                                <Card.Body className='padding0'>
                                    {/* -----comment card for edit---- */}
                                    <Card.Text>
                                        <Form.Control
                                            name='tag_comment_text'
                                            className='editTextArea'
                                            as="textarea"
                                            rows="7"
                                            value={item.tag_comment_text}
                                            onChange={(e) => { OnChangeTextArea(e, index) }}
                                            // style={{fontSize: 17}}
                                        />
                                    </Card.Text>
                                    {/* -----comment card for edit---- */}
                                </Card.Body>
                            </Card>
                        </Col>))}
                    {/* ------Edit comment card Section----- */}
                </Row>}
        </>
    );
}

const mapStateToProps = (state) => ({
    state: state,
    classTag: state.classTag,
    speedTagloader: state.classTag.speedTagloader,
});
const mapDispatchToProps = (dispatch) => {
    return {
        getSpeedNote: (data) => dispatch(getSpeedNote(data)),
        getNoteSpiralTag : (data) => dispatch(getNoteSpiralTag(data))
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClassNoteCommentCard);