import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setPaymentDataRequest: ['data'],
    setPaymentDataSuccess: ['data'],
    setPaymentDataFailure: ['error'],

    addUserSubscriptionRequest: ['data'],
    addUserSubscriptionSuccess: ['data'],
    addUserSubscriptionFailure: ['error'],

    addPaymentStatusSuccess: ['data'],
})



export const userPayPalPaymentReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    DataUsage: [],
    paymentStatus: false,
    error: null,
    exists: null,
    loader: null,
    action: null
})

/* ------------- Reducers ------------- */

/* Get Payment Request */
export const setPaymentDataRequest = (state) => {
    return { ...state, fetching: true, error: null, loader: true, paymentStatus: false, }
}
export const setPaymentDataSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, loader: false, DataUsage: data, paymentStatus: true }
}
export const setPaymentDataFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'SET_PAYMENT_DATA_FAILURE', loader: false, paymentStatus: false }
}


/* add User Subscription Request */
export const addUserSubscriptionRequest = (state) => {
    return { ...state, fetching: true, error: null, loader: true }
}
export const addUserSubscriptionSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, loader: false, DataUsage: data }
}
export const addUserSubscriptionFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'ADD_USER_SUBSCRIPTION_FAILURE', loader: false }
}


export const addPaymentStatusSuccess = (state, action) => {
    return { ...state, paymentStatus: false }
}



/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.SET_PAYMENT_DATA_REQUEST]: setPaymentDataRequest,
    [Types.SET_PAYMENT_DATA_SUCCESS]: setPaymentDataSuccess,
    [Types.SET_PAYMENT_DATA_FAILURE]: setPaymentDataFailure,


    [Types.ADD_USER_SUBSCRIPTION_REQUEST]: addUserSubscriptionRequest,
    [Types.ADD_USER_SUBSCRIPTION_SUCCESS]: addUserSubscriptionSuccess,
    [Types.ADD_USER_SUBSCRIPTION_FAILURE]: addUserSubscriptionFailure,

    [Types.ADD_PAYMENT_STATUS_SUCCESS]: addPaymentStatusSuccess,




})
