import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import './Format.scss'

const Format = (props) => {
    const { defaultData, getUserSetting, onChangeformat, onChangeSeparators, onChangeClock } = props;
    const [format, setFormat] = useState('DD,MM,YYYY')
    const [separators, setSeparators] = useState('Slash (/)')
    const [clock, setClock] = useState('12 Hours')

    useEffect(() => {
        if (getUserSetting && defaultData.cc_user_seperator) {
            setFormat(defaultData.cc_user_date_format);
            setSeparators(defaultData.cc_user_seperator);
            setClock(defaultData.cc_user_clock_format);
        }
    }, [defaultData])
    return (
        <Card.Body>
            {/* ------- Format  ------- */}
            <div className='mainDivForFormat'>
                <p className='cardDateFormatTitle'>Formats</p>
                <div>
                <Col xs="12" md="12" lg="12" className="sort-by-col-CND ">
                                    <div className='Button-div-2-CND '>
                                        <button type="button" className={format === "DD/MM/YYYY" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => onChangeformat('DD/MM/YYYY')}>
                                        DD/MM/YYYY</button>
                                    
                                        <button type="button" className={format === "MM/DD/YYYY" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => onChangeformat('MM/DD/YYYY')}>
                                        MM/DD/YYYY</button>
                                        <button type="button" className={format === "YYYY,MM,DD" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => onChangeformat('YYYY,MM,DD')} >
                                        YYYY,MM,DD</button>
                                        <button type="button" className={format === "MMM DD/ YYYY" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"}onClick={() => onChangeformat('MMM DD/ YYYY')}>
                                        MMM DD/ YYYY</button>
                                       
                                    </div>
                                </Col>
                    {/* <div className='dateFormatList'>
                        <i className={`${format === 'DD/MM/YYYY' ? 'checkBoxfilter' : 'unCheckBoxfilter'} material-icons`} onClick={() => onChangeformat('DD/MM/YYYY')}>
                            {format === 'DD/MM/YYYY' ? 'check_circle' : 'radio_button_unchecked'}
                        </i>
                        <p className='dateFormatListText'>DD,MM,YYYY</p>
                    </div>
                    <div className='dateFormatList'>
                        <i className={`${format === 'MM/DD/YYYY' ? 'checkBoxfilter' : 'unCheckBoxfilter'} material-icons`} onClick={() => onChangeformat('MM/DD/YYYY')}>
                            {format === 'MM/DD/YYYY' ? 'check_circle' : 'radio_button_unchecked'}
                        </i>
                        <p className='dateFormatListText'>MM,DD,YYYY</p>
                    </div>
                    <div className='dateFormatList'>
                        <i className={`${format === 'YYYY/MM/DD' ? 'checkBoxfilter' : 'unCheckBoxfilter'} material-icons`} onClick={() => onChangeformat('YYYY/MM/DD')}>
                            {format === 'YYYY/MM/DD' ? 'check_circle' : 'radio_button_unchecked'}
                        </i>
                        <p className='dateFormatListText'>YYYY,MM,DD</p>
                    </div>
                    <div className='dateFormatList'>
                        <i className={`${format === 'MMM DD/ YYYY' ? 'checkBoxfilter' : 'unCheckBoxfilter'} material-icons`} onClick={() => onChangeformat('MMM DD/ YYYY')}>
                            {format === 'MMM DD/ YYYY' ? 'check_circle' : 'radio_button_unchecked'}
                        </i>
                        <p className='dateFormatListText'>MMM DD, YYYY</p>
                    </div> */}
                </div>
            </div>
            {/* ------- Format  ------- */}

            <br />

            {/* ------- Separators  ------- */}
            <div className='mainDivForFormat'>
                <p className='cardDateFormatTitle'>Separators</p>
                {/* <div >
                    <div className='dateFormatList'>
                        <i className={`${separators === 'Slash (/)' ? 'checkBoxfilter' : 'unCheckBoxfilter'} material-icons`} onClick={() => onChangeSeparators('Slash (/)')}>
                            {separators === 'Slash (/)' ? 'check_circle' : 'radio_button_unchecked'}
                        </i>
                        <p className='dateFormatListText'>Slash (/)</p>
                    </div>
                    <div className='dateFormatList'>
                        <i className={`${separators === 'Dot (.)' ? 'checkBoxfilter' : 'unCheckBoxfilter'} material-icons`} onClick={() => onChangeSeparators('Dot (.)')}>
                            {separators === 'Dot (.)' ? 'check_circle' : 'radio_button_unchecked'}
                        </i>
                        <p className='dateFormatListText'>Dot (.)</p>
                    </div>
                    <div className='dateFormatList'>
                        <i className={`${separators === 'Comma (,)' ? 'checkBoxfilter' : 'unCheckBoxfilter'} material-icons`} onClick={() => onChangeSeparators('Comma (,)')}>
                            {separators === 'Comma (,)' ? 'check_circle' : 'radio_button_unchecked'}
                        </i>
                        <p className='dateFormatListText'>Comma (,)</p>
                    </div>
                    <div className='dateFormatList'>
                        <i className={`${separators === 'Hyphens or Dashed (-)' ? 'checkBoxfilter' : 'unCheckBoxfilter'} material-icons`} onClick={() => onChangeSeparators('Hyphens or Dashed (-)')}>
                            {separators === 'Hyphens or Dashed (-)' ? 'check_circle' : 'radio_button_unchecked'}
                        </i>
                        <p className='dateFormatListText'>Hyphens or Dashed (-)</p>
                    </div>
                </div> */}
                <Col xs="12" md="12" lg="12" className="sort-by-col-CND ">
                                    <div className='Button-div-2-CND '>
                                        <button type="button" className={separators === "Slash (/)" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => onChangeSeparators('Slash (/)')}>
                                        Slash (/)</button>
                                    
                                        <button type="button" className={separators === "Dot (.)" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => onChangeSeparators('Dot (.)')}>
                                        Dot (.)</button>
                                        <button type="button" className={separators === "Comma (,)" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => onChangeSeparators('Comma (,)')} >
                                        Comma (,)</button>
                                        <button type="button" className={separators === "Hyphens or Dashed (-)" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => onChangeSeparators('Hyphens or Dashed (-)')}>
                                        Hyphens or Dashed (-)</button>
                                       
                                    </div>
                                </Col>
            </div>
            {/* ------- Separators  ------- */}

            <br />

            {/* ------- Clock  ------- */}
            <div className='mainDivForFormat'>
                <p className='cardDateFormatTitle'>Clock</p>
                {/* <div >
                    <div className='dateFormatList'>
                        <i className={`${clock === '12 Hours' ? 'checkBoxfilter' : 'unCheckBoxfilter'} material-icons`} onClick={() => onChangeClock('12 Hours')}>
                            {clock === '12 Hours' ? 'check_circle' : 'radio_button_unchecked'}
                        </i>
                        <p className='dateFormatListText'>12 Hours</p>
                    </div>
                    <div className='dateFormatList'>
                        <i className={`${clock === '24 Hours' ? 'checkBoxfilter' : 'unCheckBoxfilter'} material-icons`} onClick={() => onChangeClock('24 Hours')}>
                            {clock === '24 Hours' ? 'check_circle' : 'radio_button_unchecked'}
                        </i>
                        <p className='dateFormatListText'>24 Hours</p>
                    </div>

                </div> */}

                <Col xs="12" md="12" lg="12" className="sort-by-col-CND ">
                                    <div className='Button-div-2-CND '>
                                        <button type="button" className={clock === "12 Hours" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => onChangeClock('12 Hours')}>
                                        12 Hours</button>
                                    
                                        <button type="button" className={clock === "24 Hours" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => onChangeClock('24 Hours')}>
                                        24 Hours</button>
                                      
                                       
                                    </div>
                                </Col>
            </div>
            {/* ------- Clock  ------- */}

        </Card.Body>
    )
}

export default Format