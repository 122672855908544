import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import StudentGradeBookTabPanel from "../StudentGradeBookTabPanelComponent/StudentGradeBookTabPanel";
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import "./StudentGradeBookTabs.scss";

const StudentGradeBookTabs = (props) => {
  const {
    studentClassList,
    studentGradebookList,
    getGradebookByClass, classId, BackPage,
    loader,
    calculateAverage,
    history,
    selectCategory,
    selectedCategory,
    UpdateStudentGradebookInputRequest,
    UpdateStudentGradebookColorRequest,
    updateValueOfCategory } = props
  const [value, setValueCheck] = useState(0)

  /* ---------------onLoad------------ */
  useEffect(() => {
    if (BackPage === 'Roster') {
      getGradebookByClass(studentClassList[0].cc_class_id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* ---------------onLoad------------ */

  const handleChange = (event, newClass) => {
    setValueCheck(newClass)
    getGradebookByClass(newClass)

  };

  return (
    <div className={""}>
      <AppBar position="static" color="default">
        <Tabs
          value={classId}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {studentClassList.map((item, i) => {
            return (
              <Tab key={i} value={item.cc_class_id} label={item.cc_class_name} index={i} />
            )
          })}

        </Tabs>
      </AppBar>
      {loader && <div>
        <img src={loaderImag} alt='' className='loaderIconCss' />
      </div>}
      {studentGradebookList && studentGradebookList.length > 0 ?
        <StudentGradeBookTabPanel
          category={studentGradebookList}
          calculateAverage={calculateAverage}
          selectCategory={selectCategory}
          selectedCategory={selectedCategory}
          UpdateStudentGradebookInputRequest={UpdateStudentGradebookInputRequest}
          updateValueOfCategory={updateValueOfCategory}
          UpdateStudentGradebookColorRequest={UpdateStudentGradebookColorRequest}
          value={value}
          history={history}
          index={0} />
        :
        <div>
          {!loader && <div>
            <p className='noCat'>No Category</p>
          </div>}
        </div>}
      {/* ------ Loader Div----- */}

    </div>
  );
}
StudentGradeBookTabs.propTypes = {
  getGradebookByClass: PropTypes.func,
  calculateAverage: PropTypes.func,
  selectCategory: PropTypes.func,
  UpdateStudentGradebookInputRequest: PropTypes.func,
  updateValueOfCategory: PropTypes.func,
  UpdateStudentGradebookColorRequest: PropTypes.func,

  // selectedCategory: PropTypes.number,
  loader: PropTypes.bool,

}

export default StudentGradeBookTabs
