import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getBlogListRequest: ['data'],
    getBlogListSuccess: ['data'],
    getBlogListFailure: ['error'],

    loadMoreBlogListRequest: [''],

})

export const userBlogReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    TotalBlogs: [],
    blogs: [],
    error: null,
    exists: null,
    loader: null,
    action: null
})

/* ------------- Reducers ------------- */

/* Get Blog Request */
export const getBlogListRequest = (state) => {
    return { ...state, fetching: true, error: null, loader: true }
}
export const getBlogListSuccess = (state, action) => {
    const { data } = action
    let value = [];
    if (data.length > 10) {
        for (let i = 0; i < 10; i++) {
            value.push(data[i])
        }
    }
    else {
        value = data
    }
    return { ...state, fetching: false, error: null, loader: false, blogs: value, TotalBlogs: data }
}
export const getBlogListFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_BLOG_LIST_FAILURE', loader: false }
}

export const loadMoreBlogListRequest = (state, action) => {
    const { TotalBlogs, blogs } = state
    let value = blogs;
    let newLength = blogs.length + 10;
    if (TotalBlogs.length > newLength) {
        for (let i = blogs.length; i < newLength; i++) {
            value.push(TotalBlogs[i])
        }
    }
    else {
        value = TotalBlogs
    }

    return { ...state, fetching: false, error: null, loader: false, blogs: value, TotalBlogs: TotalBlogs }

}



/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.GET_BLOG_LIST_REQUEST]: getBlogListRequest,
    [Types.GET_BLOG_LIST_SUCCESS]: getBlogListSuccess,
    [Types.GET_BLOG_LIST_FAILURE]: getBlogListFailure,

    [Types.LOAD_MORE_BLOG_LIST_REQUEST]: loadMoreBlogListRequest,



})
