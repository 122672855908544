import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import DivLoader from 'Components/LoadingComponent/DivLoader';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif';
import crossMark from 'Assets/AttendanceModule/crossMark.svg';
import './ShareLessonPlanTecherList.scss';

const ShareLessonPlanTecherList = (props) => {
    const { getLessonPlanSchoolTeacherState,
        getLessonPlanSchoolTeacher,
        shareLessonPlan,
        loader,
        hideTeacherListComp,
        selectedTopicPlan,
        selectedSharePlanId,
        setShowTeacherListComp } = props;
    const [selectedTeacher, setSelectedTeacher] = useState('');
    const [teacherList, setTeacherList] = useState([]);
    const [allTeachersId, setAllTeachersId] = useState([]);
    const [allRadioCheck, setAllRadioCheck] = useState(false);
    const [teachersName, setAllTeachersName] = useState([]);
    const [loadMoreCountStatus, setLoadMoreCountStatus] = useState(false);
    const [loadMoreCount, setLoadMoreCount] = useState(1);
    const [classOverAllCount, setClassOverAllCount] = useState([]);
    const confirmSlectDiv = ["confirmSlectDiv", selectedTeacher.length < 1 ? "opacityFade" : ""]
    // let data = JSON.parse(sessionStorage.getItem('UserData'));
    const showPublishPopUp = JSON.parse(sessionStorage.getItem('showPublish'));
    const sharedTeachersNames = sessionStorage.getItem('sharedTeachers');
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);


    useEffect(() => {
        const apiData = {
            "page_no": loadMoreCount
        }
        getLessonPlanSchoolTeacher(apiData);
    }, [])

    useEffect(() => {
        
        if (getLessonPlanSchoolTeacherState && getLessonPlanSchoolTeacherState.data) {
            let allId = [];
            setTeacherList(getLessonPlanSchoolTeacherState.data.teacher_data);
            if (loadMoreCount === 1) {
                setTeacherList(getLessonPlanSchoolTeacherState.data.teacher_data);
                setClassOverAllCount(getLessonPlanSchoolTeacherState.data.teacher_count);
                setLoadMoreCountStatus(false)
            }
            if (loadMoreCount > 1) {
                getLessonPlanSchoolTeacherState.data.teacher_data.map(val => {
                    teacherList.push(val)
                })
                setTeacherList(teacherList);
                setClassOverAllCount(getLessonPlanSchoolTeacherState.data.teacher_count);
                setLoadMoreCountStatus(false)
            }
            teacherList.map(val => {
                allId.push(val.cc_user_id);
            })
            setAllTeachersId(allId);
            forceUpdate()
        }
    }, [getLessonPlanSchoolTeacherState])

    useEffect(() => {
        let allId = []
        
        if(teacherList && teacherList.length > 0) {
            teacherList.map(val => {
                allId.push(val.cc_user_id);
            })
            
        }
        setAllTeachersId(allId);
    },[teacherList])

    useEffect(() => {
        console.log('allIDS')
    },[allTeachersId])

    useEffect(() => {
        const intersection = selectedTeacher && selectedTeacher.filter(element => allTeachersId.includes(element));
        
        if (selectedTeacher && intersection.length === allTeachersId.length) {
            setAllRadioCheck(true);
        } else {
            setAllRadioCheck(false);
        }
    }, [selectedTeacher])

    useEffect(() => {
        let teachersNames = [];
        let teacherNameList = [...teacherList];
        teacherNameList.map(value => {
            selectedTeacher.map(val => {
                if (val === value.cc_user_id) {
                    teachersNames.push(value.cc_user_first_name);
                }
            })
        })
        setAllTeachersName(teachersNames);
    }, [selectedTeacher])

    useEffect(() => {
        if (teachersName.length > 0) {
            sessionStorage.setItem("sharedTeachers", teachersName);
        }
    }, [teachersName])

    //  ---------Load More List-------


    const loadMoreTeachers = () => {
        let value = loadMoreCount
        value++
        setLoadMoreCountStatus(true);
        setLoadMoreCount(value);
        const apiData = {
            "page_no": value
        }
        getLessonPlanSchoolTeacher(apiData);
    }
    // ---------Load More List-------
    const getTeachersData = (StudentId) => {
        if (selectedTeacher.includes(StudentId)) {
            const selectedIds = selectedTeacher.filter((id) => id !== StudentId);
            setSelectedTeacher(selectedIds)
        } else {
            setSelectedTeacher([...selectedTeacher, StudentId])
        }
    }

    const getAllTeachersData = () => {
        if (selectedTeacher === allTeachersId) {
            setSelectedTeacher([])
        } else {
            setSelectedTeacher(allTeachersId);
        }
    }

    const unSavetWhenClose = () => {
        setSelectedTeacher([]);
    }

    const handleCloseSharePop = () => {
        sessionStorage.setItem("showPublish", false);
        sessionStorage.setItem("sharedTeachers", "");
        setShowTeacherListComp(false);
    }

    const shareLessonPlanTeacher = () => {
        sessionStorage.setItem("showPublish", true);
        const apiData = {
            'lesson_plan_id': JSON.stringify(selectedSharePlanId),
            'sharing_type': "shared_plan",
            'shared_user_ids': selectedTeacher.join(",")
        }
        shareLessonPlan(apiData);
        setSelectedTeacher([]);
    }

    const backToLessonPlan = () => {
        setSelectedTeacher([]);
        setTeacherList([]);
        setAllTeachersId([]);
        setAllRadioCheck(false);
        hideTeacherListComp();
    }



    return (
        <div className='lpTeacherShareContainer'>
            {/* ------------Heading------------- */}
            <div className='shareLessonPlanMainDiv'>
                <div className='shareLessonPlanDetailsMain'>
                    <div className='ClassBackDiv'
                        onClick={backToLessonPlan}
                    >
                        <i className='lessonBackIcon material-icons'>chevron_left</i>
                        <p className='lessonClassBactText'>Lesson Plan</p>
                    </div>
                    <div className='lessonDetailTextDiv'>
                        <p className='lessonClassName'>{selectedTopicPlan}</p>
                    </div>
                </div>
            </div>
            {teacherList && <div className='mainHeading'>
                <div className='checkDiv'>
                    <span className='checBoxDiv' onClick={getAllTeachersData}>
                        {allRadioCheck === true ?
                            <i className="checkBoxfilter material-icons">{"radio_button_checked"}</i>
                            :
                            <i className="checkBoxfilter material-icons">{"radio_button_unchecked"}</i>
                        }
                    </span>
                    <p className='headingSchoolName'>Select All Teacher</p>
                </div>
                <div>
                    <p className='headingSelectTeacher'>— Tap and select teacher to share plan</p>
                </div>
                <div className='displayFlexInSeleTeacher'>
                    <div className='undoSelect'
                        onClick={unSavetWhenClose}
                    >
                        <img
                            src={crossMark}
                            className='closeIcon'
                            alt=''
                            width='20'
                            height='20'
                        />
                    </div>
                    <div className={confirmSlectDiv.join(' ')}
                        onClick={shareLessonPlanTeacher}
                    >
                        <i className='CrossAndConfirmIcon material-icons'>
                            check
                        </i>
                    </div>
                </div>
            </div>}
            {loader && <div>
                <DivLoader />
            </div>}
            {/* ------------Heading------------- */}
            {/* ------------Teacher Card------------- */}
            {teacherList && teacherList.map((item, index) => (
                <div key={index} className='teacherMainDiv'>
                    <div className='teacherProfileImageDiv'>
                        {item.cc_user_pic ? <img src={item.cc_student_pic_url} alt='' className='teacherShareProfileImage' /> :
                            <h5 className='teacherImageDummyText'>{item.cc_user_first_name.charAt(0)}{item.cc_user_last_name.charAt(0)}</h5>}
                    </div>
                    <div className='teacherShareTextInfoDiv' >
                        <p className='teacherShareName'>{item.cc_user_first_name}</p>
                        <p className='teacherShareEmail'>{item.cc_user_email_id}</p>
                    </div>
                    <div className='teacherShareCheckBoxDiv'>
                        <span className='checBoxDiv' onClick={() => { getTeachersData(item.cc_user_id) }}>
                            {selectedTeacher.includes(item.cc_user_id) === true ?
                                <i className="checkBoxfilter material-icons">{"radio_button_checked"}</i>
                                :
                                <i className="checkBoxfilter material-icons">{"radio_button_unchecked"}</i>
                            }
                        </span>
                    </div>
                </div>
            ))}
            {(teacherList.length > 0) &&
                <div style={{ padding: 5 }}>
                    {loadMoreCountStatus ? <Button className='loadMoreClass' color="primary" >
                        <img src={loaderImag} alt='' className='loaderIconLoadMoreClass' />
                    </Button>
                        :
                        <div>
                            {classOverAllCount && classOverAllCount > teacherList.length &&
                                <Button onClick={loadMoreTeachers} className='loadMoreClass' color="primary" >
                                    <i className="material-icons">autorenew</i>
                                    <span className='LoadClassButtonText' > Load More</span>
                                </Button>}
                        </div>}
                </div>}
            <Modal centered className="publishTeachers-modal-popUp br-8" show={showPublishPopUp} >
                <Modal.Body>
                    <div className='bothDivMain'>
                        <div className="imgTextDiv">
                            <p className='textDiv'>Your plan has been shared with</p>
                            <p className='teacherTextDiv'>{sharedTeachersNames}</p>
                            <Button className='okBtn' variant="danger" onClick={handleCloseSharePop}>
                                Okay !
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ShareLessonPlanTecherList;
