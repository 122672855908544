import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    addClassRoster: ['data'],
    addClassRosterSuccess: ['data'],
    addClassRosterFailure: ['error'],

    getClassRosterList: ['data'],
    getClassRosterListSuccess: ['data'],
    getClassRosterListFailure: ['error'],

    deleteClassRoster: ['data'],
    deleteClassRosterSuccess: ['data'],
    deleteClassRosterFailure: ['error'],

    updateRosterLevel: ['data'],
    updateRosterLevelSuccess: ['data'],
    updateRosterLevelFailure: ['error'],

    searchClassRoster: ['data'],
    searchClassRosterSuccess: ['data'],
    searchClassRosterFailure: ['error'],

    importClassRoster: ['data'],
    importClassRosterSuccess: ['data'],
    importClassRosterFailure: ['error'],

    setClassRosterState: ['data']

})

export const UserClassRosterReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    roster_Data: null,
    add_roster: null,
    delete_roster: null,
    search_class_roster: null,
    import_class_roster: null,
    update_roster_level: null,
    error: null,
    exists: null,
    loader: null,
    action: null
})

/* ------------- Reducers ------------- */

export const setClassRosterState = (state) => {
    return {
        ...state,
        roster_Data: null,
        add_roster: null,
        delete_roster: null,
        search_class_roster: null,
        import_class_roster: null,
        update_roster_level: null,
        error: null,
        exists: null,
        loader: null,
        action: null
    }
}    

/* addRoster */
export const addClassRoster = (state) => {
    return {
        ...state, fetching: true, error: null, add_roster: [], loader: true
    }
}
export const addClassRosterSuccess = (state, action) => {

    const { data } = action
  
    // let arr = [...add_roster]
    return { ...state, fetching: false, error: null, add_roster: data, action: action.type, exists: true, loader: false }
}
export const addClassRosterFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'ADD_CLASS_ROSTER_FAILURE', loader: false }
}


/* getAcademicList */
export const getClassRosterList = (state, action) => {
  
    return {
        ...state, fetching: true, error: null, roster_Data: null, loader: true
    }
}
export const getClassRosterListSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, roster_Data: data, action: action.type, exists: true, loader: false }
}
export const getClassRosterListFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_CLASS_ROSTER_LIST_FAILURE', loader: false }
}

/* deleteClassRoster */
export const deleteClassRoster = (state) => {
    return {
        ...state, fetching: true, error: null, delete_roster: null, loader: true
    }
}
export const deleteClassRosterSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, delete_roster: data, action: action.type, exists: true, loader: false }
}
export const deleteClassRosterFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'DELETE_CLASS_ROSTER_FAILURE', loader: false }
}

// /* updateClassRoster level */
export const updateRosterLevel = (state) => {
    return {
        ...state, fetching: true, error: null, update_roster_level: null, loader: true
    }
}
export const updateRosterLevelSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, update_roster_level: data, action: action.type, exists: true, loader: false }
}
export const updateRosterLevelFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'UPDATE_ROSTER_LEVEL_FAILURE', loader: false }
}

/* searchClassRoster */
export const searchClassRoster = (state) => {
    return {
        ...state, fetching: true, error: null, search_class_roster: null, loader: true
    }
}
export const searchClassRosterSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, search_class_roster: data, action: action.type, exists: true, loader: false }
}
export const searchClassRosterFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'SEARCH_CLASS_ROSTER_FAILURE', loader: false }
}


/* importClassRoster */
export const importClassRoster = (state) => {
    return {
        ...state, fetching: true, error: null, import_class_roster: null, loader: true
    }
}
export const importClassRosterSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, import_class_roster: data, action: action.type, exists: true, loader: false }
}
export const importClassRosterFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'IMPORT_CLASS_ROSTER_FAILURE', loader: false }
}






/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.ADD_CLASS_ROSTER]: addClassRoster,
    [Types.ADD_CLASS_ROSTER_SUCCESS]: addClassRosterSuccess,
    [Types.ADD_CLASS_ROSTER_FAILURE]: addClassRosterFailure,

    [Types.GET_CLASS_ROSTER_LIST]: getClassRosterList,
    [Types.GET_CLASS_ROSTER_LIST_SUCCESS]: getClassRosterListSuccess,
    [Types.GET_CLASS_ROSTER_LIST_FAILURE]: getClassRosterListFailure,

    [Types.DELETE_CLASS_ROSTER]: deleteClassRoster,
    [Types.DELETE_CLASS_ROSTER_SUCCESS]: deleteClassRosterSuccess,
    [Types.DELETE_CLASS_ROSTER_FAILURE]: deleteClassRosterFailure,

    [Types.UPDATE_ROSTER_LEVEL]: updateRosterLevel,
    [Types.UPDATE_ROSTER_LEVEL_SUCCESS]: updateRosterLevelSuccess,
    [Types.UPDATE_ROSTER_LEVEL_FAILURE]: updateRosterLevelFailure,

    [Types.SEARCH_CLASS_ROSTER]: searchClassRoster,
    [Types.SEARCH_CLASS_ROSTER_SUCCESS]: searchClassRosterSuccess,
    [Types.SEARCH_CLASS_ROSTER_FAILURE]: searchClassRosterFailure,


    [Types.IMPORT_CLASS_ROSTER]: importClassRoster,
    [Types.IMPORT_CLASS_ROSTER_SUCCESS]: importClassRosterSuccess,
    [Types.IMPORT_CLASS_ROSTER_FAILURE]: importClassRosterFailure,

    [Types.SET_CLASS_ROSTER_STATE]: setClassRosterState,


})
