import { call, takeLatest, put } from "redux-saga/effects";
import UserClassStandardReducer from "Redux/Reducers/ClassReducer/classStandardsReducer";
import { createNotification } from "Config/notificationtoast";
import {
  GET_CLASS_CORE_STANDARD,
  GET_CORE_STANDARD,
  ADD_CLASS_CORE_STANDARD,
  DELETE_CUSTOM_STANDARDS,
  EDIT_CUSTOM_STANDARDS,
} from "Actions/constants";
import {
  GetClassCoreStandard_API,
  GetCoreStandard_API,
  AddClassCoreStandard_API,
  deleteCustomStandard_API,
  editCustomStandard_API,
} from "Config/Api";
import { chroniclecloud_code } from "Config/Constant";
import { decryptData, encryptData, logOut } from "Utils/Helper";
import request from "Config/request";

export function* userClassCoreStandardModuleSaga() {
  yield takeLatest(GET_CLASS_CORE_STANDARD, getClassCoreStandard);
  yield takeLatest(GET_CORE_STANDARD, getCoreStandard);
  yield takeLatest(ADD_CLASS_CORE_STANDARD, addClassCoreStandard);
  yield takeLatest(DELETE_CUSTOM_STANDARDS, deleteCustomStandards);
  yield takeLatest(EDIT_CUSTOM_STANDARDS, editCustomStandards);
}

function* getClassCoreStandard(action) {
  let data = JSON.parse(sessionStorage.getItem("UserData"));
  const URL = `${GetClassCoreStandard_API}?user_id=${
    data.cc_user_id
  }&class_id=${
    sessionStorage.getItem("Class_ID_for_LP") &&
    sessionStorage.getItem("Class_ID_for_LP") > 0
      ? sessionStorage.getItem("Class_ID_for_LP")
      : action.data.cc_class_id
  }&platform=web`;
  const BODY = {
    chroniclecloud_code: chroniclecloud_code,
    user_id: data.cc_user_id,
    device_udid: sessionStorage.getItem("UDID"),
    class_id: action.data.cc_class_id,
  };
  let response;
  try {
    response = yield call(request, URL, {
      method: "GET",
      // body: { "data": encryptData(BODY) },
    });
    const MainDecode = decryptData(response.res);
    if (MainDecode.status == 200) {
      yield put(
        UserClassStandardReducer.getClassCoreStandardSuccess(MainDecode)
      );
    } else {
      if (
        MainDecode.message ===
        "Your session has been expired. Please login again"
      ) {
        logOut("error", MainDecode.message);
      }
      createNotification("error", MainDecode.message);
      yield put(
        UserClassStandardReducer.getClassCoreStandardFailure(MainDecode)
      );
    }
  } catch (error) {
    createNotification("warning", "Something went wrong");
    yield put(UserClassStandardReducer.getClassCoreStandardFailure(false));
  }
}

function* getCoreStandard(action) {
  let data = JSON.parse(sessionStorage.getItem("UserData"));
  const URL = `${GetCoreStandard_API}?user_id=${
    data.cc_user_id
  }&main_id=${action.data.main_id.toString()}&grade_id=${
    action.data.grade_id
  }&group_id=${action.data.group_id}&standard_id=${
    action.data.standard_id
  }&platform=web`;
  // const BODY = {
  //     "chroniclecloud_code": chroniclecloud_code,
  //     "user_id": data.cc_user_id,
  //     // "device_udid": sessionStorage.getItem('UDID'),
  //     "class_id": action.data.class_id,
  //     "date": action.data.date,
  //     "platform" : "web"
  // }
  let response;
  try {
    response = yield call(request, URL, {
      method: "GET",
      // body: { "data": encryptData(BODY) },
    });
    const MainDecode = decryptData(response.res);
    if (MainDecode.status == 200) {
      if (action?.data?.fromDashboard && action?.data?.main_id > 0) {
        let obj = {
          mainId: action?.data?.main_id,
          groupId: action?.data?.group_id,
          mainDecode: MainDecode,
          fromDashboard: true,
        };
        yield put(UserClassStandardReducer.getCoreStandardSuccess(obj));
      } else {
        yield put(UserClassStandardReducer.getCoreStandardSuccess(MainDecode));
      }
    } else {
      if (
        MainDecode.message ===
        "Your session has been expired. Please login again"
      ) {
        logOut("error", MainDecode.message);
      }
      createNotification("error", MainDecode.message);
      yield put(UserClassStandardReducer.getCoreStandardFailure(MainDecode));
    }
  } catch (error) {
    createNotification("warning", "Something went wrong");
    yield put(UserClassStandardReducer.getCoreStandardFailure(false));
  }
}

function* addClassCoreStandard(action) {
  let data = JSON.parse(sessionStorage.getItem("UserData"));
  const URL = AddClassCoreStandard_API;

  // const b2 = {
  //     "user_id": data.cc_user_id,
  //     "class_id": action.data.class_id,
  // "main_id": action.data.main_id.toString(),
  // "grade_id": action.data.grade_id,
  // "group_id": action.data.group_id,
  // "core_standard_details_array": (action.data.core_standard_details_array),
  // "platform" : "web"
  // }
  const BODY = {
    user_id: data.cc_user_id,
    class_id: action.data.class_id,
    main_id: action.data.main_id.toString(),
    grade_id: action.data.grade_id,
    group_id: action.data.group_id,
    core_standard_details_array: action.data.core_standard_details_array,
    platform: "web",
  };

  let response;
  try {
    response = yield call(request, URL, {
      method: "POST",
      body: { data: encryptData(BODY) },
    });
    const MainDecode = decryptData(response.res);

    if (MainDecode.status == 200) {
      // let data = {
      //     "class_id": action.data.class_id,
      // }
      yield put(
        UserClassStandardReducer.addClassCoreStandardSuccess(MainDecode)
      );
      // yield put(UserClassStandardReducer.getClassTag(data));
      createNotification("success", MainDecode.message);
    } else {
      if (
        MainDecode.message ===
        "Your session has been expired. Please login again"
      ) {
        logOut("error", MainDecode.message);
      }
      createNotification("error", MainDecode.message);
      yield put(
        UserClassStandardReducer.addClassCoreStandardFailure(MainDecode)
      );
    }
  } catch (error) {
    createNotification("warning", "Something went wrong");
    yield put(UserClassStandardReducer.addClassCoreStandardFailure(false));
  }
}

function* deleteCustomStandards(action) {
  let data = JSON.parse(sessionStorage.getItem("UserData"));
  const URL = deleteCustomStandard_API;
  const BODY = {
    user_id: data.cc_user_id,
    main_id: action?.data?.main_id,
    platform: "web",
  };

  let response;
  try {
    response = yield call(request, URL, {
      method: "PUT",
      body: { data: encryptData(BODY) },
    });
    const MainDecode = decryptData(response.res);

    if (MainDecode.status == 200) {
      yield put(
        UserClassStandardReducer.deleteCustomStandardsSuccess(MainDecode)
      );
      let obj = {
        main_id: '',
        group_id: '',
        grade_id: '',
        standard_id: '',
        fromDelete: true,
      }
      yield put(UserClassStandardReducer.getCoreStandard(obj));
      createNotification("success", MainDecode.message);
    } else {
      if (
        MainDecode.message ===
        "Your session has been expired. Please login again"
      ) {
        logOut("error", MainDecode.message);
      }
      createNotification("error", MainDecode.message);
      yield put(
        UserClassStandardReducer.deleteCustomStandardsError(MainDecode)
      );
    }
  } catch (error) {
    createNotification("warning", "Something went wrong");
    yield put(UserClassStandardReducer.deleteCustomStandardsError(false));
  }
}


function* editCustomStandards(action) {
  if(action?.data?.core_standard_data?.trim().length == 0) {
    createNotification("error", "Please Enter the Standard Name");
    yield put(
      UserClassStandardReducer.editCustomStandardsError("Please Enter the Standard Name")
    );
  } else {
    let data = JSON.parse(sessionStorage.getItem("UserData"));
    const URL = editCustomStandard_API;
    const BODY = {
      user_id: data.cc_user_id,
      core_standards_text: action?.data?.core_standard_data,
      previous_core_standards_text: action?.data?.previous_core_standards_text,
      platform: "web",
    };
  
    let response;
    try {
      response = yield call(request, URL, {
        method: "PUT",
        body: { data: encryptData(BODY) },
      });
      const MainDecode = decryptData(response.res);
  
      if (MainDecode.status == 200) {
        yield put(
          UserClassStandardReducer.editCustomStandardsSuccess(MainDecode)
        );
        let obj = {
          main_id: '',
          group_id: '',
          grade_id: '',
          standard_id: '',
          fromDelete: true,
        }
        yield put(UserClassStandardReducer.getCoreStandard(obj));
        createNotification("success", MainDecode.message);
      } else {
        if (
          MainDecode.message ===
          "Your session has been expired. Please login again"
        ) {
          logOut("error", MainDecode.message);
        }
        createNotification("error", MainDecode.message);
        yield put(
          UserClassStandardReducer.editCustomStandardsError(MainDecode)
        );
      }
    } catch (error) {
      createNotification("warning", "Something went wrong");
      yield put(UserClassStandardReducer.editCustomStandardsError('Something went wrong'));
    }
  }
  }
  