import React, { useState, useEffect } from 'react';
import { Button } from "reactstrap";
import Modal from 'react-bootstrap/Modal';
import * as routes from "Router/RoutesURL";
import './UpgradePopUp.scss';

const UpgradePopUp = (props) => {
    const [ show, setShow ] = useState(false);
    
    const { handleClosePopUp } = props;
    useEffect(()=>{
        setShow(true);
    },[props])

    const handleClose = () => {
        setShow(false);
        handleClosePopUp(false);
    }

    const showUpgradePage = () => {
        setShow(false);
        window.location.href = routes.DATAUSAGE;
    }

    return (
        <div className="upgradePopUp">
            <Modal className="popUp-modal-warning br-8" show={show} onHide={handleClose} centered={true}>
                <Modal.Header>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="confirm-text">You have reached the usage limit for the current plan.<br>
                    </br> Please Click here to upgrade or Dismiss to continue</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="cancelUpgrade-btn" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="default" className="showNext-btn" onClick={showUpgradePage}>
                        Upgrade Plan
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UpgradePopUp