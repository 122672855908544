import { call, takeLatest, put } from 'redux-saga/effects';
import UserClassRosterSelectReducer from 'Redux/Reducers/ClassReducer/classSelectRosterReducer';
import { createNotification } from 'Config/notificationtoast';
import { GET_NOT_SELECTED_ROSTERS, ADD_SELECTED_ROSTERS } from 'Actions/constants';
import {
    GetRosterWithoutClass_API,
    AddRoster_API,
    GetClass_API,
    GetRoster_API,
    AddMultipleRoster
} from 'Config/Api';
import { chroniclecloud_code } from 'Config/Constant';
import request from 'Config/request';
import { decryptData, encryptData, getRandomColor, logOut } from 'Utils/Helper';
import  UserClassRosterReducer  from 'Redux/Reducers/ClassReducer/classRosterReducer';

export function* userClassSelectRosterModuleSaga() {
    yield takeLatest(GET_NOT_SELECTED_ROSTERS, getNotSelectedRosters);
    yield takeLatest(ADD_SELECTED_ROSTERS, addSelectedRosters);
}
// const classURL = `${GetClass_API}?role=${data.user_type}&teacher_id=${data.cc_user_id}&page=${action.data.page_no}&limit=10&class_academic_year=${action.data.class_academic_year}&filter_by=${action.data.filter_by}&platform=web`;

function* getNotSelectedRosters(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    // const URL = GetRosterWithoutClass_API;
    // const URL = `${GetClass_API}?role=${data.user_type}&teacher_id=${data.cc_user_id}&class_academic_year=${action.data.class_id}&page=${action.data.page_no}&limit=100&filter_by=${action.data.filter_by}&platform=web`;
    // const URL =  `${GetRosterWithoutClass_API}?user_id=${data.cc_user_id}&academic_year_id=${action.data.academic_year}&class_id=${action.data.class_id > 0 ? action.data.class_id : null}&page_no=${action.data.page_no}&filter_by=${action.data.filter_by}&sort_by=${'sort_by_first_name'}&search_text=${action.data.search_text}&by_date=${action.data.by_date}&date_flag=${action?.data?.date_flag || ''}&platform=web`;
    // console.log('URL', URL)
    const URL =  `${GetRosterWithoutClass_API}?user_id=${data.cc_user_id}&academic_year_id=${action.data.academic_year}&class_id=${action.data.class_id > 0 ? action.data.class_id : null}&page_no=${action.data.page_no}&limit=100&filter_by=${action.data.filter_by}&sort_by=${'sort_by_first_name'}&search_text=${action.data.search_text}&by_date=${action.data.by_date}&date_flag=${action.data.date_flag  || ""}&platform=web`;
    const BODY = JSON.stringify({
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "filter_by": action.data.filter_by,                                                                                        
        // "sort_by": action.data.sort_by,
        "tag_id": action.data.tag_id,
        "page_no": action.data.page_no,
        "academic_year_id": action.data.academic_year,
        "sort_by": "sort_by_first_name",
        "search_text": action.data.search_text,
        "by_date": action.data.by_date,
        "date_flag": action.data.date_flag,
    })

    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res)
       
        if (MainDecode.status === 200) {
            yield put(UserClassRosterSelectReducer.getNotSelectedRostersSuccess(MainDecode));

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassRosterSelectReducer.getNotSelectedRostersFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassRosterSelectReducer.getNotSelectedRostersFailure(false));
    }
}

function* addSelectedRosters(action) {

    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = AddMultipleRoster;
    const BODY = {

        // "chroniclecloud_code": chroniclecloud_code,
        // "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        // "student_color_code": '',
        // "student_pic_size": '',
        // "student_first_name": '',
        // "student_last_name": '',
        // "student_registration_email_id": '',
        // "student_secondary_email_id": '',
        // "student_pic_url": '',
        // "student_id": action.data.student_id,
        // "academic_year_id": action.data.academic_year_id,
        // "class_id": action.data.class_id,
        // "by_class_sharing": action.data.by_class_sharing,
        
        "role": data.user_type,
        // "first_name": "",
        // "last_name": action.data.student_last_name,
        // "email": action.data.student_registration_email_id,
        // "student_profile_url": action.data.student_pic_url,
        "student_ids": action.data.student_id.toString(),
        // "school_id": data.school_id,
        "user_id": data.cc_user_id,
        // "academic_year_id":action.data.academic_year_id,
        // "student_color_code": getRandomColor(),
        "class_id": action.data.class_id || action.data.cc_class_id ,
        // "student_pic_size": `${action.data.student_pic_size}`,
        // "by_class_sharing": action.data.by_class_sharing,
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res)
        if (MainDecode.status === 200) {
            let data = {
                "class_id": action.data.class_id,
                "filter_by": "not_in_current_class",
                "sort_by": "sort_by_first_name",
                "page_no": 1,
                "academic_year": action.data.academic_year_id,
                "search_text": "",
                "by_date": "",
                "date_flag": "",

            }
            const value = {
                "class_id": action.data.class_id,
                "filter_by": "by_class",
                "page_no": 1,
                "academic_year": action.data.academic_year_id,
                "search_data" : "",
                "limit": action.data.limit
            }
            // createNotification('success', MainDecode.message);
            yield put(UserClassRosterSelectReducer.addSelectedRostersSuccess(MainDecode));
            // yield put(UserClassRosterReducer.)
            yield put(UserClassRosterReducer.getClassRosterList(value));
            yield put(UserClassRosterSelectReducer.getNotSelectedRosters(data));
            // console.log('action.data.history.pathname', action.data.history.location.pathname)
            // if (action.data.history.location.pathname ==  "/home/Class/selectRoster") {
            //     action.data.history.goBack()
            // }

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassRosterSelectReducer.addSelectedRostersFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassRosterSelectReducer.addSelectedRostersFailure(false));
    }
}


