import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import playStore from "Assets/CommonComponent/playStore.svg";
import iStore from "Assets/CommonComponent/iStore.svg";
import "./FooterDashboard.scss";
const FooterDashboard = (props) => {
  // ------Go To PlayStore------
  const goToPlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.thesynapses.chronicle"
    );
  };
  // ------Go To PlayStore------
  // ------Go To Istore------
  const goToIstore = () => {
    window.open(
      "https://apps.apple.com/us/app/chronicle-cloud-teachers-app/id1326291372"
    );
  };
  return (
    <div
      className="footer"
      style={{
        bottom: props?.location?.pathname == "/home/allStandards" ? 0 : "-10vh",
      }}
    >
      <Row>
        <Col md={9} className="alignCentre">
          <p className="copyright">
            COPYRIGHT © ALL RIGHT RESERVED POWERED BY SYNAPSE XTREME ENGINE
            (SXE)
          </p>
        </Col>
        <Col md={3}>
          <span className="downloadOptions">
            <p className="downloadTitle">Download App </p>
            <img
              src={playStore}
              className="footerSocialIcon"
              height="30"
              alt="CoolBrand"
              onClick={goToPlayStore}
            />
            <img
              src={iStore}
              className="footerSocialIcon"
              height="30"
              alt="CoolBrand"
              onClick={goToIstore}
            />
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default FooterDashboard;
