import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import BlogCards from '../BlogCardsComponent/BlogCards';
import DivLoader from 'Components/LoadingComponent/DivLoader';
import './BlogDashboard.scss';

const BlogDashboard = (props) => {
    const { getBlogListRequest, loadMoreBlogListRequest, blogList, TotalBlogs, loader } = props;
    useEffect(() => {
        getBlogListRequest()
    }, [getBlogListRequest]);

    const loadMore = () => {
        loadMoreBlogListRequest()
    }
    return (
        <div className='blogContainer'>
            <p className='blogHeading'>Blog</p>
            {loader && <DivLoader />}
            <div className='blogMainCard'>
                {blogList?.length > 0 && blogList.map((item, index) => (
                    <BlogCards key={index} index={index} item={item} blogList={blogList} history={props.history} />
                ))}
            </div>

            {TotalBlogs.length > blogList.length &&
                <Button onClick={loadMore} className='blogLoadMore'><p className='blogMoreText'>Load More</p></Button>}

        </div>
    )
}
BlogDashboard.propTypes = {
    getBlogListRequest: PropTypes.func,
    loadMoreBlogListRequest: PropTypes.func,
    loader: PropTypes.bool,

}

export default BlogDashboard