import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import ContactInfo from '../ContactInfoComponent/ContactInfo';
import ContactForm from '../ContactFormComponent/ContactForm';
import './ContactUsDashboard.scss'


const ContactUsDashboard = (props) => {
    const { sendContactUsRequest } = props
    return (
        <div className='contactContainer'>
            <Card className='cardOfContact'>
                <p className='aboutHeading'>Contact Us</p>
                <Row>
                    {/*  ------ Contact Info ------ */}
                    <ContactInfo />
                    {/*  ------ Contact Info ------ */}

                    {/*  ------ Contact Form ------ */}
                    <ContactForm sendContactUsRequest={sendContactUsRequest} />
                    {/*  ------ Contact Form ------ */}

                </Row>
            </Card>
        </div>
    )
}
ContactUsDashboard.propTypes = {
    sendContactUsRequest: PropTypes.func
}

export default ContactUsDashboard