

import {
    GET_SUBSCRIPTION_PLAN_REQUEST,
    GET_SUBSCRIPTION_PLAN_SUCCESS,
    GET_SUBSCRIPTION_PLAN_FAILURE,
    GET_STRIPE_PRODUCTS,
    GET_STRIPE_PRODUCTS_ERROR,
    GET_STRIPE_PRODUCTS_SUCCESS,
    CHANGE_USER_SUBSCRIPTION_REQUEST,
    CHANGE_USER_SUBSCRIPTION_FAILURE,
    CHANGE_USER_SUBSCRIPTION_SUCCESS,

} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */



// ----------- Get Subscribe Plan-------------
export function getSubscriptionPlanRequest(data) {
    return {
        type: GET_SUBSCRIPTION_PLAN_REQUEST,
        data,
    };
}

export function getSubscriptionPlanSuccess(data) {
    return {
        type: GET_SUBSCRIPTION_PLAN_SUCCESS,
        data,
    };
}

export function getSubscriptionPlanFailure(data) {
    return {
        type: GET_SUBSCRIPTION_PLAN_FAILURE,
        data,
    };
}


export function getStripeProducts(data) {
    return {
      type: GET_STRIPE_PRODUCTS,
      data,
    };
  }
  
  export const getStripeProductsError = (data) => {
    return {
      type: GET_STRIPE_PRODUCTS_ERROR,
      data,
    };
  };
  
  export const getStripeProductsSuccess = (data) => {
    return {
      type: GET_STRIPE_PRODUCTS_SUCCESS,
      data,
    };
  };

  export function changeUserSubscriptionRequest(data) {
    return {
      type: CHANGE_USER_SUBSCRIPTION_REQUEST,
      data,
    };
  }
  
  export function changeUserSubscriptionFailure(data) {
    return {
      type: CHANGE_USER_SUBSCRIPTION_FAILURE,
      data,
    };
  }
  
  export function changeUserSubscriptionSuccess(data) {
    return {
      type: CHANGE_USER_SUBSCRIPTION_SUCCESS,
      data,
    };
  }




