import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Button from "react-bootstrap/Button";
import ClassGradebookColorPopover from '../ClassGradebookColorPopoverModule/ClassGradebookColorPopover';
import commentIcon from 'Assets/ClassGradeBookModule/comment.svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Rearrange_blue from 'Assets/ClassGradeBookModule/Rearrange_blue.png';
import swipeIcon from 'Assets/ClassGradeBookModule/Swipe.png';
import "./ClassGradeBookTableData.scss";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const ClassGradeBookTableData = (props) => {
    const {
        index,
        cIndex,
        provided,
        snapshot,
        item,
        ClassData,
        selectedCatIndex,
        SeleectedEvetIndex,
        SelectedStudentIndex,
        setIndex,
        addClassGradebookPopoverDataRequest,
        selectedStudentForPopoverDetails,
        updateClassGradebookPopoverColorRequest,
        updateClassGradebookPopoverInputRequest,
        updateClassGradebookScoreCommentRequest,
        openClassGradebookPopoverRequest,
        history,
        isDragDisabled, changeGradebookType, updateClassGradebookStudentsRequest, updateClassGradebookIdRequest, studentList, setCategoryIdCheck, setCategoryNameCheck, setEventNameCheck, setEventIdCheck, setdateCheck, getListStyle, getClassGradebookList, updateClassGradebookDragEventDataRequest } = props;
    const numbersArr = Array.from({ length: 50 }, (v, k) => k + 1);
    const [showTextArea, setShowTextAreaCheck] = useState(false);
    const [isEventDragDisabled, setEventDragDisabledCheck] = useState(false)

    
    
    /* ---------------onLoad------------ */
    useEffect(() => {
        if (isDragDisabled) {
            setEventDragDisabledCheck(false)
        }

    }, [isDragDisabled]);
    /* ---------------onLoad------------ */

    const getItemStyle = (isDragging, draggableStyle, isDragDisabled, index) => ({
        userSelect: 'none',
        // minWidth: index === 0 ? 120 : index * 120,
        Width: index === 0 ? 120 : index * 120,

        // change background colour if dragging
        background: isDragging || isDragDisabled ? '#CCCCCC70' : 'white',
        borderRight: "1px solid #CCCCCC",
        // styles we need to apply on draggables
        ...draggableStyle,
    });
    const [mainGradebooklist, setMainGradebooklist] = useState([]);
    useEffect(() => {
        if (getClassGradebookList && getClassGradebookList?.students && getClassGradebookList?.students?.length > 0) {
            
            setMainGradebooklist(getClassGradebookList);
        }
    }, [getClassGradebookList])
    // --------Edit event-------
    const typeOfEvent = (category, event) => {
      
        sessionStorage.setItem("mainGradebooklist", JSON.stringify(mainGradebooklist));
        if (!isDragDisabled && category.cc_category_name !== "") {

            const data = {
                category, event
            }
            setCategoryIdCheck(category.cc_category_id)
            setCategoryNameCheck(category.cc_category_name)
            setEventIdCheck(event.grading_event_id)
            setEventNameCheck(event.grading_event_name)
            setdateCheck(event.grading_event_modifyed_on ? event.grading_event_modifyed_on : event.grading_event_add_on)
            updateClassGradebookIdRequest(data)
            updateClassGradebookStudentsRequest(event.event_student)
            changeGradebookType("editEvent")
        }
    }
    // --------Edit event-------


    // --------Edit category-------
    const typeOfCategory = (category, event) => {
    
        sessionStorage.setItem("mainGradebooklist", JSON.stringify(mainGradebooklist));
        
        if (!isDragDisabled) {
            const data = {
                category, event
            }
            setCategoryIdCheck(category.cc_category_id)
            setCategoryNameCheck(category.cc_category_name)
            setdateCheck(category.cc_category_add_on)
            setEventIdCheck('')
            setEventNameCheck('')
            updateClassGradebookIdRequest(data)
            updateClassGradebookStudentsRequest(studentList)
            changeGradebookType("editCat")
        }
    }
    // --------Edit category-------

    // --------open PopOver-------
    const openPopOver = async (StudentIndex, subItem, eventIndex, cIndex) => {
        if (!isDragDisabled) {
            if (!(selectedCatIndex === '')) {
                await closePopOver(selectedCatIndex, SeleectedEvetIndex, SelectedStudentIndex)
            }
            setIndex(cIndex, eventIndex, StudentIndex)
            addClassGradebookPopoverDataRequest(subItem.event_student[StudentIndex])
            const data = {
                cIndex: cIndex,
                eIndex: eventIndex,
                sIndex: StudentIndex
            }
            // setShowTextAreaCheck(false)
            openClassGradebookPopoverRequest(data)
        }
    }
    // --------open PopOver-------

    // --------close PopOver-------
    const closePopOver = (cIndex, eventIndex, StudentIndex) => {
        if (!isDragDisabled) {
            addClassGradebookPopoverDataRequest([])
            const data = {
                cIndex: cIndex,
                eIndex: eventIndex,
                sIndex: StudentIndex
            }
            setIndex('', '', '');
            setShowTextAreaCheck(false)
            openClassGradebookPopoverRequest(data)
        }
    }
    // --------close PopOver-------

    // -----drag data-----
    const onDragEnd = (result, catEvents, cIndex) => {

        if (catEvents.category_events.length > 1) {
            if (!result.destination) {
                return;
            }
            const Value = reorder(
                catEvents.category_events,
                result.source.index,
                result.destination.index
            );
            let data = getClassGradebookList.category;
            data[cIndex].category_events = Value;
            const APIValue = {
                "eventId": result.draggableId,
                "newIndex": result.destination.index,
                "classId": ClassData.cc_class_id,
                "Value": data,
            }

            updateClassGradebookDragEventDataRequest(APIValue)
        }
    }
    // -----drag data-----

    const startEventSwipe = () => {
        if (!isDragDisabled) {
            setEventDragDisabledCheck(!isEventDragDisabled)
        }
    }

    return (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, isDragDisabled, index)}>
            {isDragDisabled && item.cc_category_name !== "" && <img className="swipeIcon" src={swipeIcon} alt="" width="44" height="40" />}
            <div className={`${isDragDisabled && "mt-40"} data-title data-header-style`}>
                <div className={`${item.cc_category_name !== "" && "backgroundEvent"} main-header`}  >
                    <label onClick={() => { typeOfCategory(item, {}) }}>{item.cc_category_name?.replace(/(.{13})..+/, "$1…")}</label>
                    {item.category_events.length > 1 && <img src={Rearrange_blue} alt='' onClick={startEventSwipe} className='eventSwipeIcon'></img>}
                </div>
            </div>
            <DragDropContext onDragEnd={(result) => { onDragEnd(result, item, cIndex) }}>
                <Droppable key={index} droppableId={JSON.stringify(item.cc_category_id)} direction="horizontal">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                        >
                            <div className={`${index > 0 && "displayFlex"}`}>
                                {item?.category_events?.length > 0 && item?.category_events?.map((subItem, eventIndex) => (
                                    <div key={eventIndex} className={index > 1 ? "bl-1" : ""}>
                                        <Draggable key={subItem.grading_event_id} draggableId={JSON.stringify(subItem.grading_event_id)} index={eventIndex} isDragDisabled={!isEventDragDisabled}>
                                            {(provided, snapshot) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, isEventDragDisabled ? isEventDragDisabled : isDragDisabled, eventIndex)}>
                                                    {isEventDragDisabled && <img className="swipeIcon" src={swipeIcon} alt="" width="44" height="40" />}
                                                    <div className={`${isEventDragDisabled && "mt-40"} ${subItem.grading_event_name !== "" && "backgroundEvent"} data-title data-sub-header-style`} >
                                                        <div className="sub-header" onClick={() => { typeOfEvent(item, subItem) }}>
                                                            <label>{subItem.grading_event_name?.replace(/(.{13})..+/, "$1…")}</label>
                                                        </div>
                                                    </div>
                                                    {subItem?.event_student?.length > 0 && subItem?.event_student?.map((student, sIndex) => (
                                                        <div className="data-style" key={sIndex}>
                                                            {student.grading_event_student_score === '' ?
                                                                <div className={`data-styles ${student?.grading_event_student_comments?.length > 0  ? "" : "grade-div"}`}>
                                                                    <Button
                                                                        id={`mypopOver${cIndex}${eventIndex}${sIndex}`}
                                                                        variant="link"
                                                                        className="grade-btn score"
                                                                        style={{ color: (student?.grading_event_student_comments?.length > 0 && (student.grading_event_student_color_code === '' || student.grading_event_student_color_code === '#FFFFFF')) ? '#222222' : student.grading_event_student_color_code }}
                                                                        onClick={() => { openPopOver(sIndex, subItem, eventIndex, cIndex) }}
                                                                    >
                                                                        {/* {student?.grading_event_student_comments.length > 0 ? "0" : "GradeNow"} */}
                                                                        {
                                                                            student?.grading_event_student_comments?.length > 0  ? 
                                                                            <>
                                                                            <label>-</label>
                                                                            {!(student.grading_event_student_comments === '') && <img className="comment-img" src={commentIcon} alt="" width="16" height="15" />}
                                                                            </>
                                                                            :
                                                                            "GradeNow"
                                                                        }
                                                                        </Button>

                                                                    {student.openPopover && <ClassGradebookColorPopover
                                                                        setValue={student.openPopover}
                                                                        sIndex={sIndex}
                                                                        eIndex={eventIndex}
                                                                        cIndex={cIndex}
                                                                        ClassData={ClassData}
                                                                        showTextArea={showTextArea}
                                                                        setShowTextAreaCheck={setShowTextAreaCheck}
                                                                        closePopOver={closePopOver}
                                                                        PopOverStudentData={selectedStudentForPopoverDetails}
                                                                        updateClassGradebookPopoverColorRequest={updateClassGradebookPopoverColorRequest}
                                                                        updateClassGradebookPopoverInputRequest={updateClassGradebookPopoverInputRequest}
                                                                        updateClassGradebookScoreCommentRequest={updateClassGradebookScoreCommentRequest}
                                                                    />}
                                                                </div>
                                                                :
                                                                <div className="data-style" >
                                                                    {student.cc_student_id !== "" && <p
                                                                        id={`mypopOver${cIndex}${eventIndex}${sIndex}`}
                                                                        onClick={() => { openPopOver(sIndex, subItem, eventIndex, cIndex) }}
                                                                        className="score" style={{ color: (student.grading_event_student_color_code === '' || student.grading_event_student_color_code === '#FFFFFF') ? '#222222' : student.grading_event_student_color_code }}>
                                                                        <label>{student.grading_event_student_score}</label>
                                                                        {!(student.grading_event_student_comments === '') && <img className="comment-img" src={commentIcon} alt="" width="16" height="15" />}
                                                                    </p>}
                                                                    {student.openPopover && <ClassGradebookColorPopover
                                                                        setValue={student.openPopover}
                                                                        sIndex={sIndex}
                                                                        eIndex={eventIndex}
                                                                        ClassData={ClassData}
                                                                        cIndex={cIndex}
                                                                        showTextArea={showTextArea}
                                                                        setShowTextAreaCheck={setShowTextAreaCheck}
                                                                        closePopOver={closePopOver}
                                                                        PopOverStudentData={selectedStudentForPopoverDetails}
                                                                        updateClassGradebookPopoverColorRequest={updateClassGradebookPopoverColorRequest}
                                                                        updateClassGradebookPopoverInputRequest={updateClassGradebookPopoverInputRequest}
                                                                        updateClassGradebookScoreCommentRequest={updateClassGradebookScoreCommentRequest}
                                                                    />}
                                                                </div>}
                                                        </div>))}
                                                </div>
                                            )}

                                        </Draggable>
                                    </div>))}
                            </div >
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

        </div >
    );
}
ClassGradeBookTableData.propTypes = {
    changeGradebookType: PropTypes.func,
    updateClassGradebookIdRequest: PropTypes.func,
    setCategoryIdCheck: PropTypes.func,
    setCategoryNameCheck: PropTypes.func,
    setEventNameCheck: PropTypes.func,
    setEventIdCheck: PropTypes.func,
    setdateCheck: PropTypes.func,
    openClassGradebookPopoverRequest: PropTypes.func,
    index: PropTypes.number,
    cIndex: PropTypes.number,
    isDragDisabled: PropTypes.bool,
    item: PropTypes.object,
    ClassData: PropTypes.object,
    studentList: PropTypes.array,
    updateClassGradebookPopoverColorRequest: PropTypes.func,
    updateClassGradebookPopoverInputRequest: PropTypes.func,
    addClassGradebookPopoverDataRequest: PropTypes.func,
    updateClassGradebookScoreCommentRequest: PropTypes.func,
    updateClassGradebookDragEventDataRequest: PropTypes.func
};
export default ClassGradeBookTableData