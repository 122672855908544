import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { login } from "Actions/AuthAction/loginAction";
import Logo from "Assets/LoginModule/mainLogo.png";
import "firebase/analytics";
import * as routes from "Router/RoutesURL";
import "./LoginPage.scss";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@material-ui/core/TextField";
import email from "Assets/LoginModule/email.png";
import padlock from "Assets/LoginModule/padlock.png";
import { setCookie, getCookie, deleteCookie, encryptPasswordHandler, decryptPasswordHandler } from "Utils/Helper";

const LoginPage = (props) => {
  const { history, login, userStatusData } = props;
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [show, setShow] = useState("Password");
  const [ErrorMessageEmail, setErrorMessageEmail] = useState("");
  const [ErrorMessagePassword, setErrorMessagePassword] = useState("");
  const [InvalidMessageEmail, setInvalidMessageEmail] = useState("");
  const [InvalidMessagePass, setInvalidMessagePass] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [keepMeSinged, setKeepMeSinged] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    let data = getCookie("user_email");
    let dataPassword = getCookie("user_password");
    let rememberData = getCookie("remember_user");
    if (data) {
      setEmail(data);
      setPassword(decryptPasswordHandler(dataPassword));
      setRememberMe(true);
    }
    if (rememberData) {
      setKeepMeSinged(true);
    }
  }, []);

  // useEffect(() => {
  //   if (!keepMeSinged) {
  //     deleteCookie("user_email", "remember_user");
  //   }
  // }, [keepMeSinged]);

  useEffect(() => {
    if (userStatusData?.login_Data) {
      if (userStatusData?.action === "LOGIN_SUCCESS") {
        if (userStatusData?.login_Data?.status === 200) {
          sessionStorage.setItem(
            "FirstTimeLogin",
            userStatusData.login_Data.data.first_time_login
          );
          sessionStorage.setItem(
            "RegionalUrl",
            userStatusData.login_Data.data.regional_url
          );
          if (userStatusData?.login_Data?.data?.first_time_login === 1) {
            history.push(routes.VERIFICATION, { email: Email });
          } else {
            window.location.href = routes.ACADEMICDASHBOARD;
          }
        }
      }
    }
  }, [userStatusData]);

  // ---------Input Handle change-------
  const handleChange = () => {
    setErrorMessageEmail("");
    setErrorMessagePassword("");
    setInvalidMessageEmail("");
    setIsSubmit(false);
  };

  // ---------Show password -------
  const showPassWord = (value) => {
    setShow(value);
  };

  // ---------User Login-------
  const loginUser = () => {
    sessionStorage.removeItem("deleteUSer");
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

    if (Email === "") {
      setErrorEmail(true);
      setEmailErrorMessage("*Email ID is required");
    } else if (!emailRegex.test(Email)) {
      setErrorEmail(true);
      setEmailErrorMessage("*Please enter valid email address");
      return false;
    } else {
      setIsSubmit(true);
      setErrorEmail(false);
      sessionStorage.setItem("RegisterEmail", Email);
      sessionStorage.setItem("Password", Password);
      sessionStorage.setItem("keepMeSinged", keepMeSinged);
      sessionStorage.setItem("previousPage", "login");
      const sendRequest = {
        user_email_id: Email,
        user_password: Password,
        keepMeSinged: keepMeSinged,
      };
      login(sendRequest);
      if (keepMeSinged) {
        setCookie("user_email", Email);
        setCookie("user_password", encryptPasswordHandler(Password));
        setCookie("remember_user", keepMeSinged);
      }
      if(!keepMeSinged) {
        deleteCookie("user_email","user_password", "remember_user");
      }
    }
  };

  // ---------Custom Theme CSS-------
  const theme = createTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: "transparent",
          marginLeft: "-10px !important",
          "&:hover": {
            backgroundColor: "transparent",
            marginLeft: "-10px !important",
          },
          "&$focused": {
            backgroundColor: "transparent",
            marginLeft: "-10px !important",
          },
        },
        underline: {
          "&:before": {
            borderBottomColor: "transparent",
            width: "106%",
            marginBottom: "1px !important",
          },
          "&:hover:not(.Mui-focused):before": {
            borderBottomColor: "transparent",
            width: "106%",
            marginBottom: "1px !important",
          },
          "&:after": {
            // focused
            borderBottomColor: "#386CB5",
            width: "106%",
            marginBottom: "1px !important",
          },
        },
      },
      MuiInputLabel: {
        filled: {
          color: "#222222",
          marginLeft: "-10px !important",
          top: "-1px !important",
          "&$focused": {
            color: "#386CB5",
            marginLeft: "-10px !important",
          },
          ".MuiFormControl-root:hover &:not($focused)": {
            color: "#222222",
            marginLeft: "-10px !important",
          },
        },
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: "-44px",
          marginTop: "1px",
        },
      },
    },
  });

  return (
    <div className="LoginMainContainer">
      <div className="LoginSubContainer">
        <div>
          <img alt="" src={Logo} className="img-div" />
        </div>
      </div>
      <div className="LoginSubContainer">
        <div className="LoginCardDiv">
          <div className="LoginTextDiv">
            <p className="LoginText">Sign In</p>
            <p className="ProcessText font-weight-bold">
              Enter your email address and password to access your account.
            </p>
          </div>
          {/*  Input Email Div First*/}
          <div className="LoginInputDiv">
            <Box fullWidth sx={{ display: "flex", alignItems: "flex-end" }}>
              {/* <MailOutlineIcon sx={{ color: 'action.active', mr: 1, my: 0.25, mx: 1, }} style={{ marginBottom: errorEmail ? '33px' : '12px' }} /> */}
              <img
                alt=""
                src={email}
                className="img-div"
                sx={{ color: "action.active", mr: 1, my: 0.25, mx: 1 }}
                style={{
                  marginBottom: errorEmail ? "24px" : "2px",
                  // margin: errorEmail ? '15px' : '12px'
                  marginRight: "10px",
                  marginLeft: "15px",
                  // marginBottom: '0px'
                }}
              />

              <FormControl
                sx={{ mr: 1, my: 0.25, mx: 1, width: "36ch" }}
                variant="standard"
              >
                <MuiThemeProvider theme={theme}>
                  <TextField
                    size="small"
                    type="Email"
                    name="Email"
                    id="input-with-sx"
                    label="Email ID"
                    shrink={true}
                    variant="filled"
                    autoComplete="off"
                    style={{ backgroundColor: "transparent !important" }}
                    value={Email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                      handleChange();
                    }}
                    disableUnderline={isVisible}
                    onFocus={() => setIsVisible(false)}
                    onBlur={() => setIsVisible(true)}
                    error={errorEmail === true ? true : false}
                    helperText={errorEmail === true ? emailErrorMessage : ""}
                  />
                </MuiThemeProvider>
              </FormControl>
            </Box>
          </div>
          {/*  Input Email Div First*/}

          {/*  Input Password Div Second*/}
          <div className="LoginInputDiv">
            <Box fullWidth sx={{ display: "flex", alignItems: "flex-end" }}>
              {/* <LockOutlinedIcon sx={{ color: 'action.active', mr: 1, my: 0.25, mx: 1, }} style={{ marginBottom: '12px' }} /> */}
              <img
                alt=""
                src={padlock}
                className="img-div"
                sx={{ color: "action.active", mr: 1, my: 0.25, mx: 1 }}
                style={{
                  marginRight: "11px",
                  marginLeft: "17px",
                  marginBottom: "5px",
                }}
              />
              <FormControl
                sx={{ mr: 1, my: 0.25, mx: 1, width: "36ch" }}
                variant="standard"
              >
                <MuiThemeProvider theme={theme}>
                  <TextField
                    size="small"
                    id="standard-adornment-password"
                    type={show}
                    name="Password"
                    label="Password"
                    value={Password}
                    shrink={true}
                    variant="filled"
                    autoComplete="off"
                    onChange={(event) => {
                      setPassword(event.target.value);
                      handleChange();
                    }}
                    disableUnderline={isVisible}
                    onFocus={() => setIsVisible(false)}
                    onBlur={() => setIsVisible(true)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            style={{
                              marginRight: "-40px",
                              paddingBottom: "10px",
                            }}
                            onClick={() => {
                              show === "text"
                                ? showPassWord("Password")
                                : showPassWord("text");
                            }}
                          >
                            {show === "text" ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </MuiThemeProvider>
              </FormControl>
            </Box>
          </div>
          <div className="errorDiv">
            {!Password && (
              <p className="errormessage">{ErrorMessagePassword}</p>
            )}
            {Password && <p className="errormessage">{InvalidMessagePass}</p>}
          </div>
          {/*  Input Password Div Second*/}

          {/*  Keep Me signIn Text*/}
          <div className="buttonTop">
            <div style={{ display: "inline-flex" }} className="fLeft">
              <i
                onClick={() => setKeepMeSinged(!keepMeSinged)}
                className="checkBoxColorLogin material-icons"
                style={{
                  color: keepMeSinged === true ? "#2DD152" : "#00000033",
                  fontSize: "22px !important",
                }}
              >
                {keepMeSinged ? "check_circle" : "radio_button_unchecked"}
              </i>
              <span className="keepMeTextLogin">Remember me</span>
            </div>
            <div
              className="fRight"
              onClick={() => {
                history.push(routes.FORGOT);
              }}
            >
              <span className="Textforgot"> Forgot Password?</span>
            </div>
          </div>
          {/*  Keep Me signIn Text*/}

          {/*  Login Button*/}
          <div className="SigninBtn">
            <Button
              disabled={Email !== "" && Password !== "" ? false : true}
              onClick={() => loginUser()}
              color="primary"
              size="lg"
              block
            >
              Sign In
            </Button>
          </div>
          {/*  Login Button*/}

          {/*  Register*/}
          <div className="RegistrationBtnDiv">
            <p className="registerText">Don't have an account?</p>
            <div
              className="RegistrationBtn"
              onClick={() => {
                history.push(routes.REGISTER);
              }}
            >
              <span className="RegistrationBtnText">REGISTER NOW</span>
            </div>
          </div>
          {/*  Register*/}
        </div>
        <div className="bottomDiv">
          <p className="text-1">
            © 2022 All rights reserved | Built for Chronicle Cloud.
          </p>
          <p className="text-2">Powered with Synapse Xtreme Engine (SXE)</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: state,
  userStatusData: state.login,
});
const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(login(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
