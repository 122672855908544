import React from 'react';
import { connect } from 'react-redux';
import ClassNoteAddGroup from './ClassNoteAddGroup'
import { getGroupDetailsRequest, addMultipleStudentsRequest, 
    deleteGroupRequest, 
    addNewStudentInGroupRequest,
    removeStudentFromGroupRequest,
    renameGroupRequest, 
    createGroupRequest 
    } 
    from 'Actions/NoteAction/groupDetailsAction';

const ClassNoteAddGroupIndex = (props) => {
    return (
        <ClassNoteAddGroup {...props} />
    )
}

const mapStateToProps = state => ({
    groupsData: state.addGroup.groupsData,
   groupDetails: state.addGroup,
   addMultipleStudents: state.addGroup.addMultipleStudents,
   deleteGroup: state.addGroup.deleteGroup,
   addNewStudentInGroup:state.addGroup.addNewStudentInGroup,
   removeStudentFromGroup:state.addGroup.removeStudentFromGroup,
   renameGroup:state.addGroup.renameGroup,
   createGroup:state.addGroup.createGroup
});

const mapDispatchToProps = dispatch => {
    return {
        getGroupDetailsRequest: (data) => dispatch(getGroupDetailsRequest(data)),
        addMultipleStudentsRequest: (data) => dispatch(addMultipleStudentsRequest(data)),
        deleteGroupRequest: (data) => dispatch(deleteGroupRequest(data)),
        addNewStudentInGroupRequest:  (data) => dispatch(addNewStudentInGroupRequest(data)),
        removeStudentFromGroupRequest:  (data) => dispatch(removeStudentFromGroupRequest(data)),
        renameGroupRequest:  (data) => dispatch(renameGroupRequest(data)),
        createGroupRequest:  (data) => dispatch(createGroupRequest(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    ClassNoteAddGroupIndex
);