import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


// import Logger from '../Lib/Logger'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    register: ['data'],
    registerSuccess: ['data'],
    registerFailure: ['error'],

    registerReset: []

})

export const UserRegisterReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    register_Data: null,
    error: null,
    exists: null,
    action: null
})

/* ------------- Reducers ------------- */

/* signin */
export const register = (state) => {
    return {
        ...state, fetching: true, error: null, register_Data: null,
    }
}
export const registerSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, action: action.type, register_Data: data, exists: true }
}
export const registerFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, }
}

export const registerReset = (state, action) => {
    return {
        ...state, fetching: true, error: null, register_Data: null, action: action.type,
    }
}


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.REGISTER]: register,
    [Types.REGISTER_SUCCESS]: registerSuccess,
    [Types.REGISTER_FAILURE]: registerFailure,
    [Types.REGISTER_RESET]: registerReset,

})
