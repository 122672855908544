import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    updateUser: ['data'],
    updateUserSuccess: ['data'],
    updateUserFailure: ['error'],

    deleteUser: ['data'],
    deleteUserSuccess: ['data'],
    deleteUserFailure: ['error'],


})

export const UserProfileReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    user_Data: null,
    error: null,
    exists: null,
    loader: null,
    action: null,
    deleteUser:null,
})

/* ------------- Reducers ------------- */

/* updateUser */
export const updateUser = (state) => {
    return {
        ...state, fetching: true, error: null, user_Data: null, loader: true
    }
}
export const updateUserSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, user_Data: data, action: action.type, exists: true, loader: false }
}
export const updateUserFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'UPDATE_USER_INFO_FAILURE' }
}

export const deleteUser = (state) => {
    return {
        ...state,
        fetching: true, error: null, user_Data: null, loader: true
    }
}

export const deleteUserFailure = (state, action) => {
    return {
        ...state,
        fetching: false, error: null,  action: action.type, user_Data: null, loader: false
    }
}

export const deleteUserSuccess = (state, action) => {
    return {
        ...state,
        fetching: false, error: null,  action: action.type, user_Data: null, loader: false
    }
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.UPDATE_USER]: updateUser,
    [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
    [Types.UPDATE_USER_FAILURE]: updateUserFailure,

    [Types.DELETE_USER]: deleteUser,
    [Types.DELETE_USER_SUCCESS]: deleteUserSuccess,
    [Types.DELETE_USER_FAILURE]: deleteUserFailure,

})



