import React from 'react';
import { connect } from 'react-redux';
import StudentShareDashboard from './StudentShareDashboard';
import {
    getSchoolTeacher,
    shareStudent,
} from 'Actions/ClassAction/ClassShareAction';

const StudentShareIndex = (props) => {
    return (
        <StudentShareDashboard {...props} />
    )
}

const mapStateToProps = state => ({
    state: state,
    shareStudentData: state.classShare,
    upgradePopUp: state.classShare.upgrade_PopUp_Open
});
const mapDispatchToProps = dispatch => {
    return {

        getSchoolTeacher: (data) => dispatch(getSchoolTeacher(data)),
        shareStudent: (data) => dispatch(shareStudent(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    StudentShareIndex
);
