import {
    GET_USER_SETTING_REQUEST,
    GET_USER_SETTING_SUCCESS,
    GET_USER_SETTING_FAILURE,

    UPDATE_USER_SETTING_REQUEST,
    UPDATE_USER_SETTING_SUCCESS,
    UPDATE_USER_SETTING_FAILURE
   

} from '../constants';

// -----------Get Group Details-------------
export function getUserSettingRequest(data) {
    return {
        type: GET_USER_SETTING_REQUEST,
        data,
    };
}

export function getUserSettingSuccess(data, page) {
    return {
        type: GET_USER_SETTING_SUCCESS,
        data,
        page,
    };
}

export function getUserSettingFailure(data) {
    return {
        type: GET_USER_SETTING_FAILURE,
        data,
    };
}


// -----------Add Multiple Students Request-------------
export function updateUserSettingRequest(data) {
    return {
        type: UPDATE_USER_SETTING_REQUEST,
        data,
    };
}

export function updateUserSettingSuccess(data, page) {
    return {
        type: UPDATE_USER_SETTING_SUCCESS,
        data,
        page,
    };
}

export function updateUserSettingFailure(data) {
    return {
        type: UPDATE_USER_SETTING_FAILURE,
        data,
    };
}
