import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import "./AddNewRubrics.scss";

export default function DiscreteSlider({
  sliderValue,
  label,
  customClassName = "customDiscreetSlider",
  getSliderValue,
}) {
  const [marks, setMarks] = React.useState([]);

  React.useEffect(() => {
    const sliderMarks = Array.from(
      { length: sliderValue + 1 },
      (value, index) => ({
        value: index,
        label: `${index}`,
      })
    );
    setMarks(sliderMarks.slice(1));
  }, []);

  function valuetext(value) {
    getSliderValue(value, label);
  }
  return (
    <div className={`${customClassName}`}>
      <Box>
        <Slider
          aria-label={label}
          defaultValue={1}
          getAriaValueText={valuetext}
          valueLabelDisplay="auto"
          step={1}
          marks={marks}
          min={0}
          max={sliderValue}
        />
      </Box>
    </div>
  );
}
