import { call, takeLatest, put } from 'redux-saga/effects';
import UserAddGroupReducer from 'Redux/Reducers/NoteReducer/addGroupReducer';

import { createNotification } from 'Config/notificationtoast';
import {
    GET_GROUP_DETAILS_REQUEST,
    ADD_MULTIPLE_STUDENTS_REQUEST,
    DELETE_GROUP_REQUEST,
    ADD_NEW_STUDENT_IN_GROUP_REQUEST,
    REMOVE_STUDENT_FROM_GROUP_REQUEST,
    RENAME_GROUP_REQUEST,
    CREATE_GROUP_REQUEST
} from 'Actions/constants';
import {
    GetGroupDetails_API,
    AddMultipleStudents_API,
    DeleteGroup_API,
    AddNewStudentInGroup_API,
    RemoveStudentFromGroup_API,
    RenameGroup_API,
    CreateGroup_API
} from 'Config/Api';
import { getImage, getRosterImage } from "Config/commonFirebaseImage";
import { chroniclecloud_code } from 'Config/Constant';
import request from 'Config/request';
import { decryptData, encryptData, logOut } from 'Utils/Helper';

export function* userAddGroupModuleSaga() {
    yield takeLatest(GET_GROUP_DETAILS_REQUEST, getGroupDetailsRequest);
    yield takeLatest(ADD_MULTIPLE_STUDENTS_REQUEST, addMultipleStudentsRequest);
    yield takeLatest(DELETE_GROUP_REQUEST, deleteGroupRequest);
    yield takeLatest(ADD_NEW_STUDENT_IN_GROUP_REQUEST, addNewStudentInGroupRequest);
    yield takeLatest(REMOVE_STUDENT_FROM_GROUP_REQUEST, removeStudentFromGroupRequest);
    yield takeLatest(RENAME_GROUP_REQUEST, renameGroupRequest);
    yield takeLatest(CREATE_GROUP_REQUEST, createGroupRequest);
}
// ---------- Get group details-----
function* getGroupDetailsRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${GetGroupDetails_API}?user_id=${data.cc_user_id}&group_class_id=${action.data.class_id}&platform=web`;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "group_class_id": action.data.class_id,
        "page_no": action.data.pageNo,
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res)
        if (MainDecode.status == 200) {
            sessionStorage.setItem('groupOfStudentsInNotes',JSON.stringify(MainDecode?.data?.response_data?.[0]))
            for (let groupDetails of MainDecode.data.response_data[0][0].student_list) {
                if (groupDetails.cc_student_pic_url) {
                    if (!(groupDetails.cc_student_pic_url.includes("https") === true)) {
                        groupDetails.cc_student_pic_url = yield getRosterImage(groupDetails.cc_student_pic_url);
                    }
                }
                if (groupDetails.associated_group) {
                    if (groupDetails.associated_group.length === groupDetails.associated_group_count) {
                        groupDetails.associated_group.unshift({
                            "cc_group_id": "All",
                            "cc_group_name": "Select All",
                            "is_associated": 1
                        })

                    } else {
                        groupDetails.associated_group.unshift({
                            "cc_group_id": "All",
                            "cc_group_name": "Select All",
                            "is_associated": 0
                        })

                    }
                }
            }
            yield put(UserAddGroupReducer.getGroupDetailsSuccess(MainDecode, action.data.pageNo, action));
            // console.log('herelost',MainDecode?.data?.response_data?.[0]?.[0]?.student_list)
            // createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserAddGroupReducer.getGroupDetailsFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserAddGroupReducer.getGroupDetailsFailure(false));
    }
}
// ---------- Get group details-----


// ----------Add Multiple Students-----
function* addMultipleStudentsRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = AddMultipleStudents_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": `${data.cc_user_id}`,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "student_id": action.data.studentId,
        "group_ids": action.data.group_ids,
        "platform" : "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            yield put(UserAddGroupReducer.addMultipleStudentsSuccess(MainDecode, action));
            createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserAddGroupReducer.addMultipleStudentsFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserAddGroupReducer.addMultipleStudentsFailure(false));
    }
}
// ----------Add Multiple Students-----

// ----------delete group-----
function* deleteGroupRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = DeleteGroup_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "group_id": action.data.group_id,
        "platform" : "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            yield put(UserAddGroupReducer.deleteGroupSuccess(MainDecode, action));
            // createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserAddGroupReducer.deleteGroupFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserAddGroupReducer.deleteGroupFailure(false));
    }
}
// ----------delete group-----

// ----------add New Student In Group Request-----
function* addNewStudentInGroupRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = AddNewStudentInGroup_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": `${data.cc_user_id}`,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "source_group_id": "0",
        "destination_group_id": action.data.groupId,
        "student_id": action.data.studentId,
        "remove_student_id" : action.data.remove_student_id,
        "platform"  : "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            yield put(UserAddGroupReducer.addNewStudentInGroupSuccess(MainDecode, action));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserAddGroupReducer.addNewStudentInGroupFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserAddGroupReducer.addNewStudentInGroupFailure(false));
    }
}
// ----------add New Student In Group Request-----

// ----------remove Student From Group Request-----
function* removeStudentFromGroupRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = RemoveStudentFromGroup_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": `${data.cc_user_id}`,
        // "device_udid": sessionStorage.getItem('UDID'),
        "student_id": `${action.data.studentId}`,
        "group_id": action.data.groupId,
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            yield put(UserAddGroupReducer.removeStudentFromGroupSuccess(MainDecode, action));
            createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserAddGroupReducer.removeStudentFromGroupFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserAddGroupReducer.removeStudentFromGroupFailure(false));
    }
}
// ----------remove Student From Group Request-----


// ----------Rename Group Request-----
function* renameGroupRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = RenameGroup_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": `${data.cc_user_id}`,
        // "device_udid": sessionStorage.getItem('UDID'),
        "group_name": action.data.groupName,
        "group_id": action.data.groupId,
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {

            yield put(UserAddGroupReducer.renameGroupSuccess(MainDecode, action));
            createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserAddGroupReducer.renameGroupFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserAddGroupReducer.renameGroupFailure(false));
    }
}
// ----------Rename Group Request-----

// ----------create Group Request-----
function* createGroupRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = CreateGroup_API;
    let students = [...new Set(action.data.student_ids)];
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": `${data.cc_user_id}`,
        // "device_udid": sessionStorage.getItem('UDID'),
        "group_class_id": `${action.data.group_class_id}`,
        "student_ids": students.toString(),
        "group_name": action.data.group_name,
        "list_type": "mutiple_student",
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            yield put(UserAddGroupReducer.createGroupSuccess(MainDecode, action));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserAddGroupReducer.createGroupFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserAddGroupReducer.createGroupFailure(false));
    }
}
// ----------create Group Request-----
