import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import ClassRubricsDashboard from './ClassRubricsDashboard';
import {
    getRubricList,
    addRubric,
    viewRubricDetails,
    deleteCriteria,
    viewStudentsAssessmentDetails,
    saveAssessment,
    deleteRubric,
    forwardRubricsReport

} from 'Actions/ClassAction/ClassRubricsAction';
import { getClassList } from 'Actions/ClassAction/classAction';

const ClassRubricsIndex = (props) => {
    return (
        <div>
            <ClassRubricsDashboard  {...props} />
        </div>
    )
}


const mapStateToProps = state => ({
    state: state,
    classeslist: state.class,

    getRubricListState: state.classRubrics.Get_Rubric_List,
    addNewRubricState: state.classRubrics.Add_New_Rubrics,
    viewRubricDetailsState: state.classRubrics.View_Rubric_Details,
    deleteCriteriaState: state.classRubrics.Delete_Criteria,
    viewStudentsAssessmentDetailsState: state.classRubrics.View_Students_Assessment_Details,
    saveAssessmentState: state.classRubrics.Save_Assessment,
    deleteRubricState: state.classRubrics.Delete_Rubric,
    loader: state.UserClassGradebook.loader
});
const mapDispatchToProps = dispatch => {
    return {
        getClassList: (data) => dispatch(getClassList(data)),
        getRubricList: (data) => dispatch(getRubricList(data)),
        addRubric: (data) => dispatch(addRubric(data)),
        viewRubricDetails: (data) => dispatch(viewRubricDetails(data)),
        deleteCriteria: (data) => dispatch(deleteCriteria(data)),
        viewStudentsAssessmentDetails: (data) => dispatch(viewStudentsAssessmentDetails(data)),
        saveAssessment: (data) => dispatch(saveAssessment(data)),
        deleteRubric: (data) => dispatch(deleteRubric(data)),
        forwardRubricsReport: (data) => dispatch(forwardRubricsReport(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    ClassRubricsIndex
);
