import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SubscriptionsPlans from '../SubscriptionsPlansComponent/SubscriptionsPlans';
import './SubscriptionsDashboard.scss'


const SubscriptionsDashboard = (props) => {
    const { getSubscriptionPlanRequest, Plans } = props
    useEffect(() => {
        getSubscriptionPlanRequest()
    }, [getSubscriptionPlanRequest]);

    return (
        <div className='subscriptionsContainer'>
            <Card className='cardOfSubscriptions'>
                <p className='subscriptionsHeading'>Subscriptions</p>
                <div className='blueBorder'></div>
                <Card className='cardOfSubscriptionsPlan'>
                    <p className='descriptionText'>Chronicle Cloud covers teachers with 5 MB of free cloud storage or 5 free multimedia notes. However, should you wish to expand this amount, premium plans are available to affordably meet any of your potential storage needs.</p>
                    <p className='descriptionText'>Our simple, no-nonsense subscription plans can be easily managed, enabling you to continue with what you do best 一 teach!</p>
                    <div className='cardOfPlan'>
                        <Row>
                            {Plans?.map((item, index) => (
                                <Col md={4} lg={4} key={index}>
                                    <SubscriptionsPlans key={index} item={item} history={props.history} />
                                </Col>))}
                        </Row>
                    </div>

                </Card>

            </Card>
        </div>
    )
}

export default SubscriptionsDashboard