import React from 'react';
import { connect } from 'react-redux';
import ClassNoteAdd from './ClassNoteAdd'
import { getClassNoteRequest } from 'Actions/NoteAction/classNoteAction';
import { getSpeedNote, getClassTag   } from 'Actions/ClassAction/classTagAction';
import { addGroupNote, editGroupNote } from 'Actions/NoteAction/studentGroupNoteActions';
import { getNoteSpiralTag } from "Actions/ClassAction/ClassStandardsAction";


const ClassNoteAddIndex = (props) => {
    return (
        <ClassNoteAdd {...props} />
    )
}

const mapStateToProps = state => ({
    classTag: state.classTag,
    getTagLoader: state.classTag.loader,
    notesData: state.studentNote,
    speedTagloader: state.classTag.speedTagloader,
    upgradePopUp: state.groupNote.Add_Group_Note
});

const mapDispatchToProps = dispatch => {
    return {
        getClassNoteRequest: (data) => dispatch(getClassNoteRequest(data)),
        addGroupNote: (data, funObje) => dispatch(addGroupNote(data, funObje)),
        editGroupNote: (data, funObje) => dispatch(editGroupNote(data, funObje)),
        getClassTag: (data) => dispatch(getClassTag(data)),
        getSpeedNote: (data) => dispatch(getSpeedNote(data)),
        getNoteSpiralTag: (data) => dispatch(getNoteSpiralTag(data)),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    ClassNoteAddIndex
);