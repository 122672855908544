import {
  GET_CLASS_LIST,
  GET_CLASS_LIST_SUCCESS,
  GET_CLASS_LIST_FAILURE,
  ADD_CLASS,
  ADD_CLASS_SUCCESS,
  ADD_CLASS_FAILURE,
  EDIT_CLASS,
  EDIT_CLASS_SUCCESS,
  EDIT_CLASS_FAILURE,
  DELETE_CLASS,
  DELETE_CLASS_SUCCESS,
  DELETE_CLASS_FAILURE,
  EDIT_CLASS_COLOR_WHEEL,
  EDIT_CLASS_COLOR_WHEEL_SUCCESS,
  EDIT_CLASS_COLOR_WHEEL_FAILURE,
  SET_CLASS_SIDEBAR_OPEN,
  SEARCH_CLASS,
  SEARCH_CLASS_SUCCESS,
  SEARCH_CLASS_FAILURE,
  SET_CLASS_STATE,
  SET_CLASS_LESSON_PLAN_STATE
} from "../constants";

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */

// -----------Get ClassList-------------
export function getClassList(data) {
  return {
    type: GET_CLASS_LIST,
    data,
  };
}

export function getClassListSuccess(data) {
  return {
    type: GET_CLASS_LIST_SUCCESS,
    data,
  };
}

export function getClassListFailure(data) {
  return {
    type: GET_CLASS_LIST_FAILURE,
    data,
  };
}

// -----------Add Class------------
export function addClass(data) {
  return {
    type: ADD_CLASS,
    data,
  };
}

export function addClassSuccess(data) {
  return {
    type: ADD_CLASS_SUCCESS,
    data,
  };
}

export function addClassFailure(data) {
  return {
    type: ADD_CLASS_FAILURE,
    data,
  };
}

// -----------Edit Class------------
export function editClass(data) {
  return {
    type: EDIT_CLASS,
    data,
  };
}

export function editClassSuccess(data) {
  return {
    type: EDIT_CLASS_SUCCESS,
    data,
  };
}

export function editClassFailure(data) {
  return {
    type: EDIT_CLASS_FAILURE,
    data,
  };
}

// -----------Delete Class------------
export function deleteClass(data) {
  return {
    type: DELETE_CLASS,
    data,
  };
}

export function deleteClassSuccess(data) {
  return {
    type: DELETE_CLASS_SUCCESS,
    data,
  };
}

export function deleteClassFailure(data) {
  return {
    type: DELETE_CLASS_FAILURE,
    data,
  };
}

// -----------Edit Class Color Wheel------------
export function editClassColorWheel(data) {
  return {
    type: EDIT_CLASS_COLOR_WHEEL,
    data,
  };
}

export function editClassColorWheelSuccess(data) {
  return {
    type: EDIT_CLASS_COLOR_WHEEL_SUCCESS,
    data,
  };
}

export function editClassColorWheelFailure(data) {
  return {
    type: EDIT_CLASS_COLOR_WHEEL_FAILURE,
    data,
  };
}

export function setClassSidebarOpen(data) {
  return {
    type: SET_CLASS_SIDEBAR_OPEN,
    data,
  };
}

//---------Search Class---------------------

export function searchClass(data) {
  return {
    type: SEARCH_CLASS,
    data,
  };
}

export function searchClassSuccess(data) {
  return {
    type: SEARCH_CLASS_SUCCESS,
    data,
  };
}

export function searchClassFailure(data) {
  return {
    type: SEARCH_CLASS_FAILURE,
    data,
  };
}
export function setClassState(data) {
  return {
    type: SET_CLASS_STATE,
    data,
  };
}

export function setClassLessonPlanState(data) {
  return {
    type: SET_CLASS_LESSON_PLAN_STATE,
    data,
  };
}
