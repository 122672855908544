

// export default mySaga;

import { all } from "redux-saga/effects";

// ------Auth Module-------
import { userLoginModuleSaga } from "./AuthModuleSaga/loginSaga";
import { userForgotModuleSaga } from "./AuthModuleSaga/forgotSaga";
import { userRegisterModuleSaga } from "./AuthModuleSaga/registerSaga";
import { userVerifyModuleSaga } from "./AuthModuleSaga/VerifySaga";
import { userPlaceSearchModuleSaga } from "./AuthModuleSaga/placeSearchSaga";
// ------Auth Module-------

// ------Academic Module-------
import { userAcademicModuleSaga } from "./AcademicModuleSaga/academicSaga";
// ------Academic Module-------

// ------Academic Module-------
import { userProfileModuleSaga } from "./ProfileModuleSaga/profileSaga";
// ------Academic Module-------

// ------Roster Module-------
import { userRosterModuleSaga } from "./RosterModuleSaga/rosterSaga";
import { userRosterProfileModuleSaga } from "./RosterModuleSaga/rosterProfileSaga";
import { userRosterEnrolledModuleSaga } from "./RosterModuleSaga/rosterEnrolledSaga";
// ------Roster Module-------

// ------Class Module-------
import { userClassModuleSaga } from "./ClassModuleSaga/classSaga";
import { userClassRosterModuleSaga } from "./ClassModuleSaga/classRosterSaga";
import { userClassSelectRosterModuleSaga } from "./ClassModuleSaga/classSelectRosterSaga";
import { userClassTagModuleSaga } from "./ClassModuleSaga/classTagSaga";
import { userClassAttModuleSaga } from "./ClassModuleSaga/classAttendanceSaga";
import { userClassLessonPlanModuleSaga } from "./ClassModuleSaga/classLessonPlanSaga";
import { userRubricsModuleSaga } from "./ClassModuleSaga/classRubricsSaga";
import { userClassCoreStandardModuleSaga } from "./ClassModuleSaga/classStandardSaga";
import { userClassShareModuleSaga } from "./ClassModuleSaga/classShareSaga";
// ------Class Module-------


// ------Note Module-------
import { userStudentNoteModuleSaga } from "./NoteModuleSaga/studentNoteSaga";
import { userGroupNoteModuleSaga } from "./NoteModuleSaga/groupNoteSaga";
import { userAddGroupModuleSaga } from "./NoteModuleSaga/addGroupSaga";
import { userClassNoteModuleSaga } from "./NoteModuleSaga/classNoteSaga";
// ------Note Module-------

// ---------FormativeAssessment Module --------
import { userFormativeAssessmentModuleSaga } from "./FormativeAssessmentModuleSaga/FormativeAssessmentSaga";
// ---------FormativeAssessment Module --------


// ---------Class Gradebook Module --------
import { userClassGradebookModuleSaga } from "./GradebookModuleSaga/classGradebookSaga";
import { userStudentGradebookModuleSaga } from "./GradebookModuleSaga/studentGradebookSaga";
// ---------Class Gradebook Module --------

// ---------User Core Standard Module --------
import { userCoreStandardModuleSaga } from "./CoreStandardModuleSaga/coreStandardSaga";
// ---------User Core Standard Module --------

// ---------User Footer Module --------
import { userFooterModuleSaga } from "./FooterModuleSaga/footerSaga";
// ---------User Footer Module --------


// ---------User Footer Module --------
import { userBlogModuleSaga } from "./BlogModuleSaga/blogSaga";
// ---------User Footer Module --------

// ---------User Data Usage Module --------
import { userDataUsageModuleSaga } from "./DataUsageModuleSaga/dataUsageSaga";
// ---------User Data Usage Module --------


// ---------Subscription Plan Module --------
import { userSubscriptionPlanSaga } from "./SubscriptionPlanModuleSaga/SubscriptionPlanSaga";
// ---------Subscription Plan Module --------

// ---------setting Module --------
import { settingSaga } from "./SettingsModuleSaga/SettingSaga";
// ---------setting Plan Module --------

// ---------Notification Module --------
import { notificationSaga } from "./NotificationModuleSaga/notificationSaga";
// ---------Notification Plan Module --------

// ---------ContactUs Plan Module --------
import { userContactUsModuleSaga } from "./ContactUsModuleSaga/contactUsSaga";
// ---------ContactUs Plan Module --------



// ---------Pay Pal Payment Module --------
import { userPayPalPaymentModuleSaga } from "./PayPalPaymentModuleSaga/PayPalPaymentSaga";
// ---------Pay Pal Payment Module --------




export function* SagaMain() {
  yield all([

    // ------Auth Module-------
    userLoginModuleSaga(),
    userForgotModuleSaga(),
    userRegisterModuleSaga(),
    userVerifyModuleSaga(),
    userPlaceSearchModuleSaga(),
    // ------Auth Module-------

    // ------Academic Module-------
    userAcademicModuleSaga(),
    // ------Academic Module-------

    // ------Academic Module-------
    userProfileModuleSaga(),
    // ------Academic Module-------

    // ------Roster Module-------
    userRosterModuleSaga(),
    userRosterProfileModuleSaga(),
    userRosterEnrolledModuleSaga(),
    // ------Roster Module-------

    // ------Class Module-------
    userClassModuleSaga(),
    userClassRosterModuleSaga(),
    userClassSelectRosterModuleSaga(),
    userClassTagModuleSaga(),
    userClassAttModuleSaga(),
    userClassLessonPlanModuleSaga(),
    userRubricsModuleSaga(),
    userClassCoreStandardModuleSaga(),
    userClassShareModuleSaga(),
    // ------Class Module-------

    // ------Note Module-------
    userStudentNoteModuleSaga(),
    userGroupNoteModuleSaga(),
    userClassNoteModuleSaga(),
    userAddGroupModuleSaga(),
    // ------Note Module-------

    // ---------FormativeAssessment Module --------
    userFormativeAssessmentModuleSaga(),
    // ---------FormativeAssessment Module --------

    // ---------Class Gradebook Module --------
    userClassGradebookModuleSaga(),
    userStudentGradebookModuleSaga(),
    // ---------Class Gradebook Module --------

    // ---------User Core Standard Module --------
    userCoreStandardModuleSaga(),
    // ---------User Core Standard Module --------


    // ---------User Footer Module --------
    userFooterModuleSaga(),
    // ---------User Footer Module --------

    // ---------User Blog Module --------
    userBlogModuleSaga(),
    // ---------User Blog Module --------

    // ---------Data Usage Module --------
    userDataUsageModuleSaga(),
    // ---------Data Usage Module --------

    // ---------Pay Pal Payment Module --------
    userPayPalPaymentModuleSaga(),
    // ---------Pay Pal Payment Module --------

    // ---------Subscription Plan Module --------
    userSubscriptionPlanSaga(),
    // ---------Subscription Plan Module --------

    // ---------setting Module --------
    settingSaga(),
    // --------setting Module --------


    // ---------Notification Module --------
    notificationSaga(),
    // ---------Notification Plan Module --------

    // ---------Contact Us Module --------
    userContactUsModuleSaga(),
    // ---------Contact Us Module --------



  ]);
}
