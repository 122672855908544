import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif';
import downBlackArrow from 'Assets/LessonPlanModule/downBlackArrow.svg';
import cloudGreen from 'Assets/LessonPlanModule/cloudGreen.png';
import cloudYellow from 'Assets/LessonPlanModule/cloudYellow.png';
import cloudRed from 'Assets/LessonPlanModule/cloudRed.png';
import { createNotification } from 'Config/notificationtoast';
import deSelectedTickIcon from 'Assets/LessonPlanModule/deSelectedTickIcon.svg';
import iconSearch from "Assets/RosterModule/icon-search.png";
import cancelBlack from 'Assets/RosterModule/cancelBlack.png'
import closeIcon from 'Assets/NoteModule/close.svg';
import tick from 'Assets/NoteModule/tick.svg';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import CommonTextField from "Components/CommonTextField/CommonTextField";
import { getFormattedDate } from "Utils/Helper";
import EditRatingImport from "./EditRatingImport";
import './ImportLessonPlan.scss';

const ImportLessonPlan = (props) => {
    const {
        classDetail,
        classId,
        closeImportPage,
        viewLessonPlanDetailsState,
        getViewImportLessonPlanList,
        getImportTopicList,
        saveImportLessonPlanRating,
        importLessonPlan,
        viewLessonPlanDetails,
        getViewImportLessonPlanListState,
        getImportTopicListState,
        saveImportLessonPlanRatingState,
        importLessonPlanState,
        state,
        history,
        setShowTeacherListComp,
        setImportViewPage
    } = props;
    const { loader } = state.classLessonPlan;
    const [searchText, setSearchText] = useState("")
    const [plansDropdownOpen, setPlansDropdownOpen] = useState(false);
    const [plansDropdownValue, setPlansDropdownValue] = useState("Shared Plans");
    const [classDropdownOpen, setClassDropdownOpen] = useState(false);
    const [classDropdownValue, setClassDropdownValue] = useState("");
    const [selectedClassId, setSelectedClassId] = useState("");
    const [topicDropdownOpen, setTopicDropdownOpen] = useState(false);
    const [topicDropdownValue, setTopicDropdownValue] = useState("");
    const [showEditRatingPage, setShowEditRatingPage] = useState(false);
    const [selectedStudentsLessonsForImport, setSelectedStudentsLessonsForImport] = useState([]);
    const [mainViewImportLessonPlanListState, setMainViewImportLessonPlanListState] = useState([]);
    const [selectedLessonPlan, setSelectedLessonPlan] = useState("");
    const [importCount, setImportCount] = useState("");
    const [totalRating, setTotalRating] = useState("");
    const [ratingByTotalUsers, setRatingByTotalUsers] = useState("");
    const [isImported, setIsImported] = useState("");
    const signDate = sessionStorage.getItem('separatorFormat')
    const formatDate = sessionStorage.getItem('dateFormat');
    const [questionAnswers, setQuestionAnswers] = useState([]);
    const _ = require('lodash');
    const addNewLesson = ["addNewLesson", selectedStudentsLessonsForImport.length < 1 ? "opacityFade" : ""]
    const searchLesson = ["searchLesson", searchText === "" ? "opacityFade" : ""];

    useEffect(() => {
        setSelectedLessonPlan("");
        let apiData = {
            "class_id": classId,
            "sharing_type": plansDropdownValue === "All Plans" ? "all" :
                plansDropdownValue === "Shared Plans" ? "shared_plan" :
                    plansDropdownValue === "Public Plans" ? "public_plan" : "",
            "topic": "",
            "keyword": searchText,
        }
        getViewImportLessonPlanList(apiData);
    }, [])

    useEffect(() => {
        if (showEditRatingPage === false) {
            setQuestionAnswers([]);
        }
    }, [showEditRatingPage])

    useEffect(() => {
        setSelectedLessonPlan("");
        let apiData = {
            "class_id": classId,
            "sharing_type": plansDropdownValue === "All Plans" ? "all" :
                plansDropdownValue === "Shared Plans" ? "shared_plan" :
                    plansDropdownValue === "Public Plans" ? "public_plan" : "",
            "topic": "",
            "keyword": searchText,
        }
        getViewImportLessonPlanList(apiData);
    }, [plansDropdownValue])

    useEffect(() => {
        if (importLessonPlanState && importLessonPlanState.message === "Lesson Plan imported successfully") {
            setSelectedStudentsLessonsForImport([]);
            let apiData = {
                "class_id": classId,
                "sharing_type": plansDropdownValue === "All Plans" ? "all" :
                    plansDropdownValue === "Shared Plans" ? "shared_plan" :
                        plansDropdownValue === "Public Plans" ? "public_plan" : "",
                "topic": "",
                "keyword": searchText,
            }
            getViewImportLessonPlanList(apiData);
        }
    }, [importLessonPlanState])

    useEffect(() => {
        if (saveImportLessonPlanRatingState && saveImportLessonPlanRatingState.message === "Lesson Plan rating saved successfully") {
            let apiData = {
                "class_id": classId,
                "sharing_type": plansDropdownValue === "All Plans" ? "all" :
                    plansDropdownValue === "Shared Plans" ? "shared_plan" :
                        plansDropdownValue === "Public Plans" ? "public_plan" : "",
                "topic": "",
                "keyword": searchText,
            }
            getViewImportLessonPlanList(apiData);
        }
    }, [saveImportLessonPlanRatingState])

    useEffect(() => {
        if (getViewImportLessonPlanListState) {
            setMainViewImportLessonPlanListState(getViewImportLessonPlanListState.data.importLessonPlanList);
        }
    }, [getViewImportLessonPlanListState])

    useEffect(() => {
        if (topicDropdownValue !== "") {
            let apiData = {
                "class_id": classId,
                "sharing_type": plansDropdownValue === "All Plans" ? "all" :
                    plansDropdownValue === "Shared Plans" ? "shared_plan" :
                        plansDropdownValue === "Public Plans" ? "public_plan" : "",
                "topic": topicDropdownValue,
                "keyword": searchText,
            }
            getViewImportLessonPlanList(apiData);
        }
    }, [topicDropdownValue])

    useEffect(() => {
        if (showEditRatingPage && selectedLessonPlan !== "") {
            mainViewImportLessonPlanListState && mainViewImportLessonPlanListState.map((val, index) => {
                let answerValue = { ...val }
                if (val.cc_lp_id === selectedLessonPlan) {
                    setTotalRating(val.rating)
                }
            })
        }
    }, [mainViewImportLessonPlanListState])

    useEffect(() => {
        if (classDropdownValue && selectedClassId !== "") {
            let apiData = {
                "class_id": selectedClassId,
                "history": history,
                "setShowTeacherListComp": setShowTeacherListComp,
                "setImportViewPage": setImportViewPage
            }
            getImportTopicList(apiData)
        }
    }, [classDropdownValue])

    const importSelectedPlans = () => {
        if (selectedStudentsLessonsForImport.length > 0) {
            let apiData = {
                "class_id": classDetail?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
                'lesson_plan_id': selectedStudentsLessonsForImport.join(","),
            }
            importLessonPlan(apiData)
        }
    }

    const closeImportPageView = () => {
        setSearchText("")
        setPlansDropdownOpen(false);
        setPlansDropdownValue("All Plans");
        setClassDropdownOpen(false);
        setClassDropdownValue("Class 1");
        setTopicDropdownOpen(false);
        setTopicDropdownValue("Topic");
        setSelectedStudentsLessonsForImport([]);
        setIsImported("");
        setShowEditRatingPage(false);
        setQuestionAnswers([]);
        closeImportPage();
    }

    // ---------Auto search  -------
    const handleSearchText = (event) => {
        setSearchText(event.target.value)
    }
    // ---------Auto search  -------

    const searchlessonPlan = () => {
        if (searchText.length >= 1) {
            let apiData = {
                "class_id": selectedClassId,
                "sharing_type": plansDropdownValue === "All Plans" ? "all" :
                    plansDropdownValue === "Shared Plans" ? "shared_plan" :
                        plansDropdownValue === "Public Plans" ? "public_plan" : "",
                "topic": topicDropdownValue,
                "keyword": searchText,
            }
            getViewImportLessonPlanList(apiData);
        }
    }

    const togglePlans = () => {
        setPlansDropdownOpen(!plansDropdownOpen);
    }

    const changePlansDropValue = (code) => {
        setPlansDropdownValue(code.currentTarget.textContent)
    }

    const toggleClass = () => {
        setClassDropdownOpen(!classDropdownOpen);
    }

    const changeClassDropValue = (code, a) => {
        setClassDropdownValue(code)
        setSelectedClassId(a)
    }

    const toggleTopic = () => {
        if (selectedClassId) {
            setTopicDropdownOpen(!topicDropdownOpen);
        } else {
            createNotification('warning', 'Please select class');
        }
    }

    const changeRating = (rate, value) => {
        const ratings = rate.toString();
        const apiData = {
            "lesson_plan_id": selectedLessonPlan,
            "rating": ratings
        }
        saveImportLessonPlanRating(apiData);
        let changedMainList = getViewImportLessonPlanListState && getViewImportLessonPlanListState.data.importLessonPlanList.map((val, index) => {
            let answerValue = { ...val }
            if (val.cc_lp_id === value) {
                const ratings = rate.toString();
                answerValue = { ...answerValue, rating: ratings }
            } else {
                answerValue = { ...answerValue }
            }
            return answerValue
        })
        setMainViewImportLessonPlanListState(changedMainList)
    }

    const changeTopicDropValue = (code) => {
        setTopicDropdownValue(code)
    }

    const openEditRatingComp = (value, id) => {
        setSelectedLessonPlan(value.cc_lp_id)
        setShowEditRatingPage(true);
        setImportCount(value.importCount);
        setTotalRating(value.rating);
        setRatingByTotalUsers(value.rating_by_total_user);
        setIsImported(value.is_imported)
    }
    const getData = (StudentId) => {
        if (selectedStudentsLessonsForImport.includes(StudentId)) {
            setSelectedStudentsLessonsForImport(selectedStudentsLessonsForImport.filter((id) => id !== StudentId))
        } else {
            setSelectedStudentsLessonsForImport([...selectedStudentsLessonsForImport, StudentId])
        }
    }
    return (
        <div className='importLessonContainer'>
            <div className='importLessonPlanMainDiv justify-content-between'>
                <div className='importLessonPlanDetailsMain'>
                    <div className='ClassBackDiv'
                        onClick={closeImportPageView}
                    >
                        {/* <img className='closeIconInModal' src={cancelBlack} alt='' /> */}
                        <div className='lessonCancelX'>
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                        </div>
                        <div className='lessonClassBactTextDiv'>
                        <p className='lessonClassBactText'>Import Plan</p>
                        </div>
                    </div>
                </div>
                {/* <div className='importLessonPlanDetailsMainTwo'>
                    <div className='lessonDetailTextDiv'>
                        <p className='lessonClassName'> Import Plan</p>
                    </div>
                </div> */}
                <div className='importButtonMarginTop' style={{width: 250, justifyContent : 'space-evenly'}}>
                    {!showEditRatingPage && 
                     <>     
                                   {/* <Button className={addNewLesson.join(' ')}
                        onClick={importSelectedPlans}
                    > */}
                       
                        {/* <span className='viewAttText'>Import</span> */}
                        <Button variant="default" className="action-btn d-flex flex-wrap align-items-center" onClick={() => {  closeImportPageView()  }}
                            style={{background : "#ea3b3b", color: '#fff', width: 100, height: 40, borderRadius: 8, textTransform : 'capitalize' }}
                            // disabled={quality_points == 0 ? true : false}
                            >
                                <img src={closeIcon} alt='' /> Cancel
                            </Button>
                            <Button style={
                                selectedStudentsLessonsForImport.length == 0 ?
                                 {cursor : "not-allowed", background : "#2ec428", color: '#fff', width: 100, height: 40, borderRadius: 8, textTransform : 'capitalize'} 
                                 : 
                                 {cursor : "pointer", background : "#2ec428", color: '#fff', width: 100, height: 40, borderRadius: 8, textTransform : 'capitalize'
                                 }} 
                                 variant="default" className={`action-btn d-flex flex-wrap align-items-center`}
                                onClick={() => {
                                    if(selectedStudentsLessonsForImport.length > 0) {
                                        importSelectedPlans()
                                    }
                                }}
                                disabled={selectedStudentsLessonsForImport.length > 0 ? false : true}
                                  >
                                <img src={tick} alt='' />&nbsp; Import
                            </Button>
                           
                    {/* </Button> */}
                    </>
                    
                    }
                    {showEditRatingPage && <Button className='addNewLesson'
                        onClick={() => { setShowEditRatingPage(false) }}
                    >
                        <i className='lessonBackIcons material-icons'>chevron_left</i>
                        <span className='viewAttTexts'>Back</span>
                    </Button>}
                </div>
            </div>
            {!showEditRatingPage && <div>
                <div className="searchDiv" style={{background : "#fff", padding : 15}}>
                    <div className="dropdownDiv">
                        <Dropdown isOpen={plansDropdownOpen} className="dropDiv" toggle={togglePlans}>
                            <DropdownToggle className="dropbtn d-flex flex-wrap justify-content-between" style={{width : 180}}>
                                <div>
                                {plansDropdownValue}
                                </div>
                                <div>
                                <img src={downBlackArrow} className='arrowBlackIcon' height="17" alt="CoolBrand" />
                                </div>
                               
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={changePlansDropValue} dropDownValue="all">All Plans</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={changePlansDropValue} dropDownValue="shared_plan">Shared Plans</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={changePlansDropValue} dropDownValue="public_plan">Public Plans</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className="totalPlanSearchDiv">
                        <div style={{ width: '90%' }}>
                        <div
                className="searchFilter-RD searchClassFilter-RD"
                onClick={() => {
                  // this.setState({
                  //     // searchBar: true,
                  //     // searchText: ''
                  // })
                }}
              >
                <img className="searchFilterIcon-RD" src={iconSearch} alt="" />
                <CommonTextField
                  margin="normal"
                  // variant="outlined"
                  type="FirstName"
                  value={state.searchText}
                  onChange={handleSearchText}
                //   onChange={(e) => {
                //     handleSearchText(e);
                //     setSearchText(e.target.value);
                //   }}
                  name="searchText"
                  className="searchFilterInputBox-RD"
                  placeholder="Enter Keywords…"
                />
              </div>
                            {/* <div className="searchStudentdiv">
                                <div className="input-group-prepend border-0">
                                    <button id="button-addon4" type="button" className="btn btn-link text-info searchIconDiv"><i className="fa fa-search searchIcon"></i></button>
                                </div>
                                <input
                                    autoFocus
                                    margin="normal"
                                    type='text'
                                    value={state.searchText}
                                    onChange={handleSearchText}
                                    name="searchText"
                                    className='searchStudent'
                                    placeholder="Enter Keywords"
                                ></input>
                            </div> */}
                        </div>
                    </div>

                    <div className='searchBtnDiv'>
                        <Button className={searchLesson.join(' ')}
                            onClick={searchlessonPlan}
                        >
                            <span className='viewAttText'>Search</span>
                        </Button>
                    </div>
                </div>
                {!showEditRatingPage && plansDropdownValue === "Public Plans" || plansDropdownValue === "Shared Plans" ?
                    <div>
                        <div className='classTopicDivMain'>
                            <div className='nameLinediv'>
                                <p className='titleName'>
                                    {plansDropdownValue}
                                </p>
                                <hr className="hrLine"></hr>
                            </div>
                            <div className='dropdownsDiv'>
                                <p className='filterTitle'>Filter:</p>
                                <div className='classDrpDwn'>
                                    <Dropdown isOpen={classDropdownOpen} className="dropDiv" toggle={toggleClass}>
                                        <DropdownToggle className="dropClassBtn d-flex flex-wrap justify-content-between">
                                            <div>
                                            {classDropdownValue !== "" ? _.truncate(classDropdownValue, {
                                                'length': 10,
                                                'omission': '...'
                                            }) : <span style={{color : "#ccc"}}>Class</span>}
                                            </div>
                                            <div>
                                            <img src={downBlackArrow} className='arrowBlackIcon' height="17" alt="CoolBrand" />
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {getViewImportLessonPlanListState && mainViewImportLessonPlanListState && getViewImportLessonPlanListState.data.classList.map((value, i) => {
                                                return (
                                                    <div key={i}>
                                                        <DropdownItem onClick={() => { changeClassDropValue(value.cc_class_name, value.cc_class_id) }}>
                                                            {_.truncate(value.cc_class_name, {
                                                                'length': 15,
                                                                'omission': '...'
                                                            })}
                                                        </DropdownItem>
                                                        <DropdownItem divider />
                                                    </div>)
                                            })}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                                <div className='topicDrpDwn'>
                                    <Dropdown isOpen={topicDropdownOpen} className="dropDiv" toggle={toggleTopic}>
                                        <DropdownToggle className="dropClassBtn d-flex flex-wrap justify-content-between">
                                            <div>
                                            {topicDropdownValue !== "" ? _.truncate(topicDropdownValue, {
                                                'length': 10,
                                                'omission': '...'
                                            }) : <span style={{color : "#ccc"}}>Topic</span>}
                                            </div>
                                            <div>
                                            <img src={downBlackArrow} className='arrowBlackIcon' height="17" alt="CoolBrand" />
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {selectedClassId && getImportTopicListState && getImportTopicListState.data.map((value, i) => {
                                                return (<div key={i}>
                                                    <DropdownItem onClick={() => { changeTopicDropValue(value.cc_lp_topic) }}>
                                                        {_.truncate(value.cc_lp_topic, {
                                                            'length': 15,
                                                            'omission': '...'
                                                        })}
                                                    </DropdownItem>
                                                    <DropdownItem divider />
                                                </div>)
                                            })}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div>
                            {loader && <img src={loaderImag} alt='' className='loaderIconCss' />}
                        </div>
                        <div className='classCardDivMain'>
                            {getViewImportLessonPlanListState && mainViewImportLessonPlanListState && mainViewImportLessonPlanListState.map((item, k) => {

                                return (
                                    <div className='classCardsDiv' key={k}>
                                        <div className='classDetailsCard'>
                                            <div className='textLeft' onClick={() => { openEditRatingComp(item) }}>
                                                <p className='lessonTitle'>{item.cc_lp_title}</p>
                                                <p className='classGradeAndSectionText'>{item.cc_lp_topic}</p>
                                                <div className='headingTitles'>
                                                    <p className='attTitleName'> Created by: </p>
                                                    <p className='head'>{item.created_by_name} <span className="middleDot"></span>{getFormattedDate(item.cc_lp_created_at, formatDate, signDate)}</p>
                                                </div>
                                            </div>
                                            <div className='textRight'>
                                                {item.is_imported === 1 ? <img src={deSelectedTickIcon} alt="" width="28" height="18" /> : ""}
                                                {item.is_imported === 0 ? <span className='checBoxDiv' onClick={() => { getData(item.cc_lp_id) }}>
                                                    {selectedStudentsLessonsForImport.includes(item.cc_lp_id) === true ?
                                                        <i className="checkBoxfilter material-icons">{"radio_button_checked"}</i>
                                                        :
                                                        <i className="checkBoxfilter material-icons">{"radio_button_unchecked"}</i>
                                                    }
                                                </span> : ""}
                                            </div>
                                        </div>
                                        <div className='classTimeline'>
                                            <div className='headingTitles'>
                                                <p className='attTitleName'> Import: </p>
                                                <p className='head'>{item.importCount} times</p>
                                            </div>
                                            <div className='headingTitlesRight'>
                                                <p className='attTitleName'> Rating: </p>
                                                <div className='ratingDivImg'>
                                                    {Number(item.rating) >= 3.5 && <img src={cloudGreen} alt="" width="45" height="27" />}
                                                    {Number(item.rating) < 3.5 && Number(item.rating) >= 2 && <img src={cloudYellow} alt="" width="45" height="27" />}
                                                    {Number(item.rating) < 2 && <img src={cloudRed} alt="" width="45" height="27" />}
                                                    <p className='overImgText'>{(Number(item.rating)).toFixed(1)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                            })}
                        </div></div> : ""}
            </div>}

            {!showEditRatingPage && plansDropdownValue === "All Plans" && <div><div className='classTopicDivMain'>
                <div className='nameLinediv'>
                    <p className='titleName'>
                        Shared Plans
                    </p>
                    <hr className="hrLine"></hr>
                </div>
                <div className='dropdownsDiv'>
                    <p className='filterTitle'>Filter:</p>
                    <div className='classDrpDwn'>
                        <Dropdown isOpen={classDropdownOpen} className="dropDiv" toggle={toggleClass}>
                            <DropdownToggle className="dropClassBtn d-flex flex-wrap justify-content-between">
                                <div>
                                {classDropdownValue !== "" ? _.truncate(classDropdownValue, {
                                    'length': 10,
                                    'omission': '...'
                                }) : <span style={{color : "#ccc"}}>Class</span>}
                                </div>
                                <div>
                                <img src={downBlackArrow} className='arrowBlackIcon' height="17" alt="CoolBrand" />
                                </div>
                            </DropdownToggle>
                            <DropdownMenu>
                                {getViewImportLessonPlanListState && mainViewImportLessonPlanListState && getViewImportLessonPlanListState.data.classList.map((value, i) => {
                                    return (
                                        <div key={i}>
                                            <DropdownItem onClick={() => { changeClassDropValue(value.cc_class_name, value.cc_class_id) }}>
                                                {_.truncate(value.cc_class_name, {
                                                    'length': 15,
                                                    'omission': '...'
                                                })}
                                            </DropdownItem>
                                            <DropdownItem divider />
                                        </div>)
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className='topicDrpDwn'>
                        <Dropdown isOpen={topicDropdownOpen} className="dropDiv" toggle={toggleTopic}>
                            <DropdownToggle className="dropClassBtn d-flex flex-wrap justify-content-between">
                                <div>
                                {topicDropdownValue !== "" ? _.truncate(topicDropdownValue, {
                                    'length': 10,
                                    'omission': '...'
                                }) : <span style={{color : "#ccc"}}>Topic</span>}
                                </div>
                                <div>
                                <img src={downBlackArrow} className='arrowBlackIcon' height="17" alt="CoolBrand" />
                                </div>
                            </DropdownToggle>
                            <DropdownMenu>
                                {selectedClassId && getImportTopicListState && getImportTopicListState.data.map((value, i) => {
                                    return (<div key={i}>
                                        <DropdownItem onClick={() => { changeTopicDropValue(value.cc_lp_topic) }}>
                                            {_.truncate(value.cc_lp_topic, {
                                                'length': 15,
                                                'omission': '...'
                                            })}
                                        </DropdownItem>
                                        <DropdownItem divider />
                                    </div>)
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </div>
                <div>
                    {loader && <img src={loaderImag} alt='' className='loaderIconCss' />}
                </div>
                <div className='classCardDivMain'>
                    {getViewImportLessonPlanListState && mainViewImportLessonPlanListState && mainViewImportLessonPlanListState.map((item, k) => {
                        return (item.cc_lp_sharing_type === "shared_plan" ? <div className='classCardsDiv' key={k}>
                            <div className='classDetailsCard'>
                                <div className='textLeft' onClick={() => { openEditRatingComp(item) }}>
                                    <p className='lessonTitle'>{item.cc_lp_title}</p>
                                    <p className='classGradeAndSectionText'>{item.cc_lp_topic}</p>
                                    <div className='headingTitles'>
                                        <p className='attTitleName'> Created by: </p>
                                        <p className='head'>{item.created_by_name} <span className="middleDot"></span>{getFormattedDate(item.cc_lp_created_at, formatDate, signDate)}</p>
                                    </div>
                                </div>
                                <div className='textRight'>
                                    {item.is_imported === 1 ? <img src={deSelectedTickIcon} alt="" width="28" height="18" /> : ""}
                                    {item.is_imported === 0 ? <span className='checBoxDiv' onClick={() => { getData(item.cc_lp_id) }}>
                                        {selectedStudentsLessonsForImport.includes(item.cc_lp_id) === true ?
                                            <i className="checkBoxfilter material-icons">{"radio_button_checked"}</i>
                                            :
                                            <i className="checkBoxfilter material-icons">{"radio_button_unchecked"}</i>
                                        }
                                    </span> : ""}
                                </div>
                            </div>
                            <div className='classTimeline'>
                                <div className='headingTitles'>
                                    <p className='attTitleName'> Import: </p>
                                    <p className='head'>{item.importCount} times</p>
                                </div>
                                <div className='headingTitlesRight'>
                                    <p className='attTitleName'> Rating: </p>
                                    <div className='ratingDivImg'>
                                        {Number(item.rating) >= 3.5 && <img src={cloudGreen} alt="" width="45" height="27" />}
                                        {Number(item.rating) < 3.5 && Number(item.rating) >= 2 && <img src={cloudYellow} alt="" width="45" height="27" />}
                                        {Number(item.rating) < 2 && <img src={cloudRed} alt="" width="45" height="27" />}
                                        <p className='overImgText'>{(Number(item.rating)).toFixed(1)}</p>
                                    </div>
                                </div>
                            </div>
                        </div> : "")
                    })}
                </div>
            </div>}
            {!showEditRatingPage && plansDropdownValue === "All Plans" && <div><div className='classTopicDivMain'>
                <div className='nameLinediv'>
                    <p className='titleName'>
                        Public Plans
                    </p>
                    <hr className="hrLine"></hr>
                </div>
            </div>
                <div>
                    {loader && <img src={loaderImag} alt='' className='loaderIconCss' />}
                </div>
                <div className='classCardDivMain'>
                    {getViewImportLessonPlanListState && mainViewImportLessonPlanListState && mainViewImportLessonPlanListState.map((item, k) => {
                        return (item.cc_lp_sharing_type === "public_plan" ? <div className='classCardsDiv' key={k}>
                            <div className='classDetailsCard'>
                                <div className='textLeft' onClick={() => { openEditRatingComp(item) }}>
                                    <p className='lessonTitle'>{item.cc_lp_title}</p>
                                    <p className='classGradeAndSectionText'>{item.cc_lp_topic}</p>
                                    <div className='headingTitles'>
                                        <p className='attTitleName'> Created by: </p>
                                        <p className='head'>{item.created_by_name} <span className="middleDot"></span>{getFormattedDate(item.cc_lp_created_at, formatDate, signDate)}</p>
                                    </div>
                                </div>
                                <div className='textRight'>
                                    {item.is_imported === 1 ? <img src={deSelectedTickIcon} alt="" width="28" height="18" /> : ""}
                                    {item.is_imported === 0 ? <span className='checBoxDiv' onClick={() => { getData(item.cc_lp_id) }}>
                                        {selectedStudentsLessonsForImport.includes(item.cc_lp_id) === true ?
                                            <i className="checkBoxfilter material-icons">{"radio_button_checked"}</i>
                                            :
                                            <i className="checkBoxfilter material-icons">{"radio_button_unchecked"}</i>
                                        }
                                    </span> : ""}
                                </div>
                            </div>
                            <div className='classTimeline'>
                                <div className='headingTitles'>
                                    <p className='attTitleName'> Import: </p>
                                    <p className='head'>{item.importCount} times</p>
                                </div>
                                <div className='headingTitlesRight'>
                                    <p className='attTitleName'> Rating: </p>
                                    <div className='ratingDivImg'>
                                        {Number(item.rating) >= 3.5 && <img src={cloudGreen} alt="" width="45" height="27" />}
                                        {Number(item.rating) < 3.5 && Number(item.rating) >= 2 && <img src={cloudYellow} alt="" width="45" height="27" />}
                                        {Number(item.rating) < 2 && <img src={cloudRed} alt="" width="45" height="27" />}
                                        <p className='overImgText'>{(Number(item.rating)).toFixed(1)}</p>
                                    </div>
                                </div>
                            </div>
                        </div> : "")
                    })}
                </div>
            </div>}
            {showEditRatingPage && <div>
                <EditRatingImport
                    loader={loader}
                    classDetail={classDetail}
                    viewLessonPlanDetailsState={viewLessonPlanDetailsState}
                    viewLessonPlanDetails={viewLessonPlanDetails}
                    lessonPlanId={selectedLessonPlan}
                    getData={getData}
                    changeRating={changeRating}
                    isImported={isImported}
                    selectedStudentsLessonsForImport={selectedStudentsLessonsForImport}
                    importCount={importCount}
                    totalRating={totalRating}
                    ratingByTotalUsers={ratingByTotalUsers}
                    questionAnswers={questionAnswers}
                    setQuestionAnswers={setQuestionAnswers}
                />

            </div>
            }
        </div>
    );
}

export default ImportLessonPlan
