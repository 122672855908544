import { call, takeLatest } from 'redux-saga/effects';
import { createNotification } from 'Config/notificationtoast';
import {
    SEND_SUBSCRIBE_REQUEST,
} from 'Actions/constants';
import { chroniclecloud_code, BASE_URL_FOR_NOTIFY_EMAIL } from 'Config/Constant';
import request from 'Config/request';
import { logOut } from 'Utils/Helper';


export function* userFooterModuleSaga() {
    yield takeLatest(SEND_SUBSCRIBE_REQUEST, sendSubscribeRequest);

}

// ---------- Get Class Gradebook-----
function* sendSubscribeRequest(action) {
    const URL = BASE_URL_FOR_NOTIFY_EMAIL;
    const Body = {
        "chroniclecloud_code": chroniclecloud_code,
        "email": action.data.email
    }

    var jdata = JSON.stringify(Body);
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": btoa(jdata) },
        });
        const MainDecode = JSON.parse(atob(response));
        if (MainDecode.status === 'Success') {
            createNotification('success', MainDecode.message);

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');

    }
}
// ---------- Get Class Gradebook------
