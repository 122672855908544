import { call, takeLatest, put } from 'redux-saga/effects';
import { createNotification } from 'Config/notificationtoast';
import userBlogReducer from 'Redux/Reducers/BlogReducer/blogReducer';
import { BASE_URL_FOR_BLOG } from "Config/Constant";
import { GET_BLOG_LIST_REQUEST } from 'Actions/constants';
import request from 'Config/request';


export function* userBlogModuleSaga() {
    yield takeLatest(GET_BLOG_LIST_REQUEST, getBlogListRequest);

}

// ---------- Get Blog -----
function* getBlogListRequest() {
    const URL = BASE_URL_FOR_BLOG;
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
        });
        yield put(userBlogReducer.getBlogListSuccess(response));
    } catch (error) {
        yield put(userBlogReducer.getBlogListFailure());
        createNotification('warning', 'Something went wrong');

    }
}
// ---------- Get Blog------
