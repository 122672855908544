import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({

    addGroupNote: ['data'],
    addGroupNoteSuccess: ['data'],
    addGroupNoteFailure: ['error'],

    editGroupNote: ['data'],
    editGroupNoteSuccess: ['data'],
    editGroupNoteFailure: ['error'],


    getGroupNote: ['data'],
    getGroupNoteSuccess: ['data'],
    getGroupNoteFailure: ['error'],


    upgradePopUpOpen: ['data'],
    deleteGroupNote: ['data'],
    deleteGroupNoteSuccess: ['data'],
    deleteGroupNoteFailure: ['error'],

})

export const UserGroupNoteReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    Add_Group_Note: null,
    Edit_Group_Note: null,
    Get_Group_Note: null,
    Delete_Group_Note: null,
    students_List_Note: null,
    upgrade_PopUp_Open: null,
    error: null,
    exists: null,
    loader: null,
    Notesloader: null,
    GroupNotesLoader: null,
    action: null
})

/* ------------- Reducers ------------- */


/* addStudentNote */

export const addGroupNote = (state) => {
    return {
        ...state, fetching: true, error: null, Add_Group_Note: null, Notesloader: true
    }
}
export const addGroupNoteSuccess = (state, action) => {
    sessionStorage.removeItem('noteCommentData')
    sessionStorage.removeItem('finalTranscribedAudio')
    sessionStorage.removeItem('finalNoteCommentCardsAfterRemove')
    const { data } = action
    return { ...state, fetching: false, error: null, Add_Group_Note: data, action: action.type, exists: true, Notesloader: false }
}
export const addGroupNoteFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'ADD_GROUP_NOTE_FAILURE', Notesloader: false }
}

export const upgradePopUpOpen = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, Add_Group_Note: data, action: action.type, exists: true, Notesloader: false }
}

/* editStudentNote */

export const editGroupNote = (state) => {
    return {
        ...state, fetching: true, error: null, Edit_Group_Note: null, Notesloader: true
    }
}
export const editGroupNoteSuccess = (state, action) => {
    sessionStorage.removeItem('noteCommentData')
    sessionStorage.removeItem('finalTranscribedAudio')
    sessionStorage.removeItem('finalNoteCommentCardsAfterRemove')
    const { data } = action
    return { ...state, fetching: false, error: null, Edit_Group_Note: data, action: action.type, exists: true, Notesloader: false }
}
export const editGroupNoteFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'EDIT_GROUP_NOTE_FAILURE', Notesloader: false }
}



/* getStudentNote */

export const getGroupNote = (state) => {
    sessionStorage.removeItem('noteCommentData')
    sessionStorage.removeItem('finalTranscribedAudio')
    sessionStorage.removeItem('finalNoteCommentCardsAfterRemove')
    return {
        ...state, fetching: true, error: null, Get_Group_Note: null, Notesloader: true
    }
}
export const getGroupNoteSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, Get_Group_Note: data, action: action.type, exists: true, Notesloader: false }
}
export const getGroupNoteFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_GROUP_NOTE_FAILURE', Notesloader: false }
}

/* deleteStudentNote */
export const deleteGroupNote = (state) => {
    sessionStorage.removeItem('noteCommentData')
    sessionStorage.removeItem('finalTranscribedAudio')
    sessionStorage.removeItem('finalNoteCommentCardsAfterRemove')
    return {
        ...state, fetching: true, error: null, Delete_Group_Note: null, Notesloader: true
    }
}
export const deleteGroupNoteSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, Delete_Group_Note: data, action: action.type, exists: true, Notesloader: false }
}
export const deleteGroupNoteFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'DELETE_GROUP_NOTE_FAILURE', Notesloader: false }
}



/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {


    [Types.GET_GROUP_NOTE]: getGroupNote,
    [Types.GET_GROUP_NOTE_SUCCESS]: getGroupNoteSuccess,
    [Types.GET_GROUP_NOTE_FAILURE]: getGroupNoteFailure,

    [Types.ADD_GROUP_NOTE]: addGroupNote,
    [Types.ADD_GROUP_NOTE_SUCCESS]: addGroupNoteSuccess,
    [Types.ADD_GROUP_NOTE_FAILURE]: addGroupNoteFailure,

    [Types.EDIT_GROUP_NOTE]: editGroupNote,
    [Types.EDIT_GROUP_NOTE_SUCCESS]: editGroupNoteSuccess,
    [Types.EDIT_GROUP_NOTE_FAILURE]: editGroupNoteFailure,

    [Types.DELETE_GROUP_NOTE]: deleteGroupNote,
    [Types.DELETE_GROUP_NOTE_SUCCESS]: deleteGroupNoteSuccess,
    [Types.DELETE_GROUP_NOTE_FAILURE]: deleteGroupNoteFailure,
    [Types.UPGRADE_POP_UP_OPEN]: upgradePopUpOpen
   
})
