import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from 'react-bootstrap/FormControl';
import { createNotification } from 'Config/notificationtoast';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import '../ContactUsDashboardComponent/ContactUsDashboard.scss'


const ContactForm = (props) => {
    const { sendContactUsRequest } = props;
    const [isSubmit, setSubmitCheck] = useState(false);
    const [isName, setNameCheck] = useState('');
    const [isEmailAddress, setEmailAddressCheck] = useState('');
    const [isSubject, setSubjectCheck] = useState('');
    const [isMessage, setMessageCheck] = useState('');

    /*  ------ on Change Name ------ */
    const onChangeName = (e) => {
        setSubmitCheck(false);
        setNameCheck(e.target.value)
    }
    /*  ------ on Change Name ------ */

    /*  ------ on Change Email Address ------ */
    const onChangeEmailAddress = (e) => {
        setSubmitCheck(false);
        setEmailAddressCheck(e.target.value)
    }
    /*  ------ on Change Email Address ------ */

    /*  ------ on Change Subject ------ */
    const onChangeSubject = (e) => {
        setSubmitCheck(false);
        setSubjectCheck(e.target.value)
    }
    /*  ------ on Change Subject ------ */

    /*  ------ on Change Message ------ */
    const onChangeMessage = (e) => {
        setSubmitCheck(false);
        setMessageCheck(e.target.value)
    }
    /*  ------ on Change Message ------ */

    /*  ------ Send Mail ------ */
    const sendMail = () => {
        setSubmitCheck(true);

        if (!isName || !isEmailAddress || !isSubject || !isMessage) {
            // createNotification('error', 'Please enter value')
            return false;
        }
        const valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        if (!valid.test(isEmailAddress)) {
            createNotification('error', 'Invalid email address, Please enter a valid email address.')
            return false;
        }
        const data = {
            name: isName,
            email: isEmailAddress,
            subject: isSubject,
            message: isMessage
        }

        sendContactUsRequest(data)

    }
    /*  ------ Send Mail ------ */

    return (

        <Col md={5} lg={5}>
            <div className='formDiv'>
                <FormControl
                    placeholder="Full Name (Required)"
                    aria-label="Full Name (Required)"
                    className="fromInput"
                    value={isName}
                    onChange={onChangeName}
                />
                {(isSubmit && !isName) && <p className='errormessage'>Please Enter Full Name</p>}
                <FormControl
                    placeholder="Email Address (Required)"
                    aria-label="Email Address (Required)"
                    className="fromInput"
                    value={isEmailAddress}
                    onChange={onChangeEmailAddress}

                />
                {(isSubmit && !isEmailAddress) && <p className='errormessage'>Please Enter Email</p>}

                <Form.Group controlId="exampleForm.ControlSelect1" >
                    <Form.Control value={isSubject} as="select" className="fromInput" onChange={onChangeSubject}>
                        <option value=''>Subject (Required)</option>
                        <option value='Subscription (Individual)'>Subscription (Individual)</option>
                        <option value='Subscription (School)'>Subscription (School)</option>
                        <option value='Feedback'>Feedback</option>
                        <option value='Other'>Other</option>
                    </Form.Control>
                    {(isSubmit && !isSubject) && <p className='errormessage'>Please Select Subject</p>}

                </Form.Group>

                <Form.Control as="textarea" rows="6" placeholder="Message." className='fromInputArea' value={isMessage} onChange={onChangeMessage} />

                {(isSubmit && !isMessage) && <p className='errormessage'>Please Enter message</p>}
                <Button onClick={sendMail} className='sendButton'><p className='sendText'>Send</p></Button>



            </div>
        </Col>

    )
}
ContactForm.propTypes = {
    sendContactUsRequest: PropTypes.func
}
export default ContactForm