import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { saveAs } from 'file-saver';
import disNonAtt from 'Assets/RubricsModule/disNonAtt.svg'
import absentAtt from 'Assets/RubricsModule/absentAtt.svg'
import calendarIcon from 'Assets/NoteModule/calendar.svg'
import download from 'Assets/AttendanceModule/download.svg'
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import Modal from 'react-bootstrap/Modal';
import { getFormattedDate, addDaysGetSpan } from 'Utils/Helper'
import PreviewRubricsReport from './PreviewRubricsReport';
import axios from 'axios';
import { createNotification } from 'Config/notificationtoast'
import { BASE_MICROSERVICE_URL } from "../../../Config/Constant";
import DatePicker from 'react-datepicker'
import {
	viewRubricDetails,
	getRubricList,
	forwardRubricsReport
} from 'Actions/ClassAction/ClassRubricsAction';
import "./RubricsAssessmentTable.scss";
import "./FillAssessmentComp.scss";
import "./ViewOnlyRubric.scss";


const ViewOnlyRubric = (props) => {
	const {
		viewRubricDetails,
		viewRubricDetailsState,
		forwardRubricsReport,
		history,
		state
	} = props;
	const {
		ClassDetails,
		selectionType,
		SelectedRubric,
	} = history.location.state;
	const { loader, action } = state.classRubrics;
	const [viewRubricDetailsStates, setViewRubricDetailsStates] = useState([]);
	const [selectedStudentForData, setSelectedStudentForData] = useState("");
	const [stdInfo, setStdInfo] = useState([]);
	const [show, setShow] = useState(false);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [firstDatePre, setFirstDatePre] = useState("");
	const [filterType, setFilterType] = useState("all");
	const [rangeSecondDatePre, setRangeSecondDatePre] = useState("");
	const [showViewAtt, setShowViewAtt] = useState(false);
	const [firstDate, setFirstDate] = useState("");
	const [rangeSecondDate, setRangeSecondDate] = useState("");
	const [firstDateReportPre, setFirstDateReportPre] = useState("");
	const [rangeSecondDateReportPre, setRangeSecondDateReportPre] = useState("");
	const [recordFound, setRecordFound] = useState("");
	const [fileUrl, setFileUrl] = useState("");
	const [upoadFile, setUpoadFile] = useState("");
	const [showPreview, setShowPreview] = useState(false);
	const [showPreviewType, setShowPreviewType] = useState("");
	const [showViewDateRange, setShowViewDateRange] = useState(false);
	const [avgrageValue, setAverageValue] = useState("");
	const signDate = sessionStorage.getItem('separatorFormat')
	const formatDate = sessionStorage.getItem('dateFormat')
	const base_url_API = BASE_MICROSERVICE_URL;
	const device_udid = sessionStorage.getItem('UDID');
	const userLocalData = JSON.parse(sessionStorage.getItem('UserData'))
	const tableRightSection = [`tableRightSection ${selectedStudentForData === "" ? "forNoneData" : ""}`];
	const numbersArr = Array.from({ length: 10 }, (v, k) => k + 1);

	useEffect(() => {
		const spanDate = addDaysGetSpan(new Date(), 1);
		setFirstDateReportPre(new Date());
		setRangeSecondDateReportPre(spanDate);
		const formattedDate = getFormattedDate(new Date(), formatDate, signDate)
		setFirstDate(formattedDate);
		const secondFormattedDate = getFormattedDate(spanDate, formatDate, signDate)
		setRangeSecondDate(secondFormattedDate);
	}, [showViewAtt])

	useEffect(() => {
		if (viewRubricDetailsState) {
			const allDataList = viewRubricDetailsState && viewRubricDetailsState.studentArr.map(val => {
				let dataVal = { ...val };
				let criData = [];
				viewRubricDetailsState.criteriaArr.map(value => {
					if (dataVal.assessmentArr && dataVal.assessmentArr.length > 0) {
						dataVal.assessmentArr.map(values => {
							if (value.cc_criteria_id === values.cc_criteria_id) {
								let valObj = { ...values, "cc_criteria_title": value.cc_criteria_title };
								criData.push(valObj);
							}
						})
					}
				})
				return { ...dataVal, "assessmentArr": criData }
			})
			setViewRubricDetailsStates({ ...viewRubricDetailsState, "studentArr": allDataList });
		}
	}, [viewRubricDetailsState])

	useEffect(() => {
		if (selectionType === "view" && SelectedRubric) {
			const apiData = {
				"class_id": ClassDetails.cc_class_id,
				"rubric_id": SelectedRubric.cc_rubric_id,
			}
			viewRubricDetails(apiData)
		}
	}, [])


	useEffect(() => {
		viewRubricDetailsStates && viewRubricDetailsState && viewRubricDetailsStates.studentArr && viewRubricDetailsStates.studentArr.map(value => {
			if (value.cc_student_id === selectedStudentForData) {
				setAverageValue(value.average_score);
			}
		})
	}, [viewRubricDetailsStates, selectedStudentForData])

	useEffect(() => {
		selectedStudentForData && viewRubricDetailsState && viewRubricDetailsStates && viewRubricDetailsStates.studentArr.map(val => {
			if (val.cc_student_id === selectedStudentForData) {
				if (val.availability_status !== "absent") {
					setShow(true);
					setStdInfo(val.assessmentArr)
				} else {
					setShow(false);
					setStdInfo("")
				}
			}
		})
	}, [selectedStudentForData])

	const getDateFormatAsApi = date => {
		const FormatDate = date.toISOString().split('T')[0]
		return FormatDate
	}

	const BackTOHome = () => {
		history.goBack(selectionType, SelectedRubric);
		setFilterType("drafted");
		setStartDate("");
		setEndDate("");
		setFirstDatePre("");
		setRangeSecondDatePre("");
		setShowViewDateRange(false);
	}

	const reportDownload = () => {
		if (showPreviewType === "pdf") {
			const fileName = `rubricsReport ${new Date()}.pdf`
			saveAs(fileUrl, fileName);

		} else if (showPreviewType === "csv") {
			const fileName = `rubricsReport ${new Date()}.csv`
			// saveAs(showCsvUrl, fileName);
		}
	}

	const selectStudent = (val) => {
		if (val.availability_status !== "absent") {
			setSelectedStudentForData(val.cc_student_id);
		}
	}

	const confirmationViewRubReport = () => {
		setShowPreview(true);
		setShowPreviewType("pdf");
		const rangeStartDate = getDateFormatAsApi(firstDateReportPre);
		const rangeEndDate = getDateFormatAsApi(rangeSecondDateReportPre);
		const pdfUrl = base_url_API + `rubric/apiDownloadRubricPdf?user_id=${userLocalData.cc_user_id}&class_id=${ClassDetails.cc_class_id}&rubric_id=${SelectedRubric.cc_rubric_id}&platform=web`
		reportPDFDownload(pdfUrl, `${"rubricReport.pdf"}`);
	};

	const cancelViewAtt = () => {
		setShowViewDateRange(false);
	};

	const confirmationViewReport = () => {
		setShowViewDateRange(false);
		const firstDate = addDaysGetSpan(firstDatePre, 1);
		const secondDate = addDaysGetSpan(rangeSecondDatePre, 1);
		const apiData = {
			"class_id": ClassDetails.cc_class_id,
			"rubric_status": "all",
			"start_date": getDateFormatAsApi(firstDate),
			"end_date": getDateFormatAsApi(secondDate)
		}
		getRubricList(apiData)
	};

	function blobToFile(theBlob, fileName) {
		return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
	}

	const reportPDFDownload = (URl, projectName) => {
		const options = {
			method: 'GET',
			baseURL: URl,
		};
		axios(options).then(async responses => {
			if (responses.data.message === "No record found") {
				setRecordFound(responses.data.message);
				createNotification('error', responses.data.message);
			} else {
				const options = {
					method: 'GET',
					baseURL: URl,
					responseType: 'blob'
				};
				axios(options).then(async responses => {
					const file = new Blob(
						[responses.data],
						{ type: 'application/pdf' });
					const myFile = blobToFile(file, "rubrics.pdf");
					setUpoadFile(myFile)
					const fileURL = URL.createObjectURL(file);
					setFileUrl(fileURL)
				})
			}
		})
			.catch(err => {
				if (err && err.message) {
					createNotification('error', err.message);
				}
			});
	}


	const setRangeFirstDate = (date) => {
		setFirstDatePre(date);
		const formattedDate = getFormattedDate(date, formatDate, signDate)
		setStartDate(formattedDate);
	}

	const addRangeSecondDate = (date) => {
		setRangeSecondDatePre(date);
		const formattedDate = getFormattedDate(date, formatDate, signDate)
		setEndDate(formattedDate);
	}

	const setRangeFirstDateReport = (date) => {
		setFirstDateReportPre(date);
		const formattedDate = getFormattedDate(date, formatDate, signDate)
		setFirstDate(formattedDate);
	}

	const addRangeSecondDateReport = (date) => {
		setRangeSecondDateReportPre(date);
		const formattedDate = getFormattedDate(date, formatDate, signDate)
		setRangeSecondDate(formattedDate);
	}

	const viewAttendanceClick = date => {
		setShowViewAtt(true);
	}

	const cancelViewRub = date => {
		setShowViewAtt(false);
		setShowPreview(false);
		setShowPreviewType("pdf");
	}

	return (
		<div className="viewRubricsMainContainer">
			{viewRubricDetailsStates && !showPreview && <div className="viewRubricsContainer">
				<div className='addRubricsMainDiv'>
					<div className='RubricsDetailsMain'>
						<div className='ClassBackRubDiv' onClick={BackTOHome}>
							<i className='lessonBackIcon material-icons'>chevron_left</i>
							{/* <p className='lessonClassBactText'>Rubrics</p> */}
						</div>
						<div className='RubricSeprator'></div>
						<div className='attClassDetailTextDiv'>
							<p className='head'>{ClassDetails.cc_class_name}</p>
							<div className='displayFlexClassRoster'>
								<p className='topicHead'>Topic: </p>
								<p className='topicTextName'>{viewRubricDetailsStates.cc_rubric_title} </p>
								<p className='topicDate'>- {viewRubricDetailsStates.cc_rubric_date}</p>
							</div>
						</div>
					</div>
					<div className='displayFlexInSeletAtt'>
						<div className='buttonMarginTop'>
							<Button className="editBtnAsses"
								onClick={confirmationViewRubReport}
							>
								<img className='viewIconDwnload' src={download} alt='' />
								<span className='viewAttText'>Download & Share</span>
							</Button>
						</div>
					</div>
				</div>
				{loader && <div>
					<img src={loaderImag} alt='' className='loaderIconCss' />
				</div>}
				<div className="table-rubrics table-Fix-Size">
					<div className="table-rubrics-table">
						<div className="table-left-section">
							{viewRubricDetailsStates && viewRubricDetailsState && viewRubricDetailsStates.studentArr && viewRubricDetailsStates.studentArr.map((item, k) => {
								const headerStyle = ["headerStyle", selectedStudentForData === item.cc_student_id ? "selectedProfile" : ""];
								const avgScoresText = ["avgScoresText", item.average_score <= "30" ? "begin" : item.average_score <= "60" && item.average_score > "30" ? "develope" : item.average_score <= "80" && item.average_score > "60" ? "accomplish" : item.average_score <= "100" && item.average_score > "80" ? "exemplar" : ""];
								const profileImg = ["profile-img", item.availability_status === "absent" ? "opaqueProf" : ""];
								const profileDummyRosterProfile = ["profileDummyRosterProfile", item.availability_status === "absent" ? "opaqueProf" : ""];
								const nameLable = ["nameLable", item.availability_status === "absent" ? "opaqueProf" : ""];
								return (<div className={headerStyle.join(' ')} key={k}>
									<div className="profile">
										{item.cc_student_pic_url ?
											<img className={profileImg.join(" ")} src={item.cc_student_pic_url} alt="" width="50" height="50" onClick={() => { selectStudent(item) }} />
											:
											<div className={profileDummyRosterProfile.join(" ")} onClick={() => { selectStudent(item) }}>
												<h5 className='rosterProfileImageText'>{item.cc_student_first_name.charAt(0)}{item.cc_student_last_name.charAt(0)}</h5>
											</div>
										}
										<label className={nameLable.join(" ")} onClick={() => { selectStudent(item) }}>{item.cc_student_last_name}, {item.cc_student_first_name}</label>
										<div className='emojiIconsRub'>
											{item.availability_status === "absent" ? (
												<img className='emojiIcons-img' src={absentAtt}
													alt='disEmoji' width='40' height='40' />
											) : ""}
											{item.availability_status === "present" && item?.average_score <= 0 ?
												(<img className='emojiIcons-img' src={disNonAtt}
													alt='disEmoji' width='40' height='40' />
												) : ""}
											{item.availability_status === "present" && item?.average_score > 0 ?
												(<p className={avgScoresText.join(" ")}
												// >{`${(item?.average_score).toFixed(2)}%`}</p>) : ""}
												>{`${parseFloat(item?.average_score).toFixed(2)}%`}</p>) : ""}
											{/* (value).toFixed(2); */}
											{/* >{`${(item?.average_score !== null && item?.average_score).toFixed(2)}%`}</p>) : ""} */}
										</div>
									</div>
								</div>)
							})}
						</div>
						<div className={tableRightSection.join(' ')}>
							{!selectedStudentForData && <p className="NoneSelected">Click on student to View Assessment</p>}
							{show && <div>
								<div className="rubricsAssesTabContainer">
									<div className="assessmentMainDiv">
										<div className="assessmentHeaderDiv">
											<p className='tableTitles'>Criteria</p>
										</div>
										<div className="assessmentNumbersTitle">
											<div className='beginning'><p className='nameTexts '>Beginning</p></div>
											<div className='developing'><p className='nameTexts'>Developing</p></div>
											<div className='accomplished'><p className='nameTexts'>Accomplished</p></div>
											<div className='exemplary'><p className='nameTexts'>Exemplary</p></div>
										</div>
									</div>
									<div className='assessmentMainDataDiv'>
										{viewRubricDetailsState && viewRubricDetailsStates && stdInfo && stdInfo.map((value, j) => {
											return (
												<div className='mainAssessDiv' key={j}>
													<div className='criteriaTitle' key={j} ><p className='criteriaText'>{value.cc_criteria_title}</p></div>
													<div className='criNumbers'>
														<div className='numbersDiv'>
															{numbersArr && numbersArr.map((val, k) => {
																const criteriaNumbers = ["criteriaNumbers", value.cc_ra_assessment_point <= 3 && val === value.cc_ra_assessment_point ? "beging" : value.cc_ra_assessment_point > 3 && value.cc_ra_assessment_point <= 6 && val === value.cc_ra_assessment_point ? "develope" : value.cc_ra_assessment_point > 6 && value.cc_ra_assessment_point <= 8 && val === value.cc_ra_assessment_point ? "accomplish" : value.cc_ra_assessment_point > 8 && value.cc_ra_assessment_point <= 10 && val === value.cc_ra_assessment_point ? "exemp" : ""];
																return (
																	<div className="numDiv" key={k}>
																		<p className={criteriaNumbers.join(' ')} id={`mypopOver${k}${j}`} > {val !== 10 ? "0" + val : val}</p>
																	</div>
																)
															})}
														</div>
														<div className="commentsSection">{value.cc_ra_assessment_comment ? 
														<span className="commentsSectionSpan"><p className="cmtTitle">Comment: </p><p className="cmtText">{value?.cc_ra_assessment_comment?.trim().length > 0 && value.cc_ra_assessment_comment}</p></span> : 
														<p className='noCmt'>No Comments</p>}</div>

													</div>
												</div>)
										}
										)}
									</div>
								</div>
								<div className="avgScoreDiv">
									<span className='avgText'>
										Average score: {avgrageValue && <p className='avgTextScore'>{avgrageValue === "00.00" ? avgrageValue : parseFloat(avgrageValue).toFixed(2)} %</p>}
									</span>
								</div>
							</div>}
						</div>
					</div>
				</div>
				<Modal centered className="rubrics-modal-warning br-8" show={showViewDateRange} >
					<Modal.Header>
						<Modal.Title>Select Date </Modal.Title>
					</Modal.Header>
					<div className="dateBlock">
						<div>
							<div className="dateSubBlock">
								<div className="dayDateTextDiv" ><p className="dayDateText">{startDate}</p></div>
								<DatePicker
									onChange={date => { setRangeFirstDate(date) }}
									customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
								/>
							</div>
							<div className="dateSubBlocks">
								<div className="dayDateTextDiv" ><p className="dayDateText">{endDate}</p></div>
								<DatePicker
									onChange={date => { addRangeSecondDate(date) }}
									customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
								/>
							</div>
						</div>
					</div>
					<Modal.Footer>
						<Button variant="default" className="cancelAtt-btn"
							onClick={cancelViewAtt}
						>
							Cancel
						</Button>
						<Button variant="default" className="getReport-btn"
							onClick={confirmationViewReport}
						>
							Apply
						</Button>
					</Modal.Footer>
				</Modal>

				<Modal centered className="att-modal-warning br-8" show={showViewAtt} >
					<Modal.Header>
						<Modal.Title>Select Date </Modal.Title>
					</Modal.Header>
					<Modal.Body>
					</Modal.Body>
					<div className="dateBlock">
						<div>
							<div className="dateSubBlock">
								<div className="dayDateTextDiv" ><p className="dayDateText">{firstDate}</p></div>
								<DatePicker
									onChange={date => { setRangeFirstDateReport(date) }}
									customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
								/>
							</div>
							<div className="dateSubBlocks">
								<div className="dayDateTextDiv" ><p className="dayDateText">{rangeSecondDate}</p></div>
								<DatePicker
									onChange={date => { addRangeSecondDateReport(date) }}
									customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
								/>
							</div>
						</div>
					</div>
					<Modal.Footer>

						<Button variant="default" className="cancelAtt-btn"
							onClick={cancelViewRub}
						>
							Cancel
						</Button>
						<Button variant="default" className="getReport-btn"
							onClick={confirmationViewRubReport}
						>
							Get
						</Button>
					</Modal.Footer>
				</Modal>
			</div>}
			{showPreview && <div>
				<PreviewRubricsReport
					showPreviewType={showPreviewType}
					fileUrl={fileUrl}
					history={history}
					forwardRubricsReport={forwardRubricsReport}
					setShowPreviewType={setShowPreviewType}
					setFileUrl={setFileUrl}
					setShowPreview={setShowPreview}
					upoadFile={upoadFile}
					recordFound={recordFound}
					setRecordFound={setRecordFound}
					setUpoadFile={setUpoadFile}
				/> </div>}
		</div>
	)
}

const mapStateToProps = state => ({
	state: state,
	getRubricListState: state.classRubrics.Get_Rubric_List,
	viewRubricDetailsState: state.classRubrics.View_Rubric_Details,
});
const mapDispatchToProps = dispatch => {
	return {
		viewRubricDetails: (data) => dispatch(viewRubricDetails(data)),
		getRubricList: (data) => dispatch(getRubricList(data)),
		forwardRubricsReport: (data) => dispatch(forwardRubricsReport(data)),

	};
};
export default connect(mapStateToProps, mapDispatchToProps)(
	ViewOnlyRubric
);
