import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import DivLoader from "Components/LoadingComponent/DivLoader";
import DataUsagePlan from "../DataUsagePlanComponent/DataUsagePlan";
import DataUsageProgressBar from "../DataUsageProgressBarComponent/DataUsageProgressBar";
import "./DataUsageDashboard.scss";

const DataUsageDashboard = (props) => {
  const { getDataUsageRequest, DataUsage, loader } = props;
  const { user_space_response } = DataUsage || {};

  /* ------ Get DataUsage API------ */
  useEffect(() => {
    getDataUsageRequest();
  }, [getDataUsageRequest]);
  /* ------ Get DataUsage API------ */

  return (
    <div className="dataUsageContainer">
      {loader && <DivLoader datatestid="loader" />}
      {/* ------ Plans Info------ */}
      {DataUsage?.user_space_response && (
        <Card className="cardOfPlan p-0" style={{ width: 975, margin: "auto" }}>
          <DataUsagePlan
            history={props.history}
            user_space_response={user_space_response}
          />
        </Card>
      )}
      {/* ------ Plans Info------ */}

      {DataUsage?.user_space_response && (
        <Card className="cardOfProgressBar">
          {/* ------ Data Info in Progress Bar------ */}
          <DataUsageProgressBar user_space_response={user_space_response} />
          {/* ------ Data Info in Progress Bar------ */}

          {/* ------ Note Info------ */}
          <Row style={{ justifyContent: "space-between", marginTop: 10 }}>
            <div>
              <p className="noteCount">
                Note Count: {user_space_response.total_notes_count}
              </p>
              <p className="noteCount">
                Multimedia Count: {user_space_response.total_media_count}
              </p>
            </div>
            <p className="dataAvailable">
              {(
                user_space_response.total_alloted_space -
                user_space_response.total_space
              ).toFixed(2)}{" "}
              available of {user_space_response.total_alloted_space}.0 MB
            </p>
          </Row>
          {/* ------ Note Info------ */}
        </Card>
      )}
    </div>
  );
};

DataUsageDashboard.propTypes = {
  getDataUsageRequest: PropTypes.func,
  loader: PropTypes.bool,
};

export default DataUsageDashboard;
