import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


// import Logger from '../Lib/Logger'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    verify: ['data'],
    verifySuccess: ['data'],
    verifyFailure: ['error'],

    resendVerify: ['data'],
    resendVerifySuccess: ['data'],
    resendVerifyFailure: ['error'],

})

export const UserVerifyReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    verify_Data: null,
    resendVerify_Data: null,
    error: null,
    exists: null,
    action: null
})

/* ------------- Reducers ------------- */

/* verify */
export const verify = (state) => {
    return {
        ...state, fetching: true, error: null, verify_Data: null,
    }
}
export const verifySuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, verify_Data: data, action: action.type, exists: true }
}
export const verifyFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, }
}



/* resendVerify */
export const resendVerify = (state) => {
    return {
        ...state, fetching: true, error: null, resendVerify_Data: null,
    }
}
export const resendVerifySuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, resendVerify_Data: data, action: action.type, exists: true }
}
export const resendVerifyFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, }
}


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.VERIFY]: verify,
    [Types.VERIFY_SUCCESS]: verifySuccess,
    [Types.VERIFY_FAILURE]: verifyFailure,


    [Types.RESEND_VERIFY]: resendVerify,
    [Types.RESEND_VERIFY_SUCCESS]: resendVerifySuccess,
    [Types.RESEND_VERIFY_FAILURE]: resendVerifyFailure,

})