import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ColourCode } from 'Config/Constant'
import tickIcon from 'Assets/FormativeAssessmentModule/tick.svg';
import './ClassGradeBookColorFooter.scss'

const ClassGradeBookColorFooter = (props) => {
    const { updateClassGradebookAllColorRequest } = props;
    const [allColorSelect, setAllColorSelect] = useState("")
    // -----------Update All Color-----
    const changeColor = (color) => {
        if (allColorSelect !== color) {
            setAllColorSelect(color);
            const data = {
                colorCode: color,
            }
            updateClassGradebookAllColorRequest(data)
        } else {
            setAllColorSelect("");
            const data = {
                colorCode: "",
            }
            updateClassGradebookAllColorRequest(data)
        }
    }
    // -----------Update All Color-----

    return (
        <Row className="bottom-color-panel">
            <Col xs="12" className="grade-bottom-panel">
                <Card className="mt-15 grade-bottom-panel-bottom">
                    <Card.Body>
                        <Row>
                            <Col xs="12" className="text-right">
                                <div className="text-sec">
                                    <span className="text">One Touch Color</span>
                                    {ColourCode.map((colorCode, i) => (
                                        <Button
                                            key={i}
                                            variant="default"
                                            className="color-btn"
                                            style={{ background: colorCode }}
                                            onClick={() => { changeColor(colorCode) }}
                                        >
                                            {colorCode === allColorSelect ? <img src={tickIcon} alt='' /> : ""}
                                        </Button>))}
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}
ClassGradeBookColorFooter.propTypes = {
    updateClassGradebookAllColorRequest: PropTypes.func,
};

export default ClassGradeBookColorFooter 