
import {
    PLACE_SEARCH,
    PLACE_SEARCH_SUCCESS,
    PLACE_SEARCH_FAILURE,


    SELECT_PLACE,
    SELECT_PLACE_SUCCESS,
    SELECT_PLACE_FAILURE,
} from '../constants';

/**
 * Fetches the login details of the user if already in DB
 *
 * @param  {data} data in the form
 *
 * @return {object}    An action object with a type of FETCH_LOGIN_DETAILS
 */

// =======Place Search=====
export function placeSearch(data) {
    return {
        type: PLACE_SEARCH,
        data,
    };
}

export function placeSearchSuccess(data) {
    return {
        type: PLACE_SEARCH_SUCCESS,
        data,
    };
}

export function placeSearchFailure(data) {
    return {
        type: PLACE_SEARCH_FAILURE,
        data,
    };
}


// =======selectPlace=====
export function selectPlace(data) {
    return {
        type: SELECT_PLACE,
        data,
    };
}

export function selectPlaceSuccess(data) {
    return {
        type: SELECT_PLACE_SUCCESS,
        data,
    };
}

export function selectPlaceFailure(data) {
    return {
        type: SELECT_PLACE_FAILURE,
        data,
    };
}


