import React , {useEffect} from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import tickIcon from 'Assets/FormativeAssessmentModule/tick.svg';
import { ColourCode } from 'Config/Constant'
import './AddStudentProfiles.scss';


const AssessmentStudentProfile = (props) => {
    const { AllStudent, updateColorAssessmentTagRequired , CLR , FT , setFT } = props;
    const _ = require('lodash');
    
    const setColor = (itemIndex, colorCode) => {
        const value = {
            itemIndex: itemIndex,
            colorCode: colorCode,
            deSeletColor: false
        }
        updateColorAssessmentTagRequired(value)
    }
    useEffect(() => {
        if(FT) {
         AllStudent.map((item) => {
          item.cc_ref_student_color_code = ""
         })
        }
      
      }, [])
    return (
        AllStudent.map((item, index) => {
            return (
                /* ---------------Add Student Section------------ */
                <Col key={index} sm="6">
                    <Card className="mt-15 br-8" >
                    
                        <Card.Body className="addMainDiv" style={ { borderRight: item.cc_ref_student_color_code ? `6px solid ${item.cc_ref_student_color_code}` : '6px solid #ffffff' } }  >

                            <Row className="profile">
                                <Col xs="7" md="12" lg="6" style={{ padding: 2 , display: "flex", alignItems: "center"}}>
                                    <div className="img-div">
                                        {item.cc_student_pic_url ?
                                            <Card.Img className="imgGoal" variant="top" alt='' src={item.cc_student_pic_url} />
                                            :
                                            <div className='studentProfileImageDiv'>
                                                <div className='imageDummyRosterProfiles'>
                                                    <h5 className='rosterProfileImageText'>{item.cc_student_first_name.charAt(0)}{item.cc_student_last_name.charAt(0)}</h5>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="details w-100"
                                    >
                                        {/* <span className="name">{item.cc_student_last_name} {item.cc_student_first_name} {item.selectedColor}</span> */}
                                        <span className="name">{_.truncate(item.cc_student_last_name + " " + item.cc_student_first_name, {
                                                'length': 20,
                                                'omission': '...'
                                            })}</span>
                                        <br></br>
                                        <span className="mail FAaddassesmentmail"
                                        style={{display : (item?.cc_student_email_id || item?.cc_student_email_id?.length > 0) && 'inline-block'}}
                                        >
                                            {item.cc_student_email_id}
                                            </span>
                                    </div>
                                </Col>
                                <Col xs="5" md="12" lg="6" className="action">
                                    {ColourCode.map((colorCode, i) => (<Button key={i}
                                        onClick={() => { setColor(index, colorCode) }}
                                        variant="default"
                                        className="color-btn "
                                        style={{ background: colorCode }}
                                    >{colorCode === item.cc_ref_student_color_code ? <img src={tickIcon} alt='' /> : ""}
                                    </Button>))}

                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                /* ---------------Add Student Section------------ */

            )
        })
    )
}


AssessmentStudentProfile.propTypes = {
    updateColorAssessmentTagRequired: PropTypes.func

};

export default AssessmentStudentProfile