import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


// import Logger from '../Lib/Logger'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    addRoster: ['data'],
    addRosterSuccess: ['data'],
    addRosterFailure: ['error'],

    getRosterList: ['data'],
    getRosterListSuccess: ['data'],
    getRosterListFailure: ['error'],

    deleteRoster: ['data'],
    deleteRosterSuccess: ['data'],
    deleteRosterFailure: ['error'],


    searchRoster: ['data'],
    searchRosterSuccess: ['data'],
    searchRosterFailure: ['error'],


    importRoster: ['data'],
    importRosterSuccess: ['data'],
    importRosterFailure: ['error'],

    setRosterSidebarOpen: [''],
    setRosterState: ['data']


})

export const UserRosterReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    roster_Data: null,
    add_roster: null,
    delete_roster: null,
    search_roster: null,
    import_Roster: null,
    sideMenu: false,
    error: null,
    exists: null,
    loader: null,
    action: null,
    init_state: false
})

/* ------------- Reducers ------------- */
/* addRoster */
export const addRoster = (state) => {
    return {
        ...state, fetching: true, error: null, add_roster: null, loader: false
    }
}
export const addRosterSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, add_roster: data, action: action.type, exists: true, loader: false }
}
export const addRosterFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'ADD_ROSTER_FAILURE', loader: false }
}

export const setRosterState = (state) => {
    return {
        ...state,
        roster_Data: null,
    add_roster: null,
    delete_roster: null,
    search_roster: null,
    import_Roster: null,
    sideMenu: false,
    error: null,
    exists: null,
    loader: null,
    action: null
    }
}

/* getAcademicList */
export const getRosterList = (state, action) => {
    return {
        ...state, fetching: true, error: null, roster_Data: null, loader:action?.data?.showLoader ? true : state?.roster_Data?.data?.length > 0 ? false :  true
    }
}
export const getRosterListSuccess = (state, action) => {
    const { data } = action
    return { ...state, fetching: false, error: null, roster_Data: data, action: action.type, exists: true, loader: false }
}
export const getRosterListFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_ROSTER_LIST_FAILURE', loader: false }
}

/* deleteRoster */
export const deleteRoster = (state) => {
    return {
        ...state, fetching: true, error: null, delete_roster: null, loader: false
    }
}
export const deleteRosterSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, delete_roster: data, action: action.type, exists: true, loader: false }
}
export const deleteRosterFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'DELETE_ROSTER_FAILURE', loader: false }
}

/* searchRoster */
export const searchRoster = (state) => {
    return {
        ...state, fetching: true, error: null, search_roster: null, loader: true
    }
}
export const searchRosterSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, search_roster: data, action: action.type, exists: true, loader: false }
}
export const searchRosterFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'SEARCH_ROSTER_FAILURE', loader: false }
}


/* importRoster */
export const importRoster = (state) => {
    return {
        ...state, fetching: true, error: null, import_roster: null, loader: true
    }
}
export const importRosterSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, import_roster: data, action: action.type, exists: true, loader: false }
}
export const importRosterFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'IMPORT_ROSTER_FAILURE', loader: false }
}

export const setRosterSidebarOpen = (state, action) => {
    return { ...state, sideMenu: action.data }
}




/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.ADD_ROSTER]: addRoster,
    [Types.ADD_ROSTER_SUCCESS]: addRosterSuccess,
    [Types.ADD_ROSTER_FAILURE]: addRosterFailure,

    [Types.GET_ROSTER_LIST]: getRosterList,
    [Types.GET_ROSTER_LIST_SUCCESS]: getRosterListSuccess,
    [Types.GET_ROSTER_LIST_FAILURE]: getRosterListFailure,

    [Types.DELETE_ROSTER]: deleteRoster,
    [Types.DELETE_ROSTER_SUCCESS]: deleteRosterSuccess,
    [Types.DELETE_ROSTER_FAILURE]: deleteRosterFailure,


    [Types.SEARCH_ROSTER]: searchRoster,
    [Types.SEARCH_ROSTER_SUCCESS]: searchRosterSuccess,
    [Types.SEARCH_ROSTER_FAILURE]: searchRosterFailure,


    [Types.IMPORT_ROSTER]: importRoster,
    [Types.IMPORT_ROSTER_SUCCESS]: importRosterSuccess,
    [Types.IMPORT_ROSTER_FAILURE]: importRosterFailure,

    [Types.SET_ROSTER_SIDEBAR_OPEN]: setRosterSidebarOpen,
    [Types.SET_ROSTER_STATE]: setRosterState



})
