import { call, takeLatest, put } from 'redux-saga/effects';
import UserClassTagReducer  from 'Redux/Reducers/ClassReducer/classTagReducer';
import { createNotification } from 'Config/notificationtoast';
import { GET_CLASS_TAG, DELETE_CLASS_TAG, EDIT_CLASS_TAG, GET_SPEED_NOTE, IMPORT_SPEED_NOTE, GET_CLASS_TAG_EDIT  } from 'Actions/constants';
import {
    GetClassTag_API,
    EditClassTag_API,
    DeleteClassTag_API,
    GetSpeedNote_API,
    importSpeedNote_API,
    GetNoteCore_API
} from 'Config/Api';
import { chroniclecloud_code } from 'Config/Constant';
import { decryptData, encryptData, logOut } from 'Utils/Helper';
import request from 'Config/request';

export function* userClassTagModuleSaga() {
    yield takeLatest(GET_CLASS_TAG, getClassTag);
    yield takeLatest(GET_CLASS_TAG_EDIT, getClassTagEdit);
    yield takeLatest(DELETE_CLASS_TAG, deleteClassTag);
    yield takeLatest(EDIT_CLASS_TAG, editClassTag);
    yield takeLatest(GET_SPEED_NOTE, getSpeedNote);
    yield takeLatest(IMPORT_SPEED_NOTE, importSpeedNote);
}

function* getClassTag(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${GetClassTag_API}?user_id=${data.cc_user_id}&class_id=${action.data.class_id  || sessionStorage.getItem("classIDForNotes")}&platform=web`;
    const BODY = {
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
    }

    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res)
        if (MainDecode.status === 200) {
            yield put(UserClassTagReducer.getClassTagSuccess(MainDecode));

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassTagReducer.getClassTagFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassTagReducer.getClassTagFailure(false));
    }
}
function* getClassTagEdit(action) {
    console.log('action reducer :>> ', action);
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${GetClassTag_API}?user_id=${data.cc_user_id}&class_id=${action.data.class_id  || sessionStorage.getItem("classIDForNotes")}&platform=web`;
    const BODY = {
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
    }

    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res)
        console.log('MainDecode reducer :>> ', MainDecode);
        if (MainDecode.status === 200) {
            yield put(UserClassTagReducer.getClassTagSuccessEdit(MainDecode));

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassTagReducer.getClassTagFailureEdit(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassTagReducer.getClassTagFailureEdit(false));
    }
}
function* editClassTag(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = EditClassTag_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": `${data.cc_user_id}`,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "delete_tag_ids": `${action.data.delete_tag_ids}`,
        "tag_details_array": action.data.tag_details_array,
        "platform": "web"
    }

    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res)
        if (MainDecode.status === 200) {
            let data = {
                "class_id": action.data.class_id,
            }
            yield put(UserClassTagReducer.editClassTagSuccess(MainDecode));
            yield put(UserClassTagReducer.getClassTag(data));
            createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassTagReducer.editClassTagFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassTagReducer.editClassTagFailure(false));
    }
}

function* deleteClassTag(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = DeleteClassTag_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "tag_id": action.data.cc_tag_id,
        "platform" : "web"
    }

    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res)
        if (MainDecode.status === 200) {
            let data = {
                "class_id": action.data.cc_tag_class_id,
            }
            yield put(UserClassTagReducer.deleteClassTagSuccess(MainDecode));
            yield put(UserClassTagReducer.getClassTag(data));


        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassTagReducer.deleteClassTagFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassTagReducer.deleteClassTagFailure(false));
    }
}

function* getSpeedNote(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${GetSpeedNote_API}?user_id=${data.cc_user_id}&class_id=${action.data.class_id}&customize_tag_id=${action.data.customize_tag_id}&filter_text=${action.data.filter_text || null}&platform=web`;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "customize_tag_id": action.data.customize_tag_id,
        "filter_text": action.data.filter_text,
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserClassTagReducer.getSpeedNoteSuccess(MainDecode));

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassTagReducer.getSpeedNoteFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassTagReducer.getSpeedNoteFailure(false));
    }
}

function* importSpeedNote(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = importSpeedNote_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "customize_tag_id": `${action.data.customize_tag_id}`,
        "speed_notes_array": [action.data.speed_notes_array],
        "platform" : "web"

    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            // body: { "data": btoa(BODY) },
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            let data = {
                "class_id": action.data.class_id,
                "customize_tag_id": action.data.selected_id,
                "filter_text": action.data.filter_text
            }

            createNotification('success', MainDecode.message);
            yield put(UserClassTagReducer.importSpeedNoteSuccess(MainDecode));
            yield put(UserClassTagReducer.getSpeedNote(data));

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassTagReducer.importSpeedNoteFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', "In selected file contains some characters which is outside of the Latin1 range, Please use the keyboard for special characters.");
        yield put(UserClassTagReducer.importSpeedNoteFailure(false));
    }
}