import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import tickIcon from "Assets/FormativeAssessmentModule/tick.svg";
import { ColourCode } from "Config/Constant";
import './StudentGradebokColorPopover.scss'

const StudentGradebokColorPopover = (props) => {
  const { editAddColor, cIndex, eIndex, setEditAddColorCheck, gradingEventId, openPopOver, UpdateStudentGradebookColorRequest } =
    props;
  const [allColorSelect, setAllColorSelect] = useState("");
  const ref = useRef();

  useOnClickOutside(ref, () => openPopOver(0));

  function useOnClickOutside(ref, handler) {

    useEffect(
      () => {
        
        const listener = (event) => {
          
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
         
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      [ref, handler]
    );
  }

  const changeColor = (colorCode) => {
    if (allColorSelect !== colorCode) {
      setAllColorSelect(colorCode);
      const data = {
        colorCode,
        cIndex,
        eIndex,
      };
      UpdateStudentGradebookColorRequest(data);
      openPopOver(0)
    } else {
      setAllColorSelect("");
      const data = {
        colorCode,
        cIndex,
        eIndex,
      };
      UpdateStudentGradebookColorRequest(data);
      openPopOver(0)
    }
  };
  return (
    <>
    {/* <div className="singleClassPopoverParentDiv">
      <div
      className="popover studentGradebookPopover"
      >
        {ColourCode.map((colorCode, i) => (
          <Button
            key={i}
            variant="default"
            className="color-btn"
            style={{ background: colorCode }}
            onClick={() => {
              changeColor(colorCode);
            }}
          >
            {colorCode === allColorSelect ? <img src={tickIcon} alt="" /> : ""}
          </Button>
        ))}
      </div>
      </div> */}
      {
       editAddColor ==  gradingEventId && 
      
    <div className="sutdentgradebbokpopoverparentdiv" ref={ref} style={{width : 0}}>
      <div class="wrap">
  <div class="arrow"></div>
  <div class="arrow-cover"></div>
  <div class="box">
  {ColourCode.map((colorCode, i) => (
          <Button
            key={i}
            variant="default"
            className="color-btn"
            style={{ background: colorCode }}
            onClick={() => {
              changeColor(colorCode);
            }}
          >
            {colorCode === allColorSelect ? <img src={tickIcon} alt="" /> : ""}
          </Button>
        ))}
  </div>
</div>
</div>
}
      {/* <UncontrolledPopover
        trigger="legacy"
        placement="bottom"
        isOpen={editAddColor === 0 ? false : true}
        target={`mypopOver${cIndex}${eIndex}`}
        className="score-color-popover-positioned"
      >
        <PopoverBody>
          {ColourCode.map((colorCode, i) => (
            <Button
              key={i}
              variant="default"
              className="color-btn"
              style={{ background: colorCode }}
              onClick={() => {
                changeColor(colorCode);
              }}
            >
              {colorCode === allColorSelect ? (
                <img src={tickIcon} alt="" />
              ) : (
                ""
              )}
            </Button>
          ))}
        </PopoverBody>
      </UncontrolledPopover> */}
    </>
  );
};
StudentGradebokColorPopover.propTypes = {
  editAddColor: PropTypes.number,
  UpdateStudentGradebookColorRequest: PropTypes.func,
  eIndex: PropTypes.number,
  cIndex: PropTypes.number,
};
export default StudentGradebokColorPopover;
