import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as routes from "Router/RoutesURL";
import { createNotification } from 'Config/notificationtoast';
import Card from 'react-bootstrap/Card';
import classIcon from 'Assets/StudentGradeBookModule/class.svg';
import noteIcon from 'Assets/StudentGradeBookModule/note.svg';
import userShareIcon from 'Assets/RosterModule/userShare.png'

const StudentGradeBookHeader = (props) => {
    const { history } = props;
    const { location } = history;
    const { studentInfo, academicYear, classId, classPermission } = location.state;
    const _ = require('lodash');
    const disShareOptionDiv = ['shareOptionDiv', classPermission !== null && classPermission === 'read' ? 'opacityProp' : '']

    return (
        <Row>
            <Col xs="6" className="pr-2 profile-header-title">
                <div className="profile-card-img">
                    {studentInfo.cc_student_pic_url === '' ?
                        <h5 className='noteImageDummyText'>{studentInfo.cc_student_first_name.charAt(0)}{studentInfo.cc_student_last_name.charAt(0)}</h5>
                        :
                        <Card.Img className="profile-img" variant="top" alt="" src={studentInfo.cc_student_pic_url} />}
                </div>
                <div className="profile-details-col">
                    <span style={{font: "normal normal 600 1rem/1.375rem Open Sans", color: "#222222", position: "relative", top: "2px !important"}}>
                        {studentInfo.cc_student_last_name?.replace(/(.{13})..+/, "$1…")}, {studentInfo.cc_student_first_name?.replace(/(.{13})..+/, "$1…")}
                        </span>
                    <br></br>
                    <span style={{position: "relative", top: "0px !important", font: "normal normal normal 12px/16px Open Sans", color: "#777777"}}>
                        {_.truncate(studentInfo.cc_student_email_id, {
                            'length': 30,
                            'omission': '...'
                        })}
                    </span>
                </div>
            </Col>

            <Col xs="6" className="pl-2 profile-header-action">
                <div>
                    <div className="studentOptionDiv">
                        {/* ---------Class-------- */}
                        <div className="classOptionDiv" onClick={() => {
                            if (studentInfo.is_associated_with_class === 'no') {
                                createNotification('error', 'This student is not associated with any Class.');
                                return;
                            }
                            history.push(routes.ROSTERENRILLED, { academicYear: academicYear, studentInfo: studentInfo, academicYearIDFromClassNote : props?.AcademicDataObjData?.academic_year_id || props?.history?.location?.state?.academicYearIDFromClassNote, classId: classId, BackPage: 'Student Gradebook', classPermission: classPermission })
                        }}>
                            <img src={classIcon} alt="" className="optionIcons" />
                            <p className="optionText">Class</p>
                        </div>
                        {/* ---------Class-------- */}


                        {/* ---------Note-------- */}
                        <div className="noteOptionDiv" onClick={() => {
                            if (studentInfo.is_associated_with_class === 'no') {
                                createNotification('error', 'This student is not associated with any Class.');
                                return;
                            }
                            history.push(routes.STUDENTNOTE, { studentInfo: studentInfo, academicYearIDFromClassNote : props?.AcademicDataObjData?.academic_year_id || props?.history?.location?.state?.academicYearIDFromClassNote, academicYear: academicYear, pageBYClass: true, rosterClassId: classId, classId: classId, BackPage: 'Student Gradebook', classPermission: classPermission })
                        }}>
                            <img src={noteIcon} alt="" className="optionIcons" />
                            <p className="optionText">Note</p>
                        </div>
                        {/* ---------Note-------- */}

                        {/* ---------Share-------- */}
                        {classPermission !== null && classPermission === 'read' ?
                            <div className={disShareOptionDiv.join(' ')}>
                                <img src={userShareIcon} alt="" className="optionIcons" />
                                <p className="optionText">Share</p>
                            </div> :
                            <div className="shareOptionDiv" onClick={() => {
                                history.push(routes.STUDENTSHARE, {
                                    studentInfo: studentInfo.cc_student_id
                                });
                            }}>
                                <img src={userShareIcon} alt="" className="optionIcons" />
                                <p className="optionText">Share</p>
                            </div>}
                        {/* ---------Share-------- */}

                    </div>
                </div>
            </Col>
        </Row>
    )
}
StudentGradeBookHeader.propTypes = {
    classId: PropTypes.number,
    studentInfo: PropTypes.object,
    academicYear: PropTypes.string
}
export default StudentGradeBookHeader