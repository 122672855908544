import React, { useState } from "react";
import PropTypes from 'prop-types';
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import pencilIcon from "Assets/StudentGradeBookModule/pencil.png";
import { getFormattedDate } from "Utils/Helper";
import StudentGradebokColorPopover from '../StudentGradebokColorPopoverComponent/StudentGradebokColorPopover'

import "./StudentGradeBookTabPanel.scss";
import CommonTextField from "Components/CommonTextField/CommonTextField";
import { createNotification } from "Config/notificationtoast";

const StudentGradeBookTabPanel = (props) => {
  const { index, history, category, calculateAverage, selectCategory, selectedCategory, UpdateStudentGradebookInputRequest, updateValueOfCategory, UpdateStudentGradebookColorRequest } = props
  const [editAddComment, setEditAddCommentCheck] = useState(0);
  const [editAddGrade, setEditAddGradeCheck] = useState(0);
  const [editAddColor, setEditAddColorCheck] = useState(0);
  const signDate = sessionStorage.getItem('separatorFormat')
  const formatDate = sessionStorage.getItem('dateFormat')
  
  // ----- toggle on/off------
  const filterSelect = (value, eIndex, cIndex) => {
    calculateAverage(value, eIndex, cIndex)
  }
  // ----- toggle on/off------



  // ----- change Input------
  const changeInput = (value, cIndex, eIndex, type, evenId) => {
    const data = {
      value: value,
      cIndex: cIndex,
      eIndex: eIndex,
      type: type === 'score' ? true : false,
    }
    if (type === 'score') {
      // setEditAddGradeCheck(evenId)
      if(value.length == 0) {
        const data = {
          value: '',
          cIndex: cIndex,
          eIndex: eIndex,
          type: type === 'score' ? true : false,
        }
        UpdateStudentGradebookInputRequest(data)
    } else {
       //CHECK IF NUMBER
      var pattern = /^[0-9]+/;
      if(pattern.test(value[0])) {
        const scoreValue = /^[0-9]{0,3}(\.[0-9]{0,2})?$/;
            if ((scoreValue.test(value))) {
                setEditAddGradeCheck(evenId)
                 UpdateStudentGradebookInputRequest(data)
            } 
    } else {
      if(value.length < 4) {
        // const data = {
        //   value: value,
        //   cIndex: cIndex,
        //   eIndex: eIndex,
        //   type: type === 'score' ? true : false,
        // }
        UpdateStudentGradebookInputRequest(data)
        }
    }
  }
    } else {
      UpdateStudentGradebookInputRequest(data)
    }
   
  }
  // ----- change Input------

  // ----- add Grade Status------
  const addGrade = (id, catId) => {
    selectCategory(catId)
    setEditAddGradeCheck(id)
  }
  // ----- add Grade Status------


  // ----- add Comment Status------
  const addComment = (id) => {
    setEditAddCommentCheck(id)
  }
  // ----- add Comment Status------


  // ----- UnSelect Category------
  const unSelectCategory = () => {
    setEditAddColorCheck()
    setEditAddGradeCheck(0)
    setEditAddCommentCheck(0)
    selectCategory(0)
   
  }
  // ----- UnSelect Category------

   // ----- Reset Category------
  const resetCategory = () => {
    const data = {
      resetValue : true
    }
    UpdateStudentGradebookInputRequest(data)
  }
 // ----- UnSelect Category------

  // ----- Update Category------
  const updateSelectCategory = (value) => {
    let sendArray = [];
    for (let item of value.category_events) {
      const array = {
        "event_id": item.grading_event_id,
        "color_code": item.grading_event_student_color_code,
        "score": item.grading_event_student_score,
        "comments": item.grading_event_student_comments,
      }
      sendArray.push(array)
    }
    unSelectCategory();
    updateValueOfCategory(sendArray)
  }
  // ----- Update Category------

  // ----- Open/Close PopOver------
  const openPopOver = (id) => {
    if (editAddColor === id) {
      setEditAddColorCheck()
    } else {
      setEditAddColorCheck(id)
    }

  }
  // ----- Open/Close PopOver------

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    >
      {category && category.length > 0 && category.map((cItem, cIndex) => (
        <Card key={cIndex} className="score-card br-8 mt-15">
          {cItem.category_events.length === 0 ?
            <Card.Header><span>{cItem.cc_category_name}</span><span> (No events)</span></Card.Header> :
            <Card.Header>
              <span>{cItem.cc_category_name}</span>
              {!(selectedCategory === cItem.cc_category_id) ? (
                <Button variant="default" className="pull-right" onClick={() => { selectCategory(cItem.cc_category_id) }} disabled={history?.location?.state?.classData?.cc_class_share_permission == 'read'}>
                  <img src={pencilIcon} alt="" /> Edit
                </Button>
              ) : (
                  <>
                    <Button variant="default" className="pull-right" onClick={() => { unSelectCategory(); resetCategory() }}>
                      Cancel
                    </Button>
                    <Button variant="default" className="pull-right update-btn" onClick={() => { updateSelectCategory(cItem) }}>
                      Update
                    </Button>
                  </>
                )}

            </Card.Header>}
          <Card.Body>
            <Card.Text as="div">
              <Row>
                <Col xs="12">
                  {cItem.category_events && cItem.category_events.length > 0 && cItem.category_events.map((eItem, eIndex) => (
                    <Row key={eIndex}>
                      <Col xs="7" md="8" lg="9" className="pr-0 text-left">
                        <div className="checkbox">
                          <i onClick={() => { filterSelect(eItem, eIndex, cIndex) }} className="checkBoxfilter material-icons">{eItem.isToggleActive ? 'check_circle' : 'radio_button_unchecked'}</i>
                        </div>
                        <div className="checkbox-lable">
                          <span className="heading">{eItem.grading_event_name}</span>
                          <br></br>
                          {eItem.grading_event_student_comments ? <span className="sub-heading">
                            {selectedCategory === cItem.cc_category_id ? <FormControl
                              autoFocus
                              placeholder="Please Enter Comment Here"
                              aria-label="Comment"
                              value={eItem.grading_event_student_comments}
                              onChange={(e) => { changeInput(e.target.value, cIndex, eIndex, 'comment') }}
                              className="sub-heading none-italic-style"
                            /> : eItem.grading_event_student_comments}
                          </span>
                            :
                            <div>
                              {editAddComment === eItem.grading_event_id ? <FormControl
                                autoFocus
                                placeholder="Please Enter Comment Here"
                                aria-label="Comment"
                                onChange={(e) => { changeInput(e.target.value, cIndex, eIndex, 'comment', eItem.grading_event_id) }}
                                className="sub-heading none-italic-style"
                              /> :

                                <Button
                                  variant="default"
                                  className="add-comment"
                                  onClick={() => {
                                    if (selectedCategory === cItem.cc_category_id) {
                                      addComment(eItem.grading_event_id)
                                    }
                                  }}
                                >
                                  Add Comment
                              </Button>}

                            </div>}

                        </div>
                      </Col>
                      <Col
                        xs="5"
                        md="4"
                        lg="3"
                        className="pl-0 score-section d-flex justify-content-end"
                      >
                        <label> {getFormattedDate(eItem.grading_event_date, formatDate, signDate)}</label>
                        <div className="score-div">
                          {eItem.grading_event_student_score ? 
                          <>
                                    
                           <FormControl
                             value={eItem.grading_event_student_score}
                             type='text'
                             placeholder=""
                             aria-label="Level"
                             className="score"
                              // maxLength={3}
                             autoFocus
                             id={`mypopOver${cIndex}${eIndex}`}
                             onClick={() => { openPopOver(eItem.grading_event_id) }}
                            //  onBlur={() => { openPopOver(0) }}
                             onChange={(e) => { changeInput(e.target.value, cIndex, eIndex, 'score', eItem.grading_event_id) }}
                             style={{ color: (eItem.grading_event_student_color_code === '' || eItem.grading_event_student_color_code === '#FFFFFF') ? '#777777' : eItem.grading_event_student_color_code, border: eItem.grading_event_student_color_code && `2px solid ${eItem.grading_event_student_color_code}` }}
                             disabled={selectedCategory === cItem.cc_category_id ? false : true}
                           />
                         
                          </>
                           :
                            <div>
                              {editAddGrade === eItem.grading_event_id ?
                               <FormControl
                                value={eItem.grading_event_student_score}
                                placeholder=""
                                aria-label="Level"
                                className="score"
                                // maxLength={3}
                                autoFocus
                                id={`mypopOver${cIndex}${eIndex}`}
                                onClick={() => { openPopOver(eItem.grading_event_id) }}
                                // onBlur={() => { openPopOver(0) }}
                                style={{ color: (eItem.grading_event_student_color_code === '' || eItem.grading_event_student_color_code === '#FFFFFF') ? '#777777' : eItem.grading_event_student_color_code, border: eItem.grading_event_student_color_code && `2px solid ${eItem.grading_event_student_color_code}` }}
                                onChange={(e) => { changeInput(e.target.value, cIndex, eIndex, 'score', eItem.grading_event_id) }}
                              /> :
                                
                                  selectedCategory && selectedCategory > 0 ? 
                                  <>
                                  <FormControl
                                  value={eItem.grading_event_student_score}
                                  placeholder=""
                                  aria-label="Level"
                                  className="score"
                                  // maxLength={3}
                                  autoFocus
                                  id={`mypopOver${cIndex}${eIndex}`}
                                  onClick={() => { openPopOver(eItem.grading_event_id) }}
                                  // onBlur={() => { openPopOver(0) }}
                                  style={{ color: (eItem.grading_event_student_color_code === '' || eItem.grading_event_student_color_code === '#FFFFFF') ? '#777777' : eItem.grading_event_student_color_code, border: eItem.grading_event_student_color_code && `2px solid ${eItem.grading_event_student_color_code}` }}
                                  onChange={(e) => { changeInput(e.target.value, cIndex, eIndex, 'score', eItem.grading_event_id) }}
                                />
                                  </>
                                :

                                <Button
                                  variant="default"
                                  className="add-grade"
                                  onClick={() => {
                                    if(props?.history?.location?.state?.classData?.cc_class_share_permission != 'read') {
                                    addGrade(eItem.grading_event_id, cItem.cc_category_id)
                                    }
                                    // if (selectedCategory === cItem.cc_category_id) {
                                    //   addGrade(eItem.grading_event_id)
                                    // }
                                  }}
                                >
                                  Grade Now
                              </Button>}
                            </div>}
                          {editAddColor === eItem.grading_event_id &&
                            <StudentGradebokColorPopover
                              editAddColor={editAddColor}
                              setEditAddColorCheck={setEditAddColorCheck}
                              openPopOver={openPopOver}
                              gradingEventId={eItem.grading_event_id}
                              cIndex={cIndex}
                              eIndex={eIndex}
                              UpdateStudentGradebookColorRequest={UpdateStudentGradebookColorRequest}
                            />}
                        </div>
                      </Col>
                      {!(cItem.category_events.length - 1 === eIndex) && <Col xs="12">
                        <hr></hr>
                      </Col>}
                    </Row>))}
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>))}
    </div>
  );

}
StudentGradeBookTabPanel.propTypes = {
  calculateAverage: PropTypes.func,
  selectCategory: PropTypes.func,
  UpdateStudentGradebookInputRequest: PropTypes.func,
  updateValueOfCategory: PropTypes.func,
  UpdateStudentGradebookColorRequest: PropTypes.func,
  index: PropTypes.number
}

export default StudentGradeBookTabPanel
