import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import pencilIcon from 'Assets/FormativeAssessmentModule/pencil-alt.png';
import trashIcon from 'Assets/FormativeAssessmentModule/trash-alt.png';
import '../FormativeAssessmentDashboardComponent/FormativeAssessmentDashboard.scss';
import warningIcon from 'Assets/NoteModule/warning-icon.png'

const FormativeAssessmentTag = (props) => {
    const { FormativeAssessmentTags, onChangeTagId,setEditAssesmentTagId, userAddAssesment, setAssissmentTypeCheck, setAssissmentPageCheck, setUserAddAssesment, onDeleteTagId, setUserEditTag, userEditTag, onSelectTagId, selectedTagId, typeAssissment, permissionType, classSharePermission, FT , setFT } = props;
        
    const [deleteModal, setdeleteModalCheck] = useState(false)
    const [deleteItem, setdeleteitemCheck] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [userEditData, setUserEditData] = useState(null)
    /* --------------- Edit Request for Edit Tag------------ */
    const selectTagIdEdit = (item) => {
        onChangeTagId(item)
    }
    /* ---------------Edit Request for Edit Tag------------ */

    /* --------------- Delete Request Modal Approve for Delete Tag------------ */
    const selectTagIdDelete = (item) => {
        setdeleteModalCheck(true)
        setdeleteitemCheck(item)
    }
    /* --------------- Delete Request Modal Approve for Delete Tag------------ */

    /* --------------- Approved for Delete Tag------------ */
    const approveDelete = () => {
        setdeleteModalCheck(false)
        onDeleteTagId(deleteItem.assessment_tag_id)
        setAssissmentPageCheck(false)
    }
    /* --------------- Approved for Delete Tag------------ */

    /* ---------------Not Approved for Delete Tag------------ */
    const notApproveDelete = () => {
        setdeleteModalCheck(false)
        setdeleteitemCheck([])
    }
    /* ---------------Not Approved for Delete Tag ------------ */

    /* ---------------select Tag Id For Load Data------------ */
    const selectTagIdData = (item) => {
        if (permissionType !== null && permissionType !== 'read') {
            if (typeAssissment === 'add') {
                onSelectTagId(item)
            }
        }
    }
    /* --------------select Tag Id For Load Data-------------- */
    return (
        <div>
            { /* ---------------Tag Section------------ */}
            <Card.Body style={{minHeight: '1px', padding: '0px'}}>
                {(FormativeAssessmentTags && FormativeAssessmentTags?.data && FormativeAssessmentTags?.data?.assessment_details?.length > 0) ?
                    <Row className="tagsTitle"><Col xs="12"><div className="cardTitle"><p className="attchement-card">Tags</p></div></Col></Row>
                    :
                    <Row className="tagsTitle"><Col xs="12" ><div className="cardTitle"><p className="attchement-card">No Tags</p></div></Col></Row>
                }
                {FormativeAssessmentTags && FormativeAssessmentTags?.data && FormativeAssessmentTags?.data?.assessment_details?.length > 0 && <Card.Text as="div" style={{overflowX : "hidden"}} className="tagsFormDiv">
                    <Row >
                        {FormativeAssessmentTags?.data?.assessment_details.map((item, index) => {
                            return (
                                <Col onClick={() => { if(userAddAssesment) {setShowModal(true); setUserEditData(item)} else { selectTagIdData(item); setUserEditTag(true); setFT(false);} }} key={index} xs="12" className="single-attchement" style={{ background: selectedTagId === item.assessment_tag_id ? '#F1F5FA' : '' }}>
                                    <div className='tooltip'>
                                        <label className="title">{item.assessment_tag_name}</label>
                                    </div>
                                    {selectedTagId === item.assessment_tag_id  && <div className="action">
                                        {(permissionType !== null && permissionType === 'read') || classSharePermission == 'read'|| sessionStorage.getItem('FAClassPermission') == 'read' || props?.history?.location?.state?.classDetailsdata?.cc_class_share_permission == 'read' ? < Button variant="default" className="disabledEdit-btn">
                                            <img src={pencilIcon} alt='' className='img' />
                                        </Button> :
                                        <>
                                        {
                                            userEditTag && 
                                            <Button onClick={() => { selectTagIdEdit(item); setEditAssesmentTagId(item.assessment_tag_id); setUserEditTag(true) }} variant="default" className={`edit-btn ${item.assessment_tag_id == props.editAssesmentTagId && 'disabledEdit-btn'}`} disabled={item.assessment_tag_id == props.editAssesmentTagId}>
                                            <img src={pencilIcon} alt='' className='img' />
                                        </Button>
                                        }
                                           
                                            </>
                                            }
                                        {(permissionType !== null && permissionType === 'read')|| classSharePermission == 'read'|| sessionStorage.getItem('FAClassPermission') == 'read' || props?.history?.location?.state?.classDetailsdata?.cc_class_share_permission == 'read' ? <Button variant="default" className="disabledTrash-btn">
                                            <img src={trashIcon} alt='' className='img' />
                                        </Button> :
                                        <>
                                        {
                                            userEditTag && 
                                            <Button onClick={() => { selectTagIdDelete(item) }} variant="default" className="trash-btn">
                                                <img src={trashIcon} alt='' className='img' />
                                            </Button>
                                        }
                                            </>
                                            }
                                    </div>}

                                </Col>
                            )
                        })}
                    </Row>
                </Card.Text>}
            </Card.Body>
            { /* ---------------Tag Section------------ */}

            { /* ---------------Modal For Delete Assessment------------ */}
            <Modal size="sm" show={deleteModal} centered={true}>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <div className='TagSaveModalMain'>
                        <p className='TagSaveModalNoteText'>Delete Assessment Tag</p>
                    </div>
                    <p className='TagSaveModalWorningText'>Are you sure you want to delete assessment tag?</p>
                    <div className='permissionButtonsDiv'>
                        <div className='backButtonTagText' onClick={notApproveDelete}>
                            No
                        </div>
                        <div>
                            <Button onClick={approveDelete} className='modalSaveTagButton' color="primary" >
                                <span className='modalSaveTagButtonText' > Delete</span>
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            { /* ---------------Modal For Delete Assessment------------ */}

            <Modal centered className="delete-modal-warning br-8" show={showModal} >
                    <Modal.Header>
                        <Modal.Title>
                            <div style={{display : 'flex', flexDirection: 'column'}}>
                                <img src={warningIcon} alt="" style={{ width: "60px", height: "60px"}} />
                                <span style={{fontSize: '24px', color: '#ff1f1f', marginTop: '10px'}}>Wait!</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>All your data will be lost. </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className="cancel-btn-div cancel-btn-div-note" onClick={() => {   setShowModal(false); }}
                        style={{marginRight : 18}}
                        >
                            Cancel
                        </Button>
                        <Button variant="default" className="goback-btn-div goback-btn-div-note" onClick={() => { 
                            // confirmationToBack(); 
        // setConfirmationType('back') 
        // history.goBack()
        setUserAddAssesment(false)
        setAssissmentTypeCheck('')
        setShowModal(false)
        selectTagIdData(userEditData); 
        setUserEditTag(true)
        setAssissmentPageCheck(false)
        
        }}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>

        </div >
    )
}

FormativeAssessmentTag.propTypes = {
    onChangeTagId: PropTypes.func,
    onDeleteTagId: PropTypes.func,
    onSelectTagId: PropTypes.func,

};

export default FormativeAssessmentTag
