import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StudentGradeBookTabs from '../StudentGradeBookTabComponent/StudentGradeBookTabs';
import StudentGradeBookHeader from '../StudentGradeBookHeaderComponent/StudentGradeBookHeader';
import StudentGradeBookFooter from '../StudentGradeBookFooterComponent/StudentGradeBookFooter'
import DivLoader from 'Components/LoadingComponent/DivLoader';
import './StudentGreadeBookProfile.scss';

const StudentGreadeBookProfile = (props) => {
    const {
        getStudentGradebookRequest,
        history,
        studentGradebookList,
        studentClassList,
        eventAverage,
        UpdateStudentGradebookAverageRequest,
        UpdateStudentGradebookCheckboxRequest,
        UpdateStudentGradebookInputRequest,
        updateStudentGradebookRequest,
        UpdateStudentGradebookColorRequest,
        selectedClassId,
        loader } = props;
    const { location } = history;
    const { state } = location;
    const { classId, studentInfo, academicYear, BackPage, ClassData } = state;
    const [selectedClassIdEvents, setSelectedClassIdEventsCheck] = useState(classId)
    const [averageEvents, setAverageEventsCheck] = useState([])
    const [selectedCategory, setSelectedCategoryCheck] = useState(0)
    /* ---------------onLoad------------ */

   
    useEffect(() => {
        getGradebookByClass(classId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /* ---------------onLoad------------ */



    /* ---------------Get Gradebook By Class------------ */
    const getGradebookByClass = (classId) => {
        if (BackPage !== 'Roster') {
            setSelectedClassIdEventsCheck(classId)
        }
        setAverageEventsCheck([])
        setSelectedCategoryCheck('')
        UpdateStudentGradebookAverageRequest(0)
        const apiValue = {
            "backPage": BackPage,
            "classId": classId,
            "studentId": studentInfo.cc_student_id,
            "pageNo": 1
        }
        getStudentGradebookRequest(apiValue);
    }
    /* ---------------Get Gradebook By Class------------ */

    /* ---------------Average Score Calculation------------ */
    const calculateAverage = async (value, eIndex, cIndex) => {
        let array = averageEvents;
        if (!value.isToggleActive) {
            array.push(value);
        } else {
            let index = 0
            for (let item of array) {
                if (item.grading_event_id === value.grading_event_id) {
                    break
                }
                index++;
            }
            array.splice(index, 1)
        }

        let total = 0;
        for (let item of array) {
            total = await totalAverage(item, total)
        }
        const Ava = total === 0 ? 0 : total / averageEvents.length
        const data = {
            eIndex, cIndex
        }
        UpdateStudentGradebookCheckboxRequest(data);
        UpdateStudentGradebookAverageRequest(Ava)
        setAverageEventsCheck(array)

    }
    /* ---------------Average Score Calculation------------ */


    /* ---------------total Average Calculation------------ */
    const totalAverage = (item, total) => {

        if (item.grading_event_student_score === '') {
            total = Number(total) + Number(0);

        } else {
            if (!/^-?[\d.]+(?:e-?\d+)?$/.test(item.grading_event_student_score)) {
                total = Number(total) + Number(0);
            }
            else {
                total = Number(total) + Number(item.grading_event_student_score);

            }
        }
        return total;
    }
    /* ---------------total Average Calculation------------ */




    /* ---------------Select Category------------ */
    const selectCategory = (id) => {
        setSelectedCategoryCheck(id)
    }
    /* ---------------Select Category------------ */


    /* ---------------update Value Of Category------------ */
    const updateValueOfCategory = (gradingList) => {
        const data = {
            "classId": classId,
            "studentId": studentInfo.cc_student_id,
            "pageNo": 1,
            "gradingList": gradingList
        }
        updateStudentGradebookRequest(data)
    }
    /* ---------------update Value Of Category------------ */


    return (
        <div className='studentGradeBookMainContainer'>
            <Row>

                {/* ---------------Main panel------------ */}
                <Col md="12" lg="12">
                    {/* ---------------Top header panel------------ */}
                    <Card className="profile-header mb-15">
                        <Card.Body>
                            <StudentGradeBookHeader studentInfo={studentInfo} classId={selectedClassIdEvents} AcademicDataObjData={props?.history?.location?.state?.AcademicDataObj} academicYear={academicYear} ClassData={ClassData} history={history} />
                        </Card.Body>
                    </Card>
                    {/* ---------------Top header panel------------ */}

                    {/* ---------------Tab panel------------ */}

                    {studentClassList && studentClassList.length > 0 ? <Row className="overFlowClass" >
                        <Col xs="12" md="12" lg="12">
                            <StudentGradeBookTabs
                                classId={selectedClassId}
                                studentClassList={studentClassList}
                                studentGradebookList={studentGradebookList}
                                getGradebookByClass={getGradebookByClass}
                                calculateAverage={calculateAverage}
                                selectCategory={selectCategory}
                                updateValueOfCategory={updateValueOfCategory}
                                UpdateStudentGradebookInputRequest={UpdateStudentGradebookInputRequest}
                                selectedCategory={selectedCategory}
                                UpdateStudentGradebookColorRequest={UpdateStudentGradebookColorRequest}
                                loader={loader}
                                BackPage={BackPage}
                                history={history}
                            />
                        </Col>
                    </Row>
                        :
                        <Row>
                            {loader && <div style={{ width: "100%", textAlign: 'center' }}>
                                <DivLoader />
                            </div>}
                        </Row>}
                    {/* ---------------Tab panel------------ */}

                    {/* ---------------Bottom panel------------ */}
                    <Row className="avg-panel">
                        <StudentGradeBookFooter eventAverage={eventAverage} />
                    </Row>
                    {/* ---------------Bottom panel------------ */}

                </Col>
                {/* ---------------Right panel------------ */}

            </Row>
        </div>
    )

}
StudentGreadeBookProfile.propTypes = {
    getStudentGradebookRequest: PropTypes.func,
    UpdateStudentGradebookAverageRequest: PropTypes.func,
    UpdateStudentGradebookCheckboxRequest: PropTypes.func,
    UpdateStudentGradebookInputRequest: PropTypes.func,
    updateStudentGradebookRequest: PropTypes.func,
    UpdateStudentGradebookColorRequest: PropTypes.func,
    eventAverage: PropTypes.number,
    eventCount: PropTypes.number,
    loader: PropTypes.bool,
}

export default StudentGreadeBookProfile